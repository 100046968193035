import { HtmlRenderer } from '@ambition/ui-html-renderer'
import { CompactPositionRecord, encodeToken, IPositionPartialDetail, isExpiredPosition, Job } from 'core'
import React, { useEffect, useRef } from 'react'

import { BottomSheet, Modal } from '~/components'
import { useIntersectionObserver } from '~/hooks'
import { Button, useHistory, useStore, useTranslation, View } from '~/lite'
import { getScreenWidth } from '~/util'

import { ActionButtons } from '../PositionContent'
import { PositionContentProps } from './types'

const isPositionDetail = (
  position: IPositionPartialDetail | CompactPositionRecord
): position is IPositionPartialDetail => 'teamDetail' in position

export const PositionShortContent: React.FC<PositionContentProps> = ({ id, data, onVisible, skeleton }) => {
  const t = useTranslation()
  const history = useHistory()
  const { isAuthenticated } = useStore()
  const [isDescriptionBottomSheetOpened, setIsDescriptionBottomSheetOpened] = React.useState<boolean | null>(null)
  const [isMagicLinkBottomSheetOpened, setIsMagicLinkBottomSheetOpened] = React.useState<boolean | null>(null)

  const visibilityRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(visibilityRef, { paused: !onVisible })
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible) {
      onVisible?.(data.id)
    }
  }, [isVisible, onVisible, data])

  const onApplyButtonPress = () => {
    setIsMagicLinkBottomSheetOpened(true)
  }

  const renderActionButtons = () => {
    if (!isPositionDetail(data)) {
      return null
    }
    return (
      <ActionButtons
        id={data.id}
        hasForm={data.hasForm}
        title={data.title}
        applicationUrl={data.applicationUrl}
        enableMagicLinkAnimation={false}
      />
    )
  }

  /*
    const enhancementResults = extra?.enhancements?.results || {}
    const tags = enhancementResults?.tags
    const headerText = enhancementResults?.header
    <View position="absolute" width="100%" height="100%" top={0} zIndex={100}>
      <PositionNavBar url={data?.id ? `${constants.frontendUrl}/jobs/${encodeToken(data.id)}` : null} />
    </View>
  */

  return (
    <>
      <div className="visibility-check" ref={visibilityRef} />
      {isPositionDetail(data) ? (
        <>
          <Job
            id={id}
            t={t}
            data={data}
            skeleton={skeleton}
            magicLink={
              isExpiredPosition(data) ? null : isAuthenticated ? (
                <View paddingBottom="m">{renderActionButtons()}</View>
              ) : (
                <View paddingX="m" paddingY="s">
                  <Button skipTracking primary text="Apply" onPress={onApplyButtonPress} />
                </View>
              )
            }
            onTeamPress={username => history.push(`/teams/${encodeToken(username)}/jobs`)}
            onLocationPress={locationPath => history.push(locationPath)}
            onDescriptionButtonClick={() => setIsDescriptionBottomSheetOpened(true)}
          />
          {isMagicLinkBottomSheetOpened !== null && (
            <Modal isOpen={isMagicLinkBottomSheetOpened} onClose={() => setIsMagicLinkBottomSheetOpened(false)}>
              {renderActionButtons()}
            </Modal>
          )}
          {isDescriptionBottomSheetOpened !== null && (
            <BottomSheet
              isOpen={isDescriptionBottomSheetOpened}
              onClose={() => setIsDescriptionBottomSheetOpened(false)}
              snapPoints={['75%', '85%']}
              alwaysOpen={1}
              useBottomSheetModal
              title="Description"
            >
              <View paddingX="m">
                <HtmlRenderer contentWidth={getScreenWidth()} html={data.description ?? ('' as any)} />
              </View>
            </BottomSheet>
          )}
        </>
      ) : (
        <Job id={id} t={t} data={null} magicLink={null} skeleton />
      )}
    </>
  )
}
