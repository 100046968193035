import { JobInterstitial } from '@ambition/module-shared'
import { HtmlRenderer } from '@ambition/ui-html-renderer'
import { IPosition, IPositionPartial, isExpiredPosition, Job } from 'core'
import React, { useCallback } from 'react'

import { BottomSheet } from '~/components'
import { useScreenTracking } from '~/hooks'
import { encodeToken, useHistory, useStore, useTranslation, View } from '~/lite'
import { getScreenWidth } from '~/util'

import { IPositionLike } from '../Position'
import { PositionCard } from '../PositionCard'
import { ActionButtons } from './ActionButtons'
import { CommentsSection } from './CommentsSection'
import { CompanySection } from './CompanySection'
import { RelatedPositionSection } from './RelatedPositionSection'
import { PositionContentProps } from './types'

export type { PositionContentProps }

const isExistingPosition = (position: IPositionLike): position is IPositionPartial | IPosition =>
  !!(position as IPosition).id

export const PositionContent: React.FC<PositionContentProps> = ({
  data,
  context,
  onApplyButtonPress,
  onStudioButtonPress,
  skipTracking,
  getJobDashboardPath
}) => {
  const source = useStore(store => store.source)
  const isTeamMember = useStore(store => store.isTeamMember)
  const t = useTranslation()
  const history = useHistory()
  const [isDescriptionBottomSheetOpened, setIsDescriptionBottomSheetOpened] = React.useState<boolean | null>(null)
  const [showInterstitial, setShowInterstitial] = React.useState<null | boolean>(
    false //data.hasForm || context === 'Dashboard' ? false : null
  )
  const { teamDetail } = data

  useScreenTracking(
    () =>
      isExistingPosition(data) &&
      !skipTracking && { screen: 'Position', context, positionId: data.id, teamId: teamDetail?.id ?? null }
  )

  const onDismissInterstitial = useCallback(() => {
    if (data.applicationUrl && !data.showDetailPage) {
      const link = document.createElement('a')
      link.referrerPolicy = 'no-referrer'
      link.rel = 'noreferrer'
      link.href = data.applicationUrl
      link.click()
    } else {
      setShowInterstitial(false)
    }
  }, [data.applicationUrl, data.showDetailPage])

  /*
  // disabled because people want to get to the job page, and it was redirecting to the external job page
  // just show the external job page if they already have a resume on file
  const resumeImportState = useStore(store => store.resumeImportState)
  const importedResume = resumeImportState === 'Success'
  useEffect(() => {
    const cookiePrefix = isStaging ? 'staging-' : ''

    if (data.applicationUrl && (importedResume || getCookie(`${cookiePrefix}resumeImportState`) === 'Success')) {
      onDismissInterstitial()
    }
  }, [data.applicationUrl, importedResume, onDismissInterstitial])
  */

  // @todo should have a non-ChatGPT version
  //if (showInterstitial || (showInterstitial === null && source === 'chatgpt')) {
  if (showInterstitial || showInterstitial === null) {
    return (
      <JobInterstitial
        jobCard={<PositionCard record={data} linkInSameWindow onPress={onDismissInterstitial} listItem />}
        onDismiss={onDismissInterstitial}
      />
    )
  }

  const isMember = isTeamMember(teamDetail?.id)

  return (
    <>
      <Job
        id={`job-${data.id}`}
        t={t}
        data={data}
        skeleton={false}
        magicLink={
          isExpiredPosition(data) ? null : (
            <ActionButtons
              id={data.id}
              team={teamDetail}
              isTeamMember={isMember}
              hasForm={data.hasForm}
              enabledInterview={!!data.interviewState}
              title={data.title}
              applicationUrl={data.applicationUrl}
              getJobDashboardPath={getJobDashboardPath}
              onApplyButtonPress={onApplyButtonPress}
              onStudioButtonPress={onStudioButtonPress}
            />
          )
        }
        onTeamPress={username =>
          history.push(
            getJobDashboardPath
              ? getJobDashboardPath({ teamId: data.teamDetail.id })
              : `/teams/${encodeToken(username)}/jobs`
          )
        }
        onLocationPress={locationPath => history.push(locationPath)}
        onDescriptionButtonClick={() => setIsDescriptionBottomSheetOpened(true)}
        getJobDashboardPath={getJobDashboardPath}
      />
      {isDescriptionBottomSheetOpened !== null && (
        <BottomSheet
          isOpen={isDescriptionBottomSheetOpened}
          onClose={() => setIsDescriptionBottomSheetOpened(false)}
          snapPoints={['75%', '85%']}
          alwaysOpen={1}
          useBottomSheetModal
          title="Description"
        >
          <View paddingX="m">
            <HtmlRenderer contentWidth={getScreenWidth()} html={data.description ?? ('' as any)} />
          </View>
        </BottomSheet>
      )}
      <View paddingX={getJobDashboardPath ? 'm' : undefined}>
        <CommentsSection positionId={data.id} commentsCount={data?.commentsCount ?? null} />
        <CompanySection
          id={teamDetail.id}
          positionId={data.id}
          name={teamDetail.name}
          username={teamDetail.username}
          location={teamDetail.location}
          avatar={teamDetail.logo}
          description={teamDetail.bio}
          hideAnalyzeButtonOnPositionSection={isMember}
          getJobDashboardPath={getJobDashboardPath}
        />
        <RelatedPositionSection
          id={data.id}
          occupationName={data?.occupationName}
          hideAnalyzeButtonOnPositionSection={isMember}
          getJobDashboardPath={getJobDashboardPath}
        />
      </View>
    </>
  )
}

/*
  const isTeamMember = useStore(state => state.isTeamMember)
  const {
    title,
    teamDetail,
    location,
    postedAt,
    description,
    summary,
    id,
    hasForm,
    applicationUrl,
    compensation,
    allowContract
  } = data

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean | null>(null)
  const [isSuggestModalOpen, setIsSuggestModalOpen] = React.useState<boolean | null>(null)
  const user = useStore(state => state.user)

  const isMember = isTeamMember(teamDetail?.id)
  const canPromote = isMember && user
  const canDelete = isMember && user

  // @todo need to auto-parse dates within the api layer
  const hasExpired = isExpiredPosition(data)

  const onDeleteButtonPress = () => {
    if (!isExistingPosition(data)) {
      return
    }
    deletePosition({ id: id })
    setIsDeleteModalOpen(false)
  }

  <FlexHorizontal width="100%" justifyContent="center" marginTop={20}>
    {isMember && (
      <Button
        action="EditPosition"
        text="Edit"
        to={`/positions/${encodeToken(id)}/edit`}
        testID={`edit-position-${id}-button`}
        icon="pencil"
        marginRight="xs"
      />
    )}
    {canPromote && (
      <Button
        action="PromotePosition"
        testID={`promote-position-${id}-button`}
        large
        icon="checkmark-circle"
        text="Promote"
        to={`/positions/${encodeToken(id)}/promote`}
        marginRight="xs"
      />
    )}
    {canDelete && (
      <Button
        action="DeletePosition"
        testID={`delete-position-${id}-button`}
        large
        dangerous
        icon="remove-circle-outline"
        text="Delete"
        onPress={() => setIsDeleteModalOpen(true)}
        marginRight="xs"
      />
    )}
  </FlexHorizontal>
  {isDeleteModalOpen !== null && (
    <PositionDialog
      isOpen={isDeleteModalOpen}
      title="Delete this job?"
      confirmButtonAction="DeletePosition"
      cancelButtonAction="CancelDeletePosition"
      confirmationText={t('Are you sure you want to delete "{{recordName}}"?', { recordName: title })}
      confirmButtonText="Delete"
      onClose={() => setIsDeleteModalOpen(false)}
      onConfirm={onDeleteButtonPress}
    />
  )}
  {isSuggestModalOpen !== null && (
    <PositionDialog
      isOpen={isSuggestModalOpen}
      icon="warning"
      title="You are not logged in!"
      confirmButtonAction="ShowSignUpToApply"
      cancelButtonAction="CancelShowSignUpToApply"
      confirmationText={t('Please log in or sign up to submit an application')}
      confirmButtonText="Log In"
      onClose={() => setIsSuggestModalOpen(false)}
      onConfirm={() => {
        setIsSuggestModalOpen(false)
        prompt()
      }}
    />
  )}
  */
