export const BREAKPOINT_WIDTH = {
  XS: 480,
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536
}

export const breakpoint = {
  sm: `(min-width: ${BREAKPOINT_WIDTH.SM}px)`,
  md: `(min-width: ${BREAKPOINT_WIDTH.MD}px)`,
  lg: `(min-width: ${BREAKPOINT_WIDTH.LG}px)`,
  xl: `(min-width: ${BREAKPOINT_WIDTH.XL}px)`,
  '2xl': `(min-width: ${BREAKPOINT_WIDTH['2XL']}px)`
}

export const goldenRatio = 1.618
