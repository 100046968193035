import { FlexHorizontal, FlexVertical, H1, InfoList, ScrollView, styled, Text, View } from '~/components'

export const TeamInfo = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`

export const TeamAvatarWrap = styled(View)`
  margin-right: 5px;
  overflow: hidden;
`

export const Actions = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ApplicationInstructions = styled(View)`
  max-width: 250px;
`

export const Title = styled(H1)`
  font-size: 26px;
  margin-bottom: 0;
`

export const TeamName = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
`

export const ExpiredBanner = styled(View)`
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fefcea;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
`

export const ExpiredBannerText = styled(Text)`
  color: #7f5322;
  font-size: 16px;
  font-weight: bold;
`

export const StyledInfoList = styled(InfoList)`
  margin-top: 15px;
  padding-top: 15px;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.border};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.border};
`

export const Section = styled(View)`
  padding: 15px 0;
  margin-bottom: 20px;
`

export const SectionHeader = styled(View)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  color: ${props => props.theme.text};
  margin-bottom: 16px;
`

export const SummarySection = styled(View)`
  padding: 15px 0;
  margin-bottom: 20px;
  font-size: 24px;
`

export const SummaryOverflow = styled(Text)`
  color: #fff;
`

export const ExperienceSection = styled(View)`
  margin-bottom: 20px;
  padding: 15px 0 0;
`

export const Wrap = styled(ScrollView)`
  padding: 20px;
`

export const DialogBody = styled(FlexVertical)`
  padding: 24px;
`

export const DialogHeader = styled(FlexHorizontal)`
  position: relative;
  align-items: center;
  justify-content: center;
`

export const SuccessDialogBody = styled(FlexVertical)`
  padding: 20px 0 10px;
  align-items: center;
`
