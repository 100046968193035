import { AdvancedSearchQueryType, IPosition } from 'core'
import React from 'react'
import { useLocation } from 'react-router-dom'

export type SearchParams = {
  searchTerm: string
  searchType: AdvancedSearchQueryType
  positionId?: IPosition['id']
}

export const useSearchQueryParams = () => {
  const { search } = useLocation()
  const searchParams: SearchParams = React.useMemo(() => {
    const params = new URLSearchParams(search)
    return {
      searchTerm: params.get('query') || '',
      searchType: (params.get('type') || 'all') as AdvancedSearchQueryType,
      positionId: (params.get('positionId') as IPosition['id']) || undefined
    }
  }, [search])

  return searchParams
}
