import {
  convertDateValues,
  getFetchParams,
  IMessage,
  SendMessage,
  SendMessageStreamResponse,
  TranslatedText
} from 'core'

import { fetchWithStream } from '~/lite'

export const sendMessageWithStreamingResponse = (
  data: SendMessage['body'],
  callback: (response: SendMessage['response']) => void
): Promise<void> => {
  const [endpoint, options] = getFetchParams<SendMessage>('POST', '/messaging/messages/stream', { data })
  const messages: Record<IMessage['id'], IMessage> = {}

  return fetchWithStream({
    endpoint,
    options,
    callback: (chunk, error) => {
      if (error) {
        callback({ success: false })
        return
      }

      if (!chunk?.startsWith('data: ')) {
        return
      }

      const data = JSON.parse(chunk.slice(6)) as SendMessageStreamResponse

      // if it's the final data
      if ('success' in data) {
        if (data.success) {
          const adjustedMessages: IMessage[] = []

          for (const message of data.messages) {
            const adjusted = convertDateValues(message)
            adjustedMessages.push(adjusted)
            messages[adjusted.id] = adjusted
          }

          callback({ ...data, messages: adjustedMessages })
        } else {
          callback(data)
        }
        return
      }

      const message = messages[data.messageId] as IMessage | undefined
      let commands: IMessage['commands'] = message?.commands ?? []
      let attachments: IMessage['attachments'] = message?.attachments ?? []

      for (const command of data.commands ?? []) {
        const existingIndex: number = commands.findIndex(item => item.id === command.id)

        commands =
          existingIndex > -1
            ? [...commands.slice(0, existingIndex), command, ...commands.slice(existingIndex + 1)]
            : [...commands, command]
      }

      for (const attachment of data.attachments ?? []) {
        const existingIndex: number = attachment.id
          ? attachments.findIndex(item => item.type === attachment.type && item.id === attachment.id)
          : -1

        attachments =
          existingIndex > -1
            ? [...attachments.slice(0, existingIndex), attachment, ...attachments.slice(existingIndex + 1)]
            : [...attachments, attachment]
      }

      if (message) {
        messages[data.messageId] = {
          ...message,
          message: (message.message + data.chunk) as TranslatedText,
          ...(commands.length > 0 ? { commands } : {}),
          ...(attachments.length > 0 ? { attachments } : {})
        }
        callback({ success: true, messages: [messages[data.messageId]] })
      }
    }
  })
}
