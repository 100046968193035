import { breakpoint, H2, H3, styled, View } from '~/lite'

export const backgrounds = {
  purple: 'radial-gradient(circle at 50% center, rgb(34, 3, 42) 0%, rgb(0, 0, 0) 25%)',
  blue: 'radial-gradient(circle at 50% center, rgb(9, 40, 64) 0%, rgb(0, 0, 0) 25%)',
  pink: 'radial-gradient(circle at 50% center, rgb(60, 9, 55) 0%, rgb(0, 0, 0) 25%)',
  orange: 'radial-gradient(circle at 50% center, rgb(39, 12, 3) 0%, rgb(0, 0, 0) 25%)',
  yellow: 'radial-gradient(circle at 50% center, rgb(48, 46, 6) 0%, rgb(0, 0, 0) 25%)',
  orangePink: 'radial-gradient(circle at 50% center, rgb(43, 19, 0) 0%, rgb(0, 0, 0) 25%)'
}

export const Section = styled.section`
  padding-top: 32px;
`

export const SectionContent = styled(View)<{ $width?: 'normal' | 'wide' | 'full' }>`
  width: ${props => (props.$width === 'full' ? '100%' : '920px')};
  max-width: 100%;
  margin: 0 auto;
  justify-content: start;
  align-items: stretch;

  @media ${breakpoint.xl} {
    width: ${props => (props.$width === 'full' ? '100%' : props.$width === 'wide' ? '1100px' : '920px')};
  }
`

export const SectionHeader = styled(H2)`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 36px;
  margin: 32px 16px 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;

  ::selection {
    -webkit-text-fill-color: #fff;
  }

  @media ${breakpoint.md} {
    margin-top: 64px;
    font-size: 56px;
  }
`

export const SectionSubheader = styled(H3)`
  text-align: center;
  text-wrap: balance;
  color: ${props => (props.theme.dark ? '#b4bcd0' : props.theme.text)};
  margin: 0 16px 16px;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;

  @media ${breakpoint.md} {
    margin-bottom: 32px;
  }
`
