import { ClientRoutePath, TranslatedText, UntranslatedText } from 'core'
import React, { forwardRef, useCallback, useMemo } from 'react'
import type { Keyframes } from 'styled-components'
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  borderRadius,
  BorderRadiusProps,
  color,
  ColorProps,
  flex,
  flexbox,
  FlexboxProps,
  FlexProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps
} from 'styled-system'

import { trackEvent } from '../analytics/analytics'
import { ButtonAction } from '../analytics/events'
import { isWeb } from '../env'
import { useStore } from '../hooks'
import { useHistory } from '../navigation'
import { css, styled } from '../styled'
import { fixSpacePropsForNative } from '../styled'
import { StyledSystemTheme } from '../theme'
import { animations } from './animations'
import { Icon } from './Icon'
import { Image } from './Lazy'
import { Loader } from './Loader'
import { Pressable } from './Pressable'
import { Text } from './Text'
import { IIconProps, IPressableProps } from './types'
import { View } from './View'

export type IButtonTrackingProps =
  | { action?: never; skipTracking: true }
  | { action: ButtonAction; skipTracking?: never }

export interface IButtonBaseProps {
  testID?: string
  text?: UntranslatedText
  rawText?: TranslatedText
  numberOfTextLines?: number
  to?: ClientRoutePath
  href?: string
  onPress?(): void
  icon?: IIconProps['icon']
  rightIcon?: IIconProps['icon']
  onRightIconPress?(): void
  iconSize?: number
  loading?: boolean
  loadingText?: UntranslatedText
  disabled?: boolean
  skeleton?: boolean
  fill?: boolean
  color?: string
  children?: React.ReactNode

  // variants
  primary?: boolean
  minimal?: boolean
  active?: boolean
  dangerous?: boolean
  small?: boolean
  large?: boolean
  link?: boolean
  style?: IPressableProps['style']
}

type IUnstyledButtonProps = IButtonBaseProps & IButtonTrackingProps

export type IButtonProps = IUnstyledButtonProps &
  LayoutProps &
  SpaceProps<StyledSystemTheme> &
  BorderProps &
  BorderRadiusProps &
  PositionProps &
  FlexboxProps &
  FlexProps &
  ColorProps

const UnstyledButton = forwardRef<typeof Pressable, IUnstyledButtonProps>(
  (
    {
      action,
      skipTracking,
      testID,
      text,
      rawText,
      to,
      href,
      onPress,
      icon,
      rightIcon,
      onRightIconPress,
      iconSize,
      loading,
      loadingText,
      disabled,
      skeleton,
      fill,
      primary,
      minimal,
      active,
      dangerous,
      small,
      large,
      link,
      children,
      numberOfTextLines,
      color,
      ...props
    },
    forwardedRef
  ) => {
    const history = useHistory()
    const theme = useStore(state => state.theme)
    const darkMode = useStore(state => state.darkMode)

    const variants = [
      {
        active: primary,
        backgroundColor: theme.primary,
        hover: theme.primaryHover,
        textColor: '#ffffff',
        iconColor: '#ffffff'
      },
      {
        active: minimal && dangerous,
        backgroundColor: undefined,
        hover: undefined,
        textColor: theme.error,
        iconColor: theme.error
      },
      {
        active: minimal,
        backgroundColor: undefined,
        hover: undefined,
        textColor: color || theme.primary,
        iconColor: theme.primary
      },
      {
        active: dangerous,
        backgroundColor: theme.error,
        hover: undefined,
        textColor: '#ffffff',
        iconColor: '#ffffff'
      },
      {
        active: active === true,
        backgroundColor: theme.primary,
        hover: theme.primaryHover,
        textColor: '#ffffff',
        iconColor: '#ffffff'
      },
      {
        active: active === false,
        backgroundColor: theme.buttonBackground,
        hover: theme.buttonHover,
        textColor: theme.buttonColor,
        iconColor: theme.buttonColor
      }
    ]

    const { backgroundColor, hover, textColor, iconColor } = {
      backgroundColor: theme.buttonBackground,
      hover: theme.buttonHover,
      textColor: theme.buttonColor,
      iconColor: theme.dark ? theme.buttonColor : '#5c7080',
      ...variants.find(variant => variant.active)
    }

    const accessoryLeft = useMemo(() => {
      if (icon) {
        return () =>
          typeof icon === 'string' ? (
            <Icon icon={icon} color={color || iconColor} size={iconSize} />
          ) : (
            <Image height={20} width={20} marginRight="m" source={icon} resizeMode="contain" />
          )
      }
    }, [color, icon, iconColor, iconSize])

    const accessoryRight = useMemo(() => {
      if (rightIcon) {
        return () => <Icon icon={rightIcon} color={color || iconColor} size={iconSize} onPress={onRightIconPress} />
      }
    }, [rightIcon, color, iconColor, iconSize, onRightIconPress])

    const onPressHandler = useCallback(
      (e: any) => {
        if (e && 'preventDefault' in e) {
          e.preventDefault()
        }

        if (e && 'stopPropagation' in e) {
          e.stopPropagation()
        }

        if (action) {
          trackEvent('Press', { action, ...(text ? { text } : {}) })
        }

        onPress?.()

        if (to) {
          history.push(to)
        }
      },
      [onPress, to, history, action, text]
    )

    // @todo turn these into links once ssr is set up for the pages they link to
    // onPress={!disabled && !loading && !to ? onPressHandler : undefined}
    // {...(!disabled && !loading && to && { href: to, onPress: onPressHandler })}

    return (
      <Wrap
        ref={forwardedRef as any}
        testID={testID}
        onPress={!disabled && !skeleton && !loading ? onPressHandler : undefined}
        disabled={loading || disabled || skeleton}
        background={backgroundColor}
        position="relative"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        borderRadius={8}
        paddingX={small ? 's' : large ? 'l' : 'm'}
        {...(fill && { flex: 1, minHeight: 'auto' })}
        {...fixSpacePropsForNative(props as any)}
        $animation={
          skeleton ? (darkMode ? animations.skeletonLoadingDark : animations.skeletonLoadingLight) : undefined
        }
        $hover={disabled ? undefined : hover}
      >
        {children || (
          <>
            {!loading &&
              accessoryLeft &&
              (React.isValidElement(accessoryLeft) ? accessoryLeft : React.createElement(accessoryLeft))}
            {loading && (
              <View
                position={loadingText ? undefined : 'absolute'}
                marginLeft={loadingText ? -8 : 0}
                marginRight={loadingText ? 6 : 0}
                paddingY={loadingText ? undefined : small ? 'xs' : large ? 'm' : 6}
              >
                <Loader testID="button-loader" size="small" inline color={primary || dangerous ? 'white' : undefined} />
              </View>
            )}
            {(typeof rawText !== 'undefined' || typeof text !== 'undefined' || (loading && loadingText)) && (
              <Text
                color={color || textColor}
                paddingY={small ? 5 : large ? 12 : 's'}
                paddingLeft={accessoryLeft ? (small ? 'xxs' : large ? 's' : 'xs') : 'none'}
                paddingRight={accessoryRight ? (small ? 'xxs' : large ? 's' : 'xs') : 'none'}
                numberOfLines={numberOfTextLines}
                opacity={skeleton || (loading && !loadingText) ? 0 : 1}
                href={isWeb ? href ?? to : undefined}
                onPress={e => {
                  e.preventDefault()
                  return false
                }}
                {...((small || large) && { fontSize: small ? 12 : 16 })}
                {...(typeof rawText !== 'undefined'
                  ? { rawText }
                  : { text: (loading ? loadingText ?? text : text) ?? '' })}
              />
            )}
            {!loading &&
              accessoryRight &&
              (React.isValidElement(accessoryRight) ? accessoryRight : React.createElement(accessoryRight))}
          </>
        )}
      </Wrap>
    )
  }
)

export const Button = styled(UnstyledButton)<IButtonProps>`
  ${layout}
  ${position}
  ${flex}
  ${flexbox}
  ${space}
  ${border}
  ${borderRadius}
  ${color}
`

export const HeaderButton = styled(Button)`
  border-width: 0;
  height: 100%;
  ${!isWeb &&
  css`
    background-color: transparent;
  `}
`

export const EditButton = styled(Button)`
  align-self: flex-start;
`

export const BorderlessButton = styled(Button)`
  border-width: 0;
`

type WrapStyledProps = BackgroundProps & FlexboxProps & LayoutProps & SpaceProps<StyledSystemTheme> & BorderProps

const Wrap = styled(Pressable)<WrapStyledProps & { $animation?: Keyframes; $hover?: string }>`
  border-color: ${props => props.theme.inputBorderColor};
  transition: background 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  user-select: none;
  ${background}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${props =>
    props.$animation &&
    css`
      animation: ${props.$animation} 1s ease-in-out infinite alternate;
    `}
    ${props =>
    isWeb &&
    props.$hover &&
    css`
      &:hover {
        background: ${props.$hover};
      }
    `}

  a:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
  }
`
