import StickyBox from 'react-sticky-box'

import { breakpoint, styled, View } from '~/lite'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px 0;
`

export const FilterStickyBox = styled(StickyBox)`
  align-self: flex-end;
  display: none;
  @media ${breakpoint.lg} {
    display: flex;
  }
`

export const Content = styled(View)`
  width: 100%;
  overflow-y: auto;
  display: none;
  #job-detail-container {
    max-width: 100%;
  }
  @media ${breakpoint.lg} {
    display: flex;
  }
`

export const PositionListContainer = styled(View)`
  width: 100%;
`
