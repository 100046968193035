import { PositionFilterFormProps, usePositionFilterQueryParams } from '@ambition/module-form'
import { useQueryClient } from '@tanstack/react-query'
import {
  CollectionSlug,
  fixtures,
  getCombinedPositionQuery,
  ICollectionHeader,
  ICombinedPositionData,
  IMapBounds,
  IPosition,
  IPositionPartial,
  IPositionPartialDetail,
  IPositionQuery,
  IRecordStyle,
  TranslatedText
} from 'core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, ButtonContainer, FormGroup, HeaderText, IListProps, Input, List, Modal, View } from '~/components'
import {
  useBottomTabBarHeight,
  useCollection,
  useDeleteCollection,
  useHistory,
  usePositionQuery,
  usePositions,
  useStore,
  useTranslation,
  useUpdateCollection
} from '~/hooks'
import { isWeb } from '~/lite'
import { emptyString } from '~/util'

import { CollectionHeader } from './CollectionHeader'
import { CollectionToolbar } from './CollectionToolbar'
import { IPositionListItemProps, PositionListItem } from './PositionListItem'
import { CloseButton, DeleteButton, DeleteDialogBody, DeleteText, DialogBody, DialogHeader } from './styles'

const positionFixture = fixtures.getPositions(1).positions[0]

export interface IPositionListProps
  extends Pick<IListProps<IPositionPartial>, 'ListHeaderComponent' | 'bottomSheetProps'> {
  selectedId?: IPosition['id']
  context: IPositionListItemProps['context']
  collectionSlug: CollectionSlug
  collectionHeader: ICollectionHeader | null
  headerText: TranslatedText | null
  collectionStyle: IRecordStyle | null
  mapBounds?: IMapBounds | null
  onDataChange?(data: ICombinedPositionData | null, isLoading: boolean): void
  pageSize?: number
  showHeader?: boolean
  showTeamHeader?: boolean
  color?: string
  isUpdateModalOpen?: boolean
  setIsUpdateModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  isDeleteModalOpen?: boolean
  setIsDeleteModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  renderPositionFilter: (props: PositionFilterFormProps) => void
}

export const PositionList: React.FC<IPositionListProps> = ({
  selectedId,
  context,
  collectionSlug,
  collectionHeader,
  headerText,
  collectionStyle,
  mapBounds,
  onDataChange,
  pageSize = 30,
  color,
  showHeader = true,
  showTeamHeader = true,
  isUpdateModalOpen,
  setIsUpdateModalOpen,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  renderPositionFilter,
  ...listProps
}) => {
  const t = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const tabBarHeight = useBottomTabBarHeight()
  const user = useStore(state => state.user)
  const theme = useStore(state => state.theme)
  const currentTeam = useStore(state => state.currentTeam)
  const [collectionName, setCollectionName] = useState<TranslatedText>(emptyString)

  const { query, setQuery, collectionType } = usePositionQuery(collectionSlug)
  const { data, refetch: refetchCollection } = useCollection(collectionSlug)

  const { collection } = data ?? {}
  const formFilterParams = usePositionFilterQueryParams()

  const combinedPositionQuery = useMemo<IPositionQuery>(() => {
    return getCombinedPositionQuery(query, formFilterParams, mapBounds ?? null)
  }, [query, formFilterParams, mapBounds])

  const { combinedData, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, refetch } =
    usePositions(combinedPositionQuery)

  const { mutateAsync: updateCollectionAsync, isLoading: isUpdatingCollection } = useUpdateCollection({
    onSuccess: data => {
      if (data.success) {
        queryClient.invalidateQueries(['collections', 'private', user?.id])
      }
    }
  })
  const { mutateAsync: deleteCollectionAsync, isLoading: isDeletingCollection } = useDeleteCollection({
    onSuccess: data => {
      if (data.success) {
        queryClient.invalidateQueries(['collections', 'private', user?.id])
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (onDataChange) {
      onDataChange(combinedData, isLoading)
    }

    if (collection) {
      setCollectionName(collection.name)
    }
  }, [onDataChange, combinedData, isLoading, collection])

  const { positions } = combinedData ?? { positions: [] }

  const listHeader = useMemo(
    () =>
      showHeader && collectionType ? (
        <>
          {collectionHeader && (
            <CollectionHeader
              testID="collection-header"
              query={query}
              collectionType={collectionType}
              collectionSlug={collectionSlug}
              collectionHeader={collectionHeader}
              collectionStyle={collectionStyle}
              headerText={headerText}
              showTeamHeader={showTeamHeader}
              onHeaderIconClick={() => setIsUpdateModalOpen?.(true)}
              data={collection}
            />
          )}
          {!currentTeam && (
            <CollectionToolbar
              collectionSlug={collectionSlug}
              query={query}
              onChange={setQuery}
              renderPositionFilter={renderPositionFilter}
            />
          )}
        </>
      ) : (
        <></>
      ),
    [
      showHeader,
      showTeamHeader,
      collectionType,
      query,
      collectionSlug,
      collectionHeader,
      collectionStyle,
      headerText,
      collection,
      currentTeam,
      setQuery,
      setIsUpdateModalOpen,
      renderPositionFilter
    ]
  )

  const renderItem = useCallback<NonNullable<IListProps<IPositionPartialDetail>['renderItem']>>(
    ({ item: position }) => (
      <PositionListItem
        context={context}
        testID={`position-${position?.id}`}
        team={position.teamDetail}
        position={position || positionFixture}
        collectionSlug={collectionSlug}
        color={color}
        isSelected={selectedId === position?.id}
        openInNewWindow={isWeb && collectionType === 'Nearby'}
      />
    ),
    [collectionSlug, collectionType, color, context, selectedId]
  )

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage && fetchNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  const onUpdateCollectionButtonPress = async () => {
    if (!collection) {
      return
    }

    await updateCollectionAsync({ id: collection.id, name: collectionName })
    setIsUpdateModalOpen?.(false)
    refetchCollection()
  }

  const onDeleteCollectionButtonPress = async () => {
    if (!collection) {
      return
    }

    await deleteCollectionAsync(collection.id)

    setIsDeleteModalOpen?.(false)
    setIsUpdateModalOpen?.(false)

    history.push('/')
  }

  return (
    <View height="100%" backgroundColor={theme.listBackground}>
      <View height="100%">
        <List<IPositionPartialDetail>
          testID="position-list"
          data={positions}
          emptyText="No Jobs"
          emptyIcon="newspaper-outline"
          renderItem={renderItem}
          loadMore={loadMore}
          contentContainerStyle={{ paddingBottom: tabBarHeight }}
          isLoading={isLoading}
          isLoadingMore={isFetchingNextPage}
          ListHeaderComponent={listHeader}
          ListFooterComponent={
            currentTeam && (
              <Button
                action="NewPosition"
                testID="team-positions-button"
                text="Post a Job"
                to="/promote"
                icon="add"
                primary
                margin="m"
              />
            )
          }
          {...listProps}
        />
      </View>
      <Modal isOpen={!!isUpdateModalOpen} onClose={() => setIsUpdateModalOpen?.(false)} padding={0}>
        <DialogHeader>
          <CloseButton
            action="CancelEditCollection"
            icon="close"
            primary
            onPress={() => setIsUpdateModalOpen?.(false)}
          />
          <HeaderText text="Settings" />
          <DeleteButton
            action="DeleteCollection"
            text="Delete"
            primary
            dangerous
            onPress={() => setIsDeleteModalOpen?.(true)}
          />
        </DialogHeader>
        <DialogBody>
          <FormGroup label="Collection name" labelFor="name-input">
            <Input
              testID="collection-name-input"
              id="name-input"
              value={collectionName}
              onChange={text => setCollectionName(text)}
            />
          </FormGroup>
        </DialogBody>
        <ButtonContainer>
          <Button action="CancelEditCollection" text="Cancel" onPress={() => setIsUpdateModalOpen?.(false)} />
          <Button
            action="UpdateCollection"
            text="Save"
            loading={isUpdatingCollection}
            primary
            onPress={onUpdateCollectionButtonPress}
          />
        </ButtonContainer>
      </Modal>
      <Modal isOpen={!!isDeleteModalOpen} onClose={() => setIsDeleteModalOpen?.(false)} padding={0}>
        <DeleteDialogBody>
          <HeaderText text="Delete this list" />
          {collection && (
            <DeleteText
              rawText={t('Are you sure you want to delete "{{recordName}}"?', { recordName: collection.name })}
            />
          )}
        </DeleteDialogBody>
        <ButtonContainer>
          <Button
            action="CancelDeleteCollection"
            text="Cancel"
            minimal
            primary
            onPress={() => setIsDeleteModalOpen?.(false)}
          />
          <Button
            action="ConfirmDeleteCollection"
            text="Delete"
            minimal
            dangerous
            loading={isDeletingCollection}
            primary
            onPress={onDeleteCollectionButtonPress}
          />
        </ButtonContainer>
      </Modal>
    </View>
  )
}
