import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { BreadcrumbNode, IFeedSection, parseLandingPageParams, TranslatedText } from 'core'
import React, { useMemo, useState } from 'react'

import { ListItem, Text } from '~/components'
import { useCategoryPath, useFeedSectionDetail, useFeedSections, useStore } from '~/hooks'
import { Icon, isNotBot, useHistory, useOnHydrate, View } from '~/lite'

import { useRouteParams } from '../../../hooks/useRouteParams'
import { CollapsibleTrigger, Panel, Wrap } from '../styles'

const Collapsible = CollapsiblePrimitive.Root
const CollapsibleContent = CollapsiblePrimitive.Content

export const FeedSectionList: React.FC = () => {
  const { theme } = useStore(state => ({
    theme: state.theme
  }))
  const history = useHistory()
  const routeParams = useRouteParams({}) as any
  const { countryCode, landingPageSlug } = parseLandingPageParams(routeParams)
  const { params } = useCategoryPath({
    countryCode,
    landingPageSlug
  })
  const { sectionDetail: selectedSection } = useFeedSectionDetail(
    {
      feedSectionId: (params?.feedSectionIdSlug
        ? `fs-${params?.feedSectionIdSlug}`
        : params?.publicSectionIdSlug
        ? `ps-${params.publicSectionIdSlug}`
        : undefined) as IFeedSection['sectionId']
    },
    {
      excludePositions: '1'
    },
    !!params?.feedSectionIdSlug || !!params?.publicSectionIdSlug
  )
  const { sections: feedSections, isLoading } = useFeedSections({ limit: '10' }, isNotBot)
  const [open, setOpen] = useState(true)

  const initialNumToRender = 3

  useOnHydrate(() => {
    setOpen(false)
  })

  const selectedSectionName = selectedSection?.title || params?.sectionName
  const sectionList = useMemo(() => {
    if (!feedSections) {
      return []
    }
    return feedSections.filter(
      section => !params?.feedSectionIdSlug || section.sectionId?.toLowerCase() !== params?.feedSectionIdSlug
    )
  }, [feedSections, params])

  const renderListItem = ({
    key,
    item,
    skeleton,
    type,
    clickable
  }: {
    key?: string | number
    item?: BreadcrumbNode<IFeedSection>
    skeleton?: boolean
    type?: 'Selected' | 'Detail'
    clickable?: boolean
  }) => {
    if (skeleton) {
      return <ListItem key={key} variant="Menu" accessoryLeft={<Icon icon="location-outline" />} skeleton />
    }

    if (!item) {
      return null
    }

    if (!item.record && type !== 'Selected') {
      return null
    }

    const title = type === 'Selected' ? selectedSectionName : item.record?.title

    let icon = <Icon icon="location-outline" />
    if (type === 'Selected') {
      icon = <Icon icon="layers" />
    } else {
      icon = <Icon icon="layers-outline" />
    }

    return (
      <ListItem
        key={key}
        action={{ item: 'Locations' }}
        title={title as TranslatedText}
        accessoryLeft={icon}
        onPress={() => {
          if (clickable && item.record?.seeAllPath) {
            history.push(item.record.seeAllPath)
          }
        }}
        variant="Menu"
        selected={item.active}
      />
    )
  }

  const getSectionHeight = (isOpen: boolean, initialNumToRender: number): number => {
    const headerHeight = 31 + (selectedSectionName ? 42 : 0)
    const childCount = sectionList?.length || 0

    const rowCounts = {
      body: isOpen ? childCount : Math.min(childCount, initialNumToRender),
      footer: childCount > initialNumToRender ? 1 : 0
    }
    return headerHeight + (rowCounts.body + rowCounts.footer) * 42
  }

  const sectionHeight = getSectionHeight(open, initialNumToRender)

  if (sectionHeight === 0 || sectionList.length === 0) {
    return null
  }

  const renderSectionList = () => {
    return sectionList.length > initialNumToRender ? (
      <Collapsible open={open} onOpenChange={setOpen}>
        {sectionList
          .slice(0, initialNumToRender)
          .map((item, idx) => renderListItem({ item: { record: item }, key: idx, clickable: true }))}
        <CollapsibleContent>
          {sectionList
            .slice(initialNumToRender)
            .map((item, idx) => renderListItem({ item: { record: item }, key: idx, clickable: true }))}
        </CollapsibleContent>
        <CollapsibleTrigger style={{ paddingLeft: 36 }}>
          <Text text={open ? 'Show less' : 'Show more'} color={theme.primary} />
        </CollapsibleTrigger>
      </Collapsible>
    ) : (
      sectionList.map((item, idx) => renderListItem({ item: { record: item }, key: idx, clickable: true }))
    )
  }

  return (
    <View className="main-menu-list">
      <Wrap style={{ height: sectionHeight }}>
        <Panel>
          <Text fontSize={16} paddingX={8} paddingBottom={12} text="Sections customized just for you" />
          {selectedSectionName
            ? renderListItem({ key: -1, item: { record: null, active: true }, type: 'Selected' })
            : null}
          {isLoading &&
            Array(initialNumToRender)
              .fill(0)
              .map((_, skeletonIndex) => renderListItem({ key: skeletonIndex, skeleton: true }))}
          {renderSectionList()}
        </Panel>
      </Wrap>
    </View>
  )
}
