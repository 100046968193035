import { HtmlRenderer } from '@ambition/ui-html-renderer'
import { formatToISO, IApplicationField, markTranslated, SanitizedHtml } from 'core'
import { isBoolean } from 'lodash'
import React from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'

import { Checkbox, DatePicker, FormGroup, Input, Radio, Slider } from '~/components'
import { useBreakpoints, useTranslation } from '~/hooks'
import { Button, Select, styled, View } from '~/lite'
import { getScreenWidth } from '~/util'

import { CoverLetter } from './fields/CoverLetter'
import { File } from './fields/File'
import { Group } from './fields/Group'
import { Resume } from './fields/Resume'

const CheckboxWrap = styled(View)`
  flex-direction: column;
  padding: 8px 0;
`

const SliderWrap = styled(View)`
  flex-direction: row;
  align-items: stretch;
  padding: 3px 4px;
  gap: 4px;
  background: ${props => props.theme.inputBackground};
  border-radius: 20px;
  border: 1px solid ${props => props.theme.inputBorderColor};
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  input {
    font-variant-numeric: tabular-nums;
  }
`

const SliderButton = styled(Button)<{ $icon?: boolean }>`
  border-radius: 100px;
  height: 32px;
  min-width: 32px;
  padding: ${props => (props.$icon ? '0' : '0 8px')};
`

const SliderInput = styled(Input)`
  padding: 0;
  text-align: center;
  background: ${props => props.theme.buttonBackground};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

interface IDynamicFormComponentProps {
  question: IApplicationField
  control: UseControllerProps['control']
  error: string
  source: 'Application' | 'ScreenerFilter'
  onStudioButtonPress?(value: string): void
}

export const DynamicFormComponent: React.FC<IDynamicFormComponentProps> = ({
  question,
  control,
  error,
  source,
  onStudioButtonPress
}) => {
  const t = useTranslation()
  const { isPhone } = useBreakpoints()

  const maxLength = 'maxLength' in question ? question.maxLength : undefined

  const props = {
    control,
    name: question.id,
    rules: {
      required: { message: t('This field is required'), value: question.required },
      maxLength:
        typeof maxLength !== 'undefined'
          ? { message: t('Max length is {{count}} characters', { count: maxLength }), value: maxLength }
          : undefined
    }
  }

  switch (question.type) {
    case 'Text':
      return (
        <Controller
          {...props}
          render={({ field: { value, onChange, ...field } }) => {
            if (question.variant === 'CoverLetter') {
              return (
                <CoverLetter
                  error={error}
                  isRequired={question.required}
                  onChange={onChange}
                  question={question}
                  type="Text"
                  value={value}
                  onStudioButtonPress={onStudioButtonPress}
                />
              )
            }
            return (
              <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
                <Input {...field} onChange={onChange} value={value || ''} />
              </FormGroup>
            )
          }}
        />
      )
    case 'Number':
      return (
        <Controller
          {...props}
          render={({ field: { value, onChange, ...field } }) => {
            const minValue = question.minValue || 0
            const maxValue = Math.min(Math.max(minValue * 10, 10), 20)
            const stepValue = question.step || Math.max(1, Math.floor((maxValue - minValue) / 10))
            return (
              <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
                {source === 'Application' ? (
                  <Input {...field} value={value || ''} onChange={onChange} keyboardType="numeric" />
                ) : (
                  <SliderWrap>
                    <SliderButton text="Any" primary={!value} onPress={() => onChange(0)} skipTracking />
                    {!!minValue && (
                      <SliderButton
                        text={minValue}
                        primary={value === minValue}
                        onPress={() => onChange(minValue)}
                        skipTracking
                      />
                    )}
                    <View flex={1} justifyContent="center" mx={4}>
                      <Slider
                        value={value ? Math.max(0, Math.min(+value, maxValue)) : undefined}
                        min={0}
                        max={maxValue}
                        stepSize={stepValue}
                        onChange={e => {
                          if (Array.isArray(e)) {
                            onChange(e[0])
                          } else {
                            onChange(e)
                          }
                        }}
                        input
                      />
                    </View>
                    <SliderInput
                      width={50}
                      wrapStyle={{
                        borderWidth: 0,
                        borderRadius: 50,
                        overflow: 'hidden',
                        alignItems: 'stretch'
                      }}
                      {...field}
                      onChange={value => onChange(Math.max(0, +value))}
                      value={value || ''}
                      keyboardType="numeric"
                    />
                  </SliderWrap>
                )}
              </FormGroup>
            )
          }}
        />
      )
    case 'Email':
      return (
        <Controller
          {...props}
          render={({ field: { value, ...field } }) => (
            <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
              <Input {...field} value={value || ''} keyboardType="email-address" />
            </FormGroup>
          )}
        />
      )
    case 'TextArea':
      return (
        <Controller
          {...props}
          render={({ field: { value, ...field } }) => (
            <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
              <Input type="textarea" {...field} value={value || ''} numberOfLines={5} />
            </FormGroup>
          )}
        />
      )
    case 'Checkbox':
      return (
        <Controller
          {...props}
          defaultValue={[]}
          render={({ field: { value: rawValue, onChange } }) => {
            let value = rawValue
            if (!Array.isArray(value)) {
              onChange([])
              value = []
            }
            return (
              <FormGroup
                rawLabel={question.label}
                isRequired={question.label.length > 0 && question.options.length === 1 ? undefined : question.required}
                helperText={error}
                helperTextPosition="Top"
              >
                <CheckboxWrap>
                  {question.options.map(({ value: itemValue, label, isHtml }, index: number) => (
                    <Checkbox
                      key={index}
                      rawLabel={markTranslated(label)}
                      checked={value.includes(itemValue)}
                      labelComponent={
                        isHtml ? (
                          <View flex={1}>
                            <HtmlRenderer html={label as SanitizedHtml} contentWidth={getScreenWidth()} />
                          </View>
                        ) : undefined
                      }
                      onChange={() => {
                        const newValue = [...value]
                        const checkIndex = value.indexOf(itemValue)
                        if (checkIndex > -1) {
                          newValue.splice(checkIndex, 1)
                        } else {
                          newValue.push(itemValue)
                        }
                        onChange(newValue)
                      }}
                    />
                  ))}
                </CheckboxWrap>
              </FormGroup>
            )
          }}
        />
      )
    case 'Boolean':
      return (
        <Controller
          {...props}
          defaultValue={false}
          render={({ field: { value: rawValue, onChange } }) => {
            let value = rawValue
            if (!isBoolean(value)) {
              onChange(false)
              value = false
            }
            return (
              <FormGroup isRequired={question.required} helperText={error} helperTextPosition="Top">
                <Checkbox
                  rawLabel={markTranslated(question.label)}
                  checked={!!value}
                  onChange={() => {
                    onChange(!value)
                  }}
                />
              </FormGroup>
            )
          }}
        />
      )
    case 'Radio':
      return (
        <Controller
          {...props}
          render={({ field: { value: rawValue, onChange } }) => {
            let value = rawValue
            if (Array.isArray(value)) {
              onChange(undefined)
              value = undefined
            }
            return (
              <FormGroup
                rawLabel={question.label}
                isRequired={question.required}
                helperText={error}
                helperTextPosition="Top"
              >
                <Radio
                  options={question.options.map(({ label, ...option }) => ({
                    ...option,
                    rawLabel: markTranslated(label)
                  }))}
                  value={value}
                  onChange={onChange}
                />
              </FormGroup>
            )
          }}
        />
      )
    case 'Select':
      return (
        <Controller
          {...props}
          defaultValue={question.options[0]?.value || ''}
          render={({ field: { value: rawValue, onChange } }) => {
            let value = rawValue
            if (Array.isArray(value)) {
              onChange(question.options[0]?.value || '')
              value = question.options[0]?.value || ''
            }
            const options = question.options.map(({ value, label }) => ({ value, label: markTranslated(label) }))
            const selectedOption = options.find(option => option.value === value) || null
            return (
              <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
                <Select<typeof options[number]>
                  options={options}
                  value={selectedOption}
                  onChange={option => onChange(option?.value)}
                />
              </FormGroup>
            )
          }}
        />
      )
    case 'Date':
      return (
        <Controller
          {...props}
          render={({ field: { value, onChange } }) => (
            <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
              <DatePicker selected={value && new Date(value)} onChange={date => date && onChange(formatToISO(date))} />
            </FormGroup>
          )}
        />
      )
    case 'Group':
      return (
        <Controller
          {...props}
          render={({ field: { value, onChange } }) => <Group onChange={onChange} value={value} question={question} />}
        />
      )
    case 'Phone':
      return (
        <Controller
          {...props}
          defaultValue=""
          render={({ field: { value, ...field } }) => (
            <FormGroup rawLabel={question.label} isRequired={question.required} helperText={error}>
              <Input keyboardType="phone-pad" {...field} value={value || ''} />
              {/*<PhoneInput onChange={onChange} value={value} />*/}
            </FormGroup>
          )}
        />
      )
    case 'File':
      return (
        <Controller
          {...props}
          defaultValue=""
          render={({ field: { value, onChange } }) => {
            if (question.variant === 'Resume') {
              return (
                <Resume
                  error={error}
                  isRequired={question.required}
                  onChange={onChange}
                  question={question}
                  value={value}
                />
              )
            }

            if (question.variant === 'CoverLetter') {
              return (
                <CoverLetter
                  error={error}
                  isRequired={question.required}
                  onChange={onChange}
                  question={question}
                  type="File"
                  value={value}
                  onStudioButtonPress={onStudioButtonPress}
                />
              )
            }

            return (
              <File
                error={error}
                isRequired={question.required}
                onChange={onChange}
                question={question}
                value={value}
              />
            )
          }}
        />
      )
  }

  return null
}
