import {
  Collection as CollectionComponent,
  SkillCollections as SkillCollectionsComponent
} from '@ambition/module-collection'
import {
  Affiliate as AffiliateComponent,
  Api as ApiComponent,
  Business as BusinessComponent,
  Plus as PlusComponent,
  Promote as PromoteComponent,
  Screener as ScreenerWebsiteComponent
} from '@ambition/module-content'
import { Explore as ExploreComponent } from '@ambition/module-explore'
import { InstantSdkProfile as InstantSdkProfileComponent } from '@ambition/module-instantsdk'
import { LaunchpadProfile as LaunchpadProfileComponent } from '@ambition/module-marketplace'
import {
  JobDetail as JobDetailComponent,
  JobShort as JobShortComponent,
  Shorts as ShortsComponent
} from '@ambition/module-position'
import { Auth } from '@ambition/module-shared'
import { CompanyDetail as CompanyDetailComponent } from '@ambition/module-team'
import { parseLandingPageParams } from 'core'
import React from 'react'

import { Container, OAuth as OAuthComponent, WebView as WebViewComponent } from '~/lite'

import { useRouteParams } from '../hooks/useRouteParams'
import { Lazy } from './Lazy'
import { LocaleSettings as LocaleSettingsComponent } from './LocaleSettings'

// this is only used on native, so we can use the react-navigation route
const OAuth: React.FC<any> = ({ route }) => {
  const { url, headerTitle, userId, username, type, ref: invitationId } = route.params

  return (
    <OAuthComponent
      url={url}
      headerTitle={headerTitle}
      userId={userId}
      username={username}
      type={type}
      invitationId={invitationId}
    />
  )
}

const AuthForAI: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <Auth variant="ChatGPTOAuth" {...(params as any)} />
}

// this is only used on native, so we can use the react-navigation route
const WebView: React.FC<any> = ({ route }) => (
  <WebViewComponent type={route.params.type} url={route.params.url} headerTitle={route.params.headerTitle} />
)

const OccupationCollections: React.FC<any> = ({ route }) => (
  <Lazy.Collections groupSoc={route.params.groupSoc} forOccupations />
)

interface IScreenProps {
  route?: {
    params?: Record<string, any>
  }
}

export const generatedScreens = Object.entries(Lazy).reduce(
  (screens, [name, LazyComponent]) => ({
    ...screens,
    [name]: (props: IScreenProps) => {
      const params = useRouteParams(props)
      return <LazyComponent {...(params as any)} />
    }
  }),
  {} as { [K in keyof typeof Lazy]: React.FC<React.ComponentProps<any>> }
)

const GitHub: React.FC<IScreenProps> = props => {
  // when we build the callback url we encode some values as search params
  const params = useRouteParams(props, ['code', 'type', 'userId', 'username'])
  return <Lazy.GitHub {...(params as any)} />
}

const CompanyDetail: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)

  return (
    <Container>
      <CompanyDetailComponent {...(params as any)} />
    </Container>
  )
}

const JobDetail: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <JobDetailComponent {...(params as any)} />
}

const JobShort: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <JobShortComponent {...(params as any)} />
}

const InstantSdkProfileViaSubdomain: React.FC<{ subdomain: string }> = props => (
  <InstantSdkProfileComponent {...(props as any)} />
)

const LaunchpadProfileViaSubdomain: React.FC<{ subdomain: string }> = props => (
  <LaunchpadProfileComponent {...(props as any)} />
)

const Shorts: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <ShortsComponent {...(params as any)} />
}

const Collection: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <CollectionComponent {...(params as any)} />
}

const SkillCollections: React.FC<IScreenProps> = () => <SkillCollectionsComponent />

const Explore: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props) as any
  const { countryCode, landingPageSlug } = parseLandingPageParams(params)
  return <ExploreComponent countryCode={countryCode} landingPageSlug={landingPageSlug} />
}

const Affiliate: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <AffiliateComponent {...(params as any)} />
}

const Api: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <ApiComponent {...(params as any)} />
}

const Business: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <BusinessComponent {...(params as any)} />
}

const ScreenerWebsite: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <ScreenerWebsiteComponent {...(params as any)} />
}

const LocaleSettings: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <LocaleSettingsComponent variant="Screen" {...(params as any)} />
}

const Plus: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <PlusComponent {...(params as any)} />
}

const Promote: React.FC<IScreenProps> = props => {
  const params = useRouteParams(props)
  return <PromoteComponent {...(params as any)} />
}

export const Screen = {
  ...generatedScreens,
  Affiliate,
  Api,
  Business,
  LocaleSettings,
  Plus,
  Promote,
  ScreenerWebsite,
  CompanyDetail,
  Explore,
  Collection,
  JobDetail,
  JobShort,
  InstantSdkProfileViaSubdomain,
  LaunchpadProfileViaSubdomain,
  Shorts,
  GitHub,
  OccupationCollections,
  SkillCollections,
  OAuth,
  AuthForAI,
  PositionPreview: () => <Lazy.PositionPreview Form={PositionFormStack} />,
  // ProfilePreview,
  Search: () => <Lazy.Search />,
  TeamSettings: () => <Lazy.TeamSettings forCurrentTeam />,
  WebView
}

// @todo decide if we want to implement this or not (difficult to migrate to react-router)
const PositionFormStack: React.FC = () => {
  return null
}

/*
const ProfilePreview: React.FC<IProfilePreviewProps> = () => <Lazy.ProfilePreview Form={ProfileFormStack} />

import type { ILocationOption } from '@ambition/module-shared'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createStackNavigator } from '@react-navigation/stack'
import { INewPosition, IPosition } from 'core'
import React, { useState } from 'react'
import { Host } from 'react-native-portalize'

import { useTranslation } from '~/hooks'
import { isWeb } from '~/util'

import { useStackOptions } from '../../hooks/useStackOptions'
import { Screen } from '../Screen'
import { PositionFormStackParamList } from './types'

const Stack = isWeb
  ? createStackNavigator<PositionFormStackParamList>()
  : createNativeStackNavigator<PositionFormStackParamList>()

export interface IPositionFormStackProps {
  positionId?: IPosition['id']
  onChange(team: IPosition | INewPosition): void
}

const SelectLocation: React.FC<Pick<ISelectLocationProps, 'onSelect'>> = ({ onSelect }) => (
  <Lazy.SelectLocation onSelect={onSelect} />
)

const EditPosition: React.FC<any & Omit<IEditPositionProps, 'positionId'>> = ({ route, location, onChange }) => (
  <Lazy.EditPosition positionId={route.params.positionId} location={location} onChange={onChange} />
)

export const PositionFormStack: React.FC<IPositionFormStackProps> = ({ onChange }) => {
  const { headerShown } = useStackOptions()
  const t = useTranslation()

  const [location, setLocation] = useState<ILocationOption | null>(null)

  return (
    <Host>
      <Stack.Navigator screenOptions={{ headerShown, fullScreenGestureEnabled: true }}>
        <Stack.Screen
          name="NewPosition"
          options={{ title: t('Post a Job'), presentation: 'modal', headerShown: false }}
        >
          {props => <Screen.NewPosition {...props} location={location} onChange={onChange} />}
        </Stack.Screen>
        <Stack.Screen name="EditPosition" options={{ title: t('Edit Position'), headerShown: false }}>
          {props => <Screen.EditPosition {...props} onChange={onChange} location={location} />}
        </Stack.Screen>
        <Stack.Screen name="PromotePosition" options={{ title: t('Promote Position') }}>
          {props => <Screen.PromotePosition {...props} onChange={onChange} />}
        </Stack.Screen>
        <Stack.Screen name="SelectLocation" options={{ title: t('Select location') }}>
          {props => <Screen.SelectLocation {...props} onSelect={setLocation} />}
        </Stack.Screen>
      </Stack.Navigator>
    </Host>
  )
}
*/
