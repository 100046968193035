import React from 'react'

import { useStore } from '~/hooks'

import { View, ViewProps } from '../View/View'

type ListDividerProps = ViewProps

export const ListDivider = (props: ListDividerProps) => {
  const theme = useStore(state => state.theme)
  return <View height={1} backgroundColor={theme.border} {...props} />
}
