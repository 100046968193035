import { IMessage, IUser, TranslatedText } from 'core'
import { compact } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import urlRegexSafe from 'url-regex-safe'

import { IMenuProps, Menu, Text, URLPreview, View } from '~/components'
import { useTranslation, useURLPreview } from '~/hooks'
import { isWeb } from '~/lite'
import { copyToClipboard } from '~/util'

import { AgentCommand } from './AgentCommand'
import { Attachment } from './Attachment'
import { Media } from './Media'
import { MessageBody, MessageLoader, MessageLoaderBubble, MessageMarkdown, MessageText, MessageWrap } from './styles'

interface IMessageProps {
  message: IMessage
  currentUserId: IUser['id'] | null
  hideTime?: boolean
}

export const Message: React.FC<IMessageProps> = ({ message, currentUserId, hideTime }) => {
  const t = useTranslation()
  const [mediaUrl, setMediaUrl] = useState<string | null>(null)
  const [mediaDownloadFailed, setMediaDownloadFailed] = useState<boolean>(false)

  const isOwn = !!currentUserId && message.userId === currentUserId

  const urlsFromMessage = useMemo<string[]>(() => {
    const urls: string[] = []
    const matches = message.message.match(urlRegexSafe()) || []

    for (const match of matches) {
      match && urls.push(match)
    }

    return urls
  }, [message.message])
  const { data: previews } = useURLPreview(urlsFromMessage)

  useEffect(() => {
    if (message.attachmentUrl) {
      setMediaUrl(message.attachmentUrl)
    }
  }, [message.attachmentUrl])

  const menuItems = useMemo<IMenuProps['items']>(
    () => [
      {
        testID: `message-action-copy-${message.id}`,
        text: 'Copy',
        icon: 'copy-outline',
        onPress: () => copyToClipboard(message.message)
      }
      /*...(isMyMessage
        ? [
            {
              testID: 'message-action-delete',
              text: t('Delete'),
              icon: 'trash-outline',
              onPress: () => {}
            }
          ]
        : [])*/
    ],
    [message]
  )

  const inner = (
    <>
      {mediaUrl && (
        <View>
          <Media hasFailed={mediaDownloadFailed} url={mediaUrl} />
        </View>
      )}
      {message.commands?.map((command, idx) => (
        <AgentCommand key={idx} command={command} />
      ))}
      <View flex={isWeb ? 1 : undefined} width="100%" display="flex" flexDirection="row" alignItems="center">
        {!!(message.message || message.attachments?.length) &&
          (message.role === 'Greeting' && !isWeb ? null : (
            <MessageBody $isOwn={isOwn}>
              {!!message.message &&
                (message.role === 'Assistant' ? (
                  <MessageMarkdown $isOwn={isOwn}>{message.message}</MessageMarkdown>
                ) : (
                  <MessageText rawText={message.message} $isOwn={isOwn} />
                ))}
              {message.attachments?.map((attachment, idx) => (
                <Attachment key={idx} attachment={attachment} />
              ))}
            </MessageBody>
          ))}
        {message.message === ('' as TranslatedText) &&
          !message.commands?.length &&
          !message.attachments?.length &&
          message.role === 'Assistant' &&
          (message.state === 'Ghost' || message.state === 'Pending') && (
            <MessageBody $isOwn={isOwn}>
              <MessageLoader>
                <MessageLoaderBubble $index={0} />
                <MessageLoaderBubble $index={1} />
                <MessageLoaderBubble $index={2} />
              </MessageLoader>
            </MessageBody>
          )}
        {!hideTime && (
          <Text rawText={t.formatDate(message.createdAt, { timeStyle: 'short' })} marginX={18} appearance="hint" />
        )}
      </View>
    </>
  )

  return (
    <MessageWrap $isOwn={isOwn} disabled={isWeb}>
      {previews && compact(previews).length > 0 && (
        <View flexDirection="column" alignItems="flex-end" minHeight={200}>
          {compact(previews).map(preview => (
            <URLPreview key={preview.url} preview={preview} />
          ))}
        </View>
      )}
      {isWeb ? (
        <View flex={1}>{inner}</View>
      ) : (
        <Menu items={menuItems} testID={`message-body-${message.id}`} openOnHold>
          {inner}
        </Menu>
      )}
    </MessageWrap>
  )
}
