import { Flex, ListItem, Markdown, styled, Text, View } from '~/components'
import { H3, H4, keyframes, Pressable } from '~/lite'
import { isWeb } from '~/util'

export const ChannelWrap = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
`

export const DialogBody = styled(View)`
  padding: 20px;
`

export const MessageWrap = styled(ListItem)<{ $isOwn: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.$isOwn ? 'flex-end' : 'flex-start')};
  padding-right: ${props => (props.$isOwn ? 16 : 48)}px;
  padding-left: ${props => (props.$isOwn ? 48 : 16)}px;
`

export const MessageBody = styled(View)<{ $isOwn: boolean }>`
  background-color: ${props => (props.$isOwn ? props.theme.primary : props.theme.menuItemBackground)};
  margin: 4px 0;
  padding: 8px 12px;
  border-radius: 12px;
  align-items: center;
  position: relative;
  max-width: 100%;
`

export const MessageText = styled(Text)<{ $isOwn: boolean }>`
  font-size: 16px;
  color: ${props => (props.$isOwn ? '#ffffff' : props.theme.color)};
`

export const MessageMarkdown = styled(Markdown)<{ $isOwn: boolean }>`
  font-size: 16px;
  color: ${props => (props.$isOwn ? '#ffffff' : props.theme.color)};
`

export const TypingIndicatorContainer = styled(View)<{ $isActive: boolean }>`
  display: ${props => (props.$isActive ? 'flex' : 'none')};
  flex: none;
  align-items: flex-end;
  margin: 4px 0;
`

export const TypingIndicatorBubble = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 32px;
  margin: 0 8px;
  background-color: #f0f1f1;
  border-radius: 12px;
`

export const TypingIndicatorBubbleDot = styled(View)`
  width: 4px;
  height: 4px;
  margin-right: 4px;
  background-color: #57585a;
  border-radius: 20px;
`

const fadeInOut = keyframes`
  40% {
    opacity: 0.2;
  }

  0, 80%, 100% {
    opacity: 0.8;
  }
`

export const MessageLoader = styled(View)`
  flex-direction: row;
  margin: 4px 0;
`

export const MessageLoaderBubble = styled(View)<{ $index: number }>`
  animation-fill-mode: both;
  animation: ${fadeInOut} 1.8s infinite ease-in-out;
  animation-delay: ${props => (2 - props.$index) * -0.16}s;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: ${isWeb ? '50%' : 100};
  background-color: ${props => props.theme.text};
`

export const AttachmentWrap = styled(Pressable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 8px;
  border-radius: 12px;
  padding: 12px 16px;
  background: ${props => props.theme.cardBackground};

  ${MessageLoader} {
    margin: 4px auto;
  }
`

export const AgentCommandWrap = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 0 12px 4px;
`

export const AgentCommandIcon = styled(View)`
  width: 20px;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
`

export const AttachmentTitle = styled(H3)``

export const AttachmentSubtitle = styled(H4)``
