import { compact } from 'lodash'

import { CompensationFrequency, CurrencyCode, ICompensation } from '../models'
import { markTranslated, SupportedLocaleCode, TranslatedText } from './i18n'

const zeroDecimalRe = /(\.|,|·|')0+$/g

export const formatCurrency = (
  locale: SupportedLocaleCode,
  currency: CurrencyCode,
  value: number,
  noDecimals?: boolean
): TranslatedText => {
  try {
    return markTranslated(
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        ...(noDecimals && { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      }).format(value)
    )
  } catch {
    return markTranslated(`${value} ${currency}`)
  }
}

export const getCurrencyFns = (
  locale: SupportedLocaleCode,
  formatCompensationFrequency: (amount: TranslatedText, frequency: CompensationFrequency) => TranslatedText
) => {
  const formatCompensation = (compensation: ICompensation): TranslatedText | null => {
    const { currency, frequency } = compensation
    let { min, max } = compensation
    let showThousandsMark = false

    if (!currency || (min === null && max === null) || frequency === null) {
      return null
    }

    if (locale === 'en-US' && currency === 'USD' && (min === null || min >= 10000) && (max === null || max >= 10000)) {
      if (min !== null) {
        min = Math.floor(min / 1000)
      }

      if (max !== null) {
        max = Math.floor(max / 1000)
      }

      showThousandsMark = true
    }

    const minAmount = min === null ? null : formatCurrency(locale, currency, min).replace(zeroDecimalRe, '')
    const maxAmount = max === null ? null : formatCurrency(locale, currency, max).replace(zeroDecimalRe, '')

    let formattedAmount = markTranslated(
      compact([
        minAmount === maxAmount ? (minAmount as string) : [minAmount, maxAmount].filter(v => v !== null).join(' - '),
        showThousandsMark ? 'k' : null,
        minAmount === maxAmount || !maxAmount ? '+' : null
      ]).join('')
    )

    // "$15 - $20" -> "$15-20"
    formattedAmount = markTranslated(
      formattedAmount.replace(/\$([^-]+)\s-\s\$(.*)/g, (_, min, max) => `$${min}-${max}`)
    )

    return formatCompensationFrequency(formattedAmount, frequency)
  }

  return { formatCompensation }
}

export type CurrencyFns = ReturnType<typeof getCurrencyFns>

// these are the currencies we support displaying / charging people in.
// based on Netflix's currency in each country.
export const presentmentCurrencies = [
  'AED',
  'ARS',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KES',
  'KRW',
  'MAD',
  'MXN',
  'MYR',
  'NGN',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'TWD',
  'USD',
  'VND',
  'ZAR'
] as const

export type SupportedPresentmentCurrency = typeof presentmentCurrencies[number]
