import React from 'react'
import { background, BackgroundProps, size, SizeProps } from 'styled-system'

import { styled } from '../styled'
import { ISvgProps } from './types'
import { View } from './View'

// ...(hasStyle ? pick(skillStyle, 'filter') : {})
// setting the color of an svg is difficult on the web...
// can't be set if background-image or object, and loading via ajax or mask-image runs into CORS issues
// so generating the filter from the color using https://codepen.io/sosuke/pen/Pjoqqp (pressing until ~0.1 loss)
const filters: Record<string, string> = {
  '#ffffff': 'invert(100%) brightness(100%)',
  '#000000': '',
  '#0e103c': 'invert(9%) sepia(17%) saturate(6719%) hue-rotate(224deg) brightness(92%) contrast(106%)',
  '#182026': 'invert(8%) sepia(43%) saturate(412%) hue-rotate(163deg) brightness(97%) contrast(91%)',
  '#6360ff': 'invert(36%) sepia(58%) saturate(3982%) hue-rotate(229deg) brightness(102%) contrast(101%)',
  '#5c7080': 'invert(46%) sepia(10%) saturate(961%) hue-rotate(165deg) brightness(89%) contrast(83%)',
  '#8a8d92': 'invert(58%) sepia(6%) saturate(247%) hue-rotate(178deg) brightness(96%) contrast(87%);',
  '#b6bac0': 'invert(86%) sepia(11%) saturate(133%) hue-rotate(177deg) brightness(87%) contrast(86%);',
  '#34b792': 'invert(58%) sepia(16%) saturate(1551%) hue-rotate(112deg) brightness(101%) contrast(87%)',
  '#ff656d': 'invert(48%) sepia(17%) saturate(1694%) hue-rotate(308deg) brightness(109%) contrast(115%)',
  '#00aa55': 'invert(35%) sepia(95%) saturate(1903%) hue-rotate(130deg) brightness(97%) contrast(102%)',
  '#00c853': 'invert(42%) sepia(94%) saturate(1415%) hue-rotate(114deg) brightness(104%) contrast(101%)',
  '#facc14': 'invert(67%) sepia(82%) saturate(475%) hue-rotate(2deg) brightness(106%) contrast(96%)',
  '#1a74e8': 'invert(43%) sepia(98%) saturate(4305%) hue-rotate(203deg) brightness(95%) contrast(91%)',
  '#e0372c': 'invert(32%) sepia(48%) saturate(3439%) hue-rotate(343deg) brightness(89%) contrast(98%)',
  '#34a854': 'invert(53%) sepia(70%) saturate(430%) hue-rotate(84deg) brightness(89%) contrast(89%)',
  '#88add2': 'invert(65%) sepia(19%) saturate(590%) hue-rotate(169deg) brightness(99%) contrast(93%)'
}

const WebImage = styled(View)<BackgroundProps & SizeProps & { $filter?: string }>`
  ${size}
  ${background}
  ${props => (props.$filter ? `filter: ${props.$filter};` : '')}
`

export const Svg: React.FC<ISvgProps> = ({ uri, xml, color, width = '100%', height = '100%', ...props }) => {
  const hexColor = color
    ? { '#fff': '#ffffff', '#000': '#000000', white: '#ffffff', black: '#000000' }[color] ?? color
    : null
  const filter = hexColor ? filters[hexColor] : undefined

  if (hexColor && typeof filter === 'undefined') {
    throw new Error(`No filter for svg color ${color}. Please add to filters object`)
  }

  if (!uri && !xml) {
    return null
  }

  const scClassName = (props as { className?: string }).className
  const className = scClassName ? `web-image ${scClassName}` : 'web-image'

  return (
    <WebImage
      {...props}
      {...(uri && { backgroundImage: `url(${uri})` })}
      {...(xml && { dangerouslySetInnerHTML: { __html: xml } })}
      $filter={filter}
      className={className}
      width={width}
      height={height}
      paddingBottom={width === '100%' && height === '100%' && !xml ? '100%' : undefined}
    />
  )
}
