import { PositionFilterForm, positionFilterOffset } from '@ambition/module-form'
import {
  Breadcrumb,
  CategorySlug,
  IOccupation,
  LANDING_PAGE_POSITION_PAGE_LIMIT,
  Mutable,
  ReadonlyObjectDeep
} from 'core'
import { compact } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { View } from '~/components'
import { useCategoryPath, useLandingPage } from '~/hooks'
import { breakpoint, MetaTags, styled, useStore } from '~/lite'

import { SectionList } from './SectionList'
import { SectionListProps } from './SectionList/types'
import { ILandingPageProps } from './types'

const SectionWrap = styled(View)`
  padding: 0 16px;

  @media ${breakpoint.md} {
    padding: 0;
  }
`

export const LandingPage: React.FC<ILandingPageProps> = ({ countryCode, landingPageSlug }) => {
  const selectedLocation = useStore(store => store.selectedLocation)
  const hydrated = useStore(store => store.hydrated)
  const setOccupationNavigation = useStore(store => store.setOccupationNavigation)
  const { data, isLoading, hasNextPage, fetchNextPage } = useLandingPage(
    {
      countryCode,
      slug: landingPageSlug,
      locationId: countryCode && landingPageSlug ? undefined : selectedLocation?.locationId,
      excludePositions: 1,
      page: 0,
      limit: LANDING_PAGE_POSITION_PAGE_LIMIT // only affects sub pages
    },
    hydrated
  )
  const [occupations, setOccupations] = useState<Breadcrumb<ReadonlyObjectDeep<Mutable.Occupation>> | undefined>()

  useEffect(() => {
    setOccupations(undefined)
  }, [landingPageSlug])

  const { getCategoryPath, params } = useCategoryPath({
    countryCode,
    landingPageSlug
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [landingPageSlug])

  const isRootPage =
    !params?.categorySlug && !params?.locationSlug && !params?.feedSectionIdSlug && !params?.publicSectionIdSlug
  const firstPage = data?.pages?.[0].results

  useEffect(() => {
    if (!occupations && firstPage?.occupations) {
      setOccupations(firstPage?.occupations)
    }
  }, [firstPage?.occupations, occupations])

  // const occupations = firstPage?.occupations
  // const header = firstPage?.header
  const pageTitle = firstPage?.pageTitle

  const sections = React.useMemo(
    () => (data?.pages ? compact(data.pages.flatMap(group => group?.results?.sections)) : undefined),
    [data?.pages]
  )

  const occupationBreadcrumb = useMemo<Breadcrumb<IOccupation> | null>(() => {
    if (!occupations) {
      return null
    }

    const adjustItem = (item: Breadcrumb<IOccupation>['items'][number]): Breadcrumb<IOccupation>['items'][number] => {
      const children: Breadcrumb<IOccupation>['items'][number]['children'] = item.children
        ? item.children.map(adjustItem)
        : item.children

      if (item.record) {
        const categorySlug = item.record.slug as unknown as CategorySlug
        const path = getCategoryPath(categorySlug)
        return { ...item, record: { ...item.record, path }, active: params?.categorySlug === categorySlug, children }
      }

      return { ...item, children }
    }

    return {
      ...occupations,
      items: occupations.items.map(item => adjustItem(item))
    }
  }, [getCategoryPath, occupations, params?.categorySlug])

  const renderSectionList = () => {
    const sectionListProps: SectionListProps = {
      data: sections,
      isLoading,
      hasNextPage: isRootPage ? false : hasNextPage,
      fetchNextPage: isRootPage ? undefined : fetchNextPage,
      showPositionSectionTitle: true,
      queryKey: data?.pages?.[0].queryKey,
      isRootPage
    }
    return (
      <SectionWrap marginTop={positionFilterOffset}>
        <SectionList {...sectionListProps} />
      </SectionWrap>
    )
  }

  useEffect(() => {
    setOccupationNavigation({ breadcrumb: occupationBreadcrumb, isLoading, rootPath: getCategoryPath(null) })
  }, [occupationBreadcrumb, isLoading, setOccupationNavigation, getCategoryPath])

  // const user = useStore(state => state.user)
  // const { data: profileResume } = useProfileResume(user?.username || null)
  // {!countryCode && <ExploreHeader isAuthenticated={!!user?.id} avatar={profileResume?.basics?.image} />}

  return (
    <View flexDirection="row" alignItems="flex-start">
      {pageTitle && <MetaTags type="LandingPage" headerText={pageTitle} />}
      <View className="explore-body">
        {/*<PhoneView>
          <OccupationGrid breadcrumb={occupationBreadcrumb} isLoading={isLoading} />
         </PhoneView>*/}
        <PositionFilterForm />
        {renderSectionList()}
      </View>
    </View>
  )
}
