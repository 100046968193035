import { styled } from '~/lite'
import { breakpoint } from '~/util'

export const SearchResult = styled.div`
  position: relative;
  background-color: ${props => props.theme.background};
  max-height: 70vh;
  width: 100vw;
  margin: 0 auto;
  overflow: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow-y: scroll;
  @media ${breakpoint.md} {
    overflow-y: auto;
    width: 500px;
    max-height: 80vh;
  }
  @media ${breakpoint['2xl']} {
    width: 700px;
  }
`

export const CitySelectContainer = styled.div`
  padding: 10px;
  .react-select {
    width: 100%;
  }
`
