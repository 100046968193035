import type { ClientRoutePath, UntranslatedText } from 'core'

export const pageTitles: Record<ClientRoutePath, UntranslatedText> = {
  '/': 'Explore',
  '/affiliate': 'Affiliate',
  '/api': 'Jobs API',
  '/analyze': 'Analyze CV',
  '/arena': 'Talent Arena',
  '/pipeline': 'Talent Pipeline',
  '/autopilot': 'Autopilot',
  '/screener': 'CV Screener',
  '/TeamUsername': '{{companyName}}' as UntranslatedText,
  '/collections/applied': 'My Applications',
  '/collections?groupSoc=Soc2018Code&title=TranslatedText': '{{occupationName}} Jobs',
  '/collections/CollectionSlug': '{{collectionName}} Jobs',
  '/collections/CollectionSlug/jobs/Position': '{{collectionName}} Jobs',
  '/collections/CollectionSlug/jobs/TalentJobId': '{{collectionName}} Jobs',
  '/collections/Collection': '{{collectionName}} Jobs',
  '/collections/Collection/jobs/Position': '{{collectionName}} Jobs',
  '/collections/Collection/jobs/TalentJobId': '{{collectionName}} Jobs',
  '/collections/SkillSlug': '{{skillName}} Jobs',
  '/collections/SkillSlug/jobs/Position': '{{skillName}} Jobs',
  '/collections/SkillSlug/jobs/TalentJobId': '{{skillName}} Jobs',
  '/collections/Soc2018Code-string-jobs': '{{occupationName}} Jobs',
  '/collections/countries-CountryCode': '{{locationName}} Jobs',
  '/collections/locations-LocationSlug': '{{locationName}} Jobs',
  '/collections/teams-TeamUsername': '{{companyName}} Jobs',
  '/jobs': 'Dashboard',
  '/jobs?EncodedParams': 'Dashboard',
  '/dashboard/teams/Team': 'Dashboard',
  '/dashboard/teams/Team/jobs/Position': 'Dashboard',
  '/dashboard/teams/Team/jobs/Position/applications/ScreenerResume': 'Dashboard',
  '/github': 'GitHub' as UntranslatedText,
  '/github?EncodedParams': 'GitHub' as UntranslatedText,
  '/github/Invitation': 'GitHub' as UntranslatedText,
  '/github/Invitation?EncodedParams': 'GitHub' as UntranslatedText,
  '/interview': 'Interview',
  '/select-language': 'Select Language',
  '/locations': 'Top Locations',
  '/subscriptions': 'Subscriptions',
  '/launchpad': 'Launchpad',
  '/launchpad/MarketProfile': 'Launchpad',
  '/launchpad/MarketProfile/about': 'Launchpad',
  '/launchpad/MarketProfile/deliverables': 'Launchpad',
  '/launchpad/MarketProfile/testimonials': 'Launchpad',
  '/launchpad/MarketProfile/faq': 'Launchpad',
  '/launchpad/MarketProfile/general': 'Launchpad',
  '/launchpad/MarketProfile/hero': 'Launchpad',
  '/launchpad/MarketProfile/plans': 'Launchpad',
  '/launchpad/MarketProfile/products': 'Launchpad',
  '/launchpad/MarketProfile/setup': 'Launchpad',
  '/login': 'Log In',
  '/login/NotificationSecret': 'Log In',
  '/marketplace': 'Marketplace',
  '/messages': 'Messages',
  '/messages/Channel': 'Conversation',
  '/phone-auth': '',
  '/plus': 'Ambition Plus' as UntranslatedText,
  '/promote': 'Promote',
  '/collections/recommendation': 'Recommended for You',
  '/collections/trending': 'Trending Now',
  '/collections/related': 'More jobs like this',
  '/collections/related/jobs/Position': 'More jobs like this',
  '/collections/related/jobs/TalentJobId': 'More jobs like this',
  '/collections/education-based-jobs': '{{jobCategory}} jobs in {{location}}',
  '/collections/occupation-based-jobs': '{{jobCategory}} jobs in {{location}}',
  '/collections/recently-visited': 'Recent visits',
  '/collections/new-jobs': '{{jobCategory}} jobs in {{location}}',
  '/collections/trending-from-location': '{{jobCategory}} jobs in {{location}}',
  '/positions/recommendation': 'Recommended for You',
  '/positions/trending': 'Trending Now',
  '/positions/related/Position': 'More jobs like this',
  '/positions/related': 'More jobs like this',
  '/positions/education-based-jobs': '{{jobCategory}} jobs in {{location}}',
  '/positions/occupation-based-jobs': '{{jobCategory}} jobs in {{location}}',
  '/positions/recently-visited': 'Recent visits',
  '/positions/new': 'New Position',
  '/positions/new-jobs': '{{jobCategory}} jobs in {{location}}',
  '/positions/trending-from-location': '{{jobCategory}} jobs in {{location}}',
  '/positions/Position/edit': 'Edit Position',
  '/positions/Position/promote': 'Promote Position',
  '/positions/Position/promote/StripeProduct': 'Promote Position',
  '/positions/select-location': 'Select location',
  '/privacy': 'Privacy',
  '/recover-password': 'Recover Password',
  '/search': 'Search',
  '/select-country-code': 'Select region',
  '/signup': 'Sign Up',
  '/sitemap': 'Sitemap',
  '/sitemap/SitemapSlug': 'Sitemap',
  '/studio': 'Cover Letter Studio',
  '/confirm/NotificationSecret': 'Confirmation',
  '/teams/TeamUsername': '{{companyName}}' as UntranslatedText,
  '/teams/TeamUsername/jobs': '{{companyName}} Jobs',
  '/teams/applications': 'Applications',
  '/teams/new': 'New Team',
  '/teams/edit': 'Edit Team',
  '/teams/statuses': 'Application Statuses',
  '/teams/messages/Channel': 'Conversation',
  '/teams/profile/UserUsername': 'Profile',
  '/terms': 'Terms',
  '/user/account': 'Account',
  '/user/teams': 'Teams',
  '/user/profile/UserUsername': '{{userName}}' as UntranslatedText,
  '/user/profile': 'Edit Profile',
  '/user/profile/edit': 'Edit Profile',
  '/user/profile/edit/company': 'Select a Company',
  '/user/profile/edit/university': 'Select a University',
  '/user/delete': 'Delete Account',
  '/jobs/Position': '{{jobTitle}} at {{companyName}}',
  '/jobs/JoinJobInternalId': '{{jobTitle}} at {{companyName}}',
  '/jobs/TalentJobId': '{{jobTitle}} at {{companyName}}',
  '/jobs/Position/interview': 'Interview',
  '/shorts/Position': '{{jobTitle}} at {{companyName}}',
  '/shorts/TalentJobId': '{{jobTitle}} at {{companyName}}',
  '/shorts': 'Jobs',
  '/CountryCode/LandingPageSlug': 'Explore',
  '/LandingPageSlug': 'Explore'
}
