import type { IMapItem, IMapProps } from '@ambition/ui-map'
import React, { Suspense } from 'react'

import { Loader } from '~/components'

export type { IMapProps }

const LazyMap = React.lazy(() => import('@ambition/ui-map').then(module => ({ default: module.Map })))

export function Map<ItemT extends IMapItem>(
  props: Parameters<React.FC<IMapProps<ItemT>>>[0]
): ReturnType<React.FC<IMapProps<ItemT>>> {
  return (
    <Suspense fallback={<Loader />}>
      <LazyMap {...props} />
    </Suspense>
  )
}
