import React, { Suspense } from 'react'

import { Loader } from '../Loader/Loader'
import { View } from '../View/View'
import type { ISliderProps } from './types'

export { ISliderProps }

const LazySlider = React.lazy(() => import('./Slider').then(module => ({ default: module.Slider })))

export const Slider: React.FC<ISliderProps> = props => (
  <Suspense
    fallback={
      <View width="100%" justifyContent="center" alignItems="center" height={50}>
        <Loader inline size="small" />
      </View>
    }
  >
    <LazySlider {...props} />
  </Suspense>
)
