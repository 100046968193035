import { ICoverLetter, ICoverLetterSettings, IPosition, IStudioContent } from '../models'
import { Endpoint, get, post } from '../util/api'
import { TranslatedText } from '../util/i18n'

export type GetStudioData = Endpoint<
  Record<string, never>,
  {
    success: true
    data: {
      content: IStudioContent<TranslatedText>
      coverLetters: Pick<ICoverLetter, 'id' | 'jobTitle'>[]
      coverLetterSettings: ICoverLetterSettings | null
      // coverLetterTones: ICoverLetterTone[]
      recommendedJobTitles: TranslatedText[]
      showWelcome: boolean
    }
  }
>

export const getStudioData = () => get<GetStudioData>('/studio/data')

export const getStudioDataQuery = () => ({
  queryKey: ['studio-data'],
  queryFn: async () => {
    const results = await getStudioData()
    return results.success ? results : null
  }
})

export type CreateCoverLetter = Endpoint<
  { stream?: 'stream' },
  { success: true; coverLetter: TranslatedText; error?: string },
  {
    resume: string | null
    positionId: IPosition['id'] | null
    jobTitle: string | null
    jobDescription: string | null
    settings: ICoverLetterSettings
  }
>

export type CreateCoverLetterStreamResponse = CreateCoverLetter['response'] | { chunk: string }

export const createCoverLetter = (data: CreateCoverLetter['body']) =>
  post<CreateCoverLetter>('/studio/cover-letter', data)
