import { background, border, flexbox, fontSize, layout, space } from 'styled-system'

import { Pressable, styled } from '~/lite'

// copied https://github.com/cchanxzy/react-currency-input-field lib into this folder
// (it uses a default export which is undefined in our production ESM build)
// @todo figure out how to make it work
import { CurrencyInput as CurrencyInputField } from './react-currency-input-field'
import { TextAreaInputProps, TextInputProps, WrapStyledProps } from './types'

export const Wrap = styled(Pressable)<WrapStyledProps>`
  background-color: ${props => props.theme.inputBackground};
  border: 1px solid ${props => props.theme.inputBorderColor};
  cursor: default;
  ${border}
  ${layout}
  ${flexbox}
  ${space}
`

export const TextInput = styled.input<TextInputProps>`
  outline-width: 0;
  color: ${props => props.theme.inputText};
  resize: none;
  appearance: none;
  box-sizing: border-box;
  ${layout}
  ${flexbox}
  ${space}
  ${fontSize}
  ${background}
  ${border}
  &::placeholder {
    color: ${props => props.theme.inputPlaceholder};
  }
`

export const TextAreaInput = styled.textarea<TextAreaInputProps>`
  outline-width: 0;
  color: ${props => props.theme.inputText};
  resize: none;
  appearance: none;
  box-sizing: border-box;
  ${layout}
  ${flexbox}
  ${space}
  ${fontSize}
  ${background}
  ${border}
  &::placeholder {
    color: ${props => props.theme.inputPlaceholder};
  }
`

export const CurrencyInput = styled(CurrencyInputField)<TextInputProps>`
  outline-width: 0;
  color: ${props => props.theme.inputText};
  resize: none;
  appearance: none;
  box-sizing: border-box;
  ${layout}
  ${flexbox}
  ${space}
  ${fontSize}
  ${background}
  ${border}
  &::placeholder {
    color: ${props => props.theme.inputPlaceholder};
  }
`
