import React, { useEffect, useState } from 'react'

import { Image, Modal, Text, View } from '~/components'
import { getElementById, scrollIntoView } from '~/dom'
import { isWeb } from '~/util'

import { DialogBody } from './styles'

interface IMediaProps {
  hasFailed: boolean
  url: string
}

export const Media: React.FC<IMediaProps> = ({ hasFailed, url }) => {
  const [showMedia, setShowMedia] = useState(false)

  useEffect(() => {
    if (isWeb) {
      const el = getElementById('messages-list-bottom')

      if (el) {
        scrollIntoView(el)
      }
    }
  }, [])

  return (
    <View position="relative">
      {hasFailed && (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Text text="Failed to load media" />
        </View>
      )}

      {!hasFailed && url && <Image source={url} height={100} width={100} />}
      {showMedia && url && (
        <Modal isOpen={showMedia} onClose={() => setShowMedia(false)} title="Media" closeOnOverlayPress>
          <DialogBody>
            <Image source={url} height={100} width={300} />
          </DialogBody>
        </Modal>
      )}
    </View>
  )
}
