export type Mode = 'development' | 'staging' | 'production'

export type Domain = 'instantsdk.com' | 'remoteambition.com' | 'weebin.com'

const endpoints: Record<Mode, { frontendUrl: string; apiServerUrl: string }> = {
  development: {
    frontendUrl: 'http://localhost:4200',
    apiServerUrl: 'http://localhost:3030'
  },
  staging: {
    frontendUrl: 'https://staging.remoteambition.com',
    apiServerUrl: 'https://api.staging.remoteambition.com'
  },
  production: {
    frontendUrl: 'https://remoteambition.com',
    apiServerUrl: 'https://api.remoteambition.com'
  }
}

export const getEndpointsForMode = (mode: Mode) => {
  if (!endpoints[mode]) {
    throw new Error('Uknown mode')
  }

  // override to avoid CORS issues during development
  // avoiding hard coding the ngrok URL in case it changes and for security reasons
  if (mode === 'development' && typeof window !== 'undefined' && window.document) {
    const { host } = new URL(window.location.href)

    if (host.endsWith('.ngrok.io')) {
      return { frontendUrl: `https://${host}`, apiServerUrl: `https://${host.replace('front', 'back')}` }
    }
  }

  return endpoints[mode]
}
