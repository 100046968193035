import { PositionFilterForm, PositionFilterFormProps, usePositionFilterQueryParams } from '@ambition/module-form'
import {
  CollectionSlug,
  encodeToken,
  getCombinedPositionQuery,
  ICombinedPositionData,
  IMapBounds,
  IPosition,
  IPositionPartial,
  IPositionPartialDetail
} from 'core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { View } from '~/components'
import { useCollection, useHistory, usePositionQuery, usePositions, useScreenTracking } from '~/hooks'
import { MetaTags, useBreakpoints, useLayoutEffectOnNative, useTranslation } from '~/lite'
import { isWeb } from '~/util'

import { PositionList } from '../../components/PositionList'
import { Map } from '../Map'
import { Content, ContentContainer, FilterStickyBox, PositionListContainer } from './styles'

export type CollectionContentProps = {
  collectionSlug: CollectionSlug
  positionId: IPosition['id']
}

export const CollectionContent: React.FC<CollectionContentProps> = ({ collectionSlug, positionId }) => {
  const { isPhone } = useBreakpoints()
  const t = useTranslation()
  const history = useHistory()
  const { collectionType, query } = usePositionQuery(collectionSlug)

  const [isLoading, setIsLoading] = useState(true)
  const [view, setView] = useState<'Table' | 'MasterDetail' | 'Map'>(
    collectionType === 'Nearby' ? 'Map' : 'MasterDetail'
  )
  const [customMapBounds, setCustomMapBounds] = useState<IMapBounds | null>(null)
  const [selectedPosition, setSelectedPosition] = useState<IPositionPartial | null>(null)

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { data } = useCollection(collectionSlug)

  const { collection, header, style: collectionStyle, mapBounds } = data ?? {}

  const dedupedSlug = collectionSlug.split(',')[0] as CollectionSlug
  const collectionCountryCode = query.countryCodes?.length ? query.countryCodes[0] : undefined

  useEffect(() => {
    // reset if collection is recycled
    setView(collectionType === 'Nearby' ? 'Map' : 'MasterDetail')
    setCustomMapBounds(null)
  }, [dedupedSlug, collectionType])

  const formFilterParams = usePositionFilterQueryParams()

  const combinedPositionQuery = useMemo(
    () => getCombinedPositionQuery(query, formFilterParams, customMapBounds),
    [query, formFilterParams, customMapBounds]
  )

  const { combinedData: positionData } = usePositions(combinedPositionQuery)

  useScreenTracking(
    () =>
      data && {
        screen: 'Collection',
        collectionSlug,
        collectionType: collectionType ?? null,
        collectionId: collection?.id ?? null
      }
  )

  useLayoutEffectOnNative(() => {
    history.setOptions({ headerTitle: header?.text ?? undefined })
  }, [history, header])

  const onSearchAreaButtonPress = useCallback((mapBounds: IMapBounds) => setCustomMapBounds(mapBounds), [])

  const positionsWithLatLng = useMemo<IPositionPartialDetail[]>(
    () => positionData?.positions?.filter(position => !!position.latLng) ?? [],
    [positionData]
  )

  const onSelectPosition = useCallback(
    (position: IPositionPartialDetail) => {
      setSelectedPosition(position)
      history.push(`/jobs/${encodeToken(position.id)}`)
    },
    [history]
  )

  const onPositionListDataChange = React.useCallback(
    (data: ICombinedPositionData | null, isLoading: boolean) => {
      setIsLoading(isLoading)
      if (isPhone) return

      if (data?.positions?.length) {
        setSelectedPosition(data.positions[0])
      }
    },
    [isPhone]
  )

  const renderMap = () => (
    <View height="100%" minHeight="100vh">
      <Map<IPositionPartial>
        items={positionsWithLatLng}
        onSelect={onSelectPosition}
        selectedItemId={selectedPosition?.id ?? null}
        fetchWithinBounds={onSearchAreaButtonPress}
        bounds={mapBounds && !customMapBounds ? mapBounds : undefined}
        isLoading={isLoading}
      />
    </View>
  )

  const headerText = header?.text
    ? collectionType === 'Country' || collectionType === 'Location'
      ? t('Jobs in {{location}}', { location: header.text })
      : t('{{collectionName}} Jobs', { collectionName: header.text })
    : null

  const renderPositionFilter = React.useCallback(
    (props?: PositionFilterFormProps) => {
      return (
        <PositionFilterForm collectionSlug={collectionSlug} collectionCountryCode={collectionCountryCode} {...props} />
      )
    },
    [collectionCountryCode, collectionSlug]
  )

  return (
    <ContentContainer>
      <MetaTags type="Collection" headerText={headerText} />
      <Content>
        <View paddingX="m" paddingY="none">
          <PositionListContainer>
            <PositionList
              context="Collection"
              isUpdateModalOpen={isUpdateModalOpen}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
              isDeleteModalOpen={isDeleteModalOpen}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              mapBounds={customMapBounds}
              collectionSlug={collectionSlug}
              collectionHeader={header ?? null}
              headerText={headerText}
              collectionStyle={collectionStyle ?? null}
              onDataChange={onPositionListDataChange}
              renderPositionFilter={renderPositionFilter}
              {...(!isWeb && {
                bottomSheetProps: {
                  isOpen: true,
                  alwaysOpen: 300,
                  backdropComponent: (props: any) => <View {...props}>{renderMap()}</View>
                }
              })}
            />
          </PositionListContainer>
        </View>
      </Content>
      <FilterStickyBox bottom offsetBottom={-10}>
        <View width={350} height="95vh">
          {renderPositionFilter()}
        </View>
      </FilterStickyBox>
    </ContentContainer>
  )
}
