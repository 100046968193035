import type { EncodedParams, INewTeam, ITeam } from 'core'

import { constants } from './constants'

const isPlainObject = (value: unknown): value is Record<string, any> => {
  if (typeof value !== 'object' || value === null) {
    return false
  }

  const proto = Object.getPrototypeOf(value)
  return proto === null || proto === Object.getPrototypeOf({})
}

const isString = (value: unknown): value is string => typeof value === 'string'

const isArray = (value: unknown): value is unknown[] => Array.isArray(value)

const isDate = (value: unknown): value is Date => value instanceof Date && !isNaN(value.getTime())

const parseDate = (value: unknown) => {
  if (isDate(value)) {
    return value
  }

  if (isString(value)) {
    const match = value.match(/^(\d{4}-\d{2}-\d{2})/)

    if (match) {
      return new Date(match[1])
    }
  }

  return null
}

const getIsoDate = (value: Date) => value.toISOString().split('T')[0]

const formatDate = (date: unknown) => {
  if (isString(date)) {
    const parsedDate = parseDate(date)

    if (isDate(parsedDate)) {
      return getIsoDate(parsedDate)
    }
  }

  if (isDate(date)) {
    return getIsoDate(date)
  }

  return undefined
}

const addDaysToDate = (date: Date, days: number) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export const encodeParams = (params: { [s: string]: any }) =>
  Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(isArray(value) ? value.join(',') : value || '')}`)
    .join('&') as EncodedParams

type Opaque<Type, Token = unknown> = Type & { readonly __opaque__: Token }

type Token<Type> = Type extends Opaque<unknown, infer Key> ? Key : never

export const encodeToken = <T extends string>(value: T) => encodeURIComponent(value) as Token<T>

const omit = (object: Record<string, any>, path: string) =>
  Object.entries(object).reduce(($object, [key, value]) => (key === path ? $object : { ...$object, [key]: value }), {})

export const util = {
  isPlainObject,
  isString,
  isDate,
  isArray,
  parseDate,
  getIsoDate,
  formatDate,
  addDaysToDate,
  encodeParams,
  omit
}

export const getTeamLogo = (
  teamOrAvatar: Pick<ITeam | INewTeam, 'logo'> | string | null | undefined
): string | null => {
  const logo = typeof teamOrAvatar === 'string' ? teamOrAvatar : teamOrAvatar?.logo

  if (!logo) {
    return null
  }

  return logo.startsWith('http') ? logo : `${constants.assetServerUrl}/team-logos/${logo}`
}
