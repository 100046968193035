import { CreateStripeAccountOnboardingLink, getCountry } from 'core'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormGroup, Input, Radio } from '~/components'
import { useCreateStripeAccountOnboardingLink, useGeoData } from '~/hooks'
import { Button, Dialog, IDialogProps, Select, styled, toast, useStore, useTranslation } from '~/lite'

const AccountTypeRadio = styled(Radio)`
  flex-direction: row;
  gap: 8px;
`

interface IAccountDialog extends Omit<IDialogProps, 'trigger' | 'children'> {
  onClose(): void
}

export const AccountDialog: React.FC<IAccountDialog> = ({ onClose, ...props }) => {
  const t = useTranslation()
  const user = useStore(state => state.user)
  const theme = useStore(state => state.theme)

  const { mutateAsync: createStripeAccountOnboardingLink, isLoading: isCreatingAccountOnboardingLink } =
    useCreateStripeAccountOnboardingLink()

  const geoData = useGeoData()

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm<NonNullable<CreateStripeAccountOnboardingLink['body']['account']>>()

  useEffect(() => {
    reset({ businessType: 'Individual', country: getCountry() ?? 'US', email: '', firstName: '', lastName: '' })
  }, [reset])

  const businessType = watch('businessType')

  const countryOptions = (geoData.data?.countries ?? []).map(country => ({ label: country.name, value: country.code }))

  return (
    <Dialog
      {...props}
      trigger={null}
      title="Accept payment"
      description="Please enter details exactly as they appear on your tax or government ID."
      small
      footer={
        <>
          <Button text="Cancel" onPress={onClose} skipTracking />
          <Button
            text="Continue"
            onPress={handleSubmit(async account => {
              const results = await createStripeAccountOnboardingLink({ account })

              if (results.success) {
                window.location.href = results.onboardingLink
              } else {
                toast.failure()
              }
            })}
            disabled={isCreatingAccountOnboardingLink}
            loading={isCreatingAccountOnboardingLink}
            skipTracking
            primary
          />
        </>
      }
    >
      <Controller
        control={control}
        name="businessType"
        rules={{ required: { value: true, message: t('This field is required') } }}
        render={({ field: { value, onChange } }) => (
          <FormGroup
            label="Account type"
            helperText={errors.businessType?.message}
            helperTextPosition="Top"
            style={{ marginBottom: 0 }}
          >
            <AccountTypeRadio
              options={[
                { label: 'Individual', value: 'Individual' },
                { label: 'Company', value: 'Company' }
              ]}
              value={value}
              onChange={onChange}
            />
          </FormGroup>
        )}
      />
      {businessType === 'Individual' ? (
        <>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: { value: true, message: t('This field is required') } }}
            render={({ field }) => (
              <FormGroup label="Legal first name" helperText={errors.firstName?.message} style={{ marginBottom: 0 }}>
                <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
              </FormGroup>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{ required: { value: true, message: t('This field is required') } }}
            render={({ field }) => (
              <FormGroup label="Legal last name" helperText={errors.firstName?.message} style={{ marginBottom: 0 }}>
                <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
              </FormGroup>
            )}
          />
        </>
      ) : (
        <Controller
          control={control}
          name="companyName"
          rules={{ required: { value: true, message: t('This field is required') } }}
          render={({ field }) => (
            <FormGroup label="Legal company name" helperText={errors.companyName?.message} style={{ marginBottom: 0 }}>
              <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
            </FormGroup>
          )}
        />
      )}
      {!user?.email && (
        <Controller
          control={control}
          name="email"
          rules={{ required: { value: true, message: t('This field is required') } }}
          render={({ field }) => (
            <FormGroup label="Email" helperText={errors.firstName?.message} style={{ marginBottom: 0 }}>
              <Input
                {...field}
                keyboardType="email-address"
                wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }}
              />
            </FormGroup>
          )}
        />
      )}
      <Controller
        control={control}
        name="country"
        rules={{ required: { value: true, message: t('This field is required') } }}
        render={({ field: { value: rawValue, onChange, ...field } }) => {
          const value = countryOptions.find(option => option.value === rawValue)

          return (
            <FormGroup label="Country" helperText={errors.country?.message} style={{ marginBottom: 0 }}>
              <Select
                options={countryOptions}
                value={value ?? null}
                onChange={option => onChange(option?.value ?? null)}
                {...field}
              />
            </FormGroup>
          )
        }}
      />
    </Dialog>
  )
}
