import {
  CanonicalFieldName,
  FieldId,
  FieldValue,
  GroupedFieldId,
  IApplicationField,
  IApplicationFieldWithAnswer,
  IApplicationForm,
  IApplicationFormWithAnswers,
  IAutofillData,
  IAutofillState,
  IGroupField,
  NormalizedFieldName
} from '../models'

type AutofillLoader = () => Promise<{ data: IAutofillData; state: IAutofillState } | null>

const normalizeFieldName = (
  name: string,
  data: Pick<IAutofillData, 'fieldMarkup' | 'fieldQualifiers'>
): NormalizedFieldName => {
  let normalized = name.toLowerCase()

  for (const qualifier of data.fieldQualifiers) {
    normalized = normalized.replaceAll(qualifier, '')
  }

  for (const markup of data.fieldMarkup) {
    normalized = normalized.replaceAll(markup, '')
  }

  return normalized.replace(/\s+/g, ' ').trim() as NormalizedFieldName
}

// this returns a string with everything relevant to autofilling, and nothing irrelevant to autofilling.
// if the strings are the same, then the fields are the same in autofill's eyes.
// generally these will be visual things, since autofill mimics a user.
// useful for an initial round of deduping (then we map equivalent terms, use AI, etc to further dedup).
export const getFormFieldHashInput = (field: IApplicationField) => {
  if (field.type === 'Other' || field.type === 'Group') {
    return null
  }

  const formField = {
    type: field.type,
    label: field.label,
    helper: 'helper' in field && field.helper ? field.helper : null,
    options: 'options' in field && field.options.length ? field.options.map(option => option.label) : null
  }

  // making adjustments that don't change the meaning of anything but reduce variance.
  // need to regenerate form_fields.hash_id values (and a bunch of other stuff) in the database
  // whenever this changes, so never change it.
  const hashInput = JSON.stringify([
    formField.type,
    formField.label.toLowerCase().trim(),
    formField.helper?.toLowerCase().trim() ?? '',
    formField.options?.map(label => label.toLowerCase().trim()).sort((a, b) => a.localeCompare(b)) ?? ''
  ])

  return { hashInput, formField }
}

let _autofillPromise: Promise<
  | (IAutofillState & {
      getCanonicalFieldName(name: string | null): CanonicalFieldName | null
    })
  | null
> | null = null

const getAutofill = (loader: AutofillLoader) => {
  if (!_autofillPromise) {
    _autofillPromise = (async () => {
      const autofill = await loader()

      if (!autofill) {
        // allow retry after 30 seconds
        setTimeout(() => {
          _autofillPromise = null
        }, 30 * 1000)

        return null
      }

      const { fieldAliases, fieldQualifiers, fieldMarkup } = autofill.data

      const fieldMap: Record<string, CanonicalFieldName> = {}

      for (const [canonicalName, aliases] of Object.entries(fieldAliases)) {
        for (const alias of aliases ?? []) {
          fieldMap[alias] = canonicalName as CanonicalFieldName
        }
      }

      const getCanonicalFieldName = (name: string | null): CanonicalFieldName | null => {
        if (!name) {
          return null
        }

        const normalized = normalizeFieldName(name, { fieldMarkup, fieldQualifiers })
        return normalized in fieldMap ? fieldMap[normalized] : null
      }

      return { ...autofill.state, getCanonicalFieldName }
    })()
  }

  return _autofillPromise
}

export const getFieldAutofill = async (
  loader: AutofillLoader,
  field: {
    label: string | null
    placeholder: string | null
    name: string | null
  }
): Promise<{ field: CanonicalFieldName | null; value: FieldValue | null }> => {
  const autofill = await getAutofill(loader)

  if (!autofill) {
    return { field: null, value: null }
  }

  const { getCanonicalFieldName } = autofill

  const fieldName =
    getCanonicalFieldName(field.label) ?? getCanonicalFieldName(field.placeholder) ?? getCanonicalFieldName(field.name)

  if (!fieldName) {
    return { field: null, value: null }
  }

  return { field: fieldName, value: autofill.values[fieldName] ?? null }
}

export const getGroupedFieldId = (parentFieldId: FieldId, childFieldId: FieldId): GroupedFieldId =>
  `${parentFieldId}.${childFieldId}` as GroupedFieldId

export async function adjustApplicationForm<T extends IApplicationForm>(
  form: T,
  callback: (field: T['sections'][number]['fields'][number]) => Promise<T['sections'][number]['fields'][number]>
): Promise<T> {
  return {
    ...form,
    sections: await Promise.all(
      form.sections.map(async section => ({
        ...section,
        fields: await Promise.all(
          section.fields.map(async field => {
            if (field.type === 'Group') {
              return {
                ...field,
                fields: await Promise.all(
                  field.fields.map(async groupField => {
                    const result = await callback(groupField)

                    if (result.type === 'Group') {
                      throw new Error('Cannot return a nested Group field from the callback')
                    }

                    return result
                  })
                )
              }
            }

            return callback(field)
          })
        )
      }))
    )
  }
}

export function iterateApplicationForm<T extends IApplicationForm>(
  form: T,
  callback: (field: T extends IApplicationFormWithAnswers ? IApplicationFieldWithAnswer : IApplicationField) => void
): void {
  for (const section of form.sections) {
    for (const field of section.fields) {
      callback(field)

      if (field.type === 'Group') {
        for (const groupField of field.fields) {
          callback(groupField)
        }
      }
    }
  }
}

const getFieldAutofillAnswer = (field: IApplicationField, autofill: IAutofillState | null) => {
  const answer = field.canonical ? autofill?.values[field.canonical] : undefined

  if (typeof answer === 'undefined') {
    return
  }

  if (field.type === 'Checkbox' && Array.isArray(answer)) {
    return field.options
      .filter(option => answer.includes(option.label) || answer.includes(option.value))
      .map(option => option.value)
  }

  if (field.type === 'Checkbox' || field.type === 'Radio' || field.type === 'Select') {
    return field.options.find(option => option.label === answer)?.value
  }

  return answer
}

export const addAnswersToForm = (
  form: IApplicationForm,
  autofill: IAutofillState | null
): IApplicationFormWithAnswers => ({
  ...form,
  sections: form.sections.map(section => ({
    ...section,
    fields: section.fields.map((field): IApplicationFieldWithAnswer => {
      if (field.type === 'Group') {
        const groupAnswer: Record<string, FieldValue> = {}
        const groupFields = field.fields.map((groupField): any => {
          const fieldId = getGroupedFieldId(field.id, groupField.id)
          const answer = getFieldAutofillAnswer(field, autofill)
          if (answer) {
            groupAnswer[groupField.id] = answer
          }
          return {
            ...groupField,
            answer: answer ?? groupField.defaultValue
          }
        })
        return {
          ...field,
          fields: groupFields,
          answer: [groupAnswer]
        }
      }

      if (field.type === 'Hidden') {
        return { ...field, answer: field.defaultValue }
      }

      return { ...field, answer: getFieldAutofillAnswer(field, autofill) ?? field.defaultValue }
    })
  }))
})

export const checkCompletenessOfForm = (form: IApplicationFormWithAnswers) => {
  const results: {
    answeredFields: IApplicationFieldWithAnswer[]
    unansweredOptionalFields: IApplicationField[]
    unansweredRequiredFields: IApplicationField[]
  } = {
    answeredFields: [],
    unansweredOptionalFields: [],
    unansweredRequiredFields: []
  }

  const partitionField = (
    field: Exclude<IApplicationFormWithAnswers['sections'][number]['fields'][number], IGroupField>
  ) => {
    if (field.answer) {
      results.answeredFields.push(field)
    }

    if (!field.answer && field.required) {
      results.unansweredRequiredFields.push(field)
    }

    if (!field.answer && !field.required) {
      results.unansweredOptionalFields.push(field)
    }
  }

  form.sections.forEach(section => {
    section.fields.forEach(field => {
      if (field.type === 'Group') {
        field.fields.forEach(groupField => partitionField(groupField))
      } else {
        partitionField(field)
      }
    })
  })

  return results
}
