import { useQueryClient } from '@tanstack/react-query'
import {
  coverLetterSizes,
  coverLetterTones,
  defaultCoverLetterSettings,
  ICoverLetterSettings,
  IStudioContent,
  supportedLocales,
  TranslatedText
} from 'core'
import React, { useRef, useState } from 'react'

import { Accordion, Input } from '~/components'
import { useSubmitUserPrompt } from '~/hooks'
import { breakpoint, Button, Dialog, Icon, Select, styled, Text, useTranslation, View } from '~/lite'

const Wrap = styled(View)<{ $variant?: 'Apply' | 'Dashboard' | 'Step' }>`
  width: 100%;
  padding: ${props => (props.$variant === 'Apply' ? 0 : props.$variant === 'Step' ? '32px 16px 16px' : '16px')};
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  border-width: ${props => (props.$variant === 'Apply' ? 0 : '1px')};
  margin-bottom: 16px;
  gap: 16px;
`

const Row = styled(View)`
  flex-direction: row;
  gap: 8px;

  button {
    flex: 1;
    border-radius: 6px;
  }
`

const SizeButton = styled(Button)<{ $active: boolean }>`
  flex: 1;
  border-radius: 6px;
  background: ${props => (props.$active ? props.theme.primary : props.theme.buttonBackground)};
`

const InstructionsButton = styled(Button)`
  flex: 1;
  border-radius: 6px;
  background: ${props => props.theme.buttonBackground};
`

const Example = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
`

const ExampleText = styled(Text)`
  padding-left: 6px;
  font-size: 14px;
`

const AccordionWrap = styled(View)`
  max-height: 250px;
  overflow-y: auto;

  @media ${breakpoint.md} {
    max-height: none;
    overflow-y: visible;
  }
`

interface ISettingsProps {
  variant?: 'Apply' | 'Dashboard' | 'Step'
  content: IStudioContent
  settings: ICoverLetterSettings
  onChange(changes: Partial<ICoverLetterSettings>): void
}

export const Settings: React.FC<ISettingsProps> = ({ variant, content, settings, onChange }) => {
  const t = useTranslation()
  const queryClient = useQueryClient()

  const getLocaleOption = (locale: typeof supportedLocales[number]) => ({ label: locale.name, value: locale.code })
  const defaultLocaleOption = { label: t('Detect language'), value: null }
  const selectedLocale = supportedLocales.find(locale => locale.code === settings.locale)

  const getToneOption = (tone: ICoverLetterSettings['tone']) => ({ label: t(tone), value: tone })
  const defaultToneOption = getToneOption(defaultCoverLetterSettings.tone)
  const selectedTone = coverLetterTones.find(tone => tone === (settings.tone ?? defaultCoverLetterSettings.tone))

  const instructionsInputRef = useRef<HTMLInputElement>(null)
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false)
  const [pendingInstructions, setPendingInstructions] = useState<TranslatedText | null>(null)

  const { mutate: submitInstructions, isLoading: isSubmittingInstructions } = useSubmitUserPrompt({
    onSuccess: async data => {
      if (data.success) {
        await queryClient.invalidateQueries(['user-prompt'])
        onChange({ instructions: pendingInstructions })
        setPendingInstructions(null)
        setIsInstructionsOpen(false)
      }
    }
  })

  const onSaveInstructionsButtonPress = () => {
    if (pendingInstructions !== null && (pendingInstructions ?? '') !== (settings.instructions ?? '')) {
      submitInstructions({
        promptValue: (pendingInstructions ?? '') as TranslatedText,
        type: 'CoverLetterInstructions'
      })
    } else {
      setIsInstructionsOpen(false)
    }
  }

  const instructions = pendingInstructions ?? settings.instructions ?? ''

  return (
    <Wrap $variant={variant}>
      <Row>
        {coverLetterSizes.map(size => (
          <SizeButton
            key={size}
            onPress={() => onChange({ size })}
            text={size}
            $active={settings.size === size}
            active={settings.size === size}
            skipTracking
          />
        ))}
      </Row>
      <Row>
        <Select
          placeholder={defaultToneOption.label}
          value={selectedTone ? getToneOption(selectedTone) : null}
          options={coverLetterTones.map(getToneOption)}
          onChange={option => option && onChange({ tone: option.value })}
        />
        <Select
          placeholder={t('Detect language')}
          value={selectedLocale ? getLocaleOption(selectedLocale) : null}
          options={[defaultLocaleOption, ...supportedLocales.map(getLocaleOption)]}
          onChange={option => onChange({ locale: option?.value || null })}
        />
      </Row>
      <Row>
        <Dialog
          open={isInstructionsOpen}
          onOpenChange={open => {
            setIsInstructionsOpen(open)
            setPendingInstructions(null)
          }}
          trigger={
            <InstructionsButton
              text="Custom instructions"
              onPress={() => {
                setIsInstructionsOpen(true)
                ;(instructionsInputRef.current as any)?.setValue(settings.instructions ?? '')
              }}
              skipTracking
            />
          }
          rawTitle={content.instructions.header}
          rawDescription={content.instructions.subheader}
          footer={
            <>
              <Button
                text="Cancel"
                onPress={() => {
                  setIsInstructionsOpen(false)
                  setPendingInstructions(null)
                }}
                minimal
                skipTracking
              />
              <Button
                text="Save"
                onPress={onSaveInstructionsButtonPress}
                loading={isSubmittingInstructions}
                disabled={isSubmittingInstructions}
                primary
                skipTracking
              />
            </>
          }
        >
          <Input
            ref={instructionsInputRef}
            numberOfLines={3}
            value={instructions}
            onChange={value => setPendingInstructions(value as TranslatedText)}
          />
          <View flexDirection="row" alignItems="center">
            <Icon icon="bulb-outline" marginRight={4} size={18} />
            <Text text="Inspiration" fontWeight={600} fontSize={18} />
          </View>
          <AccordionWrap>
            <Accordion
              data={content.instructions.examples.map(example => ({
                trigger: example.header,
                content: (
                  <>
                    {example.examples.map((example, idx) => (
                      <Example key={idx}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z" />
                          <path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z" />
                        </svg>
                        <ExampleText rawText={example} />
                      </Example>
                    ))}
                  </>
                )
              }))}
            />
          </AccordionWrap>
        </Dialog>
      </Row>
    </Wrap>
  )
}
