import { OccupationBrowser, SelectCity } from '@ambition/module-shared'
import { ClientRoutePath, encodeToken } from 'core'
import React from 'react'

import { useHistory, useLocation, useStore, useToggle } from '~/hooks'
import { DesktopView, useBreakpoints, View } from '~/lite'

import { SearchInput } from './SearchInput'
import { CloseIcon, SearchBarWrap } from './styles'

export type SearchbarView = 'Search' | 'Location'

type SearchbarProps = {
  activeView: SearchbarView | null
  setActiveView: React.Dispatch<React.SetStateAction<SearchbarView | null>>
}

export const Searchbar: React.FC<SearchbarProps> = ({ activeView, setActiveView }) => {
  const location = useLocation()
  const history = useHistory()
  const selectedLocation = useStore(state => state.selectedLocation)
  const { isPhone } = useBreakpoints()
  const [showCloseIcon, toggleShowCloseIcon] = useToggle(false)

  React.useEffect(() => {
    setTimeout(() => {
      setActiveView(view => (view === 'Search' ? null : view))
    }, 200)
  }, [location, setActiveView])

  /*
  @todo add as a button
  React.useEffect(() => {
    fetchCurrentLocation()
  }, [fetchCurrentLocation])
  */

  /*
  const hideSearchContent = () => {
    setActiveView(null)
    toggleShowCloseIcon()
  }

  const onChange = (value: string) => {
    setSearchTerm(value)
    if (activeView !== 'Search') {
      setActiveView('Search')
    }
  }

  const onKeyPress: IInputProps<string>['onKeyPress'] = key => {
    switch (key) {
      case 'Enter':
        searchTerm &&
          createRecentSearches({
            name: searchTerm as TranslatedText
          })
        history.push(`/search?query=${encodeToken(searchTerm || '')}&locationId=${selectedLocation?.locationId || ''}`)
        break
      case 'Escape':
        setActiveView(null)
        break
      default:
        break
    }
  }
  */

  /*
    accessoryRight={
    isInitialLoading ? (
      <Loader inline isLoading={isInitialLoading} size="small" />
    ) : (
      !isPhone && (
        <Button
          action="SearchJobsNavBar"
          icon="search"
          to={`/search?query=${encodeToken(searchTerm || '')}&locationId=${selectedLocation?.locationId || ''}`}
          link
          minimal
          paddingX="m"
          marginRight={-12}
          height={40}
        />
      )
    )
  }
  */

  return (
    <SearchBarWrap>
      <DesktopView justifyContent="center">
        <OccupationBrowser />
      </DesktopView>
      {(activeView !== 'Location' || !isPhone) && (
        <View flexDirection="row" alignItems="center" flex={1}>
          <SearchInput
            onChange={(searchTerm, path) => {
              if (!searchTerm) {
                return
              }
              const { search } = location
              const queryValue = encodeToken(searchTerm)
              const locationId = selectedLocation?.locationId || ''
              const queryString = search.split('?')[1]
              const queryParams = queryString ? queryString.split('&') : []
              let foundQueryKey = false,
                foundLocationIdKey = false
              let subTitleQueryIndex = -1
              for (let i = 0; i < queryParams.length; i++) {
                const [key] = queryParams[i].split('=')
                if (key === 'query') {
                  queryParams[i] = `query=${queryValue}`
                  foundQueryKey = true
                } else if (key === 'locationId') {
                  queryParams[i] = `locationId=${locationId}`
                  foundLocationIdKey = true
                } else if (key === 'subTitleQuery') {
                  subTitleQueryIndex = i
                }
              }
              if (!foundQueryKey) {
                queryParams.push(`query=${queryValue}`)
              }
              if (!foundLocationIdKey) {
                queryParams.push(`locationId=${locationId}`)
              }
              if (subTitleQueryIndex >= 0) {
                queryParams.splice(subTitleQueryIndex, 1)
              }

              const routePath = path ?? (`/search?${queryParams.join('&')}` as ClientRoutePath)
              history.push(routePath)
            }}
            onFocus={() => {
              setActiveView('Search')

              if (!showCloseIcon) {
                toggleShowCloseIcon()
              }
            }}
            onBlur={() => setActiveView(view => (view === 'Search' ? null : view))}
            enabled={activeView === 'Search'}
          />
          {activeView === 'Search' && isPhone && showCloseIcon && (
            <CloseIcon
              icon="close"
              size={24}
              marginLeft="s"
              marginRight="m"
              onPress={() => {
                setActiveView(null)
                toggleShowCloseIcon()
              }}
            />
          )}
          {/*<Icon icon="search" />*/}
        </View>
      )}
      {(activeView !== 'Search' || !isPhone) && (
        <View justifyContent="center" paddingRight={4}>
          <SelectCity
            onFocus={() => setActiveView('Location')}
            onBlur={() => setActiveView(view => (view === 'Location' ? null : view))}
            radix
            minimal
          />
        </View>
      )}
    </SearchBarWrap>
  )
}

/*
          <SearchInput
            value={searchTerm}
            wrapStyle={{ flex: 1, borderWidth: 0, backgroundColor: 'none' }}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            accessoryLeft={<Icon icon="search" />}
            accessoryRight={
              activeView === 'Search' && isPhone && showCloseIcon ? (
                <CloseIcon icon="close" size={24} marginLeft="s" onPress={() => hideSearchContent()} />
              ) : undefined
            }
          />
          <SearchContent
            data={allResults?.[0]}
            searchTerm={searchTerm}
            visible={activeView === 'Search'}
            attributes={attributes}
            popperRef={popperElementRef}
            styles={styles}
          />
          */
