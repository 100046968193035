import React from 'react'

import { ViewProps } from '../View/View'
import { CheckmarkWrapper, Mark } from './styles'

export interface ICheckmarkProps {
  style?: ViewProps['style']
}

export const Checkmark: React.FC<ICheckmarkProps> = ({ style }) => {
  return (
    <CheckmarkWrapper
      position="relative"
      height={28}
      width={28}
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={1111}
      style={style}
    >
      <Mark
        width={8}
        height={14}
        borderRadius={2}
        position="relative"
        top={-2}
        borderTopWidth={2}
        borderTopColor="white"
        borderLeftWidth={2}
        borderLeftColor="white"
      />
    </CheckmarkWrapper>
  )
}
