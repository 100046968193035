import React from 'react'

import { breakpoint, Icon, Image, styled, Text, View } from '~/lite'

const Wrap = styled(View)`
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  padding: 0 8px;

  @media ${breakpoint.md} {
    flex-direction: row;
    margin: 32px auto 0;
  }
`

const CommunityHeader = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const CommunityPhotos = styled(View)`
  flex-direction: row;
  padding-left: 12px;
`

const Stars = styled(View)`
  flex-direction: row;
  gap: 4px;
`

const CommunityPhoto = styled(Image)`
  position: relative;
  border-radius: 100%;
  margin-left: -12px;
  border-width: 2px;
`

const CommunityTagline = styled(Text)`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.8;
  margin: 0 auto;
  max-width: 500px;
  hyphens: none;
  text-wrap: balance;
`

interface ICommunityProps {
  compact?: boolean
  jobSeeker?: boolean
}

export const Community: React.FC<ICommunityProps> = ({ compact, jobSeeker }) => {
  return (
    <Wrap>
      <CommunityHeader>
        <CommunityPhotos>
          {[1, 2, 3, 4, 5].map(index => (
            <CommunityPhoto
              key={index}
              source={`https://assets.remoteambition.com/marketing/user-${index}.jpg`}
              width={44}
              height={44}
              resizeMode="contain"
              zIndex={index}
            />
          ))}
        </CommunityPhotos>
        <Stars>
          {[1, 2, 3, 4, 5].map(index => (
            <Icon key={index} icon="star" color="#facc14" />
          ))}
        </Stars>
      </CommunityHeader>
      <CommunityTagline
        text={
          jobSeeker
            ? 'Over 14,000 professionals have joined RemoteAmbition. Unlock advanced tools that elevate your job search success.'
            : 'Over 14,000 professionals have joined RemoteAmbition. Experience modern tools that drive successful hiring.'
        }
      />
    </Wrap>
  )
}
