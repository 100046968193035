import { ICollection, ILocation, ISkill, IVisitorLocation, markTranslated, TranslatedText } from 'core'
import type { PlatformIOSStatic } from 'react-native'

import { Platform } from '~/lite'

export const isWeb: boolean = Platform.OS === 'web'
export const isIos: boolean = Platform.OS === 'ios'
export const isAndroid: boolean = Platform.OS === 'android'
export const isPad: boolean = (Platform as PlatformIOSStatic).isPad

export const renderComponent = (webComponent: JSX.Element, mobileComponent: JSX.Element) => {
  return isWeb ? webComponent : mobileComponent
}

export const getCollectionIcon = (collection: ICollection): string => `https://ambitioncdn.com${collection.icon}`

export const getLocationPhoto = (location: ILocation): string =>
  `https://remoteambition.imgix.net/location-photos/${location.photo}`

export const getVisitorCityName = (location?: IVisitorLocation | null): TranslatedText => {
  return location?.cityName ? markTranslated(location?.cityName) : emptyString
}

export const getSkillInitials = (skill: ISkill): TranslatedText =>
  markTranslated((skill.name.match(/[A-Z0-9]/g) || []).slice(0, 3).join(''))

export const formatMoney = (value: number, withSymbol = false): TranslatedText => {
  const amount = isWeb
    ? new Intl.NumberFormat('en-US', { currency: 'USD', maximumFractionDigits: 2 }).format(value)
    : `${value}`

  if (isNaN(amount as any)) {
    return markTranslated(withSymbol ? `$${value.toString()}` : value.toString())
  }

  return markTranslated(withSymbol ? `$${amount}` : amount)
}

export const emptyString = markTranslated('')

export const toText = (value: number) => markTranslated(value.toString())

export const mobileBrowser = {
  isAndroid: () => {
    return navigator.userAgent.match(/Android/i)
  },
  isIOS: () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  }
}

export const addHttpToLink = (link: string) => (link && !link.startsWith('http') ? `//${link}` : link)
