import { isServer } from '../env'
import { AppMessage } from '../types'

let _clientPromise: Promise<BroadcastChannel>

const getClient = () => {
  if (!_clientPromise) {
    _clientPromise = (async () => {
      const client = new BroadcastChannel('ambition')
      client.onmessage = (event: MessageEvent<AppMessage>) => listeners.forEach(listener => listener(event.data))
      return client
    })()
  }

  return _clientPromise
}

const publish = async (message: AppMessage) => {
  if (isServer) {
    return
  }

  const client = await getClient()
  client.postMessage(message)

  // also publish to local listeners so logic can run
  listeners.forEach(listener => listener(message))
}

const listeners: ((message: AppMessage) => void)[] = []

const subscribe = (listener: typeof listeners[number]) => {
  listeners.push(listener)

  return () => {
    const index = listeners.indexOf(listener)
    listeners.splice(index, 1)
  }
}

export const pubSub = { publish, subscribe }
