/// <reference types="vite/client" />

import { getEndpointsForMode } from 'core'

import { IAppEnv, IPlatform } from './env.shared'

// the native app will crash with a cryptic error if RN/Metro encounters "import...".
// so we need to keep these special Vite variables in files it doesn't process.
// in this case, env.native.ts will take precedence over this file when bundling.

// VITE_MODE is set in .github/workflows/web-*.yml
const viteMode = import.meta.env.VITE_MODE
const viteSentryRelease = import.meta.env.VITE_SENTRY_RELEASE
const viteStagingFrontendToken = import.meta.env.VITE_STAGING_FRONTEND_TOKEN

export const Platform: IPlatform = { OS: 'web' }

export const isServer = !(typeof window !== 'undefined' && window.document && window.document.createElement)
export const isWeb = true
export const isIos = false
export const isAndroid = false
export const isNotBot = isServer || !!(window as any)?.isNotBot

let _isWidget = false

if (!isServer) {
  try {
    _isWidget = window.self !== window.top
  } catch (e) {
    _isWidget = true
  }
}

export const isWidget = _isWidget

export const isInstantSdk = (typeof window !== 'undefined' && window.location.href.includes('instantsdk.com')) || false

const mode = viteMode === 'production' ? 'production' : viteMode === 'staging' ? 'staging' : 'development'

export const appEnv: IAppEnv = {
  ...getEndpointsForMode(mode),
  mode,
  sentryRelease: typeof viteSentryRelease === 'string' ? viteSentryRelease : undefined,
  stagingFrontendToken: typeof viteStagingFrontendToken === 'string' ? viteStagingFrontendToken : undefined,
  appVersion: null,
  buildVersion: null
}
