import React from 'react'

import { animations, breakpoint, Button, H1, H2, Icon, styled, Text, View } from '~/lite'

const Wrap = styled(View)`
  min-height: 100vh;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

const Header = styled(H1)`
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 60px;
  margin: 0 auto 16px;

  span:first-child {
    -webkit-text-fill-color: transparent;
    background: ${props =>
      props.theme.dark
        ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
        : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
    -webkit-background-clip: text;

    ::selection {
      -webkit-text-fill-color: #fff;
    }
  }

  span:last-child {
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 8px;
    color: #fff;
    background: ${props => props.theme.primary};
    font-size: 48px;
  }
`

const Divider = styled(View)`
  width: 100px;
  height: 4px;
  background: #45c7fc;
  margin: 0 auto 16px;
`

const Subheader = styled(H2)`
  text-align: center;
  text-wrap: balance;
  color: ${props => (props.theme.dark ? '#b4bcd0' : props.theme.text)};
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;

  @media ${breakpoint.md} {
    margin-bottom: 32px;
  }
`

const PerksHeader = styled(Text)`
  font-size: 26px;
`

const Steps = styled(View)`
  margin: 32px auto 8px;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  padding: 32px;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 0 0 1px ${props => props.theme.border};
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: 3000ms;
  animation-name: ${animations.fadeIn};
`

const Step = styled(View)`
  flex-direction: row;
`

const StepIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const StepTextWrap = styled(View)`
  flex: 1;
  display: block;
`

const BoldStepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: bold;
`

const StepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: normal;
  opacity: 0.8;
`

const Section = styled.section``

const SectionContent = styled(View)<{ $allowWide?: boolean }>`
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px;
  justify-content: start;
  align-items: stretch;

  @media ${breakpoint.xl} {
    width: 1000px;
  }
`

const Body = styled(View)`
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;
  overflow: hidden;
  max-height: 500px;

  @media ${breakpoint.md} {
    max-height: unset;
  }
`

const Shadow = styled(View)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}00 0%,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}ff 100%
  );
`

const Benefit = styled(Text)<{ $delay: number }>`
  padding: 8px 16px;
  background: ${props => props.theme.backgroundContrast};
  font-size: 18px;
  border-radius: 8px;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.$delay}ms;
  animation-name: ${animations.popInUpSubtle};
`

const CallToActionButton = styled(Button)`
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: 3000ms;
  animation-name: ${animations.fadeIn};
`

const paymentUrl = 'https://buy.stripe.com/cN28yud8n0H25r2fZ9'

export interface IInstantSdkProps {
  locale: string
}

export const InstantSdk: React.FC<IInstantSdkProps> = () => {
  return (
    <Wrap>
      <Header
        rawText={
          (
            <>
              <span>Instant</span>
              <span>SDK</span>
            </>
          ) as any
        }
      />
      <Subheader rawText={'Add an AI-managed developer experience to your product in 15 minutes.' as any} />
      <Section>
        <SectionContent>
          <Body>
            {[
              '📜 OpenAPI spec',
              '🧩 Native SDKs',
              '💵 Monetization',
              '✨ Auto-Integrate',
              '📖 Docs',
              '⚡ Quick start files',
              '🛠️ Developer dashboard',
              '🧪 Test servers',
              '🌐 Internationalization',
              '🔢 Semantic versioning',
              '🔄 Changelog',
              '📦 Package publishing',
              '🤖 LLM plugin distribution'
            ].map((item, index) => (
              <Benefit key={index} rawText={item as any} $delay={500 + 100 * index} />
            ))}
          </Body>
        </SectionContent>
      </Section>
      <Steps>
        <PerksHeader rawText={'🎁 Pre-order perks' as any} />
        {[
          ['Early access to this product.', 'Our initial launch date is April 2024.'],
          [
            '$10,000 in credits, which never expire.',
            "These are used to cover our costs for infrastructure and AI models managing your API's SDKs, docs, and more."
          ],
          [
            '2 years of unlimited access guaranteed.',
            'Regardless of the final pricing, you will have 2 years of unlimited access to the full product.'
          ],
          [
            'Feature requests.',
            'Ping our devs over site chat and mention your pre-order email, and we will try to implement features you would like.'
          ],
          [
            'Change your mind anytime before launch.',
            'Just message us on the site chat or over email and we will refund your deposit.'
          ]
        ].map(([header, subheader], idx) => (
          <Step key={idx}>
            <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
            <StepTextWrap>
              <BoldStepText rawText={header as any} /> {!!subheader && <StepText rawText={subheader as any} />}
            </StepTextWrap>
          </Step>
        ))}
      </Steps>
      <View flexDirection="row" marginTop={32} justifyContent="center">
        <CallToActionButton
          rawText={'Pre-order ($2,500)' as any}
          onPress={() => window.open(paymentUrl, '_blank')}
          skipTracking
          primary
          large
        />
      </View>
    </Wrap>
  )
}
