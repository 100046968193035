import { CollectionSlug, getCollectionInfoFromSlug, getCollectionSlugFromInfo, IPositionQuery } from 'core'
import { useCallback, useMemo } from 'react'

import { useHistory } from '~/lite'

export const usePositionQuery = (collectionSlug: CollectionSlug) => {
  const history = useHistory()

  const { collectionType, query } = useMemo(() => getCollectionInfoFromSlug(collectionSlug), [collectionSlug])

  const setQuery = useCallback<(query: IPositionQuery) => void>(
    query =>
      history.replace(
        `/collections/${getCollectionSlugFromInfo({ collectionType, query } as Parameters<
          typeof getCollectionSlugFromInfo
        >[0])}`
      ),
    [history, collectionType]
  )

  return useMemo(() => ({ collectionType, query, setQuery }), [collectionType, query, setQuery])
}
