import React from 'react'
import _styled, { ThemeProvider as SCThemeProvider } from 'styled-components'

import type { ITheme } from '../theme'

export { createGlobalStyle, css, keyframes } from 'styled-components'

// our ssr script builds to esm so need to use a workaround for styled-components' default export
// https://github.com/styled-components/styled-components/issues/3601
export const styled = (typeof _styled === 'function' ? _styled : (_styled as any).default) as typeof _styled

interface IThemeProviderProps {
  theme: ITheme
  children?: React.ReactNode
}

export const ThemeProvider: React.FC<IThemeProviderProps> = ({ theme, children }) => (
  <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
)
