import {
  IMarketDirectory,
  IMarketMembership,
  IMarketPerson,
  IMarketProduct,
  IMarketProfile,
  INewMarketMembership,
  INewMarketPerson,
  INewMarketProduct
} from '../models'
import { Endpoint, get, post, put, remove } from '../util/api'
import type { IMarketProfileSetupData, IMarketProfileUpdateData } from '../util/helpers'

export type CreateMarketMembership = Endpoint<
  Record<string, never>,
  { success: true; membership: IMarketMembership },
  INewMarketMembership
>

export type UpdateMarketMembership = Endpoint<
  Record<string, never>,
  { success: true; membership: IMarketMembership },
  INewMarketMembership
>

export type DeleteMarketMembership = Endpoint<{ id: IMarketMembership['id'] }, { success: true }>

export type CreateMarketPerson = Endpoint<
  Record<string, never>,
  { success: true; person: IMarketPerson },
  INewMarketPerson
>

export type UpdateMarketPerson = Endpoint<
  Record<string, never>,
  { success: true; person: IMarketPerson },
  INewMarketPerson
>

export type DeleteMarketPerson = Endpoint<{ id: IMarketPerson['id'] }, { success: true }>

export type GetMarketDirectory = Endpoint<
  Record<string, never>,
  { success: true; directory: IMarketDirectory },
  Record<string, never>
>

type MarketProfileResponse = { success: true; profile: IMarketProfile | null }

export type GetMarketProfile = Endpoint<{ id: IMarketProfile['id'] }, MarketProfileResponse, Record<string, never>>

export type GetMarketProfileByUsername = Endpoint<
  { username: IMarketProfile['username'] },
  MarketProfileResponse,
  Record<string, never>
>

export type GetExampleMarketProfile = Endpoint<Record<string, never>, MarketProfileResponse, Record<string, never>>

export interface IMarketStateResponse {
  success: true
  person: IMarketPerson | null
  memberships: IMarketMembership[]
  profiles: IMarketProfile[]
}

export type GetMarketState = Endpoint<Record<string, never>, IMarketStateResponse, Record<string, never>>

export type UpdateMarketProfile = Endpoint<Record<string, never>, IMarketStateResponse, IMarketProfileUpdateData>

export type SetUpMarketProfile = Endpoint<Record<string, never>, IMarketStateResponse, IMarketProfileSetupData>

export type GenerateMarketProducts = Endpoint<
  { profileId: IMarketProfile['id'] },
  { success: true; products: IMarketProduct[] },
  { businessType: 'Individual' }
>

export type CreateMarketProfile = Endpoint<
  Record<string, never>,
  { success: true; profile: IMarketProfile },
  { businessType: 'Individual' }
>

export type CreateMarketProduct = Endpoint<
  Record<string, never>,
  { success: true; product: IMarketProduct },
  INewMarketProduct
>

export type UpdateMarketProduct = Endpoint<
  Record<string, never>,
  { success: true; product: IMarketProduct },
  INewMarketProduct
>

export type DeleteMarketProduct = Endpoint<{ id: IMarketProduct['id'] }, { success: true }>

export const createMarketMembership = (membership: INewMarketMembership) =>
  post<CreateMarketMembership>('/markets/memberships', membership)

export const updateMarketMembership = (id: IMarketMembership['id'], membership: INewMarketMembership) =>
  put<UpdateMarketMembership>(`/markets/memberships/${id}`, membership)

export const deleteMarketMembership = (id: IMarketMembership['id']) =>
  remove<DeleteMarketMembership>(`/markets/memberships/${id}`, {})

export const createMarketPerson = (person: INewMarketPerson) => post<CreateMarketPerson>('/markets/people', person)

export const updateMarketPerson = (id: IMarketPerson['id'], person: INewMarketPerson) =>
  put<UpdateMarketPerson>(`/markets/people/${id}`, person)

export const deleteMarketPerson = (id: IMarketPerson['id']) => remove<DeleteMarketPerson>(`/markets/people/${id}`, {})

export const getMarketDirectory = () => get<GetMarketDirectory>('/markets/directory')

export const getMarketProfile = (id: IMarketProfile['id']) => get<GetMarketProfile>(`/markets/profiles/${id}`)

export const getMarketProfileByUsername = (username: IMarketProfile['username']) =>
  get<GetMarketProfileByUsername>(`/markets/profiles/username/${username}`)

export const getExampleMarketProfile = () => get<GetExampleMarketProfile>('/markets/profiles/example')

export const getMarketState = (refreshStripe?: IMarketProfile['id']) =>
  get<GetMarketState>(`/markets/state${refreshStripe ? `?refreshStripe=${refreshStripe}` : ''}`)

export const updateMarketProfile = (id: IMarketProfile['id'], profile: IMarketProfileUpdateData) =>
  put<UpdateMarketProfile>(`/markets/profiles/${id}`, profile)

export const setUpMarketProfile = (id: IMarketProfile['id'], profile: IMarketProfileSetupData) =>
  put<SetUpMarketProfile>(`/markets/profiles/${id}/setup`, profile)

export const generateMarketProducts = (profileId: IMarketProfile['id'], data: GenerateMarketProducts['body']) =>
  post<GenerateMarketProducts>(`/markets/profiles/${profileId}/products/generate`, data)

export const createMarketProfile = (data: CreateMarketProfile['body']) =>
  post<CreateMarketProfile>('/markets/profiles', data)

export const createMarketProduct = (product: INewMarketProduct) =>
  post<CreateMarketProduct>('/markets/products', product)

export const updateMarketProduct = (id: IMarketProduct['id'], product: INewMarketProduct) =>
  put<UpdateMarketProduct>(`/markets/products/${id}`, product)

export const deleteMarketProduct = (id: IMarketProduct['id']) =>
  remove<DeleteMarketProduct>(`/markets/products/${id}`, {})

export const getMarketProfileQuery = (id?: IMarketProfile['id']) => ({
  queryKey: ['markets', 'profiles', id],
  queryFn: async () => {
    if (!id) {
      return null
    }

    const results = await getMarketProfile(id)
    return results.success ? results.profile : null
  }
})

export const getMarketProfileByUsernameQuery = (username?: IMarketProfile['username']) => ({
  queryKey: ['markets', 'profiles', 'username', username],
  queryFn: async () => {
    if (!username) {
      return null
    }

    const results = await getMarketProfileByUsername(username)
    return results.success ? results.profile : null
  }
})
