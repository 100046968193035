import { getAvatarInitials, getGradient } from 'core'
import React from 'react'

import { isWeb } from '../env'
import { useStore } from '../hooks'
import { css, styled } from '../styled'
import { Image } from './Image'
import { Text } from './Text'
import { AvatarSize, IAvatarProps } from './types'
import { View } from './View'

const sizes: Record<AvatarSize, number> = { tiny: 24, small: 32, medium: 40, large: 48, giant: 56, huge: 100 }

const GradientWrap = styled(View)<{ $gradient: [string, string] }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${props => props.$gradient[0]};
  ${props =>
    isWeb &&
    css`
      background-image: linear-gradient(135deg, ${props.$gradient[0]} 10%, ${props.$gradient[1]} 100%);
    `};
`

const StyledText = styled(Text)`
  color: #fff;
  user-select: none;
`

export const Avatar: React.FC<IAvatarProps> = ({
  source,
  recordKey,
  skeleton,
  size,
  onPress,
  testID,
  width = sizes.medium,
  height = sizes.medium,
  borderRadius
}) => {
  const theme = useStore(state => state.theme)
  const [isError, setIsError] = React.useState(false)
  const sizeNumber = size ? sizes[size] : width

  const style = {
    width: width ?? sizeNumber,
    height: height ?? sizeNumber,
    borderRadius: borderRadius ?? sizeNumber / 2
  }

  if (isError || skeleton) {
    return <View {...style} backgroundColor={theme.placeholder} />
  }

  if (source?.uri) {
    return (
      <Image
        testID={testID}
        source={source?.uri}
        onPress={onPress}
        onError={e => {
          if (e.nativeEvent.error) {
            setIsError(true)
          }
        }}
        resizeMode="cover"
        {...style}
      />
    )
  }

  if (!source && !skeleton && recordKey) {
    return (
      <GradientWrap onPress={onPress} {...style} $gradient={getGradient(recordKey)}>
        <StyledText rawText={getAvatarInitials(recordKey)} fontSize={Math.round(style.width * 0.35)} />
      </GradientWrap>
    )
  }

  return null
}
