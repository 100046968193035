/* eslint-disable @typescript-eslint/no-empty-function */
import { useQueryClient } from '@tanstack/react-query'
import {
  ClientRoutePath,
  encodeToken,
  INewPosition,
  IPosition,
  IProduct,
  ITeam,
  outOfCreditError,
  TranslatedText,
  validateNewPosition
} from 'core'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormGroup, HeaderButton, HeaderText, HtmlEditor, Input, KeyboardAvoidingView, Panel } from '~/components'
import {
  useApiErrors,
  useCreatePosition,
  useHistory,
  useTeamByUsername,
  useTranslation,
  useUpdatePosition
} from '~/hooks'
import {
  Button,
  DialogFooter,
  HoldMenuProvider,
  isWeb,
  PortalHost,
  styled,
  Text,
  toast,
  useLoadUserData,
  View
} from '~/lite'
import { emptyString } from '~/util'

import { Header } from '../styles'

type Data = INewPosition

const getProductOption = (product: IProduct) => ({
  value: product.id,
  label: product.name ?? product.description ?? emptyString
})

const GeneralError = styled(View)`
  margin-bottom: 15px;
  background-color: rgba(219 55 55 / 15%);
  padding: 10px 12px 9px;
`

const EditEmailWrap = styled(View)`
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const EmailPrefixInput = styled(Input)`
  flex: 1;
  text-align: end;
  font-size: 16px;
  font-weight: bold;
`

const EmailSuffixText = styled(Text)`
  flex: 1;
  text-align: start;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
`

export interface IPositionFormProps {
  isCreating: boolean
  onClose?(changed: boolean, positionId?: IPosition['id']): void
  team?: ITeam
  teamUsername: ITeam['username'] | null
  teamName: ITeam['name'] | null
  position?: IPosition
  onChange?(data: Data): void
  variant?: 'screener'
  // teamSubscriptionPlan?: ReturnType<typeof useTeamSubscriptionBenefit>['data']
}

export const PositionForm: React.FC<IPositionFormProps> = ({
  isCreating,
  onClose,
  position,
  teamUsername,
  teamName,
  // teamSubscriptionPlan,
  onChange,
  variant
}) => {
  const t = useTranslation()
  const history = useHistory()
  const loadUserData = useLoadUserData()
  const queryClient = useQueryClient()
  const { data: team } = useTeamByUsername(teamUsername)
  // const { data: products } = useProducts()
  // const basicProduct = useBasicProduct()
  // const [numberOfJobs, setNumberOfJobs] = useState(0)
  const { mutateAsync: createPosition, isLoading: isCreatingPosition } = useCreatePosition()
  const { mutateAsync: updatePosition, isLoading: isUpdatingPosition } = useUpdatePosition()
  // const { location, setLocation } = usePositionFormStore(state => ({
  //   location: state.location,
  //   setLocation: state.setLocation
  // }))

  // useEffect(() => {
  //   if (
  //     !teamSubscriptionPlan?.benefits ||
  //     !teamSubscriptionPlan?.metadata ||
  //     !('numberOfJobs' in teamSubscriptionPlan.benefits)
  //   ) {
  //     return
  //   }
  //   setNumberOfJobs(teamSubscriptionPlan.benefits.numberOfJobs)
  // }, [teamSubscriptionPlan])

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors }
  } = useForm<INewPosition & { teamName?: string | null }>({
    defaultValues: {
      teamId: team?.id as unknown as ITeam['id'],
      teamName: teamName,
      title: emptyString,
      location: emptyString,
      placeId: null,
      summary: position?.summary,
      description: position?.description,
      allowEmployee: false,
      allowContract: false,
      employeeCompensation: null,
      contractCompensation: null,
      experiences: [],
      applicationUrl: null,
      applicationEmail: null,
      applicationInstructions: null,
      remoteCreatedAt: null,
      stripeProductId: null,
      interviewState: 'Disabled',
      screenerEmail: emptyString
    }
  })

  const [emailSuffix, setEmailSuffix] = useState(team ? `@${team.username}.remoteambition.com` : '')
  useEffect(() => {
    setEmailSuffix(team ? `@${team.username}.remoteambition.com` : '')
  }, [team])

  const [apiErrors, setApiErrors] = useApiErrors(setError)
  const currentFormData = watch()

  /*
  useEffect(() => {
    onChange?.(currentFormData)
  }, [currentFormData, onChange])
  */

  const onSubmitButtonPress = handleSubmit(async formData => {
    if (isCreating) {
      // if (numberOfJobs <= 0) {
      //   toast.failure('You have run out of credits. Please upgrade to continue using this feature.')
      //   return
      // }
      const validation = await validateNewPosition(formData)

      if (validation.success) {
        const response = await createPosition(formData)
        response.success && response.position
          ? toast.success('Your new job posting is live!')
          : response.success && response.error && response.error === outOfCreditError
          ? toast.failure('You have run out of credits. Please upgrade to continue using this feature.')
          : toast.failure('An error has occurred')
        if (response.success && response.position) {
          !teamUsername && loadUserData()
          if (onClose) {
            onClose(true, response.position.id)
          } else {
            queryClient.clear()
            if (response.position.stripeProductId) {
              history.push(
                `/positions/${encodeToken(response.position.id)}/promote/${encodeToken(
                  response.position.stripeProductId
                )}`
              )
            } else {
              history.push(`/jobs/${encodeToken(response.position.id)}`)
            }
          }
        }
      } else {
        setApiErrors(validation.errors)
      }

      return
    }

    if (position) {
      const response = await updatePosition({
        ...position,
        ...formData,
        screenerEmail: formData.screenerEmail + emailSuffix
      })
      response.success ? toast.success('Your job posting has been updated') : toast.failure('An error has occurred')
      if (response.success) {
        if (onClose) {
          onClose(true, position.id)
        } else {
          queryClient.clear()
          history.push(`/jobs/${encodeToken(position.id)}`)
        }
      }

      if (!response.success && response.errors) {
        setApiErrors(response.errors)
      }
    }
  })

  useEffect(() => {
    if (team?.id) {
      setValue('teamId', team?.id)
    }
  }, [setValue, team?.id])

  useEffect(() => {
    if (teamName) {
      setValue('teamName', teamName)
    }
  }, [setValue, teamName])

  useEffect(() => {
    if (position) {
      const value = position.screenerEmail?.split('@').shift() || ''
      reset({ ...position, screenerEmail: value })
      // if (position.location && position.placeId) {
      //   setLocation({ name: position.location, placeId: position.placeId })
      // }
    }
  }, [reset, position])

  // useEffect(() => {
  //   setValue('location', location?.name || null)
  //   setValue('placeId', location?.placeId || null)
  // }, [location?.name, location?.placeId, setValue])

  /*
  <SummaryInput
    testID="position-summary-input"
    value={value || ''}
    maxCharacters={positionSummaryMaxCharacters}
    labelInfo={t('optional')}
    helperText={errors.summary?.message}
    tooltip={
      <SummaryTooltip>
        <Text text="Your job's summary is intended to describe the job in a concise way. Consider using this to highlight exciting aspects of the role, product, or team." />
      </SummaryTooltip>
    }
    inputRef={ref}
    {...field}
  />
  */

  const form = (
    <>
      <Controller
        name="title"
        control={control}
        render={({ field: { value, ref, ...field } }) => (
          <FormGroup label="Job Title" labelFor="title-input" helperText={errors.title?.message}>
            <Input testID="position-title-input" id="title-input" value={value || ''} ref={ref} {...field} />
          </FormGroup>
        )}
        rules={{ required: { value: true, message: t('This field is required') } }}
      />
      {/*<Controller
        name="teamName"
        control={control}
        render={({ field: { value, ref, ...field } }) => (
          <FormGroup label="Company" labelFor="team-name-input" helperText={errors.teamName?.message}>
            <Input
              disabled={!!teamName}
              testID="position-team-name-input"
              id="team-name-input"
              value={value || ''}
              ref={ref}
              {...field}
            />
          </FormGroup>
        )}
        rules={{ required: { value: true, message: t('This field is required') } }}
      />*/}
      {/* <QualificationInput data={currentFormData} errors={errors} updateField={setValue} /> */}
      <Controller
        name="location"
        control={control}
        render={({ field: { value, ref, ...field } }) => (
          <FormGroup label="Location" labelFor="location-input" helperText={errors.location?.message}>
            <Input testID="position-location-input" id="location-input" value={value || ''} ref={ref} {...field} />
          </FormGroup>
        )}
        rules={{ required: { value: true, message: t('This field is required') } }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field: { value, ref, ...field } }) => (
          <FormGroup label="Description" labelFor="description-input" helperText={errors.description?.message}>
            <HtmlEditor value={value || ''} bodyStyle={{ height: 200 }} {...field} />
          </FormGroup>
        )}
      />
      {!isCreating && (
        <Controller
          name="screenerEmail"
          control={control}
          render={({ field: { value, ref, ...field } }) => (
            <FormGroup label="Email" labelFor="screener-email-input" helperText={errors.screenerEmail?.message}>
              <EditEmailWrap>
                <EmailPrefixInput
                  testID="position-screener-email-input"
                  id="screener-email-input"
                  value={value || emptyString}
                  ref={ref}
                  {...field}
                />
                <EmailSuffixText rawText={emailSuffix as TranslatedText} />
              </EditEmailWrap>
            </FormGroup>
          )}
        />
      )}
      {!isCreating && variant !== 'screener' && (
        <Controller
          name="applicationUrl"
          control={control}
          render={({ field: { value, ref, ...field } }) => (
            <FormGroup
              label="Application URL"
              labelFor="application-url-input"
              helperText={errors.applicationUrl?.message}
            >
              <Input
                testID="position-application-url-input"
                id="application-url-input"
                {...field}
                value={value || ''}
                ref={ref}
                {...field}
              />
            </FormGroup>
          )}
        />
      )}
      {!isCreating && variant !== 'screener' && (
        <Controller
          name="applicationEmail"
          control={control}
          render={({ field: { value, ref, ...field } }) => (
            <FormGroup
              label="Application Email"
              labelFor="application-email-input"
              helperText={errors.applicationEmail?.message}
            >
              <Input
                testID="position-application-email-input"
                id="application-email-input"
                value={value || ''}
                ref={ref}
                autoCapitalize="none"
                {...field}
              />
            </FormGroup>
          )}
        />
      )}
      {/*<Controller
        name="interviewState"
        control={control}
        render={({ field: { value, onChange, ...field } }) => (
          <Checkbox
            label={'Enable Interview' as UntranslatedText}
            checked={value === 'Public'}
            onChange={e => onChange(e ? 'Public' : 'Disabled')}
            {...field}
          />
        )}
        />*/}
      {/*<Controller
        name="stripeProductId"
        control={control}
        render={({ field: { value, onChange, ref, ...field } }) => {
          const productSelected =
            value && products ? products.find(product => product.id === value) : basicProduct

          return (
            <FormGroup label="Product" labelFor="product-input" helperText={errors.stripeProductId?.message}>
              <Select<ReturnType<typeof getProductOption>>
                options={(products ?? [basicProduct]).map(getProductOption)}
                value={productSelected && getProductOption(productSelected)}
                onChange={option => option && onChange(option.value)}
                {...field}
              />
            </FormGroup>
          )
        }}
      />*/}
    </>
  )

  if (isWeb) {
    const to: ClientRoutePath = isCreating || !position?.id ? '/' : `/jobs/${encodeToken(position.id)}`

    return (
      <>
        {apiErrors?.general && (
          <GeneralError>
            <Text text={apiErrors.general} />
          </GeneralError>
        )}
        {form}
        <DialogFooter>
          <Button
            action={isCreating ? 'CancelNewPosition' : 'CancelEditPosition'}
            text="Cancel"
            {...(onClose ? { onPress: () => onClose(false) } : { to })}
            minimal
          />
          <Button
            action={isCreating ? 'CreatePosition' : 'UpdatePosition'}
            loading={isCreatingPosition || isUpdatingPosition}
            text={isCreating ? 'Create' : 'Update'}
            onPress={onSubmitButtonPress}
            primary
          />
        </DialogFooter>
      </>
    )
  }

  return (
    <HoldMenuProvider>
      <PortalHost>
        <Header>
          <HeaderButton
            marginLeft="s"
            action="CancelNewPosition"
            text="Cancel"
            to={isCreating || !position?.id ? '/' : `/jobs/${encodeToken(position.id)}`}
          />
          <View flexDirection="column">
            <HeaderText text={isCreating ? 'Post a Job' : 'Edit Job'} />
            {/* {isCreating && (
              <Text mt="s" textAlign="center" rawText={t('{{count}} credits left', { count: numberOfJobs })} />
            )} */}
          </View>
          <HeaderButton
            marginRight="s"
            action="CreatePosition"
            primary
            testID="submit-position-button"
            loading={isCreatingPosition || isUpdatingPosition}
            loadingText={isCreating ? 'Create' : 'Update'}
            text={isCreating ? 'Create' : 'Update'}
            onPress={onSubmitButtonPress}
          />
        </Header>
        <Panel errors={apiErrors} style={{ height: '100%', borderRadius: 0 }} testID="position-form">
          <KeyboardAvoidingView>{form}</KeyboardAvoidingView>
        </Panel>
      </PortalHost>
    </HoldMenuProvider>
  )
}
