import * as SelectPrimitive from '@radix-ui/react-select'
import { TranslatedText } from 'core'
import React, { forwardRef, useState } from 'react'

import { useStore } from '../hooks'
import { css, styled } from '../styled'
import { animations } from './animations'
import { Icon } from './Icon'
import { Text } from './Text'
import { View } from './View'

type ISelectTriggerProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>

type ISelectSeparatorProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>

type ISelectContentProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & { position?: string }

type ISelectLabelProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>

type ISelectItemProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { selected: boolean }

const StyledSelectTrigger = styled(SelectPrimitive.Trigger)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.buttonBackground};
  padding: 0 16px;
  cursor: pointer;
  transition: background 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover,
  &[data-state='open'] {
    background: ${props => props.theme.buttonHover};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const SelectTriggerInner = styled(View)`
  flex-direction: row;
  padding: 8px 0;
`

const StyledSelectIcon = styled(Icon)``

const StyledSelectContent = styled(SelectPrimitive.Content)<{ position: string }>`
  position: relative;
  z-index: 100;
  min-width: 8rem;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  border-radius: 6px;
  padding: 4px;
  background: ${props => (props.theme.dark ? props.theme.inputBackground : '#fff')};
  box-shadow: ${props =>
    props.theme.dark
      ? 'rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'
      : 'rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'};
  border: 1px solid ${props => (props.theme.dark ? props.theme.buttonBackground : '#fff')};
  animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &[data-state='open'] {
    animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: animate-out 0.3s;
  }

  &[data-side='bottom'] {
    animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  ${props =>
    props.position === 'popper' &&
    css`
      &[data-side='bottom'] {
        transform: translateY(1);
      }
    `}
`

const StyledSelectViewport = styled(SelectPrimitive.Viewport)<{ position: string }>`
  padding: 0.25rem;

  ${props =>
    props.position === 'popper' &&
    css`
      height: var(--radix-select-trigger-height);
      width: 100%;
      min-width: var(--radix-select-trigger-width);
    `}
`

const StyledSelectLabel = styled(SelectPrimitive.Label)`
  padding: 8px;
  font-weight: 600;
`

const StyledSelectItem = styled(SelectPrimitive.Item)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  user-select: none;
  align-items: center;
  border-radius: 6px;
  padding: 8px;
  outline: none;

  &:focus {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`

const RightSlot = styled(View)`
  margin-left: auto;
  padding-left: 16px;

  [data-disabled] & {
    opacity: 0.5;
  }

  svg {
    color: ${props => props.theme.primary} !important;
  }

  [data-highlighted] & {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }
`

const StyledSelectSeparator = styled(SelectPrimitive.Separator)`
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  height: 1px;
  background-color: ${props => props.theme.border};
`

interface IOption {
  label: TranslatedText
  value: string | number | null
}

interface ISelectProps<T extends IOption> {
  id?: string
  options: T[]
  value: T | null
  onChange(option: T | null): void
  placeholder?: TranslatedText
}

export function Select<TOption extends IOption>({
  options,
  value,
  onChange,
  placeholder,
  ...props
}: ISelectProps<TOption>): ReturnType<React.FC<ISelectProps<TOption>>> {
  const renderOption = (option: TOption) => (
    <SelectItem key={option.value} value={option.value?.toString() ?? ''} selected={option.value === value}>
      <Text rawText={option.label} />
    </SelectItem>
  )

  // placeholder prop must be a DOM node, not a string, otherwise there will be re-rendering errors
  return (
    <SelectPrimitive.Root
      value={!value || value.value === null ? undefined : value.value.toString()}
      {...props}
      onValueChange={value => {
        const option = options.find(option => option.value?.toString() === value.toString())

        if (!option && value !== '') {
          throw new Error(`Option with value "${value}" not found`)
        }

        onChange(option ?? null)
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder={placeholder ? <Text rawText={placeholder} /> : undefined} />
      </SelectTrigger>
      <SelectContent sideOffset={4} position="item-aligned">
        {options.map(renderOption)}
      </SelectContent>
    </SelectPrimitive.Root>
  )
}

export const SelectGroup = SelectPrimitive.Group

export const SelectValue = SelectPrimitive.Value

export const SelectTrigger = forwardRef<typeof SelectPrimitive.Trigger, ISelectTriggerProps>(
  ({ children, ...props }, ref) => {
    const theme = useStore(state => state.theme)

    return (
      <StyledSelectTrigger ref={ref as any} {...props}>
        <SelectTriggerInner>{children}</SelectTriggerInner>
        <SelectPrimitive.Icon asChild>
          <StyledSelectIcon icon="chevron-down-outline" size={14} color={theme.text} marginLeft={4} />
        </SelectPrimitive.Icon>
      </StyledSelectTrigger>
    )
  }
)

export const SelectContent = forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, ISelectContentProps>(
  ({ children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
      <StyledSelectContent ref={ref} position={position} {...props}>
        <StyledSelectViewport position={position}>{children}</StyledSelectViewport>
      </StyledSelectContent>
    </SelectPrimitive.Portal>
  )
)

export const SelectLabel = forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, ISelectLabelProps>(
  (props, ref) => <StyledSelectLabel ref={ref} {...props} />
)

export const SelectItem = forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, ISelectItemProps>(
  ({ selected, ...props }, ref) => {
    const theme = useStore(state => state.theme)
    const [isFocused, setIsFocused] = useState(false)

    return (
      <StyledSelectItem ref={ref} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} {...props}>
        <SelectPrimitive.ItemText>{props.children}</SelectPrimitive.ItemText>
        <RightSlot>
          <SelectPrimitive.ItemIndicator>
            <Icon icon="checkmark-outline" color={isFocused ? '#fff' : theme.primary} />
          </SelectPrimitive.ItemIndicator>
        </RightSlot>
      </StyledSelectItem>
    )
  }
)

export const SelectSeparator = forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, ISelectSeparatorProps>(
  (props, ref) => <StyledSelectSeparator ref={ref} {...props} />
)
