import { CollectionCard, collectionCardAspectRatio, collectionCardMinWidth } from '@ambition/module-shared'
import { ICollection } from 'core'
import React, { useCallback } from 'react'

import { Container, GridList, IGridListProps } from '~/components'
import { getWindowWidth } from '~/util'

export interface ICollectionListProps
  extends Omit<IGridListProps<ICollection>, 'data' | 'renderItem' | 'minItemWidth' | 'giveFullRow'> {
  collections: ICollection[]
  isLoading?: boolean
  testID?: string
}

export const CollectionList: React.FC<ICollectionListProps> = ({ collections, isLoading, ...listProps }) => {
  const renderItem = useCallback<NonNullable<IGridListProps<ICollection>['renderItem']>>(({ item: collection }) => {
    let id = collection.id as string
    if (!collection.id && collection.url) {
      const [, ...urlParts] = collection.url.split('/')
      id = urlParts.join('-')
    }
    return <CollectionCard testID={`collection-card-${id}`} key={id} collection={collection} />
  }, [])

  return (
    <Container>
      <GridList<ICollection>
        {...listProps}
        isLoading={isLoading}
        data={collections}
        renderItem={renderItem}
        minItemWidth={collectionCardMinWidth}
        width={getWindowWidth()}
        aspectRatio={collectionCardAspectRatio}
        giveFullRow={item => !!item.children}
        animation="fadeInUp"
        // ListHeaderComponent={listHeader}
      />
    </Container>
  )
}
