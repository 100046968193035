import {
  formatCurrency,
  IMarketProduct,
  IMarketProfile,
  INewMarketProduct,
  presentmentCurrencies,
  TranslatedText
} from 'core'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, UseFieldArrayReturn, useForm } from 'react-hook-form'

import { FormGroup, Input } from '~/components'
import { useCreateMarketProduct, useDeleteMarketProduct, useUpdateMarketProduct } from '~/hooks'
import { Button, Dialog, IDialogProps, Select, styled, Text, toast, useStore, useTranslation, View } from '~/lite'

const FeatureList = styled(View)`
  width: 100%;
  gap: 8px;
`

const Feature = styled(View)`
  flex-direction: row;
  align-items: center;
`

const DeleteButton = styled(Button)`
  background: none;
  padding: 0;
  height: 40px;
  width: 40px;
  opacity: 0.5;

  &:hover {
    background: none;
    opacity: 1;
  }
`

const CurrencyWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-right: 4px;
  border-radius: 10px;
  border-width: 1px;
  background: ${props => (props.theme.dark ? props.theme.background : props.theme.cardBackground)};
`

interface IProductDialogProps extends Omit<IDialogProps, 'trigger' | 'children'> {
  profile: IMarketProfile
  products: UseFieldArrayReturn<IMarketProfile, 'products', 'id'>
  product: IMarketProduct | INewMarketProduct
  onClose(): void
}

export const ProductDialog: React.FC<IProductDialogProps> = ({ profile, products, product, onClose, ...props }) => {
  const t = useTranslation()
  const locale = useStore(state => state.locale)
  const theme = useStore(state => state.theme)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const isNewForm = !('id' in product)

  const productIndex = isNewForm ? -1 : products.fields.findIndex(item => item.id === product.id)

  const { mutateAsync: createProduct, isLoading: isCreatingProduct } = useCreateMarketProduct({
    onSuccess: data => {
      if (data.success) {
        products.append(data.product)
        onClose()
        toast.success('Added product')
      }
    }
  })

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } = useUpdateMarketProduct({
    onSuccess: data => {
      if (data.success) {
        products.update(productIndex, data.product)
        onClose()
        toast.success('Updated product')
      }
    }
  })

  const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useDeleteMarketProduct({
    onSuccess: data => {
      if (data.success) {
        products.remove(productIndex)
        onClose()
        toast.success('Deleted product')
      }
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm<IMarketProduct>()

  const { replace: replaceFeatures, ...features } = useFieldArray({ control, name: 'features' })

  useEffect(() => {
    const resetForm = (product: IMarketProduct | INewMarketProduct) => {
      reset(product)

      // useFieldArray doesn't seem to update when useForm reset is called, so we need to manually update it
      replaceFeatures((product.features as any) ?? [])
    }

    resetForm(product)
    setIsConfirmingDelete(false)
  }, [reset, product, replaceFeatures])

  const formValues = watch()

  const currencyOptions = presentmentCurrencies.map(currency => ({
    value: currency,
    label: currency as TranslatedText
  }))

  const disableActions = isCreatingProduct || isUpdatingProduct || isDeletingProduct

  return (
    <Dialog
      {...props}
      trigger={null}
      title={product.isRecurring ? (isNewForm ? 'Add Plan' : 'Edit Plan') : isNewForm ? 'Add Product' : 'Edit Product'}
      disableAutoFocus={!isNewForm}
      footer={
        <>
          {!isNewForm && (
            <>
              <Button
                text={isConfirmingDelete ? 'Confirm Delete' : 'Delete'}
                disabled={disableActions}
                loading={isDeletingProduct}
                onPress={() => {
                  if (!isConfirmingDelete) {
                    setIsConfirmingDelete(true)
                  } else {
                    deleteProduct(product.id)
                  }
                }}
                skipTracking
                dangerous
              />
              <View flex={1} />
            </>
          )}
          <Button text="Cancel" onPress={onClose} disabled={disableActions} skipTracking />
          <Button
            text="Save"
            onPress={handleSubmit(values => {
              const data = { ...values, isRecurring: product.isRecurring }
              isNewForm ? createProduct(data) : updateProduct(data)
            })}
            disabled={disableActions}
            loading={isCreatingProduct || isUpdatingProduct}
            skipTracking
            primary
          />
        </>
      }
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <FormGroup label="Name" helperText={errors.name?.message} style={{ marginBottom: 0 }} isRequired>
            <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
          </FormGroup>
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <FormGroup
            label="Description"
            helperText={errors.description?.message}
            style={{ marginBottom: 0 }}
            isRequired={false}
          >
            <Input
              {...field}
              type="textarea"
              wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }}
              numberOfLines={3}
            />
          </FormGroup>
        )}
      />
      {product.isRecurring ? (
        <FormGroup
          label="Monthly price"
          helperText={errors.price?.message || errors.currency?.message}
          style={{ width: 'auto', marginBottom: 0 }}
          isRequired
        >
          <View flexDirection="row" alignItems="center">
            <CurrencyWrap>
              <Controller
                control={control}
                name="price"
                rules={{ required: { value: true, message: t('This field is required') } }}
                render={({ field }) => (
                  <Input
                    {...field}
                    keyboardType="numeric"
                    wrapStyle={{ width: 100, backgroundColor: 'transparent', borderWidth: 0, borderRadius: 0 }}
                  />
                )}
              />
              <Controller
                control={control}
                name="currency"
                rules={{ required: { value: true, message: t('This field is required') } }}
                render={({ field: { value, onChange, ...field } }) => (
                  <Select
                    {...field}
                    options={currencyOptions}
                    value={currencyOptions.find(option => option.value === value) ?? null}
                    onChange={value => onChange(value?.value)}
                  />
                )}
              />
            </CurrencyWrap>
            <View flex={1}>
              {!!formValues.currency && !!formValues.price && (
                <Text
                  rawText={t('{{amount}} / month', {
                    amount: formatCurrency(locale, formValues.currency, formValues.price, true)
                  })}
                  marginLeft="s"
                  opacity={0.8}
                />
              )}
            </View>
          </View>
        </FormGroup>
      ) : (
        <FormGroup
          label="Estimate"
          helperText={errors.estimateMin?.message || errors.estimateMax?.message}
          style={{ marginBottom: 0 }}
          isRequired={false}
        >
          <View flexDirection="row">
            <Controller
              control={control}
              name="estimateMin"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  {...field}
                  keyboardType="numeric"
                  value={value ? +value / 60 : ''}
                  onChange={value => onChange(+value * 60)}
                  wrapStyle={{ width: 80, backgroundColor: theme.background, borderColor: theme.border }}
                />
              )}
            />
            <View justifyContent="center" marginX="s">
              <Text text="-" />
            </View>
            <Controller
              control={control}
              name="estimateMax"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  {...field}
                  keyboardType="numeric"
                  value={value ? +value / 60 : ''}
                  onChange={value => onChange(+value * 60)}
                  wrapStyle={{ width: 80, backgroundColor: theme.background, borderColor: theme.border }}
                />
              )}
            />
            <View justifyContent="center" marginX="s">
              <Text text="hours" />
            </View>
          </View>
        </FormGroup>
      )}

      <FeatureList>
        <Text marginX="xs" text="Feature list" />
        {features.fields.map((field, index) => (
          <Controller
            key={field.id}
            control={control}
            name={`features.${index}.name`}
            render={({ field }) => (
              <Feature>
                <Input
                  {...field}
                  wrapStyle={{ flex: 1, backgroundColor: theme.background, borderColor: theme.border }}
                />
                <DeleteButton icon="trash" onPress={() => features.remove(index)} skipTracking />
              </Feature>
            )}
          />
        ))}
        <View flexDirection="row">
          <Button onPress={() => features.append({ name: '' as TranslatedText })} icon="add" text="Add" skipTracking />
          <View flex={1} />
        </View>
      </FeatureList>
    </Dialog>
  )
}
