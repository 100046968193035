import React, {
  forwardRef,
  InputHTMLAttributes,
  startTransition,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import type { KeyboardTypeOptions } from 'react-native'

import { useTranslation } from '~/hooks'
import { SpaceVariant, useBreakpoints, useOnHydrate } from '~/lite'

import { CurrencyInput, TextAreaInput, TextInput, Wrap } from './styles'
import { IInputProps, InputRef } from './types'

export type { IInputProps, InputRef } from './types'

const keyboardTypeProps: Record<
  KeyboardTypeOptions,
  [InputHTMLAttributes<HTMLInputElement>['type'], InputHTMLAttributes<HTMLInputElement>['inputMode']]
> = {
  'ascii-capable': ['text', 'text'],
  'decimal-pad': ['number', 'decimal'],
  default: ['text', 'text'],
  'email-address': ['email', 'email'],
  'name-phone-pad': ['text', 'text'],
  'number-pad': ['number', 'numeric'],
  'numbers-and-punctuation': ['text', 'text'],
  numeric: ['number', 'numeric'],
  'phone-pad': ['tel', 'tel'],
  twitter: ['text', 'text'],
  url: ['url', 'url'],
  'visible-password': ['text', 'text'],
  'web-search': ['search', 'search']
}

export const Input = forwardRef<InputRef, IInputProps<string>>(
  (
    {
      type,
      placeholder,
      rawPlaceholder,
      onChange,
      disabled,
      hidden,
      accessoryLeft,
      accessoryRight,
      wrapStyle,
      flex,
      width,
      onFocus,
      onBlur,
      onKeyPress,
      onEnterKeyPress,
      keyboardType,
      testID,
      secureTextEntry,
      defaultValue,
      value,
      id,
      autoCapitalize,
      autoFocus,
      autoGrow,
      currencyInputOptions,
      numberOfLines,
      maxHeight: maxHeightProp,
      ...rest
    },
    ref
  ) => {
    const t = useTranslation()
    const { isPhone } = useBreakpoints()
    const inputRef = useRef<HTMLElement | HTMLTextAreaElement>(null)
    const [isComposing, setIsComposing] = useState(false)

    useImperativeHandle(ref, () => ({
      focus: () => (inputRef.current as any)?.focus?.(),
      getValue: () => (inputRef.current as any)?.value,
      clear: () => ((inputRef.current as any).value = ''),
      setValue: (value: string) => ((inputRef.current as any).value = value),
      select: () => (inputRef.current as any)?.select?.()
    }))

    const [inputType, inputMode]: [
      InputHTMLAttributes<HTMLInputElement>['type'],
      InputHTMLAttributes<HTMLInputElement>['inputMode']
    ] = secureTextEntry ? ['password', undefined] : keyboardTypeProps[keyboardType ?? 'default']

    const maxHeight = maxHeightProp === null ? undefined : autoGrow ? (isPhone ? 80 : 340) : maxHeightProp

    const handleAutoGrow = useMemo(() => {
      const doAutoGrow = () => {
        if (autoGrow) {
          startTransition(() => {
            if (inputRef?.current) {
              inputRef.current.style.height = 'inherit'
              inputRef.current.style.height = `${inputRef.current?.scrollHeight}px`
              inputRef.current.style.overflow = `${
                maxHeight && inputRef?.current?.scrollHeight > maxHeight ? 'auto' : 'hidden'
              }`
            }
          })
        }
      }

      if (value && autoGrow) {
        doAutoGrow()
      }

      return doAutoGrow
    }, [autoGrow, maxHeight, value])

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange?.(e.target.value)
        handleAutoGrow()
      },
      [onChange, handleAutoGrow]
    )

    useOnHydrate(() => {
      handleAutoGrow()
    })

    const onCompositionStart = useCallback(() => setIsComposing(true), [])

    const onCompositionEnd = useCallback(() => setIsComposing(false), [])

    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !isComposing && !e.shiftKey && onEnterKeyPress) {
          e.preventDefault()
          onEnterKeyPress()
        } else {
          onKeyPress?.(e.key)
        }
      },
      [isComposing, onEnterKeyPress, onKeyPress]
    )

    const inputProps = {
      id,
      ...(testID && { 'data-testid': testID }),
      ...(type === 'textarea' && { scrollEnabled: false, multiline: true }),
      ...rest,
      ref: inputRef as any,
      type: inputType,
      inputMode,
      placeholder:
        typeof rawPlaceholder === 'undefined'
          ? typeof placeholder === 'undefined'
            ? undefined
            : t(placeholder)
          : rawPlaceholder,
      value: value ?? defaultValue,
      onChange: handleOnChange,
      autoCapitalize,
      autoFocus,
      disabled: !!disabled,
      minWidth: 0,
      flex: 1,
      padding: 11,
      paddingLeft: (accessoryLeft ? 's' : 'm') as SpaceVariant,
      paddingRight: (accessoryRight ? 's' : 'm') as SpaceVariant,
      background: 'transparent',
      border: 'none',
      borderRadius: 0,
      fontSize: 16,
      onFocus,
      onBlur,
      onCompositionStart,
      onCompositionEnd,
      onKeyDown
    }

    // iOS Safari will zoom in on focus if input fontSize isn't at least 16px
    return (
      <Wrap
        flex={flex}
        display={hidden ? 'none' : 'flex'}
        flexDirection="row"
        alignItems={autoGrow ? 'end' : 'center'}
        width={width}
        borderWidth={1}
        borderRadius={10}
        paddingLeft={accessoryLeft ? 'm' : 0}
        paddingRight={accessoryRight ? 'm' : 0}
        style={wrapStyle}
      >
        {accessoryLeft}
        {currencyInputOptions ? (
          <CurrencyInput {...currencyInputOptions} {...inputProps} />
        ) : numberOfLines || autoGrow ? (
          <TextAreaInput {...inputProps} maxHeight={maxHeight} rows={numberOfLines ?? 1} />
        ) : (
          <TextInput {...inputProps} />
        )}
        {accessoryRight}
      </Wrap>
    )
  }
) as <T>(props: IInputProps<T> & { ref?: React.ForwardedRef<InputRef> }) => JSX.Element
