import { Lottie } from '@ambition/module-shared'
import { GetJobDashboardPath, IPosition } from 'core'
import React from 'react'

import { AnimatedView, BottomSheet, IBottomSheetProps, View } from '~/components'
import { Button, encodeToken, styled, Text, useStore } from '~/lite'

const MessageText = styled(Text)`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-wrap: balance;
`

interface IApplicationStatusProps {
  status: 'Success' | 'Failure'
  isOpen: IBottomSheetProps['isOpen']
  onClose: IBottomSheetProps['onClose']
  positionId: IPosition['id']
  getJobDashboardPath?: GetJobDashboardPath
  dashboard?: boolean
}

export const ApplicationStatus: React.FC<IApplicationStatusProps> = ({
  isOpen,
  onClose,
  positionId,
  status,
  getJobDashboardPath,
  dashboard
}) => {
  const { theme } = useStore()

  if (status === 'Success') {
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['45%']}
        alwaysOpen={1}
        dashboard={dashboard}
        useBottomSheetModal
      >
        <View paddingX="m" paddingY="m" justifyContent="center" alignItems="center">
          <Lottie animation="checkCircle" loop={false} width={150} height={150} duration={2500} />
          <AnimatedView animation="FadeIn" duration={500} delay={2000}>
            <MessageText marginTop="s" text="Your application has been submitted!" />
          </AnimatedView>
          <View marginTop="m" marginBottom="m">
            <Button
              text="Done"
              marginTop="m"
              to={getJobDashboardPath ? undefined : `/positions/related/${encodeToken(positionId)}`}
              onPress={onClose}
              primary
              large
              skipTracking
            />
          </View>
        </View>
      </BottomSheet>
    )
  }

  if (status === 'Failure') {
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['45%']}
        alwaysOpen={1}
        dashboard={dashboard}
        useBottomSheetModal
      >
        <View paddingX="m" paddingBottom="m" justifyContent="center" alignItems="center">
          <Lottie animation="errorCircle" width={150} height={150} />
          <MessageText text="Failed to send your application." color={theme.error} />
          <Text text="Please find another job to apply for." textAlign="center" marginTop="s" />
          <View marginTop="l" marginBottom="m">
            {getJobDashboardPath ? (
              <Button text="OK" onPress={onClose} primary large skipTracking />
            ) : (
              <Button
                text="See more jobs like this"
                to={`/positions/related/${encodeToken(positionId)}`}
                onPress={onClose}
                primary
                large
                skipTracking
              />
            )}
          </View>
        </View>
      </BottomSheet>
    )
  }

  return null
}
