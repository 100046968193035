import { IMarketMembership, IMarketPerson, IMarketProfile } from 'core'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormGroup, Input } from '~/components'
import { useCreateMarketMembership, useDeleteMarketMembership, useUpdateMarketMembership } from '~/hooks'
import { Button, Dialog, IDialogProps, toast, useStore, View } from '~/lite'

interface IMembershipDialogProps extends Omit<IDialogProps, 'trigger' | 'children'> {
  profiles: Pick<IMarketProfile, 'id'>[] | null
  person: Pick<IMarketPerson, 'id'> | null
  membership: IMarketMembership | null
  onClose(membership: IMarketMembership | null, action: 'create' | 'update' | 'delete' | null): void
}

export const MembershipDialog: React.FC<IMembershipDialogProps> = ({
  profiles,
  person,
  membership,
  onClose,
  ...props
}) => {
  const theme = useStore(state => state.theme)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const { mutateAsync: createMembership, isLoading: isCreatingMembership } = useCreateMarketMembership({
    onSuccess: data => {
      if (data.success) {
        onClose(data.membership, 'create')
        toast.success('Invitation has been sent')
      } else {
        toast.failure()
      }
    },
    onError: () => {
      toast.failure()
    }
  })

  const { mutateAsync: updateMembership, isLoading: isUpdatingMembership } = useUpdateMarketMembership({
    onSuccess: data => {
      if (data.success) {
        onClose(data.membership, 'update')
        toast.success('Changes saved')
      } else {
        toast.failure()
      }
    },
    onError: () => {
      toast.failure()
    }
  })

  const { mutateAsync: deleteMembership, isLoading: isDeletingMembership } = useDeleteMarketMembership({
    onSuccess: data => {
      if (data.success) {
        onClose(membership, 'delete')
        toast.success('Deleted invitation')
      } else {
        toast.failure()
      }
    },
    onError: () => {
      toast.failure()
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IMarketMembership>()

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      reset({ marketProfileId: profiles[0].id, marketPersonId: person?.id, ...membership })
    }

    setIsConfirmingDelete(false)
  }, [reset, profiles, person, membership])

  const disableActions = isCreatingMembership || isUpdatingMembership || isDeletingMembership

  return (
    <Dialog
      {...props}
      trigger={null}
      title="Request to join team"
      disableAutoFocus={!!membership}
      footer={
        <>
          {membership?.state === 'Pending' && (
            <>
              <Button
                text={isConfirmingDelete ? 'Confirm Delete' : 'Delete'}
                disabled={disableActions}
                loading={isDeletingMembership}
                onPress={() => {
                  if (!isConfirmingDelete) {
                    setIsConfirmingDelete(true)
                  } else {
                    deleteMembership(membership.id)
                  }
                }}
                skipTracking
                dangerous
              />
              <View flex={1} />
            </>
          )}
          <Button text="Cancel" onPress={() => onClose(null, null)} disabled={disableActions} skipTracking />
          <Button
            text={membership ? 'Update' : 'Send'}
            onPress={handleSubmit(values => (membership ? updateMembership(values) : createMembership(values)))}
            disabled={disableActions}
            loading={isCreatingMembership || isUpdatingMembership}
            skipTracking
            primary
          />
        </>
      }
    >
      <Controller
        control={control}
        name="note"
        render={({ field }) => (
          <FormGroup helperText={errors.note?.message} style={{ marginBottom: 0 }}>
            <Input
              type="textarea"
              numberOfLines={4}
              placeholder="Add a comment"
              autoGrow
              {...field}
              wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }}
            />
          </FormGroup>
        )}
      />
    </Dialog>
  )
}
