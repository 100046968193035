import { Launchpad } from '@ambition/module-content'
import { ResumeImport, SignUpNote } from '@ambition/module-shared'
import { encodeToken, IMarketProfile } from 'core'
import React, { useCallback, useState } from 'react'

import { Menu } from '~/components'
import { useCreateMarketProfile, useMarketState } from '~/hooks'
import { Button, styled, useHistory, useStore, useTranslation, View } from '~/lite'

import { currentMarketProfileSetupVersion, SetupDialog } from './SetupDialog'

const Wrap = styled(View)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`

const HighlightedButton = styled(Button)`
  border: ${props => (props.theme.dark ? '2px solid rgba(255, 255, 255, 0.1)' : 'none')};
  box-shadow: 0 0 5px rgba(${props => props.theme.backgroundRgb}, 1);
`

const SignUpNoteWrap = styled.div`
  display: block;
  padding: 0 8px;
  margin-top: -4px;
  font-size: 11px;
  text-align: center;
  opacity: 0.8;
  text-wrap: balance;

  a:hover {
    text-decoration: underline;
  }
`

export const LaunchpadSetup: React.FC = () => {
  const t = useTranslation()
  const history = useHistory()
  const resumeImportState = useStore(state => state.resumeImportState)

  const [pendingProfile, setPendingProfile] = useState<IMarketProfile | null>(null)
  const [hasCreatedProfile, setHasCreatedProfile] = useState(false)
  const { data: marketState, refetch: refetchMarketState, isFetching: isFetchingMarketState } = useMarketState()
  const { mutateAsync: doCreateMarketProfile, isLoading: isCreatingProfile } = useCreateMarketProfile({
    onSuccess: () => {
      setHasCreatedProfile(true)
      refetchMarketState()
    }
  })

  const createMarketProfile = useCallback(
    async (redirect: boolean) => {
      if (!pendingProfile && !isCreatingProfile && !hasCreatedProfile) {
        const result = await doCreateMarketProfile({ businessType: 'Individual' })

        if (result.success) {
          setPendingProfile(result.profile)
        }
      }
    },
    [pendingProfile, isCreatingProfile, hasCreatedProfile, doCreateMarketProfile]
  )

  const [isUploadingResume, setIsUploadingResume] = useState(false)
  const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(true)
  const isImportingResume = resumeImportState === 'Pending' || resumeImportState === 'Processing'
  const hasImportedResume = resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success'

  const isSettingUp = isCreatingProfile || (hasCreatedProfile && isFetchingMarketState)

  const getSetupComponent = () => {
    if (
      marketState?.profiles.length &&
      marketState.profiles.some(profile => profile.state === 'Draft' || profile.state === 'Live')
    ) {
      if (marketState.profiles.length === 1) {
        return (
          <HighlightedButton
            text="Open dashboard"
            to={`/launchpad/${encodeToken(marketState.profiles[0].id)}`}
            primary
            skipTracking
            large
          />
        )
      } else {
        return (
          <Menu
            align="end"
            triggerStyle={{ flex: 1, cursor: 'pointer' }}
            items={marketState.profiles.map(profile => ({
              rawText: profile.companyName ?? t('Open dashboard'),
              onPress: () => history.push(`/launchpad/${encodeToken(profile.id)}`)
            }))}
          >
            <HighlightedButton text="Open dashboard" primary skipTracking large />
          </Menu>
        )
      }
    }

    if (hasImportedResume) {
      return (
        <HighlightedButton
          text={pendingProfile ? 'Next' : 'Get started'}
          loadingText="Generating your site..."
          onPress={() => createMarketProfile(true)}
          disabled={isSettingUp}
          loading={isSettingUp}
          primary
          skipTracking
          large
        />
      )
    }

    return (
      <Wrap>
        <ResumeImport
          variant="Launchpad"
          method="Launchpad"
          onUploadChange={() => {
            setIsUploadingResume(true)
            createMarketProfile(false)
          }}
          onChange={() => {}}
          skipSignupNote
        />
        {!isUploadingResume && !isImportingResume && !isSettingUp && (
          <SignUpNoteWrap>
            <SignUpNote style={{ fontSize: 11 }} short />
          </SignUpNoteWrap>
        )}
      </Wrap>
    )
  }

  const currentProfile = pendingProfile ?? marketState?.profiles[0]

  const needsSetup =
    !!currentProfile &&
    (currentProfile.setupVersion ?? 0) < currentMarketProfileSetupVersion &&
    (isImportingResume || hasImportedResume)

  return (
    <>
      <Launchpad setupComponent={getSetupComponent()} />
      {needsSetup && (
        <SetupDialog
          profile={currentProfile}
          open={isSetupDialogOpen}
          onClose={() => {
            setIsSetupDialogOpen(false)
            history.push(`/launchpad/${encodeToken(currentProfile.id)}`)
          }}
        />
      )}
    </>
  )
}
