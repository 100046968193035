import { RELATED_POSITION_PAGE_INDEX, RELATED_POSITION_PAGE_LIMIT } from 'core'
import React from 'react'

import { useRelatedPositions, useTranslation } from '~/hooks'
import { encodeToken } from '~/lite'

import { PositionSection } from '../../PositionSection'
import { RelatedPositionSectionProps } from './types'

export const RELATED_POSITION_QUERY = 'positions/related'

export const RelatedPositionSection: React.FC<RelatedPositionSectionProps> = ({
  id: mainPositionId,
  hideAnalyzeButtonOnPositionSection,
  getJobDashboardPath
}) => {
  const t = useTranslation()
  const { data, isLoading } = useRelatedPositions(
    mainPositionId,
    RELATED_POSITION_PAGE_INDEX,
    RELATED_POSITION_PAGE_LIMIT
  )

  if (!data?.length && !isLoading) {
    return null
  }

  return (
    <PositionSection
      useCarouselLayout={false}
      title={t('More jobs like this')}
      seeAllPath={`/collections/related/jobs/${encodeToken(mainPositionId)}`}
      data={data}
      isLoading={isLoading}
      hideAnalyzeButton={hideAnalyzeButtonOnPositionSection}
      getJobDashboardPath={getJobDashboardPath}
    />
  )
}
