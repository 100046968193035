import { IStudioContent } from 'core'
import React from 'react'

import { breakpoint, H1, H2, H3, H4, Icon, styled, useStore, View } from '~/lite'

const Wrap = styled(View)`
  width: 100%;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  border-width: 1px;
  margin-bottom: 16px;
`

const Intro = styled(View)`
  padding: 16px;

  @media ${breakpoint.md} {
    padding: 16px 64px;
  }
`

export const HeaderTag = styled(H4)`
  padding: 6px 16px;
  margin: 0 auto 16px auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.border)};
  border-radius: 20px;
  font-size: 12px;
  white-space: nowrap;
`

export const Header = styled(H1)`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;

  ::selection {
    -webkit-text-fill-color: #fff;
  }

  @media ${breakpoint.md} {
    font-size: 40px;
  }
`

const SubheaderWrap = styled(View)`
  flex-direction: row;
  width: 520px;
  max-width: 100%;
  margin: 0 auto 16px;
`

const SubheaderIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const SubheaderText = styled(View)`
  flex: 1;
  display: block;
`

const BoldSubheader = styled(H2)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-size: 16px;
  font-weight: bold;
`

const Subheader = styled(H3)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-size: 16px;
  font-weight: normal;
  opacity: 0.8;
`

export const SectionTitle = styled(H2)`
  margin-top: 32px;

  @media ${breakpoint.md} {
    margin-top: 64px;
  }
`

const Features = styled(View)`
  flex: 1;
  padding: 16px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  border-top-width: 1px;

  @media ${breakpoint.md} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: unset;
    place-items: start center;
  }
`

const Feature = styled(View)`
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;

  @media ${breakpoint.md} {
    max-width: 256px;
  }
`

const FeatureIconWrap = styled(View)``

const FeatureHeader = styled(H4)`
  font-size: 18px;
  font-weight: bold;
  text-wrap: balance;
  hyphens: none;
`

const icons: Record<string, (size: number) => React.ReactNode> = {
  'brain-circuit': size => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08 2.5 2.5 0 0 0 4.91.05L12 20V4.5Z" />
      <path d="M16 8V5c0-1.1.9-2 2-2" />
      <path d="M12 13h4" />
      <path d="M12 18h6a2 2 0 0 1 2 2v1" />
      <path d="M12 8h8" />
      <path d="M20.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M16.5 13a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M20.5 21a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M18.5 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
    </svg>
  )
}

interface IWelcomeProps {
  content: IStudioContent['welcome']
}

export const Welcome: React.FC<IWelcomeProps> = ({ content }) => {
  const darkMode = useStore(state => state.darkMode)

  return (
    <Wrap>
      <Intro>
        {content.headerTag && <HeaderTag rawText={content.headerTag} />}
        {content.header && <Header rawText={content.header} />}
        {content.subheaders.map((subheaders, idx) => (
          <SubheaderWrap key={idx}>
            <SubheaderIcon icon="checkmark-outline" color="#6360ff" size={24} />
            <SubheaderText>
              {subheaders[0] && <BoldSubheader rawText={subheaders[0]} />}{' '}
              {subheaders[1] && <Subheader rawText={subheaders[1]} />}
            </SubheaderText>
          </SubheaderWrap>
        ))}
      </Intro>
      <Features>
        {content.features.map(({ header, icon }, idx) => (
          <Feature key={idx}>
            {icon && !!icons[icon] && <FeatureIconWrap>{icons[icon](22)}</FeatureIconWrap>}
            {icon && !icons[icon] && (
              <FeatureIconWrap>
                <Icon icon={icon} size={22} color={darkMode ? '#fff' : '#000'} />
              </FeatureIconWrap>
            )}
            <FeatureHeader rawText={header} />
          </Feature>
        ))}
      </Features>
    </Wrap>
  )
}
