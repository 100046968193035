import { compact } from 'lodash'
import React, { useCallback, useState } from 'react'

import { useUpdateTheme } from '~/hooks'
import {
  Avatar,
  breakpoint,
  Button,
  DesktopView,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  styled,
  useBreakpoints,
  useStore,
  View
} from '~/lite'

import type { IHeaderMenuProps } from './HeaderMenu.native'
import { Lazy } from './Lazy'
import { LocaleSettings } from './LocaleSettings'
import { UserIcon } from './styles'

const ThemeButton = styled(Button)`
  background: none;
  padding: 0;
  width: 44px;

  &:hover {
    opacity: 1;
    background: none;
  }
`

const AvatarWrap = styled(View)`
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;

  @media ${breakpoint.lg} {
    padding-left: 8px;
  }
`

export const HeaderMenu: React.FC<IHeaderMenuProps> = ({ onVisibilityChange }) => {
  const { isPhone } = useBreakpoints()
  const user = useStore(state => state.user)
  const theme = useStore(state => state.theme)
  const setTheme = useStore(state => state.setTheme)
  const themeName = useStore(state => state.themeName)

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const { mutateAsync: updateTheme, isLoading: isUpdatingTheme } = useUpdateTheme()

  const onUserAccountClose = useCallback(() => setIsSettingsOpen(false), [])

  return (
    <View display="flex" flexDirection="row" alignItems="stretch">
      <DesktopView display="flex" flexDirection="row" alignItems="stretch" justifyContent="stretch">
        <View width={44} alignItems="center" justifyContent="center">
          <LocaleSettings variant="Menu" />
        </View>
        <ThemeButton
          icon="sunny-outline"
          iconSize={28}
          color="#5c7080"
          disabled={isUpdatingTheme}
          onPress={() => {
            const newTheme = themeName === 'dark' ? 'light' : 'dark'
            updateTheme({ oldTheme: themeName, newTheme })
            setTheme(newTheme)
          }}
          skipTracking
        />
      </DesktopView>
      <Popover
        open={isSettingsOpen}
        onOpenChange={open => {
          setIsSettingsOpen(false)
          onVisibilityChange?.(open)
        }}
      >
        <PopoverTrigger asChild>
          <AvatarWrap onPress={() => setIsSettingsOpen(isOpen => !isOpen)}>
            {user ? (
              <Avatar
                source={user.avatar ?? (user.githubData as any)?.user_avatar}
                recordKey={compact([user.firstName, user.lastName]).join(' ') || user.username}
                size="medium"
              />
            ) : (
              <UserIcon>
                <Icon icon="person-outline" />
              </UserIcon>
            )}
          </AvatarWrap>
        </PopoverTrigger>
        <PopoverContent align="start" dataSide="bottom">
          <View
            width={isPhone ? '80vw' : 350}
            minHeight={198}
            maxHeight="85vh"
            backgroundColor={theme.background}
            overflow="auto"
            borderRadius={10}
          >
            <Lazy.UserAccount isPhone={isPhone} onClose={onUserAccountClose} />
          </View>
        </PopoverContent>
      </Popover>
    </View>
  )
}
