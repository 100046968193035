import {
  INewTeam,
  IStripeBaseSubscription,
  IStripeSubscriptionBenefits,
  ITeam,
  ITeamDetail,
  PageableResponse,
  StripeTeamService
} from '../models'
import { Endpoint, get, post, put } from '../util/api'
import { buildQueryString } from '../util/helpers'
import { JobScope } from '../util/query'

export type GetTeam = Endpoint<{ id: ITeam['id'] }, { success: true; team: ITeam }>

export type GetTeamsByUser = Endpoint<Record<string, never>, { success: true; teams: ITeam[] }>

export type GetTeamByUsername = Endpoint<{ username: ITeam['username'] }, { success: true; team: ITeam }>

export type CreateTeam = Endpoint<
  Record<string, never>,
  { success: true; team: ITeam },
  INewTeam & { isColdEmail?: boolean }
>

export type AddSlackIntegration = Endpoint<{ code: string; state: string }>

export type RemoveSlackIntegration = Endpoint<Record<string, never>, { success: true }, ITeam>

export type IsTeamMember = Endpoint<{ username: ITeam['username'] }, { success: true; isTeamMember: boolean }>

export type UpdateTeam = Endpoint<Record<string, never>, { success: true; team: ITeam }, INewTeam & { id: ITeam['id'] }>

export type UpdateTeamLogo = Endpoint<
  Record<string, never>,
  { success: true; logo: ITeam['logo'] },
  { id: ITeam['id']; logo: ITeam['logo'] }
>

export type FindTopTeam = Endpoint<Record<string, never>, { success: true } & PageableResponse<ITeamDetail>>

export type GetTeamPlanSubscription = Endpoint<
  { service: StripeTeamService },
  {
    success: true
    teamDetail: ITeam | null
    benefits?: IStripeSubscriptionBenefits | null
    metadata?: IStripeBaseSubscription | null
  }
>

export type UpdateTeamSettings = Endpoint<
  { id: ITeam['id'] },
  { success: true; autoProcessScreenerAnalytics: boolean },
  { id: ITeam['id']; autoProcessScreenerAnalytics: boolean }
>

export const getTeam = (id: ITeam['id']) => get<GetTeam>(`/teams/${id}`)

export const getTeamsByUser = () => get<GetTeamsByUser>(`/teams/user`)

export const getTeamByUsername = (username: ITeam['username']) => get<GetTeamByUsername>(`/teams/username/${username}`)

export const createTeam = (team: CreateTeam['body']) => post<CreateTeam>('/teams', team)

export const updateTeam = (team: UpdateTeam['body']) => put<UpdateTeam>('/teams', team)

export const removeSlackIntegration = (team: ITeam) => post<RemoveSlackIntegration>('/teams/slack/integration', team)

export const isTeamMember = (username: ITeam['username']) => get<IsTeamMember>(`/teams/username/${username}/isMember`)

export const updateTeamLogo = (team: UpdateTeamLogo['body']) => put<UpdateTeamLogo>(`/teams/avatar`, team)

export const findTopTeams = (page?: number, limit?: number, scope?: JobScope, isExpandable?: boolean) =>
  get<FindTopTeam>(`/teams/top${buildQueryString({ page, limit, scope, isExpandable })}`)

export const getTeamSubscriptionPlan = (service: StripeTeamService, teamId?: ITeam['id']) =>
  get<GetTeamPlanSubscription>(`/teams/subscription/${service}${buildQueryString({ teamId })}`)

export const updateTeamSettings = (data: UpdateTeamSettings['body']) => put<UpdateTeamSettings>(`/teams/settings`, data)
