import { FormGroup, styled, Tag, View } from '~/components'

export const GroupTag = styled(Tag)`
  margin-left: 2%;
`

export const SummaryInputWrap = styled(FormGroup)`
  position: relative;
`

export const CharacterCount = styled(View)`
  position: absolute;
  top: ${23 + 38 / 2 - 10}px;
  right: ${10}px;
`
