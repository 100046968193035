import { useQueryClient } from '@tanstack/react-query'
import { IMessageSideEffect } from 'core'
import { useCallback } from 'react'

import { useOnLogin } from '~/hooks'
import { useHistory, useLoadUserData, useStore } from '~/lite'

import { useAssistantBottomSheetStore } from './AssistantBottomSheet.store'

export const useSideEffectHandler = () => {
  const onLogin = useOnLogin()
  const loadUserData = useLoadUserData()
  const queryClient = useQueryClient()
  const history = useHistory()
  const setJobScope = useStore(state => state.setJobScope)
  const { assistantMessageSheetRef } = useAssistantBottomSheetStore()

  return useCallback<(sideEffect: IMessageSideEffect) => Promise<void>>(
    async sideEffect => {
      if (sideEffect.type === 'NewFeedSectionAdded') {
        if (sideEffect.jobScope) {
          setJobScope({ scope: sideEffect.jobScope, fromSearchbar: false })
          await queryClient.resetQueries(['user-locations'])
        } else {
          await queryClient.resetQueries(['landing-page'])
        }
        assistantMessageSheetRef?.current?.snapToIndex(0)
        return
      }

      if (sideEffect.type === 'GoTo') {
        if (sideEffect.path) {
          history[sideEffect.replace ? 'replace' : 'push'](sideEffect.path)
        }

        if (sideEffect.url) {
          history.openWebView({ type: 'ExternalSite', url: sideEffect.url })
        }
      }

      if (sideEffect.type === 'HandleLogin') {
        return onLogin(sideEffect.skipRedirect ?? true)
      }

      if (sideEffect.type === 'InvalidateQueries') {
        return queryClient.resetQueries(sideEffect.queryKeys ?? undefined)
      }

      if (sideEffect.type === 'LoadUserData') {
        await loadUserData()
        return
      }
    },
    [history, loadUserData, onLogin, queryClient, assistantMessageSheetRef]
  )
}
