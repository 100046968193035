import {
  affiliateCommissionRate,
  compactAndJoin,
  CurrencyCode,
  formatCurrency,
  IAffiliateContent,
  markTranslated,
  TranslatedText
} from 'core'
import React, { startTransition, useCallback, useEffect, useState } from 'react'

import { Input, Slider } from '~/components'
import { animations, breakpoint, Button, styled, Text, useStore, useTranslation, View } from '~/lite'

import { backgrounds, Section, SectionContent, SectionTitle } from '../styles'

const CalculatorWrap = styled(View)`
  padding: 16px;
  background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : props.theme.backgroundContrast)};
  backdrop-filter: blur(18px);
  border-radius: 20px;
`

const CalculatorHeader = styled(View)`
  padding: 0 8px 16px;
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const CalculatorTitle = styled(SectionTitle)`
  margin: 0;
  font-size: 24px;
  text-align: left;
`

const CalculatorSubtitle = styled(Text)`
  opacity: 0.5;
  font-size: 16px;
  font-weight: 400;
  text-wrap: balance;
`

const Total = styled(Text)`
  opacity: 0.6;
  font-size: 16px;
  font-weight: 400;
  font-variant-numeric: tabular-nums;

  @media ${breakpoint.md} {
    font-size: 24px;
  }
`

const Products = styled(View)`
  flex-direction: column;
  gap: 16px;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`

const ProductWrap = styled(View)`
  flex: 1;
  background: ${props => props.theme.cardBackground};
  border-width: 1px;
  border-radius: 12px;
  padding: 16px;
`

const Options = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

const Option = styled(Button)`
  padding: 0;
  border-radius: 6px;
`

const ProductName = styled.h2`
  font-size: 22px;
`

const ProductDescription = styled(Text)`
  opacity: 0.8;
`

const Subtotal = styled(View)<{ $visible: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  border: 0.75px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : props.theme.border)};
  background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.buttonBackground)};
  border-radius: 10px;
  padding: 12px 16px;
  margin-top: 16px;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: ${props => (props.$visible ? animations.fadeInUp : animations.fadeOutUp)};
`

const SubtotalLabel = styled(Text)`
  font-size: 16px;
`

const SubtotalValue = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  font-variant-numeric: tabular-nums;
`

const ProductField = styled(View)`
  padding-top: 16px;
`

const Label = styled(Text)`
  margin-bottom: 8px;
  font-weight: bold;
`

const ReferralLabel = styled(Text)`
  font-weight: bold;
`

const ReferralInput = styled(Input)`
  padding: 6px;
  width: 50px;
  text-align: center;
  border: 1px solid ${props => (props.theme.dark ? props.theme.buttonBackground : props.theme.border)};
  border-radius: 9px;
  font-variant-numeric: tabular-nums;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Disclaimer = styled(Text)`
  padding: 16px;
  font-size: 12px;
  opacity: 0.6;
`

interface IEarning {
  amount: number
  frequency: 'Single' | 'Monthly'
}

interface IProductProps {
  product: IAffiliateContent['products'][number]
  updateEarnings(earning: IEarning): void
}

const Product: React.FC<IProductProps> = ({ product, updateEarnings }) => {
  const t = useTranslation()
  const locale = useStore(state => state.locale)

  const [selectedPlan, setSelectedPlan] = useState(product.plans[1] ?? product.plans[0])
  const [isAnnual, setIsAnnual] = useState(true)
  const [referrals, setReferrals] = useState(0)

  const estimatedEarning =
    ('price' in selectedPlan ? selectedPlan.price : isAnnual ? selectedPlan.annually : selectedPlan.monthly) *
    referrals *
    affiliateCommissionRate

  const formattedEarning = formatCurrency(locale, 'USD' as CurrencyCode, estimatedEarning, true) as TranslatedText

  const frequency = isAnnual || product.type === 'Purchase' ? 'Single' : 'Monthly'

  useEffect(() => {
    startTransition(() => {
      updateEarnings({ amount: estimatedEarning, frequency })
    })
  }, [estimatedEarning, frequency, updateEarnings])

  return (
    <ProductWrap>
      <ProductName>{product.name}</ProductName>
      <ProductDescription rawText={product.description as TranslatedText} />
      <ProductField>
        <View flexDirection="row" alignItems="center" justifyContent="space-between" marginBottom={8}>
          <ReferralLabel text="Referrals" />
          <ReferralInput
            keyboardType="number-pad"
            wrapStyle={{ backgroundColor: 'transparent', borderWidth: 0, borderRadius: 0 }}
            value={referrals}
            onChange={value => setReferrals(Math.max(Number(value) || 0, 0))}
          />
        </View>
        <Slider
          min={0}
          max={100}
          value={Math.min(referrals, 100)}
          onChange={value => setReferrals(Array.isArray(value) ? value[0] : value)}
        />
      </ProductField>
      {product.type === 'Subscription' && (
        <ProductField>
          <Label text="Billing frequency" />
          <Options>
            <Option text="Monthly" active={!isAnnual} onPress={() => setIsAnnual(false)} skipTracking />
            <Option text="Annual" active={isAnnual} onPress={() => setIsAnnual(true)} skipTracking />
          </Options>
        </ProductField>
      )}
      {product.plans.length > 1 && (
        <ProductField>
          <Label text={product.type === 'Purchase' ? 'Product' : 'Plan'} />
          <Options>
            {product.plans.map((plan, idx) => (
              <Option
                key={idx}
                rawText={plan.name as TranslatedText}
                active={plan.name === selectedPlan.name}
                onPress={() => setSelectedPlan(plan)}
                skipTracking
              />
            ))}
          </Options>
        </ProductField>
      )}
      <View flex={1} />
      <Subtotal $visible={estimatedEarning > 0}>
        <SubtotalLabel text="Earn" />
        <SubtotalValue
          rawText={frequency === 'Monthly' ? t('{{amount}} / month', { amount: formattedEarning }) : formattedEarning}
        />
      </Subtotal>
    </ProductWrap>
  )
}

interface ICalculatorProps {
  data: IAffiliateContent['calculator']
  products: IAffiliateContent['products']
}

export const Calculator: React.FC<ICalculatorProps> = ({ data, products }) => {
  const t = useTranslation()
  const darkMode = useStore(state => state.darkMode)
  const locale = useStore(state => state.locale)

  const [earnings, setEarnings] = useState<IEarning[]>(
    Array(products.length).map(() => ({ amount: 0, frequency: 'Single' }))
  )

  const updateEarnings = useCallback((index: number, newEarning: IEarning) => {
    setEarnings(earnings => {
      const newEarnings = [...earnings]
      newEarnings[index] = newEarning
      return newEarnings
    })
  }, [])

  const hasEarnings = earnings.filter(earning => earning.amount > 0).length > 0

  const singleTotal = earnings
    .filter(earning => earning.frequency === 'Single')
    .reduce((acc, cur) => acc + cur.amount, 0)

  const monthlyTotal = earnings
    .filter(earning => earning.frequency === 'Monthly')
    .reduce((acc, cur) => acc + cur.amount, 0)

  const singleFormatted = formatCurrency(locale, 'USD' as CurrencyCode, singleTotal, true) as TranslatedText

  const monthlyFormatted = formatCurrency(locale, 'USD' as CurrencyCode, monthlyTotal, true) as TranslatedText

  return (
    <Section id="calculator" style={darkMode ? { background: backgrounds.blue } : undefined}>
      <SectionContent>
        <CalculatorWrap>
          <CalculatorHeader>
            <CalculatorTitle rawText={compactAndJoin([data.header, markTranslated('(US)')], ' ')} />
            {hasEarnings ? (
              <Total
                rawText={compactAndJoin(
                  [
                    monthlyTotal ? t('{{amount}} / month', { amount: monthlyFormatted }) : null,
                    singleTotal ? singleFormatted : null
                  ],
                  ' + '
                )}
              />
            ) : (
              <CalculatorSubtitle rawText={data.subheader} />
            )}
          </CalculatorHeader>
          <Products>
            {products.map((product, index) => (
              <Product key={index} product={product} updateEarnings={earning => updateEarnings(index, earning)} />
            ))}
          </Products>
        </CalculatorWrap>
        <Disclaimer rawText={data.disclaimer} />
      </SectionContent>
    </Section>
  )
}
