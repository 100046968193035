import { ClientRoutePath } from 'core'
import { useCallback } from 'react'

import { pubSub, useLoadUserData } from '~/lite'

import { usePromptAuth } from './usePromptAuth'

export const useOnLogin = () => {
  const loadUserData = useLoadUserData()
  const { redirect } = usePromptAuth()

  return useCallback(
    async (skipRedirect?: boolean, redirectUrl?: ClientRoutePath) => {
      const userId = await loadUserData()

      pubSub.publish({ type: 'Login', userId })

      if (!skipRedirect) {
        await redirect(redirectUrl)
      }
    },
    [loadUserData, redirect]
  )
}
