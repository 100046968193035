import {
  IContinent,
  ICountry,
  IDevice,
  IExtensionState,
  IPosition,
  IStripeSubscription,
  ISubscription,
  IUser,
  IVisitorLocation,
  ResumeImportState
} from '../models'
import { Endpoint, get, post } from '../util/api'
import { encodeFileUploadQuery, IFileUploadQuery, IFileUploadQueryParams } from '../util/query'

export type GetData = Endpoint<
  Record<string, never>,
  {
    success: true
    data: {
      stats: { jobsCount: number }
      user: IUser | null
      location: IVisitorLocation | null
      currentUserId: IUser['id']
      device: IDevice | null
      subscriptions: ISubscription[] | null
      notificationBadgeCount: number
      resumeImportState: ResumeImportState | null
      extensionState: IExtensionState | null
      shouldLoadMessages: boolean
      applied: IPosition['id'][]
      saved: IPosition['id'][]
      ambitionPlusSubscription: IStripeSubscription | null
      hasStripeSubscription: boolean
    }
  }
>

export type GetGeoData = Endpoint<
  Record<string, never>,
  { success: true; continents: IContinent[]; countries: ICountry[] }
>

export const getData = () => get<GetData>('/data')

export const getGeoData = () => get<GetGeoData>('/data/geo')

export type RequestUploadUrl = Endpoint<
  IFileUploadQueryParams,
  {
    success: true
    data: GetFileSignedUrlResponse
  }
>

export type GetFileSignedUrlResponse = {
  url: string
  fileName: string
  fileType: string
}

export const requestUploadUrl = (query: IFileUploadQuery) => {
  const encodedQuery = query ? encodeFileUploadQuery(query) : null
  return get<RequestUploadUrl>(encodedQuery ? `/files/signed-url?${encodedQuery}` : '/files/signed-url')
}

export type CreateMultipartUpload = Endpoint<
  Record<string, never>,
  { success: true; uploadId: string; key: string },
  {
    filename: string
    type?: string
    metadata: Record<string, string>
  }
>

export const createMultipartUpload = async (data: CreateMultipartUpload['body']) => {
  return (await post<CreateMultipartUpload>('/uploads/s3/multipart', data)) as { uploadId: string; key: string }
}
