import * as RSwitch from '@radix-ui/react-switch'
import React from 'react'

import { styled } from '~/lite'

import { ISwitchProps } from './types'

export type { ISwitchProps }

const Wrap = styled(RSwitch.Root)<{ $checked: boolean; $disabled: boolean }>`
  all: unset;
  width: 42px;
  height: 25px;
  background-color: ${props => (props.$checked ? props.theme.primary : props.theme.inputBackground)};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
`

const Thumb = styled(RSwitch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: rgb(0 0 0 / 14%) 0px 2px 2px;
  transition: transform 100ms;
  transform: translateX(2px);
  willchange: transform;

  &[data-state='checked'] {
    transform: translateX(19px);
  }
`

export const Switch: React.FC<ISwitchProps> = ({ id, value, onChange, disabled }) => (
  <Wrap id={id} checked={value} $checked={value} onCheckedChange={onChange} disabled={disabled} $disabled={!!disabled}>
    <Thumb />
  </Wrap>
)
