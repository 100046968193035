import React, { ReactNode, useCallback, useMemo } from 'react'

import type { IPositionPartialDetail, ITeam } from '../models'
import { ClientRoutePath, encodeToken } from '../routes'
import { hoverArrow, isExpiredPosition } from '../util/helpers'
import type { TranslationFn } from '../util/i18n'
import { getCountryLocale } from '../util/i18n-countries'
import { getLandingPageSlugParser } from '../util/parsers'
import { GetJobDashboardPath } from '../util/query'
import { Avatar } from './Avatar'

// top priorities for this component are SEO and speed...
// it's used by both the web client and SSR server to render individual jobs.
// rather than SSR the entire app for every job request, the server SSR's a single page with a placeholder for the job.
// then we can interpolate the output of this component directly into that output for faster response times.
// to avoid possible hydration mismatches, it uses global styles defined in JobStyles.css rather than styled-components.
// this also reduces the page size since styles are extracted out of the individual pages into a common file.
// it's good to avoid any external dependencies, but made an exception for React for XSS protection.
// likely 100x slower than vanilla js, but still fast enough to where there are more important bottlenecks to work on:
// https://github.com/marko-js/templating-benchmarks#current-results

export type IJobProps = {
  id?: string
  t: TranslationFn
  magicLink: string | ReactNode
  onTeamPress?(username: ITeam['username']): void
  onLocationPress?(locationPath: ClientRoutePath): void
  onDescriptionButtonClick?(): void
  getJobDashboardPath?: GetJobDashboardPath
} & ({ skeleton?: boolean; data: IPositionPartialDetail } | { skeleton: true; data: null })

export const Job: React.FC<IJobProps> = ({
  id,
  t,
  data,
  skeleton,
  magicLink,
  onTeamPress,
  onLocationPress,
  onDescriptionButtonClick,
  getJobDashboardPath
}) => {
  const locationLink = useMemo<ClientRoutePath | null>(() => {
    const location = data?.locationDetail
    const countryCode = location?.country ? location.country.code : null

    if (getJobDashboardPath && location?.id) {
      if (location.city) {
        return getJobDashboardPath({ locationId: location.id, locationLevel: 'city' })
      }

      if (location.level1) {
        return getJobDashboardPath({ locationId: location.id, locationLevel: 'level1' })
      }

      return getJobDashboardPath({ locationId: location.id })
    }

    if (countryCode && location?.slugNew) {
      const slug = getLandingPageSlugParser(getCountryLocale(countryCode)).format({
        locationSlug: location.slugNew,
        categorySlug: null,
        feedSectionIdSlug: null,
        sectionName: null,
        publicSectionIdSlug: null
      })

      return `/${encodeToken(countryCode.toLowerCase())}/${encodeToken(slug)}`
    }

    return null
  }, [data?.locationDetail, getJobDashboardPath])

  const handleOnTeamPress = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (data && onTeamPress) {
        e.preventDefault()
        onTeamPress(data?.teamDetail.username)
      }
    },
    [onTeamPress, data?.teamDetail.username]
  )

  const handleOnLocationPress = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onLocationPress && locationLink) {
        e.preventDefault()
        onLocationPress(locationLink)
      }
    },
    [onLocationPress, locationLink]
  )

  if (skeleton) {
    return (
      <div id={id} className="job-wrap">
        <div className="job"></div>
      </div>
    )
  }

  const {
    title,
    teamDetail,
    postedAt,
    location,
    compensation,
    description,
    summary,
    hasForm,
    applicationUrl,
    allowContract,
    allowRemote,
    extra
  } = data

  const descriptionValue = description || summary
  const hasExpired = isExpiredPosition(data)
  const formattedCompensation = compensation ? t.formatCompensation(compensation) : null
  const teamLink: ClientRoutePath = getJobDashboardPath
    ? getJobDashboardPath({ teamId: teamDetail.id })
    : `/teams/${encodeToken(teamDetail.username)}/jobs`
  const locationLabel = allowRemote ? t('Remote') : location ?? '...'

  return (
    <div id={id} className="job-wrap">
      <div className="job">
        <div
          className="job-header"
          {...(teamDetail.banner && {
            style: { backgroundImage: `url("https://ambitioncdn.com/team-banners/${teamDetail.banner}")` }
          })}
        >
          <Avatar team={teamDetail} to={teamLink} onPress={handleOnTeamPress} />
        </div>
        <div className="job-general-info">
          <h1 className="job-title">{title}</h1>
          <div className="job-subtitle">
            <a href={teamLink} onClick={handleOnTeamPress}>
              {teamDetail.name}
            </a>
            <span>{postedAt && <>&nbsp;· {t.getTimeAgo(postedAt)}</>}</span>
          </div>
        </div>
        <div className="job-highlights">
          <div className="job-highlight job-location">
            <div className="job-icon"></div>
            <div className="job-highlight-info">
              {locationLink ? (
                <a href={locationLink} onClick={handleOnLocationPress}>
                  {locationLabel}
                </a>
              ) : (
                locationLabel
              )}
            </div>
          </div>
          <div className="job-highlight job-compensation">
            <div className="job-icon"></div>
            <div className="job-highlight-info">
              {formattedCompensation ? (
                <>
                  {formattedCompensation}
                  {compensation?.type === 'Estimate' && (
                    <>
                      <br />
                      {t('Estimation')}
                    </>
                  )}
                </>
              ) : (
                t('Negotiable')
              )}
            </div>
          </div>
          <div className="job-highlight job-type">
            <div className="job-icon"></div>
            <div className="job-highlight-info">
              {t(allowContract ? 'Contractor' : 'Full-time')}
              {!!allowRemote && (
                <>
                  <br />
                  {t('Remote')}
                </>
              )}
            </div>
          </div>
        </div>
        {hasExpired && <div className="job-expired-banner">{t('This job is no longer available')}</div>}
        {typeof magicLink === 'string' ? <div dangerouslySetInnerHTML={{ __html: magicLink }}></div> : magicLink}
        {descriptionValue && (
          <div className={getJobDashboardPath ? 'job-description dashboard-job-description' : 'job-description'}>
            <div
              className="rendered-html"
              dangerouslySetInnerHTML={{
                __html: `${descriptionValue} • ${
                  postedAt
                    ? `<p>${t('Last updated on {{date}}', {
                        date: t.formatDate(postedAt, { dateStyle: 'medium' })
                      })}</p>`
                    : ''
                }`
              }}
            ></div>
            <div className="job-button hover-arrow" onClick={onDescriptionButtonClick}>
              {t('See more')}
              <span dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
