import React, { forwardRef } from 'react'
import { border, color, flexbox, layout, position, space } from 'styled-system'

import { styled, View } from '~/lite'

import { ScrollViewBaseProps, ScrollViewStyledProps } from './types'

export type { IScrollViewProps } from './types'

const InnerScrollView = forwardRef<HTMLDivElement, ScrollViewBaseProps>(
  (
    {
      refreshControl,
      contentContainerStyle,
      horizontal,
      contentInsetAdjustmentBehavior,
      keyboardDismissMode,
      scrollEventThrottle,
      scrollEnabled,
      showsVerticalScrollIndicator,
      style,
      children,
      ...rest
    },
    ref
  ) => (
    <View
      ref={ref}
      style={[
        { flexGrow: 1, flexShrink: 1, WebkitOverflowScrolling: 'touch' },
        { overflowX: 'hidden', overflowY: 'auto' },
        horizontal && { overflowX: 'auto', overflowY: 'hidden' },
        scrollEnabled === false && {
          overflowX: 'hidden',
          overflowY: 'hidden',
          touchAction: 'none'
        },
        showsVerticalScrollIndicator === false && ({ scrollBarWidth: 'none' } as any),
        style
      ]}
      {...rest}
    >
      {refreshControl}
      <View style={[contentContainerStyle, horizontal && { flexDirection: 'row' }]}>{children}</View>
    </View>
  )
)

export const ScrollView = styled(InnerScrollView)<ScrollViewStyledProps>`
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${border}
  ${position}
`
