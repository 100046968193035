import React, { useEffect } from 'react'

import { useBusinessContent, useTeamSubscriptionBenefit } from '~/hooks'
import { Loader } from '~/lite'

import { Faq } from '../Faq'
import { Features } from '../Features'
import { HowItWorks } from '../HowItWorks'
import { Pricing } from '../Pricing'
import { ContentWrap } from '../styles'
import { Hero } from './Hero'

export const Business: React.FC = () => {
  const { data, isLoading } = useBusinessContent()
  const { data: teamSubscriptionPlan } = useTeamSubscriptionBenefit('team-interview')

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoading) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { hero, features, howItWorks, pricing, faq } = data.data

  return (
    <ContentWrap>
      <Hero data={hero} />
      <Features data={features} />
      <HowItWorks data={howItWorks} />
      <Pricing data={pricing} currentProductId={teamSubscriptionPlan?.metadata.stripeProductId ?? null} />
      <Faq data={faq} />
    </ContentWrap>
  )
}
