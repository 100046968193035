import { PositionSection } from '@ambition/module-position'
import { ProfileAvatar } from '@ambition/module-shared'
import { TEAM_DETAIL_POSITION_PAGE_LIMIT, TEAM_POSITION_PAGE_INDEX } from 'core'
import React from 'react'

import { Button, FlexHorizontal, H1, Icon, Section, SectionDivider, Text, View } from '~/components'
import { openNewPage } from '~/dom'
import { useStore, useTeamByUsername, useTeamPositions, useTranslation } from '~/hooks'
import { getTeamLogo, MetaTags, useHistory } from '~/lite'
import { constants } from '~/util'

import { COMPANY_BANNER_SIZE, COMPANY_LOGO_SIZE, CompanyLogoImage } from './styles'
import { CompanyDetailProps } from './types'

export const CompanyDetail: React.FC<CompanyDetailProps> = ({ username }) => {
  const history = useHistory()
  const theme = useStore(state => state.theme)
  const isTeamMember = useStore(state => state.isTeamMember)
  const setCurrentTeam = useStore(state => state.setCurrentTeam)
  const { data: teamData } = useTeamByUsername(username)
  const t = useTranslation()
  const isMember = isTeamMember(teamData?.id)

  const onPostAJobButtonClick = () => {
    if (teamData) {
      setCurrentTeam(teamData)
      history.push('/promote')
    }
  }

  const onApplicationsButtonClick = () => {
    if (teamData) {
      setCurrentTeam(teamData)
      history.push('/teams/applications')
    }
  }

  const { id: teamId, banner, name, website, logo: avatar, location, bio } = teamData || {}
  const { data: positions, isInitialLoading } = useTeamPositions(
    teamId,
    TEAM_POSITION_PAGE_INDEX,
    TEAM_DETAIL_POSITION_PAGE_LIMIT
  )

  return (
    <View backgroundColor={theme.background} paddingBottom="m">
      <MetaTags type="Collection" headerText={name ? t('{{collectionName}} Jobs', { collectionName: name }) : null} />
      <View width="100%" height={COMPANY_BANNER_SIZE} backgroundColor={theme.placeholder}>
        {banner ? (
          <CompanyLogoImage
            source={`${constants.assetServerUrl}/team-banners/${banner}`}
            width="100%"
            height={COMPANY_BANNER_SIZE}
            backgroundSize="cover"
          />
        ) : null}
      </View>
      <FlexHorizontal marginX="m" justifyContent="space-between">
        {username ? (
          <View marginTop={-COMPANY_LOGO_SIZE / 2}>
            <ProfileAvatar name={name} size={COMPANY_LOGO_SIZE} avatar={getTeamLogo(avatar)} />
          </View>
        ) : null}
        {(isMember || website) && (
          <FlexHorizontal paddingTop="m">
            {isMember && (
              <Button
                skipTracking
                marginRight="m"
                primary
                icon="file-tray-full-outline"
                text="Applications"
                onPress={onApplicationsButtonClick}
              />
            )}
            {isMember && (
              <Button
                skipTracking
                marginRight="m"
                primary
                icon="create-outline"
                text="Post a Job"
                onPress={onPostAJobButtonClick}
              />
            )}
            {website && (
              <Button
                action="OpenTeamWebsiteInWebView"
                icon="open-outline"
                text="Visit website"
                onPress={() => openNewPage(`//${website}`)}
              />
            )}
          </FlexHorizontal>
        )}
      </FlexHorizontal>
      <View paddingX="m" paddingTop="s">
        {name && username ? (
          <H1
            rawText={t('{{companyName}} Jobs', { companyName: name })}
            numberOfLines={1}
            ellipsizeMode="tail"
            fontWeight="bold"
          />
        ) : null}
        {location ? (
          <FlexHorizontal alignItems="center" marginTop="m">
            <Icon icon="location" marginRight="xs" color={theme.primary} size={14} />
            <Text rawText={location} numberOfLines={1} ellipsizeMode="tail" fontSize={14} />
          </FlexHorizontal>
        ) : null}
      </View>
      {bio ? (
        <Section title={t('About the company')} paddingX="m">
          <Text rawText={bio} />
        </Section>
      ) : null}
      <SectionDivider />
      <PositionSection
        title={t('Recently posted jobs')}
        useCarouselLayout={false}
        data={positions}
        isLoading={isInitialLoading}
        hideAnalyzeButton
      />
    </View>
  )
}
