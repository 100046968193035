import { CategorySlug, defaultLocale, IOccupation, Token } from 'core'
import { groupBy } from 'lodash'
import React, { useMemo, useState } from 'react'

import { IMenuItemProps, Menu } from '~/components'
import { useCategoryPath, useOccupations } from '~/hooks'
import { Button, encodeParams, styled, useBreakpoints, useHistory, useTranslation, View } from '~/lite'

const StyledButton = styled(Button)<{ $dashboard?: boolean }>`
  background: ${props => (props.theme.dark ? props.theme.buttonBackground : props.theme.menuItemBackground)};
  box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.1);
  border-radius: ${props => (props.$dashboard ? '8px' : '50px')};

  &:hover {
    background: ${props => (props.theme.dark ? props.theme.buttonHover : props.theme.inputBorderColor)};
  }
`

export interface IOccupationBrowserProps {
  dashboard?: boolean
  onChange?(occupationId: IOccupation): void
}

export const OccupationBrowser: React.FC<IOccupationBrowserProps> = ({ dashboard, onChange }) => {
  const t = useTranslation()
  const history = useHistory()
  const { isPhone } = useBreakpoints()

  const [enabled, setEnabled] = useState(false)

  // root level slugs are always english for matching
  const { data: occupations, isLoading } = useOccupations(defaultLocale, enabled)
  const { getCategoryPath } = useCategoryPath({ countryCode: null, landingPageSlug: null })

  const items = useMemo<IMenuItemProps[]>(() => {
    if (!occupations) {
      return []
    }

    const sorted = occupations.sort((a, b) =>
      !!a.isOther === !!b.isOther ? (a.simpleName ?? a.name).localeCompare(b.simpleName ?? b.name) : a.isOther ? 1 : -1
    )
    const byParent = groupBy(sorted, occupation => occupation.parentId ?? 'root')

    const getMenuItem = (item: IOccupation): IMenuItemProps => {
      let children = byParent[item.id]

      // collapse useless levels
      if (children?.length === 1) {
        children = byParent[children[0].id]
      }

      const onPress = () => {
        if (onChange) {
          return onChange(item)
        }

        if (isPhone) {
          return history.push(item.path ?? getCategoryPath(item.slug as unknown as CategorySlug))
        }

        return history.push(
          `/jobs?${encodeParams({ occupationId: item.id }) as Token<ReturnType<typeof encodeParams>>}`
        )
      }
      const childItems = children?.map(getMenuItem)

      if (childItems?.length) {
        childItems.unshift({ rawText: t('All'), onPress })
      }

      return {
        rawText: item.isOther ? t('Other') : item.simpleName ?? item.name,
        onPress,
        childItems
      }
    }

    return byParent.root?.map(getMenuItem) ?? []
  }, [t, history, occupations, getCategoryPath, onChange, isPhone])

  return (
    <View>
      <Menu
        align="start"
        sideOffset={dashboard ? 4 : undefined}
        maxHeight={dashboard ? 'calc(100vh - 180px)' : undefined}
        triggerStyle={{ flex: 1, cursor: 'pointer' }}
        isLoading={isLoading}
        items={items}
        onOpenChange={() => setEnabled(true)}
      >
        <StyledButton action="BrowseOccupations" text="Browse" $dashboard={dashboard} />
      </Menu>
    </View>
  )
}
