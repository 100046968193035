import {
  AssemblyUserToken,
  IInterview,
  IInterviewLogMessage,
  IInterviewQuestion,
  InterviewId,
  IPosition
} from '../models'
import { Endpoint, get, post, put } from '../util/api'

export type GetInterviews = Endpoint<Record<string, never>, { success: true; interviews: IInterview[] }>

export type GetInterview = Endpoint<
  { id: IInterview['id'] },
  {
    success: true
    interview: IInterview
    interviewQuestions: IInterviewQuestion[]
    interviewLogMessages: IInterviewLogMessage[]
  }
>

export type RequestAssemblyUserToken = Endpoint<Record<string, never>, { success: true; token: AssemblyUserToken }>

export type InterviewWithAIAssistant = Endpoint<
  Record<string, never>,
  { success: true },
  { jobId: IPosition['id']; interviewId: InterviewId; messageId?: string; message?: string }
>

export type UpdateInterviewAudio = Endpoint<
  Record<string, never>,
  { success: true },
  {
    jobId: IPosition['id']
    interviewId: InterviewId
    messageId: string
    audioFileUrl: string
    audioDuration: number
    role: 'user' | 'assistant'
  }
>

export const getInterviews = () => get<GetInterviews>('/interviews')

export const getInterview = (id: IInterview['id']) => get<GetInterview>(`/interviews/${id}`)

export const requestAssemblyUserToken = async () => post<RequestAssemblyUserToken>(`/interviews/assembly-token`, {})

export const updateInterviewMessageAudio = async (data: UpdateInterviewAudio['body']) =>
  put<UpdateInterviewAudio>('/interviews/message-audio', data)
