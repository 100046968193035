import type { SupportedLocaleCode, TranslatedText } from 'core'
import React from 'react'

import { constants, DesktopView, Image, isProduction, Link, Svg, Text, useStore, View } from '~/lite'

// Apple doesn't provide badges for every language we support
const missingAppleBadges: SupportedLocaleCode[] = ['af-ZA', 'ca-ES', 'hr-HR', 'hy-AM', 'sr-SP', 'uk-UA', 'ur-PK']

// Google randomly adds padding to some of their badges
const playBadgeDimensions: { [locale in SupportedLocaleCode]?: [number, number] } = {
  'ar-SA': [195, 75],
  'en-US': [195, 75],
  'he-IL': [195, 75],
  'hy-AM': [195, 75],
  'ur-PK': [195, 75],
  // adjust for Apple's badge
  'hi-IN': [160, 66],
  'ja-JP': [164, 66],
  'zh-CN': [164, 66],
  'zh-TW': [164, 66]
}

export const DownloadApp: React.FC = () => {
  const locale = useStore(state => state.locale)
  const themeName = useStore(state => state.themeName)

  const badges = (
    <>
      <Link
        href="https://play.google.com/store/apps/details?id=com.remoteambition.app"
        title="Download"
        rawText={
          (
            <Image
              source={`${constants.assetServerUrl}/badges/android/${locale}.png`}
              resizeMode="contain"
              width={playBadgeDimensions[locale]?.[0] ?? 170}
              height={playBadgeDimensions[locale]?.[1] ?? 66}
            />
          ) as unknown as TranslatedText
        }
      />
      <View marginTop={playBadgeDimensions[locale] ? 'none' : 'xs'}>
        <Link
          href="https://apps.apple.com/app/id1569146673"
          title="Download"
          rawText={
            (
              <Svg
                uri={`${constants.assetServerUrl}/badges/ios/black/${
                  missingAppleBadges.includes(locale) ? 'en-US' : locale
                }.svg`}
                width={170}
                height={60}
              />
            ) as unknown as TranslatedText
          }
        />
      </View>
    </>
  )

  return (
    <View display="flex" flexDirection="row" justifyContent="center" alignItems="start">
      <DesktopView width={130} display="flex" alignItems="center" paddingRight="s" marginBottom="m">
        <View padding="s" borderRadius={8} backgroundColor={themeName === 'dark' ? '#ffffff' : undefined}>
          <Image
            source={`${constants.assetServerUrl}/${isProduction ? 'app-qrcode.png' : 'app-qrcode-staging.png'}`}
            resizeMode="contain"
            height={100}
            width={100}
          />
        </View>
        <Text text="Scan to download our app" marginTop="s" textAlign="center" />
      </DesktopView>
      <View display="flex" justifyContent="center" alignItems="center">
        {badges}
      </View>
    </View>
  )
}
