import { localizePath, markTranslated, supportedLocales, TranslatedText } from 'core'
import React from 'react'

import { breakpoint, Icon, Link, styled, Text, useStore, View } from '~/lite'

import { Changelog } from './Changelog'
import { ToolMenu } from './ToolMenu'

const Wrap = styled(View)<{ $variant: 'phone' | 'desktop' }>`
  flex-direction: column;
  display: ${props => (props.$variant === 'phone' ? 'flex' : 'none')};
  padding: ${props => (props.$variant === 'phone' ? '32px 16px 50px' : '0 0 16px')};
  border-top-width: ${props => (props.$variant === 'phone' ? '1px' : '0')};

  @media ${breakpoint.lg} {
    display: ${props => (props.$variant === 'phone' ? 'none' : 'flex')};
    margin: 0 16px;
  }
`

const SiteLinks = styled(View)`
  display: block;
  margin-top: 16px;
  text-align: center;
  width: 100%;
  hyphens: none;
`

const SiteLink = styled(Link)`
  display: inline-block;
  min-width: 48px;
  padding: 16px 8px;
  font-size: 15px;
`

const Copyright = styled(Text)`
  display: block;
  margin-bottom: 5px;
`

const SocialLinks = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SocialLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px;
`

const Languages = styled(View)`
  width: 100%;
  opacity: 0.8;
  display: block;
  padding-top: 16px;
  background: ${props => props.theme.background};
  justify-content: center;
  text-align: center;

  @media ${breakpoint.lg} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, max-content));
  }
`

const LanguageLink = styled(Link)`
  display: inline-block;
  min-width: 48px;
  padding: 15px 8px;
  font-size: 16px;
  color: ${props => props.theme.text};

  @media ${breakpoint.lg} {
    display: flex;
    min-width: 0;
    padding: 4px 0;
    font-size: 12px;
    text-align: left;
  }

  &:hover {
    text-decoration: underline;
  }
`

interface IWebFooterProps {
  variant: 'phone' | 'desktop'
}

export const WebFooter: React.FC<IWebFooterProps> = ({ variant }) => {
  const locale = useStore(state => state.locale)
  const isWidget = useStore(state => state.isWidget)

  return (
    <Wrap $variant={variant}>
      <ToolMenu />
      <Changelog />
      <View flex={1} flexDirection="row">
        <SiteLinks>
          <Copyright rawText={markTranslated('© 2024 RemoteAmbition')} />
          <SiteLink text="Affiliate" to={localizePath('/affiliate', locale)} />
          <Text rawText={markTranslated(' · ')} />
          <SiteLink text="Privacy" to="/privacy" />
          <Text rawText={markTranslated(' · ')} />
          <SiteLink text="Terms" to="/terms" />
          <Text rawText={markTranslated(' · ')} />
          <SiteLink text="Sitemap" href="/sitemap" />
          <Text rawText={markTranslated(' · ')} />
          <SiteLink
            text="Status"
            href="https://stats.uptimerobot.com/YrJVzI3XLr"
            hrefAttrs={{ rel: 'noopener noreferrer nofollow', target: '_blank' }}
          />
          <SocialLinks>
            <SocialLink
              rawText={
                (
                  <svg viewBox="0 0 24 24" width="30" height="30">
                    <g>
                      <path
                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                        fill="#5c7080"
                      />
                    </g>
                  </svg>
                ) as unknown as TranslatedText
              }
              href="https://twitter.com/remoteambition"
              rawTitle={markTranslated('Twitter')}
            />
            <SocialLink
              rawText={
                (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-10.63 -.07077792 823.87 610.06955549"
                    width="30"
                    height="30"
                  >
                    <path
                      d="m678.27 51.62c90.35 132.84 134.97 282.68 118.29 455.18-.07.73-.45 1.4-1.05 1.84-68.42 50.24-134.71 80.73-200.07 100.95a2.55 2.55 0 0 1 -2.81-.95c-15.1-21.01-28.82-43.16-40.84-66.42-.69-1.37-.06-3.02 1.36-3.56 21.79-8.21 42.51-18.05 62.44-29.7 1.57-.92 1.67-3.17.22-4.25-4.23-3.14-8.42-6.44-12.43-9.74-.75-.61-1.76-.73-2.61-.32-129.39 59.75-271.13 59.75-402.05 0-.85-.38-1.86-.25-2.59.35-4 3.3-8.2 6.57-12.39 9.71-1.45 1.08-1.33 3.33.25 4.25 19.93 11.43 40.65 21.49 62.41 29.74 1.41.54 2.08 2.15 1.38 3.52-11.76 23.29-25.48 45.44-40.86 66.45-.67.85-1.77 1.24-2.81.92-65.05-20.22-131.34-50.71-199.76-100.95-.57-.44-.98-1.14-1.04-1.87-13.94-149.21 14.47-300.29 118.18-455.18.25-.41.63-.73 1.07-.92 51.03-23.42 105.7-40.65 162.84-50.49 1.04-.16 2.08.32 2.62 1.24 7.06 12.5 15.13 28.53 20.59 41.63 60.23-9.2 121.4-9.2 182.89 0 5.46-12.82 13.25-29.13 20.28-41.63a2.47 2.47 0 0 1 2.62-1.24c57.17 9.87 111.84 27.1 162.83 50.49.45.19.82.51 1.04.95zm-339.04 283.7c.63-44.11-31.53-80.61-71.9-80.61-40.04 0-71.89 36.18-71.89 80.61 0 44.42 32.48 80.6 71.89 80.6 40.05 0 71.9-36.18 71.9-80.6zm265.82 0c.63-44.11-31.53-80.61-71.89-80.61-40.05 0-71.9 36.18-71.9 80.61 0 44.42 32.48 80.6 71.9 80.6 40.36 0 71.89-36.18 71.89-80.6z"
                      fill="#5c7080"
                    />
                  </svg>
                ) as unknown as TranslatedText
              }
              href="https://discord.gg/sJUXdWDtpX"
              rawTitle={markTranslated('Discord')}
            />
            <SocialLink
              rawText={(<Icon icon="logo-github" size={30} />) as unknown as TranslatedText}
              href="https://github.com/remoteambition"
              rawTitle={markTranslated('GitHub')}
            />
          </SocialLinks>
        </SiteLinks>
      </View>
      {!isWidget && (
        <Languages>
          {supportedLocales.map(locale => (
            <LanguageLink key={locale.code} rawText={locale.name} href={localizePath('/', locale.code)} />
          ))}
        </Languages>
      )}
    </Wrap>
  )
}
