import { createGlobalStyle } from '~/lite'

export const ProfileGlobalStyle = createGlobalStyle`
  @media (max-width: 768px) {
    #hubspot-messages-iframe-container {
      margin-bottom: 100px !important;
    }
    .hs-messages-widget-open #hubspot-messages-iframe-container {
      height: calc(100% - 100px) !important;
    }
  }
`
