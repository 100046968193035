import React from 'react'

import { breakpoint, styled, View } from '~/lite'

import { IProductProps, Product } from './Product'
import { getPlans } from './shared'
import { Section, SectionContent, SectionHeader, SectionSubheader } from './styles'

const CarouselInner = styled(View)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;

  @media ${breakpoint.md} {
    gap: 24px;
  }
`

type IPlansProps = Omit<IProductProps, 'product'>

export const Plans: React.FC<IPlansProps> = props => {
  if (!props.profile.metadata.plans) {
    return null
  }

  const { header, subheader } = props.profile.metadata.plans

  return (
    <Section id="plans">
      <SectionContent $width="wide">
        {!!header && <SectionHeader rawText={header} />}
        {!!subheader && <SectionSubheader rawText={subheader} />}
        <CarouselInner>
          {getPlans(props.profile).map((plan, idx) => (
            <Product {...props} key={idx} product={plan} small />
          ))}
        </CarouselInner>
      </SectionContent>
    </Section>
  )
}
