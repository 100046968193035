import type { CountryCode } from '../../models'

// for use on the frontend. on the backend, use getCountryCode which validates the value against a dataset.
export const getUnverifiedCountryCode = (query: string): CountryCode => {
  if (!/^[a-z]{2}$/i.test(query)) {
    throw new Error(`Invalid country code: ${query}`)
  }

  return query.toUpperCase() as CountryCode
}
