import { compact } from 'lodash'
import React, { forwardRef } from 'react'
import { alignItems, border, color, flexbox, layout, minHeight, position, space, typography } from 'styled-system'

import { convertNativeToWebStyle, getStyledConfig, styled } from '../styled'
import { BREAKPOINT_WIDTH } from '../util/style'
import { ViewBaseProps, ViewProps, ViewStyledProps } from './types'

const InnerView = forwardRef<HTMLDivElement, ViewBaseProps>(
  ({ testID, style, to, children, collapsable, pointerEvents, onPress, className, ...rest }, ref) => {
    const props = {
      style: convertNativeToWebStyle(style),
      ...rest,
      ...(onPress && { onClick: onPress })
    }

    if (to && to !== 'goBack') {
      return (
        <a
          ref={ref as any}
          className={compact(['view-link', className]).join(' ')}
          {...(props as any)}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            onPress?.()
            return false
          }}
          href={to as string}
        >
          {children}
        </a>
      )
    }

    return (
      <div ref={ref} onClick={onPress} className={className} {...props}>
        {children}
      </div>
    )
  }
)

const StyledView = styled(InnerView).withConfig(getStyledConfig())<ViewStyledProps & { $pressable: boolean }>`
  cursor: ${props => (props.$pressable ? 'pointer' : 'inherit')};
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${border}
  ${position}
  ${typography}
  ${alignItems}
  ${minHeight}
`

export const View = forwardRef<HTMLDivElement, ViewProps>(({ testID, ...props }, ref) => (
  <StyledView ref={ref} $pressable={!!props.onPress} {...(testID && { 'data-testid': testID })} {...props} />
))

export const PhoneView = styled(View)`
  @media (min-width: ${BREAKPOINT_WIDTH.LG}px) {
    display: none !important;
  }
`

export const DesktopView = styled(View)`
  @media (max-width: ${BREAKPOINT_WIDTH.LG - 1}px) {
    display: none !important;
  }
`
