import React, { Suspense, useState } from 'react'

import { isWeb, useOnHydrate } from '~/lite'

import type { IBottomSheetProps } from './types'

export { IBottomSheetProps }

const LazyBottomSheet = React.lazy(() => import('./BottomSheet').then(module => ({ default: module.BottomSheet })))

export const BottomSheet: React.FC<IBottomSheetProps> = props => {
  const [isHydrated, setIsHydrated] = useState(!isWeb)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <LazyBottomSheet {...props} />
    </Suspense>
  )
}
