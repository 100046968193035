import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { TranslatedText, UntranslatedText } from 'core'
import * as React from 'react'

import { styled } from '../styled'
import { Button, IButtonProps } from './Button'
import { dialogStyles } from './Dialog'
import { Text } from './Text'

const AlertDialogOverlay = styled(AlertDialogPrimitive.Overlay)`
  ${dialogStyles.overlay}
`

const AlertDialogContent = styled(AlertDialogPrimitive.Content)`
  ${dialogStyles.content}
  max-width: 500px;
`

const AlertDialogHeader = styled.div`
  ${dialogStyles.header}
`

const AlertDialogFooter = styled.div`
  ${dialogStyles.footer}
`

const AlertDialogTitle = styled(AlertDialogPrimitive.Title)`
  font-size: 16px;
  font-weight: 600;
`

const AlertDialogDescription = styled(AlertDialogPrimitive.Description)`
  opacity: 0.8;
`

interface IAlertDialogProps extends AlertDialogPrimitive.DialogProps {
  trigger?: React.ReactNode
  title?: UntranslatedText
  rawTitle?: TranslatedText
  description?: UntranslatedText
  rawDescription?: TranslatedText
  onConfirm: NonNullable<IButtonProps['onPress']>
  action: NonNullable<IButtonProps['action']>
  confirmText?: UntranslatedText
  footer?: React.ReactNode
  dangerous?: boolean
  isLoading?: boolean
}

export const AlertDialog: React.FC<IAlertDialogProps> = ({
  trigger,
  title,
  rawTitle,
  description,
  rawDescription,
  confirmText,
  onConfirm,
  action,
  footer,
  dangerous,
  isLoading,
  ...props
}) => (
  <AlertDialogPrimitive.Root {...props}>
    {trigger && <AlertDialogPrimitive.Trigger asChild>{trigger}</AlertDialogPrimitive.Trigger>}
    <AlertDialogPrimitive.Portal>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {!!(title || rawTitle) && (
            <AlertDialogTitle>
              {title ? <Text text={title} /> : rawTitle ? <Text rawText={rawTitle} /> : null}
            </AlertDialogTitle>
          )}
          {!!(description || rawDescription) && (
            <AlertDialogDescription>
              {description ? <Text text={description} /> : rawDescription ? <Text rawText={rawDescription} /> : null}
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          {footer}
          <AlertDialogPrimitive.Cancel asChild>
            <Button text="Cancel" onPress={() => props.onOpenChange?.(false)} minimal skipTracking />
          </AlertDialogPrimitive.Cancel>
          <AlertDialogPrimitive.Action asChild>
            <Button
              text={confirmText ?? 'Confirm'}
              onPress={onConfirm}
              loading={isLoading}
              action={action}
              dangerous={dangerous}
              primary={!dangerous}
            />
          </AlertDialogPrimitive.Action>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogPrimitive.Portal>
  </AlertDialogPrimitive.Root>
)
