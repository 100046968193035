import { ClientRoutePath, markTranslated } from 'core'
import React, { useMemo } from 'react'

import { Link, Text, TextStyle, useTranslation } from '~/lite'

export interface ISignUpNoteProps {
  short?: boolean
  style?: Pick<TextStyle, 'color' | 'fontSize'>
}

export const SignUpNote: React.FC<ISignUpNoteProps> = ({ short, style }) => {
  const t = useTranslation()

  return useMemo(() => {
    const linkRe = /<a([^>]*)>([^>]+)<\/a>/gi
    const hrefRe = /href="([^"]+)"/
    const linkToken = '[link]'
    const links: { innerText: string; href: string | null }[] = []

    let text = t(
      short
        ? 'By signing up, you agree to our <a href="{{termsRoute}}">Terms</a> and <a href="{{privacyRoute}}">Privacy Policy</a>.'
        : 'By pressing the button above, you agree to our <a href="{{termsRoute}}">Terms</a> and <a href="{{privacyRoute}}">Privacy Policy</a>, and agree to receive email job alerts. You can unsubscribe anytime.',
      { termsRoute: '/terms', privacyRoute: '/privacy' }
    )

    text = markTranslated(
      text.replace(linkRe, (_, attributes, inner) => {
        links.push({ innerText: inner ?? null, href: attributes.match(hrefRe)?.[1] ?? null })
        return linkToken
      })
    )

    return (
      <>
        {text.split(linkToken).map((text, i) => (
          <React.Fragment key={i}>
            <Text {...style} rawText={markTranslated(text)} />
            {links[i] && (
              <Link
                style={style}
                to={(links[i].href as ClientRoutePath | null) ?? '/'}
                rawText={markTranslated(links[i].innerText)}
              />
            )}
          </React.Fragment>
        ))}
      </>
    )
  }, [t, style])
}
