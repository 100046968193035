import React, { Suspense, useState } from 'react'

import { styled, useOnHydrate, View } from '~/lite'

import type { IHtmlEditorProps } from './types'

export type { Editor } from '@tiptap/react'

const Skeleton = styled(View)<{ $theme?: IHtmlEditorProps['theme'] }>`
  background: ${props => (props.$theme === 'Light' ? '#fff' : props.theme.inputBackground)};
  border-width: 1px;
  border-radius: 12px;
  overflow: hidden;
`

const MenuBarSkeleton = styled(View)<{ $theme?: IHtmlEditorProps['theme'] }>`
  background: ${props => (props.$theme === 'Light' ? '#f5f8fa' : props.theme.cardBackground)};
  border-bottom-width: 1px;
  border-color: ${props => (props.$theme === 'Light' ? '#f5f8fa' : props.theme.inputBorderColor)};
  height: 57px;
`

const BodySkeleton = styled(View)`
  padding: 16px;
  min-height: 200px;
`

const LazyHtmlEditor = React.lazy(() => import('./HtmlEditor').then(module => ({ default: module.HtmlEditor })))

export const HtmlEditor: React.FC<IHtmlEditorProps> = React.memo(props => {
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  const skeleton = (
    <Skeleton style={props.style} $theme={props.theme}>
      <MenuBarSkeleton $theme={props.theme} />
      <BodySkeleton style={props.bodyStyle} />
    </Skeleton>
  )

  if (!isHydrated) {
    return skeleton
  }

  return (
    <Suspense fallback={skeleton}>
      <LazyHtmlEditor {...props} />
    </Suspense>
  )
})
