import { useQueryClient } from '@tanstack/react-query'
import {
  encodeToken,
  IMarketDirectory,
  IMarketMembership,
  IMarketPerson,
  IMarketProfile,
  MarketCofounderState,
  TranslatedText
} from 'core'
import { compact, keyBy } from 'lodash'
import React, { useMemo, useState } from 'react'

import { marketDirectoryQueryKey, useGeoData, useMarketDirectory, useMarketState } from '~/hooks'
import { breakpoint, Button, CountryIcon, Link, Loader, styled, Text, toast, useTranslation, View } from '~/lite'

import { MembershipDialog } from './MembershipDialog'

const Wrap = styled(View)`
  align-items: stretch;
  padding: 16px 0;

  @media (${breakpoint.md}) {
    padding: 16px;
  }
`

const Profiles = styled(View)`
  @media (${breakpoint.md}) {
    gap: 16px;
  }
`

const Profile = styled(View)`
  background: ${props => props.theme.cardBackground};
  border-bottom-width: 6px;

  @media (${breakpoint.md}) {
    border-bottom-width: 0;
    border-radius: 12px;
    box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px;
  }
`

const ProfileHeader = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 12px 16px;
  white-space: nowrap;
  overflow: hidden;
`

const ProfileHeaderInner = styled(View)`
  flex: 1;
  align-items: stretch;
`

const ProfileHeaderPrimary = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const ProfileHeaderSecondary = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CompanyName = styled(Text)`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileLink = styled(Link)`
  margin: 0 0 -16px -16px;
  padding: 4px 16px 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }

  @media (${breakpoint.md}) {
    margin-top: -4px;
  }
`

const People = styled(View)`
  border-top-width: 1px;
`

const FloatingButtons = styled(View)`
  flex-direction: row;
  gap: 8px;
  position: absolute;
  top: 12px;
  right: 16px;
  visibility: hidden;
`

const Person = styled(View)`
  position: relative;
  flex-direction: row;
  align-items: start;
  gap: 8px;
  padding: 16px;
`

/*
  &:hover {
    background: #00000022;

    ${FloatingButtons} {
      visibility: visible;
    }
  }
*/

const PersonName = styled(Text)`
  white-space: nowrap;
`

const MemberState = styled(Text)`
  white-space: nowrap;
  padding: 4px;
  margin-top: -2px;
  background: ${props => props.theme.background};
  border-radius: 4px;
  border-width: 1px;
  font-size: 11px;
`

const JoinWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
  top: 4px;
`

const CofounderState = styled(View)<{ $state: MarketCofounderState }>`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const CofounderStateText = styled(Text)`
  padding: 5px 0 5px 6px;
  font-size: 12px;

  @media (${breakpoint.md}) {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
  }
`

export const Directory: React.FC = () => {
  const t = useTranslation()
  const geoData = useGeoData()
  const queryClient = useQueryClient()

  const { data: marketState, isLoading: isLoadingMarketState } = useMarketState()
  const { data: directory, isLoading: isLoadingDirectory } = useMarketDirectory()

  const [activeMembership, setActiveMembership] = useState<{
    profiles: Pick<IMarketProfile, 'id'>[]
    person: Pick<IMarketPerson, 'id'> | null
    membership?: IMarketMembership
  } | null>(null)

  const countriesByCode = keyBy(geoData.data?.countries ?? [], country => country.code)

  const members = useMemo(() => {
    const mapping: Record<
      IMarketProfile['id'],
      { membership: IMarketMembership; person: IMarketDirectory['people'][number] }[]
    > = {}
    const peopleById = keyBy(directory?.people ?? [], person => person.id)

    for (const membership of directory?.memberships ?? []) {
      const person = peopleById[membership.marketPersonId]

      if (!person) {
        continue
      }

      if (!mapping[membership.marketProfileId]) {
        mapping[membership.marketProfileId] = []
      }

      mapping[membership.marketProfileId].push({ membership, person })
    }

    return mapping
  }, [directory?.memberships, directory?.people])

  const { ownProfiles, allProfiles } = useMemo(() => {
    const profileIds =
      marketState?.memberships
        .filter(membership => membership.state === 'Active')
        .map(membership => membership.marketProfileId) ?? []

    const ownProfiles = directory?.profiles.filter(profile => profileIds.includes(profile.id)) ?? []
    const allProfiles = [...(directory?.profiles ?? [])].sort(
      (p1, p2) => (ownProfiles.includes(p2) ? 1 : 0) - (ownProfiles.includes(p1) ? 1 : 0)
    )

    return { ownProfiles, allProfiles }
  }, [marketState?.memberships, directory?.profiles])

  if (isLoadingDirectory || isLoadingMarketState) {
    return (
      <View minHeight="calc(100vh - 60px)">
        <Loader />
      </View>
    )
  }

  if (!directory || !marketState) {
    return null
  }

  return (
    <Wrap>
      <Profiles>
        {allProfiles.map(profile => {
          const siteName = `${encodeToken(profile.username)}.weebin.com`

          const profileMembers = members[profile.id] ?? []
          const hasAnyMembership = profileMembers.some(member => member.person.id === marketState.person?.id)
          const hasActiveMembership = profileMembers.some(
            member => member.person.id === marketState.person?.id && member.membership.state === 'Active'
          )

          const cofounderStates: Record<MarketCofounderState, TranslatedText> = {
            Seeking: t('Actively seeking a co-founder'),
            Open: t('Open to a co-founder'),
            Closed: t('Not looking for a co-founder')
          }

          return (
            <Profile>
              <ProfileHeader>
                <ProfileHeaderInner>
                  <ProfileHeaderPrimary>
                    {<CompanyName rawText={profile.companyName || t('Anonymous')} />}
                    {!hasActiveMembership && <Text rawText={t.getTimeAgo(profile.createdAt)} />}
                  </ProfileHeaderPrimary>
                  <ProfileHeaderSecondary>
                    <ProfileLink
                      rawText={siteName as any}
                      href={`https://${siteName}`}
                      hrefAttrs={{ target: '_blank' }}
                    />
                    {!hasAnyMembership && (
                      <JoinWrap>
                        <CofounderState $state={profile.cofounderState}>
                          <CofounderStateText rawText={cofounderStates[profile.cofounderState]} />
                          {profile.cofounderState !== 'Closed' && (
                            <Button
                              text="Connect"
                              onPress={() => {
                                if (ownProfiles.length > 0) {
                                  setActiveMembership({ profiles: [profile], person: marketState.person })
                                } else {
                                  toast.info('Please set up your launchpad first')
                                }
                              }}
                              small
                              skipTracking
                            />
                          )}
                        </CofounderState>
                        {!!profile.cofounderNote && <Text rawText={profile.cofounderNote} opacity={0.5} />}
                      </JoinWrap>
                    )}
                  </ProfileHeaderSecondary>
                </ProfileHeaderInner>
                {hasActiveMembership && (
                  <Button text="Open dashboard" to={`/launchpad/${encodeToken(profile.id)}`} primary skipTracking />
                )}
              </ProfileHeader>
              <People>
                {profileMembers.map(({ membership, person }) => {
                  const personCountry = countriesByCode[person.country]

                  return (
                    <Person>
                      {!!person.firstName &&
                        (profileMembers.length > 1 || !profile.companyName?.startsWith(person.firstName)) && (
                          <PersonName rawText={person.firstName} />
                        )}
                      {!!personCountry && <CountryIcon country={personCountry} size={18} />}
                      {membership.state === 'Pending' && <MemberState text="requested to join" />}
                      <View flex={1}>{!!person.headline && <Text rawText={person.headline} opacity={0.5} />}</View>
                      <FloatingButtons>
                        {/*person.id !== marketState.person?.id && (
                          <Button
                            text="Invite to team"
                            onPress={() => {
                              if (ownProfiles.length > 0) {
                                setActiveMembership({ profiles: ownProfiles, person })
                              } else {
                                toast.info('Please set up your launchpad first')
                              }
                            }}
                            small
                            skipTracking
                          />
                        )*/}
                        {person.id === marketState.person?.id && membership.state === 'Pending' && (
                          <Button
                            text="Edit"
                            onPress={() => setActiveMembership({ profiles: [profile], membership, person })}
                            small
                            skipTracking
                          />
                        )}
                      </FloatingButtons>
                    </Person>
                  )
                })}
              </People>
            </Profile>
          )
        })}
      </Profiles>
      <MembershipDialog
        profiles={activeMembership?.profiles ?? null}
        person={activeMembership?.person ?? null}
        membership={activeMembership?.membership ?? null}
        open={!!activeMembership}
        onClose={(membership, action) => {
          if (membership) {
            queryClient.setQueryData<IMarketDirectory>(marketDirectoryQueryKey, old => {
              if (!old) {
                return old
              }

              if (action === 'create') {
                return { ...old, memberships: [...old.memberships, membership] }
              }

              return {
                ...old,
                memberships: compact(
                  old.memberships.map(m => (m.id === membership.id ? (action === 'delete' ? null : membership) : m))
                )
              }
            })
          }

          setActiveMembership(null)
        }}
      />
    </Wrap>
  )
}
