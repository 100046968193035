import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'

import { keyframes, styled, Text, View } from '~/lite'

const enterFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const enterFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const exitToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
`

const exitToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
`

const animations = {
  enterFromRight,
  enterFromLeft,
  exitToRight,
  exitToLeft
} as const

export const Wrap = styled(View)`
  position: relative;
  transform-origin: top center;
  width: 100%;
  overflow: hidden;
  transition: width, height, 300ms ease;
`

export const Panel = styled(View)<{ $animation?: keyof typeof animations }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-name: ${props => (props.$animation ? animations[props.$animation] : 'none')};
`

export const CollapsibleTrigger = styled(CollapsiblePrimitive.Trigger)`
  padding: 12px 8px 12px 44px;
  background: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.menuItemBackground};
  }
`

export const Tag = styled(Text)`
  padding: 4px 6px;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  font-size: 12px;
`
