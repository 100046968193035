import { defaultThemeName, ThemeName } from 'core'
import { StateCreator } from 'zustand'

import { isInstantSdk, isWidget } from '../../env'
import { themes } from '../../theme'
import { IStoreState, IThemeSlice } from './types'

export const getThemeValues = (themeName: ThemeName) => ({
  themeName,
  theme: themes[themeName],
  darkMode: themeName === 'dark'
})

export const createThemeSlice: StateCreator<IStoreState, [], [], IThemeSlice> = set => ({
  ...getThemeValues(isWidget ? 'light' : isInstantSdk ? 'dark' : defaultThemeName),
  setTheme: themeName => set(() => getThemeValues(themeName))
})
