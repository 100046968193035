import { uniq } from 'lodash'

// https://en.wikipedia.org/wiki/European_Union
const eu = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE'
]

// https://en.wikipedia.org/wiki/European_Free_Trade_Association
const efta = ['IS', 'LI', 'NO', 'CH']

// https://en.wikipedia.org/wiki/European_Economic_Area
const eea = [...eu, ...efta.filter(country => country != 'CH')]

// https://en.wikipedia.org/wiki/European_single_market
const europeanSingleMarket = [...eea, 'CH']

// https://en.wikipedia.org/wiki/Common_Travel_Area
const commonTravelArea = ['GB', 'IE']

// accounting for brexit
const britishMarket = commonTravelArea
const irishMarket = uniq([...commonTravelArea, ...europeanSingleMarket])

const regions = {
  // 015
  'Northern Africa': ['DZ', 'EG', 'EH', 'LY', 'MA', 'SD', 'TN', 'EA', 'IC'],

  // 011
  'Western Africa': [
    'BF',
    'BJ',
    'CI',
    'CV',
    'GH',
    'GM',
    'GN',
    'GW',
    'LR',
    'ML',
    'MR',
    'NE',
    'NG',
    'SH',
    'SL',
    'SN',
    'TG'
  ],

  // 017
  'Middle Africa': ['AO', 'CD', 'CF', 'CG', 'CM', 'GA', 'GQ', 'ST', 'TD'],

  // 014
  'Eastern Africa': [
    'BI',
    'DJ',
    'ER',
    'ET',
    'IO',
    'KE',
    'KM',
    'MG',
    'MU',
    'MW',
    'MZ',
    'RE',
    'RW',
    'SC',
    'SO',
    'SS',
    'TF',
    'TZ',
    'UG',
    'YT',
    'ZM',
    'ZW'
  ],

  // 018
  'Southern Africa': ['BW', 'LS', 'NA', 'SZ', 'ZA'],

  // 021
  'Northern America': ['BM', 'CA', 'GL', 'PM', 'US'],

  // 013
  'Central America': ['BZ', 'CR', 'GT', 'HN', 'MX', 'NI', 'PA', 'SV'],

  // 029
  Caribbean: [
    'AG',
    'AI',
    'AW',
    'BB',
    'BL',
    'BQ',
    'BS',
    'CU',
    'CW',
    'DM',
    'DO',
    'GD',
    'GP',
    'HT',
    'JM',
    'KN',
    'KY',
    'LC',
    'MF',
    'MQ',
    'MS',
    'PR',
    'SX',
    'TC',
    'TT',
    'VC',
    'VG',
    'VI'
  ],

  // 005
  'South America': ['AR', 'BO', 'BR', 'BV', 'CL', 'CO', 'EC', 'FK', 'GF', 'GS', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE'],

  // 154
  'Northern Europe': ['GG', 'IM', 'JE', 'AX', 'DK', 'EE', 'FI', 'FO', 'GB', 'IE', 'IS', 'LT', 'LV', 'NO', 'SE', 'SJ'],

  // 155
  'Western Europe': ['AT', 'BE', 'CH', 'DE', 'FR', 'LI', 'LU', 'MC', 'NL'],

  // 151
  'Eastern Europe': ['BG', 'BY', 'CZ', 'HU', 'MD', 'PL', 'RO', 'RU', 'SK', 'UA'],

  // 039
  'Southern Europe': [
    'AD',
    'AL',
    'BA',
    'ES',
    'GI',
    'GR',
    'HR',
    'IT',
    'ME',
    'MK',
    'MT',
    'RS',
    'PT',
    'SI',
    'SM',
    'VA',
    'XK'
  ],

  // 145
  'Western Asia': [
    'AE',
    'AM',
    'AZ',
    'BH',
    'CY',
    'GE',
    'IL',
    'IQ',
    'JO',
    'KW',
    'LB',
    'OM',
    'PS',
    'QA',
    'SA',
    'SY',
    'TR',
    'YE'
  ],

  // 143
  'Central Asia': ['TM', 'TJ', 'KG', 'KZ', 'UZ'],

  // 030
  'Eastern Asia': ['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW'],

  // 034
  'Southern Asia': ['AF', 'BD', 'BT', 'IN', 'IR', 'LK', 'MV', 'NP', 'PK'],

  // 035
  'Southeast Asia': ['BN', 'ID', 'KH', 'LA', 'MM', 'MY', 'PH', 'SG', 'TH', 'TL', 'VN'],

  // 053
  Australasia: ['AU', 'CC', 'CX', 'HM', 'NF', 'NZ'],

  // 054
  Melanesia: ['FJ', 'NC', 'PG', 'SB', 'VU'],

  // 057
  'Micronesian Region': ['FM', 'GU', 'KI', 'MH', 'MP', 'NR', 'PW', 'UM'],

  // 061
  Polynesia: ['AS', 'CK', 'NU', 'PF', 'PN', 'TK', 'TO', 'TV', 'WF', 'WS'],

  // QO
  QO: ['AQ', 'AC', 'CP', 'DG', 'TA']
} as const

export const world = {
  ...regions,

  // 002
  Africa: [
    ...regions['Northern Africa'],
    ...regions['Western Africa'],
    ...regions['Middle Africa'],
    ...regions['Eastern Africa'],
    ...regions['Southern Africa']
  ],

  // 003
  'North America': [...regions['Northern America'], ...regions['Central America'], ...regions['Caribbean']],

  // 019
  Americas: [
    ...regions['Northern America'],
    ...regions['Central America'],
    ...regions['Caribbean'],
    ...regions['South America']
  ],

  // 150
  Europe: [
    ...regions['Northern Europe'],
    ...regions['Western Europe'],
    ...regions['Eastern Europe'],
    ...regions['Southern Europe']
  ],

  // 142
  Asia: [
    ...regions['Western Asia'],
    ...regions['Central Asia'],
    ...regions['Eastern Asia'],
    ...regions['Southern Asia'],
    ...regions['Southeast Asia']
  ],

  // 009
  Oceania: [
    ...regions['Australasia'],
    ...regions['Melanesia'],
    ...regions['Micronesian Region'],
    ...regions['Polynesia'],
    ...regions['QO']
  ],

  // 202
  'Sub-Saharan Africa': [
    ...regions['Western Africa'],
    ...regions['Middle Africa'],
    ...regions['Eastern Africa'],
    ...regions['Southern Africa']
  ],

  // 419
  'Latin America': [...regions['Central America'], ...regions['Caribbean'], ...regions['South America']]
} as const

export type WorldRegion = keyof typeof world
