import { convertDateValues } from 'core'

import { appVersion } from '../../constants'
import { provider } from './provider'
import { IStorage } from './shared'

export type { GridSavedStateId, IStorage } from './shared'

// reasonable upper limit to TTL, so we don't brick installs if backend accidentally sets it too high
const maxTTL = 60 * 60 * 24 * 7 // 1 week

type StoredValue<T extends keyof IStorage> = { expires: number | null; appVersion: string | null; value: IStorage[T] }

export async function saveData<T extends keyof IStorage>(
  key: T,
  value: IStorage[T],
  options?: { unversioned?: boolean }
) {
  const data: StoredValue<T> = { value, appVersion: options?.unversioned ? null : appVersion, expires: null }

  /*
  if (typeof value === 'object' && value !== null && 'ttl' in value && typeof value.ttl === 'number') {
    data.expires = new Date().getTime() + Math.min(value.ttl, maxTTL) * 1000
  }
  */

  await provider.set(key, JSON.stringify(data))
}

export async function loadData<T extends keyof IStorage>(key: T): Promise<IStorage[T] | null> {
  const value = await provider.get(key)

  if (value === null) {
    return null
  }

  const data = convertDateValues(JSON.parse(value)) as StoredValue<T>

  if ((data.expires && data.expires < new Date().getTime()) || (data.appVersion && data.appVersion !== appVersion)) {
    await provider.remove(key)
    return null
  }

  return data.value
}

export const deleteData = (key: keyof IStorage, options?: { session?: boolean }) => provider.remove(key)

export const clearAllData = (options?: { session?: boolean }) => provider.clear()

const listeners: ((key: keyof IStorage, newValue?: unknown, oldValue?: unknown) => void)[] = []

export const onDataChange = (listener: typeof listeners[number]) => {
  listeners.push(listener)

  return () => {
    const index = listeners.indexOf(listener)
    listeners.splice(index, 1)
  }
}

provider.onChange((key, newValue, oldValue) => {
  const newData = newValue ? (convertDateValues(JSON.parse(newValue)) as StoredValue<any>) : null
  const oldData = oldValue ? (convertDateValues(JSON.parse(oldValue)) as StoredValue<any>) : null
  listeners.forEach(listener => listener(key as keyof IStorage, newData?.value ?? null, oldData?.value ?? null))
})
