import { IMarketProfile } from 'core'
import { useEffect, useRef } from 'react'

import { useMarketState } from './api'

export const usePollMarketState = (profileId: IMarketProfile['id'], options: Parameters<typeof useMarketState>[0]) => {
  const result = useMarketState(options)
  const intervalId = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = undefined
    }

    if (!result.data) {
      return
    }

    const profile = result.data.profiles.find(profile => profile.id === profileId)

    if (profile && profile.state !== 'Pending' && profile.state !== 'Generating') {
      return
    }

    intervalId.current = setInterval(result.refetch, 5000)
  }, [result.data, result.refetch, profileId])

  return result
}
