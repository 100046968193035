/// <reference types="@types/gtag.js" />

export const getGtag = (): Gtag.Gtag => window.gtag

export const scrollToTopOfPage = (): void => window.scrollTo(0, 0)

export const openPage = (url: string): void => {
  window.open(url)
}

export const openNewPage = (url: string): void => {
  window.open(url, '_blank')
}

export const redirectPage = (url: string): void => {
  window.location.href = url
}

export const reloadPage = (): void => window.location?.reload()

export const getPageTitle = (): string => window.document?.title

export const getPageLocation = (): string => window.location?.href

export const getPagePath = (): string => window.location?.pathname

export const createDivElement = (): HTMLDivElement => document.createElement('div')

export const createAnchorElement = (): HTMLAnchorElement => document.createElement('a')

export const getElementById = (elementId: string): HTMLElement | null => document.getElementById(elementId)

export const scrollIntoView = (element: Element) => element.scrollIntoView({ behavior: 'smooth' })

export const localStorage = {
  getItem: (key: string): string | null => window.localStorage.getItem(key),
  setItem: (key: string, value: string): void => window.localStorage.setItem(key, value),
  removeItem: (key: string): void => window.localStorage.removeItem(key)
}

export const setGlobalVariable = (name: string, value: any): void => {
  ;(window as any)[name] = value
}

export type WebFile = File

export type WebGeolocationPosition = GeolocationPosition

export const isIosSafari = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isSafari = () => {
  return (window as any).safari !== undefined
}
