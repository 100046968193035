import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery as _useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import {
  AddPositionToCollection,
  addPositionToCollection,
  AdvancedSearchQueryType,
  analyzeAllScreenerResume,
  AnalyzeAllScreenerResumes,
  AnalyzeScreenerResume,
  analyzeScreenerResume,
  AssociateUserWithSharedTeam,
  associateUserWithSharedTeam,
  ClientRoutePath,
  CollectionSlug,
  CompactPositionRecord,
  ConfirmEmail,
  confirmEmail,
  CountryCode,
  CreateApplication,
  createApplication,
  CreateBookmarks,
  createBookmarks,
  CreateCollection,
  createCollection,
  CreateMarketMembership,
  createMarketMembership,
  CreateMarketPerson,
  createMarketPerson,
  CreateMarketProduct,
  createMarketProduct,
  CreateMarketProfile,
  createMarketProfile,
  CreatePosition,
  createPosition,
  CreateScreenerResume,
  createScreenerResume,
  CreateStripeAccountLoginLink,
  createStripeAccountLoginLink,
  CreateStripeAccountOnboardingLink,
  createStripeAccountOnboardingLink,
  CreateTeam,
  createTeam,
  CreateUniversity,
  createUniversity,
  DatePostedFilterValues,
  DeleteBookmarks,
  deleteBookmarks,
  DeleteCollection,
  deleteCollection,
  DeleteMarketMembership,
  deleteMarketMembership,
  DeleteMarketPerson,
  deleteMarketPerson,
  DeleteMarketProduct,
  deleteMarketProduct,
  DeletePosition,
  deletePosition,
  DeleteUserFeedSection,
  deleteUserFeedSection,
  ExplorePositionType,
  findTopTeams,
  GenerateJobMacro,
  generateJobMacros,
  GenerateMarketProducts,
  generateMarketProducts,
  GenerateScreenerFilters,
  generateScreenerFilters,
  GenerateScreenerInterviewQuestions,
  generateScreenerInterviewQuestions,
  GenerateScreenerShareLink,
  generateScreenerShareLink,
  generateSpeech,
  getAdvancedSearch,
  getAffiliateContentQuery,
  getApiContentQuery,
  getApplicationsByTeam,
  getApplicationsByUser,
  getApplicationsStatuses,
  getAutopilotContentQuery,
  getBusinessContentQuery,
  getChangelogContentQuery,
  getChannelById,
  getChannelByPosition,
  getChannelByTeam,
  getChannelByType,
  getChannels,
  getCitiesSearch,
  getCollectionQuery,
  getCollections,
  getCompanySuggestions,
  getCopilotContentQuery,
  getCountryCollections,
  getDashboardPositions,
  getExampleMarketProfile,
  getExtensionState,
  GetFeedSectionDetail,
  getFeedSectionDetail,
  getFeedSections,
  getGeoData,
  getHash,
  getInterview,
  getInterviewQuestions,
  getInterviews,
  getInvitation,
  getInvitationsForUser,
  getJobMacros,
  getLandingPage,
  getLandingPageQuery,
  getLastPositionScreenerFilter,
  getLaunchpadContentQuery,
  getMarketDirectory,
  getMarketProfileByUsernameQuery,
  getMarketProfileQuery,
  GetMarketState,
  getMarketState,
  getNearbyLocations,
  getNewPositions,
  getNotifications,
  getOccupations,
  getPipelineContentQuery,
  getPlusContentQuery,
  getPositionComments,
  getPositionDetailByIDs,
  getPositionDetailQuery,
  getPositionQuery,
  GetPositionQuestion,
  getPositionQuestions,
  getPositionRawApplicationUrl,
  getPositionRelevant,
  getPositionsQuery,
  getPositionTrending,
  getPrivateCollections,
  getProfile,
  getProfileResume,
  getPromoteContentQuery,
  getRecentlyVisitedPositions,
  getRecommendedPositionBaseOnEducation,
  getRecommendedPositionBaseOnOccupation,
  getRelatedPositions,
  getRelatedPositionsQuery,
  getScreenerCategories,
  getScreenerContentQuery,
  getScreenerInterviewQuestions,
  getScreenerResumesByPositionId,
  getSearchGlobal,
  getShortPositionsQuery,
  getSitemapQuery,
  getSkillCollectionsQuery,
  getSkills,
  getSpecialCollectionsQuery,
  getStripeProducts,
  getStudioDataQuery,
  getSubscriptions,
  getTeamApplicationsStatuses,
  getTeamByIdQuery,
  getTeamByUsernameQuery,
  getTeamDashboardContentQuery,
  getTeamListForPositionFilter,
  getTeamPositionsQuery,
  getTeamsByUser,
  getTeamSubscriptionPlan,
  getTrendingJobsFromLocation,
  getUniversities,
  getURLPreview,
  GetUserFeedSections,
  getUserGitHubData,
  getUserJobFilters,
  getUserLocations,
  getUserPrompt,
  getUserSubscriptionBenefit,
  IAdvancedSearchQuery,
  IAgentCommand,
  IApplication,
  IBasicProduct,
  IChannel,
  ICollection,
  ICombinedPositionData,
  ICompany,
  IFeedSection,
  IFeedSectionQuery,
  IInterview,
  IInterviewQuestion,
  IInvitation,
  ILocation,
  ILocationSearchQuery,
  IMarketDirectory,
  IMarketMembership,
  IMarketPerson,
  IMarketProduct,
  IMarketProfile,
  IMarketProfileSetupData,
  IMarketProfileUpdateData,
  ImportResume,
  importResume,
  INewCollection,
  INewMarketMembership,
  INewMarketPerson,
  INewMarketProduct,
  INewUser,
  INotification,
  IOccupation,
  IPosition,
  IPositionPartialDetail,
  IPositionQuery,
  IResume,
  IResumeUpdateRequest,
  IScreenerResume,
  ISitemapSection,
  isTeamMember,
  ITeam,
  IUniversity,
  IUser,
  IUserPreference,
  IUserPrompt,
  JobScope,
  LandingPageResult,
  LandingPageSlug,
  MarkAllNotificationsAsRead,
  markAllNotificationsAsRead,
  MarkNotificationAsRead,
  markNotificationAsRead,
  MarkNotificationAsUnread,
  markNotificationAsUnread,
  NewChannel,
  PatchAutopilotState,
  patchAutopilotState,
  PromoteScreenerPosition,
  promoteScreenerPosition,
  RemovePositionFromCollection,
  removePositionFromCollection,
  RequestMagicLink,
  requestMagicLink,
  searchLocations,
  SendMessage,
  sendMessage,
  SetUpMarketProfile,
  setUpMarketProfile,
  Soc2018Code,
  StripeTeamService,
  StripeUserService,
  SubmitUserPrompt,
  submitUserPrompt,
  SupportedLocaleCode,
  TranslatedText,
  updateApplication,
  UpdateCollection,
  updateCollection,
  UpdateInterviewAudio,
  updateInterviewMessageAudio,
  UpdateJobScope,
  UpdateLocale,
  updateLocale,
  UpdateMarketMembership,
  updateMarketMembership,
  UpdateMarketPerson,
  updateMarketPerson,
  UpdateMarketProduct,
  updateMarketProduct,
  UpdateMarketProfile,
  updateMarketProfile,
  UpdatePosition,
  updatePosition,
  UpdatePreference,
  updateProfile,
  UpdateProfileAvatar,
  updateProfileAvatar,
  UpdateProfileCoverImage,
  updateProfileCoverImage,
  UpdateProfileResume,
  updateProfileResume,
  updateScope,
  UpdateScreenerEmail,
  updateScreenerEmail,
  UpdateTeam,
  updateTeam,
  updateTeamApplicationsStatuses,
  UpdateTeamLogo,
  updateTeamLogo,
  UpdateTeamSettings,
  updateTeamSettings,
  UpdateTheme,
  updateTheme,
  updateUserPreference,
  ValidateMagicLink,
  validateMagicLink,
  viewPosition
} from 'core'
import { useCallback, useMemo } from 'react'

import { isNotBot, useHistory, useLocation, useStore, useTranslation } from '~/lite'

import { IUseQueryWithNonIdealStateOptions, useQueryWithNonIdealState } from './useQueryWithNonIdealState'

const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> &
    IUseQueryWithNonIdealStateOptions<UseQueryResult<TData, TError>>
) => {
  const { isAuthorized, ...useQueryOptions } = options || {}

  return useQueryWithNonIdealState(_useQuery(queryKey, queryFn, useQueryOptions), {
    isAuthorized,
    enabled: useQueryOptions.enabled
  })
}

export const useGeoData = (enabled = true) =>
  useQuery(
    ['geodata'],
    async () => {
      const results = await getGeoData()

      if (!results.success) {
        return null
      }

      return { continents: results.continents, countries: results.countries }
    },
    { enabled }
  )

export const useTeamByUsername = (username?: ITeam['username'] | null) => {
  const { queryKey, queryFn } = getTeamByUsernameQuery(username)
  return useQuery(queryKey, queryFn, { isAuthorized: results => !!results.data?.isMember, enabled: !!username })
}

export const useTeamById = (id?: ITeam['id'] | null) => {
  const { queryKey, queryFn } = getTeamByIdQuery(id)
  return useQuery(queryKey, queryFn, { enabled: !!id })
}

export const useTeamStatuses = (teamId?: ITeam['id']) =>
  useQuery(['teams-statuses', teamId], async () => {
    if (!teamId) return []
    const results = await getTeamApplicationsStatuses(teamId)
    return results.success ? results.statuses : []
  })

export const useUpdateTeamLogo = (
  options?: UseMutationOptions<UpdateTeamLogo['response'], UpdateTeamLogo['errors'], UpdateTeamLogo['body']>
) => useMutation(async logo => updateTeamLogo(logo), options)

export const useCollections = (enabled = true, soc2018Code?: Soc2018Code, userId?: string) =>
  useQuery(
    ['collections', soc2018Code, userId],
    async () => {
      const results = await getCollections(soc2018Code)
      return results?.success ? results.collections : []
    },
    { enabled }
  )

export const useSkillCollections = () => {
  const { queryKey, queryFn } = getSkillCollectionsQuery()
  return useQuery(queryKey, queryFn)
}

export const useCreateBookmarks = (
  options?: UseMutationOptions<CreateBookmarks['response'], CreateBookmarks['errors'], CreateBookmarks['body']>
) => useMutation(({ positionIds }: { positionIds: IPosition['id'][] }) => createBookmarks({ positionIds }), options)

export const useDeleteBookmarks = (
  options?: UseMutationOptions<DeleteBookmarks['response'], DeleteBookmarks['errors'], DeleteBookmarks['body']>
) => useMutation(({ positionIds }: { positionIds: IPosition['id'][] }) => deleteBookmarks({ positionIds }), options)

export const useCreateCollection = (
  options?: UseMutationOptions<CreateCollection['response'], CreateCollection['errors'], CreateCollection['body']>
) => useMutation((collection: INewCollection) => createCollection(collection), options)

export const useUpdateCollection = (
  options?: UseMutationOptions<UpdateCollection['response'], UpdateCollection['errors'], UpdateCollection['body']>
) =>
  useMutation(
    (collection: { id: ICollection['id'] } & INewCollection) => updateCollection(collection.id, collection),
    options
  )

export const useDeleteCollection = (
  options?: UseMutationOptions<
    DeleteCollection['response'],
    DeleteCollection['errors'],
    DeleteCollection['params']['id']
  >
) => useMutation(id => deleteCollection(id), options)

export const useSpecialCollections = () => {
  const { queryKey, queryFn } = getSpecialCollectionsQuery()
  return useQuery(queryKey, queryFn)
}

export const useCollection = (collectionSlug: CollectionSlug) => {
  const { queryKey, queryFn } = getCollectionQuery(collectionSlug)
  return useQuery(queryKey, queryFn)
}

export const usePrivateCollections = (user: IUser | null, enabled = true) =>
  useQuery(
    ['privateCollections', user?.id, enabled],
    async () => {
      const results = user ? await getPrivateCollections() : null
      return results?.success ? results.collections : []
    },
    { enabled: !!user && enabled }
  )

export const useCountryCollections = () =>
  useQuery(['collections/countries'], async () => {
    const results = await getCountryCollections()
    return results?.success ? results.collections : []
  })

const useUserPreference = (): IUserPreference => {
  const { preferRemoteJobs, datePosted, distanceRadiusFilter, compensationFilter, compensationFrequencyFilter } =
    useStore()
  return {
    date: DatePostedFilterValues[datePosted],
    distanceRadius: distanceRadiusFilter,
    preferRemote: preferRemoteJobs,
    compensation: compensationFilter,
    compensationFrequency: compensationFrequencyFilter
  }
}

export const useValidateSearchRoute = () => {
  const pathLocation = useLocation()
  const history = useHistory()
  return useCallback(
    (newQueryKey: string, newQueryValue: string) => {
      const [route, query] = [pathLocation.pathname, pathLocation.search.substring(1)]
      if (route === '/search') {
        const queryParams = query ? query.split('&') : []
        let keyIndex = -1
        for (let i = 0; i < queryParams.length; i++) {
          const [key] = queryParams[i].split('=')
          if (key === newQueryKey) {
            queryParams[i] = `${newQueryKey}=${newQueryValue}`
            keyIndex = i
          }
        }
        if (keyIndex < 0 && newQueryValue) {
          queryParams.push(`${newQueryKey}=${newQueryValue}`)
        } else if (keyIndex >= 0 && !newQueryValue) {
          queryParams.splice(keyIndex, 1)
        }
        history.push(`/search?${queryParams.join('&')}` as ClientRoutePath)
      }
    },
    [history, pathLocation.pathname, pathLocation.search]
  )
}

const POSITION_SECTION_INDEX = 1
export type UsingLandingPageResult = {
  results: LandingPageResult | null
  queryKey: (string | number | null)[]
}
export const useLandingPage = (
  {
    countryCode,
    slug,
    locationId,
    excludePositions,
    page,
    limit
  }: {
    countryCode: CountryCode | null
    slug: LandingPageSlug | null
    locationId: ILocation['id'] | null
    excludePositions?: number
    page?: number
    limit?: number
  },
  enabled = true
) => {
  const userPreference = useUserPreference()
  const { queryKey } = getLandingPageQuery({
    countryCode,
    slug,
    locationId,
    excludePositions,
    page,
    limit,
    userPreference
  })
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 0 }) => {
      const results = await getLandingPage({
        countryCode,
        slug,
        locationId,
        excludePositions,
        page: pageParam,
        limit,
        userPreference
      })
      return {
        results: results.success ? results.data : null,
        queryKey
      }
    },
    {
      getNextPageParam: lastPageData => {
        return lastPageData?.results?.sections?.[POSITION_SECTION_INDEX]?.positions?.pageable?.nextPage
      },
      enabled
    }
  )
}

export const useFeedSections = (query?: IFeedSectionQuery, enabled = true) => {
  const { data, ...rest } = useInfiniteQuery<GetUserFeedSections['success']['data'] | null>(
    ['feed-sections', query],
    async ({ pageParam = 0 }) => {
      const results = await getFeedSections({
        ...query,
        page: pageParam.toString()
      })

      return results?.success ? results.data : null
    },
    { getNextPageParam: (lastPage: any) => lastPage.nextPage, enabled: enabled }
  )

  const combinedFeedSections = useMemo(
    () =>
      data?.pages
        ? data.pages.reduce(
            (combined, page) => ({
              sections: [...combined.sections, ...(page?.sections.data ?? [])]
            }),
            {
              sections: []
            } as {
              sections: IFeedSection[]
            }
          )
        : null,
    [data?.pages]
  )

  return {
    sections: combinedFeedSections?.sections,
    ...rest
  }
}

export const useFeedSectionDetail = (
  params: GetFeedSectionDetail['params'],
  query?: IFeedSectionQuery,
  isUpdatingJobScope = false,
  enabled = true
) => {
  const jobScope = useStore(state => state.jobScope)
  const userPreference = useUserPreference()

  const { data, ...rest } = useInfiniteQuery<GetFeedSectionDetail['success']['data'] | null>(
    ['feed-section-detail', params, userPreference, query, jobScope, isUpdatingJobScope],
    async ({ pageParam = 0 }) => {
      if (!params.feedSectionId || isUpdatingJobScope) {
        return null
      }
      const results = await getFeedSectionDetail(params?.feedSectionId, {
        ...query,
        date: userPreference?.date?.toString(),
        distanceRadius: userPreference?.distanceRadius?.toString(),
        preferRemote: userPreference?.preferRemote ? '1' : '0',
        compensation: userPreference?.compensation?.toString(),
        compensationFrequency: userPreference?.compensationFrequency || undefined,
        page: pageParam.toString(),
        scope: jobScope
      })

      return results?.success ? results.data : null
    },
    { getNextPageParam: (lastPage: any) => lastPage?.nextPage, enabled: enabled && !!params.feedSectionId }
  )

  const combinedJobs = useMemo(
    () =>
      data?.pages
        ? data.pages.reduce(
            (combined, page) => ({
              positions: [...combined.positions, ...(page?.positions.data ?? [])],
              nextPage: page?.positions.pageable.nextPage || null,
              sectionDetail: combined.sectionDetail || page?.sectionDetail || null,
              subTitleQueries: combined.subTitleQueries || page?.subTitleQueries || null
            }),
            {
              sectionDetail: null,
              positions: [],
              nextPage: null,
              subTitleQueries: null
            } as {
              sectionDetail: IFeedSection | null
              positions: CompactPositionRecord[]
              nextPage: number | null
              subTitleQueries: string[] | null
            }
          )
        : null,
    [data?.pages]
  )

  return {
    jobs: combinedJobs?.positions,
    sectionDetail: combinedJobs?.sectionDetail || null,
    subTitleQueries: combinedJobs?.subTitleQueries || null,
    queryKey: ['feed-section-detail', params, query, jobScope],
    ...rest
  }
}

export const useUserPrompt = (type: IUserPrompt['promptType'], locale: SupportedLocaleCode) =>
  useQuery(
    ['user-prompt', type, locale],
    async () => {
      const results = await getUserPrompt(type)
      return results?.success ? { promptValue: results.promptValue } : null
    },
    { enabled: isNotBot }
  )

export const useUserJobMacros = (jobIds: IPosition['id'][], userRequested = false) =>
  useQuery(['user-job-macros', jobIds, userRequested], async () => {
    if (!jobIds.length || !userRequested) {
      return {
        jobMacroData: []
      }
    }
    const results = await getJobMacros({ jobIds })
    return results?.success ? results.data : null
  })

export const useSubmitUserPrompt = (
  options?: UseMutationOptions<SubmitUserPrompt['response'], SubmitUserPrompt['errors'], SubmitUserPrompt['body']>
) =>
  useMutation(
    (data: { promptValue: TranslatedText; type: IUserPrompt['promptType'] }) =>
      submitUserPrompt(data.type, data.promptValue),
    options
  )

export const useGenerateJobMacros = (
  options?: UseMutationOptions<GenerateJobMacro['response'], GenerateJobMacro['errors'], GenerateJobMacro['body']>
) => useMutation((data: GenerateJobMacro['body']) => generateJobMacros(data), options)

export const usePositionComments = (positionId: IPosition['id'], enabled = true) =>
  useQuery(
    ['comments/positions', positionId],
    async () => {
      const results = await getPositionComments(positionId)
      return results?.success ? { comments: results.comments, reactions: results.reactions } : null
    },
    { enabled }
  )

export const usePosition = (id: IPosition['id'] | null | undefined) => {
  const { queryKey, queryFn } = getPositionQuery(id)
  return useQuery(queryKey, queryFn)
}

export const usePositionDetail = (id: IPosition['id'] | null | undefined, enabled = true, includeDeleted?: boolean) => {
  const { queryKey, queryFn } = getPositionDetailQuery(id, includeDeleted)
  return useQuery(queryKey, queryFn, { enabled })
}

export const useMultiplePositionsDetail = (ids: IPosition['id'][], enabled = true) =>
  useQuery(
    ['multiple-positions-detail', ids.length > 0 ? getHash(ids.join('|')) : null],
    async () => {
      const results = ids.length > 0 ? await getPositionDetailByIDs(ids) : null
      return results?.success ? results.data : null
    },
    { enabled }
  )

export const useViewPosition = (id: IPosition['id'] | null, agentCommandId: IAgentCommand['id'] | null) => {
  const queryClient = useQueryClient()
  return useQuery<null>(
    ['positions/view', id, agentCommandId],
    async () => {
      if (id) {
        const response = await viewPosition(id, agentCommandId)
        if (response.success && response.newFeedSectionId) {
          queryClient.resetQueries(['landing-page'])
        }
      }

      return null
    },
    { enabled: !!id }
  )
}

export const usePositions = (query: IPositionQuery | false) => {
  const jobScope = useStore(state => state.jobScope)
  const finalScope = query && query.scope ? query.scope : jobScope
  const { queryKey, queryFn, options } = getPositionsQuery(query, finalScope)

  const { data, ...rest } = useInfiniteQuery(queryKey, queryFn, options)

  const combinedData = useMemo<ICombinedPositionData | null>(
    () =>
      data?.pages
        ? data.pages.reduce(
            (combined, page) => ({
              positions: [...combined.positions, ...page.positions],
              locationsById: { ...combined.locationsById, ...page.locationsById },
              nextPage: page.nextPage
            }),
            {
              positions: [],
              locationsById: {},
              nextPage: null
            }
          )
        : null,
    [data?.pages]
  )

  return { data, combinedData, ...rest }
}

// export const useRecommendedPositions = (query: IRecommededPositionsQuery, enabled = true) =>
//   useQuery(
//     ['positions/recommendation-position', query],
//     async () => {
//       const results = await getRecommendedPositions(query)
//       return results.success ? results.positions : []
//     },
//     { enabled }
//   )

export const useRecommendedPositionsToUser = (
  page?: number,
  limit?: number,
  latLng?: [number, number],
  enabled = true
) => {
  const jobScope = useStore(state => state.jobScope)

  return useQuery(
    ['positions/recommendation', page, limit, jobScope],
    async () => {
      const results = await getPositionRelevant(page, limit, latLng, jobScope)
      return results.success ? results.data : []
    },
    { enabled }
  )
}

export const useTrendingPositions = (page?: number, limit?: number) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(['positions/trending', jobScope], async () => {
    const results = await getPositionTrending(undefined, page, limit, jobScope)
    return results.success ? results.data : []
  })
}

export const useExplorePositions = (
  type: ExplorePositionType,
  queryKey: QueryKey,
  limit?: number,
  mainPositionId?: IPositionPartialDetail['id'],
  scope?: JobScope
) => {
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 0 }) => {
      let results
      switch (type) {
        case 'trending':
          results = await getPositionTrending(undefined, pageParam, limit, scope)
          break
        case 'recommendation':
          results = await getPositionRelevant(pageParam, limit, undefined, scope)
          break
        case 'recently-visited':
          results = await getRecentlyVisitedPositions(pageParam, limit)
          break
        case 'related':
          if (mainPositionId) {
            results = await getRelatedPositions(mainPositionId, pageParam, limit, undefined, scope)
          }
          break
        case 'education-based-jobs':
          results = await getRecommendedPositionBaseOnEducation(pageParam, limit, scope)
          break
        case 'occupation-based-jobs':
          results = await getRecommendedPositionBaseOnOccupation(pageParam, limit, scope)
          break
        case 'new-jobs':
          results = await getNewPositions(pageParam, limit, scope)
          break
        case 'trending-from-location':
          results = await getTrendingJobsFromLocation(pageParam, limit, scope)
          break
        default:
          break
      }
      return results?.success ? results : null
    },
    {
      getNextPageParam: lastPageData => {
        return lastPageData?.pageable?.nextPage
      }
    }
  )
}

export const useSkills = () =>
  useQuery(['skills'], async () => {
    const results = await getSkills()
    return results.success ? results.skills : []
  })

export const useInvitation = (invitationId: IInvitation['id'] | null | undefined) =>
  useQuery(
    ['invitations', invitationId],
    async () => {
      if (!invitationId) {
        return null
      }

      const results = await getInvitation(invitationId)
      return results.success ? results.invitation : null
    },
    { enabled: !!invitationId }
  )

export const useUserGitHubData = () =>
  useQuery(['github/data'], async () => {
    const results = await getUserGitHubData()
    return results.success ? results.data : null
  })

export const useProfile = (username: IUser['username'] | null) =>
  useQuery<IUser | null>(
    ['user-profile', username],
    async () => {
      if (!username) {
        return null
      }

      const response = await getProfile(username)
      return response.success ? response.profile : null
    },
    {
      enabled: !!username
    }
  )

export const useProfileResume = (username: IUser['username'] | null) =>
  useQuery<IResume | null>(
    ['user-resume', username],
    async () => {
      if (!username) {
        return null
      }

      const response = await getProfileResume(username)
      return response.success ? response.profile : null
    },
    {
      enabled: !!username
    }
  )

export const useBasicProduct = () => {
  const t = useTranslation()

  return useMemo<IBasicProduct>(
    () => ({
      id: null,
      name: t('Basic'),
      description: t('Basic - free'),
      active: false,
      price: null,
      url: null,
      type: 'good',
      attributes: null,
      created: new Date().getTime(),
      updated: new Date().getTime(),
      images: [],
      livemode: true,
      metadata: {},
      unitLabel: null
    }),
    [t]
  )
}

const useStripeProducts = () =>
  useQuery(['stripeProducts'], async () => {
    const results = await getStripeProducts()
    return results.success ? results.products : null
  })

export const useProducts = () => {
  const basicProduct = useBasicProduct()
  const stripeProducts = useStripeProducts()

  return useMemo(
    () => (stripeProducts.data ? { ...stripeProducts, data: [...stripeProducts.data, basicProduct] } : stripeProducts),
    [stripeProducts, basicProduct]
  )
}

export const useTeams = (userId?: IUser['id']) =>
  useQuery<ITeam[] | null>(['user-teams', userId], async () => {
    const response = await getTeamsByUser()
    return response.success ? response.teams : null
  })

export const useCreateTeam = (
  options?: UseMutationOptions<CreateTeam['response'], CreateTeam['errors'], CreateTeam['body']>
) => useMutation((team: CreateTeam['body']) => createTeam(team), options)

export const useUpdateTeam = (
  options?: UseMutationOptions<UpdateTeam['response'], UpdateTeam['errors'], UpdateTeam['body']>
) => useMutation((team: UpdateTeam['body']) => updateTeam(team), options)

/*
@todo unused
export const useTeamChannels = (teamUsername: ITeam['username'] | undefined) =>
  useQuery<IChannel[] | null>(['channelMembers', teamUsername], async () => {
    if (!teamUsername) return null
    const results = await getTeamChannels(teamUsername)
    if (results.success) {
      return results.channels
    }
    return null
  })
*/

export const useCreateApplication = (
  options?: UseMutationOptions<CreateApplication['response'], CreateApplication['errors'], CreateApplication['body']>
) =>
  useMutation(({ positionId, formData }: CreateApplication['body']) => createApplication(positionId, formData), options)

export const useIsTeamMember = (username: ITeam['username'] | undefined) =>
  useQuery(['is-team-member', username], async () => {
    if (!username) return false
    const response = await isTeamMember(username)
    return response?.success ? response.isTeamMember : false
  })

export const useUpdateLocale = () =>
  useMutation(async ({ oldLocale, newLocale }: UpdateLocale['body']) => updateLocale(oldLocale, newLocale))

export const useUpdateTheme = () =>
  useMutation(async ({ oldTheme, newTheme }: UpdateTheme['body']) => updateTheme(oldTheme, newTheme))

export const useUpdateJobScope = (
  options?: UseMutationOptions<UpdateJobScope['response'], UpdateJobScope['errors'], UpdateJobScope['body']>
) => useMutation(async ({ oldScope, newScope }: UpdateJobScope['body']) => updateScope(oldScope, newScope), options)

export const useUpdateUserPreference = () =>
  useMutation(async (requestBody: UpdatePreference['body']) => updateUserPreference(requestBody))

export const useUpdateProfile = () => useMutation(async (user: INewUser) => updateProfile(user))

export const useImportResume = (
  options?: UseMutationOptions<ImportResume['response'], ImportResume['errors'], ImportResume['body']>
) => useMutation(async (data: { resume: IUser['resume']; method?: 'Launchpad' }) => importResume(data), options)

export const useUpdateProfileAvatar = (
  options?: UseMutationOptions<
    UpdateProfileAvatar['response'],
    UpdateProfileAvatar['errors'],
    UpdateProfileAvatar['body']
  >
) => useMutation(async (avatar: { avatar: IUser['avatar'] }) => updateProfileAvatar(avatar), options)

export const useUpdateProfileCoverImage = (
  options?: UseMutationOptions<
    UpdateProfileCoverImage['response'],
    UpdateProfileCoverImage['errors'],
    UpdateProfileCoverImage['body']
  >
) =>
  useMutation(
    async (coverImage: { coverImage: NonNullable<IResume['basics']>['coverImage'] }) =>
      updateProfileCoverImage(coverImage),
    options
  )

export const useUpdateProfileResume = (
  options?: UseMutationOptions<
    UpdateProfileResume['response'],
    UpdateProfileResume['errors'],
    UpdateProfileResume['body']
  >
) => useMutation(async (resume: IResumeUpdateRequest) => updateProfileResume(resume), options)

export const useUniversities = (term: string) =>
  useQuery<IUniversity[] | null>(
    ['universities', term],
    async () => {
      const response = await getUniversities(term)
      return response.success ? response.universities : null
    },
    { enabled: !!term }
  )

export const useCreateUniversity = (
  options?: UseMutationOptions<CreateUniversity['response'], CreateUniversity['errors'], CreateUniversity['body']>
) => useMutation((university: CreateUniversity['body']) => createUniversity(university.name), options)

export const useApplicationsByUser = () =>
  useQuery(['user-applications'], async () => {
    const results = await getApplicationsByUser()
    return results.success ? results.applications : []
  })

export const useApplicationsByTeam = (teamId: ITeam['id'] | null) =>
  useQuery<IApplication[] | null>(
    ['teams', teamId, 'applications'],
    async () => {
      const response = teamId ? await getApplicationsByTeam(teamId) : null
      return response?.success ? response.applications : null
    },
    { enabled: !!teamId }
  )

export const useApplicationsStatuses = () =>
  useQuery(['applications-statuses'], async () => {
    const results = await getApplicationsStatuses()
    return results.success ? results.statuses : []
  })

export const useUpdateApplicationMutation = () =>
  useMutation(
    async (application: Parameters<typeof updateApplication>[0]) => (await updateApplication(application)).success,
    { retry: 3 }
  )

export const useCreatePosition = () =>
  useMutation(async (position: CreatePosition['body']) => {
    const response = await createPosition(position)
    return response
  })

export const useUpdatePosition = () =>
  useMutation(async (position: UpdatePosition['body']) => {
    const response = await updatePosition(position)
    return response
  })

export const useAddPositionToCollection = (
  options?: UseMutationOptions<
    AddPositionToCollection['response'],
    AddPositionToCollection['errors'],
    AddPositionToCollection['body']
  >
) =>
  useMutation(
    async (params: {
      positionId: AddPositionToCollection['body']['positionId']
      collectionId: AddPositionToCollection['params']['id']
    }) => {
      const response = await addPositionToCollection(params.positionId, params.collectionId)
      return response
    },
    options
  )

export const useRemovePositionFromCollection = (
  options?: UseMutationOptions<
    RemovePositionFromCollection['response'],
    RemovePositionFromCollection['errors'],
    RemovePositionFromCollection['body']
  >
) =>
  useMutation(async (params: { id: IPosition['id'] }) => {
    const response = await removePositionFromCollection(params.id)
    return response
  }, options)

export const useDeletePosition = (
  options?: UseMutationOptions<DeletePosition['response'], DeletePosition['errors'], DeletePosition['body']>
) =>
  useMutation(async (position: DeletePosition['params']) => {
    const response = await deletePosition(position)
    return response
  }, options)

export const useInvitations = () =>
  useQuery<IInvitation[] | null>(['user-invitations'], async () => {
    const response = await getInvitationsForUser()
    return response.success ? response.invitations : null
  })

export const useGlobalSearch = (debouncedSearchQuery: string, value: string) =>
  useQuery(['searchGlobal', debouncedSearchQuery], () => getSearchGlobal(debouncedSearchQuery), {
    select: response =>
      response.success
        ? response.results
        : {
            occupations: [],
            skills: [],
            locations: [],
            teams: []
          },
    enabled: !!value
  })

export const useURLPreview = (urls: string[]) =>
  useQuery(['url-preview', urls], async () => {
    if (!urls || urls.length === 0) {
      return null
    }
    const response = await getURLPreview(urls)
    return response.success ? response.previews : null
  })

export const useUpdateTeamStatuses = () =>
  useMutation(({ teamId, statuses }: { teamId: ITeam['id']; statuses: Array<any> }) =>
    updateTeamApplicationsStatuses(teamId, statuses)
  )

export const useChannels = () =>
  useQuery<IChannel[]>(['channels'], async () => {
    const response = await getChannels()
    return response.success ? response.channels : []
  })

export const useChannel = (
  channel: IChannel['id'] | NewChannel | null,
  options?: { onSuccess?: (data: IChannel | null) => void }
) =>
  useQuery<IChannel | null>(
    ['channel', channel],
    async () => {
      if (!channel) {
        return null
      }

      if (typeof channel === 'string') {
        const response = await getChannelById(channel)
        return response.success ? response.channel : null
      } else if ('positionId' in channel) {
        const response = await getChannelByPosition(channel.positionId)
        return response.success ? response.channel : null
      } else if ('teamId' in channel) {
        const response = await getChannelByTeam(channel.teamId)
        return response.success ? response.channel : null
      } else if ('type' in channel) {
        const response = await getChannelByType(channel.type)
        return response.success ? response.channel : null
      }

      return null
    },
    options
  )

export const useSendMessage = () =>
  useMutation(async (data: SendMessage['body']) => {
    const response = await sendMessage(data)
    return response.success ? { messages: response.messages, channels: response.channels } : null
  })

export const useCompanySuggestions = (term: string) =>
  useQuery<ICompany[] | null>(['company-suggestions', term], async () => {
    const response = await getCompanySuggestions(term)
    return response.success ? response.companies : null
  })

export const useMarkAllNotificationsAsRead = (
  options?: UseMutationOptions<
    MarkAllNotificationsAsRead['response'],
    MarkAllNotificationsAsRead['errors'],
    MarkAllNotificationsAsRead['body']
  >
) => useMutation(() => markAllNotificationsAsRead(), options)

export const useMarkNotificationAsRead = (
  options?: UseMutationOptions<
    MarkNotificationAsRead['response'],
    MarkNotificationAsRead['errors'],
    MarkNotificationAsRead['params']['id']
  >
) => useMutation(notificationId => markNotificationAsRead(notificationId), options)

export const useMarkNotificationAsUnread = (
  options?: UseMutationOptions<
    MarkNotificationAsUnread['response'],
    MarkNotificationAsUnread['errors'],
    MarkNotificationAsUnread['params']['id']
  >
) => useMutation(notificationId => markNotificationAsUnread(notificationId), options)

export const useNotifications = () => {
  const { data, ...rest } = useInfiniteQuery(
    ['notifications'],
    async ({ pageParam }) => getNotifications({ cursor: pageParam }),
    { getNextPageParam: lastPage => (lastPage.success && lastPage.nextCursor ? lastPage.nextCursor : undefined) }
  )

  const combinedData = useMemo(
    () =>
      data?.pages
        ? data.pages.reduce(
            (combined, page) => (page.success ? [...combined, ...page.notifications] : combined),
            [] as INotification[]
          )
        : null,
    [data?.pages]
  )

  return { data, combinedData, ...rest }
}

export const useSubscriptions = (limit?: number) =>
  useInfiniteQuery(
    ['subscriptions'],
    async ({ pageParam = 0 }) => {
      const results = await getSubscriptions(pageParam, limit)
      return results?.success ? results : null
    },
    {
      getNextPageParam: lastPageData => {
        return lastPageData?.pageable?.nextPage
      }
    }
  )

export const useFindTopTeams = (page?: number, limit?: number, isExpandable?: boolean) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(['top-teams', page, limit, jobScope, isExpandable], async () => {
    const results = await findTopTeams(page, limit, jobScope, isExpandable)
    return results.success ? results.data : []
  })
}

export const useGetRecentlyVisitedPositions = (page?: number, limit?: number) =>
  useQuery(['positions/recently-visited'], async () => {
    const results = await getRecentlyVisitedPositions(page, limit)
    return results.success ? results.data : []
  })

export const useGetNewPositions = (page?: number, limit?: number, enabled = true) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(
    ['positions/new', jobScope],
    async () => {
      const results = await getNewPositions(page, limit, jobScope)
      return results.success ? { category: results.category, data: results.data } : { category: null, data: [] }
    },
    {
      enabled
    }
  )
}

export const useGetRecommendedPositionBaseOnEducation = (page?: number, limit?: number, enabled = true) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(
    ['positions/recommended-positions-based-on-education', jobScope],
    async () => {
      const results = await getRecommendedPositionBaseOnEducation(page, limit, jobScope)
      return results.success ? { category: results.category, data: results.data } : { category: null, data: [] }
    },
    {
      enabled
    }
  )
}

export const useGetRecommendedPositionBaseOnOccupation = (page?: number, limit?: number, enabled = true) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(
    ['positions/recommended-positions-based-on-occupation', jobScope],
    async () => {
      const results = await getRecommendedPositionBaseOnOccupation(page, limit, jobScope)
      return results.success ? { category: results.category, data: results.data } : { category: null, data: [] }
    },
    {
      enabled
    }
  )
}

export const useGetTrendingJobsFromLocation = (page?: number, limit?: number, enabled = true) => {
  const jobScope = useStore(state => state.jobScope)
  return useQuery(
    ['positions/trending-from-location', jobScope],
    async () => {
      const results = await getTrendingJobsFromLocation(page, limit, jobScope)
      return results.success ? { category: results.category, data: results.data } : { category: null, data: [] }
    },
    {
      enabled
    }
  )
}

export const useTeamPositions = (id?: ITeam['id'], page?: number, limit?: number, source?: 'dashboard' | null) => {
  const jobScope = useStore(state => state.jobScope)
  const { queryKey, queryFn } = getTeamPositionsQuery(id, page, limit, jobScope, source)

  return useQuery(queryKey, queryFn, {
    enabled: !!id
  })
}

export const useRelatedPositions = (
  id: IPosition['id'],
  page?: number,
  limit?: number,
  latLng?: [number, number],
  enabled = true
) => {
  const jobScope = useStore(state => state.jobScope)
  const { queryKey, queryFn } = getRelatedPositionsQuery(id, page, limit, latLng, jobScope)
  return useQuery(queryKey, queryFn)
}

export const usePositionQuestions = (
  id: IPosition['id'],
  options?: UseQueryOptions<IPosition['questions'] | null, GetPositionQuestion['errors'], IPosition['questions'] | null>
) =>
  useQuery(
    ['positions/question', id],
    async () => {
      const results = await getPositionQuestions(id)
      return results.success ? results.questions : null
    },
    options
  )

export const useAdvancedSearch = (
  search: string,
  type: AdvancedSearchQueryType,
  page?: number,
  latLng?: [number, number],
  query?: IAdvancedSearchQuery
) => {
  return useInfiniteQuery(
    ['advanced-search', search, type, page, latLng, query],
    async ({ pageParam = 0 }) => {
      const results = search ? await getAdvancedSearch({ ...query, search, type, page: pageParam }) : null
      return results?.success ? results.data : null
    },
    {
      enabled: !!search,
      getNextPageParam: lastPageData => {
        return lastPageData?.nextPage
      }
    }
  )
}

export const useCitiesSearch = (page?: number, limit?: number, search?: string, state?: string) => {
  return useInfiniteQuery(
    ['cities-search', page, limit, search, state],
    async ({ pageParam = 0 }) => {
      const results = await getCitiesSearch({
        page: pageParam.toString(),
        limit: limit ? limit.toString() : undefined,
        search,
        state
      })
      return results?.success ? results.data : null
    },
    {
      getNextPageParam: lastPageData => {
        return lastPageData?.pageable.nextPage
      },
      enabled: !!search
    }
  )
}

export const useSearchLocations = (query: ILocationSearchQuery, enabled = true) =>
  useQuery(
    ['locations-search', query],
    async () => {
      if (!query.search) {
        return []
      }
      const results = await searchLocations(query)
      return results.success ? results.locations : null
    },
    {
      enabled
    }
  )

export const useNearbyLocations = (query: ILocationSearchQuery, isUpdatingJobScope = false, enabled = true) =>
  useQuery(
    ['nearby-locations', query, isUpdatingJobScope],
    async () => {
      if (isUpdatingJobScope) {
        return null
      }
      const results = await getNearbyLocations(query)
      return results.success ? results.nearbyLocations : null
    },
    {
      enabled: enabled
    }
  )

export const useUserLocations = (query: ILocationSearchQuery, enabled = true) =>
  useQuery(
    ['user-locations', query],
    async () => {
      const results = await getUserLocations(query)
      return results.success ? results.userLocations : null
    },
    {
      enabled: enabled
    }
  )

export const useTeamListForPositionFilter = (query: IPositionQuery | false, searchQuery: string) => {
  const jobScope = useStore(state => state.jobScope)
  const finalScope = query && query.scope ? query.scope : jobScope
  return useInfiniteQuery(
    ['position-filter-team-list', query, searchQuery, finalScope],
    async ({ pageParam = 0 }) => {
      const results = await getTeamListForPositionFilter({
        ...query,
        page: pageParam.toString(),
        teamName: searchQuery,
        scope: finalScope
      })
      return results?.success ? results.data : null
    },
    {
      getNextPageParam: lastPageData => {
        return lastPageData?.pageable.nextPage
      }
    }
  )
}

export const useConfirmEmail = (
  options?: UseMutationOptions<ConfirmEmail['response'], ConfirmEmail['errors'], ConfirmEmail['body']>
) => useMutation(async data => confirmEmail(data.secret), options)

export const useRequestMagicLink = (
  options?: UseMutationOptions<RequestMagicLink['response'], RequestMagicLink['errors'], RequestMagicLink['body']>
) => useMutation(async data => requestMagicLink(data), options)

export const useValidateMagicLink = (
  options?: UseMutationOptions<ValidateMagicLink['response'], ValidateMagicLink['errors'], ValidateMagicLink['body']>
) => useMutation(async data => validateMagicLink(data.secret), options)

export const useShortPositions = (page?: number, limit?: number, enabled = true) => {
  const jobScope = useStore(state => state.jobScope)
  const { queryKey, queryFn } = getShortPositionsQuery(page, limit, jobScope)
  return useInfiniteQuery(queryKey, queryFn, {
    enabled,
    getNextPageParam: lastPageData => {
      return lastPageData?.pageable.nextPage
    }
  })
}

export const useSitemap = (section?: ISitemapSection['slug'], page?: string) => {
  const { queryKey, queryFn } = getSitemapQuery(section, page)
  return useQuery(queryKey, queryFn)
}

export const useUserLocation = () => {
  const geoData = useGeoData()
  const { countries } = geoData?.data ?? {}
  const [location, jobScope] = useStore(store => [store.location, store.jobScope])

  const locationInfo = useMemo(() => {
    if (jobScope !== 'country' && jobScope !== 'expand' && jobScope !== location?.countryCode) {
      return { countryCode: jobScope as string, cityName: null, level1Name: null }
    }
    if (!location || !location.countryCode) {
      return null
    }
    return { countryCode: location.countryCode, cityName: location.cityName, level1Name: location.level1Name }
  }, [jobScope, location])

  const countryName = useMemo<string | null>(() => {
    if (!locationInfo) {
      return null
    }
    return countries?.find(country => country.code === locationInfo.countryCode)?.name || null
  }, [countries, locationInfo])

  return { locationInfo, countryName }
}

export const useDeleteUserFeedSection = (
  options?: UseMutationOptions<
    DeleteUserFeedSection['response'],
    DeleteUserFeedSection['errors'],
    DeleteUserFeedSection['params']
  >
) =>
  useMutation(async params => {
    const response = await deleteUserFeedSection(params.id)
    return response
  }, options)

export const useOccupations = (slugLocale: SupportedLocaleCode, enabled = true) =>
  useQuery<IOccupation[]>(
    ['occupations', slugLocale],
    async () => {
      const response = await getOccupations(slugLocale)
      return response.success ? response.occupations : []
    },
    { enabled }
  )

const studioDataQuery = getStudioDataQuery()

export const useStudioData = (enabled: boolean) =>
  useQuery(studioDataQuery.queryKey, studioDataQuery.queryFn, { enabled })

export const useGenerateSpeech = (question?: string) =>
  useQuery<Blob | undefined>(
    ['speech', question],
    async () => {
      if (!question) {
        return
      }
      const response = await generateSpeech(question)
      return response
    },
    { enabled: !!question }
  )

export const usePositionRawApplicationUrl = (positionId: IPosition['id']) =>
  useQuery<string | null>(['positions', 'application-url', positionId], async () => {
    const response = await getPositionRawApplicationUrl(positionId)
    return response.success ? response.applicationUrl : null
  })

export const usePositionInterviewQuestions = (positionId: IPosition['id'] | null) =>
  useQuery<IInterviewQuestion[] | null>(['positions', 'interview-questions', positionId], async () => {
    if (!positionId) {
      return null
    }
    const response = await getInterviewQuestions(positionId)
    return response.success ? response.questions : null
  })

const affiliateContentQuery = getAffiliateContentQuery()

const apiContentQuery = getApiContentQuery()

const autopilotContentQuery = getAutopilotContentQuery()

const businessContentQuery = getBusinessContentQuery()

const changelogContentQuery = getChangelogContentQuery()

const copilotContentQuery = getCopilotContentQuery()

const launchpadContentQuery = getLaunchpadContentQuery()

const pipelineContentQuery = getPipelineContentQuery()

const plusContentQuery = getPlusContentQuery()

const promoteContentQuery = getPromoteContentQuery()

const screenerContentQuery = getScreenerContentQuery()

const teamDashboardContentQuery = getTeamDashboardContentQuery()

export const useAffiliateContent = () => useQuery(affiliateContentQuery.queryKey, affiliateContentQuery.queryFn)

export const useApiContent = () => useQuery(apiContentQuery.queryKey, apiContentQuery.queryFn)

export const useAutopilotContent = () => useQuery(autopilotContentQuery.queryKey, autopilotContentQuery.queryFn)

export const useBusinessContent = () => useQuery(businessContentQuery.queryKey, businessContentQuery.queryFn)

export const useChangelogContent = () => useQuery(changelogContentQuery.queryKey, changelogContentQuery.queryFn)

export const useCopilotContent = ({ enabled }: { enabled: boolean }) =>
  useQuery(copilotContentQuery.queryKey, copilotContentQuery.queryFn, { enabled })

export const useLaunchpadContent = () => useQuery(launchpadContentQuery.queryKey, launchpadContentQuery.queryFn)

export const usePipelineContent = () => useQuery(pipelineContentQuery.queryKey, pipelineContentQuery.queryFn)

export const usePlusContent = () => useQuery(plusContentQuery.queryKey, plusContentQuery.queryFn)

export const usePromoteContent = () => useQuery(promoteContentQuery.queryKey, promoteContentQuery.queryFn)

export const useScreenerContent = () => useQuery(screenerContentQuery.queryKey, screenerContentQuery.queryFn)

export const useTeamDashboardContent = () =>
  useQuery(teamDashboardContentQuery.queryKey, teamDashboardContentQuery.queryFn)

export const useInterviews = () =>
  useQuery(['interviews'], async () => {
    const results = await getInterviews()
    return results.success ? results.interviews : null
  })

export const useInterview = (interviewId: IInterview['id']) =>
  useQuery(['interviews', interviewId], async () => {
    const results = await getInterview(interviewId)
    return results.success
      ? {
          interview: results.interview,
          interviewLogMessages: results.interviewLogMessages,
          interviewQuestions: results.interviewQuestions
        }
      : null
  })

export const useUpdateInterviewMessageAudio = (
  options?: UseMutationOptions<
    UpdateInterviewAudio['response'],
    UpdateInterviewAudio['errors'],
    UpdateInterviewAudio['body']
  >
) => useMutation(async data => updateInterviewMessageAudio(data), options)

export const useExtensionState = () => useQuery(['extension-state'], getExtensionState)

export const useUserSubscriptionBenefit = (service: StripeUserService) =>
  useQuery(['user-subscription-benefit', service], async () => {
    const results = await getUserSubscriptionBenefit(service)
    if (service === 'ambition-plus') {
      return results.success && results.benefits && 'numberOfCoverLetter' in results.benefits && results.metadata
        ? { benefits: results.benefits, metadata: results.metadata }
        : null
    }

    return null
  })

export const useTeamSubscriptionBenefit = (service: StripeTeamService, teamId?: ITeam['id']) =>
  useQuery(['team-subscription', service, teamId], async () => {
    if (!teamId) {
      return null
    }
    const results = await getTeamSubscriptionPlan(service, teamId)
    return results.success && results.benefits && results.metadata
      ? { benefits: results.benefits, metadata: results.metadata, teamDetail: results.teamDetail }
      : null
  })

export const useUserJobFilters = () =>
  useQuery(['user-job-filters'], async () => {
    const results = await getUserJobFilters()
    return results.success ? results.filters : null
  })

export const useDashboardPositions = (query: IPositionQuery) =>
  useQuery(['positions', 'dashboard', query], async () => {
    const results = await getDashboardPositions(query)
    return results.success ? results : null
  })

export const useScreenerCategories = () =>
  useQuery(['screener', 'categories'], async () => {
    const results = await getScreenerCategories()
    return results.success ? results.data : null
  })

export const useGenerateScreenerFilters = (
  options?: UseMutationOptions<
    GenerateScreenerFilters['response'],
    GenerateScreenerFilters['errors'],
    GenerateScreenerFilters['body']
  >
) => useMutation(async data => generateScreenerFilters(data), options)

export const usePositionLastScreenerFilter = (positionId?: IPosition['id']) =>
  useQuery(['position-last-screener-filter', positionId], async () => {
    if (!positionId) {
      return null
    }
    const results = await getLastPositionScreenerFilter(positionId)
    return results.success ? results.data : null
  })

export const useGetScreenerResumesByPositionId = (positionId?: IPosition['id']) =>
  useQuery(['screener-resumes', positionId], async () => {
    if (!positionId) {
      return null
    }
    const results = await getScreenerResumesByPositionId(positionId)
    return results.success ? results.data : null
  })

export const useCreateScreenerResume = (
  options?: UseMutationOptions<
    CreateScreenerResume['response'],
    CreateScreenerResume['errors'],
    CreateScreenerResume['body']
  >
) => useMutation(async data => createScreenerResume(data), options)

export const useGetScreenerInterviewQuestions = (
  teamId?: ITeam['id'],
  positionId?: IPosition['id'],
  screenerResumeId?: IScreenerResume['id']
) =>
  useQuery(['screener-interview-questions', positionId, screenerResumeId], async () => {
    if (!positionId || !teamId) {
      return null
    }
    const results = await getScreenerInterviewQuestions({ teamId, positionId, screenerResumeId })
    return results.success ? results.data : null
  })

export const useGenerateScreenerInterviewQuestions = (
  options?: UseMutationOptions<
    GenerateScreenerInterviewQuestions['response'],
    GenerateScreenerInterviewQuestions['errors'],
    GenerateScreenerInterviewQuestions['body']
  >
) => useMutation(async data => generateScreenerInterviewQuestions(data), options)

export const useGenerateScreenerShareLink = (
  options?: UseMutationOptions<
    GenerateScreenerShareLink['response'],
    GenerateScreenerShareLink['errors'],
    GenerateScreenerShareLink['body']
  >
) => useMutation(async data => generateScreenerShareLink(data), options)

export const useAssociateUserWithSharedTeam = (
  options?: UseMutationOptions<
    AssociateUserWithSharedTeam['response'],
    AssociateUserWithSharedTeam['errors'],
    AssociateUserWithSharedTeam['body']
  >
) => useMutation(async data => associateUserWithSharedTeam(data), options)

export const usePromoteScreenerPosition = (
  options?: UseMutationOptions<
    PromoteScreenerPosition['response'],
    PromoteScreenerPosition['errors'],
    PromoteScreenerPosition['body']
  >
) => useMutation(async data => promoteScreenerPosition(data), options)

export const useUpdateTeamSettings = (
  options?: UseMutationOptions<UpdateTeamSettings['response'], UpdateTeamSettings['errors'], UpdateTeamSettings['body']>
) => useMutation(async data => updateTeamSettings(data), options)

export const useAnalyzeScreenerResume = (
  options?: UseMutationOptions<
    AnalyzeScreenerResume['response'],
    AnalyzeScreenerResume['errors'],
    AnalyzeScreenerResume['body']
  >
) => useMutation(async data => analyzeScreenerResume(data), options)

export const useAnalyzeAllScreenerResumes = (
  options?: UseMutationOptions<
    AnalyzeAllScreenerResumes['response'],
    AnalyzeAllScreenerResumes['errors'],
    AnalyzeAllScreenerResumes['body']
  >
) => useMutation(async data => analyzeAllScreenerResume(data), options)

export const usePatchAutopilotState = (
  options?: UseMutationOptions<
    PatchAutopilotState['response'],
    PatchAutopilotState['errors'],
    PatchAutopilotState['body']
  >
) => useMutation(async state => patchAutopilotState(state), options)
export const useUpdatePositionScreenerEmail = (
  options?: UseMutationOptions<
    UpdateScreenerEmail['response'],
    UpdateScreenerEmail['errors'],
    UpdateScreenerEmail['body']
  >
) => useMutation(async data => updateScreenerEmail(data), options)

export const useMarketProfile = (id?: IMarketProfile['id']) => {
  const { queryKey, queryFn } = getMarketProfileQuery(id)
  return useQuery<IMarketProfile | null>(queryKey, queryFn, { enabled: !!id })
}

export const useMarketProfileByUsername = (username?: IMarketProfile['username']) => {
  const { queryKey, queryFn } = getMarketProfileByUsernameQuery(username)
  return useQuery<IMarketProfile | null>(queryKey, queryFn, { enabled: !!username })
}

export const marketStateQueryKey = ['markets', 'state']

export const marketDirectoryQueryKey = ['markets', 'directory']

export const useMarketState = (options?: {
  refreshStripe?: IMarketProfile['id']
  onSuccess?: (data: Extract<GetMarketState['response'], { success: true }> | null) => void
}) =>
  useQuery<Extract<GetMarketState['response'], { success: true }> | null>(
    marketStateQueryKey,
    async () => {
      const response = await getMarketState(options?.refreshStripe)
      return response.success ? response : null
    },
    options
  )

export const useExampleMarketProfile = (options?: {
  enabled?: boolean
  onSuccess?: (data: IMarketProfile | null) => void
}) =>
  useQuery<IMarketProfile | null>(
    ['markets', 'profiles', 'example'],
    async () => {
      const response = await getExampleMarketProfile()
      return response.success ? response.profile : null
    },
    options
  )

export const useMarketDirectory = (options?: { enabled?: boolean }) =>
  useQuery<IMarketDirectory | null>(
    marketDirectoryQueryKey,
    async () => {
      const response = await getMarketDirectory()
      return response.success ? response.directory : null
    },
    options
  )

export const useCreateStripeAccountOnboardingLink = (
  options?: UseMutationOptions<
    CreateStripeAccountOnboardingLink['response'],
    CreateStripeAccountOnboardingLink['errors'],
    CreateStripeAccountOnboardingLink['body']
  >
) => useMutation(data => createStripeAccountOnboardingLink(data), options)

export const useCreateStripeAccountLoginLink = (
  options?: UseMutationOptions<
    CreateStripeAccountLoginLink['response'],
    CreateStripeAccountLoginLink['errors'],
    CreateStripeAccountLoginLink['body']
  >
) => useMutation(() => createStripeAccountLoginLink({}), options)

export const useCreateMarketMembership = (
  options?: UseMutationOptions<
    CreateMarketMembership['response'],
    CreateMarketMembership['errors'],
    CreateMarketMembership['body']
  >
) => useMutation((membership: INewMarketMembership) => createMarketMembership(membership), options)

export const useUpdateMarketMembership = (
  options?: UseMutationOptions<
    UpdateMarketMembership['response'],
    UpdateMarketMembership['errors'],
    UpdateMarketMembership['body']
  >
) =>
  useMutation(
    (membership: { id: IMarketMembership['id'] } & INewMarketMembership) =>
      updateMarketMembership(membership.id, membership),
    options
  )

export const useDeleteMarketMembership = (
  options?: UseMutationOptions<
    DeleteMarketMembership['response'],
    DeleteMarketMembership['errors'],
    DeleteMarketMembership['params']['id']
  >
) => useMutation(id => deleteMarketMembership(id), options)

export const useCreateMarketPerson = (
  options?: UseMutationOptions<CreateMarketPerson['response'], CreateMarketPerson['errors'], CreateMarketPerson['body']>
) => useMutation((person: INewMarketPerson) => createMarketPerson(person), options)

export const useUpdateMarketPerson = (
  options?: UseMutationOptions<UpdateMarketPerson['response'], UpdateMarketPerson['errors'], UpdateMarketPerson['body']>
) =>
  useMutation(
    (person: { id: IMarketPerson['id'] } & INewMarketPerson) => updateMarketPerson(person.id, person),
    options
  )

export const useDeleteMarketPerson = (
  options?: UseMutationOptions<
    DeleteMarketPerson['response'],
    DeleteMarketPerson['errors'],
    DeleteMarketPerson['params']['id']
  >
) => useMutation(id => deleteMarketPerson(id), options)

export const useCreateMarketProfile = (
  options?: UseMutationOptions<
    CreateMarketProfile['response'],
    CreateMarketProfile['errors'],
    CreateMarketProfile['body']
  >
) => useMutation((data: CreateMarketProfile['body']) => createMarketProfile(data), options)

export const useUpdateMarketProfile = (
  options?: UseMutationOptions<
    UpdateMarketProfile['response'],
    UpdateMarketProfile['errors'],
    UpdateMarketProfile['body']
  >
) => useMutation((profile: IMarketProfileUpdateData) => updateMarketProfile(profile.id, profile), options)

export const useSetUpMarketProfile = (
  options?: UseMutationOptions<SetUpMarketProfile['response'], SetUpMarketProfile['errors'], SetUpMarketProfile['body']>
) =>
  useMutation(
    ({ id, ...data }: { id: IMarketProfile['id'] } & IMarketProfileSetupData) => setUpMarketProfile(id, data),
    options
  )

export const useGenerateMarketProducts = (
  options?: UseMutationOptions<
    GenerateMarketProducts['response'],
    GenerateMarketProducts['errors'],
    GenerateMarketProducts['body']
  >
) =>
  useMutation(
    ({ profileId, ...data }: { profileId: IMarketProfile['id'] } & GenerateMarketProducts['body']) =>
      generateMarketProducts(profileId, data),
    options
  )

export const useCreateMarketProduct = (
  options?: UseMutationOptions<
    CreateMarketProduct['response'],
    CreateMarketProduct['errors'],
    CreateMarketProduct['body']
  >
) => useMutation((product: INewMarketProduct) => createMarketProduct(product), options)

export const useUpdateMarketProduct = (
  options?: UseMutationOptions<
    UpdateMarketProduct['response'],
    UpdateMarketProduct['errors'],
    UpdateMarketProduct['body']
  >
) =>
  useMutation(
    (product: { id: IMarketProduct['id'] } & INewMarketProduct) => updateMarketProduct(product.id, product),
    options
  )

export const useDeleteMarketProduct = (
  options?: UseMutationOptions<
    DeleteMarketProduct['response'],
    DeleteMarketProduct['errors'],
    DeleteMarketProduct['params']['id']
  >
) => useMutation(id => deleteMarketProduct(id), options)
