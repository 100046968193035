import { useQueryClient } from '@tanstack/react-query'
import { IPosition, SHORT_POSITION_PAGE_INDEX, SHORT_POSITION_PAGE_LIMIT } from 'core'
import { compact, debounce } from 'lodash'
import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { useShortPositions } from '~/hooks'
import { constants, encodeToken, Loader, useStore, View } from '~/lite'

import { PositionNavBar } from '../PositionNavBar'
import { PositionShortContent } from '../PositionShortContent'

export const JobShort: React.FC = () => {
  const queryClient = useQueryClient()
  const [selectedJob, setSelectedJob] = React.useState<IPosition['id']>()
  const [skeletons, setSkeletons] = React.useState(true)
  const resumeImportState = useStore(state => state.resumeImportState)

  const jobContainerRef = React.useRef<HTMLDivElement>(null)
  const { data, isLoading, hasNextPage, fetchNextPage } = useShortPositions(
    SHORT_POSITION_PAGE_INDEX,
    SHORT_POSITION_PAGE_LIMIT
  )

  React.useEffect(() => {
    if (resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success') {
      queryClient.invalidateQueries(['short-positions'])
    }
  }, [queryClient, resumeImportState])

  React.useEffect(() => {
    window.document.body.classList.add('shorts-view')

    return () => {
      window.document.body.classList.remove('shorts-view')
    }
  }, [])

  const positionList = React.useMemo(() => {
    return compact(data?.pages.flatMap(group => group?.data.map(item => item) ?? []) ?? [])
  }, [data])

  const selectedJobIndex = positionList.findIndex(job => job.id === selectedJob) ?? 0

  if (selectedJobIndex > 0 && skeletons) {
    setSkeletons(false)
  }

  const setSelectedJobDebounced = useMemo(() => debounce((jobId: IPosition['id']) => setSelectedJob(jobId), 100), [])

  const renderPositionScrollerItem = () => {
    return (
      <InfiniteScroll
        className="infinite-list"
        hasMore={hasNextPage}
        useWindow={false}
        pageStart={0}
        threshold={800}
        getScrollParent={() => jobContainerRef.current}
        loader={
          <View key="loader" width="100%" justifyContent="center" alignItems="center" paddingY="s">
            <Loader testID="load-more-indicator" style={{ margin: 8 }} inline />
          </View>
        }
        loadMore={handleFetchMore}
      >
        {positionList?.map((position, index) => (
          <View key={`short-${index}`}>
            <PositionNavBar url={position?.id ? `${constants.frontendUrl}/jobs/${encodeToken(position.id)}` : null} />
            <PositionShortContent
              id={`short-${index}`}
              context="Short"
              skeleton={skeletons && index > 0}
              data={position}
              onVisible={setSelectedJobDebounced}
            />
          </View>
        ))}
      </InfiniteScroll>
    )
  }

  const handleFetchMore = () => {
    if (!hasNextPage) {
      return
    }
    fetchNextPage()
  }

  return (
    <Loader isLoading={isLoading}>
      <div className="job-master-detail job-shorts">
        <div className="job-detail" ref={jobContainerRef}>
          {renderPositionScrollerItem()}
        </div>
      </div>
    </Loader>
  )
}

/*
const t = useTranslation()
const history = useHistory()
const listItems = React.useMemo(
  () =>
    positionList.map((item, index) => (
      <JobCard
        key={`short-${index}-list-item`}
        id={`short-${index}-list-item`}
        t={t}
        record={item}
        selected={selectedJob === item.id}
        onPress={() => {
          setSelectedJob(item.id)
          document.getElementById(`short-${index}`)?.scrollIntoView({ behavior: 'smooth' })
        }}
        onTeamPress={username => history.push(`/teams/${encodeToken(username)}/jobs`)}
        listItem
      />
    )),
  [positionList, t, history, selectedJob]
)
const scrollToJobItemDebounced = useMemo(
  () =>
    debounce((index: number) => {
      document.getElementById(`short-${index}-list-item`)?.scrollIntoView({ behavior: 'smooth' })
    }, 500),
  []
)
const setSelectedJobDebounced = useMemo(
  () =>
    debounce((jobId: IPosition['id'], index: number) => {
      setSelectedJob(jobId)
      scrollToJobItemDebounced(index)
    }, 1000),
  []
)
<div className="job-list">{listItems}</div>
*/
