import { deleteInvitation, encodeToken, IInvitation, markTranslated } from 'core'
import React, { useMemo } from 'react'

import { Actions, Icon, IListItemProps, ListItem, Menu, styled } from '~/components'
import { useTranslation } from '~/hooks'

const Wrap = styled(ListItem)`
  background: ${props => props.theme.background};
  background-color: ${props => props.theme.background};
  width: 100%;
  margin-bottom: 2px;
`

interface IInvitationListItemProps {
  invitation: IInvitation
  actions?: Actions
  view: 'Team' | 'User'
  testID?: string
  refetchInvitations?: () => void
  isEditing?: boolean
}

export const InvitationListItem: React.FC<IInvitationListItemProps> = ({
  invitation,
  refetchInvitations,
  isEditing,
  view,
  testID
}) => {
  const t = useTranslation()

  const accessoryLeft = useMemo<IListItemProps['accessoryLeft']>(() => <Icon icon="mail" size={20} />, [])

  const accessoryRight = useMemo<IListItemProps['accessoryRight']>(
    () =>
      isEditing ? (
        <Icon testID={testID} icon="ellipsis-vertical" />
      ) : (
        <Menu
          key={invitation.id}
          items={[
            {
              text: 'Delete',
              onPress: async () => {
                await deleteInvitation(invitation.id)
                refetchInvitations?.()
              },
              testID: invitation.id
            }
          ]}
        >
          <Icon testID={testID} icon="ellipsis-vertical" />
        </Menu>
      ),
    [isEditing, invitation.id, refetchInvitations, testID]
  )

  return (
    <Wrap
      action={{ item: 'TeamInvitation', invitationId: invitation.id, actor: view }}
      {...(view === 'User' && { to: `/teams/${encodeToken(invitation.teamUsername)}` })}
      title={view === 'User' ? invitation.teamName : markTranslated(invitation.email)}
      description={t('Invited by {{name}}', { name: invitation.invitingUser })}
      accessoryLeft={accessoryLeft}
      accessoryRight={accessoryRight}
    />
  )
}
