import type { ClientRoutePath, TranslatedText, UntranslatedText } from 'core'
import React, { useCallback } from 'react'

import { type ITextProps, useHistory, useStore, useTranslation } from '~/lite'

import { IconWrap, TabWrap } from './styles'

interface ITabProps {
  to?: ClientRoutePath | 'goBack'
  href?: ITextProps['href']
  hrefAttrs?: ITextProps['hrefAttrs']
  onPress?: ITextProps['onPress']
  testID?: string
  text: UntranslatedText
  icon?: string
}

export const Tab: React.FC<ITabProps> = ({ to, href, hrefAttrs, onPress: _onPress, testID, text, icon }) => {
  const theme = useStore(state => state.theme)
  const t = useTranslation()
  const history = useHistory()

  const onPress = useCallback<NonNullable<ITextProps['onPress']>>(
    e => {
      _onPress?.(e)

      if (href) {
        window.location.href = href
        return
      }

      if (to) {
        e.preventDefault()
        to === 'goBack' ? history.goBack() : history.push(to)
      }
    },
    [_onPress, href, to, history]
  )

  const props: Partial<Omit<ITextProps, 'text' | 'raw' | 'rawText'>> = { testID, href: to ?? href, hrefAttrs, onPress }

  return (
    <TabWrap
      {...props}
      rawText={
        (
          <>
            {icon && <IconWrap icon={icon} color={theme.primary} />}
            {t(text)}
          </>
        ) as unknown as TranslatedText
      }
    />
  )
}
