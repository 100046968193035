import * as DialogPrimitive from '@radix-ui/react-dialog'
import { TranslatedText, UntranslatedText } from 'core'

import { css, keyframes, styled } from '../styled'
import { breakpoint } from '../util/style'
import { animations } from './animations'
import { Text } from './Text'

const fadeInDialog = keyframes`
  from {
    opacity: 0;
    transform: translate(calc(-50% - 16px), calc(-50% + 10px)) scale(.97);
  }

  to {
    opacity: 1;
    transform: translate(calc(-50% - 16px), -50%) scale(1);
  }
`

const fadeOutDialog = keyframes`
  from {
    opacity: 1;
    transform: translate(calc(-50% - 16px), -50%) scale(1);
  }

  to {
    opacity: 0;
    transform: translate(calc(-50% - 16px), calc(-50% + 10px)) scale(.97);
  }
`

export const dialogStyles = {
  overlay: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    background-color: ${props => props.theme.background}aa;
    backdrop-filter: blur(8px);
    animation-fill-mode: both;

    &[data-state='open'] {
      animation: ${animations.fadeIn} 0.3s ease-in-out;
    }

    &[data-state='closed'] {
      animation: ${animations.fadeOut} 0.3s ease-in-out;
    }
  `,
  content: css`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 60;
    display: grid;
    gap: 16px;
    margin: 0 16px;
    width: calc(100% - 32px);
    transform: translate(calc(-50% - 16px), -50%);
    border-radius: 12px;
    border-width: 1px;
    background: ${props => props.theme.cardBackground};
    padding: 24px;
    box-shadow: ${props =>
      props.theme.dark
        ? 'rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'
        : 'rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'};
    animation-fill-mode: both;

    &[data-state='open'] {
      animation: ${fadeInDialog} 0.3s ease-in-out;
    }

    &[data-state='closed'] {
      animation: ${fadeOutDialog} 0.3s ease-in-out;
    }
  `,
  header: css`
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;

    @media ${breakpoint.md} {
      text-align: left;
    }
  `,
  footer: css`
    margin-top: 8px;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;

    @media ${breakpoint.md} {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
  `
}

const DialogClose = styled(DialogPrimitive.Close)`
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 8px;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ccc;
  }

  &:disabled {
    pointer-events: none;
  }
`

const DialogOverlay = styled(DialogPrimitive.Overlay)`
  ${dialogStyles.overlay}
`

export const DialogContent = styled(DialogPrimitive.Content)<{ $small?: boolean }>`
  ${dialogStyles.content}
  max-width: ${props => (props.$small ? '400px' : '600px')};
`

const DialogHeader = styled.div`
  ${dialogStyles.header}
`

export const DialogFooter = styled.div`
  ${dialogStyles.footer}
`

const DialogTitle = styled(DialogPrimitive.Title)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.02em;
`

const DialogDescription = styled(DialogPrimitive.Description)`
  opacity: 0.8;
`

export interface IDialogProps extends DialogPrimitive.DialogProps {
  trigger: React.ReactNode
  title?: UntranslatedText
  rawTitle?: TranslatedText
  description?: UntranslatedText
  rawDescription?: TranslatedText
  children: React.ReactNode
  footer?: React.ReactNode
  small?: boolean
  disableAutoFocus?: boolean
}

export const Dialog: React.FC<IDialogProps> = ({
  trigger,
  title,
  rawTitle,
  description,
  rawDescription,
  children,
  footer,
  small,
  disableAutoFocus,
  ...props
}) => (
  <DialogPrimitive.Root {...props}>
    <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
    <DialogPrimitive.Portal>
      <DialogOverlay />
      <DialogContent onOpenAutoFocus={disableAutoFocus ? e => e.preventDefault() : undefined} $small={small}>
        <DialogHeader>
          {!!(title || rawTitle) && (
            <DialogTitle>{title ? <Text text={title} /> : rawTitle ? <Text rawText={rawTitle} /> : null}</DialogTitle>
          )}
          {!!(description || rawDescription) && (
            <DialogDescription>
              {description ? <Text text={description} /> : rawDescription ? <Text rawText={rawDescription} /> : null}
            </DialogDescription>
          )}
        </DialogHeader>
        {children}
        {!!footer && <DialogFooter>{footer}</DialogFooter>}
      </DialogContent>
    </DialogPrimitive.Portal>
  </DialogPrimitive.Root>
)
