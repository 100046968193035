import { HtmlRenderer } from '@ambition/ui-html-renderer'
import { ClientRoutePath, IContentNode, INewPosition, IPosition, IPositionPartial, isExternalUrl } from 'core'
import { isString } from 'lodash'
import React, { useMemo } from 'react'

import { H2, Icon, styled, Text, View } from '~/components'
import { useHistory, useStore } from '~/hooks'
import { clientUrl, getLinkHandler, ILinkProps, isInternalUrl, isRelativeUrl, Link, relativeUrlBase } from '~/lite'
import { emptyString, isWeb } from '~/util'

const Wrap = styled(View)``

const Section = styled(View)`
  padding: 8px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.border};
`

const SectionHeader = styled(H2)`
  font-size: 20px;
  padding: 8px 0;
  font-weight: bold;
`

const Paragraph = styled(Text)`
  padding: 8px 0;
`

const List = styled(View)`
  padding: 8px 0;
`

const ListItem = styled(View)`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`

const ListItemIcon = styled(Icon)`
  margin: 5px 8px 0 0;
`

const ListItemText = styled(Text)``

const TextBold = styled(Text)`
  font-weight: bold;
`

interface IPositionDescriptionProps {
  position: IPositionPartial | IPosition | INewPosition
  contentWidth: number
  testID?: string
}

const defaultChildNodes: { [parentTag: string]: string } = {
  ul: 'li'
}

interface INodeProps {
  node: IContentNode
  parent?: Exclude<IContentNode, string>
  linkColor: string
  onLinkPress(href: string): void
}

const Node: React.FC<INodeProps> = ({ node, parent, linkColor, onLinkPress }) => {
  const defaultTextTag = defaultChildNodes[parent?.tag || ''] || 'p'

  if (isString(node)) {
    return (
      <Node
        node={{ tag: defaultTextTag, attributes: {}, text: node }}
        linkColor={linkColor}
        onLinkPress={onLinkPress}
      />
    )
  }

  const children =
    node.children &&
    node.children.map((child, idx) => (
      <Node key={idx} node={child} parent={node} linkColor={linkColor} onLinkPress={onLinkPress} />
    ))

  if (node.tag === 'u' || node.tag.endsWith('u')) {
    return (
      <Node
        node={{ tag: defaultTextTag, attributes: {}, text: node.text }}
        linkColor={linkColor}
        onLinkPress={onLinkPress}
      />
    )
  }

  if (node.tag === 'span' || node.tag.endsWith('span')) {
    return (
      <Node
        node={{ tag: defaultTextTag, attributes: {}, text: node.text }}
        linkColor={linkColor}
        onLinkPress={function (href: string): void {
          throw new Error('Function not implemented.')
        }}
      />
    )
  }

  const text = node.text ?? emptyString

  if (node.tag === 'div' || node.tag.endsWith('div')) {
    return <Paragraph rawText={text}>{children}</Paragraph>
  }

  if (node.tag === 'p' || node.tag.endsWith('p') || node.tag === 'section' || node.tag.endsWith('section')) {
    return <Paragraph rawText={text}>{children}</Paragraph>
  }

  if (node.tag === 'b' || node.tag.endsWith('b') || node.tag === 'strong' || node.tag.endsWith('strong')) {
    return <TextBold rawText={text} />
  }

  if (node.tag === 'br' || node.tag.endsWith('br')) {
    return <Paragraph rawText={emptyString} />
  }

  if (node.tag === 'a' || node.tag.endsWith(' a')) {
    const href = node.attributes?.href

    // don't know how to resolve non-existent or relative links, so change it to text
    if (!href || isRelativeUrl(href)) {
      return (
        <Node
          node={{ tag: defaultTextTag, attributes: {}, text: node.text }}
          linkColor={linkColor}
          onLinkPress={onLinkPress}
        />
      )
    }

    const onPress: NonNullable<ILinkProps['onPress']> = e => {
      e.preventDefault()
      onLinkPress(node.attributes.href)
    }

    if (isInternalUrl(href)) {
      return <Link to={href.replace(clientUrl, '') as ClientRoutePath} onPress={onPress} rawText={text} />
    }

    if (isExternalUrl(href)) {
      return (
        <Link
          href={href}
          hrefAttrs={{ rel: 'noopener noreferrer nofollow', target: '_blank' }}
          onPress={isWeb ? undefined : onPress}
          rawText={text}
        />
      )
    }

    return <Link href={href} onPress={onPress} rawText={text} />
  }

  if (node.tag === 'ul' || node.tag.endsWith('ul')) {
    return (
      <List>
        {node?.text && <ListItemText rawText={node.text} />}
        {children}
      </List>
    )
  }

  if (node.tag === 'li' || node.tag.endsWith('li')) {
    return (
      <ListItem>
        <ListItemIcon icon="ellipse" size={7} />
        <ListItemText rawText={text}>{children}</ListItemText>
      </ListItem>
    )
  }

  console.log(`Failed to parse node: ${JSON.stringify(node)}`)

  return null
}

export const PositionDescription: React.FC<IPositionDescriptionProps> = ({ position, contentWidth, testID }) => {
  const theme = useStore(state => state.theme)
  const history = useHistory()
  const onLinkPress = useMemo<INodeProps['onLinkPress']>(() => getLinkHandler(history), [history])

  if ('sections' in position && position.sections) {
    return (
      <Wrap testID={testID}>
        {position.sections.map((section, idx) => (
          <Section key={idx}>
            {section.sectionType !== 'Unknown' && <SectionHeader text={section.sectionType} />}
            {section.items.map((item, idx) => (
              <Node key={idx} node={item} linkColor={theme.primary} onLinkPress={onLinkPress} />
            ))}
          </Section>
        ))}
      </Wrap>
    )
  }

  if (position.description) {
    return <HtmlRenderer html={position.description} baseUrl={relativeUrlBase} contentWidth={contentWidth} />
  }

  return null
}
