import { isEmpty } from 'lodash'
import urlRegexSafe from 'url-regex-safe'

import { GetPositions, Login, SignUpWithForm, SignUpWithPhone } from './api'
import { INewCollection, INewMarketProduct, INewPosition, INewTeam, IUser } from './models'
import { Errors } from './util/api'

export type Validation<Data> = { success: true; data: Data } | { success: false; errors: Errors<Data> }

export const validateLogin = async (data: Partial<Login['body']>): Promise<Validation<Login['body']>> => {
  const errors: Errors<Login['body']> = {}

  if (!data.email) {
    errors.email = 'This field is required'
  }

  if (!data.password) {
    errors.password = 'This field is required'
  }

  return isEmpty(errors) ? { success: true, data: data as Login['body'] } : { success: false, errors }
}

export const validateSignUpWithForm = async (
  data: Partial<SignUpWithForm['body']>
): Promise<Validation<SignUpWithForm['body']>> => {
  const errors: Errors<SignUpWithForm['body']> = {}

  if (!data.username) {
    errors.username = 'This field is required'
  }

  if (!data.email) {
    errors.email = 'This field is required'
  }

  if (!data.password) {
    errors.password = 'This field is required'
  }

  if (data.username && data.username?.length > 15) {
    errors.username = 'Please shorten this value'
  }

  return isEmpty(errors) ? { success: true, data: data as SignUpWithForm['body'] } : { success: false, errors }
}

export const validateSignUpWithPhone = async (
  data: Partial<SignUpWithPhone['body']>
): Promise<Validation<SignUpWithPhone['body']>> => {
  const errors: Errors<SignUpWithPhone['body']> = {}

  if (!data.username) {
    errors.username = 'This field is required'
  }

  if (!data.firstName) {
    errors.firstName = 'This field is required'
  }

  if (!data.lastName) {
    errors.lastName = 'This field is required'
  }

  if (!data.verificationId) {
    errors.verificationId = 'This field is required'
  }

  if (!data.verificationCode) {
    errors.verificationCode = 'This field is required'
  }

  if (data.username && data.username?.length > 15) {
    errors.username = 'Please shorten this value'
  }

  return isEmpty(errors) ? { success: true, data: data as SignUpWithPhone['body'] } : { success: false, errors }
}

export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const isValidName = (email: string): boolean => {
  const nameRe = /^[a-zA-Z ]+$/
  return nameRe.test(email.toLowerCase())
}

export const isValidUrl = (url: string): boolean => {
  return urlRegexSafe({ exact: true, strict: false }).test(url)
}

export const validateNewTeam = async (data: INewTeam): Promise<Validation<INewTeam>> => {
  const errors: Errors<INewTeam> = {}

  if (!data.name) {
    errors.name = 'This field is required'
  }

  // if (!data.username) {
  //   errors.username = 'This field is required'
  // }

  if (data.website && !isValidUrl(data.website)) {
    errors.website = 'Please enter a valid value'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateNewInvites = async (data: Record<string, any>): Promise<Validation<Record<string, any>>> => {
  const errors: Errors<Record<string, any>> = {}
  const invitationEmails = Object.keys(data)

  invitationEmails.map(email => {
    if (!data[email]) {
      errors[email] = 'This field is required'
    } else if (!emailRegExp.test(data[email])) {
      errors[email] = 'Please enter a valid value'
    }
  })

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const positionSummaryMaxCharacters = 750

export const validateCollectionData = async (data: INewCollection): Promise<Validation<INewCollection>> => {
  const errors: Errors<INewCollection> = {}

  if (!data.name) {
    errors.name = 'This field is required'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateMarketProductData = async (data: INewMarketProduct): Promise<Validation<INewMarketProduct>> => {
  const errors: Errors<INewMarketProduct> = {}

  if (!data.name) {
    errors.name = 'This field is required'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateNewPosition = async (data: INewPosition): Promise<Validation<INewPosition>> => {
  const errors: Errors<INewPosition> = {}

  if (!data.title) {
    errors.title = 'This field is required'
  }

  if (data.summary && data.summary.length > positionSummaryMaxCharacters) {
    errors.summary = 'Please shorten this value'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateGetPositions = async (
  data: GetPositions['params']
): Promise<Validation<GetPositions['params']>> => ({ success: true, data })

export const validateGenerateRecoveryCode = async (data: {
  email: IUser['email']
}): Promise<
  Validation<{
    email: IUser['email']
  }>
> => {
  const errors: Errors<{
    email: IUser['email']
  }> = {}

  if (!data.email) {
    errors.email = 'This field is required'
  }

  if (!emailRegExp.test(data.email)) {
    errors.email = 'Please enter a valid value'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateUseRecoveryCode = async (data: {
  email: IUser['email']
  code: string
}): Promise<
  Validation<{
    email: IUser['email']
    code: string
  }>
> => {
  const errors: Errors<{
    email: IUser['email']
    code: string
  }> = {}

  if (!data.code) {
    errors.code = 'This field is required'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}

export const validateUseResetPassword = async (data: {
  email: IUser['email']
  code: string
  password: string
}): Promise<
  Validation<{
    email: IUser['email']
    code: string
    password: string
  }>
> => {
  const errors: Errors<{
    email: IUser['email']
    code: string
    password: string
  }> = {}

  if (!data.password) {
    errors.password = 'This field is required'
  }

  return isEmpty(errors) ? { success: true, data } : { success: false, errors }
}
