import { TranslatedText, UntranslatedText } from 'core'
import React, { useState } from 'react'

import { useStore, useTranslation } from '../hooks'
import { styled } from '../styled'
import { Button, IButtonProps } from './Button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './Command'
import { Icon } from './Icon'
import { Loader } from './Loader'
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from './Popover'
import { Text } from './Text'
import { View } from './View'

interface IOption {
  label: TranslatedText
  value?: number | string | null
}

type GroupedOption<TOption extends IOption> = {
  label: TranslatedText
  options: TOption[]
}

const Wrap = styled.div``

const RightSlot = styled(View)`
  margin-left: auto;
  padding-left: 16px;

  [data-disabled] & {
    opacity: 0.5;
  }

  & *:last-child {
    display: none;
  }

  [aria-selected='true'] & *:first-child {
    display: none;
  }

  [aria-selected='true'] & *:last-child {
    display: flex;
  }
`

interface IComboboxProps<T extends IOption> {
  options: T[] | GroupedOption<T>[]
  value: T | null
  onToggle?(): void
  onChange(option: T | null): void
  trigger?(props: Pick<IButtonProps, 'onPress'>): React.ReactNode
  inputValue?: string
  onInputChange?(value: string): void
  placeholder?: TranslatedText
  inputPlaceholder?: UntranslatedText
  emptyText?: UntranslatedText
  minimal?: boolean
  shouldFilter?: boolean
  popoverProps?: React.ComponentProps<typeof PopoverContent>
  isLoading?: boolean
}

export function Combobox<TOption extends IOption>({
  trigger,
  options,
  value,
  onToggle,
  onChange,
  inputValue,
  onInputChange,
  placeholder,
  inputPlaceholder,
  emptyText,
  minimal,
  shouldFilter,
  popoverProps,
  isLoading
}: IComboboxProps<TOption>): ReturnType<React.FC<IComboboxProps<TOption>>> {
  const t = useTranslation()
  const theme = useStore(state => state.theme)
  const [isOpen, setIsOpen] = useState(false)

  const renderOption = (item: TOption) => (
    <CommandItem
      key={item.value}
      value={item.value?.toString() ?? ''}
      onSelect={() => {
        onChange(item)
        setIsOpen(false)
      }}
    >
      <Text rawText={item.label} />
      <RightSlot>
        {value?.value === item.value && (
          <>
            <Icon icon="checkmark-outline" color={theme.primary} />
            <Icon icon="checkmark-outline" color="#fff" />
          </>
        )}
      </RightSlot>
    </CommandItem>
  )

  // @todo add a11y props to button: role="combobox" aria-expanded={open} aria-label="Select ..."
  return (
    <Wrap>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          {trigger ? (
            trigger({
              onPress: () => {
                onToggle?.()
                setIsOpen(isOpen => !isOpen)
              }
            })
          ) : (
            <Button
              rawText={value?.label ?? placeholder ?? t('Select')}
              rightIcon="chevron-down-outline"
              iconSize={14}
              onPress={() => {
                onToggle?.()
                setIsOpen(isOpen => !isOpen)
              }}
              {...(minimal && { minimal: true, style: { backgroundColor: 'transparent' }, color: theme.text })}
              skipTracking
            />
          )}
        </PopoverTrigger>
        <PopoverAnchor />
        <PopoverContent {...popoverProps}>
          <Command shouldFilter={shouldFilter}>
            <CommandList>
              <CommandInput
                value={inputValue}
                onValueChange={onInputChange}
                placeholder={inputPlaceholder ?? 'Search'}
              />
              {isLoading ? (
                <View height={64} padding="m">
                  <Loader size={24} />
                </View>
              ) : (
                <>
                  <CommandEmpty text={emptyText ?? 'No results'} />
                  {options.map(option =>
                    'options' in option ? (
                      <CommandGroup key={option.label} heading={option.label}>
                        {option.options.map(renderOption)}
                      </CommandGroup>
                    ) : (
                      renderOption(option)
                    )
                  )}
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </Wrap>
  )
}
