import * as RCheckbox from '@radix-ui/react-checkbox'
import React, { useId } from 'react'

import { styled } from '~/lite'

import { Icon } from '../Icon/Icon'
import { Text } from '../Text/Text'
import { View } from '../View/View'
import type { ICheckboxProps } from './Checkbox.native'

const Wrap = styled(View)`
  flex-direction: row;
  align-items: stretch;
`

const CheckboxRoot = styled(RCheckbox.Root)`
  all: unset;
  width: 25px;
  height: 25px;
  margin: 8px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.inputBackground};
  box-shadow: 0 0 0 1px ${props => props.theme.inputBorderColor};

  &:hover {
    background: ${props => props.theme.primary}66;
    box-shadow: 0 0 0 1px ${props => props.theme.primary};
  }

  &:focus {
    box-shadow: 0 0 0 1px ${props => props.theme.primary};
  }

  &[data-state='checked'] {
    background: ${props => props.theme.primary};
    box-shadow: 0 0 0 1px ${props => props.theme.primary};
  }
`

const Label = styled.label`
  flex: 1;
  padding: 4px;
  display: flex;
  align-items: center;
`

export const Checkbox: React.FC<ICheckboxProps> = ({ labelComponent, label, rawLabel, checked, onChange }) => {
  const id = useId()

  return (
    <Wrap>
      <CheckboxRoot id={id} onCheckedChange={checked => onChange(!!checked)} checked={checked}>
        <RCheckbox.Indicator>
          <Icon icon="checkmark-sharp" color="#ffffff" size={17} />
        </RCheckbox.Indicator>
      </CheckboxRoot>
      <Label htmlFor={id}>
        {labelComponent ?? (rawLabel ? <Text rawText={rawLabel} /> : <Text text={label ?? ''} />)}
      </Label>
    </Wrap>
  )
}
