import React from 'react'

import { useAutopilotContent } from '~/hooks'
import { Button, css, Icon, Loader, styled, Text, View } from '~/lite'

import { AutopilotSteps } from './AutopilotSteps'

const Wrap = styled(View)<{ $dashboard?: boolean }>`
  min-height: ${props => (props.$dashboard ? 'calc(100vh - 60px - 60px)' : 'calc(100vh - 60px)')};
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  background: ${props => (props.theme.dark ? props.theme.background : props.theme.backgroundContrast)};

  ${props =>
    props.$dashboard &&
    css`
      position: absolute;
      top: 60px;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      padding: 16px;
    `}
`

const Inner = styled(View)`
  width: 100%;
  max-width: 600px;
  padding: 32px;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 0 0 1px ${props => props.theme.border};
`

const Header = styled(Text)`
  font-size: 26px;
`

const PerksHeader = styled(Text)`
  font-size: 26px;
`

const Subheader = styled(Text)`
  margin-top: 4px;
  margin-bottom: 16px;
  opacity: 0.8;
  hyphens: none;
`

const Steps = styled(View)`
  margin: 8px 0;
  gap: 16px;
`

const Step = styled(View)`
  flex-direction: row;
`

const StepIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const StepTextWrap = styled(View)`
  flex: 1;
  display: block;
`

const BoldStepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: bold;
`

const StepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: normal;
  opacity: 0.8;
`

const paymentUrl = 'https://buy.stripe.com/fZedSOc4j2PadXy6ou'

export interface IAutopilotProps {
  dashboard?: boolean
}

export const Autopilot: React.FC<IAutopilotProps> = ({ dashboard }) => {
  const { data, isLoading } = useAutopilotContent()

  if (isLoading) {
    return (
      <Wrap $dashboard={dashboard}>
        <Loader />
      </Wrap>
    )
  }

  if (!data) {
    return null
  }

  const { preorder } = data.data

  return (
    <Wrap $dashboard={dashboard}>
      <Inner>
        <View flexDirection="row" alignItems="start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={28}
            height={28}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 8V4H8" />
            <rect width="16" height="12" x="4" y="8" rx="2" />
            <path d="M2 14h2" />
            <path d="M20 14h2" />
            <path d="M15 13v2" />
            <path d="M9 13v2" />
          </svg>
          <Header marginLeft={8} fontSize={28} text="Autopilot" />
        </View>
        <Subheader rawText={preorder.subheader} />
        <AutopilotSteps />
        <View marginTop={32} flexDirection="row" alignItems="center">
          <Text fontSize={26} rawText={'🎁' as any} />
          <PerksHeader marginLeft={8} marginBottom={8} rawText={preorder.perksHeader} />
        </View>

        <Subheader rawText={preorder.perksSubheader} />
        <Steps>
          {preorder.perks.map((perk, idx) => (
            <Step key={idx}>
              <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
              <StepTextWrap>
                <BoldStepText rawText={perk.header} /> {!!perk.subheader && <StepText rawText={perk.subheader} />}
              </StepTextWrap>
            </Step>
          ))}
        </Steps>
        <View flexDirection="row" marginTop={32} justifyContent="center">
          <Button
            action="PreorderAutopilot"
            rawText={preorder.cta}
            onPress={() => window.open(paymentUrl, '_blank')}
            primary
            large
          />
        </View>
      </Inner>
    </Wrap>
  )
}
