import { lighten } from 'polished'

import { Button, css, Flex, FlexHorizontal, FlexVertical, styled, Text, View } from '~/components'
import { getWindowWidth, isWeb } from '~/util'

export const LargeName = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  overflow: hidden;
  margin-left: 5px;

  ${!isWeb &&
  css`
    max-width: 75%;
  `}

  ${isWeb &&
  css`
    max-width: 100%;
    font-size: 0.9em;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`

export const LargeIcon = styled(View)`
  position: relative;
  padding: 10px;
  border-radius: 15px;
  background-color: ${lighten(0.1, '#30404d')};
  height: 55px;
  min-width: 55px;
  max-width: 55px;
`

export const DialogHeader = styled(FlexHorizontal)`
  position: relative;
  align-items: center;
  justify-content: center;
`

export const DialogBody = styled(FlexVertical)`
  padding: 20px 0 10px;
  ${!isWeb &&
  css`
    min-width: ${getWindowWidth() - 90}px;
  `}
`

export const DeleteDialogBody = styled(Flex)`
  padding: 12px 0;
  align-items: center;
  ${!isWeb &&
  css`
    width: ${getWindowWidth() - 120}px;
  `}
`

export const DeleteText = styled(Text)`
  padding: 10px;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
`

export const CloseButton = styled(Button)`
  position: absolute;
  background-color: transparent;
  left: -18px;
  top: -15px;
  border: none;
`

export const DeleteButton = styled(Button)`
  position: absolute;
  right: -25px;
  top: -10px;
  background-color: transparent;
  border: none;
`
