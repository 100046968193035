import { IMarketProfile } from 'core'
import { chunk } from 'lodash'
import React, { useEffect } from 'react'

import { breakpoint, styled, Text, useBreakpoints, View } from '~/lite'

import { SectionHeader, SectionSubheader } from './styles'

const Wrap = styled(View)`
  width: 100%;
  margin-top: 16px;
  background: ${props => (props.theme.dark ? 'transparent' : '#f6f9fc00')};

  @media ${breakpoint.lg} {
    margin-top: 32px;
    padding: 32px 0;
  }
`

const DeliverablesRow = styled(View)`
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 4px 0;
  max-width: 100%;

  &:nth-child(n + 8) {
    display: none;
  }

  @media ${breakpoint.md} {
    &:nth-child(n + 8) {
      display: flex;
    }
  }
`

const Gutter = styled(View)`
  flex: 1;
  background: ${props => (props.theme.dark ? `${props.theme.background}88` : '#e3edf688')};
`

const DeliverablesLeftGutter = styled(Gutter)`
  border-radius: 0 8px 8px 0;
`

const DeliverablesRightGutter = styled(Gutter)`
  border-radius: 8px 0 0 8px;
`

const Deliverable = styled(Text)<{ $active: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  text-wrap: nowrap;
  background: ${props =>
    props.$active ? `${props.theme.primary}aa` : props.theme.dark ? `${props.theme.background}88` : '#e3edf688'};
  border: 2px solid
    ${props => (props.$active ? 'transparent' : props.theme.dark ? `${props.theme.background}88` : '#e3edf688')};
  color: ${props => (props.$active ? '#fff' : props.theme.text)};
  will-change: background-color, border-color, color;
  transition: background-color 1s ease, border-color 1s ease, color 0.2s ease;
  white-space: nowrap;
`

interface IDeliverablesProps {
  profile: IMarketProfile
}

export const Deliverables: React.FC<IDeliverablesProps> = ({ profile }) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(0)

  const { isPhone } = useBreakpoints()

  // cycle through items setting them to be the activeIndex
  useEffect(() => {
    if (!profile.metadata.deliverables?.items.length) {
      return
    }

    const interval = setInterval(() => {
      setActiveIndex(prev => {
        if (prev === null) {
          return 0
        }

        if (prev === (profile.metadata.deliverables?.items ?? []).length - 1) {
          return 0
        }

        return prev + 1
      })
    }, 3000)

    return () => clearInterval(interval)
  }, [profile.metadata.deliverables?.items])

  if (!profile.metadata.deliverables) {
    return null
  }

  const itemsPerRow = isPhone ? 2 : 4
  const { header, subheader, items } = profile.metadata.deliverables

  return (
    <Wrap id="deliverables">
      {!!header && <SectionHeader rawText={header} />}
      {!!subheader && <SectionSubheader rawText={subheader} />}
      <View>
        {chunk(items, itemsPerRow).map((group, rowIndex) => (
          <DeliverablesRow key={rowIndex}>
            <DeliverablesLeftGutter />
            {group.map((deliverable, colIndex) => (
              <Deliverable
                key={colIndex}
                rawText={deliverable.name}
                $active={activeIndex === rowIndex * itemsPerRow + colIndex}
              />
            ))}
            <DeliverablesRightGutter />
          </DeliverablesRow>
        ))}
      </View>
    </Wrap>
  )
}
