import React from 'react'

import { styled, View, ViewProps } from '~/lite'

const ArrowWrap = styled(View)<{ $rotate: number; $mirrorX: boolean; $mirrorY: boolean; $scale: number }>`
  display: inline-block;
  pointer-events: none;
  transform: rotate(${props => props.$rotate}deg) ${props => (props.$mirrorX ? 'scaleX(-1)' : '')}
    ${props => (props.$mirrorY ? 'scaleY(-1)' : '')} ${props => (props.$scale ? `scale(${props.$scale})` : '')};
  transform-origin: 50% 50%;
`

type IArrowProps = ViewProps & {
  rotate?: number
  mirrorX?: boolean
  mirrorY?: boolean
  scale?: number
  variant: 'straight' | 'curved' | 'curvy'
}

export const Arrow: React.FC<IArrowProps> = ({ variant, rotate, mirrorX, mirrorY, scale, ...props }) => {
  return (
    <ArrowWrap {...props} $rotate={rotate ?? 0} $mirrorX={!!mirrorX} $mirrorY={!!mirrorY} $scale={scale ?? 1}>
      {variant === 'straight' && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" width={50} height={50}>
          <g
            strokeWidth="17"
            stroke="#6360ff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="matrix(0.4694715627858904,-0.8829475928589271,0.8829475928589271,0.4694715627858904,-143.96766225792703,565.3904120292146)"
          >
            <path d="M274.5 274.5Q448.5 374.5 525.5 525.5 " markerEnd="url(#SvgjsMarker2374)"></path>
          </g>
          <defs>
            <marker
              markerWidth="10"
              markerHeight="10"
              refX="5"
              refY="5"
              viewBox="0 0 10 10"
              orient="auto"
              id="SvgjsMarker2374"
            >
              <polyline
                points="0,5 5,2.5 0,0"
                fill="none"
                strokeWidth="1.6666666666666667"
                stroke="#6360ff"
                strokeLinecap="round"
                transform="matrix(1,0,0,1,1.6666666666666667,2.5)"
                strokeLinejoin="round"
              ></polyline>
            </marker>
          </defs>
        </svg>
      )}
      {variant === 'curved' && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" width={50} height={50}>
          <g
            strokeWidth="17"
            stroke="#6360ff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-2.999999999999943,800)"
          >
            <path
              d="M259.0931854248047 331.96351623535156Q634.0931854248047 11.963516235351562 510.0931854248047 582.9635162353516 "
              markerEnd="url(#SvgjsMarker3254)"
            ></path>
          </g>
          <defs>
            <marker
              markerWidth="10"
              markerHeight="10"
              refX="5"
              refY="5"
              viewBox="0 0 10 10"
              orient="auto"
              id="SvgjsMarker3254"
            >
              <polyline
                points="0,5 5,2.5 0,0"
                fill="none"
                strokeWidth="1.6666666666666667"
                stroke="#6360ff"
                strokeLinecap="round"
                transform="matrix(1,0,0,1,1.6666666666666667,2.5)"
                strokeLinejoin="round"
              ></polyline>
            </marker>
          </defs>
        </svg>
      )}
      {variant === 'curvy' && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" width={15} height={15}>
          <g strokeWidth="17" stroke="#6360ff" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path
              d="M216.34559631347656 274.5Q790.3455963134766 374.5 467.34559631347656 525.5 "
              markerEnd="url(#SvgjsMarker1362)"
            ></path>
          </g>
          <defs>
            <marker
              markerWidth="10"
              markerHeight="10"
              refX="5"
              refY="5"
              viewBox="0 0 10 10"
              orient="auto"
              id="SvgjsMarker1362"
            >
              <polyline
                points="0,5 5,2.5 0,0"
                fill="none"
                strokeWidth="1.6666666666666667"
                stroke="#6360ff"
                strokeLinecap="round"
                transform="matrix(1,0,0,1,1.6666666666666667,2.5)"
                strokeLinejoin="round"
              ></polyline>
            </marker>
          </defs>
        </svg>
      )}
    </ArrowWrap>
  )
}
