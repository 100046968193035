import React, { forwardRef, useState } from 'react'

import { IImageProps, Image, styled, View, ViewProps } from '~/lite'

import { IScrollViewProps, ScrollView } from '../ScrollView/ScrollView'
import type { IAnimatedViewProps } from './Animated.native'

export type { IAnimatedViewProps }

const ViewScaledOnHover = styled(View)`
  transition: transform 100ms ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
`

// @todo implement animations
export const AnimatedView: React.FC<IAnimatedViewProps> = ({
  animation = 'FadeInOutDown',
  duration,
  children,
  ...rest
}) => {
  if (animation === 'FadeIn') {
    return <View {...rest}>{children}</View>
  }

  if (animation === 'FadeInOutDown') {
    return <View {...rest}>{children}</View>
  }

  if (animation === 'ScaleOnHover') {
    return <ViewScaledOnHover {...rest}>{children}</ViewScaledOnHover>
  }

  return null
}

const ReactAnimatedImage: React.FC<Omit<IImageProps, 'source'> & { source: { uri: string } }> = ({
  source,
  ...props
}) => <Image source={source.uri} {...props} />

const ReactAnimatedScrollView = forwardRef<HTMLDivElement, IScrollViewProps>((props, ref) => (
  <ScrollView ref={ref} {...props} />
))

const ReactAnimatedView: React.FC<ViewProps> = props => <View {...props} />

export const Animated = {
  Image: ReactAnimatedImage,
  View: ReactAnimatedView,
  ScrollView: ReactAnimatedScrollView
}

type ExtrapolateType = 'extend' | 'identity' | 'clamp'

type InterpolationConfigType = {
  inputRange: number[]
  outputRange: number[]
  easing?: ((input: number) => number) | undefined
  extrapolate?: ExtrapolateType | undefined
  extrapolateLeft?: ExtrapolateType | undefined
  extrapolateRight?: ExtrapolateType | undefined
}

export type AnimatedValue = ReturnType<typeof createAnimatedValue>

const createAnimatedValue = (initialValue: number) => {
  let value = initialValue

  return {
    getValue: () => value,
    setValue: (newValue: number) => (value = newValue),
    interpolate: (config: InterpolationConfigType): number => {
      //inputRange: [-HEADER_HEIGHT, 0],
      //outputRange: [2.4, 1],
      //extrapolateRight: 'clamp'

      const {
        inputRange: [inStart, inEnd],
        outputRange: [outStart, outEnd]
      } = config

      const outValue = outStart + (value - inStart) * ((outEnd - outStart) / (inEnd - inStart))

      return outValue

      /*
      const headerBarOpacity = scrollY.interpolate({
        inputRange: [1, headerOffset, headerOffset + 10],
        outputRange: [1, 1, 1],
        extrapolate: 'clamp'
      })
      return this*/
    }
  }
}

export const useAnimatedScrollListener = (
  scrollListener?: (event: any) => void
): [AnimatedValue, (value: any) => void] => {
  const [scrollY] = useState(createAnimatedValue(0))

  const setScrollY = (value: any) => {
    if (typeof value === 'number') {
      scrollY.setValue(value)
    }
  }

  return [scrollY, setScrollY]
}
