import { IApplication, IApplicationFormWithAnswers, IApplicationStatus, IPosition, ITeam } from '../models'
import { Endpoint, get, post, put } from '../util/api'

export type CreateApplication = Endpoint<
  Record<string, never>,
  { success: true } | { success: true; action: 'apply:external'; applicationUrl: string | null },
  { positionId: IPosition['id']; formData?: IApplicationFormWithAnswers }
>

export type GetApplicationByTeamUsername = Endpoint<
  { teamUsername: ITeam['username'] },
  { success: true; applications: IApplication[] }
>

export type GetApplicationByTeam = Endpoint<{ teamId: ITeam['id'] }, { success: true; applications: IApplication[] }>

export type GetApplicationsByUser = Endpoint<
  Record<string, never>,
  { success: true; applications: IApplication[] | null }
>

export type GetApplicationsStatuses = Endpoint<Record<string, never>, { success: true; statuses: IApplicationStatus[] }>

export type GetTeamApplicationsStatuses = Endpoint<
  Record<string, never>,
  { success: true; statuses: IApplicationStatus[] | null }
>

export type UpdateTeamApplicationsStatuses = Endpoint<
  Record<string, never>,
  { success: true; statuses: IApplicationStatus[] },
  { teamId: ITeam['id']; statuses: any[] }
>

export type CreateApplicationsStatus = Endpoint<
  Record<string, never>,
  { success: true; status: IApplicationStatus },
  { teamId: ITeam['id']; name: IApplicationStatus['name'] }
>

export type UpdateApplicationsStatus = Endpoint<
  Record<string, never>,
  { success: true; status: IApplicationStatus },
  {
    teamId: ITeam['id']
    statusId: IApplicationStatus['id']
    name: IApplicationStatus['name']
  }
>
export type UpdateApplicationStatuses = Endpoint<
  Record<string, never>,
  { success: true; statuses: IApplicationStatus[] },
  {
    teamId: ITeam['id']
    statuses: Array<Partial<IApplicationStatus>>
  }
>

export type UpdateApplication = Endpoint<
  Record<string, never>,
  { success: true },
  { application: Pick<IApplication, 'id'> & Partial<Exclude<IApplication, 'id'>> }
>

export const createApplication = (positionId: IPosition['id'], formData?: IApplicationFormWithAnswers) =>
  post<CreateApplication>('/applications', { positionId, formData })

export const getApplicationsByUser = () => get<GetApplicationsByUser>(`/applications/user`)

export const getApplicationsByTeam = (teamId: ITeam['id']) => get<GetApplicationByTeam>(`/applications/teams/${teamId}`)

export const getApplicationsByTeamUsername = (teamUsername: ITeam['username']) =>
  get<GetApplicationByTeamUsername>(`/applications/team/username/${teamUsername}`)

export const getApplicationsStatuses = () => get<GetApplicationsStatuses>(`/applications/statuses`)

export const getTeamApplicationsStatuses = (teamId: ITeam['id']) =>
  get<GetTeamApplicationsStatuses>(`/applications/statuses/${teamId}`)

export const updateTeamApplicationsStatuses = (teamId: ITeam['id'], statuses: any[]) =>
  put<UpdateTeamApplicationsStatuses>(`/applications/statuses`, { statuses, teamId })

export const updateApplication = (application: UpdateApplication['body']['application']) =>
  post<UpdateApplication>(`/applications/${application.id}`, { application })
