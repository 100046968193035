import React, { useState } from 'react'

import { useStore } from '~/lite'

import { Form } from './Form'
import { ResumeAnalysis } from './ResumeAnalysis'
import { MagicLinkContainer } from './styles'
import { IMagicLinkProps, MagicLinkCard } from './types'

export const MagicLink: React.FC<IMagicLinkProps> = ({ variant, viewProps, ...props }) => {
  const isAuthenticated = useStore(state => state.isAuthenticated)
  const isLoadingUser = useStore(state => state.isLoadingUser)
  const user = useStore(state => state.user)
  const resumeImportState = useStore(state => state.resumeImportState)
  const subscriptions = useStore(state => state.subscriptions)
  const [analysisIntervalId, setAnalysisIntervalId] = useState<ReturnType<typeof setInterval>>()
  const [analysisProgress, setAnalysisProgress] = useState<number>()
  const [confirmationReady, setConfirmationReady] = useState(false)

  const hasDailyEmailSubscription = !!subscriptions?.some(subscription => subscription.type === 'DailyEmail')
  const hasConfirmedEmail = !!user?.emailConfirmedAt
  const hasImportedResume = resumeImportState === 'Success'

  const startAnalysisProgress = () => {
    if (analysisIntervalId) {
      return
    }

    const intervalId = setInterval(() => {
      setAnalysisProgress(progress => {
        const increment = Math.min(1.5, (100 - (progress ?? 0)) / 20) * Math.random()
        return typeof progress === 'undefined' ? 0 : Math.min(progress + increment, 99)
      })
    }, 1000)

    setAnalysisIntervalId(intervalId)
  }

  if (isAuthenticated && (isLoadingUser || hasImportedResume)) {
    return null
  }

  if (variant === 'Application') {
    return (
      <MagicLinkContainer {...viewProps} $horizontal={props.horizontal}>
        <Form {...props} visible={true} />
      </MagicLinkContainer>
    )
  }

  if (variant === 'ResumeAnalysis') {
    // "Never miss a job opening"
    // "Get a 60 second daily email with our best new jobs"
    // "Get notified when a job is posted that matches your profile"
    const activeCard: MagicLinkCard =
      resumeImportState === 'Pending' || resumeImportState === 'Processing' || typeof analysisProgress === 'number'
        ? 'ResumeAnalysis'
        : 'Form'

    if (typeof analysisProgress === 'undefined' && resumeImportState === 'Processing') {
      startAnalysisProgress()
    }

    return (
      <MagicLinkContainer {...viewProps} $horizontal={props.horizontal}>
        <Form
          {...props}
          visible={activeCard === 'Form'}
          header="Save time with AI"
          subheader="Build a job hunting dashboard using your resume or CV. It's free and takes 30 seconds."
          onSuccess={() => startAnalysisProgress()}
        />
        {activeCard === 'ResumeAnalysis' && (
          <ResumeAnalysis
            {...props}
            visible={activeCard === 'ResumeAnalysis'}
            progress={Math.floor(analysisProgress ?? 0)}
          />
        )}
      </MagicLinkContainer>
    )
  }

  /*
  let activeCard: MagicLinkCard =
    !hasImportedResume && variant !== 'Application'
      ? typeof analysisProgress === 'number'
        ? 'ResumeAnalysis'
        : 'ResumeUpload'
      : 'Form'

  if (
    hasDailyEmailSubscription &&
    !hasConfirmedEmail &&
    !props.skipConfirmation &&
    confirmationReady &&
    variant !== 'Application'
  ) {
    activeCard = 'Confirmation'
  }

  {(emailResponse || activeCard === 'Confirmation') && (
    <Confirmation
      {...props}
      visible={activeCard === 'Confirmation'}
      emailResponse={emailResponse}
      setConfirmationReady={setConfirmationReady}
    />
  )}
  */

  return null
}
