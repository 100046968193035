import React, { useEffect } from 'react'

type ClickOutsideHandler = (event: MouseEvent | TouchEvent) => void

export function useOnClickOutside<T>(ref: React.MutableRefObject<T>, handler: ClickOutsideHandler) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || (ref.current as unknown as Element).contains(event.target as Node)) {
        return
      }
      handler(event)
    }

    document.getElementById('root')?.addEventListener('mousedown', listener, { passive: true })
    document.getElementById('root')?.addEventListener('touchstart', listener, { passive: true })

    return () => {
      document.getElementById('root')?.removeEventListener('mousedown', listener)
      document.getElementById('root')?.removeEventListener('touchstart', listener)
    }
  }, [handler, ref])
}
