import { AppExtensionMessage } from 'core'

if (typeof window !== 'undefined') {
  window.addEventListener('message', (event: MessageEvent<AppExtensionMessage>) => {
    if (event.data.type === 'a:extension-ready') {
      // console.log({ extension: event.data.version })
    }
  })
}

export const sendMessageToExtension = (message: AppExtensionMessage) => {
  if (typeof window === 'undefined') {
    return
  }

  let isWidget = false

  try {
    isWidget = window.self !== window.top
  } catch (e) {
    isWidget = true
  }

  if (isWidget) {
    window.parent.postMessage(message, '*')
  }
}
