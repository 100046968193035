import { useMutation } from '@tanstack/react-query'
import { createRecentSearches, deleteRecentSearch, IRecentSearch, ISearchResult } from 'core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  useBottomTabBarHeight,
  useDebounce,
  useGlobalSearch,
  useHistory,
  useRecentSearches,
  useStore,
  useTranslation
} from '~/hooks'
import { styled, useLayoutEffectOnNative } from '~/lite'
import { getWindowHeight, hideKeyboard, isWeb } from '~/util'

import { Avatar } from '../Avatar/Avatar'
import { Icon } from '../Icon/Icon'
import { Input } from '../Input/Input'
import { List } from '../List/List'
import { ListItem } from '../ListItem/ListItem'
import { Loader } from '../Loader/Loader'
import { Modal } from '../Modal/Modal'
import { ScrollView } from '../ScrollView/ScrollView'
import { Svg } from '../Svg/Svg'
import { Text } from '../Text/Text'
import { View } from '../View/View'

const DummyInput = styled(Text)`
  background-color: ${props => props.theme.inputBackground};
  border-color: ${props => props.theme.inputBorderColor};
  color: ${props => props.theme.inputText};
  font-size: 15px;
  font-weight: normal;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 4px;
  border-width: 1px;
  min-height: 40px;
  padding: 7px 8px;
  flex-direction: row;
  width: 220px;
  line-height: 24px;
  height: 40px;
  margin-top: 5px;
`

const DialogBody = styled(View)`
  padding: 0;
  height: 100%;
`

const SearchIcon = styled(View)`
  height: ${getWindowHeight() / 1.5}px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const ModalTitle = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
`

const NotDataText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.inputPlaceholder};
`

const RecentSearchTitle = styled(Text)`
  margin: 5px 10px;
  font-weight: 600;
  font-size: 18px;
`

export interface IOmniSearchProps {
  modalMode?: boolean
}

export const OmniSearch: React.FC<IOmniSearchProps> = ({ modalMode = true }) => {
  const t = useTranslation()
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedSearchId, setSelectedSearchId] = useState<string | null>(null)
  const debouncedQuery = useDebounce(query, 600)
  const history = useHistory()
  const theme = useStore(state => state.theme)
  const { data: recentSearches, refetch: refetchRecentSearches } = useRecentSearches()
  const { data, isFetching } = useGlobalSearch(debouncedQuery, query)
  const tabBarHeight = useBottomTabBarHeight()
  const results = useMemo(
    () => (data ? [...data.occupations, ...data.skills, ...data.teams, ...data.locations] : null),
    [data]
  )

  const { mutateAsync: handleDeleteRecentSearch, isLoading: isDeleting } = useMutation(
    async (id: IRecentSearch['id']) => (await deleteRecentSearch(id)).success
  )

  useEffect(
    () =>
      history.addListener('focus', () => {
        refetchRecentSearches()
      }),
    [history, refetchRecentSearches]
  )

  useEffect(() => {
    if (!isDeleting) {
      refetchRecentSearches()
      setSelectedSearchId(null)
    }
  }, [isDeleting, refetchRecentSearches])

  const openSearchModal = useCallback(() => setIsOpen(true), [setIsOpen])

  const onItemPress = useCallback(
    (item: ISearchResult) => {
      createRecentSearches(item)
      item.url && history.push(item.url)
      setIsOpen(false)
    },
    [setIsOpen, history]
  )

  useLayoutEffectOnNative(() => {
    history.setOptions({
      headerShown: true,
      headerSearchBarOptions: {
        onChangeText: event => setQuery(event.nativeEvent.text),
        placeholder: t('Companies, places, technologies'),
        autoCapitalize: 'none',
        barTintColor: theme.inputBackground,
        textColor: theme.inputText,
        hideWhenScrolling: false,
        onSearchButtonPress: e => {
          e.stopPropagation()
          hideKeyboard()
        }
      }
    })
  }, [history, theme, t])

  const renderIcon = (type: string, name: string, icon: string | null) => {
    switch (type) {
      case 'Team':
        return <Avatar recordKey={name} size="small" />

      case 'Skill':
        if (!icon) {
          return <Icon icon="ios-search-outline" />
        }
        return <Svg uri={`https://ambitioncdn.com/skill-icons/${icon}`} color="#fff" width={20} height={20} />

      default:
        return <Icon icon="ios-search-outline" />
    }
  }

  const handleDeleteSearch = useCallback(
    (id: IRecentSearch['id']) => {
      setSelectedSearchId(id)
      handleDeleteRecentSearch(id)
    },
    [setSelectedSearchId, handleDeleteRecentSearch]
  )

  const renderRecentSearch = useCallback(
    (search: IRecentSearch) => (
      <ListItem
        action={{
          item: 'RecentSearch',
          recentSearchId: search.id,
          resultType: search.search.type,
          resultName: search.search.name,
          resultUrl: search.search.url
        }}
        onPress={() => search.search.url && history.push(search.search.url)}
        title={search.search.name}
        accessoryLeft={() =>
          search.search.type && search.search.icon
            ? renderIcon(search.search.type, search.search.name, search.search.icon)
            : null
        }
        accessoryRight={() =>
          isDeleting && selectedSearchId === search.id ? (
            <Loader size="small" inline />
          ) : (
            <Icon icon="close" onPress={() => handleDeleteSearch(search.id)} />
          )
        }
      />
    ),
    [history, isDeleting, selectedSearchId, handleDeleteSearch]
  )

  const renderSearchResult = useCallback(
    (item: ISearchResult) => (
      <ListItem
        action={{
          item: 'SearchResult',
          query,
          resultType: item.type,
          resultName: item.name,
          resultUrl: item.url
        }}
        onPress={() => onItemPress(item)}
        title={item.name}
        accessoryLeft={() => (item.type && item.icon ? renderIcon(item.type, item.name, item.icon) : null)}
      />
    ),
    [query, onItemPress]
  )

  const showRecentSearches = !(query && results) && !!recentSearches && recentSearches.length > 0
  const showPlaceholder = !(query && results) && !(recentSearches && recentSearches.length > 0)
  const showResults = !!(query && results && results.length > 0)
  const showNoResults = !!(query && results && results.length === 0)

  const body = useMemo(
    () => (
      <DialogBody>
        <View>
          {modalMode && <ModalTitle text="Search" />}
          {isWeb && (
            <Input
              autoFocus={true}
              value={query}
              placeholder="Search"
              onChange={text => setQuery(text)}
              accessoryLeft={isFetching ? <Loader /> : <Icon icon="search-outline" />}
            />
          )}
        </View>
        {/* {showRecentSearches && (
          <List<IRecentSearch>
            testID="search-result-list"
            data={recentSearches}
            isLoading={isFetching}
            renderItem={({ item }) => renderRecentSearch(item)}
          />
        )} */}
        {showPlaceholder && (
          <SearchIcon>
            <Icon icon="ios-search-outline" size={100} />
          </SearchIcon>
        )}
        {!showRecentSearches && !showPlaceholder && (
          <List<ISearchResult>
            testID="search-result-list"
            data={query ? results : undefined}
            isLoading={isFetching}
            renderItem={({ item }) => renderSearchResult(item)}
          />
        )}
      </DialogBody>
    ),
    [isFetching, modalMode, query, results, renderSearchResult, showRecentSearches, showPlaceholder]
  )

  if (isWeb) {
    return modalMode ? (
      <>
        <DummyInput rawText={t('search...')} onPress={openSearchModal} />
        <Modal testID="search-modal" padding={0} isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnOverlayPress>
          {body}
        </Modal>
      </>
    ) : (
      body
    )
  }

  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      keyboardDismissMode="on-drag"
      style={{ marginBottom: tabBarHeight }}
    >
      {showRecentSearches && (
        <>
          <RecentSearchTitle text="Recent searches" />
          <View>{recentSearches?.map(renderRecentSearch)}</View>
        </>
      )}
      {showPlaceholder && (
        <SearchIcon>
          <Icon icon="ios-search-outline" size={100} />
        </SearchIcon>
      )}
      {showResults && results.map(renderSearchResult)}
      {showNoResults && (
        <SearchIcon>
          <Icon icon="ios-search-outline" size={100} />
          <NotDataText text="No results found" />
        </SearchIcon>
      )}
    </ScrollView>
  )
}
