import { escapeRegExp, flatten, memoize, startCase } from 'lodash'

import { CategorySlug, DatePosted, ILocation, ISkill, ITeam, LandingPageSlug, PositionFilterFormModel } from '../models'
import { getUnverifiedCountryCode } from './geo'
import {
  defaultLocale,
  SupportedLatinLocaleCode,
  SupportedLocaleCode,
  SupportedNonLatinLocaleCode,
  TranslatedText
} from './i18n'

interface ISkillMatch {
  start: number
  end: number
  text: string
  slug: ISkill['slug']
}

const neighboringChars = `[${[',', '\\s+', '\\<', '\\>', '\\(', '\\)', '/', '\\.'].join('|')}]`

export const getSkillQueries = memoize((skills: Pick<ISkill, 'slug' | 'name'>[]): [RegExp, ISkill['slug'], string][] =>
  flatten(
    skills.map(skill =>
      (skill.name.includes(': ') ? skill.name.split(': ')[1] : skill.name)
        .split('|')
        .map<[RegExp, ISkill['slug'], string]>((name: string) => [
          new RegExp(`(${neighboringChars})(${escapeRegExp(name.trim().toLowerCase())})(${neighboringChars})`, 'gi'),
          skill.slug,
          name.trim().toLowerCase()
        ])
    )
  ).sort((q1, q2) => (q1[2].length === q2[2].length ? q1[2].localeCompare(q2[2]) : q2[2].length - q1[2].length))
)

export const addLinksToSkills = (text: string, skills: ISkill[], getUrl: (match: ISkillMatch) => string): string => {
  const skillQueries = getSkillQueries(skills)

  let matches: ISkillMatch[] = []
  let lastMatch: ISkillMatch | null

  skillQueries.forEach(([skillRe, slug]) => {
    for (let match = skillRe.exec(text); match !== null; match = skillRe.exec(text)) {
      const start = match.index + match[1].length
      const end = start + match[2].length
      matches.push({ start, end, text: match[2], slug })
    }
  })

  matches = matches
    .sort((m1, m2) => (m1.start === m2.start ? m2.end - m1.end : m1.start - m2.start))
    .filter(match => {
      if (
        lastMatch &&
        ((match.start >= lastMatch.start && match.start <= lastMatch.end) ||
          (match.end >= lastMatch.start && match.end <= lastMatch.end))
      ) {
        return false
      }

      lastMatch = match
      return true
    })

  return matches
    .reduce(
      (prevParts, match, i) => [
        ...prevParts,
        text.slice(i === 0 ? 0 : matches[i - 1].end, match.start),
        `<a href="${getUrl(match)}">`,
        match.text,
        '</a>',
        ...(i + 1 === matches.length ? [text.slice(match.end)] : [])
      ],
      matches.length > 0 ? [] : [text]
    )
    .join('')
}

export const parsePositionFilterQueryParams = (search: string): PositionFilterFormModel => {
  const params = new URLSearchParams(search)
  const datePostedString = params.get('datePosted') || ''
  const locationString = params.get('locationId') || ''
  const salaryEstimateString = params.get('salaryEstimate')
  const salaryFrequency = params.get('salaryFrequency')
  const companyString = params.get('company')
  const distanceRadiusString = params.get('distanceRadius')
  const subTitleQuery = params.get('subTitleQuery')

  const salaryEstimate = salaryEstimateString
    ? salaryEstimateString.split(',').map(item => {
        return parseInt(item)
      })
    : null

  const company = companyString ? (companyString.split(',') as ITeam['username'][]) : null

  return {
    datePosted: datePostedString as DatePosted,
    locationId: locationString as TranslatedText,
    salaryEstimate,
    salaryFrequency: salaryFrequency as PositionFilterFormModel['salaryFrequency'],
    company,
    distanceRadius: distanceRadiusString ? parseInt(distanceRadiusString, 10) : 0,
    subTitleQuery
  }
}

// need to set up 301 redirects if we ever change these
// also, run testParsers to make sure they're still valid
const landingPageSlugs: { [locale in SupportedLatinLocaleCode]: [string, string, string] } & {
  [locale in SupportedNonLatinLocaleCode]: null
} = {
  'af-ZA': ['{{category}}-werk', 'werk-in-{{location}}', '{{category}}-werk-in-{{location}}'],
  'ar-SA': null,
  'ca-ES': ['feines-{{category}}', 'feines-a-{{location}}', 'feines-{{category}}-a-{{location}}'],
  'cs-CZ': ['{{category}}-prace', 'prace-v-{{location}}', '{{category}}-prace-v-{{location}}'],
  'da-DK': ['{{category}}-job', 'job-i-{{location}}', '{{category}}-job-i-{{location}}'],
  'de-DE': [
    '{{category}}-arbeitsplatze',
    'arbeitsplatze-im-{{location}}',
    '{{category}}-arbeitsplatze-im-{{location}}'
  ],
  'el-GR': null,
  'en-US': ['{{category}}-jobs', 'jobs-in-{{location}}', '{{category}}-jobs-in-{{location}}'],
  'es-419': ['trabajos-{{category}}', 'trabajos-en-{{location}}', 'trabajos-{{category}}-en-{{location}}'],
  'es-ES': ['trabajos-{{category}}', 'trabajos-en-{{location}}', 'trabajos-{{category}}-en-{{location}}'],
  'fi-FI': ['{{category}}-tyopaikat', 'tyopaikat-{{location}}', '{{category}}-tyopaikat-{{location}}'],
  'fr-CA': ['emplois-{{category}}', 'emplois-a-{{location}}', 'emplois-{{category}}-a-{{location}}'],
  'fr-FR': ['emplois-{{category}}', 'emplois-a-{{location}}', 'emplois-{{category}}-a-{{location}}'],
  'he-IL': null,
  'hi-IN': null,
  'hr-HR': ['{{category}}-posao', 'posao-u-{{location}}', 'posao-{{category}}-u-{{location}}'],
  'hu-HU': ['{{category}}-allasok', 'allasok-{{location}}', '{{category}}-allasok-{{location}}'], // mérnöki állást
  'hy-AM': null,
  'id-ID': ['lowongan-{{category}}', 'lowongan-di-{{location}}', 'lowongan-{{category}}-di-{{location}}'],
  'it-IT': [
    '{{category}}-offerte-lavoro',
    'offerte-lavoro-a-{{location}}',
    'offerte-lavoro-{{category}}-a-{{location}}'
  ],
  'ja-JP': null,
  'ko-KR': null,
  'ms-MY': ['pekerjaan-{{category}}', 'pekerjaan-di-{{location}}', 'pekerjaan-{{category}}-di-{{location}}'],
  'nl-NL': ['{{category}}-banen', 'banen-in-{{location}}', '{{category}}-banen-in-{{location}}'],
  'no-NO': ['{{category}}-jobber', 'jobber-i-{{location}}', '{{category}}-jobber-i-{{location}}'],
  'pl-PL': ['praca-{{category}}', 'praca-w-{{location}}', '{{category}}-praca-w-{{location}}'],
  'pt-BR': ['empregos-de-{{category}}', 'empregos-em-{{location}}', 'empregos-de-{{category}}-em-{{location}}'],
  'pt-PT': ['empregos-de-{{category}}', 'empregos-em-{{location}}', 'empregos-de-{{category}}-em-{{location}}'],
  'ro-RO': [
    'locuri-de-munca-{{category}}',
    'locuri-de-munca-in-{{location}}',
    'locuri-de-munca-{{category}}-in-{{location}}'
  ],
  'ru-RU': null,
  'sk-SK': ['{{category}}-prace', 'praca-v-{{location}}', 'praca-{{category}}-v-{{location}}'],
  'sr-SP': null,
  'sv-SE': ['{{category}}-jobb', 'jobb-i-{{location}}', '{{category}}-jobb-i-{{location}}'],
  'th-TH': null,
  'tr-TR': ['{{category}}-isleri', '{{location}}-daki-isler', '{{location}}--{{category}}-is-ilanlari'],
  'uk-UA': null,
  'ur-PK': null,
  'vi-VN': ['viec-lam-{{category}}', 'viec-lam-tai-{{location}}', 'viec-lam-{{category}}-tai-{{location}}'],
  'zh-CN': null,
  'zh-TW': null
}

export const landingPageFeedSectionSlugRegex = /^fs-([a-zA-Z0-9]{26})(-.*)?$/i
export const landingPagePublicSectionSlugRegex = /^ps-([a-zA-Z0-9]{26})(-.*)?$/i

/*
const testParsers = () => {
  const testCases = [
    { category: 'foo-category' as CategorySlug, location: null },
    { category: null, location: 'bar-location' as ILocation['slug'] },
    { category: 'foo-category' as CategorySlug, location: 'bar-location' as ILocation['slug'] }
  ]

  Object.keys(landingPageSlugs).forEach(locale => {
    const parser = getLandingPageSlugParser(locale as SupportedLocaleCode)

    for (const testCase of testCases) {
      const formatted = parser.format(testCase)
      const parsed = parser.parse(formatted)

      if (parsed?.category !== testCase.category || parsed?.location !== testCase.location) {
        const steps = `${JSON.stringify(testCase)} -> ${formatted} -> ${JSON.stringify(parsed)}`
        throw new Error(`Landing page slug is ambiguous (${locale}; ${steps})`)
      }
    }
  })
}

testParsers()
*/

const generateParser = (slugTemplate: string) => {
  const orderedTokens = [...slugTemplate.matchAll(/\{\{([^}]+)\}\}/g)].map(match => match[1])

  const parserRe = new RegExp(slugTemplate.replace(/{{category}}/, '(.+)').replace(/{{location}}/, '(.+)'))

  return (slug: string): ILandingPageParams | null => {
    const match = parserRe.exec(slug)

    if (!match) {
      return null
    }

    const category = orderedTokens.includes('category') ? match[orderedTokens.indexOf('category') + 1] : null
    const location = orderedTokens.includes('location') ? match[orderedTokens.indexOf('location') + 1] : null

    return {
      categorySlug: category ? (category as CategorySlug) : null,
      locationSlug: location ? (location as ILocation['slug']) : null,
      feedSectionIdSlug: null,
      publicSectionIdSlug: null
    }
  }
}

const slugParsers: { [locale in SupportedLocaleCode]?: ReturnType<typeof getLandingPageSlugParser> } = {}

export interface ILandingPageParams {
  categorySlug: CategorySlug | null
  locationSlug: ILocation['slug'] | null
  feedSectionIdSlug: string | null
  publicSectionIdSlug: string | null
  sectionName?: string | null
}

export const getLandingPageSlugParser = (
  locale: SupportedLocaleCode
): {
  parse(slug: LandingPageSlug): ILandingPageParams | null
  format(params: ILandingPageParams): LandingPageSlug
} => {
  const existingParser = slugParsers[locale]

  if (existingParser) {
    return existingParser
  }

  const defaultSlugs = landingPageSlugs[defaultLocale]

  if (!defaultSlugs) {
    throw new Error(`No landing page slugs for default locale ${defaultLocale}`)
  }

  const [categoryTemplate, locationTemplate, categoryAndLocationTemplate] = landingPageSlugs[locale] ?? defaultSlugs

  // most detailed parsers (regexps) need to come first, otherwise the less detailed ones will match first
  const parsers = [
    generateParser(categoryAndLocationTemplate),
    generateParser(locationTemplate),
    generateParser(categoryTemplate)
  ]

  const parse = (slug: LandingPageSlug): ILandingPageParams | null => {
    //User feed section id matching
    const feedSectionIdMatch = slug.match(landingPageFeedSectionSlugRegex)
    if (feedSectionIdMatch && feedSectionIdMatch[1]) {
      const feedSectionName = feedSectionIdMatch[2]
      return {
        categorySlug: null,
        locationSlug: null,
        feedSectionIdSlug: feedSectionIdMatch[1],
        publicSectionIdSlug: null,
        sectionName: feedSectionName ? startCase(feedSectionName.substring(1).replace(/-/g, ' ')) : null
      }
    }

    //Public section id matching
    const publicSectionIdMatch = slug.match(landingPagePublicSectionSlugRegex)
    if (publicSectionIdMatch && publicSectionIdMatch[1]) {
      return {
        categorySlug: null,
        locationSlug: null,
        feedSectionIdSlug: null,
        publicSectionIdSlug: publicSectionIdMatch[1],
        sectionName: 'Job openings picked just for you'
      }
    }

    for (const parser of parsers) {
      const result = parser(slug)

      if (result) {
        return result
      }
    }

    return null
  }

  const format = ({ categorySlug, locationSlug, feedSectionIdSlug }: ILandingPageParams): LandingPageSlug => {
    if (categorySlug && locationSlug) {
      return categoryAndLocationTemplate
        .replace('{{category}}', categorySlug)
        .replace('{{location}}', locationSlug) as LandingPageSlug
    }

    if (categorySlug) {
      return categoryTemplate.replace('{{category}}', categorySlug) as LandingPageSlug
    }

    if (locationSlug) {
      return locationTemplate.replace('{{location}}', locationSlug) as LandingPageSlug
    }

    if (feedSectionIdSlug) {
      return feedSectionIdSlug as LandingPageSlug
    }

    throw new Error('No category or location provided')
  }

  slugParsers[locale] = { parse, format }

  return { parse, format }
}

export const parseLandingPageParams = ({ firstSlug, secondSlug }: { firstSlug: string; secondSlug?: string }) =>
  firstSlug?.length === 2
    ? {
        countryCode: getUnverifiedCountryCode(firstSlug),
        landingPageSlug: secondSlug ? (secondSlug as LandingPageSlug) : null
      }
    : { countryCode: null, landingPageSlug: firstSlug as LandingPageSlug }
