import { breakpoint, Image, styled } from '~/lite'

export const COMPANY_LOGO_SIZE = 100
export const COMPANY_BANNER_SIZE = 200

export const CompanyLogoImage = styled(Image)`
  @media ${breakpoint.md} {
    height: 300px;
  }
`
