import crossFetch from 'cross-fetch'

import { CountryCode } from '../models'
import { parseFromISO } from './date'
import { SupportedLocaleCode, UntranslatedText } from './i18n'

let _deviceId: string | null = null
let _country: CountryCode | null = null
let _locale: SupportedLocaleCode | null = null
let _serverUrl: string | null = null
let _cookieHeader: string | null = null
let _appInstanceId: string | null = null
let _appVersion: string | null | undefined
let _extensionVersion: string | null | undefined

const getDeviceId = () => _deviceId

export const setDeviceId = (deviceId: string) => {
  _deviceId = deviceId
}

export const getCountry = () => _country

export const setCountry = (country: CountryCode) => {
  _country = country
}

const getLocale = () => {
  if (!_locale) {
    throw new Error("User's locale is not set")
  }

  return _locale
}

export const setLocale = (locale: SupportedLocaleCode) => {
  _locale = locale
}

const getServerUrl = () => {
  if (!_serverUrl) {
    throw new Error('Server URL is not set')
  }

  return _serverUrl
}

export const setServerUrl = (url: string) => {
  _serverUrl = url
}

const getCookieHeader = () => _cookieHeader

export const setCookieHeader = (cookieHeader: string) => {
  _cookieHeader = cookieHeader
}

const getAppInstanceId = () => {
  if (!_appInstanceId) {
    throw new Error('App instance ID is not set')
  }

  return _appInstanceId
}

export const setAppInstanceId = (appInstanceId: string) => {
  _appInstanceId = appInstanceId
}

const getAppVersion = () => {
  if (typeof _appVersion === 'undefined') {
    throw new Error('App version is not set')
  }

  return _appVersion
}

export const setAppVersion = (appVersion: string | null) => {
  _appVersion = appVersion
}

const getExtensionVersion = () => _extensionVersion

export const setExtensionVersion = (extensionVersion: string) => {
  _extensionVersion = extensionVersion
}

let failOnApiError = false

export const setFailOnApiError = (failOnError: boolean) => {
  failOnApiError = failOnError
}

let useNativeFetch = false

// some contexts like service workers need native fetch
export const setUseNativeFetch = (enabled: boolean) => {
  useNativeFetch = enabled
}

export type ApiMessagePriority = 'High' | 'Medium'

export interface IApiResponse {
  type: 'Success' | 'Failure'
  message?: UntranslatedText
  priority?: ApiMessagePriority
}

type ApiResponseListener = (response: IApiResponse) => void

let _apiResponseListeners: ApiResponseListener[] = []

export const subscribeToApiResponses = (listener: ApiResponseListener) => {
  if (!_apiResponseListeners.includes(listener)) {
    _apiResponseListeners.push(listener)
  }

  return () => {
    _apiResponseListeners = _apiResponseListeners.filter(apiListener => apiListener !== listener)
  }
}

const publishApiResponse = (response: Response<any>, priority?: ApiMessagePriority) => {
  const message = response.success ? response.confirmation : response.errors?.general ?? 'An error has occurred'

  _apiResponseListeners.forEach(listener =>
    listener({ type: response.success ? 'Success' : 'Failure', message, priority })
  )
}

export type Errors<Attributes> = { general?: UntranslatedText } & {
  -readonly [field in keyof Attributes]?: UntranslatedText
}

export type GenericSuccessResponse = {
  success: true
  headers?: Headers
  confirmation?: UntranslatedText
  [field: string]: any
}

export type FailureResponse<Attributes> = { success: false; message?: UntranslatedText; errors?: Errors<Attributes> }

export type Endpoint<
  Params extends Record<string, string> | string[] = Record<string, string>,
  SuccessResponse extends GenericSuccessResponse = GenericSuccessResponse,
  Body extends Record<string, unknown> = Record<string, unknown>
> = {
  params: Params
  body: Body
  response: SuccessResponse | FailureResponse<Params & Body>
  success: SuccessResponse
  failure: FailureResponse<Params & Body>
  errors: Errors<Params & Body>
}

export type Response<Params> = GenericSuccessResponse | FailureResponse<Params>

const cache: { [key: string]: Promise<any> | null } = {}

export const getFetchParams = <T extends Endpoint>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  endpoint: string,
  options?: { data?: T['body'] }
): [string, NonNullable<Parameters<typeof crossFetch>[1]>] => {
  const deviceId = getDeviceId()
  const country = getCountry()
  const cookieHeader = getCookieHeader()
  const appVersion = getAppVersion()
  const appInstanceId = getAppInstanceId()
  const extensionVersion = getExtensionVersion()

  return [
    `${getServerUrl()}${endpoint}`,
    {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(cookieHeader && { Cookie: cookieHeader }),
        ...(deviceId && { 'User-Device': deviceId }),
        ...(country && { 'User-Country': country }),
        // identify an instance of the app across parallel requests so the backend only creates 1 guest id
        ...(appInstanceId && { 'App-Instance': appInstanceId }),
        'User-Locale': getLocale(),
        ...(appVersion && { 'App-Version': appVersion }),
        ...(extensionVersion && { 'Extension-Version': extensionVersion })
      },
      ...(options?.data ? { body: JSON.stringify(options.data) } : {})
    }
  ]
}

export const request = async <T extends Endpoint>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  endpoint: string,
  options?: { data?: T['body'] }
): Promise<T['response']> => {
  const response = await (useNativeFetch ? fetch : crossFetch)(...getFetchParams(method, endpoint, options))
  const data = convertDateValues(await response.json())
  return { ...data, headers: response.headers } as Response<T['params']>
}

export const fetchJSON = async <T extends Endpoint>(url: string): Promise<T['response']> => {
  const response = await (useNativeFetch ? fetch : crossFetch)(url)
  const data = convertDateValues(await response.json())
  return { ...data, headers: response.headers } as Response<T['params']>
}

export const get = async <T extends Endpoint>(
  endpoint: string,
  expires = 1000,
  priority?: ApiMessagePriority
): Promise<T['response']> => {
  let response: T['response']

  try {
    if (!cache[endpoint]) {
      cache[endpoint] = request<T>('GET', endpoint)
    }

    response = await cache[endpoint]
  } catch (e) {
    if (failOnApiError) {
      throw e
    }

    response = { success: false } as unknown as T['response']
  }

  publishApiResponse(response, priority)

  setTimeout(() => {
    cache[endpoint] = null
  }, expires)

  return response
}

export const post = async <T extends Endpoint>(
  endpoint: string,
  body: T['body'],
  priority?: ApiMessagePriority
): Promise<T['response']> => {
  let response: T['response']

  try {
    response = await request<T>('POST', endpoint, { data: body })
  } catch (e) {
    response = { success: false } as unknown as T['response']
  }

  publishApiResponse(response, priority)

  return response
}

export const patch = async <T extends Endpoint>(
  endpoint: string,
  body: T['body'],
  priority?: ApiMessagePriority
): Promise<T['response']> => {
  let response: T['response']

  try {
    response = await request<T>('PATCH', endpoint, { data: body })
  } catch (e) {
    response = { success: false } as unknown as T['response']
  }

  publishApiResponse(response, priority)

  return response
}

export const put = async <T extends Endpoint>(
  endpoint: string,
  body: T['body'],
  priority?: ApiMessagePriority
): Promise<T['response']> => {
  let response: T['response']

  try {
    response = await request<T>('PUT', endpoint, { data: body })
  } catch (e) {
    response = { success: false } as unknown as T['response']
  }

  publishApiResponse(response, priority)

  return response
}

export const remove = async <T extends Endpoint>(
  endpoint: string,
  body: T['body'],
  priority?: ApiMessagePriority
): Promise<T['response']> => {
  let response: T['response']

  try {
    response = await request<T>('DELETE', endpoint, { data: body })
  } catch (e) {
    response = { success: false } as unknown as T['response']
  }

  publishApiResponse(response, priority)

  return response
}

export const convertDateValues = <T extends Record<string, any>>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertDateValues(item)) as unknown as T
  }

  if (typeof obj === 'object' && obj !== null && !(obj instanceof Date)) {
    const newObj: Record<string, unknown> = {}

    for (const key in obj) {
      const value = obj[key]

      if (key.endsWith('At') && typeof value === 'string') {
        const date = parseFromISO(value)

        if (!isNaN(date.getTime())) {
          newObj[key] = date
          continue
        }
      }

      newObj[key] = convertDateValues(value)
    }

    return newObj as T
  }

  return obj
}
