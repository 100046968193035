import type { TranslatedText } from 'core'
import React from 'react'

import { IToast, Text, useStore, useTranslation, View } from '~/lite'

interface IToastProps {
  toast: IToast
  onPress(): void
}

export const Toast: React.FC<IToastProps> = ({ toast, onPress }) => {
  const theme = useStore(state => state.theme)
  const t = useTranslation()

  const message = 'message' in toast ? t(toast.message) : toast.notification.message ?? toast.notification.header
  const type = 'type' in toast ? toast.type : 'Info'

  const styles: Record<typeof type, { background: string; color: string }> = {
    Info: { background: theme.primary, color: '#ffffff' },
    Success: { background: theme.success, color: '#ffffff' },
    Failure: { background: theme.error, color: '#ffffff' }
  }

  return (
    <View
      width="fit-content"
      maxWidth="100%"
      padding="m"
      marginX="auto"
      borderRadius={8}
      justifyContent="center"
      backgroundColor={styles[type].background}
    >
      <Text rawText={message ?? ('' as TranslatedText)} textAlign="center" color={styles[type].color} />
    </View>
  )
}
