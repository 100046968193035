import { startTransition, useEffect, useState } from 'react'

export const useOnHydrate = (callback: () => void) => {
  const [originalCallback] = useState<() => () => void>(() => callback)

  // useEffect is called after ssr when on client. callback is memoized to first value passed
  useEffect(() => {
    startTransition(() => {
      originalCallback()
    })
  }, [originalCallback])
}
