import { useState } from 'react'

import { usePatchAutopilotState } from '~/hooks'
import { Button, Dialog, Text, toast, useLoadUserData, useStore } from '~/lite'

import { AutopilotSteps } from './AutopilotSteps'

export const AutopilotDialog: React.FC = () => {
  const user = useStore(state => state.user)
  const [state, setState] = useState<'Pending' | 'Enabling' | 'Disabling' | 'Hidden'>('Pending')

  const loadUserData = useLoadUserData()

  const { mutateAsync: patchAutopilot } = usePatchAutopilotState({
    onSuccess: async response => {
      if (response.success) {
        await loadUserData()
        toast.success('Autopilot is enabled')
      } else {
        toast.failure()
      }

      setState('Hidden')
    },
    onError: () => {
      toast.failure()
      setState('Hidden')
    }
  })

  const isOpen = !!user && user.types.includes('JobSeeker') && !user.autopilotState && state !== 'Hidden'

  if (!user) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      trigger={null}
      title="Autopilot"
      description="Your personal job search assistant"
      footer={
        <>
          <Button
            action="DeclineAutopilot"
            text="No thanks"
            onPress={() => {
              setState('Disabling')
              patchAutopilot({ enabled: false })
            }}
            loading={state === 'Disabling'}
            disabled={state !== 'Pending'}
          />
          <Button
            action="EnableAutopilot"
            text="Enable Autopilot"
            onPress={() => {
              setState('Enabling')
              patchAutopilot({ enabled: true })
            }}
            loading={state === 'Enabling'}
            disabled={state !== 'Pending'}
            primary
          />
        </>
      }
    >
      <Text text="Consider enabling Autopilot. Here's how it works:" />
      <AutopilotSteps />
      <Text text="It's free, private, and you can always disable it." />
    </Dialog>
  )
}
