import { Icon, styled, Text, View } from '~/lite'

import { Calendar } from './Calendar'

const CalendarFeature = styled(View)`
  flex: 1;
  flex-direction: row;
`

const CalendarTag = styled(Text)`
  padding: 6px 16px;
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.border)};
  border-radius: 20px;
  font-size: 12px;
`

const CalendarColumn = styled(View)`
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const CalendarCard = styled(View)`
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(${props => props.theme.backgroundRgb}, 0.8);
  border-radius: 24px;
  border-width: 1px;
  overflow: hidden;
`

const CalendarArrow = styled(View)`
  justify-content: center;
  align-items: center;
  padding: 16px;
`

export const CalendarTransition: React.FC = () => (
  <CalendarFeature>
    <CalendarColumn>
      <CalendarTag text="Before" />
      <CalendarCard>
        <Calendar version="Before" />
      </CalendarCard>
    </CalendarColumn>
    <CalendarArrow>
      <Icon icon="arrow-forward-outline" size={36} />
    </CalendarArrow>
    <CalendarColumn>
      <CalendarTag text="After" />
      <CalendarCard>
        <Calendar version="After" />
      </CalendarCard>
    </CalendarColumn>
  </CalendarFeature>
)
