import React, { useEffect, useMemo, useRef } from 'react'

import { getLinkHandler, useHistory, View } from '~/lite'

import { IHtmlRendererProps } from './types'

export const HtmlRenderer: React.FC<IHtmlRendererProps> = ({ html, baseMaxHeight, contentWidth, ...rest }) => {
  const container = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const onLinkPress = useMemo(() => getLinkHandler(history), [history])

  useEffect(() => {
    const links = container.current?.getElementsByTagName('a') ?? []

    for (let i = 0; i < links.length; i++) {
      const link = links[i]

      link.onclick = e => {
        e.preventDefault()
        onLinkPress(link.href)
      }
    }
  }, [container, onLinkPress])

  return (
    <View maxHeight={baseMaxHeight} overflowY={baseMaxHeight ? 'hidden' : 'unset'} {...rest}>
      <div ref={container} className="rendered-html" dangerouslySetInnerHTML={{ __html: html }} />
    </View>
  )
}
