import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { isWeb } from '../env'
import { useOnHydrate } from './useOnHydrate'

export const useBreakpoints = () => {
  const [isMock, setIsMock] = useState(isWeb)
  const is2XlDesktop = useMediaQuery({ minWidth: 1536 })
  const isXlDesktop = useMediaQuery({ minWidth: 1280 })
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const isPhone = !isDesktop

  useOnHydrate(() => {
    setIsMock(false)
  })

  // ensure first render uses same breakpoint on server and client so there isn't a mismatch when hydrating
  return isMock
    ? { isXlDesktop: false, is2XlDesktop: false, isDesktop: false, isPhone: true }
    : { isXlDesktop, is2XlDesktop, isDesktop, isPhone }
}
