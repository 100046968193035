import { ILaunchpadContent } from 'core'

import { animations, breakpoint, H1, styled } from '~/lite'

import { ActionContainer, HeroWrap, SectionContent, Subtitle } from '../styles'
import { ResumeIllustration } from './ResumeIllustration'

const HeaderTitle = styled(H1)`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;

  ::selection {
    -webkit-text-fill-color: #fff;
  }

  margin-top: 32px;
  animation: 1000ms ease 0s both ${animations.fadeInDown};

  @media ${breakpoint.md} {
    margin-top: 0;
    font-size: 40px;
  }
`

const HeaderSubtitle = styled(Subtitle)`
  margin-bottom: 16px;
  animation: 1200ms ease 400ms both ${animations.fadeInDown};
`

interface IHeroProps {
  data: ILaunchpadContent['hero']
  children: React.ReactNode
}

export const Hero: React.FC<IHeroProps> = ({ data, children }) => (
  <HeroWrap>
    <SectionContent>
      <ResumeIllustration />
      <HeaderTitle rawText={data.header} />
      <HeaderSubtitle rawText={data.subheader} />
      <ActionContainer flexDirection="row" alignItems="stretch" justifyContent="center" paddingBottom={32} $enter>
        {children}
      </ActionContainer>
    </SectionContent>
  </HeroWrap>
)
