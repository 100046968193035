import { Stripe } from '@stripe/stripe-js'
import {
  createCustomerPortalSession,
  createGeneralStripeCheckoutSession,
  createStripeMarketCheckoutSession,
  IMarketProduct,
  IMarketProfile,
  IStripePrice,
  IStripeProduct,
  TrackdeskReferral
} from 'core'

import { constants, toast } from '~/lite'

let stripePromise: Promise<Stripe | null> | null = null
const loadStripeSingleton = async () => {
  if (!stripePromise) {
    stripePromise = import('@stripe/stripe-js').then(stripe => stripe.loadStripe(constants.stripePublishableKey))
  }

  return stripePromise
}

export const subscribeToSubscriptionPlan = async (productId: IStripeProduct['id'], priceId: IStripePrice['id']) => {
  const stripe = await loadStripeSingleton()

  if (!stripe) {
    throw new Error('Failed to load stripe')
  }

  const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)')
  let referralId: TrackdeskReferral | undefined = undefined

  if (Array.isArray(cookie)) {
    try {
      const data = cookie.pop()

      if (data) {
        referralId = JSON.parse(data)?.cid
      }
    } catch (e) {
      toast.failure()
      throw e
    }
  }

  const sessionResult = await createGeneralStripeCheckoutSession(productId, priceId, referralId)

  if (!sessionResult.success) {
    return sessionResult
  }

  if (sessionResult.session.url) {
    window.location.href = sessionResult.session.url
  } else {
    const stripeResult = await stripe.redirectToCheckout({ sessionId: sessionResult.session.id })

    if (stripeResult.error) {
      throw new Error(`Stripe error: ${JSON.stringify(stripeResult.error)}`)
    }
  }

  return { success: true }
}

export const redirectToStripeCustomerPortal = async () => {
  const stripe = await loadStripeSingleton()

  if (!stripe) {
    throw new Error('Failed to load stripe')
  }

  return createCustomerPortalSession()
}

export const redirectToMarketCheckout = async (profileId: IMarketProfile['id'], productId: IMarketProduct['id']) => {
  const stripe = await loadStripeSingleton()

  if (!stripe) {
    throw new Error('Failed to load stripe')
  }

  const sessionResult = await createStripeMarketCheckoutSession(profileId, productId)

  if (!sessionResult.success) {
    return sessionResult
  }

  if (sessionResult.session.url) {
    window.location.href = sessionResult.session.url
  } else {
    const stripeResult = await stripe.redirectToCheckout({ sessionId: sessionResult.session.id })

    if (stripeResult.error) {
      throw new Error(`Stripe error: ${JSON.stringify(stripeResult.error)}`)
    }
  }

  return { success: true }
}
