import { IInterview, IInterviewLogMessage, IInterviewQuestion, TranslatedText } from 'core'
import { Dictionary, keyBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { HoverCard, Input } from '~/components'
import { useAudioPlayer, useInterview } from '~/hooks'
import { Button, H3, Icon, Loader, styled, Text, View } from '~/lite'
import { constants } from '~/util'

type TranscriptEntry =
  | { role: 'Interviewer' | 'Interviewee'; content: string; timestamp?: string }
  | { role: 'Function'; name: 'getNextInterviewQuestion'; content: string; timestamp?: string }

const Wrap = styled(View)`
  position: relative;
  width: 100%;
  max-width: 800px;
  height: calc(100vh - 140px);
  margin: 16px auto;
  background: ${props => props.theme.cardBackground};
  border-width: 1px;
  border-radius: 12px;
  overflow: hidden;
`

const Header = styled(View)`
  padding: 16px;
  border-bottom-width: 1px;
`

const Body = styled(View)`
  flex: 1;
  overflow-y: auto;
`

const Info = styled(View)`
  flex: 1;
`

const Title = styled(H3)`
  font-size: 22px;
  font-weight: normal;
`

const PlayButton = styled(Button)`
  border-radius: 4px;
  margin-right: 8px;
`

const Question = styled(View)`
  align-items: stretch;
  justify-content: stretch;
  background: ${props => props.theme.inputBackground};
  border: 0.75px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  min-width: 24px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${props => props.theme.primaryHover};
  }
`

const PlayedBackground = styled(View)`
  flex: 1;
  transform-origin: left;
  transition: transform 0.2s ease;
  background: ${props => props.theme.primary};
`

const Questions = styled(View)`
  flex: 1;
  flex-direction: row;
  align-items: stetch;
  height: 28px;
  background: ${props => props.theme.inputBackground};
  border-radius: 4px;
  overflow: hidden;

  ${Question}:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  ${Question}:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover ${Question} {
    margin: 1px;
    border-radius: 3px;
  }
`

const Transcript = styled(View)`
  padding: 16px;
`

const InterviewerEntry = styled(View)`
  margin-bottom: 16px;
`

const InterviewerText = styled(Text)`
  font-weight: bold;
`

const IntervieweeEntry = styled(View)`
  margin-bottom: 16px;
`

const IntervieweeText = styled(Text)`
  font-size: 16px;
`

const Divider = styled(View)`
  height: 3px;
  width: 100%;
  margin: 16px 0;
  background: ${props => props.theme.primary};
  border-radius: 3px;
`

export interface IInterviewPlayerProps {
  interviewId: IInterview['id']
}

const formatTimestamp = (timestamp: number) => {
  const minutes = Math.floor(timestamp / 60)
  const seconds = Math.floor(timestamp % 60)
  return `${minutes}:${seconds}`
}

export const InterviewPlayer: React.FC<IInterviewPlayerProps> = ({ interviewId, ...props }) => {
  const { data: interviewData, isLoading } = useInterview(interviewId)
  const questionRefs = useRef<(HTMLDivElement | null)[]>([])
  const { play, pause, setAudioUrl, progress, seek, isBuffering, isPlaying } = useAudioPlayer()

  const { prefixStartTimes, formattedTranscripts, messageGroupMap } = useMemo<{
    prefixStartTimes: number[]
    formattedTranscripts: TranscriptEntry[]
    messageGroupMap: Dictionary<{
      messages: IInterviewLogMessage[]
      duration: number
      questionId: IInterviewQuestion['id']
    }>
  }>(() => {
    if (
      !interviewData?.interviewLogMessages.length ||
      !interviewData.interviewLogMessages.every(m => m.audioDuration)
    ) {
      return { formattedTranscripts: [], prefixStartTimes: [], messageGroupMap: {} }
    }
    const messagesGroupByQuestion: IInterviewLogMessage[][] = []
    let currentGroup: IInterviewLogMessage[] = []
    let currentQuestionId = interviewData.interviewLogMessages[0]?.relatedQuestionId || null
    for (const message of interviewData.interviewLogMessages) {
      if (message.relatedQuestionId === currentQuestionId) {
        currentGroup.push(message)
      } else {
        messagesGroupByQuestion.push(currentGroup)
        currentQuestionId = message.relatedQuestionId
        currentGroup = [message]
      }
    }
    if (currentGroup.length) {
      messagesGroupByQuestion.push(currentGroup)
    }
    const formattedTranscripts: TranscriptEntry[] = []
    let accumulatedDurationByMessage = 0
    currentQuestionId = interviewData.interviewLogMessages[0]?.relatedQuestionId || null
    for (const logMessage of interviewData.interviewLogMessages) {
      if (
        formattedTranscripts.length &&
        currentQuestionId !== logMessage.relatedQuestionId &&
        logMessage.role === 'assistant'
      ) {
        formattedTranscripts.push({
          role: 'Function',
          content: logMessage.message,
          name: 'getNextInterviewQuestion'
        })
      }

      formattedTranscripts.push({
        role: logMessage.role === 'assistant' ? 'Interviewer' : 'Interviewee',
        content: logMessage.message,
        timestamp: formatTimestamp(accumulatedDurationByMessage)
      })

      currentQuestionId = logMessage.relatedQuestionId
      accumulatedDurationByMessage += logMessage.audioDuration || 0
    }
    const prefixStartTimes = []
    let accumulatedDurationByGroup = 0
    const messageGroup: {
      messages: IInterviewLogMessage[]
      duration: number
      questionId: IInterviewQuestion['id']
    }[] = []
    for (const group of messagesGroupByQuestion) {
      const groupDuration = group.reduce((acc, curr) => acc + (curr.audioDuration || 0), 0) / 1000
      prefixStartTimes.push(accumulatedDurationByGroup)
      const questionId = group[0].relatedQuestionId
      if (questionId) {
        messageGroup.push({
          messages: group,
          duration: groupDuration,
          questionId
        })
      }
      accumulatedDurationByGroup += groupDuration
    }
    prefixStartTimes.push(accumulatedDurationByGroup) // add the last one, which is also the total duration
    return { prefixStartTimes, formattedTranscripts, messageGroupMap: keyBy(messageGroup, 'questionId') }
  }, [interviewData?.interviewLogMessages])

  useEffect(() => {
    if (interviewData?.interview.audioFile) {
      setAudioUrl(`${constants.assetServerUrl}/${interviewData.interview.audioFile}`)
    }
  }, [interviewData, setAudioUrl])

  useEffect(() => {
    if (
      !isPlaying ||
      !prefixStartTimes.length ||
      !interviewData?.interviewLogMessages.length ||
      !interviewData.interviewLogMessages.every(m => m.audioDuration)
    ) {
      return
    }

    const getScaleX = (questionIndex: number) => {
      const startTime = prefixStartTimes[questionIndex]
      const endTime = prefixStartTimes[questionIndex + 1]
      if (progress < startTime) {
        return 0
      }
      if (progress >= endTime) {
        return 1
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return (progress - startTime) / messageGroupMap[interviewData.interviewQuestions[questionIndex].id]?.duration || 1
    }

    questionRefs.current.forEach((el, idx) => {
      if (el) {
        const scaleX = getScaleX(idx)
        el.style.transform = `scaleX(${scaleX})`
      }
    })
  }, [isPlaying, progress, interviewData, prefixStartTimes, messageGroupMap])

  const onActionButtonClick = useCallback(() => {
    if (isPlaying) {
      pause()
    } else {
      play()
    }
  }, [isPlaying, pause, play])

  if (isLoading) {
    return <Loader />
  }

  const hasInterview = interviewData?.interviewLogMessages.length && interviewData?.interviewQuestions.length

  if (!hasInterview) {
    return null
  }

  const onCardPress = (questionIndex: number) => {
    play(prefixStartTimes[questionIndex] || 0)
  }

  return (
    <Wrap {...props}>
      <Header>
        <View marginBottom={16} flexDirection="row">
          <Info>
            <Title rawText={'Jose Quervo' as TranslatedText} />
          </Info>
          <Input placeholder="Search" accessoryLeft={<Icon icon="search" />} />
        </View>
        <View flexDirection="row">
          <PlayButton
            loading={isBuffering}
            icon={isPlaying ? 'pause' : 'play'}
            onPress={onActionButtonClick}
            skipTracking
          />
          <Questions>
            {interviewData.interviewQuestions.map(({ question }, idx) => (
              <HoverCard
                key={idx}
                openDelay={0}
                closeDelay={0}
                trigger={
                  <Question flex={1} onPress={() => onCardPress(idx)}>
                    <PlayedBackground ref={el => (questionRefs.current[idx] = el)} />
                  </Question>
                }
                content={
                  <View>
                    <Text rawText={question as TranslatedText} />
                  </View>
                }
              />
            ))}
          </Questions>
        </View>
      </Header>
      <Body>
        <Transcript>
          {formattedTranscripts.map((entry, idx) => {
            if (entry.role === 'Interviewer') {
              return (
                <InterviewerEntry key={idx}>
                  <InterviewerText rawText={entry.content as TranslatedText} />
                </InterviewerEntry>
              )
            }

            if (entry.role === 'Interviewee') {
              return (
                <IntervieweeEntry key={idx}>
                  <IntervieweeText rawText={entry.content as TranslatedText} />
                </IntervieweeEntry>
              )
            }

            if (entry.role === 'Function' && entry.name === 'getNextInterviewQuestion') {
              return <Divider key={idx} />
            }

            return null
          })}
        </Transcript>
      </Body>
    </Wrap>
  )
}
