import { Input } from '~/components'
import { animations, breakpoint, Button, css, styled, Text, View } from '~/lite'

export const cardAnimationDuration = 400

export const MagicLinkCard = styled(View)<{ $visible: boolean; $enableAnimation: boolean }>`
  margin: 16px;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  text-align: center;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: ${cardAnimationDuration}ms;
  animation-name: ${props => {
    if (!props.$enableAnimation) {
      return 'none'
    }
    return props.$visible ? animations.slideInUp : animations.minimize
  }};
  box-shadow: ${props =>
    props.$visible ? 'inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px' : 'none'};

  &:not(:first-child) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    transform: translate3d(0, 100%, 0);
  }

  .uppy-FileInput-btn {
    min-height: 42px;
    font-weight: bold;
  }
`

export const FormContainer = styled(View)`
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`

export const InputContainer = styled(View)`
  flex: 1;
  margin-top: 8px;
`

export const StyledInput = styled(Input)`
  flex: 1;
  min-width: 0;

  &:placeholder-shown {
    text-align: center;
  }

  &:focus {
    text-align: left;
  }
`

export const StyledButton = styled(Button)`
  min-height: 42px;
  font-weight: bold;
`

export const SubmitButton = styled(StyledButton)`
  margin-top: 8px;
  min-width: 80px;
  border-radius: 10px;
`

export const SignUpNoteWrap = styled.div`
  display: block;
  margin-top: 8px;
  font-size: 12px;

  a:hover {
    text-decoration: underline;
  }
`

export const InlineErrorMessage = styled(Text)`
  margin-top: 8px;
`

export const BottomErrorMessage = styled(Text)`
  margin-top: 8px;
  display: none;
`

export const MagicLinkContainer = styled(View)<{ $horizontal?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;

  .uppy-FileInput-btn {
    text-align: center;
  }

  ${props =>
    props.$horizontal &&
    css`
      @media ${breakpoint.md} {
        ${FormContainer} {
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }

        ${InputContainer} {
          margin-top: 0;
          margin-left: 8px;
        }

        ${StyledInput} {
          &:placeholder-shown {
            text-align: left;
          }
        }

        ${InlineErrorMessage} {
          display: none;
        }

        ${BottomErrorMessage} {
          display: block;
        }

        ${SubmitButton} {
          margin-top: 0;
          margin-left: 8px;
        }
      }
    `}
`
