import { AdvancedSearchResult, ILocationSearchResult, IRecentSearch, ISearchResult, PageableResponse } from '../models'
import { Endpoint, get, post, remove } from '../util/api'
import { buildQueryString } from '../util/helpers'
import { IAdvancedSearchQuery, ICitySearchQuery, ILocationSearchQuery, JobScope } from '../util/query'

export type GetGlobalSearch = Endpoint<
  Record<string, any>,
  {
    success: true
    results: {
      occupations: ISearchResult[]
      skills: ISearchResult[]
      teams: ISearchResult[]
      locations: ISearchResult[]
    }
  }
>

export type GetAdvancedSearch = Endpoint<
  Record<string, any>,
  {
    success: true
    data: AdvancedSearchResult
  }
>

export type GetCitiesSearch = Endpoint<
  Record<string, any>,
  {
    success: true
    data: PageableResponse<ILocationSearchResult>
  }
>

export type SearchNearbyLocation = Endpoint<
  { locationId: string },
  {
    success: true
    data: PageableResponse<ILocationSearchResult>
  }
>

export type SearchLocation = Endpoint<
  Record<string, never>,
  {
    success: true
    locations: ILocationSearchResult[]
  }
>

export const getSearchGlobal = (query: string) => get<GetGlobalSearch>(`/search/global?q=${query}`)

export type CreateRecentSearch = Endpoint<Record<string, never>, { success: true }, { search: ISearchResult }>

export const createRecentSearches = (search: ISearchResult) => post<CreateRecentSearch>('/search/recent', { search })

export type GetRecentSearches = Endpoint<Record<string, never>, { success: true; recentSearches: IRecentSearch[] }>

export const getRecentSearches = () => get<GetRecentSearches>('/search/recent')

export type DeleteRecentSearch = Endpoint<Record<string, never>, { success: true }, { id: IRecentSearch['id'] }>
export type DeleteAllRecentSearch = Endpoint<Record<string, never>, { success: true }>

export const deleteRecentSearch = (id: IRecentSearch['id']) => remove<DeleteRecentSearch>('/search/recent', { id })
export const deleteAllRecentSearches = () => remove<DeleteAllRecentSearch>('/search/recent/all', {})

export const getAdvancedSearch = (query: IAdvancedSearchQuery, scope?: JobScope) =>
  get<GetAdvancedSearch>(`/search/advanced${buildQueryString({ ...query, scope })}`)

export const getCitiesSearch = (query: ICitySearchQuery) =>
  get<GetCitiesSearch>(`/search/cities${buildQueryString(query)}`)

export const searchLocations = async (query: ILocationSearchQuery) =>
  get<SearchLocation>(`/search/locations${buildQueryString(query)}`)
