import { IAffiliateContent } from 'core'
import { useState } from 'react'

import { useHistory, useTranslation, View } from '~/lite'
import { showLiveChat } from '~/util'

import {
  ActionButton,
  HeaderSubtitle,
  HeaderTag,
  HeaderTitle,
  HeroWrap,
  SecondaryActionButton,
  SectionContent
} from '../styles'

interface IHeroProps {
  data: IAffiliateContent['hero']
}

export const Hero: React.FC<IHeroProps> = ({ data }) => {
  const history = useHistory()
  const t = useTranslation()
  const [isLoadingChat, setIsLoadingChat] = useState(false)

  const onActionButtonPress = () => {
    history.openWebView({
      type: 'ExternalSite',
      headerTitle: t('Affiliate'),
      url: 'https://ambition.trackdesk.com/sign-up'
    })
  }

  return (
    <HeroWrap>
      <SectionContent>
        <HeaderTag rawText={data.tag} />
        <HeaderTitle rawText={data.header} />
        <HeaderSubtitle rawText={data.subheader} />
        <View flexDirection="row" alignItems="stretch" justifyContent="center" paddingBottom={32}>
          <ActionButton onPress={onActionButtonPress} rawText={data.cta} $enter primary skipTracking large />
          {/*<SecondaryActionButton
            text={isLoadingChat ? 'Loading...' : undefined}
            rawText={isLoadingChat ? undefined : data.chatButton}
            onPress={async () => {
              setIsLoadingChat(true)
              await showLiveChat()
              setIsLoadingChat(false)
            }}
            $enter
            skipTracking
            large
          />*/}
        </View>
      </SectionContent>
    </HeroWrap>
  )
}
