import { IFaqContent } from 'core'
import React from 'react'

import { Accordion } from '~/components'
import { breakpoint, styled, useStore, View } from '~/lite'

import { backgrounds, CardTitle, Section, SectionContent, SectionTitle } from './styles'

const AccordionWrap = styled(View)`
  margin: 0 8px;

  @media ${breakpoint.md} {
    margin: 16px 0 0;
  }
`

type Variant = 'Card'

interface IFaqProps {
  data: IFaqContent
  id?: string
  variant?: Variant
}

export const Faq: React.FC<IFaqProps> = ({ data, id, variant }) => {
  const darkMode = useStore(state => state.darkMode)

  const accordion = (
    <Accordion
      data={data.questions.map(({ question, answer }) => ({ trigger: question, content: answer }))}
      simple={variant === 'Card'}
    />
  )

  return (
    <Section id={id ?? 'faq'} style={darkMode ? { background: backgrounds.pink } : undefined}>
      <SectionContent>
        {variant === 'Card' ? (
          <>
            <CardTitle rawText={data.header} />
            <View paddingX={8}>{accordion}</View>
          </>
        ) : (
          <>
            <SectionTitle rawText={data.header} />
            <AccordionWrap>{accordion}</AccordionWrap>
          </>
        )}
      </SectionContent>
    </Section>
  )
}
