import { css, Flex, FlexHorizontal, FlexVertical, Image, ListItem, ScrollView, styled, View } from '~/components'
import { isWeb } from '~/util'

export const ApplicationContainer = styled(Flex)`
  padding: 8px 3px;
  align-items: center;
`

export const MemberListItemWrap = styled(ListItem)`
  background: ${props => props.theme.background};
  background-color: ${props => props.theme.background};
  width: 100%;
  margin-bottom: 2px;
`

export const MemberImage = styled(Image)`
  border-radius: 30px;
  border-width: 1px;
  border-color: ${props => props.theme.border};
`

export const TeamStatusesPageWrap = styled(View)`
  flex: 1;
  padding: 10px;
`

export const DialogBody = styled(FlexVertical)`
  padding: 12px;
`

export const TeamWrap = styled(ScrollView)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  ${isWeb &&
  css`
    padding: 30px;
  `}
`

export const Header = styled(FlexHorizontal)`
  justify-content: space-between;
`

export const SectionWrap = styled(View)`
  margin-bottom: 10px;
  min-height: 50px;
`

export const SectionContent = styled(View)`
  background-color: ${props => props.theme.border};
  padding: 0px;
  margin: 10px 0;
  border-radius: 4px;
`

export const Container = styled(View)`
  flex-direction: row;
  margin-bottom: 3px;
  flex: 1;
`

export const Status = styled(ListItem)`
  padding: 15px 10px;
  background: ${props => props.theme.background};
  background-color: ${props => props.theme.background};
  width: 100%;
`

export const EditIcon = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  background-color: #eeeeeebf;
  position: absolute;
  right: 5px;
  bottom: 5px;
`
