import { Buffer } from 'buffer'
import { getFetchParams, UntranslatedText } from 'core'

import { fetchWithStream } from '~/lite'

const monitorAudioLevels = (
  input: MediaStream | HTMLAudioElement,
  callback: (volume: number) => void,
  connectToDestination = true
): (() => void) => {
  const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()
  const analyser = audioContext.createAnalyser()

  const source =
    input instanceof MediaStream
      ? audioContext.createMediaStreamSource(input)
      : audioContext.createMediaElementSource(input)

  source.connect(analyser)
  connectToDestination && source.connect(audioContext.destination)

  const dataArray = new Uint8Array(analyser.frequencyBinCount)

  const checkVolume = () => {
    analyser.getByteTimeDomainData(dataArray)

    const volume = dataArray.reduce((acc, value) => acc + (value - 128) * (value - 128), 0)
    const rms = Math.sqrt(volume / dataArray.length)

    callback(rms)

    requestId = requestAnimationFrame(checkVolume)
  }

  let requestId = requestAnimationFrame(checkVolume)

  const unsubscribe = () => {
    cancelAnimationFrame(requestId)
    source.disconnect()
    audioContext.close()
  }

  return unsubscribe
}

export const withMicrophoneLevels = async (userMediaStream: MediaStream | null, callback: (volume: number) => void) => {
  const stream = userMediaStream ?? (await navigator.mediaDevices.getUserMedia({ audio: true }))
  return monitorAudioLevels(stream, callback, false)
}

export const withAudioLevels = (
  audioElement: MediaStream | HTMLAudioElement,
  callback: (volume: number) => void,
  connectToDestination = true
) => monitorAudioLevels(audioElement, callback, connectToDestination)

const mergeAudioBuffers = (audioContext: AudioContext, buffer1: AudioBuffer, buffer2: AudioBuffer | null) => {
  if (!buffer2) {
    return buffer1
  }
  const numberOfChannels = Math.min(buffer1.numberOfChannels, buffer2.numberOfChannels)
  if (numberOfChannels > 1) {
    throw new Error('Merging audio buffers with more than 1 channel is not supported')
  }
  // const totalDuration = buffer1.duration + buffer2.duration
  const mergedBuffer = audioContext.createBuffer(1, buffer1.length + buffer2.length, audioContext.sampleRate)

  const channelData = mergedBuffer.getChannelData(0)
  channelData.set(buffer1.getChannelData(0))
  channelData.set(buffer2.getChannelData(0), buffer1.length)

  return mergedBuffer
}

const mergeUint8Arrays = (...arrayList: Uint8Array[]) => {
  if (arrayList.length === 1) {
    return arrayList[0]
  }
  const length = arrayList.reduce((acc, array) => acc + array.length, 0)
  const mergedArray = new Uint8Array(length)

  let offset = 0
  for (const array of arrayList) {
    mergedArray.set(array, offset)
    offset += array.length
  }

  return mergedArray
}

type StreamAudioChunk = {
  audioBuffer?: string
  transcriptText?: string
  assistantMessageId?: string
  isFinal?: true
  isEnded?: boolean
  error?: UntranslatedText
}

type AudioBufferWithTranscript = {
  audioBuffer?: Uint8Array
  transcriptText?: string
}

const defaultAnimationPerTranscriptCharacter = 0.05

const cloneUint8Array = (array: Uint8Array) => {
  const cloneArray = new Uint8Array(array.length)
  cloneArray.set(array)
  return cloneArray
}

export const playAudioWithStream = ({
  audioContext,
  streamDestination,
  fetchParams,
  initialVolume,
  onStart,
  onTranscriptAdded,
  onEnd
}: {
  audioContext: AudioContext
  streamDestination: MediaStreamAudioDestinationNode
  fetchParams: ReturnType<typeof getFetchParams>
  initialVolume: number
  onStart: (() => void) | null
  onTranscriptAdded: ((transcript: string, startTime: number, audioDuration: number) => void) | null
  onEnd:
    | ((error?: UntranslatedText, buffer?: ArrayBuffer, assistantMessageId?: string, isEnded?: boolean) => void)
    | null
}) => {
  const [endpoint, options] = fetchParams
  let nextTimestamp = 0
  const pendingItem: { buffer: Uint8Array | null; transcript: string; chunkIndex: number } = {
    buffer: null,
    transcript: '',
    chunkIndex: 0
  }

  let currentVolume = initialVolume
  let gainNode: GainNode | null = null
  const setVolume = (volume: number) => {
    currentVolume = volume
    gainNode?.gain.setValueAtTime(currentVolume, audioContext.currentTime)
  }
  const playAudioBuffer = async (aBuffer: AudioBuffer, timestamp: number, onEnd: (() => void) | null = null) => {
    pendingItem.chunkIndex++
    const source = audioContext.createBufferSource()
    gainNode = audioContext.createGain()
    gainNode.gain.setValueAtTime(currentVolume, audioContext.currentTime)
    source.buffer = aBuffer
    source.connect(gainNode)
    gainNode.connect(audioContext.destination) // for playback
    gainNode.connect(streamDestination) // for recording
    source.start(timestamp, 0, aBuffer.duration)
    source.onended = onEnd
  }
  const queueAudioChunk = async (
    audioBufferWithTranscript?: AudioBufferWithTranscript,
    playImmediately: 'playImmediately' | null = null,
    onEnd: (() => void) | null = null
  ) => {
    const currentChunkIndex = pendingItem.chunkIndex
    pendingItem.transcript += audioBufferWithTranscript?.transcriptText || ''
    pendingItem.buffer = audioBufferWithTranscript?.audioBuffer
      ? mergeUint8Arrays(pendingItem.buffer || new Uint8Array(), audioBufferWithTranscript.audioBuffer)
      : pendingItem.buffer

    let duration = pendingItem.transcript.length * defaultAnimationPerTranscriptCharacter
    if (!pendingItem.buffer) {
      if (pendingItem.transcript) {
        onTranscriptAdded?.(pendingItem.transcript, 0, duration)
      }
      nextTimestamp = Math.max(nextTimestamp, audioContext.currentTime) + duration
      pendingItem.transcript = ''
      onEnd?.()
      return
    }

    const decodedAudioBuffer = await audioContext.decodeAudioData(cloneUint8Array(pendingItem.buffer).buffer)
    if (playImmediately || (nextTimestamp && audioContext.currentTime >= nextTimestamp - 0.2)) {
      duration = decodedAudioBuffer.duration
      if (pendingItem.transcript) {
        onTranscriptAdded?.(pendingItem.transcript, Math.max(0, nextTimestamp - audioContext.currentTime), duration)
      }
      playAudioBuffer(decodedAudioBuffer, Math.max(0, nextTimestamp - 0.05), () => {
        if (currentChunkIndex === pendingItem.chunkIndex - 1) {
          queueAudioChunk(undefined, 'playImmediately', onEnd)
          return
        }
        onEnd?.()
      })
      pendingItem.buffer = null
      pendingItem.transcript = ''
      nextTimestamp = Math.max(nextTimestamp, audioContext.currentTime) + duration
      return
    }

    if (!nextTimestamp) {
      if (decodedAudioBuffer.duration >= 3) {
        // initial chunk, wait for a few chunks to prevent audio gap
        queueAudioChunk(undefined, 'playImmediately', onEnd)
      }
    }

    // let accumulatedAudioBuffer: Uint8Array | null = null
    // let accumulatedLength = 0
    // let totalDuration = 0
    // const transcriptChunks: { text: string; offset: number }[] = []
    // for (let j = 0; j < chunkGroups.length; j++) {
    //   const chunkGroup = chunkGroups[j]
    //   if (chunkGroup.type === 'full' || chunkGroup.type === 'audio') {
    //     const chunkLength = chunkGroup.chunks.reduce((acc, chunk) => acc + (chunk.audioBuffer?.length || 0), 0)
    //     accumulatedLength += chunkLength
    //     const audioBuffer = new Uint8Array(chunkLength)
    //     let offset = 0
    //     for (let i = 0; i < chunkGroup.chunks.length; i++) {
    //       const currentChunk = chunkGroup.chunks[i]
    //       if (!currentChunk.audioBuffer) {
    //         throw new Error('Missing audio buffer for audio type chunk')
    //       }
    //       audioBuffer.set(currentChunk.audioBuffer, offset)
    //       offset += currentChunk.audioBuffer.length
    //     }

    //     const decodedBufferChunk = await audioContext.decodeAudioData(audioBuffer.buffer)
    //     const transcript: string = compact(chunkGroup.chunks.map(chunk => chunk.transcriptText || null)).join('')
    //     transcriptChunks.push({ text: transcript, offset: totalDuration })
    //     totalDuration += decodedBufferChunk.duration
    //     continue
    //   } else {
    //     if (accumulatedLength) {
    //       accumulatedAudioBuffer = new Uint8Array(accumulatedLength)
    //       accumulatedLength = 0
    //       const decodedBuffer = await audioContext.decodeAudioData(accumulatedAudioBuffer.buffer)
    //       const source = audioContext.createBufferSource()
    //       source.buffer = decodedBuffer
    //       source.connect(audioContext.destination)
    //       source.start(Math.max(0, nextTimestamp - 0.05), 0, decodedBuffer.duration)
    //     }
    //   }
    //   try {
    //     let duration = transcript.length * defaultAnimationPerTranscriptCharacter
    //     if (audioBuffer) {
    //       const decodedBuffer = await audioContext.decodeAudioData(accumulatedAudioBuffer.buffer)
    //       const source = audioContext.createBufferSource()
    //       source.buffer = decodedBuffer
    //       source.connect(audioContext.destination)
    //       source.start(Math.max(0, nextTimestamp - 0.05), 0, decodedBuffer.duration)
    //       j === chunkGroups.length - 1 && (source.onended = onEnd) // only set onended for the last chunk
    //       duration = decodedBuffer.duration
    //     } else if (j === chunkGroups.length - 1) {
    //       setTimeout(() => {
    //         onEnd?.()
    //       }, duration * 1000)
    //     }

    //     transcript && onChunk?.(transcript, Math.max(0, nextTimestamp - 0.05), duration)
    //     nextTimestamp = Math.max(nextTimestamp, audioContext.currentTime) + duration
    //   } catch (e: any) {
    //     console.log(e.message)
    //     startIndexChunk = previousIndex
    //   }
    // }
  }
  const bufferChunks: Uint8Array[] = [] // for final buffer playback
  let triggerStart = false
  fetchWithStream({
    endpoint,
    options,
    callback: async (chunk, error) => {
      if (error) {
        onEnd?.('Oops! Something went wrong')
        return
      }
      if (!triggerStart && onStart) {
        triggerStart = true
        onStart()
      }
      if (chunk) {
        if (!chunk?.startsWith('data: ')) {
          return
        }

        const data = JSON.parse(chunk.slice(6)) as StreamAudioChunk
        if (data.error) {
          onEnd?.(data.error)
          return
        }

        // if it's the final data
        if (data.isFinal) {
          const buffer = mergeUint8Arrays(...bufferChunks)
          queueAudioChunk(undefined, 'playImmediately', () => {
            onEnd?.(undefined, buffer.buffer, data.assistantMessageId, data.isEnded)
          })
          return
        }
        if (!data.audioBuffer && !data.transcriptText) {
          return
        }
        const audioBuffer = data.audioBuffer ? Buffer.from(data.audioBuffer, 'base64') : undefined
        if (audioBuffer) {
          bufferChunks.push(cloneUint8Array(audioBuffer))
        }
        queueAudioChunk({ audioBuffer, transcriptText: data.transcriptText })
      }
    }
  })
  return { setVolume }
}
