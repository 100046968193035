import { IFileField } from 'core'
import React from 'react'

import { FormGroup } from '~/components'
import { Button, Icon, styled, Text, useHistory, useTranslation, View } from '~/lite'

import { FileInput } from '../../FileInput'

const Wrap = styled(View)`
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  border-radius: 10px;
  padding: 4px 4px 4px 10px;
  background: ${props => props.theme.inputBackground};
  border: 1px solid ${props => props.theme.inputBorderColor};

  .uppy-FileInput-btn {
    width: 32px !important;
    height: 32px !important;
    padding: 0;
    background: none;
    color: ${props => props.theme.text};
  }
`

const InputButton = styled(Button)`
  margin-left: 4px;
  border-radius: 6px;
  background: ${props => props.theme.inputBorderColor};
`

const FileInputWrap = styled(View)`
  overflow: hidden;
  margin-left: 4px;
  border-radius: 6px;
  background-color: ${props => props.theme.inputBorderColor};
  color: ${props => props.theme.text};

  &:hover {
    background: ${props => props.theme.buttonHover};
  }
`

interface IResumeProps {
  question: IFileField
  error: string
  isRequired?: boolean
  value: string
  onChange(value: string): void
  onStudioButtonPress?(value: string): void
}

export const Resume: React.FC<IResumeProps> = ({
  question,
  error,
  isRequired,
  value,
  onChange,
  onStudioButtonPress
}) => {
  const t = useTranslation()
  const history = useHistory()

  return (
    <FormGroup rawLabel={question.label} isRequired={isRequired} helperText={error}>
      <Wrap>
        {!!value && (
          <>
            <Icon icon="checkmark-circle" color="#34b792" size={24} marginRight={4} />
            <Text text="Attached" />
          </>
        )}
        <View flex={1} />
        {value && (
          <InputButton
            text="View"
            iconSize={16}
            onPress={() => history.openWebView({ type: 'ExternalSite', url: value, headerTitle: t('CV') })}
            skipTracking
          />
        )}
        <FileInputWrap>
          <FileInput
            onInputChange={url => url && onChange(url)}
            accept={
              question.supportedMimeTypes
                ? question.supportedMimeTypes
                : [
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                    'image/x-eps',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ]
            }
            onBeforeFileAdded={() => true}
            icon="attach-outline"
            iconSize={12}
            skipTracking
            isInputButton
          />
        </FileInputWrap>
      </Wrap>
    </FormGroup>
  )
}
