import * as RPopover from '@radix-ui/react-popover'
import * as React from 'react'

import { keyframes, styled } from '../styled'
import { animations } from './animations'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const zoomIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`

const zoomOut = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.95);
  }
`

const slideInFromTop = keyframes`
  from {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0);
  }
`

const slideInFromBottom = keyframes`
  from {
    transform: translateY(0.5rem);
  }
  to {
    transform: translateY(0);
  }
`

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-0.5rem);
  }
  to {
    transform: translateX(0);
  }
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(0.5rem);
  }
  to {
    transform: translateX(0);
  }
`

const StyledPopoverContent = styled(RPopover.Content)<{
  $dataState?: string
  $dataSide?: string
}>`
  z-index: 9999;
  border-radius: 12px;
  border-width: ${props => (props.theme.dark ? '1px' : 0)};
  overflow: hidden;
  background: ${props => (props.theme.dark ? props.theme.cardBackground : '#fff')};
  box-shadow: ${props =>
    props.theme.dark
      ? 'rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'
      : 'rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'};
  outline: none;
  animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`

/*
  animation-name: ${props => (props.$dataState === 'open' ? fadeIn : fadeOut)},
    ${props => (props.$dataState === 'open' ? zoomIn : zoomOut)},
    ${props =>
      props.$dataSide === 'top'
        ? slideInFromBottom
        : props.$dataSide === 'bottom'
        ? slideInFromTop
        : props.$dataSide === 'left'
        ? slideInFromRight
        : slideInFromLeft};
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  */

export const Popover = RPopover.Root

export const PopoverTrigger = RPopover.Trigger

export const PopoverAnchor = RPopover.Anchor

export const PopoverContent = React.forwardRef<
  React.ElementRef<typeof RPopover.Content>,
  React.ComponentPropsWithoutRef<typeof RPopover.Content> & { dataState?: string; dataSide?: string }
>(({ className, align = 'center', sideOffset = 4, dataState, dataSide, ...props }, ref) => (
  <RPopover.Portal>
    <StyledPopoverContent
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={className}
      $dataState={dataState}
      $dataSide={dataSide}
      {...props}
    />
  </RPopover.Portal>
))
