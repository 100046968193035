import { markTranslated } from 'core'
import React from 'react'

import {
  H1 as BaseH1,
  H2 as BaseH2,
  H3 as BaseH3,
  H5,
  ITextProps,
  ScrollView,
  styled,
  Text as BaseText,
  View
} from '~/components'
import { animations, breakpoint, Button, css } from '~/lite'

type UntranslatedProps<T> = Omit<T, 'text' | 'rawText' | 'raw' | 'children'> & { children?: string }

const BaseP = styled(BaseText)`
  margin: 15px 0;
`

export const H1: React.FC<UntranslatedProps<ITextProps>> = ({ children, ...props }) => (
  <BaseH1 rawText={markTranslated(children?.toString() ?? '')} {...props} />
)

export const H2: React.FC<UntranslatedProps<ITextProps>> = ({ children, ...props }) => (
  <BaseH2 rawText={markTranslated(children?.toString() ?? '')} {...props} />
)

export const H3: React.FC<UntranslatedProps<ITextProps>> = ({ children, ...props }) => (
  <BaseH3 rawText={markTranslated(children?.toString() ?? '')} {...props} />
)

export const P: React.FC<UntranslatedProps<ITextProps>> = ({ children, ...props }) => (
  <BaseP rawText={markTranslated(children?.toString() ?? '')} {...props} />
)

export const Text: React.FC<UntranslatedProps<ITextProps>> = ({ children, ...props }) => (
  <BaseText rawText={markTranslated(children?.toString() ?? '')} {...props} />
)

export const Wrap = styled(ScrollView)`
  flex: 1;
  padding: 20px 20px 0;
  background: ${props => props.theme.background};
`

export const Box = styled(View)`
  margin: 20px 0 40px 0;
  padding: 40px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  font-size: 20px;
`

export const ShareAppWrap = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const StripeClimateIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Climate</title>
    <path
      d="M33.24 21.09c-4.28 0-9.09-2.96-13.24-5.81-4.4-3.04-9.24-7.05-13.24-7.05C2.68 8.23 0 11.96 0 15.28v.28a20 20 0 1 0 40 0c-.13 2.68-2.03 5.53-6.76 5.53z"
      fill="url(#product-icon-climate-ProductNav-a)"
    ></path>
    <path
      d="M33.24 8.24c-4 0-8.84 4-13.24 7.04-4.15 2.85-8.96 5.8-13.24 5.8-4.73 0-6.63-2.84-6.76-5.52a20 20 0 1 0 40 0v-.28c0-3.32-2.67-7.05-6.76-7.04z"
      fill="url(#product-icon-climate-ProductNav-b)"
    ></path>
    <path
      d="M20 15.28c4.15 2.85 8.96 5.8 13.24 5.8 4.73 0 6.63-2.84 6.76-5.52a20 20 0 1 1-40 0c.13 2.68 2.03 5.53 6.76 5.53 4.28 0 9.09-2.96 13.24-5.81z"
      fill="url(#product-icon-climate-ProductNav-c)"
    ></path>
    <defs>
      <linearGradient
        id="product-icon-climate-ProductNav-a"
        x1="20"
        y1="20.63"
        x2="20"
        y2="9.57"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFD748"></stop>
        <stop offset=".21" stopColor="#FFD644"></stop>
        <stop offset=".33" stopColor="#FFD438"></stop>
        <stop offset=".45" stopColor="#FFD024"></stop>
        <stop offset=".57" stopColor="#FFCB09"></stop>
        <stop offset="1" stopColor="#FFC900"></stop>
      </linearGradient>
      <linearGradient
        id="product-icon-climate-ProductNav-b"
        x1="20"
        y1="9.56"
        x2="20"
        y2="21.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#009C00"></stop>
        <stop offset="1" stopColor="#00BA18"></stop>
      </linearGradient>
      <linearGradient
        id="product-icon-climate-ProductNav-c"
        x1="20"
        y1="35.28"
        x2="20"
        y2="15.28"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".13" stopColor="#00CB1B"></stop>
        <stop offset="1" stopColor="#00D924"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export const ContentWrap = styled(View)`
  background: ${props => (props.theme.dark ? '#000' : '#fff')};
  min-height: calc(100vh - 60px);

  @media ${breakpoint.md} {
    padding-bottom: 32px;
  }
`

export const backgrounds = {
  purple: 'radial-gradient(circle at 50% center, rgb(34, 3, 42) 0%, rgb(0, 0, 0) 25%)',
  blue: 'radial-gradient(circle at 50% center, rgb(9, 40, 64) 0%, rgb(0, 0, 0) 25%)',
  pink: 'radial-gradient(circle at 50% center, rgb(60, 9, 55) 0%, rgb(0, 0, 0) 25%)',
  orange: 'radial-gradient(circle at 50% center, rgb(39, 12, 3) 0%, rgb(0, 0, 0) 25%)',
  yellow: 'radial-gradient(circle at 50% center, rgb(48, 46, 6) 0%, rgb(0, 0, 0) 25%)',
  orangePink: 'radial-gradient(circle at 50% center, rgb(43, 19, 0) 0%, rgb(0, 0, 0) 25%)'
}

export const HeroWrap = styled(View)`
  background: ${props => (props.theme.dark ? backgrounds.purple : 'transparent')};
`

export const HighlightedButton = styled(Button)`
  border: ${props => (props.theme.dark ? '2px solid rgba(255, 255, 255, 0.1)' : 'none')};
  box-shadow: 0 0 5px rgba(${props => props.theme.backgroundRgb}, 1);
`

export const Section = styled.section`
  padding-top: 32px;
`

export const SectionContent = styled(View)<{ $allowWide?: boolean }>`
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px;
  justify-content: start;
  align-items: stretch;

  @media ${breakpoint.xl} {
    width: ${props => (props.$allowWide ? '1100px' : '920px')};
  }
`

const titleStyles = css`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;

  ::selection {
    -webkit-text-fill-color: #fff;
  }
`

export const Title = styled(BaseH1)`
  ${titleStyles}

  @media ${breakpoint.md} {
    font-size: 60px;
  }
`

export const Subtitle = styled(BaseH2)`
  text-align: center;
  text-wrap: balance;
  color: ${props => (props.theme.dark ? '#b4bcd0' : props.theme.text)};
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;

  @media ${breakpoint.md} {
    margin-bottom: 32px;
  }
`

export const HeaderTag = styled(BaseText)`
  display: flex;
  margin: 32px auto 0;
  backdrop-filter: blur(12px);
  font-size: 13px;
  font-weight: 500;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.border)};
  border-radius: 50px;
  animation: 1000ms ease 0s both ${animations.fadeInDown};

  @media ${breakpoint.md} {
    margin-top: 64px;
  }
`

export const HeaderTitle = styled(Title)`
  margin-top: 32px;
  animation: 1000ms ease 0s both ${animations.fadeInDown};

  @media ${breakpoint.md} {
    margin-top: 64px;

    ${HeaderTag} + & {
      margin-top: 32px;
    }
  }
`

export const HeaderSubtitle = styled(Subtitle)`
  margin-bottom: 48px;
  animation: 1200ms ease 400ms both ${animations.fadeInDown};
`

export const ActionButton = styled(HighlightedButton)<{ $enter: boolean }>`
  animation: 1200ms ease 800ms both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};
`

export const ActionContainer = styled(View)<{ $enter: boolean }>`
  animation: 1200ms ease 800ms both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};
`

export const SecondaryActionButton = styled(Button)<{ $enter: boolean }>`
  margin-left: 8px;
  background: transparent;
  border: ${props => (props.theme.dark ? '0.75px solid rgba(255, 255, 255, 0.3)' : `1px solid ${props.theme.border}`)};
  animation: 1200ms ease 800ms both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};

  &:hover {
    background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : props.theme.buttonBackground)};
  }
`

export const SectionHeaderTag = styled(H5)`
  padding: 6px 16px;
  margin: 0 auto 24px auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.border)};
  border-radius: 20px;
  font-size: 12px;
`

export const SectionTitle = styled(Title)`
  margin-top: 32px;

  @media ${breakpoint.md} {
    margin-top: 64px;
  }
`

export const CardTitle = styled(BaseH1)`
  ${titleStyles}
`
