import React, { Suspense, useState } from 'react'

import { useOnHydrate } from '~/lite'

import type { DatePickerProps } from './types'

const LazyDatePicker = React.lazy(() => import('./DatePicker').then(module => ({ default: module.DatePicker })))

export const DatePicker: React.FC<DatePickerProps> = props => {
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <LazyDatePicker {...props} />
    </Suspense>
  )
}
