import { INotification } from 'core'
import { create } from 'zustand'

interface INotificationStoreState {
  notificationBadgeCount: number
  notifications: INotification[]
  setNotificationBadgeCount(count: number): void
  addNotification(notification: INotification): void
}

export const useNotificationStore = create<INotificationStoreState>(set => ({
  notificationBadgeCount: 0,
  notifications: [],
  setNotificationBadgeCount: count => set(() => ({ notificationBadgeCount: count })),
  addNotification: notification => set(state => ({ notifications: [...state.notifications, notification] }))
}))
