import { IScreenerContent } from 'core'
import React from 'react'

import { breakpoint, H3, H4, styled, View } from '~/lite'

import { Arrow } from '../Arrow'

const icons: Record<string, (size: number) => React.ReactNode> = {
  'brain-circuit': size => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08 2.5 2.5 0 0 0 4.91.05L12 20V4.5Z" />
      <path d="M16 8V5c0-1.1.9-2 2-2" />
      <path d="M12 13h4" />
      <path d="M12 18h6a2 2 0 0 1 2 2v1" />
      <path d="M12 8h8" />
      <path d="M20.5 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M16.5 13a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M20.5 21a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path d="M18.5 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
    </svg>
  ),
  'file-up': size => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
      <polyline points="14 2 14 8 20 8" />
      <path d="M12 12v6" />
      <path d="m15 15-3-3-3 3" />
    </svg>
  ),
  'user-check': size => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <polyline points="16 11 18 13 22 9" />
    </svg>
  )
}

const Wrap = styled(View)`
  background: ${props => (props.theme.dark ? 'transparent' : '#f6f9fc')};
  align-items: center;
  padding: 32px 0;
  margin-top: 32px;

  @media ${breakpoint.md} {
    padding-top: 0;
    margin-top: 100px;
  }
`

const Inner = styled(View)`
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px;
  justify-content: start;
  align-items: stretch;

  @media ${breakpoint.lg} {
    width: auto;
    max-width: calc(100% - 336px);
    padding-left: 0;
    padding-right: 0;
  }
`

const Steps = styled(View)`
  margin: 0 8px;
  border-radius: 12px;
  background: ${props => props.theme.cardBackground};
  border-top: ${props => (props.theme.dark ? 'none' : `10px solid ${props.theme.primary}`)};
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);

  @media ${breakpoint.md} {
    border: 2px solid ${props => props.theme.border};
    margin-top: -50px;
    flex-direction: row;
    box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1);
  }

  @media ${breakpoint.lg} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Step = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-top-width: 1px;

  &:first-child {
    border-top-width: 0;
  }

  @media ${breakpoint.md} {
    border-top-width: 0;
    max-width: calc((100vw - 172px) / 3);
  }

  @media ${breakpoint.lg} {
    max-width: calc((100vw - 336px - 108px) / 3);
  }
`

const StepIcon = styled(View)`
  color: ${props => props.theme.primary};

  @media ${breakpoint.md} {
    color: ${props => props.theme.text};
  }
`

const StepHeader = styled(H3)`
  font-weight: bold;
  font-size: 18px;
`

const StepSubheader = styled(H4)`
  font-weight: 500;
`

const ArrowWrap = styled(View)`
  display: none;
  width: 70px;
  justify-content: center;
  align-items: center;

  @media ${breakpoint.md} {
    display: flex;
  }
`

interface IExplainerProps {
  data: IScreenerContent['explainer']
}

export const Explainer: React.FC<IExplainerProps> = ({ data }) => {
  return (
    <Wrap>
      <Inner>
        <Steps>
          {data.steps.map(({ header, subheader, icon }, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <ArrowWrap>
                  {index % 2 ? (
                    <Arrow variant="straight" rotate={-20} scale={2.5} mirrorY />
                  ) : (
                    <Arrow variant="straight" rotate={20} scale={2.5} />
                  )}
                </ArrowWrap>
              )}
              <Step>
                {!!icons[icon] && <StepIcon>{icons[icon](44)}</StepIcon>}
                <View flex={1}>
                  <StepHeader rawText={header} />
                  <StepSubheader rawText={subheader} />
                </View>
              </Step>
            </React.Fragment>
          ))}
        </Steps>
      </Inner>
    </Wrap>
  )
}
