import { IMarketProfile } from 'core'
import React from 'react'

import { breakpoint, Icon, styled, Text, View } from '~/lite'

import { SectionHeader, SectionSubheader } from './styles'

const Wrap = styled(View)`
  flex: 1;
  width: 100%;
  margin-top: 16px;
  overflow-x: auto;
  background: ${props => (props.theme.dark ? 'transparent' : '#f6f9fc00')};

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.lg} {
    margin-top: 32px;
    padding: 32px 0;
  }
`

const TestimonialsWrap = styled(View)`
  gap: 16px;
`

const Testimonial = styled(View)`
  position: relative;
  min-width: 350px;
  margin: 8px 8px 0;
  padding: 32px;
  border-radius: 12px;
  border-width: 1px;
  background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
  backdrop-filter: blur(20px);
  transition: transform 200ms ease;

  @media ${breakpoint.md} {
    margin: 0 auto;
    max-width: 700px;
  }
`

const Quote = styled(Text)`
  font-size: 20px;
  margin-bottom: 16px;
`

const Name = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`

const Headline = styled(Text)`
  font-size: 16px;
  opacity: 0.8;
`

const Stars = styled(View)`
  flex-direction: row;
  gap: 4px;
  margin-top: 8px;
`

interface ITestimonialsProps {
  profile: IMarketProfile
}

export const Testimonials: React.FC<ITestimonialsProps> = ({ profile }) => {
  if (!profile.metadata.testimonials) {
    return null
  }

  const { header, subheader, items } = profile.metadata.testimonials

  return (
    <Wrap id="testimonials">
      {!!header && <SectionHeader rawText={header} />}
      {!!subheader && <SectionSubheader rawText={subheader} />}
      <TestimonialsWrap>
        {items.map((testimonial, idx) => {
          const { quote, name, headline } = testimonial

          if (!quote && !name && !headline) {
            return null
          }

          return (
            <Testimonial key={idx}>
              <Quote rawText={quote} />
              <Name rawText={name} />
              <Headline rawText={headline} />
              <Stars>
                {[1, 2, 3, 4, 5].map(key => (
                  <Icon key={key} icon="star" color="#facc14" />
                ))}
              </Stars>
            </Testimonial>
          )
        })}
      </TestimonialsWrap>
    </Wrap>
  )
}
