import { INewTeam, ITeam, markTranslated } from 'core'
import React, { useCallback } from 'react'

import { css, InfoList, styled, Text, View } from '~/components'
import { useStore } from '~/hooks'
import { Link, TeamAvatar } from '~/lite'
import { isWeb } from '~/util'

import { HtmlRenderer } from './HtmlRenderer'

const isExistingTeam = (team: ITeam | INewTeam): team is ITeam => 'id' in team

const StyledTeamAvatar = styled(TeamAvatar)`
  margin: 0 15px 0 0;
`

const Placeholder = styled(Text)`
  background: ${props => props.theme.placeholder};
  color: transparent;
  border-radius: ${props => props.theme.borderRadius};
  ${isWeb &&
  css`
    user-select: none;
  `}
  box-decoration-break: clone;
`

const placeholder: { [field: string]: string } = {
  name: 'Your Team',
  logo: 'logo',
  username: 'yourusername' as INewTeam['username'],
  about:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc neque leo, sollicitudin rhoncus luctus a, venenatis sit amet est. Morbi nec mollis erat. Nulla eu ullamcorper libero.',
  location: 'Your Location',
  website: 'Your Website'
}

const addHttpToLink = (link: string) => (link && !link.startsWith('http') ? `//${link}` : link)

export interface ITeamCardProps {
  team: ITeam | INewTeam
  logo?: boolean
  header?: boolean
  placeholders?: boolean
}

export const TeamCard: React.FC<ITeamCardProps> = ({ team, header = true, logo = true, placeholders = false }) => {
  const theme = useStore(state => state.theme)
  const getValue = useCallback(
    <T extends ITeam | INewTeam, F extends keyof (ITeam | INewTeam)>(team: T, field: F) => {
      return team[field] || (placeholders ? <Placeholder rawText={markTranslated(placeholder[field])} /> : null)
    },
    [placeholders]
  )

  const renderAboutInfo = useCallback(() => {
    const aboutValue = getValue(team, 'about')
    if (!aboutValue) {
      return
    }

    if (isExistingTeam(team) && team.about) {
      return <HtmlRenderer html={team.about} baseMaxWidth={350} />
    }

    return aboutValue
  }, [getValue, team])

  return (
    <View padding="m" width={1}>
      {header ? (
        <View display="flex" flexDirection="row" alignItems="center" marginBottom="m">
          {logo && <StyledTeamAvatar team={team} size="small" skeleton={placeholders} />}
          <Text fontSize={24} fontWeight="bold" testID="user-profile-name" rawText={team.name} />
        </View>
      ) : null}

      {team.about ? (
        <View marginBottom="m" testID="team-detail-about">
          <View marginBottom="xs" color={theme.text} opacity={0.6}>
            <Text text="About" fontWeight={500} fontSize="12px" lineHeight="14px" />
          </View>
          <View opacity={0.87} fontSize={18}>
            {renderAboutInfo()}
          </View>
        </View>
      ) : null}
      <InfoList
        testID="team-detail-info-list"
        skeleton={placeholders}
        items={[
          { label: 'Location', value: team.location },
          {
            label: 'Website',
            component: team.website ? (
              <Link rawText={team.website} href={addHttpToLink(team.website)} hrefAttrs={{ target: '_blank' }} />
            ) : null
          }
        ]}
      />
    </View>
  )
}
