import { ILocation } from '../models'
import { Endpoint, get } from '../util/api'
import { buildQueryString } from '../util/helpers'
import { ILocationSearchQuery } from '../util/query'

export type GetUserLocations = Endpoint<
  Record<string, never>,
  {
    success: true
    userLocations: ILocation[]
  }
>

export type GetNearbyLocations = Endpoint<
  Record<string, never>,
  {
    success: true
    nearbyLocations: ILocation[]
  }
>

export const getUserLocations = (query: ILocationSearchQuery) =>
  get<GetUserLocations>(`/locations/user${buildQueryString(query)}`)

export const getNearbyLocations = (query: ILocationSearchQuery) =>
  get<GetNearbyLocations>(`/locations/nearby${buildQueryString(query)}`)
