import { encodeToken, TranslatedText } from 'core'
import React, { useCallback, useMemo } from 'react'

import { Image, Svg } from '~/components'
import { useHistory, useTranslation } from '~/hooks'
import { H2, trackEvent } from '~/lite'
import { getCollectionIcon, isWeb } from '~/util'

import { Category, Children, Header, Icon, StyledAnimatedView, StyledPressable, Wrap } from './styles'
import { ICollectionCardProps } from './types'

export const collectionCardMinWidth = 160
const collectionCardMinHeight = 220
export const collectionCardAspectRatio = collectionCardMinWidth / collectionCardMinHeight
const collectionCardMaxScale = 1.1

export const CollectionCard: React.FC<ICollectionCardProps> = React.memo(
  ({ collection, onPress, isChild, testID, backgroundColor }) => {
    const t = useTranslation()
    const history = useHistory()

    const onPressHandler = useCallback(() => {
      const url = collection.url ? collection.url : `/collections/${collection.id}`
      trackEvent('Select', { item: 'Collection', collectionId: collection.id, url })
      collection.url ? history.push(collection.url) : history.push(`/collections/${encodeToken(collection.id)}`)
      onPress?.(collection)
    }, [history, onPress, collection])

    const style = useMemo(
      () => ({
        wrap: {
          backgroundColor: collection.style?.backgroundColor || backgroundColor,
          background: isWeb ? collection.style?.background : backgroundColor,
          color: collection.style?.color
        },
        icon: collection.style?.padding ? { padding: collection.style.padding } : {},
        iconInner: {
          // backgroundImage: `url(${getCollectionIcon(collection)})`
          // ...(collection.style?.filter ? { filter: collection.style.filter } : {})
        }
      }),
      [collection, backgroundColor]
    )

    const uri = collection.icon ? getCollectionIcon(collection) : null

    const titleElement = (
      <Header
        to={collection.url ? collection.url : `/collections/${encodeToken(collection.id)}`}
        rawText={collection.name}
        onPress={onPressHandler}
        $color={collection.style?.color}
      />
    ) as unknown as TranslatedText

    return (
      <StyledPressable testID={testID}>
        <StyledAnimatedView animation="ScaleOnHover">
          <Wrap style={style.wrap}>
            {collection.image && (
              <Image
                source={collection.image}
                width={collectionCardMinWidth * collectionCardMaxScale}
                height={collectionCardMinHeight * collectionCardMaxScale}
              />
            )}
            {!isChild && (
              <>
                {(!!collection.category || collection.isPrivate) && collection.isPrivate ? (
                  <Category text="Collection" $color={collection.style?.color} />
                ) : (
                  <Category rawText={collection.category ?? t('')} $color={collection.style?.color} />
                )}
                <H2 rawText={titleElement} fontSize={13} fontWeight={600} />
              </>
            )}
            {uri && (
              <Icon style={style.icon}>
                <Svg uri={uri} width={isChild ? 100 : '100%'} height={isChild ? 100 : '80%'} />
              </Icon>
            )}
            {collection.children && (
              <Children>
                {collection.children.map((childCollection, idx) => (
                  <CollectionCard key={idx} collection={childCollection} onPress={onPress} isChild />
                ))}
              </Children>
            )}
          </Wrap>
        </StyledAnimatedView>
      </StyledPressable>
    )
  }
)
