import React from 'react'

import { Carousel } from '~/components'
import { Button, H2, View } from '~/lite'

import { CompanyCard } from '../CompanyCard'
import { CompanyCardSkeleton } from '../CompanyCardSkeleton'
import type { ICompanySectionProps } from './CompanySection.native'

export const CompanySection: React.FC<ICompanySectionProps> = ({ title, seeAllPath, data, isLoading }) => {
  if (!data?.length && !isLoading) {
    return null
  }

  return (
    <View className="section company-section">
      <View flexDirection="row" alignItems="center" justifyContent="space-between" paddingX="m">
        <H2 rawText={title} />
        {seeAllPath && <Button text="See All" to={seeAllPath} skipTracking minimal />}
      </View>
      <Carousel itemMinWidth={250}>
        {isLoading
          ? [...Array(10)].map((_, idx) => <CompanyCardSkeleton key={idx} />)
          : data?.map(item => <CompanyCard key={item.id} record={item} />)}
      </Carousel>
    </View>
  )
}
