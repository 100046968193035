import { AdvancedSearchQueryType, AdvancedSearchResult, defaultLocale } from 'core'
import { compact, uniqBy } from 'lodash'
import React, { startTransition } from 'react'

import { useAdvancedSearch, useDebounce, useOccupations } from '~/hooks'

const SEARCH_DEBOUNCE_TIMEOUT = 300

// ignore accents when searching
const normalizeString = (string: string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .trim()

export const useSearch = (options?: { enabled?: boolean }) => {
  const [enabled, setEnabled] = React.useState(false)
  const [searchTerm, _setSearchTerm] = React.useState('')
  const [searchType, setSearchType] = React.useState<AdvancedSearchQueryType>('all')
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_TIMEOUT)
  const { data, isLoading: isLoadingSearch, isInitialLoading } = useAdvancedSearch(debouncedSearchTerm, searchType, 0)
  // root level slugs are always english for matching
  const { data: occupations } = useOccupations(defaultLocale, enabled)

  // allows us to delay data requests until the input has been focused (minimize crawler requests, etc)
  if (options?.enabled && !enabled) {
    setEnabled(true)
  }

  const getLocalResults = React.useMemo(() => {
    const index: {
      term: {
        simpleName: string | null
        name: string
      }
      occupation: AdvancedSearchResult['occupations'][number]
    }[] = []

    for (const occupation of occupations ?? []) {
      index.push({
        term: {
          simpleName: occupation.simpleName ? normalizeString(occupation.simpleName) : null,
          name: normalizeString(occupation.name)
        },
        occupation: {
          id: occupation.id,
          slug: occupation.slug,
          name: occupation.name,
          simpleName: occupation.simpleName,
          icon: occupation.icon
        }
      })
    }

    return (queryInput: string): AdvancedSearchResult | null => {
      const query = normalizeString(queryInput)

      if (!query.length) {
        return null
      }

      const matches: AdvancedSearchResult['occupations'][number][] = []

      for (const item of index) {
        if (item.term.simpleName?.startsWith(query)) {
          matches.push(item.occupation)
        }
      }

      if (matches.length < 10) {
        for (const item of index) {
          if (item.term.simpleName?.includes(query)) {
            matches.push(item.occupation)
          }
        }
      }

      if (matches.length < 10) {
        for (const item of index) {
          if (item.term.name.startsWith(query)) {
            matches.push({ ...item.occupation, simpleName: null })
          }
        }
      }

      if (matches.length < 10) {
        for (const item of index) {
          if (item.term.name.includes(query)) {
            matches.push({ ...item.occupation, simpleName: null })
          }
        }
      }

      if (!matches.length) {
        return null
      }

      return {
        positions: [],
        teams: [],
        skills: [],
        occupations: matches,
        nextPage: null,
        subTitleQueries: []
      }
    }
  }, [occupations])

  const allResults = React.useMemo<AdvancedSearchResult[] | null>(() => {
    const localResults = getLocalResults(searchTerm)
    const remoteResults = compact(data?.pages.flat())

    if (!localResults) {
      return remoteResults
    }

    const occupations = uniqBy(
      [...(localResults?.occupations ?? []), ...(remoteResults[0]?.occupations ?? [])],
      occupation => occupation.simpleName ?? occupation.name
    )

    if (remoteResults.length === 0) {
      return [{ ...localResults, occupations }]
    }

    return [{ ...remoteResults[0], occupations }, ...remoteResults.slice(1)]
  }, [searchTerm, getLocalResults, data?.pages])

  const setSearchTerm = React.useCallback((value: string) => {
    startTransition(() => {
      _setSearchTerm(value)
    })
  }, [])

  return { searchTerm, setSearchTerm, searchType, setSearchType, allResults, isLoadingSearch, isInitialLoading }
}
