import React, { Suspense } from 'react'

import type { IImageProps, IOAuthProps, IWebViewProps } from './types'

const LazyImage = React.lazy(() => import('./Image').then(module => ({ default: module.Image })))

const LazyOAuth = React.lazy(() => import('./OAuth').then(module => ({ default: module.OAuth })))

const LazyWebView = React.lazy(() => import('./WebView').then(module => ({ default: module.WebView })))

export const Image: React.FC<IImageProps> = props => (
  <Suspense fallback={null}>
    <LazyImage {...props} />
  </Suspense>
)

export const OAuth: React.FC<IOAuthProps> = props => (
  <Suspense fallback={null}>
    <LazyOAuth {...props} />
  </Suspense>
)

export const WebView: React.FC<IWebViewProps> = props => (
  <Suspense fallback={null}>
    <LazyWebView {...props} />
  </Suspense>
)
