import React, { useState } from 'react'

import { useStore } from '~/lite'

import { LandingPage } from './LandingPage'
import { Launcher } from './Launcher'
import { IExploreProps } from './types'

export const Explore: React.FC<IExploreProps> = ({ countryCode, landingPageSlug }) => {
  const resumeImportState = useStore(state => state.resumeImportState)

  const [skipLauncher, setSkipLauncher] = useState(false)

  const hasImportedResume = resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success'

  if (!landingPageSlug && !hasImportedResume && !skipLauncher) {
    return <Launcher onSkipButtonPress={() => setSkipLauncher(true)} />
  }

  return <LandingPage countryCode={countryCode} landingPageSlug={landingPageSlug} />
}
