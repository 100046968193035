import { css, styled } from '~/lite'
import { isWeb } from '~/util'

import { Text } from '../Text/Text'

export const Placeholder = styled(Text)`
  ${isWeb &&
  css`
    user-select: none;
  `}
  box-decoration-break: clone;
  height: 20px;
`
