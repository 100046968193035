import { ClientRoutePath, encodeToken, JobCard } from 'core'
import React from 'react'

import { useBreakpoints, useHistory, useStore, useTranslation } from '~/lite'

import { PositionCardProps } from './types'

export const PositionCard: React.FC<PositionCardProps> = ({
  record,
  showTeam = true,
  selected,
  listItem,
  enableSaveToCollection = true,
  isFetchingJobMacros,
  linkInSameWindow,
  getJobDashboardPath,
  onPress
}) => {
  const t = useTranslation()
  const history = useHistory()
  const { isPhone } = useBreakpoints()
  const isWidget = useStore(state => state.isWidget)
  const shouldLinkToExternalSite = !!record.applicationUrl && !record.showDetailPage
  const jobLink: ClientRoutePath = getJobDashboardPath
    ? getJobDashboardPath({ positionId: record.id })
    : `/jobs/${encodeToken(record.id)}`

  // if it's our browser extension's widget, prefer opening the external site regardless of auth status.
  // want it to be an independent tool rather than something that just links back to our site.
  const href = isWidget
    ? record.applicationUrl ?? jobLink
    : shouldLinkToExternalSite
    ? record.applicationUrl
    : undefined

  const hrefTarget = isWidget
    ? '_parent'
    : (shouldLinkToExternalSite || !isPhone) && !linkInSameWindow
    ? '_blank'
    : undefined

  return (
    <JobCard
      id={record.id}
      t={t}
      record={record}
      showTeam={showTeam}
      selected={selected}
      listItem={listItem}
      enableSaveToCollection={enableSaveToCollection}
      isFetchingJobMacros={isFetchingJobMacros}
      onPress={onPress ?? (() => history.push(jobLink))}
      onTeamPress={username => history.push(`/teams/${encodeToken(username)}/jobs`)}
      href={href}
      hrefTarget={hrefTarget}
      getJobDashboardPath={getJobDashboardPath}
    />
  )

  /*
  return (
    <View position="relative">
      <PositionCardLink id={id} href={href} onClick={handleOnItemPress(id)}>
        <PositionCardWrapper>
          <FlexHorizontal
            backgroundColor={selected ? theme.primary : theme.background}
            padding={15}
            borderRadius={15}
            width="100%"
            {...rest}
          >
            {showTeam && (
              <View marginRight="s">
                <ProfileAvatar name={team.name} size={40} avatar={getTeamLogo(team.logo)} />
              </View>
            )}
            <View position="static" flex={1}>
              <View position="static" width="90%">
                <Text
                  rawText={title ?? ''}
                  onPress={handleOnItemPress(id)}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  fontWeight="bold"
                  color={selected ? '#fff' : undefined}
                />
              </View>
              {team.name ? (
                <Text
                  rawText={team.name}
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  marginTop={5}
                  fontSize={12}
                  color={selected ? '#fff' : undefined}
                />
              ) : null}
              {location ? (
                <FlexHorizontal alignItems="center" marginTop={5}>
                  <Icon icon="location" marginRight="xs" color={selected ? '#fff' : theme.primary} size={12} />
                  <Text
                    rawText={location}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    fontSize={12}
                    color={selected ? '#fff' : undefined}
                  />
                </FlexHorizontal>
              ) : null}
              <CompensationInfo data={compensation} selected={selected} />
              {summary ? (
                <Text
                  rawText={summary}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  marginTop={10}
                  fontSize={12}
                  color={selected ? '#fff' : undefined}
                />
              ) : null}
            </View>
          </FlexHorizontal>
        </PositionCardWrapper>
      </PositionCardLink>
      {enableSaveToCollection && (
        <View position="absolute" top={15} right={15}>
          <BookmarkButton positionId={id} action="Add/RemovePosition" />
        </View>
      )}
    </View>
  )
  */
}
