import { compact, flatten, uniq } from 'lodash'

import {
  calendarBookingServices,
  CallingCode,
  ExternalUrl,
  ICompensation,
  ICountry,
  IMarketProduct,
  IMarketProfile,
  IPositionPartialDetail,
  ISkill,
  PhoneNumber,
  PhoneNumberWithCallingCode
} from '../models'
import { markTranslated, SupportedLocaleCode, TranslatedText } from './i18n'

const getAmountStats = (amount: number, symbol = '$') => ({
  amount: `${symbol}${amount}`,
  isWholeThousands: amount >= 1000 && amount % 1000 === 0,
  isThousands: amount >= 1000,
  thousands: `${symbol}${amount / 1000}`.replace(/\.0+$/, '')
})

export const formatList = (locale: SupportedLocaleCode, values: TranslatedText[]): TranslatedText => {
  try {
    return markTranslated(new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' }).format(values))
  } catch {
    return markTranslated(values.join(', '))
  }
}

export const getFirstValue = (arrayString: TranslatedText): TranslatedText => markTranslated(arrayString.split('|')[0])

const formatRange = (start: number | string, end: number | string) => uniq([start, end]).join(' - ')

export const formatCompensation = (compensation: ICompensation, ifNoValue = 'Unknown'): TranslatedText => {
  const min = compensation.min ? getAmountStats(compensation.min) : null
  const max = compensation.max ? getAmountStats(compensation.max) : null
  const perYear = compensation.frequency === 'Year'
  const frequency =
    compensation.frequency && compensation.frequency !== 'Year' ? ` / ${compensation.frequency?.toLowerCase()}` : ''

  if (min && max && min.isThousands && max.isThousands && perYear) {
    return markTranslated(`${formatRange(min.thousands, max.thousands)}k`)
  }

  if (min && max && min.isThousands && max.isThousands) {
    return markTranslated(`${formatRange(min.thousands, max.thousands)}k${frequency}`)
  }

  if (min && max) {
    return markTranslated(`${formatRange(min.amount, max.amount)}${frequency}`)
  }

  if (min && min.isThousands) {
    return markTranslated(`${min.thousands}k${frequency}`)
  }

  if (max && max.isThousands) {
    return markTranslated(`Up to ${max.thousands}k${frequency}`)
  }

  if (min) {
    return markTranslated(`${min.amount}${frequency}`)
  }

  if (max) {
    return markTranslated(`Up to ${max.amount}${frequency}`)
  }

  return markTranslated(ifNoValue)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildQueryString = (params?: { [key: string]: any }, isMerge = false): string => {
  if (!params) {
    return ''
  }
  const queryString = flatten(
    Object.entries(params)
      .filter(([_, val]) => val !== null && val !== undefined)
      .map(([key, val]) => {
        if (Array.isArray(val)) {
          return val.map((itemValue, index) => [`${key}[${index}]`, itemValue].join('='))
        }
        return [key, val].join('=')
      })
  ).join('&')

  return queryString ? `${isMerge ? '&' : '?'}${queryString}` : ''
}

export const getQueryString = (url: string): string => {
  const parts = url.split('?')
  parts.shift()
  return parts.join('?')
}

const uuidRe = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i

export const isUUID = <T extends string>(string: string): string is T => uuidRe.test(string)

export const isExternalUrl = (url: string): url is ExternalUrl =>
  url.startsWith('http://') || url.startsWith('https://')

export const getPhoneNumberWithCallingCode = (
  callingCode: CallingCode,
  phoneNumber: PhoneNumber
): PhoneNumberWithCallingCode => `+${callingCode}${phoneNumber}` as PhoneNumberWithCallingCode

export const getCountryIcon = (country: ICountry): string | null =>
  country.noIcon ? null : `https://ambitioncdn.com/country-icons/${country.code.toLowerCase()}.svg`

export const getSkillIcon = (skill: Pick<ISkill, 'icon'>): string | null =>
  skill.icon && `https://ambitioncdn.com/skill-icons/${skill.icon}`

export const isExpiredPosition = (data: Pick<IPositionPartialDetail, 'remoteExpiresAt' | 'deletedAt'>): boolean =>
  ('remoteExpiresAt' in data && !!data.remoteExpiresAt && data.remoteExpiresAt <= new Date()) ||
  ('deletedAt' in data && !!data.deletedAt && data.deletedAt <= new Date())

export const compactAndJoin = (
  items: (TranslatedText | null | undefined)[],
  separator: '' | ' ' | ' - ' | ' | ' | ' · ' | ' + '
) => items.filter(item => !!item).join(separator) as TranslatedText

export const wrapInParenthesis = (text: TranslatedText) => markTranslated(`(${text})`)

const punctuationMarks = /[.,/#!$%^&*;:{}=\-_`~()]/g

export const getAvatarInitials = (recordKey: string) => {
  const string = recordKey.replace(punctuationMarks, '').trim()
  const words = string.split(' ').filter(word => word.length > 0)

  if (words.length >= 2) {
    return markTranslated(`${words[0][0]}${words[words.length - 1][0]}`.toUpperCase())
  }

  const secondInitial = string.substring(1).match(/[A-Z]/g)?.[0]

  return markTranslated(`${string[0]}${secondInitial ?? ''}`.toUpperCase())
}

export const getHash = (name: string) => {
  let hash = 0

  for (let i = 0; i < name.length; i++) {
    hash = (hash << 5) - hash + name.charCodeAt(i)
    hash = hash & hash // convert to 32bit integer
  }

  return Math.abs(hash)
}

const gradients: [string, string][] = [
  ['#FEB692', '#EA5455'],
  ['#CE9FFC', '#7367F0'],
  ['#ABDCFF', '#0396FF'],
  ['#F761A1', '#8C1BAB'],
  ['#FF6FD8', '#3813C2'],
  ['#FFF886', '#F072B6'],
  ['#97ABFF', '#123597'],
  ['#FF9D6C', '#BB4E75'],
  ['#FFCF71', '#2376DD'],
  ['#F05F57', '#360940'],
  ['#F6CEEC', '#D939CD'],
  ['#FEC163', '#DE4313'],
  ['#81FBB8', '#28C76F']
]

export const getGradient = (recordKey: string): [string, string] => gradients[getHash(recordKey) % gradients.length]

export const hoverArrow = (size = 10, marginLeft = 5) =>
  `
  <svg width="${size}" height="${size}" viewBox="0 0 10 10" aria-hidden="true" style="position: relative; top: 1px; margin-left: ${marginLeft}px; stroke-width: 2px; fill: none; stroke: currentColor;">
    <g fill-rule="evenodd">
      <path d="M0 5h7" class="hover-arrow-line"></path>
      <path d="M1 1l4 4-4 4" class="hover-arrow-tip"></path>
    </g>
  </svg>
`.trim()

export const getCoverLetterHtml = (text: string): string => `
  <html>
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        html {
          margin: 0;
          padding: 0;
        }
        body {
          margin: 0;
          padding: 1in;
          background-color: white;
          color: #000;
          font-family: 'Arial', sans-serif;
          font-size: 12pt;
          line-height: 1.5;
        }
      </style>
    </head>
    <body>
      ${text.replace(/\n/g, '<br>')}
    </body>
  </html>
`

export const affiliateCommissionRate = 0.3

export const getMarketPrice = (
  product: Pick<IMarketProduct, 'estimateMin' | 'estimateMax' | 'price' | 'currency'>,
  profile: Pick<IMarketProfile, 'hourlyRate'>
): number | null => {
  if (product.price !== null && product.currency) {
    return product.price
  }

  const minutes = compact([product.estimateMin, product.estimateMax])

  return minutes.length > 0
    ? (minutes.reduce((sum, value) => sum + value, 0) / minutes.length / 60) * profile.hourlyRate
    : null
}

export const parseCalendarBookingUrl = (
  url: string
): {
  service: NonNullable<typeof calendarBookingServices[number]['code']>
  username?: string | null
  eventType?: string | null
} | null => {
  const calendlyMatch = url.match(/calendly.com\/([^/]+)(?:\/([^/]+))?/)

  if (calendlyMatch) {
    const [, username, eventType] = calendlyMatch
    return { service: 'Calendly', username, eventType: eventType || null }
  }

  return null
}

export const getMarketProfileUpdateData = (
  profile: Pick<
    IMarketProfile,
    | 'id'
    | 'companyName'
    | 'username'
    | 'hourlyRate'
    | 'currency'
    | 'affiliateRate'
    | 'cofounderState'
    | 'cofounderNote'
    | 'calendarBookingUrl'
    | 'metadata'
  >
) => ({
  id: profile.id,
  companyName: profile.companyName,
  username: profile.username,
  hourlyRate: +(profile.hourlyRate || 0),
  currency: profile.currency,
  affiliateRate: profile.affiliateRate,
  cofounderState: profile.cofounderState,
  cofounderNote: profile.cofounderNote || null,
  calendarBookingUrl: profile.calendarBookingUrl || null,
  metadata: {
    hero: {
      header: profile.metadata?.hero?.header || null,
      subheader: profile.metadata?.hero?.subheader || null,
      cta: profile.metadata?.hero?.cta || null
    },
    deliverables: {
      header: profile.metadata?.deliverables?.header || null,
      subheader: profile.metadata?.deliverables?.subheader || null,
      items: (profile.metadata?.deliverables?.items || []).filter(item => item.name)
    },
    testimonials: {
      header: profile.metadata?.testimonials?.header || null,
      subheader: profile.metadata?.testimonials?.subheader || null,
      items: (profile.metadata?.testimonials?.items || []).filter(item => item.quote)
    },
    about: {
      header: profile.metadata?.about?.header || null,
      subheader: profile.metadata?.about?.subheader || null
    },
    plans: {
      header: profile.metadata?.plans?.header || null,
      subheader: profile.metadata?.plans?.subheader || null
    },
    products: {
      header: profile.metadata?.products?.header || null,
      subheader: profile.metadata?.products?.subheader || null
    },
    faq: {
      header: profile.metadata?.faq?.header || null,
      subheader: profile.metadata?.faq?.subheader || null,
      questions: (profile.metadata?.faq?.questions || []).filter(item => item.question || item.answer)
    }
  }
})

export type IMarketProfileUpdateData = ReturnType<typeof getMarketProfileUpdateData>

export type IMarketProfileSetupData = {
  version: 1
  compensationRate: number
  compensationCurrency: IMarketProfile['currency']
  compensationFrequency: 'Hourly' | 'Daily' | 'Weekly' | 'Monthly' | 'Yearly'
  cofounderState: IMarketProfile['cofounderState']
  cofounderNote: IMarketProfile['cofounderNote']
}
