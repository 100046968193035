import { IPosition } from 'core'
import { StateCreator } from 'zustand'

import { IStoreState, IUserSlice } from './types'

export const createUserSlice: StateCreator<IStoreState, [], [], IUserSlice> = (set, get) => ({
  stats: null,
  isLoadingStore: true,
  isLoadingUser: true,
  isAuthenticated: false,
  isWidget: false,
  isApplying: null,
  source: null,
  agentCommandId: null,
  user: null,
  currentTeam: null,
  location: null,
  currentUserId: null,
  resumeImportState: null,
  extensionState: null,
  shouldLoadMessages: false,
  subscriptions: [],
  applied: [],
  saved: [],
  ambitionPlusSubscription: null,
  hasStripeSubscription: false,
  setStats: stats => set(() => ({ stats })),
  setIsLoadingUser: isLoadingUser => set(() => ({ isLoadingUser })),
  setIsAuthenticated: isAuthenticated => set(() => ({ isAuthenticated })),
  setIsWidget: isWidget => set(() => ({ isWidget })),
  setIsApplying: isApplying =>
    set(state => ({ isApplying: state.isApplying === null && !isApplying ? state.isApplying : isApplying })),
  setCurrentTeam: currentTeam => set(() => ({ currentTeam })),
  isTeamMember: teamId => !!(teamId && get().user?.teams?.some(team => team.id === teamId)),
  setUserData: ({
    user,
    location,
    currentUserId,
    resumeImportState,
    extensionState,
    shouldLoadMessages,
    subscriptions,
    applied,
    saved,
    ambitionPlusSubscription,
    hasStripeSubscription
  }) =>
    set(() => ({
      user,
      location,
      currentUserId,
      resumeImportState,
      extensionState,
      shouldLoadMessages,
      subscriptions,
      applied,
      saved,
      ambitionPlusSubscription,
      hasStripeSubscription,
      ...(user?.locale && { locale: user.locale })
    })),

  addApplied: (positionId: IPosition['id']) =>
    set(state => ({
      applied: state.applied?.includes(positionId) ? state.applied : [...(state.applied ?? []), positionId]
    })),

  removeApplied: (positionId: IPosition['id']) =>
    set(state => ({ applied: state.applied?.filter(id => id !== positionId) ?? null })),

  addSaved: (positionId: IPosition['id']) =>
    set(state => ({
      saved: state.saved?.includes(positionId) ? state.saved : [...(state.saved ?? []), positionId]
    })),

  removeSaved: (positionId: IPosition['id']) =>
    set(state => ({ saved: state.saved?.filter(id => id !== positionId) ?? null }))
})
