import { keyBy } from 'lodash'

import {
  CompactPositionRecord,
  IAgentCommand,
  ICollection,
  ICollectionPosition,
  ICombinedPositionData,
  IComment,
  IDashboardLocation,
  IDashboardPosition,
  IDashboardTeam,
  IInterviewQuestion,
  ILocation,
  INewPosition,
  IPosition,
  IPositionPartialDetail,
  ITeam,
  ITeamPartial,
  PageableResponse,
  PositionFilterTeamResult,
  UserFeedSectionId
} from '../models'
import { Endpoint, fetchJSON, get, post, put, remove } from '../util/api'
import { buildQueryString } from '../util/helpers'
import { encodePositionQuery, IPositionQuery, IPositionQueryParams, JobScope } from '../util/query'
import { getTeam, getTeamByUsername } from './teams'

export const createPositionParams = ['title', 'description'] as const

export type GetPositions = Endpoint<
  IPositionQueryParams,
  {
    success: true
    positions: IPositionPartialDetail[]
    locations?: ILocation[]
    pageable: PageableResponse<IPositionPartialDetail>['pageable']
  }
>

export interface IDashboardPositionResponse {
  success: true
  positions: IDashboardPosition[]
  locations: IDashboardLocation[]
  teams: IDashboardTeam[]
}

export type GetDashboardPositions = Endpoint<IPositionQueryParams, IDashboardPositionResponse>

export type GetSocialImageForPosition = Endpoint<{ id: IPosition['id'] }, { success: true }>

export type GetPosition = Endpoint<
  { id: IPosition['id'] },
  { success: true; position: IPosition; team: ITeamPartial; location: ILocation | null }
>

export type GetPositionRawApplicationUrl = Endpoint<{ id: IPosition['id'] }, { success: true; applicationUrl: string }>

export type GetPositionDetail = Endpoint<
  { id: IPosition['id']; includeDeleted?: string },
  { success: true; data: IPositionPartialDetail }
>
export type GetPositionDetailByIDs = Endpoint<Record<string, never>, { success: true; data: IPositionPartialDetail[] }>

export type ViewPosition = Endpoint<{ id: IPosition['id'] }, { success: true; newFeedSectionId?: UserFeedSectionId }>

export type CreatePosition = Endpoint<
  Record<string, never>,
  { success: true; position?: IPosition; team?: ITeamPartial; error?: string },
  INewPosition & { teamName?: string | null }
>

export type UpdatePosition = Endpoint<Record<string, never>, { success: true }, IPosition>
export type DeletePosition = Endpoint<{ id: IPosition['id'] }, { success: true }>

export type GetPositionsLatLng = Endpoint<Record<string, never>, { success: true; positions?: IPosition[] }>

export type AddPositionToCollection = Endpoint<
  { id: ICollection['id'] },
  { success: true; collectionPosition: ICollectionPosition },
  { positionId: IPosition['id'] }
>

export type RemovePositionFromCollection = Endpoint<{ id: IPosition['id'] }, { success: true }>

export type GetCompactPositionList = Endpoint<
  Record<string, never>,
  { success: true } & PageableResponse<CompactPositionRecord>
>

export type GetRecommendedJobsBaseOnEducation = Endpoint<Record<string, never>, JobsByCategoryResponse>

export type GetRecommendedJobsBasedOnOccupation = Endpoint<Record<string, never>, JobsByCategoryResponse>

export type GetInterviewQuestions = Endpoint<
  { positionId: IPosition['id'] },
  { success: true; questions: IInterviewQuestion[] }
>

// export type GetRecommendedPosition = Endpoint<Record<string, never>, { success: true; positions: IPositionPartial[] }>

export type GetRelatedPositionList = Endpoint<
  { id: IPosition['id'] },
  { success: true } & PageableResponse<CompactPositionRecord>
>

export type GetTeamPositionList = Endpoint<
  { id: ITeam['id'] },
  { success: true } & PageableResponse<CompactPositionRecord>
>

export type GetPositionQuestion = Endpoint<
  { positionId: IPosition['id'] },
  { success: true; questions: IPosition['questions'] | null }
>

export type GetPositionComments = Endpoint<
  { id: IPosition['id'] },
  { success: true; comments: IComment[]; reactions: { likes: IComment['id'][]; dislikes: IComment['id'][] } }
>

export type GetNewPositions = Endpoint<
  Record<string, never>,
  { success: true } & PageableResponse<CompactPositionRecord>
>

export type GetTeamListForPositionFilter = Endpoint<
  IPositionQueryParams,
  { success: true; data: PageableResponse<PositionFilterTeamResult> }
>

export type GetShortPosition = Endpoint<
  Record<string, never>,
  { success: true } & PageableResponse<IPositionPartialDetail>
>

export type GetCompactShortPosition = Endpoint<
  Record<string, never>,
  { success: true } & PageableResponse<CompactPositionRecord>
>

export type GetNewJobsFromLocation = Endpoint<Record<string, never>, JobsByCategoryResponse>

export type GetTrendingJobsFromLocation = Endpoint<Record<string, never>, JobsByCategoryResponse>

export type JobsByCategoryResponse = {
  success: true
  category: string | null
} & PageableResponse<CompactPositionRecord>

export type ExplorePositionType =
  | 'trending'
  | 'recommendation'
  | 'recently-visited'
  | 'related'
  | 'education-based-jobs'
  | 'occupation-based-jobs'
  | 'new-jobs'
  | 'trending-from-location'

export const ExplorePositionTypeQueryMapping: Record<ExplorePositionType, string[]> = {
  'recently-visited': ['positions/recently-visited'],
  recommendation: ['positions/recommendation'],
  trending: ['positions/trending'],
  related: ['positions/related'],
  'education-based-jobs': ['positions/recommended-positions-based-on-education'],
  'occupation-based-jobs': ['positions/recommended-positions-based-on-occupation'],
  'new-jobs': ['positions/new-jobs'],
  'trending-from-location': ['positions/trending-from-location']
}

export const getPositions = (query: IPositionQuery) => {
  const encodedQuery = query ? encodePositionQuery(query) : null
  return get<GetPositions>(encodedQuery ? `/positions?${encodedQuery}` : '/positions')
}

export const getDashboardPositions = (query: IPositionQuery) => {
  if (Object.keys(query).length === 1 && query.countryCodes?.length) {
    const countries = query.countryCodes.map(code => code.toLowerCase()).sort()
    return fetchJSON<GetDashboardPositions>(
      `https://assets.remoteambition.com/data/dashboard/all/${countries.join('-')}.json`
    )
  }

  const encodedQuery = query ? encodePositionQuery(query) : null
  return get<GetDashboardPositions>(encodedQuery ? `/positions/dashboard?${encodedQuery}` : '/positions/dashboard')
}

export const getPosition = (id: IPosition['id'], scope?: JobScope) =>
  get<GetPosition>(`/positions/${encodeURIComponent(id)}${buildQueryString({ scope })}`)

export const getPositionDetail = (id: IPosition['id'], includeDeleted?: boolean) =>
  get<GetPositionDetail>(`/positions/detail/${encodeURIComponent(id)}${includeDeleted ? '?includeDeleted=1' : ''}`)

export const getPositionDetailByIDs = (ids: IPosition['id'][]) =>
  get<GetPositionDetailByIDs>(`/positions/details${buildQueryString({ ids })}`)

export const viewPosition = (id: IPosition['id'], agentCommandId: IAgentCommand['id'] | null) =>
  get<ViewPosition>(
    `/positions/${encodeURIComponent(id)}/view${agentCommandId ? `?ac=${encodeURIComponent(agentCommandId)}` : ''}`
  )

export const createPosition = (position: CreatePosition['body']) => post<CreatePosition>('/positions', position)

export const updatePosition = (position: UpdatePosition['body']) => {
  return put<UpdatePosition>(`/positions/${position.id}`, position)
}

export const deletePosition = (position: DeletePosition['params']) => {
  return remove<DeletePosition>(`/positions/${position.id}`, {})
}

export const addPositionToCollection = (positionId: IPosition['id'], collectionId: ICollection['id']) =>
  post<AddPositionToCollection>(`/collections/${collectionId}/positions`, { positionId })

export const removePositionFromCollection = (positionId: IPosition['id']) =>
  remove<RemovePositionFromCollection>(`/collections/positions/${positionId}`, {})

export const getPositionRelevant = (page?: number, limit?: number, latLng?: [number, number], scope?: JobScope) => {
  return get<GetCompactPositionList>(`/positions/recommendation${buildQueryString({ page, limit, latLng, scope })}`)
}

export const getPositionTrending = (pastDay?: number, page?: number, limit?: number, scope?: JobScope) => {
  return get<GetCompactPositionList>(`/positions/trending${buildQueryString({ pastDay, page, limit, scope })}`)
}

export const getNewPositions = (page?: number, limit?: number, scope?: JobScope) => {
  return get<GetNewJobsFromLocation>(`/positions/new${buildQueryString({ page, limit, scope })}`)
}

export const getTrendingJobsFromLocation = (page?: number, limit?: number, scope?: JobScope) => {
  return get<GetTrendingJobsFromLocation>(`/positions/top-trending${buildQueryString({ page, limit, scope })}`)
}

// export const getRecommendedPositions = (query: IRecommededPositionsQuery) => {
//   const encodedQuery = query ? encodePositionQuery(query) : null

//   return get<GetRecommendedPosition>(`/positions/recommended-positions${encodedQuery ? '?' + encodedQuery : ''}`)
// }

export const getRecentlyVisitedPositions = (page?: number, limit?: number) => {
  return get<GetCompactPositionList>(`/positions/recent${buildQueryString({ page, limit })}`)
}

export const getRecommendedPositionBaseOnEducation = (page?: number, limit?: number, scope?: JobScope) => {
  return get<GetRecommendedJobsBaseOnEducation>(
    `/positions/recommended-positions-based-on-education${buildQueryString({ page, limit, scope })}`
  )
}

export const getRecommendedPositionBaseOnOccupation = (page?: number, limit?: number, scope?: JobScope) => {
  return get<GetRecommendedJobsBasedOnOccupation>(
    `/positions/recommended-positions-based-on-occupation${buildQueryString({ page, limit, scope })}`
  )
}

export const getTeamListForPositionFilter = (query: IPositionQuery) => {
  const encodedQuery = query ? encodePositionQuery(query) : null
  return get<GetTeamListForPositionFilter>(
    encodedQuery ? `/positions/team/filter?${encodedQuery}` : '/positions/team/filter'
  )
}

export const getTeamByUsernameQuery = (username?: ITeam['username'] | null) => ({
  queryKey: ['teams-by-username', username],
  queryFn: async () => {
    if (!username) {
      return null
    }

    const results = username ? await getTeamByUsername(username) : null
    return results?.success ? results.team : null
  }
})

export const getTeamByIdQuery = (id?: ITeam['id'] | null) => ({
  queryKey: ['teams', id],
  queryFn: async () => {
    if (!id) {
      return null
    }

    const results = await getTeam(id)
    return results?.success ? results.team : null
  }
})

export const TEAM_POSITION_PAGE_INDEX = 0
export const TEAM_POSITION_PAGE_LIMIT = 5
export const TEAM_DETAIL_POSITION_PAGE_LIMIT = 20

export const getTeamPositionsQuery = (
  id?: ITeam['id'],
  page?: number,
  limit?: number,
  scope?: JobScope,
  source?: 'dashboard' | null
) => ({
  queryKey: ['positions/team', id, page, limit, scope, source],
  queryFn: async () => {
    if (!id) {
      return []
    }
    const results = await getTeamPositions(id, page, limit, scope, source)
    return results.success ? results.data : []
  }
})

export const RELATED_POSITION_PAGE_INDEX = 0
export const RELATED_POSITION_PAGE_LIMIT = 10
export const LANDING_PAGE_POSITION_PAGE_LIMIT = 20

export const getRelatedPositionsQuery = (
  id: IPosition['id'],
  page?: number,
  limit?: number,
  latLng?: [number, number],
  scope?: JobScope
) => ({
  queryKey: ['positions/related', id, page, limit, scope],
  queryFn: async () => {
    const results = await getRelatedPositions(id, page, limit, latLng, scope)
    return results.success ? results.data : []
  }
})

export const getRelatedPositions = (
  id: IPosition['id'],
  page?: number,
  limit?: number,
  latLng?: [number, number],
  scope?: JobScope
) => {
  return get<GetRelatedPositionList>(
    `/positions/related/${encodeURIComponent(id)}${buildQueryString({ page, limit, latLng, scope })}`
  )
}

export const getTeamPositions = (
  id: ITeam['id'],
  page?: number,
  limit?: number,
  scope?: JobScope,
  source?: 'dashboard' | null
) => {
  return get<GetTeamPositionList>(
    `/positions/team/${encodeURIComponent(id)}${buildQueryString({ page, limit, scope, source })}`
  )
}

export const getPositionQuestions = (id: IPosition['id']) => {
  return get<GetPositionQuestion>(`/positions/questions/${encodeURIComponent(id)}`)
}

export const getPositionsQuery = (query: IPositionQuery | false, jobScope?: JobScope) => ({
  queryKey: ['positions', query, jobScope],
  queryFn: async ({ pageParam = 0 }): Promise<ICombinedPositionData> => {
    const results = query ? await getPositions({ ...query, page: pageParam.toString(), scope: jobScope }) : null
    if (!results?.success) {
      return { positions: [], locationsById: {}, nextPage: null }
    }

    return {
      positions: results.positions,
      locationsById: keyBy(results.locations, 'id'),
      nextPage: results.pageable.nextPage
    }
  },
  options: { getNextPageParam: (lastPage: any) => lastPage.nextPage }
})

export const getPositionDetailQuery = (id: IPosition['id'] | null | undefined, includeDeleted?: boolean) => ({
  queryKey: includeDeleted ? ['positions-detail', id, 'include-deleted'] : ['positions-detail', id],
  queryFn: async () => {
    const results = id ? await getPositionDetail(id, includeDeleted) : null
    return results?.success ? results.data : null
  }
})

export const getPositionQuery = (id: IPosition['id'] | null | undefined) => ({
  queryKey: ['positions', id],
  queryFn: async () => {
    const results = id ? await getPosition(id) : null
    return results?.success ? { position: results.position, team: results.team, location: results.location } : null
  }
})

export const getPositionComments = (positionId: IPosition['id']) =>
  get<GetPositionComments>(`/positions/${positionId}/comments`)

export type GetShortPositions = Endpoint<
  { id: IPosition['id'] },
  { success: true } & PageableResponse<IPositionPartialDetail>
>

export const getShortPositions = (page?: number, limit?: number, scope?: JobScope) =>
  get<GetShortPositions>(`/positions/shorts${buildQueryString({ page, limit, scope })}`)

export const getCompactShortPositions = (page?: number, limit?: number, scope?: JobScope) =>
  get<GetCompactShortPosition>(`/positions/shorts/compact${buildQueryString({ page, limit, scope })}`)

export const SHORT_POSITION_PAGE_INDEX = 0
export const SHORT_POSITION_PAGE_LIMIT = 10
export const SHORT_POSITION_PAGE_INDEX_NATIVE = 1
export const SHORT_POSITION_PAGE_LIMIT_NATIVE = 5

export const getShortPositionsQuery = (page?: number, limit?: number, jobScope?: JobScope) => ({
  queryKey: ['short-positions', page, limit, jobScope],
  queryFn: async ({ pageParam = 0 }) => {
    const results = await getShortPositions(pageParam, limit, jobScope)
    return results.success ? results : null
  }
})

export const getPositionRawApplicationUrl = (id: IPosition['id']) =>
  get<GetPositionRawApplicationUrl>(`/positions/application-url/${encodeURIComponent(id)}`)

export const getInterviewQuestions = (id: IPosition['id']) =>
  get<GetInterviewQuestions>(`/positions/interview-questions/${encodeURIComponent(id)}`)
