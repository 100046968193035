import { SupportedLocaleCode } from 'core'
import { shuffle, uniq } from 'lodash'
import { useState } from 'react'
import { InstagramEmbed, LinkedInEmbed, TikTokEmbed, YouTubeEmbed } from 'react-social-media-embed'

import { animations, styled, useOnHydrate, useStore, View } from '~/lite'

/*
const proofs = [
  { source: 'TikTok', url: 'https://vt.tiktok.com/ZSNMN1g47/', product: 'Plugin' },
  { source: 'TikTok', url: 'https://vt.tiktok.com/ZSNMN1g47/' }
]
*/

interface ISocialProof {
  product: 'General' | 'Plugin'
  source: 'Instagram' | 'LinkedIn' | 'TikTok' | 'YouTube'
  url: string
  width?: number
  height?: number
  featured?: boolean
}

const proofs: { [locale in SupportedLocaleCode]?: ISocialProof[] } = {
  'en-US': [
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@zachkoerber/video/7281428491881647406',
      featured: true
    },
    {
      product: 'General',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/Cs_3JibSddR/?utm_source=ig_embed&amp;utm_campaign=loading',
      featured: true
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@techsavvyassistant/video/7232720525284396294'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@mattfarmerai/video/7234004579883568390'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@webcafeai/video/7267317059380350251'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@aiinspire/video/7236070665021918506'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@ainuggetz/video/7255449326586006826'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@rodneoai/video/7235791504093039918'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@betterlegal/video/7235047411947801902'
    },
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/CsVLrVxsCnZ/?utm_source=ig_embed&amp;utm_campaign=loading'
    },
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/Ct1zZtkAHvq/?utm_source=ig_embed&amp;utm_campaign=loading'
    }
    /*
    {
      product: 'Plugin',
      source: 'LinkedIn',
      url: 'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7065028041130811392',
      width: 504
    },
    {
      product: 'Plugin',
      source: 'LinkedIn',
      url: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7065082276652462080',
      width: 504,
      height: 1063
    },
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/p/CtjqQcQh3cN/?utm_source=ig_embed&amp;utm_campaign=loading'
    },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=lYP_26lIdcE' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=mhLlY6dUd9w' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=NGmyEIVmzvM' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=fFEvNNQVJik' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=BTxbzBAWZio' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=aQxvd6aj3ws' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/shorts/FbmKSSoTflo' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=D2dAyh4Vn88' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=3IJkbYWLhG0' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/shorts/Ql1BKwP0fXM' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/watch?v=CT0ercUvtlM' },
    { product: 'Plugin', source: 'YouTube', url: 'https://www.youtube.com/shorts/Wn3E7uPcn5c' },
    { product: 'General', source: 'YouTube', url: 'https://www.youtube.com/watch?v=XYDMwsKJL1Q' }
    */
  ],
  'es-419': [
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@antoniomartinez1980_/video/7244727583181507845'
    },
    {
      product: 'Plugin',
      source: 'TikTok',
      url: 'https://www.tiktok.com/@abxda/video/7235080898218036486'
    },
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/CtPpKciJZUH/?utm_source=ig_embed&amp;utm_campaign=loading'
    },
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/Ct0Cc-KAPK9/?utm_source=ig_embed&amp;utm_campaign=loading'
    }
  ],
  'sv-SE': [
    /*{
      product: 'General',
      source: 'LinkedIn',
      url: 'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7082636495433478144',
      width: 504,
      height: 511
    }*/
  ],
  'tr-TR': [
    {
      product: 'Plugin',
      source: 'Instagram',
      url: 'https://www.instagram.com/reel/CtegyiOKQSz/?utm_source=ig_embed&amp;utm_campaign=loading'
    }
  ]
}

const Wrap = styled(View)`
  flex-direction: row;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Inner = styled(View)`
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  gap: 16px;
`

const Item = styled(View)`
  position: relative;
  width: 325px;
  height: 620px;
  overflow: hidden;
  border-radius: 8px;
`

const Shadow = styled(View)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 160px;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}00 0%,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}ff 70%,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}ff 100%
  );
`

const Skeleton = styled(View)<{ $width: number; $height: number }>`
  width: ${props => props.$width}px;
  height: ${props => props.$height}px;
  border-radius: 8px;
  border-width: 1px;
  animation: ${props => (props.theme.dark ? animations.skeletonLoadingDark : animations.skeletonLoadingLight)} 1s
    ease-in-out infinite alternate;
`

export const SocialProof: React.FC = () => {
  const locale = useStore(state => state.locale)
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return null
  }

  const adjustedLocale = locale === 'es-ES' ? 'es-419' : locale

  const shownProofs = uniq([
    ...shuffle((proofs[adjustedLocale] ?? []).filter(proof => proof.featured)),
    ...shuffle(proofs[adjustedLocale] ?? []),
    ...shuffle((proofs['en-US'] ?? []).filter(proof => proof.featured)),
    ...shuffle(proofs['en-US'] ?? [])
  ]).slice(0, 4)

  return (
    <Wrap>
      <Inner>
        {shownProofs.map((proof, idx) => {
          const width = proof.width ?? 325
          const height = proof.height ?? 620

          const placeholder = <Skeleton $width={width} $height={height} />

          if (proof.source === 'Instagram') {
            return (
              <Item key={idx}>
                <Shadow />
                <InstagramEmbed url={proof.url} width={width} height={height} embedPlaceholder={placeholder} />
              </Item>
            )
          }

          if (proof.source === 'LinkedIn') {
            return (
              <Item key={idx}>
                <LinkedInEmbed key={idx} url={proof.url} width={width} height={height} embedPlaceholder={placeholder} />
              </Item>
            )
          }

          if (proof.source === 'TikTok') {
            return (
              <Item key={idx}>
                <Shadow />
                <TikTokEmbed url={proof.url} width={width} height={height} embedPlaceholder={placeholder} />
              </Item>
            )
          }

          if (proof.source === 'YouTube') {
            return (
              <YouTubeEmbed
                key={idx}
                url={proof.url}
                width={325}
                height={220}
                embedPlaceholder={<Skeleton $width={325} $height={220} />}
              />
            )
          }

          return null
        })}
      </Inner>
    </Wrap>
  )
}
