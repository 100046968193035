import { add, differenceInHours, formatISO, isToday as _isToday, isYesterday as _isYesterday, parseISO } from 'date-fns'

import { markTranslated, TranslatedText } from '../i18n'

export const datePlaceholder = 'MM/DD/YYYY'

export const formatToISO = (date: Date): string => formatISO(date)

export const parseFromISO = (dateString: string): Date => parseISO(dateString)

export const isToday = (date: Date) => _isToday(date)

export const isYesterday = (date: Date) => _isYesterday(date)

// @todo switch this over to date-fns w/ localization
export const formatMonthAndYear = (month: number | null | undefined, year: number | null | undefined): TranslatedText =>
  markTranslated([month, year].filter(Boolean).join('/'))

export const addHours = (date: Date, hours: number): Date => add(date, { hours })

export const getDifferenceInHours = (laterDate: Date, earlierDate: Date): number =>
  differenceInHours(laterDate, earlierDate)
