import { IExperience, INewExperience, ISkill, TranslatedText, UntranslatedText } from 'core'
import produce from 'immer'
import { keyBy } from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import {
  BorderlessButton,
  Button,
  FormGroup,
  Icon,
  IInputProps,
  Input,
  InputRef,
  IPanelProps,
  Panel,
  PanelToolbar,
  RangeSlider,
  Slider,
  styled,
  View
} from '~/components'
import { useSkills, useTranslation } from '~/hooks'
import { isWeb } from '~/util'

import { SkillList } from './SkillList'

const ExperienceList = styled(View)`
  flex: 1;
`

const SearchInput = styled(Input)`
  border-width: 0;
  background-color: ${props => props.theme.cardBackground};
`

const StyledSkillList = styled(SkillList)`
  padding: 0 15px;
  background-color: ${props => props.theme.background};
`

const Experience = styled(View)`
  padding: 20px;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.border};
`

const StyledPanelToolbar = styled(PanelToolbar)`
  background-color: ${props => props.theme.background};
`

interface IExperienceInputProps extends IPanelProps {
  experiences: Readonly<IExperience[]>
  view?: 'Skills' | 'Durations' | 'SkillsAndDurations'
  onChange(experiences: Readonly<(IExperience | INewExperience)[]>): void
  durationPanelHeader?: UntranslatedText
  minYears?: number
  maxYears?: number
  kind: IExperience['kind']
  advanced?: boolean
}

export const ExperienceInput: React.FC<IExperienceInputProps> = ({
  experiences,
  view = 'Skills',
  durationPanelHeader = 'Minimum years',
  minYears = 0,
  maxYears = 15,
  kind,
  advanced = false,
  onChange,
  onClose,
  ...panelProps
}) => {
  const t = useTranslation()
  const [showDurationsPanel, setShowDurationsPanel] = useState(false)

  const searchInput = useRef<InputRef>(null)
  const [query, setQuery] = useState('')

  const hasQuery = query.length > 0

  const onExperienceChange = useCallback(
    (experience: IExperience | INewExperience, min: number | number[], max: number | number[]) => {
      onChange(
        produce(experiences, draft => {
          const exp = draft.find(e => e === experience)
          if (typeof min !== 'number' || typeof max !== 'number') {
            return
          }
          if (exp) {
            exp.minYears = min === minYears ? null : min
            exp.maxYears = max === maxYears ? null : max
          }
        })
      )
    },
    [onChange, experiences, minYears, maxYears]
  )

  const { data: skills = [] } = useSkills()

  const skillsBySlug = useMemo(() => keyBy(skills, 'slug'), [skills])

  const selectedSkillIds = useMemo(
    () =>
      Object.keys(skillsBySlug).length ? experiences.map(experience => skillsBySlug[experience.skillSlug].id) : [],
    [skillsBySlug, experiences]
  )

  const onSearchInputChange = useCallback<NonNullable<IInputProps<string>['onChange']>>(value => {
    setQuery(value)
  }, [])

  const onSkillPress = useCallback(
    (skill: ISkill) => {
      onChange(
        experiences.some(e => e.skillSlug === skill.slug)
          ? experiences.filter(e => e.skillSlug !== skill.slug)
          : [...experiences, { skillSlug: skill.slug, minYears: null, maxYears: null, kind }]
      )

      if (hasQuery) {
        setQuery('')
        if (searchInput?.current) {
          searchInput.current.focus()
        }
      }
    },
    [onChange, experiences, kind, hasQuery]
  )

  const durationPanelProps = useMemo(
    () => (view === 'SkillsAndDurations' ? { active: showDurationsPanel, header: durationPanelHeader } : {}),
    [view, showDurationsPanel, durationPanelHeader]
  )

  const formatExperienceValue = (value: number | null, maxYears: number): TranslatedText => {
    if (value === null || value === 0) {
      return t('Any')
    }

    const rounded = Math.floor(value)

    if (rounded === maxYears) {
      return t('{{count}}+ years', { count: rounded })
    }

    return t('{{count}} years', { count: rounded })
  }

  return (
    <Panel
      {...panelProps}
      {...(selectedSkillIds.length > 0 ? { onClose: undefined } : { onClose })}
      style={isWeb ? { overflow: 'scroll' } : undefined}
      subheader={
        <SearchInput
          ref={searchInput}
          accessoryLeft={<Icon icon="search" size={14} />}
          placeholder="Search skills"
          value={query}
          onChange={onSearchInputChange}
        />
      }
      noBodyPadding
    >
      <StyledSkillList selectedSkillIds={selectedSkillIds} query={query} onSkillPress={onSkillPress} grouped />
      {['SkillsAndDurations', 'Durations'].includes(view) && (
        <Panel {...panelProps} {...durationPanelProps} noBodyPadding style={{ height: 700, top: -90 }}>
          <ExperienceList>
            {experiences.map((experience, idx) => (
              <Experience key={idx}>
                <FormGroup
                  rawLabel={(skillsBySlug[experience.skillSlug] || {}).name}
                  labelInfo={formatExperienceValue(experience.minYears, maxYears)}
                >
                  {!advanced && (
                    <Slider
                      min={minYears}
                      max={maxYears}
                      stepSize={1}
                      value={experience.minYears || minYears}
                      onChange={value => onExperienceChange(experience, value, maxYears || 15)}
                    />
                  )}
                  {advanced && (
                    <RangeSlider
                      min={minYears}
                      max={maxYears}
                      labelRenderer={value =>
                        value === 0 ? t('Any') : value === maxYears ? `${value}+` : value.toString()
                      }
                      value={[experience.minYears || minYears, experience.maxYears || maxYears]}
                      onChange={([min, max]) => onExperienceChange(experience, min, max)}
                    />
                  )}
                </FormGroup>
              </Experience>
            ))}
          </ExperienceList>
          {view === 'SkillsAndDurations' && (
            <StyledPanelToolbar>
              <Button
                width="100%"
                text="Done"
                onPress={() => setShowDurationsPanel(false)}
                primary
                large
                skipTracking
              />
            </StyledPanelToolbar>
          )}
        </Panel>
      )}
      {onClose && selectedSkillIds.length > 0 && (
        <StyledPanelToolbar>
          {view === 'SkillsAndDurations' && (
            <BorderlessButton
              text="Set minimum years"
              onPress={() => setShowDurationsPanel(true)}
              minimal
              large
              link
              skipTracking
            />
          )}
          <Button
            width={view === 'SkillsAndDurations' ? undefined : '100%'}
            text="Done"
            onPress={() => onClose && onClose()}
            primary
            large
            skipTracking
          />
        </StyledPanelToolbar>
      )}
    </Panel>
  )
}
