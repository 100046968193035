import { breakpoint, Button, css, keyframes, styled, Text, View } from '~/lite'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const AssistantButton = styled(Button)<{ $active: boolean; $processing: boolean }>`
  display: flex;
  margin-right: -16px;
  z-index: 2;
  padding: 0 8px;
  background: none;
  ${props =>
    props.$processing &&
    css`
      animation: ${rotate} 2s linear infinite;
    `}
`

export const AssistantWrap = styled(View)<{ $mini: boolean; $closed: boolean }>`
  display: flex;
  width: 100%;
  height: ${props => (props.$mini ? 190 : props.$closed ? 50 : 300)}px;
  background: ${props => props.theme.cardBackground};
  box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px;
  transition: height 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

  @media ${breakpoint.lg} {
    & {
      width: auto;
      height: 380px;
      margin: 16px;
      border-radius: 12px;
    }
  }
`

export const AssistantHeader = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  padding: 4px 16px;
  border-bottom-width: 1px;
  overflow: auto;
`

export const AssistantOnline = styled(View)`
  font-size: 18px;
  font-weight: bold;
  background: #00c853;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform-origin: center center;
  pointer-events: none;
`

export const AssistantHeaderText = styled(Text)`
  padding: 8px 8px 8px 0;
  font-size: 18px;
  font-weight: bold;
  text-wrap: nowrap;
`

export const CloseButton = styled(Button)`
  display: inherit;
  @media ${breakpoint.lg} {
    & {
      display: none;
    }
  }
`

export const AccordionWrap = styled(View)`
  max-height: 250px;
  overflow-y: auto;

  @media ${breakpoint.md} {
    max-height: none;
    overflow-y: visible;
  }
`

export const Example = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
`

export const ExampleText = styled(Text)`
  padding-left: 6px;
  font-size: 14px;
`
