import { setLocale as setCoreLocale } from 'core'
import { isEqual } from 'lodash'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { hideSplashScreen } from '../../navigation'
import { createI18nSlice } from './createI18nSlice'
import { createJobScopeSlice } from './createJobScopeSlice'
import { createPersistenceSlice, saveState, serializeState } from './createPersistenceSlice'
import { createThemeSlice } from './createThemeSlice'
import { createUserSlice } from './createUserSlice'
import { IStoreState } from './types'

export { loadSavedState } from './createPersistenceSlice'
export { getThemeValues } from './createThemeSlice'

export const useStore = create<IStoreState>()(
  subscribeWithSelector((...a) => ({
    ...createPersistenceSlice(...a),
    ...createI18nSlice(...a),
    ...createThemeSlice(...a),
    ...createUserSlice(...a),
    ...createJobScopeSlice(...a)
  }))
)

useStore.subscribe(
  state => ({ hydrated: state.hydrated, ...serializeState(state) }),
  ({ hydrated, ...savedState }) => {
    if (hydrated) {
      saveState(savedState)
    }
  },
  { equalityFn: isEqual }
)

useStore.subscribe(
  state => state.locale,
  locale => setCoreLocale(locale)
)

useStore.subscribe(
  state => state.hydrated,
  hydrated => {
    if (hydrated) {
      hideSplashScreen()
    }
  }
)

useStore.subscribe(
  state => state.themeName,
  themeName => {
    if (typeof document !== 'undefined') {
      document.body.classList.toggle('dark', themeName === 'dark')
    }
  }
)
