import { ExplorePositionType } from './api/positions'
import { Opaque } from './lib/type-fest'
import {
  CollectionSlug as CollectionSlugType,
  IChannel,
  ICollection,
  ICountry,
  IFeedSection,
  IInterview,
  IInvitation,
  ILocation,
  IMarketProfile,
  IPosition,
  IScreenerResume,
  ISkill,
  IStripeProduct,
  ITeam,
  IUser,
  LandingPageSlug as LandingPageSlugType,
  NotificationSecret as NotificationSecretType,
  Soc2018Code as Soc2018CodeType
} from './models'
import { ISitemapSection } from './sitemap'
import { TranslatedText as UnencodedTranslatedText } from './util/i18n'

export type InstantSdkView = 'general'

export type LaunchpadView =
  | 'setup'
  | 'general'
  | 'hero'
  | 'deliverables'
  | 'testimonials'
  | 'about'
  | 'faq'
  | 'plans'
  | 'products'

export type EncodedParams = Opaque<string, 'EncodedParams'>

export const encodeToken = <T extends string>(value: T) => encodeURIComponent(value) as Token<T>

export type Token<Type> = Type extends Opaque<unknown, infer Key> ? Key : never

type TranslatedText = Token<UnencodedTranslatedText>
type Params = Token<EncodedParams>
type ChannelId = Token<IChannel['id']>
type CollectionId = Token<ICollection['id']>
type CollectionSlug = Token<CollectionSlugType>
type CountryCode = Token<ICountry['code']>
type InterviewId = Token<IInterview['id']>
type InvitationId = Token<IInvitation['id']>
type LandingPageSlug = Token<LandingPageSlugType>
type LocationId = Token<ILocation['id']>
type LocationSlug = Token<ILocation['slug']>
type MarketProfileId = Token<IMarketProfile['id']>
type NotificationSecret = Token<NotificationSecretType>
type PositionId = Token<IPosition['id']>
type ScreenerResumeId = Token<IScreenerResume['id']>
type SitemapSlug = Token<ISitemapSection['slug']>
type SkillSlug = Token<ISkill['slug']>
type Soc2018Code = Token<Soc2018CodeType>
type StripeProductId = Token<IStripeProduct['id']>
type JoinJobId = 'JoinJobInternalId'
type TalentJobId = 'TalentJobId'
type TeamId = Token<ITeam['id']>
type TeamUsername = Token<ITeam['username']>
type UserUsername = Token<IUser['username']>
type UserFeedSectionId = Token<IFeedSection['sectionId']>
type UserFeedSectionTitle = Token<IFeedSection['title']>
type PositionListType = ExplorePositionType

// NOTE: all tokens need to run through the Token generic above, otherwise this union will end up accepting any string.
// if we put an opaque type into a template literal type, ts interprets the entire thing as just a string.
// so we need to extract the token string from the opaque type and use it instead, in combination with encodeToken.
export type ClientRoutePath =
  | '/'
  | `/${TeamUsername}`
  | '/affiliate'
  | '/analyze'
  | '/api'
  | '/arena'
  | '/autopilot'
  | '/screener'
  | `/collections/applied`
  | `/collections?groupSoc=${Soc2018Code}&title=${TranslatedText}`
  | `/collections/${CollectionId | CollectionSlug | SkillSlug | PositionListType}`
  | `/collections/${CollectionId | CollectionSlug | SkillSlug}/jobs/${PositionId | TalentJobId}`
  | `/collections/${Soc2018Code}-${string}-jobs`
  | `/collections/countries-${CountryCode}`
  | `/collections/locations-${LocationSlug}`
  | `/collections/teams-${TeamUsername}`
  | `/collections/related/jobs/${PositionId | TalentJobId}`
  | '/collections/new-jobs'
  | '/collections/education-based-jobs'
  | '/collections/occupation-based-jobs'
  | '/collections/trending-from-location'
  | `/jobs`
  | `/jobs?${Params}`
  | `/dashboard/teams/${TeamId}`
  | `/dashboard/teams/${TeamId}/jobs/${PositionId}`
  | `/dashboard/teams/${TeamId}/jobs/${PositionId}/applications/${ScreenerResumeId}`
  | '/github'
  | `/github?${Params}`
  | `/github/${InvitationId}`
  | `/github/${InvitationId}?${Params}`
  | '/interview'
  | '/select-language'
  | '/locations'
  | '/subscriptions'
  | '/launchpad'
  | `/launchpad/${MarketProfileId}`
  | `/launchpad/${MarketProfileId}/${LaunchpadView}`
  | '/login'
  | `/login/${NotificationSecret}`
  | '/marketplace'
  | '/messages'
  | `/messages/${ChannelId}`
  | '/phone-auth'
  | '/pipeline'
  | '/plus'
  | `/positions/${PositionListType}`
  | `/positions/related/${PositionId}`
  | '/positions/new'
  | '/positions/new-jobs'
  | '/positions/education-based-jobs'
  | '/positions/occupation-based-jobs'
  | '/positions/trending-from-location'
  | `/positions/${PositionId}/edit`
  | `/positions/${PositionId}/promote`
  | `/positions/${PositionId}/promote/${StripeProductId}`
  | `/positions/select-location`
  | '/privacy'
  | '/promote'
  | '/recover-password'
  | '/search'
  | '/select-country-code'
  | '/signup'
  | `/confirm/${NotificationSecret}`
  | '/sitemap'
  | `/sitemap/${SitemapSlug}`
  | `/sitemap/${SitemapSlug}/${number}`
  | '/studio'
  | `/teams/${TeamUsername}`
  | `/teams/${TeamUsername}/jobs`
  | '/teams/applications'
  | '/teams/new'
  | '/teams/edit'
  | '/teams/statuses'
  | `/teams/messages/${ChannelId}`
  | `/teams/profile/${UserUsername}`
  | '/terms'
  | '/user/account'
  | '/user/teams'
  | `/user/profile`
  | `/user/profile/${UserUsername}`
  | '/user/profile/edit'
  | '/user/profile/edit/company'
  | '/user/profile/edit/university'
  | '/user/delete'
  | `/jobs/${PositionId | TalentJobId | JoinJobId}`
  | `/jobs/${PositionId}/interview`
  | `/shorts/${PositionId | TalentJobId}`
  | `/shorts`
  | `/${CountryCode}/${LandingPageSlug}`
  | `/${LandingPageSlug}`
  | `/fs-${UserFeedSectionId}-${UserFeedSectionTitle}`
