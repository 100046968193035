import { TranslatedText } from 'core'
import React, { useCallback, useState } from 'react'

import { useChangelogContent } from '~/hooks'
import { Link, styled, Text, useStore, useTranslation, View } from '~/lite'
import { showLiveChat } from '~/util'

const Wrap = styled(View)`
  align-items: stretch;
  background: ${props => props.theme.cardBackground};
  padding: 16px;
  border-radius: 12px;
  box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px;

  span,
  a {
    display: inline;
  }
`

const Tag = styled(Text)`
  padding: 4px 6px;
  background: ${props => (props.theme.dark ? props.theme.inputPlaceholder : props.theme.backgroundContrast)};
  border-radius: 12px;
  font-size: 11px;
`

export const Changelog: React.FC = () => {
  const t = useTranslation()
  const { data, isLoading } = useChangelogContent()
  const { locale } = useStore()

  const [isLoadingHelp, setIsLoadingHelp] = useState(false)

  const onHelpPress = useCallback(async () => {
    setIsLoadingHelp(true)
    await showLiveChat()
    setIsLoadingHelp(false)
  }, [])

  if (isLoading || !data) {
    return null
  }

  const { header, about, items } = data.data

  return (
    <Wrap className="changelog">
      <View paddingBottom={16}>
        <Text rawText={about} />
      </View>
      {locale === 'en-US' && (
        <View display="block" borderTopWidth={1} paddingY={16}>
          {isLoadingHelp ? (
            <Text text="Loading..." />
          ) : (
            <>
              <Text rawText={"We're interested in hearing what you like and don't like! " as TranslatedText} />
              <Link href="#" onPress={onHelpPress} rawText={'Live chat' as TranslatedText} />
              <Text rawText={' with our founder or ' as TranslatedText} />
              <Link href="https://discord.gg/sJUXdWDtpX" rawText={'join our Discord' as TranslatedText} />
            </>
          )}
        </View>
      )}
      <View borderTopWidth={1} paddingTop={16} flex={1} alignItems="stretch">
        <Text rawText={header} fontWeight="bold" />
        {items.map((item, idx) => (
          <View key={idx} borderTopWidth={idx > 0 ? 1 : 0} paddingTop={idx > 0 ? 16 : 0} marginTop={16}>
            <View flexDirection="row" justifyContent="space-between" alignItems="center" paddingBottom={8}>
              <Text rawText={item.label} fontSize={16} />
              <Tag rawText={t.formatDateString(item.date as unknown as string, { month: 'short', day: 'numeric' })} />
            </View>
            <Text rawText={item.description} opacity={0.8} />
          </View>
        ))}
      </View>
    </Wrap>
  )
}
