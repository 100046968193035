import { ProfileAvatar } from '@ambition/module-shared'
import { ClientRoutePath, encodeToken, TEAM_POSITION_PAGE_INDEX, TEAM_POSITION_PAGE_LIMIT } from 'core'
import React from 'react'

import { Button, FlexHorizontal, Icon, Section, Text, View } from '~/components'
import { useStore, useTeamPositions, useTranslation } from '~/hooks'
import { getTeamLogo, Link } from '~/lite'

import { PositionSection } from '../../PositionSection'
import { COMPANY_LOGO_SIZE } from './styles'
import { CompanySectionProps } from './types'

export const CompanySection: React.FC<CompanySectionProps> = ({
  avatar,
  description,
  name,
  username,
  id: teamId,
  positionId,
  location,
  hideAnalyzeButtonOnPositionSection,
  getJobDashboardPath
}) => {
  const { theme } = useStore()
  const t = useTranslation()
  const { data: positions, isInitialLoading } = useTeamPositions(
    teamId,
    TEAM_POSITION_PAGE_INDEX,
    TEAM_POSITION_PAGE_LIMIT
  )

  const seeAllPath: ClientRoutePath | undefined = getJobDashboardPath
    ? getJobDashboardPath({ teamId })
    : username
    ? `/teams/${encodeToken(username)}/jobs`
    : undefined

  return (
    <>
      <Section title={t('About the company')} titleStyle={getJobDashboardPath ? undefined : { paddingLeft: 8 }}>
        <FlexHorizontal padding="m" backgroundColor={theme.cardBackground} borderRadius={12} borderWidth={1}>
          {seeAllPath ? (
            <View>
              <Button skipTracking minimal style={{ padding: 0 }} to={seeAllPath}>
                <ProfileAvatar name={name} size={COMPANY_LOGO_SIZE} avatar={getTeamLogo(avatar)} />
              </Button>
            </View>
          ) : null}
          <View paddingX="s" flex={1} justifyContent={location || description ? 'start' : 'center'}>
            {name && seeAllPath ? (
              <Link to={seeAllPath} rawText={name} fontWeight="bold" fontSize={18} style={{ color: theme.text }} />
            ) : null}
            {location ? (
              <FlexHorizontal alignItems="center" marginTop="xs">
                <Icon icon="location" marginRight="xs" color={theme.primary} size={12} />
                <Text rawText={location} numberOfLines={1} ellipsizeMode="tail" fontSize={12} />
              </FlexHorizontal>
            ) : null}
            {description ? <Text rawText={description} numberOfLines={4} ellipsizeMode="tail" marginTop="s" /> : null}
          </View>
        </FlexHorizontal>
      </Section>
      {(isInitialLoading || !!positions?.length) && (
        <PositionSection
          useCarouselLayout={false}
          title={name ? t('More jobs at {{teamName}}', { teamName: name }) : t('Recently posted jobs')}
          seeAllPath={seeAllPath}
          data={positions}
          isLoading={isInitialLoading}
          showTeam={false}
          hideAnalyzeButton={hideAnalyzeButtonOnPositionSection}
          getJobDashboardPath={getJobDashboardPath}
        />
      )}
    </>
  )
}
