import React, { lazy, Suspense } from 'react'

import { IMarkdownProps } from './types'

const LazyReactMarkdown = React.memo(lazy(() => import('react-markdown')))

export const Markdown: React.FC<IMarkdownProps> = props => (
  <Suspense fallback={null}>
    <LazyReactMarkdown {...props} />
  </Suspense>
)
