import { Endpoint, Errors, Validation } from 'core'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface IUseFormState<Data, FormEndpoint extends Endpoint> {
  data: Data
  errors: FormEndpoint['errors']
  updateField<FieldName extends keyof Data>(field: FieldName, value: Data[FieldName]): void
  submitted: boolean
  submitProps: { disabled: boolean; loading: boolean; onPress(): Promise<void> }
  resetProps: { onPress(): void }
}

/**
 * Standardizes and simplifies the form submission process.
 *
 * @usage const { data, errors, updateField, submitProps, resetProps } = useForm<Data, FormEndpoint>(validate, submit, onSubmit)
 *
 * @param validate A function to validate form data before submission
 * @param submit A function to submit form data to the backend
 * @param onSubmit A function to call when the form has been submitted and received a response
 */
export const useForm = <Data extends {}, FormEndpoint extends Endpoint>(
  initialState: Data,
  validate: (data: Data) => Promise<Validation<Data>>,
  submit: (data: Data) => Promise<FormEndpoint['success'] | FormEndpoint['failure']>,
  onSubmit: (response: FormEndpoint['success']) => void,
  aditionalData?: Record<string, any>
): Omit<IUseFormState<Data, FormEndpoint>, 'submitted'> => {
  type State = IUseFormState<Data, FormEndpoint>
  const [data, setData] = useState<State['data']>(initialState)
  const [errors, setErrors] = useState<State['errors']>({})
  const [showErrors, setShowErrors] = useState(false)

  const [validating, setValidating] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState<State['submitted']>(false)

  const updateField: State['updateField'] = useCallback(
    <T extends keyof Data>(field: T, value: Data[T]) => setData(data => ({ ...data, [field]: value })),
    []
  )

  const validateData = useCallback(async () => {
    setValidating(true)
    const validation = await validate(data)
    setErrors('errors' in validation ? validation.errors : {})
    setValidating(false)

    return validation
  }, [validate, data])

  useEffect(() => {
    if (showErrors) {
      validateData()
    }
  }, [showErrors, validateData])

  const onSubmitButtonPress = useCallback(async () => {
    setShowErrors(true)
    setSubmitting(true)

    const validation = await validateData()

    if (validation.success) {
      const submission = await submit({ ...validation.data, ...aditionalData })

      setErrors(submission.errors || {})

      if (submission.success) {
        setSubmitted(true)
        onSubmit(submission)
      }
    }

    setSubmitting(false)
  }, [aditionalData, validateData, submit, onSubmit])

  const onResetButtonPress = useCallback(() => {
    setData({} as Data)
    setErrors({} as Errors<Data>)
    setShowErrors(false)
    setValidating(false)
    setSubmitting(false)
    setSubmitted(false)
  }, [])

  const submitProps: State['submitProps'] = useMemo(
    () => ({
      disabled: validating || submitting || submitted,
      loading: submitting,
      onPress: onSubmitButtonPress
    }),
    [validating, submitting, submitted, onSubmitButtonPress]
  )

  const resetProps: State['resetProps'] = useMemo(() => ({ onPress: onResetButtonPress }), [onResetButtonPress])

  return useMemo(
    () => ({
      data,
      errors: showErrors ? errors : ({} as Errors<Data>),
      updateField,
      submitProps,
      resetProps
    }),
    [data, showErrors, errors, updateField, submitProps, resetProps]
  )
}
