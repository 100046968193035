import { appEnv, Platform } from './env'
import { IPlatform } from './env.shared'

const { appVersion, buildVersion, mode, sentryRelease, stagingFrontendToken, frontendUrl, apiServerUrl } = appEnv

export { appVersion, buildVersion }

export const isProduction = mode === 'production'

export const isStaging = mode === 'staging'

// native dev build is hard-coded to v1.0.0, so we can target it that way
// this constant is useful if a dev wants to point the app to staging (isStaging is true),
// but we still want to treat it like it's in dev for things like skipping error logging, etc
export const isDevelopmentBuild = !!appVersion && appVersion === '1.0.0'

if (isProduction && process.env.NODE_ENV !== 'production') {
  throw new Error('Environment variable NODE_ENV must be "production" in production')
}

type Mode = 'development' | 'staging' | 'production'

interface IConstants {
  frontendUrl: string
  appleServicesKeyId: string
  appleServicesPrivateKey: string
  stagingFrontendToken?: string
  apiServerUrl: string
  gaMeasurementId: string
  gitHubClientId: string
  facebookAppId: string
  googleMapsApiKey: string
  logRocketAppId: string
  mixpanelToken: string
  pusherKey: string
  pusherCluster: string
  oneSignalAppId: string
  sentryDsn: string
  sentryRelease: string | undefined
  sentryEnvironment: Mode
  slackClientId: string
  stripePublishableKey: string
  googleOAuthWebClientId: string
  googleOAuthIosClientId: string
  assetServerUrl: string
}

const nonProduction: Omit<
  IConstants,
  | 'apiServerUrl'
  | 'frontendUrl'
  | 'gitHubClientId'
  | 'logRocketAppId'
  | 'mixpanelToken'
  | 'pusherCluster'
  | 'pusherKey'
  | 'sentryDsn'
  | 'sentryEnvironment'
  | 'slackClientId'
  | 'assetServerUrl'
> = {
  gaMeasurementId: '',
  googleMapsApiKey: 'AIzaSyC3sVAZjQYcpVdtBnrhwqjVGu8wnIixexo',
  oneSignalAppId: '60df72de-dece-49ee-875d-543047bb1e2a',
  sentryRelease: '',
  appleServicesKeyId: 'com.remoteambition.web',
  appleServicesPrivateKey: '',
  facebookAppId: '556986626066475',
  stripePublishableKey:
    'pk_test_51I4pvnGvUBE6gqURCgdI0p8Hwl7lhc7VWMT8s2nqy7kvdKsOcE4IFU1M2XzH9e1LAOqT7L0BchlCwlF6RWUbE60300GDMPT8Go',
  googleOAuthWebClientId: '925854404440-brkiijlt8mb32u8q51rfdp127gpbbeh1.apps.googleusercontent.com',
  googleOAuthIosClientId: '925854404440-3h382tt9o0j27e5h8cekte929aonjvvb.apps.googleusercontent.com'
}

const development: IConstants = {
  ...nonProduction,
  apiServerUrl,
  frontendUrl,
  gitHubClientId: '8ffd384edf141d207ec7',
  logRocketAppId: 'saudwa/development-5hkpo',
  mixpanelToken: '55ce999ad8fc5079d6461c6a429ea82f',
  pusherCluster: 'mt1',
  pusherKey: 'fcb2e36a11bd6af5bd3d',
  sentryDsn: '',
  sentryEnvironment: 'development',
  slackClientId: '',
  stagingFrontendToken,
  assetServerUrl: 'https://ambitioncdn.com'
}

const staging: Omit<IConstants, 'sentryDsn'> = {
  ...nonProduction,
  apiServerUrl,
  frontendUrl,
  gitHubClientId: '8ffd384edf141d207ec7',
  logRocketAppId: 'saudwa/staging-pkvt3',
  mixpanelToken: '758c457e47f063b0bb4f430ea2a22acd',
  pusherCluster: 'mt1',
  pusherKey: '0242b793d913c6642b3f',
  sentryEnvironment: 'staging',
  sentryRelease,
  slackClientId: '1562249210085.1623175635780',
  stagingFrontendToken,
  assetServerUrl: 'https://ambitioncdn.com'
}

const stagingAndroid: IConstants = {
  ...staging,
  sentryDsn: 'https://851c5d4e69ad4b23819da40540f518a3@o489790.ingest.sentry.io/6008473'
}

const stagingIOS: IConstants = {
  ...staging,
  sentryDsn: 'https://851c5d4e69ad4b23819da40540f518a3@o489790.ingest.sentry.io/6008473'
}

const stagingWeb: IConstants = {
  ...staging,
  sentryDsn: 'https://5bca7b2a918b4019b59f84a8b6c50127@o489790.ingest.sentry.io/6007275'
}

const production: Omit<IConstants, 'googleMapsApiKey' | 'sentryDsn'> = {
  apiServerUrl,
  appleServicesKeyId: 'com.remoteambition.web',
  appleServicesPrivateKey: '',
  frontendUrl,
  gaMeasurementId: 'G-4TJTKQSN84',
  gitHubClientId: 'c58bdf92b612553493b2',
  logRocketAppId: 'saudwa/production',
  mixpanelToken: '8413374b0d6cb7b75f7bc27eae807e68',
  oneSignalAppId: '95062f31-c2e3-4fd0-bf4e-f69b05e48baa',
  pusherCluster: 'mt1',
  pusherKey: '96d44e4112fe53538efe',
  sentryEnvironment: 'production',
  sentryRelease,
  facebookAppId: '556986626066475',
  slackClientId: '1562249210085.1623175635780',
  stripePublishableKey:
    'pk_live_51I4pvnGvUBE6gqURTG2OZWbuiPORCNZNVZfYPtgekNHevBq01Gnyc1C01JYUqB9AHPjQX8iTTVozCcM6KOM44p2H00NSpqxm5g',
  googleOAuthWebClientId: '925854404440-brkiijlt8mb32u8q51rfdp127gpbbeh1.apps.googleusercontent.com',
  googleOAuthIosClientId: '925854404440-3h382tt9o0j27e5h8cekte929aonjvvb.apps.googleusercontent.com',
  assetServerUrl: 'https://ambitioncdn.com'
}

const productionAndroid: IConstants = {
  ...production,
  googleMapsApiKey: 'AIzaSyCBBIqkqC9rhICYBrlqJXpFO_Q9Tu1Lzpo',
  sentryDsn: 'https://851c5d4e69ad4b23819da40540f518a3@o489790.ingest.sentry.io/6008473'
}

const productionIOS: IConstants = {
  ...production,
  googleMapsApiKey: 'AIzaSyDdzIT6LQC2VP3OthNiDz8fNA8rsIpr-Ek',
  sentryDsn: 'https://851c5d4e69ad4b23819da40540f518a3@o489790.ingest.sentry.io/6008473'
}

const productionWeb: IConstants = {
  ...production,
  googleMapsApiKey: 'AIzaSyD5eN_1Xlik4M86qSTjjsXXJjjZ3F9ZOCU',
  sentryDsn: 'https://5bca7b2a918b4019b59f84a8b6c50127@o489790.ingest.sentry.io/6007275'
}

const constantsByPlatform: { [mode in Mode]: { [os in IPlatform['OS']]: IConstants } } = {
  development: {
    android: development,
    ios: development,
    macos: development,
    web: development,
    windows: development
  },
  staging: {
    android: stagingAndroid,
    ios: stagingIOS,
    macos: stagingIOS,
    web: stagingWeb,
    windows: stagingAndroid
  },
  production: {
    android: productionAndroid,
    ios: productionIOS,
    macos: productionIOS,
    web: productionWeb,
    windows: productionAndroid
  }
}

export const constants: IConstants = constantsByPlatform[mode][Platform.OS]
