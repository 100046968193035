import React, { useEffect } from 'react'

import { useApiContent, useUserSubscriptionBenefit } from '~/hooks'
import { Loader, MetaTags } from '~/lite'

import { Faq } from '../Faq'
import { Features } from '../Features'
import { Pricing } from '../Pricing'
import { ContentWrap } from '../styles'
import { Hero } from './Hero'

export const Api: React.FC = () => {
  const { data, isLoading } = useApiContent()
  const { data: ambitionPlusSubscription, isLoading: isLoadingSubscription } = useUserSubscriptionBenefit('jobs-api')

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoading || isLoadingSubscription) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { metaTags, hero, features, pricing, faq } = data.data

  return (
    <ContentWrap>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/plus" />
      <Hero data={hero} />
      <Features data={features} />
      <Pricing
        data={pricing}
        currentProductId={ambitionPlusSubscription?.metadata.stripeProductId || null}
        selectPlanText="Subscribe"
      />
      <Faq data={faq} />
    </ContentWrap>
  )
}
