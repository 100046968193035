import React, { forwardRef, useCallback } from 'react'

import { useHistory } from '../navigation'
import { IPressableProps } from './types'
import { View } from './View'

export const Pressable = forwardRef<typeof View, IPressableProps>(
  ({ to, onPress, style, children, disabled, ...props }, forwardedRef) => {
    const history = useHistory()

    const onPressHandler = useCallback(() => {
      if (disabled) {
        return
      }

      onPress?.()

      if (to) {
        to === 'goBack' ? history.goBack() : history.push(to)
      }
    }, [onPress, to, history, disabled])

    return (
      <View ref={forwardedRef as any} {...props} to={to} style={style} onPress={disabled ? undefined : onPressHandler}>
        {children}
      </View>
    )
  }
)
