import type { ColumnState } from 'ag-grid-community'
import type { ClientRoutePath, ISavedState, Opaque } from 'core'

export type GridSavedStateId = Opaque<string, 'GridSavedStateId'>

export interface IStorage {
  authRedirect: ClientRoutePath
  grid: Record<GridSavedStateId, { columns: ColumnState[] }>
  state: ISavedState
}

// using this to ensure that all keys are present in storageKeys
const keyMap: Record<keyof IStorage, boolean> = { authRedirect: true, grid: true, state: true }

export const storageKeys = Object.keys(keyMap)
