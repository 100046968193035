import { TranslatedText, UntranslatedText } from 'core'
import React from 'react'

import { Button, FormButtonContainer, Icon, Modal, Text, View } from '~/components'
import type { ButtonAction } from '~/lite'

interface IPositionDialogProps {
  icon?: string
  isOpen: boolean
  title: UntranslatedText
  confirmationText: TranslatedText
  confirmButtonAction: ButtonAction
  cancelButtonAction: ButtonAction
  confirmButtonText: UntranslatedText
  cancelButtonText?: UntranslatedText
  onClose: () => void
  onConfirm: () => void
}

export const PositionDialog: React.FC<IPositionDialogProps> = ({
  icon,
  isOpen,
  title,
  confirmButtonAction,
  cancelButtonAction,
  confirmButtonText,
  confirmationText,
  cancelButtonText = 'Cancel',
  onClose,
  onConfirm
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <View display="flex" alignItems="center" paddingY="s" paddingX="none">
        {icon && <Icon icon={icon} size={50} />}
        <Text fontSize="16px" fontWeight={600} text={title} />
        <Text padding="s" fontSize="14px" textAlign="center" rawText={confirmationText} />
      </View>
      <FormButtonContainer>
        <Button action={confirmButtonAction} text={cancelButtonText} minimal onPress={onClose} />
        <Button action={cancelButtonAction} text={confirmButtonText} primary onPress={onConfirm} />
      </FormButtonContainer>
    </Modal>
  )
}
