import React, { useEffect } from 'react'

import { usePromoteContent, useTeamSubscriptionBenefit } from '~/hooks'
import { Loader, MetaTags, useStore } from '~/lite'

import { Faq } from '../Faq'
import { Features } from '../Features'
import { HowItWorks } from '../HowItWorks'
import { Pricing } from '../Pricing'
import { ContentWrap } from '../styles'
import { Hero } from './Hero'

export const Promote: React.FC = () => {
  const team = useStore(state => state.user?.teams?.[0])
  const { data, isLoading } = usePromoteContent()
  const { data: teamSubscriptionPlan, isLoading: isLoadingTeamSubscription } = useTeamSubscriptionBenefit(
    'job-posting',
    team?.id
  )

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoading || isLoadingTeamSubscription) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { metaTags, hero, features, howItWorks, pricing, faq } = data.data

  return (
    <ContentWrap>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/promote" />
      <Hero data={hero} />
      <Features data={features} />
      <HowItWorks data={howItWorks} />
      <Pricing
        data={pricing}
        currentProductId={teamSubscriptionPlan?.metadata.stripeProductId ?? null}
        selectPlanText="Subscribe"
      />
      <Faq data={faq} />
    </ContentWrap>
  )
}
