import { getFirstValue, IExperience, ISkill } from 'core'
import { keyBy } from 'lodash'
import React, { useMemo } from 'react'

import { css, styled, Text, View, ViewProps } from '~/components'
import { useSkills } from '~/hooks'

import { Skill } from './Skill'

const Group = styled(View)<{ $span?: number; $compact?: boolean }>``

const GroupName = styled(Text)<{ $compact?: boolean; $firstChild?: boolean }>`
  width: 100%;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  margin-top: ${props => (props.$firstChild ? '15px' : 0)};
  ${props =>
    !props.$compact &&
    css`
      text-align: center;
    `}
`

const GroupSkills = styled(View)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 10px 10px 15px;
`

const Skills = styled(View)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`

const Wrap = styled(View)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`

interface ISkillListProps {
  skills?: ISkill[]
  experiences?: Readonly<IExperience[]>
  filterToExperiences?: boolean
  selectedSkillIds?: ISkill['id'][]
  query?: string
  onSkillPress?(skill: ISkill): void
  grouped?: boolean
  compact?: boolean
  style?: ViewProps['style']
}

export const SkillList: React.FC<ISkillListProps> = ({
  experiences = [],
  filterToExperiences,
  selectedSkillIds = [],
  query,
  onSkillPress,
  grouped,
  compact,
  style
}) => {
  const { data: allSkills = [], NonIdealState } = useSkills()

  const normalizedQuery = useMemo(() => query?.toLowerCase().trim(), [query])

  const experiencesBySkillSlug = useMemo(() => keyBy(experiences, experience => experience.skillSlug), [experiences])

  const skillFilter = useMemo(
    () => (skill: ISkill) =>
      skill &&
      (!normalizedQuery || skill.name.toLowerCase().includes(normalizedQuery)) &&
      (!filterToExperiences || experiencesBySkillSlug[skill.slug]),
    [normalizedQuery, filterToExperiences, experiencesBySkillSlug]
  )

  const skills = useMemo(() => allSkills.filter(skill => skillFilter(skill)), [allSkills, skillFilter])

  const skillsBySlug = useMemo(() => keyBy(skills, 'slug'), [skills])

  const topLevelSkills = useMemo(
    () => (skillsBySlug.root?.children || []).map(slug => skillsBySlug[slug]),
    [skillsBySlug]
  )

  const topLevelSkillGroups = useMemo(
    () =>
      topLevelSkills.map(topLevelSkill => ({
        parentSkill: topLevelSkill,
        skills: (topLevelSkill?.children || []).map(slug => skillsBySlug[slug])
      })),
    [topLevelSkills, skillsBySlug]
  )

  const skillGroups = useMemo(() => {
    const groups: { parentSkill?: ISkill; skills: ISkill[] }[] = normalizedQuery ? [{ skills }] : topLevelSkillGroups
    const priorSkillIds: ISkill['id'][] = []

    return groups
      .map(group => ({
        ...group,
        skills: group.skills.filter(
          skill => skill && skillFilter(skill) && !priorSkillIds.includes(skill.id) && !!priorSkillIds.push(skill.id)
        )
      }))
      .filter(group => !!group.skills.length)
  }, [normalizedQuery, skillFilter, skills, topLevelSkillGroups])

  const groupSpans: number[] = []

  if (skillGroups.length) {
    for (let openColumns = 4 - skillGroups.length; openColumns > 0; openColumns--) {
      const groupRows = skillGroups.map((g, i) => Math.ceil(g.skills.length / (groupSpans[i] || 1)))
      const maxRow = Math.max(...groupRows)
      const groupIndex = maxRow === 1 ? groupRows.lastIndexOf(maxRow) : groupRows.indexOf(maxRow)
      groupSpans[groupIndex] = (groupSpans[groupIndex] || 1) + 1
    }
  }

  if (NonIdealState) {
    return <NonIdealState />
  }

  return (
    <Wrap style={style}>
      {grouped &&
        skillGroups.map((group, i) => (
          <Group key={group.parentSkill?.id || i} $span={groupSpans[i]}>
            {group.parentSkill && group.parentSkill.name && (
              <GroupName
                rawText={getFirstValue(group.parentSkill.name)}
                $firstChild={i === 0}
                /* onPress={() => onSkillPress && group.parentSkill && onSkillPress(group.parentSkill)} */
              />
            )}
            <GroupSkills>
              {group.skills.map(skill => (
                <Skill
                  key={skill.id}
                  skill={skill}
                  experience={experiencesBySkillSlug[skill.slug]}
                  selected={selectedSkillIds.includes(skill.id)}
                  onPress={onSkillPress}
                  compact={compact}
                />
              ))}
            </GroupSkills>
          </Group>
        ))}
      {!grouped && (
        <Skills>
          {skills.map(skill => (
            <Skill
              key={skill.id}
              skill={skill}
              experience={experiencesBySkillSlug[skill.slug]}
              selected={selectedSkillIds.includes(skill.id)}
              onPress={onSkillPress}
              compact={compact}
            />
          ))}
        </Skills>
      )}
    </Wrap>
  )
}
