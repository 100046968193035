import {
  calendarBookingServices,
  getMarketProfileUpdateData,
  IMarketProfile,
  IMarketProfileUpdateData,
  parseCalendarBookingUrl,
  TranslatedText
} from 'core'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormGroup, IInputProps, Input } from '~/components'
import { useUpdateMarketProfile } from '~/hooks'
import { Button, Dialog, IDialogProps, openURL, styled, toast, useStore, View } from '~/lite'

const Services = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const Service = styled(Button)``

const InputWrap = styled(View)`
  flex-direction: row;
  gap: 8px;
`

const ServiceInput: React.FC<IInputProps<string>> = props => {
  const theme = useStore(state => state.theme)
  const [placeholder, setPlaceholder] = useState<TranslatedText>(
    calendarBookingServices[0].placeholder as TranslatedText
  )

  useEffect(() => {
    let index = 1

    const interval = setInterval(() => {
      setPlaceholder(calendarBookingServices[index].placeholder as TranslatedText)
      index = (index + 1) % calendarBookingServices.length
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Input
      {...props}
      rawPlaceholder={placeholder}
      wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border, width: '100%' }}
    />
  )
}

export type IntegrationType = 'CalendarBooking'

interface IIntegrationDialogProps extends Omit<IDialogProps, 'trigger' | 'children'> {
  profile: IMarketProfile
  type: IntegrationType
  onClose(): void
}

export const IntegrationDialog: React.FC<IIntegrationDialogProps> = ({ profile, type, onClose, ...props }) => {
  const { mutateAsync: updateProfile, isLoading: isUpdatingProfile } = useUpdateMarketProfile({
    onSuccess: data => {
      if (data.success) {
        onClose()
        toast.success('Changes saved')
      } else {
        toast.failure()
      }
    },
    onError: () => {
      toast.failure()
    }
  })

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IMarketProfileUpdateData>()

  useEffect(() => {
    reset(getMarketProfileUpdateData(profile))
  }, [reset, profile])

  const data = watch()

  const activeServiceCode = data.calendarBookingUrl ? parseCalendarBookingUrl(data.calendarBookingUrl)?.service : null

  return (
    <Dialog
      {...props}
      trigger={null}
      title="Calendar booking"
      description="Sign up for a service, then paste your booking link here"
      disableAutoFocus
      footer={
        <>
          <Button text="Cancel" onPress={() => onClose()} disabled={isUpdatingProfile} skipTracking />
          <Button
            text={profile.calendarBookingUrl ? 'Save' : 'Connect'}
            onPress={handleSubmit(values => updateProfile(values))}
            disabled={isUpdatingProfile}
            loading={isUpdatingProfile}
            skipTracking
            primary
          />
        </>
      }
    >
      <Services>
        {calendarBookingServices.map(service => (
          <Service
            key={service.code}
            rawText={service.name as TranslatedText}
            onPress={() => openURL(service.url)}
            rightIcon={service.code === activeServiceCode ? 'checkmark-outline' : 'open-outline'}
            skipTracking
          />
        ))}
      </Services>
      <Controller
        control={control}
        name="calendarBookingUrl"
        render={({ field }) => (
          <FormGroup helperText={errors.calendarBookingUrl?.message} style={{ marginBottom: 0 }}>
            <InputWrap>
              <ServiceInput {...field} value={field.value ?? undefined} />
            </InputWrap>
          </FormGroup>
        )}
      />
    </Dialog>
  )
}
