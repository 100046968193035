import { IUser, TranslatedText, TranslateParams } from 'core'
import { throttle } from 'lodash'
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { BottomSheetInputGroup, Button, IInputProps, Input, Modal, View, ViewProps } from '~/components'
import { WebFile } from '~/dom'
import { Image, pusher, useBreakpoints, useStore, useTranslation } from '~/lite'
import { emptyString, isWeb } from '~/util'

import { DialogBody } from './styles'

export interface IChannelToolbarProps extends ViewProps {
  userId: IUser['id'] | null
  handleSendMessage(message: TranslatedText): void
  pusherChannel: ReturnType<typeof pusher.subscribeToChannel> | null
  sendingDisabled?: boolean
  sendTypingIndicator?: boolean
  autoFocus?: boolean
  onFocus?(): void
}

export const ChannelToolbar: React.FC<IChannelToolbarProps> = ({
  userId,
  handleSendMessage,
  pusherChannel,
  sendingDisabled = false,
  sendTypingIndicator = true,
  autoFocus,
  onFocus,
  fontSize,
  ...viewProps
}) => {
  const theme = useStore(state => state.theme)
  const { isPhone } = useBreakpoints()
  const t = useTranslation()
  const [fileInputSelectedImage, setFileInputSelectedImage] = useState<WebFile | null>(null)
  const [fileSelectedImageUrl, setFileSelectedImageUrl] = useState<string>()
  const [inputValue, setInputValue] = useState(emptyString)
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [isEmpty, setIsEmpty] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const onSendImageDialogClose = () => {
    setFileInputSelectedImage(null)
    setFileInputSelectedImage(null)
  }

  const sendFileAndMessage = async () => {
    if (fileInputSelectedImage) {
      /*await channelProxy?.sendMessage({
        contentType: fileInputSelectedImage.type,
        // casting type as media only accepts Buffer or string as its typing
        media: fileInputSelectedImage as any
      })*/
    }
    if (!isEmpty) {
      // channelProxy?.sendMessage(inputValue)
      ;(inputRef.current as any)?.clear()
      setInputValue(emptyString)
      setIsEmpty(true)
    }
    onSendImageDialogClose()
  }

  const sendTypingEvent = useMemo(
    () => throttle(() => userId && pusherChannel?.trigger('client-typing', { userId }), 200),
    [userId, pusherChannel]
  )

  const handleSubmitSendMessage = useCallback(() => {
    if (!isEmpty && !sendingDisabled) {
      handleSendMessage((inputRef.current as any)?.getValue() as TranslatedText)
      ;(inputRef.current as any)?.clear()
      setInputValue(emptyString)
      setIsEmpty(true)

      if (inputRef.current && !isPhone) {
        inputRef.current.focus()
      }
    }
  }, [isEmpty, sendingDisabled, handleSendMessage, inputRef, isPhone])

  const onSubmitEditing = useCallback<NonNullable<IInputProps<TranslatedText>['onSubmitEditing']>>(
    e => {
      e.preventDefault()
      handleSubmitSendMessage()
    },
    [handleSubmitSendMessage]
  )

  const onMessageInputChange = useCallback(
    (value: TranslatedText) => {
      startTransition(() => {
        setInputValue(value)
        setIsEmpty(value.length === 0)

        if (sendTypingIndicator && value.length > 0) {
          sendTypingEvent()
        }
      })
    },
    [sendTypingIndicator, sendTypingEvent]
  )

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  const messageInputProps = React.useMemo(
    () => ({
      ref: inputRef,
      testID: 'channel-message-input',
      placeholder: t('Send message') as unknown as TranslateParams,
      onChange: onMessageInputChange,
      flex: 1,
      wrapStyle: { borderRadius: 21 },
      onFocus: () => {
        setIsFocused(focused => {
          // only fire onFocus once per focus/blur cycle, and not on autoFocus's first focus, preventing infinite loop between autoFocus/onFocus
          if (!focused) {
            setTimeout(() => {
              startTransition(() => {
                onFocus?.()
              })
            }, 0)
          }

          return true
        })
      },
      onBlur: () => setIsFocused(false),
      autoFocus,
      autoGrow: true,
      accessoryRight: (
        <Button
          action="CreateMessage"
          testID="send-message-button"
          rightIcon="send"
          onPress={handleSubmitSendMessage}
          height={40}
          marginRight={-16}
          primary={false}
          disabled={isEmpty || sendingDisabled}
          color={isEmpty ? theme.inputPlaceholder : theme.primary}
          minimal
        />
      )
    }),
    [
      onFocus,
      onMessageInputChange,
      isEmpty,
      sendingDisabled,
      handleSubmitSendMessage,
      t,
      theme.inputPlaceholder,
      theme.primary,
      autoFocus
    ]
  )

  return (
    <View display="flex" flexDirection="row" alignItems="stretch" paddingX="s" paddingY="s" {...viewProps}>
      {fileInputSelectedImage && fileSelectedImageUrl && (
        <Modal isOpen={!!fileInputSelectedImage} onClose={onSendImageDialogClose} title="Media">
          <DialogBody>
            <Image source={fileSelectedImageUrl} height={100} width={300} />
            <Input
              testID="channel-message-input"
              placeholder="Send message"
              onChange={onMessageInputChange}
              value={inputValue}
              autoFocus
            />
            <Button action="CreateMessage" rightIcon="send" primary onPress={sendFileAndMessage} />
          </DialogBody>
        </Modal>
      )}
      {isWeb ? (
        <Input onEnterKeyPress={isPhone ? undefined : handleSubmitSendMessage} {...messageInputProps} />
      ) : (
        <BottomSheetInputGroup
          returnKeyType="send"
          blurOnSubmit={false}
          onSubmitEditing={onSubmitEditing}
          fontSize={fontSize}
          value={inputValue}
          {...messageInputProps}
        />
      )}
    </View>
  )
}
