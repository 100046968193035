import * as React from 'react'
import { Modal as RRModal } from 'react-responsive-modal'

import { useBreakpoints, useStore } from '~/hooks'

import { H1 } from '../H/H'
import { Icon } from '../Icon/Icon'
import { IModalProps } from './types'

export const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  title,
  rawTitle,
  showCloseButton,
  presentation,
  closeOnOverlayPress,
  closeOnEsc,
  blockScroll,
  testID,
  children,
  center = true,
  padding
}) => {
  const { background } = useStore(state => state.theme)
  const { isPhone } = useBreakpoints()
  const modalStyle: React.CSSProperties = React.useMemo(() => {
    let maxWidth = isPhone ? 'calc(100% - 2.4rem)' : 600
    if (presentation === 'formSheet' && !isPhone) {
      maxWidth = 400
    }
    return {
      backgroundColor: background,
      boxShadow: 'none',
      borderRadius: 15,
      width: '100%',
      maxWidth,
      ...(padding && { padding })
    }
  }, [isPhone, background, presentation, padding])

  const modalContainerStyle: React.CSSProperties = React.useMemo(() => {
    return {}
  }, [])

  const closeBtnStyle: React.CSSProperties = React.useMemo(() => {
    return {
      padding: 0,
      outline: 'none'
    }
  }, [])

  // when this is closed: https://github.com/pradel/react-responsive-modal/issues/495
  // we can remove the .react-responsive-modal-* style in WebStyles.css

  return (
    <RRModal
      open={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayPress}
      closeOnEsc={closeOnEsc ?? closeOnOverlayPress}
      blockScroll={blockScroll}
      center={center}
      showCloseIcon={showCloseButton}
      closeIcon={<Icon icon="close-outline" size={30} />}
      styles={{
        modal: modalStyle,
        modalContainer: modalContainerStyle,
        closeButton: closeBtnStyle
      }}
    >
      <div data-testid={testID}>
        {title && <H1 text={title} textAlign="center" display="block" marginY={10} />}
        {rawTitle && <H1 rawText={rawTitle} textAlign="center" display="block" marginY={10} />}
        {children}
      </div>
    </RRModal>
  )
}
