import { IMarketProfile } from 'core'
import React from 'react'

interface IProfileProps {
  profile: IMarketProfile
}

export const Profile: React.FC<IProfileProps> = () => {
  return null
}
