import { IComment, markTranslated } from 'core'
import React from 'react'

import { Icon, Text, useStore, View } from '~/lite'

interface ICommentProps {
  comment: IComment
}

export const Comment: React.FC<ICommentProps> = ({ comment }) => {
  const theme = useStore(state => state.theme)

  return (
    <View display="flex" flexDirection="row" alignItems="start" marginY="s">
      <View backgroundColor={theme.primary} marginRight="s" padding="xs" borderRadius="50%">
        <Icon icon="help-outline" size={24} color="#ffffff" />
      </View>
      <View flex={1}>
        <Text rawText={markTranslated(comment.comment ?? '')} fontSize={18} paddingY="s" />
        {comment.replies?.map(comment => (
          <Text key={comment.id} rawText={markTranslated(comment.comment ?? '')} paddingY="s" />
        ))}
      </View>
    </View>
  )
}
