import { ThemeName } from 'core'
import { darken, lighten } from 'polished'

export type SpaceVariant = 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'auto' | '100%' | '2%'

export type StyledSystemTheme = {
  colors?: Record<string, string>
  space: Record<SpaceVariant | number, number | string>
}

export interface ITheme {
  dark: boolean
  primary: string
  primaryHover: string
  text: string
  textSecondary: string
  background: string
  backgroundContrast: string
  backgroundRgb: string
  border: string
  buttonBackground: string
  buttonColor: string
  buttonHover: string
  inputBackground: string
  inputBorderColor: string
  inputText: string
  inputPlaceholder: string
  menuItemBackground: string
  placeholder: string
  error: string
  success: string
  headerBackground: string
  sidebarBackground: string
  borderRadius: string
  boxShadow: string
  cardBackground: string
  listBackground: string
}

export const space = {
  none: 0,
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  l: 32,
  xl: 64,
  auto: 'auto',
  '100%': '100%',
  '2%': '2%'
}

// don't use shorthand color syntax since we use opacity suffixes in places (e.g., "#ffffffcc")
export const themes: { [themeName in ThemeName]: ITheme & StyledSystemTheme } = {
  light: {
    dark: false,
    space,
    primary: '#6360ff',
    primaryHover: darken(0.025, '#6360ff'),
    text: '#0e103c',
    textSecondary: '#8a8d92',
    background: '#ffffff',
    backgroundContrast: '#f9fafb',
    backgroundRgb: '255, 255, 255',
    border: '#e6e9ec',
    buttonBackground: '#f5f8fa',
    buttonHover: '#f9fafb',
    buttonColor: '#182026',
    inputBackground: '#f9fafb',
    inputBorderColor: '#ced4da',
    inputText: '#000000',
    inputPlaceholder: '#5c7080',
    menuItemBackground: '#ecf0f6',
    placeholder: '#ced4da',
    error: '#ff656d',
    success: '#34b792',
    headerBackground: 'rgba(255, 255, 255, 0.7)',
    sidebarBackground: '#ffffff',
    borderRadius: '12px',
    boxShadow: 'rgba(33, 35, 74, 0.11) 0px 8px 40px',
    cardBackground: '#fff',
    listBackground: '#f2f4f6'
  },
  dark: {
    dark: true,
    space,
    primary: '#6360ff',
    primaryHover: darken(0.025, '#6360ff'),
    text: '#ffffff',
    textSecondary: '#b6bac0',
    background: darken(0.035, '#27282b'),
    backgroundContrast: '#27282b',
    backgroundRgb: '39, 40, 43',
    border: lighten(0.05, '#27282b'),
    buttonBackground: lighten(0.1, '#27282b'),
    buttonHover: lighten(0.2, '#27282b'),
    buttonColor: '#ffffff',
    inputBackground: lighten(0.05, '#27282b'),
    inputBorderColor: darken(0.035, '#27282b'),
    inputText: '#ffffff',
    inputPlaceholder: '#5c7080',
    menuItemBackground: lighten(0.05, '#27282b'),
    placeholder: lighten(0.1, '#27282b'),
    error: '#ff656d',
    success: '#34b792',
    headerBackground: 'rgba(22, 22, 26, 0.7)', // 'rgba(255, 255, 255, 0.035)',
    sidebarBackground: darken(0.035, '#27282b'), // '#1f2023',
    borderRadius: '12px',
    boxShadow: 'rgba(33, 35, 74, 0.11) 0px 8px 40px',
    cardBackground: '#27282b',
    listBackground: darken(0.035, '#27282b')
  }
}
