import { ICompanyOption } from '@ambition/module-shared'
import { IUniversity } from 'core'
import { create } from 'zustand'

type EditUserProfileStoreProps = {
  company: ICompanyOption | null
  university: IUniversity | null
  setCompany: (company: ICompanyOption) => void
  setUniversity: (university: IUniversity) => void
  clearCompany: () => void
  clearUniversity: () => void
  clearAll: () => void
}

export const useEditProfileStore = create<EditUserProfileStoreProps>(set => ({
  company: null,
  university: null,
  setCompany: company => set(() => ({ company })),
  setUniversity: university => set(() => ({ university })),
  clearCompany: () => set(() => ({ company: null })),
  clearUniversity: () => set(() => ({ university: null })),
  clearAll: () => set(() => ({ university: null, company: null }))
}))
