import { OpenAiLogo } from '@ambition/module-shared'
import { IFeaturesContent } from 'core'
import React from 'react'

import { breakpoint, H4, Icon, styled, Text, useStore, View } from '~/lite'

import { backgrounds, Section, SectionContent, SectionHeaderTag, SectionTitle, Subtitle } from './styles'

const ChildFeatures = styled(View)`
  padding: 16px 16px 64px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  column-gap: 16px;
  row-gap: 36px;

  @media ${breakpoint.md} {
    padding-top: 0;
    grid-template-columns: repeat(3, 1fr);
    column-gap: unset;
    place-items: start center;
  }
`

const Feature = styled(View)`
  @media ${breakpoint.md} {
    max-width: 256px;
  }
`

const FeatureIconWrap = styled(View)`
  margin-right: 8px;
`

const FeatureHeader = styled(H4)`
  font-size: 18px;
  font-weight: bold;
`

const FeatureSubheader = styled(Text)`
  font-size: 16px;
`

const featureBackgrounds = [backgrounds.orangePink, backgrounds.yellow, backgrounds.purple]

const FeatureIcon: React.FC<{ icon: NonNullable<IFeaturesContent[number]['features'][number]['icon']> }> = ({
  icon
}) => {
  const darkMode = useStore(state => state.darkMode)

  if (icon === 'OpenAI') {
    return (
      <FeatureIconWrap>
        <OpenAiLogo size={24} />
      </FeatureIconWrap>
    )
  }

  return (
    <FeatureIconWrap>
      <Icon icon={icon} size={24} color={darkMode ? '#fff' : '#000'} />
    </FeatureIconWrap>
  )
}

interface IFeaturesProps {
  data: IFeaturesContent
}

export const Features: React.FC<IFeaturesProps> = ({ data }) => {
  const darkMode = useStore(state => state.darkMode)

  return (
    <View id="features">
      {data.map(({ headerTag, header, subheader, features }, index) => (
        <Section
          key={index}
          style={darkMode ? { background: featureBackgrounds[index % featureBackgrounds.length] } : undefined}
        >
          <SectionContent>
            {headerTag && <SectionHeaderTag rawText={headerTag} />}
            {header && <SectionTitle rawText={header} />}
            {subheader && <Subtitle rawText={subheader} />}
            <ChildFeatures>
              {features.map(({ header, subheader, icon }, idx) => (
                <Feature key={idx}>
                  <View flexDirection="row" alignItems="center" marginBottom="s">
                    {icon && <FeatureIcon icon={icon} />}
                    <FeatureHeader rawText={header} />
                  </View>
                  <FeatureSubheader rawText={subheader} />
                </Feature>
              ))}
            </ChildFeatures>
          </SectionContent>
        </Section>
      ))}
    </View>
  )
}
