import { ILocationOption } from '@ambition/module-shared'
import { create } from 'zustand'

type PositionFormStoreProps = {
  location?: ILocationOption | null
  setLocation(location: ILocationOption | null): void
}

export const usePositionFormStore = create<PositionFormStoreProps>(set => ({
  location: undefined,
  setLocation: location => set(() => ({ location }))
}))
