import { IMarketProfile } from 'core'
import React from 'react'

import { useMarketProfile, useMarketProfileByUsername } from '~/hooks'
import { Loader } from '~/lite'

import { Profile } from './Profile'

export interface IInstantSdkProfileProps {
  id?: IMarketProfile['id']
  username?: IMarketProfile['username']
}

export const InstantSdkProfile: React.FC<IInstantSdkProfileProps> = ({ id, username }) => {
  const { data: idData, isLoading: isLoadingById } = useMarketProfile(id)
  const { data: usernameData, isLoading: isLoadingByUsername } = useMarketProfileByUsername(username)

  if (isLoadingById && isLoadingByUsername) {
    return <Loader />
  }

  const data = idData || usernameData

  if (!data) {
    return null
  }

  return <Profile profile={data} />
}
