import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { IFeedSection, markTranslated, UserFeedSectionId } from 'core'
import produce from 'immer'
import { keyBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { Carousel, FlexHorizontal, IMenuProps, Menu } from '~/components'
import {
  useDeleteUserFeedSection,
  useFeedSectionDetail,
  useGenerateJobMacros,
  useUserJobMacros,
  useValidateSearchRoute,
  UsingLandingPageResult
} from '~/hooks'
import {
  AlertDialog,
  breakpoint,
  Button,
  DesktopView,
  H2,
  H3,
  styled,
  useBreakpoints,
  useHistory,
  useStore,
  useTranslation,
  View
} from '~/lite'
import { emptyString } from '~/util'

import { PositionCard } from '../PositionCard'
import { PositionCardSkeleton } from '../PositionCardSkeleton'
import { IPositionSectionProps } from './type'

const SubTitles = styled(View)`
  flex-direction: row;
  margin-bottom: 8px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.lg} {
    padding: 0;
    margin-bottom: 16px;
  }
`

export const PositionSection: React.FC<IPositionSectionProps> = ({
  title,
  subTitleQueries,
  selectedSubTitleQuery,
  occupationId,
  reason,
  seeAllPath,
  latLng,
  data,
  list,
  useCarouselLayout = true,
  isLoading: isLandingPageLoading,
  isRootPage,
  fromSearchPage,
  removable = false,
  id,
  queryKey,
  showTeam,
  hideAnalyzeButton,
  getJobDashboardPath
}) => {
  // @todo Math.random() + SSR = mismatch
  // const [skeletonStyles] = useState([{ width: `${Math.floor(150 + Math.random() * 150)}px` }])
  const validateRoute = useValidateSearchRoute()
  const t = useTranslation()
  const history = useHistory()
  const { isPhone } = useBreakpoints()
  const [requestingJobMacros, setRequestingJobMacros] = useState(false)
  const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [skeletonStyles] = useState([{ width: '225px' }])
  const [cachedSubTitles, setCachedSubTitles] = useState<string[]>([])
  const [selectedSubTitle, setSelectedSubtitle] = useState<string | null>(null)
  const { theme, isUpdatingJobScope } = useStore()
  const {
    jobs,
    subTitleQueries: feedSectionSubTitleQueries,
    isLoading: isFeedSectionLoading
  } = useFeedSectionDetail(
    { feedSectionId: id },
    {
      subTitleQuery: selectedSubTitle || undefined,
      preferCurrentLocation: isRootPage ? '1' : '0',
      limit: !isRootPage ? '50' : undefined,
      occupationId: occupationId || undefined
    },
    isUpdatingJobScope,
    !data && !fromSearchPage && !!id
  )

  useEffect(() => {
    if (feedSectionSubTitleQueries && !cachedSubTitles.length) {
      setCachedSubTitles(feedSectionSubTitleQueries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedSectionSubTitleQueries])

  useEffect(() => {
    if (subTitleQueries) {
      setCachedSubTitles(subTitleQueries)
    }
  }, [subTitleQueries])

  useEffect(() => {
    setSelectedSubtitle(selectedSubTitleQuery || null)
  }, [selectedSubTitleQuery])

  const isLoading = data || fromSearchPage ? isLandingPageLoading : isFeedSectionLoading

  const jobData = useMemo(() => {
    if (data) {
      return data
    }
    return jobs || []
  }, [jobs, data])

  const sortedJobIds = useMemo(() => {
    return jobData
      .map(job => job.id)
      .sort((a, b) => {
        return a > b ? 1 : -1
      })
  }, [jobData])

  const { data: jobMacros, isLoading: isFetchingJobMacros } = useUserJobMacros(sortedJobIds, requestingJobMacros)
  const jobDataWithMacros = useMemo(() => {
    if (!jobMacros) {
      return jobData
    }
    const jobMacroMap = keyBy(jobMacros.jobMacroData, 'jobId')
    return jobData.map(job => ({
      ...job,
      macroResults: jobMacroMap[job.id]?.results || job.macroResults
    }))
  }, [jobMacros, jobData])

  const { mutate: deleteUserFeedSection } = useDeleteUserFeedSection({
    onMutate: async ({ id }) => {
      if (!queryKey) {
        return
      }
      await queryClient.cancelQueries(queryKey)
      const previousData = queryClient.getQueryData<InfiniteData<UsingLandingPageResult>>(queryKey)
      queryClient.setQueryData(queryKey, (old: InfiniteData<UsingLandingPageResult> | undefined) => {
        const nextState = produce(old, draftState => {
          const results = draftState?.pages?.[0]?.results
          if (results?.sections) {
            results.sections = results.sections.filter(item => item.sectionId !== id)
          }
        })
        if (!nextState?.pages?.[0]?.results?.sections?.length) {
          queryClient.invalidateQueries(queryKey)
        }
        return nextState
      })
      return { previousData }
    }
  })
  const { mutate: generateJobMacros, isLoading: isGeneratingJobMacros } = useGenerateJobMacros({
    onSuccess: async data => {
      if (data.success) {
        queryClient.resetQueries(['user-job-macros', sortedJobIds])
      }
    }
  })

  const renderPositionCards = () => {
    if ((isLoading && !jobDataWithMacros.length) || isUpdatingJobScope) {
      return Array(isRootPage ? 10 : 50)
        .fill(0)
        .map((_, idx) => (
          <View key={idx} marginY="s" width={useCarouselLayout ? 300 : '100%'}>
            <PositionCardSkeleton showTeam={showTeam} listItem={list} />
          </View>
        ))
    }

    if (!jobDataWithMacros.length && !isLoading) {
      return [
        <View padding="s">
          <H3 text="No Results" />
        </View>
      ]
    }

    return jobDataWithMacros.map(item => (
      <PositionCard
        key={item.id}
        record={item}
        listItem={list}
        showTeam={showTeam}
        isFetchingJobMacros={isFetchingJobMacros || isGeneratingJobMacros}
        getJobDashboardPath={getJobDashboardPath}
      />
    ))
  }

  const showAnalyzeButton = (isLoading || !!jobDataWithMacros.length) && !hideAnalyzeButton

  const renderMenuOptions = (): IMenuProps['items'] => {
    const options: IMenuProps['items'] = []
    if (seeAllPath) {
      options.push({
        rawText: t('See All'),
        onPress: () => history.push(seeAllPath)
      })
    }
    if (showAnalyzeButton && isPhone) {
      options.push({
        text: 'Analyze All',
        onPress: onGenerateJobMacros
      })
    }
    if (removable) {
      options.push({
        rawText: t('Delete'),
        onPress: () => {
          // need this delay when showing an AlertDialog from a menu.
          // otherwise the overlay seems to get stuck when the Dialog closes and the UI is blocked.
          setTimeout(() => {
            setDeleteSectionModalOpen(true)
          }, 100)
        }
      })
    }
    return options
  }

  const handleRemoveSection = (id: IFeedSection['sectionId']) => {
    return async () => {
      deleteUserFeedSection({ id: id as UserFeedSectionId })
    }
  }

  const onGenerateJobMacros = () => {
    if (!jobData.length) {
      return
    }
    setRequestingJobMacros(true)
    generateJobMacros({
      jobIds: jobData.map(item => item.id)
    })
  }

  const renderDeleteSection = () => {
    if (removable && id) {
      return (
        <AlertDialog
          open={deleteSectionModalOpen}
          onOpenChange={setDeleteSectionModalOpen}
          title="Warning"
          rawDescription={t('Are you sure you want to delete "{{recordName}}"?', { recordName: title || emptyString })}
          onConfirm={handleRemoveSection(id)}
          action="RemoveFeedSection"
        />
      )
    }
    return null
  }

  const renderSubTitleList = () => {
    if (cachedSubTitles.length) {
      return (
        <SubTitles>
          <Button
            active={!selectedSubTitle}
            text="All"
            onPress={() => {
              if (fromSearchPage) {
                validateRoute('subTitleQuery', '')
              } else {
                setSelectedSubtitle(null)
              }
            }}
            action="QueryBySubTitle"
            borderRadius={8}
            small
          />
          {cachedSubTitles.map((query, idx) => {
            return (
              <Button
                key={idx}
                active={query === selectedSubTitle}
                rawText={markTranslated(query)}
                onPress={() => {
                  if (fromSearchPage) {
                    validateRoute('subTitleQuery', query)
                  } else {
                    setSelectedSubtitle(query)
                  }
                }}
                action="QueryBySubTitle"
                marginLeft="s"
                borderRadius={8}
                small
              />
            )
          })}
        </SubTitles>
      )
    }
    return null
  }

  return (
    <View position="relative">
      <View className={`section${list ? ' section-list' : ''}`}>
        <View flexDirection="row" alignItems="start" justifyContent="space-between">
          {!title && !fromSearchPage && isLoading && <div className="skeleton-header" style={skeletonStyles[0]} />}
          <View flex={1} marginBottom="s">
            {title && (
              <H2
                rawText={title}
                onPress={getJobDashboardPath ? undefined : () => seeAllPath && history.push(seeAllPath)}
              />
            )}
            {reason && <H3 rawText={reason} color={theme.textSecondary} fontWeight="400" fontSize={14} />}
          </View>
          <FlexHorizontal alignItems="center" paddingLeft="m" marginBottom="s">
            {getJobDashboardPath ? (
              <Button text="See All" to={seeAllPath} skeleton={isLoading} skipTracking />
            ) : (
              <>
                {showAnalyzeButton && (
                  <DesktopView>
                    <Button
                      text="Analyze All"
                      loadingText="Analyzing"
                      onPress={onGenerateJobMacros}
                      action="ExecuteUserJobMacroPrompt"
                      loading={!isLoading && (isGeneratingJobMacros || isFetchingJobMacros)}
                      skeleton={isLoading}
                    />
                  </DesktopView>
                )}
                {(seeAllPath || removable) && (
                  <Menu items={renderMenuOptions()}>
                    <Button
                      minimal
                      width={20}
                      height={20}
                      icon="ellipsis-vertical"
                      color={theme.inputPlaceholder}
                      skipTracking
                    />
                  </Menu>
                )}
              </>
            )}
          </FlexHorizontal>
        </View>
        {renderDeleteSection()}
        {renderSubTitleList()}
        {useCarouselLayout ? (
          <Carousel>{renderPositionCards()}</Carousel>
        ) : (
          <View width="100%" flexDirection="column" overflowX="hidden">
            {renderPositionCards()}
          </View>
        )}
      </View>
    </View>
  )
}
