import type { DialogProps } from '@radix-ui/react-dialog'
import * as Dialog from '@radix-ui/react-dialog'
import { Command as CommandPrimitive } from 'cmdk'
import { TranslatedText, UntranslatedText } from 'core'
import React, { ForwardedRef, forwardRef } from 'react'

import { useTranslation } from '../hooks'
import { styled } from '../styled'
import { DialogContent } from './Dialog'
import { Icon } from './Icon'
import { Text } from './Text'
import { View } from './View'

const StyledCommand = styled(CommandPrimitive)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
`

const DialogContentWrap = styled(DialogContent)`
  overflow: hidden;
  padding: 0;

  & > [cmdk-group-heading] {
    padding-left: 0.5rem;
    font-weight: 500;
    color: ${props => props.theme.text}99;
  }

  & > [cmdk-group]:not([hidden]) ~ [data-cmdk-group] {
    padding-top: 0;
  }

  & > [cmdk-group] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  & > [cmdk-input-wrapper] svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  & > [cmdk-input] {
    height: 3rem;
  }

  & > [cmdk-item] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  & > [cmdk-item] svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`

const CommandInputWrap = styled(View)`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 8px;
  background: ${props => (props.theme.dark ? props.theme.cardBackground : '#fff')};
`

const CommandInputInner = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  background-color: ${props => props.theme.inputBackground};
  border: 1px solid ${props => props.theme.inputBorderColor};
  border-radius: 10px;
`

const CommandInputComponent = styled(CommandPrimitive.Input)`
  outline: none;
  outline-width: 0;
  color: ${props => props.theme.inputText};
  resize: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  min-width: 0;
  flex: 1;
  padding: 11px 16px 11px 8px;
  background: transparent;
  border-radius: 0;
  font-size: 16px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &::placeholder {
    color: ${props => props.theme.inputPlaceholder};
  }
`

const CommandListWrap = styled(CommandPrimitive.List)`
  max-height: calc(100vh - 200px);
  padding-bottom: 8px;
  overflow-y: auto;
  overflow-x: hidden;
`

const CommandEmptyWrap = styled(CommandPrimitive.Empty)`
  padding: 32px 16px;
  text-align: center;
  font-size: 14px;
`

const CommandGroupWrap = styled(CommandPrimitive.Group)`
  overflow: hidden;
  margin: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }

  & > [cmdk-group-heading] {
    padding: 0 16px 4px;
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.text};
    opacity: 0.6;
  }
`

const CommandSeparatorWrap = styled(CommandPrimitive.Separator)`
  margin: 0 -8px;
  height: 1px;
  background: ${props => props.theme.border};
`

const CommandItemWrap = styled(CommandPrimitive.Item)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 8px;
  margin: 0 8px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  &[aria-selected='true'] {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const CommandShortcut = styled.span`
  margin-left: auto;
  color: ${props => props.theme.text};
`

interface CommandProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive> {}

interface CommandDialogProps extends DialogProps {}

interface ICommandInputProps
  extends Omit<React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>, 'placeholder'> {
  placeholder?: UntranslatedText
  rawPlaceholder?: TranslatedText
}

interface ICommandListProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.List> {}

interface ICommandEmptyProps extends Omit<React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>, 'children'> {
  text: UntranslatedText
}

interface ICommandGroupProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group> {}

interface ICommandSeparatorProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator> {}

interface ICommandItemProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> {}

export const Command = forwardRef<React.ElementRef<typeof CommandPrimitive>, CommandProps>(
  (props, ref: ForwardedRef<React.ElementRef<typeof CommandPrimitive>>) => <StyledCommand ref={ref} {...props} />
)

export const CommandDialog: React.FC<CommandDialogProps> = ({ children, ...props }) => (
  <Dialog.Root {...props}>
    <DialogContentWrap>
      <Command>{children}</Command>
    </DialogContentWrap>
  </Dialog.Root>
)

export const CommandInput = forwardRef<React.ElementRef<typeof CommandPrimitive.Input>, ICommandInputProps>(
  ({ placeholder, rawPlaceholder, ...props }, ref) => {
    const t = useTranslation()

    return (
      <CommandInputWrap>
        <CommandInputInner>
          <Icon icon="search-outline" />
          <CommandInputComponent
            ref={ref}
            placeholder={rawPlaceholder ?? (placeholder ? t(placeholder) : undefined)}
            {...props}
          />
        </CommandInputInner>
      </CommandInputWrap>
    )
  }
)

export const CommandList = forwardRef<React.ElementRef<typeof CommandPrimitive.List>, ICommandListProps>(
  (props, ref) => <CommandListWrap ref={ref} {...props} />
)

export const CommandEmpty = forwardRef<React.ElementRef<typeof CommandPrimitive.Empty>, ICommandEmptyProps>(
  ({ text, ...props }, ref) => (
    <CommandEmptyWrap ref={ref} {...props}>
      <Text text={text} />
    </CommandEmptyWrap>
  )
)

export const CommandGroup = forwardRef<React.ElementRef<typeof CommandPrimitive.Group>, ICommandGroupProps>(
  (props, ref) => <CommandGroupWrap ref={ref} {...props} />
)

export const CommandSeparator = forwardRef<React.ElementRef<typeof CommandPrimitive.Separator>, ICommandSeparatorProps>(
  (props, ref) => <CommandSeparatorWrap ref={ref} {...props} />
)

export const CommandItem = forwardRef<React.ElementRef<typeof CommandPrimitive.Item>, ICommandItemProps>(
  (props, ref) => <CommandItemWrap ref={ref} {...props} />
)
