import BottomSheet from '@gorhom/bottom-sheet'
import React from 'react'

import { AssistantBottomSheetProps } from './types'

export const AssistantBottomSheet = React.forwardRef<React.ElementRef<typeof BottomSheet>, AssistantBottomSheetProps>(
  (props, ref) => {
    return null
  }
)
