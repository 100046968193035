import {
  CompensationFrequency,
  DatePosted,
  IAutopilotState,
  IFeedSection,
  IGoogleLocation,
  INewUser,
  IResume,
  IResumeUpdateRequest,
  IStripeBaseSubscription,
  IStripeSubscriptionBenefits,
  IUser,
  IUserJobFilter,
  IUserPrompt,
  ResumeImportState,
  StripeService,
  ThemeName,
  UserFeedSectionId
} from '../models'
import { Endpoint, get, getFetchParams, patch, post, put, remove } from '../util/api'
import { SupportedLocaleCode, TranslatedText } from '../util/i18n'
import { JobScope } from '../util/query'
import { Login } from './authentication'

export type UpdateProfile = Endpoint<Record<string, never>, { success: true }, INewUser & { email?: IUser['email'] }>

export type GetProfile = Endpoint<
  { username: IUser['username'] },
  { success: true; profile: IUser },
  Record<string, never>
>

export type GetProfileResume = Endpoint<
  { username: IUser['username'] },
  { success: true; profile: IResume },
  Record<string, never>
>

export type GetResumeViewParam = { username: IUser['username'] }

export type GeneratePdf = Endpoint<
  Record<string, never>,
  { success: true },
  { html: string; download: boolean; appendResume: boolean }
>

export type UpdateLocale = Endpoint<
  Record<string, never>,
  { success: true },
  { oldLocale: SupportedLocaleCode; newLocale: SupportedLocaleCode }
>

export type UpdateTheme = Endpoint<
  Record<string, never>,
  { success: true },
  { oldTheme: ThemeName; newTheme: ThemeName }
>

export type UpdateJobScope = Endpoint<
  Record<string, never>,
  { success: true; scope: JobScope | null },
  { oldScope: JobScope; newScope: JobScope }
>

export type UpdatePreference = Endpoint<
  Record<string, never>,
  {
    success: true
    preferRemote?: boolean
    distanceRadius?: number
    datePosted?: DatePosted
    compensation?: number
    compensationFrequency?: CompensationFrequency
  },
  {
    preferRemote?: boolean
    distanceRadius?: number
    datePosted?: DatePosted
    compensation?: number
    compensationFrequency?: CompensationFrequency
  }
>

export type UpdateProfileAvatar = Endpoint<Record<string, never>, { success: true }, { avatar: IUser['avatar'] }>

export type UpdateProfileCoverImage = Endpoint<
  Record<string, never>,
  { success: true },
  { coverImage: NonNullable<IResume['basics']>['coverImage'] }
>

export type PatchAutopilotState = Endpoint<
  Record<string, never>,
  { success: true; autopilotState: IAutopilotState },
  Partial<IAutopilotState>
>

export type ImportResume = Endpoint<
  Record<string, never>,
  { success: true; fileUrl: string },
  { resume: IUser['resume']; method?: 'Launchpad' }
>

export type GetResumeImportState = Endpoint<
  Record<string, never>,
  { success: true; state: ResumeImportState | null },
  Record<string, never>
>

export type GetUserJobFilters = Endpoint<Record<string, never>, { success: true; filters: IUserJobFilter[] }>

export type UpdateProfileResume = Endpoint<Record<string, never>, { success: true }, IResumeUpdateRequest>

export type DeleteUser = Endpoint<Record<string, never>, { success: true }, { email: IUser['email']; password: string }>

export type TrackLocation = Endpoint<Record<string, never>, { success: true }, { location: IGoogleLocation }>

export type DeleteUserFeedSection = Endpoint<{ id: UserFeedSectionId }, { success: true }, Record<string, never>>

export type GetUserPrompt = Endpoint<
  { type: IUserPrompt['promptType'] },
  { success: true; promptValue?: TranslatedText },
  Record<string, never>
>

export type SubmitUserPrompt = Endpoint<
  { type: IUserPrompt['promptType'] },
  { success: true },
  { promptValue: TranslatedText }
>

export type GetUserSubscriptionBenefits = Endpoint<
  { service: StripeService },
  { success: true; benefits?: IStripeSubscriptionBenefits; metadata?: IStripeBaseSubscription | null },
  Record<string, never>
>

export const updateLocale = (oldLocale: SupportedLocaleCode, newLocale: SupportedLocaleCode) =>
  put<UpdateLocale>('/user/locale', { oldLocale, newLocale })

export const updateTheme = (oldTheme: ThemeName, newTheme: ThemeName) =>
  put<UpdateTheme>('/user/theme', { oldTheme, newTheme })

export const updateScope = (oldScope: JobScope, newScope: JobScope) =>
  put<UpdateJobScope>('/user/job-scope', { oldScope, newScope })

export const updateProfile = (user: UpdateProfile['body']) => post<UpdateProfile>('/user/profile', user)

export const updateProfileAvatar = (avatar: UpdateProfileAvatar['body']) =>
  put<UpdateProfileAvatar>('/user/profile/avatar', avatar)

export const updateProfileCoverImage = (coverImage: UpdateProfileCoverImage['body']) =>
  put<UpdateProfileCoverImage>('/user/profile/cover', coverImage)

export const importResume = (data: ImportResume['body']) => put<ImportResume>('/user/resume/import', data)

export const getResumeImportState = () => get<GetResumeImportState>('/user/resume/import/state')

export const getUserJobFilters = () => get<GetUserJobFilters>('/user/job-filters')

export const updateProfileResume = (resume: UpdateProfileResume['body']) =>
  put<UpdateProfileResume>('/user/resume', resume)

export const getProfile = (username: IUser['username']) => get<GetProfile>(`/user/profile/${username}`)
export const getProfileResume = (username: IUser['username']) => get<GetProfileResume>(`/user/resume/${username}`)

export const trackLocation = (location: TrackLocation['body']['location']) =>
  post<TrackLocation>('/user/location', { location })

export const deleteUser = (credentials: Login['body']) => remove<DeleteUser>('/user/profile/delete', credentials)

export const deleteUserFeedSection = (feedSectionId: IFeedSection['sectionId']) =>
  remove<DeleteUserFeedSection>(`/user/sections/delete/${feedSectionId}`, {})

export const getUserPrompt = (type: IUserPrompt['promptType']) => get<GetUserPrompt>(`/user/prompt/${type}`)

export const submitUserPrompt = (type: IUserPrompt['promptType'], promptValue: TranslatedText) =>
  post<SubmitUserPrompt>(`/user/prompt/${type}`, { promptValue })

export const updateUserPreference = (data: UpdatePreference['body']) => post<UpdatePreference>(`/user/preference`, data)

export const generatePdf = async (data: GeneratePdf['body']): Promise<Blob> => {
  const response = await fetch(...getFetchParams<GeneratePdf>('POST', '/user/pdf', { data }))
  return response.blob()
}

export const getUserSubscriptionBenefit = (service: StripeService) =>
  get<GetUserSubscriptionBenefits>(`/user/subscription/${service}`)

export const patchAutopilotState = (state: PatchAutopilotState['body']) =>
  patch<PatchAutopilotState>('/user/autopilot', state)
