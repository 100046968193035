import { breakpoint, BREAKPOINT_WIDTH, css, Pressable, styled } from '~/lite'

const Arrow = styled(Pressable)`
  display: flex;
  position: absolute;
  top: 50%;
  background: ${props => props.theme.cardBackground};
  border-radius: 50%;
  padding: 8px;
  z-index: 1;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 4px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(-50%) scale(0.5);
  opacity: 0;
  pointer-events: none;
  transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;
  will-change: transform opacity;
`

// swiper has touch-action: pan-y;
export const Wrap = styled.div<{ $enabledOnDesktop: boolean }>`
  position: relative;

  ${props =>
    props.$enabledOnDesktop &&
    css`
      @media ${breakpoint.lg} {
        &:hover ${Arrow} {
          transform: translateY(-50%) scale(1);
          opacity: 1;
          pointer-events: initial;
        }
      }
    `}

  ${props =>
    !props.$enabledOnDesktop &&
    css`
      @media ${breakpoint.lg} {
        ${Slides} {
          overflow: visible;
        }
      }
    `}
`

export const LeftArrow = styled(Arrow)`
  left: 0;
`

export const RightArrow = styled(Arrow)`
  right: 0;
`

export const Slides = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: block;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  scrollbar-color: transparent transparent;
  scrollbar-width: 0px;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`

export const Grid = styled.div<{ $itemMinWidth: number; $width?: number }>`
  ${props =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}

  @media (max-width: ${BREAKPOINT_WIDTH.LG - 1}px) {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${props => props.$itemMinWidth}px, ${props => (props.$width ? '1fr' : '90vw')})
    );
    width: ${props => props.$width ?? '20000'}px;
  }
`

export const Slide = styled.div`
  scroll-snap-align: start;
  flex-shrink: 0;
  position: relative;
`

// grid-row: ${props => props.$gridRow};
// grid-column: ${props => props.$gridColumn};

/*
  .swiper-pagination {
    bottom: 0 !important;
    .swiper-pagination-bullet {
      background: ${props => props.theme.inputPlaceholder};
    }
    .swiper-pagination-bullet-active {
      background: ${props => props.theme.primary};
    }
  }
  */
