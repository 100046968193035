import type { ChannelAuthResponse, UserAuthResponse } from 'pusher'

import {
  AccessTokenClient,
  AuthCode,
  CountryCode,
  IGitHubUser,
  IInvitation,
  IOtpVerification,
  IUser,
  NotificationSecret,
  OtpVerificationCode,
  PhoneNumber
} from '../models'
import { ClientRoutePath } from '../routes'
import { Endpoint, get, post } from '../util/api'
import { TranslatedText, UntranslatedText } from '../util/i18n'

type SignUpFields = {
  username: IUser['username']
  email: IUser['email']
  invitationId?: IInvitation['id'] | null
  firstName?: NonNullable<IUser['firstName']>
  lastName?: NonNullable<IUser['lastName']>
}

export type RequestMagicLink = Endpoint<
  Record<string, never>,
  | { success: true; type: 'TypoSuggestion'; email: IUser['email']; typoSuggestion: TranslatedText }
  | { success: true; type: 'MagicLink' | 'EmailConfirmation'; email: IUser['email'] }
  | { success: true; type: 'EmailConfirmationOnSignUp'; email: IUser['email']; user: IUser },
  { email: IUser['email']; disableTypoCheck?: true; invitationId?: IInvitation['id'] | null }
>

export type ValidateMagicLink = Endpoint<
  Record<string, never>,
  { success: true; user: IUser; redirectUrl?: ClientRoutePath },
  { secret: NotificationSecret }
>

export type CreateAuthCode = Endpoint<
  Record<string, never>,
  { success: true; code: AuthCode },
  { client: AccessTokenClient }
>

export type SignUpWithForm = Endpoint<
  Record<string, never>,
  { success: true; userId: IUser['id']; user: IUser },
  SignUpFields & { password: string }
>

export type SignUpWithPhone = Endpoint<
  Record<string, never>,
  { success: true; userId: IUser['id']; user: IUser },
  SignUpFields & { verificationId: IOtpVerification['id']; verificationCode: OtpVerificationCode }
>

export type Login = Endpoint<
  Record<string, never>,
  { success: true; user: IUser },
  { email: IUser['email']; password: string }
>

export type SignUpWithGitHub = Endpoint<
  Record<string, never>,
  { success: true; user: IUser; created: boolean },
  { code: string }
>

export type GetGitHubConnection = Endpoint<Record<string, never>, { success: true; isConnected: boolean }>

export type ConnectWithGitHub = Endpoint<
  Record<string, never>,
  { success: true; confirmation: UntranslatedText },
  { code: string }
>

export type DisconnectFromGitHub = Endpoint<Record<string, never>, { success: true; confirmation: UntranslatedText }>

export type Logout = Endpoint<Record<string, never>, { success: true }>

export type AuthorizePusherUser = Endpoint<
  Record<string, never>,
  UserAuthResponse & { success: true },
  { socketId: string; socket_id?: string }
>

export type AuthorizePusherChannel = Endpoint<
  Record<string, never>,
  ChannelAuthResponse & { success: true },
  { socketId: string; channelName: string; socket_id?: string; channel_name?: string }
>

export type GetUserGitHubData = Endpoint<Record<string, never>, { success: true; data: IGitHubUser | null }>

export type ConfirmEmail = Endpoint<
  Record<string, never>,
  { success: true; user?: IUser },
  { secret: NotificationSecret }
>

export type GenerateRecoveryCode = Endpoint<
  Record<string, string>,
  { success: true; email: IUser['email'] },
  { email: IUser['email'] }
>

export type UseRecoveryCode = Endpoint<
  Record<string, string>,
  { success: true; code: string; email: IUser['email'] },
  { email: IUser['email']; code: string }
>

export type ResetPassword = Endpoint<
  Record<string, string>,
  { success: true },
  { email: IUser['email']; password: string; code: string }
>

export type AppleAuth = Endpoint<
  Record<string, string>,
  { success: true; user: IUser },
  { id_token: string; firstName?: TranslatedText | null; lastName?: TranslatedText | null }
>

export type FacebookAuth = Endpoint<Record<string, string>, { success: true; user: IUser }, { accessToken: string }>

export type GenerateOtpCode = Endpoint<
  Record<string, string>,
  { success: true; otp: IOtpVerification },
  { countryCode: CountryCode; phoneNumber: PhoneNumber }
>

export type VerifyOtpCode = Endpoint<
  Record<string, string>,
  { success: true; user: IUser | null },
  { id: IOtpVerification['id']; code: OtpVerificationCode }
>

export type GoogleAuth = Endpoint<Record<string, string>, { success: true; user: IUser }, { tokenId: string }>

export const createAuthCode = (client: AccessTokenClient) => post<CreateAuthCode>('/auth/code', { client })

export const continueWithGoogle = (tokenId: string) => post<GoogleAuth>(`/auth/google`, { tokenId })

export const continueWithApple = (
  id_token: string,
  firstName?: TranslatedText | null,
  lastName?: TranslatedText | null
) => post<AppleAuth>(`/auth/apple`, { id_token, firstName, lastName })

export const continueWithFacebook = (accessToken: string) => post<FacebookAuth>(`/auth/facebook`, { accessToken })

export const generateOtpCode = (countryCode: CountryCode, phoneNumber: PhoneNumber) =>
  post<GenerateOtpCode>('/auth/generate-otp-code', { countryCode, phoneNumber })

export const verifyOtpCode = (id: IOtpVerification['id'], code: OtpVerificationCode) =>
  post<VerifyOtpCode>('/auth/verify-otp-code', { id, code })

export const confirmEmail = (secret: NotificationSecret) => post<ConfirmEmail>('/auth/confirm-email', { secret })

export const generateRecoveryCode = (data: { email: IUser['email'] }) =>
  post<GenerateRecoveryCode>(`/auth/generate-code`, data)

export const useRecoveryCode = (data: { email: IUser['email']; code: string }) =>
  post<UseRecoveryCode>(`/auth/use-recovery-code`, data)

export const resetPassword = (data: { email: IUser['email']; code: string; password: string }) =>
  post<ResetPassword>(`/auth/reset-password`, data)

export const requestMagicLink = (info: RequestMagicLink['body'], invitationId?: IInvitation['id'] | null) =>
  post<RequestMagicLink>('/auth/magic-link', { ...info, invitationId })

export const validateMagicLink = (secret: NotificationSecret) =>
  post<ValidateMagicLink>('/auth/validate-magic-link', { secret })

export const signUpWithForm = (info: SignUpWithForm['body'], invitationId?: IInvitation['id'] | null) =>
  post<SignUpWithForm>('/signup', { ...info, invitationId })

export const signUpWithPhone = (info: SignUpWithPhone['body'], invitationId?: IInvitation['id'] | null) =>
  post<SignUpWithPhone>('/signup', { ...info, invitationId })

export const login = (credentials: Login['body']) => post<Login>('/auth/login', credentials)

export const logout = () => post<Logout>('/auth/logout', {})

export const authorizePusherUser = (socketId: string) => post<AuthorizePusherUser>('/auth/pusher/user', { socketId })

export const authorizePusherChannel = (socketId: string, channelName: string) =>
  post<AuthorizePusherChannel>('/auth/pusher/channel', { socketId, channelName })

export const signUpWithGitHub = (info: SignUpWithGitHub['body']) => post<SignUpWithGitHub>('/auth/github', info)

export const getGitHubConnection = () => get<GetGitHubConnection>('/auth/github/connection')

export const connectWithGitHub = (info: ConnectWithGitHub['body']) =>
  post<ConnectWithGitHub>('/auth/github/connect', info)

export const disconnectFromGitHub = () => post<DisconnectFromGitHub>('/auth/github/disconnect', {})

export const getUserGitHubData = () => get<GetUserGitHubData>('/auth/github/data/user')
