import { styled } from '~/lite'

import { View } from '../View/View'

export const PanelToolbar = styled(View)`
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.border};
`
