import React, { Suspense, useState } from 'react'

import { useBreakpoints } from '~/hooks'
import { ButtonAction, isWeb, trackEvent, useOnHydrate } from '~/lite'

import { Button } from '../Button/Button'
import { IShareButtonProps } from './types'

const LazyShareModal = React.lazy(() => import('./ShareModal').then(module => ({ default: module.ShareModal })))

export const ShareButton: React.FC<IShareButtonProps> = ({ action, message, url, text, Component, ...buttonProps }) => {
  const { isPhone } = useBreakpoints()
  const [isMock, setIsMock] = useState(isWeb)
  const [isModalOpen, setIsModalOpen] = React.useState<null | boolean>(null)

  // prefer share modal over native share on desktop (limited without social apps)
  // for ssr hydration, ensure useNativeShare is consistent between client and server on first render
  const useNativeShare = isMock ? false : 'share' in navigator && isPhone

  useOnHydrate(() => {
    setIsMock(false)
  })

  const onPress = async () => {
    if (useNativeShare) {
      try {
        await navigator.share({ text: message, url })
        trackEvent('Share', { ...action, message, url })
      } catch (e) {}
    } else {
      setIsModalOpen(true)
    }
  }

  const buttonAction: ButtonAction = `Share${action.item}`

  const onComponentPress = () => {
    trackEvent('Press', { action: buttonAction, ...(text ? { text } : {}) })
    onPress()
  }

  return (
    <>
      {Component ? (
        <Component onPress={onComponentPress} />
      ) : (
        <Button action={buttonAction} text={text} icon="share-outline" onPress={onPress} {...buttonProps} />
      )}
      {!useNativeShare && isModalOpen !== null && (
        <Suspense fallback={null}>
          <LazyShareModal
            action={action}
            message={message}
            url={url}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </Suspense>
      )}
    </>
  )
}
