import { IPosition } from '../models'
import { Endpoint, post, remove } from '../util/api'

export type CreateBookmarks = Endpoint<Record<string, never>, { success: true }, { positionIds: IPosition['id'][] }>

export type DeleteBookmarks = Endpoint<Record<string, never>, { success: true }, { positionIds: IPosition['id'][] }>

export const createBookmarks = ({ positionIds }: { positionIds: IPosition['id'][] }) =>
  post<CreateBookmarks>('/bookmarks', { positionIds })

export const deleteBookmarks = ({ positionIds }: { positionIds: IPosition['id'][] }) =>
  remove<DeleteBookmarks>('/bookmarks', { positionIds })
