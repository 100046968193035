import { Community, IResumeImportProps, Orb, ResumeImport, SignUpNote, SocialProof } from '@ambition/module-shared'
import React, { useCallback, useState } from 'react'

import { animations, breakpoint, Button, H1, H2, Loader, MetaTags, styled, useStore, View } from '~/lite'

const Wrap = styled(View)`
  flex: 1;
  height: 100%;
`

const ResumeLauncher = styled(View)`
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  padding: 16px 0;
  align-content: stretch;
  align-items: stretch;
  gap: 16px;

  @media ${breakpoint.lg} {
    padding: 8px 0 16px;
  }
`

const LoaderWrap = styled(View)`
  height: calc(100vh - 92px);
`

const OrbWrap = styled(View)`
  justify-content: center;
  align-items: center;
  margin: -64px auto;
  height: 400px;
  animation: 1000ms ease 0s both ${animations.fadeInDown};
  width: 100%;
  overflow: hidden;
`

const Header = styled(H1)`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 40px;
  padding: 0 16px;
  margin-bottom: 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;
  animation: 1000ms ease 0s both ${animations.fadeInDown};

  ::selection {
    -webkit-text-fill-color: #fff;
  }

  @media ${breakpoint.md} {
    font-size: 60px;
  }
`

const Subheader = styled(H2)`
  text-align: center;
  text-wrap: balance;
  hyphens: none;
  color: ${props => (props.theme.dark ? '#b4bcd0' : props.theme.text)};
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;
  animation: 1200ms ease 400ms both ${animations.fadeInDown};
`

const Body = styled(View)`
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  animation: 1400ms ease 800ms both ${animations.fadeInDown};
`

const ResumeImportWrap = styled(View)`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  border-width: 1px;
`

const SignUpNoteWrap = styled.div`
  display: block;
  padding: 16px 8px 0;
  font-size: 11px;
  text-align: center;
  opacity: 0.8;
  text-wrap: balance;

  a:hover {
    text-decoration: underline;
  }
`

interface ILauncherProps {
  onSkipButtonPress?(): void
}

export const Launcher: React.FC<ILauncherProps> = ({ onSkipButtonPress }) => {
  const isAuthenticated = useStore(state => state.isAuthenticated)
  const isLoadingUser = useStore(state => state.isLoadingUser)
  const resumeImportState = useStore(state => state.resumeImportState)

  const [isUploadingResume, setIsUploadingResume] = useState(false)

  const onResumeUploadChange = useCallback(() => setIsUploadingResume(true), [])

  const onResumeImportChange: IResumeImportProps['onChange'] = useCallback(state => {
    if (state === 'RecommendationsAdded') {
      setIsUploadingResume(false)
    }
  }, [])

  const isImportingResume = resumeImportState === 'Pending' || resumeImportState === 'Processing'
  const hasImportedResume = resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success'

  if (!isUploadingResume && !isImportingResume && isAuthenticated && isLoadingUser) {
    return (
      <Wrap>
        <LoaderWrap>
          <Loader />
        </LoaderWrap>
      </Wrap>
    )
  }

  return (
    <Wrap>
      <ResumeLauncher>
        <MetaTags type="Home" />
        <OrbWrap>
          <Orb />
        </OrbWrap>
        <Header text="Find a new job quickly" />
        <Subheader text="Streamline your job search with AI recommendations and easy applications." />
        <Body>
          <ResumeImportWrap>
            <ResumeImport
              variant="Launcher"
              onUploadChange={onResumeUploadChange}
              onChange={onResumeImportChange}
              skipSignupNote
            />
          </ResumeImportWrap>
          {!isImportingResume && !hasImportedResume && (
            <SignUpNoteWrap>
              <SignUpNote style={{ fontSize: 11 }} />
            </SignUpNoteWrap>
          )}
          {!isImportingResume && <Button marginTop={16} text="Skip" onPress={onSkipButtonPress} minimal skipTracking />}
        </Body>
      </ResumeLauncher>
      <Community />
      <SocialProof />
    </Wrap>
  )
}
