import React from 'react'

import { ITeam } from '../models'
import { ClientRoutePath } from '../routes'
import { getAvatarInitials, getGradient } from '../util/helpers'

interface IAvatarProps {
  team: Pick<ITeam, 'logo' | 'name'>
  to: ClientRoutePath
  onPress?(e: React.MouseEvent<HTMLAnchorElement>): void
}

export const Avatar: React.FC<IAvatarProps> = ({ team, to, onPress }) => {
  const logo = team.logo
    ? team.logo.startsWith('http')
      ? team.logo
      : `https://ambitioncdn.com/team-logos/${team.logo}`
    : null

  if (logo) {
    const props = { className: 'avatar', style: { backgroundImage: `url("${logo}")` } }
    return to ? <a href={to} onClick={onPress} title={team.name} {...props} /> : <div {...props} />
  }

  const gradient = getGradient(team.name)
  const initials = getAvatarInitials(team.name ?? '')

  const props: React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement> = {
    className: 'avatar',
    style: {
      whiteSpace: 'nowrap',
      backgroundColor: gradient[0],
      backgroundImage: `linear-gradient(135deg, ${gradient[0]} 10%, ${gradient[1]} 100%)`
    }
  }

  return to ? (
    <a href={to} onClick={onPress} title={team.name} {...props}>
      {initials}
    </a>
  ) : (
    <div {...props}>{initials}</div>
  )
}
