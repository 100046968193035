import { uniq } from 'lodash'
import type Stripe from 'stripe'
import type { ZodTypeAny } from 'zod'

import { ResumeSchema } from './lib/json-resume'
import { Opaque, ReadonlyDeep } from './lib/type-fest'
import { Notifiable, NotificationType } from './notifications'
import { ClientRoutePath } from './routes'
import { SupportedLocaleCode, TranslatedText, UntranslatedText } from './util/i18n'
import { IPositionQuery, JobScope } from './util/query'

export type UUID = string
export type DateString = string
export type URL = string

export const themeNames = ['light', 'dark'] as const

export type ThemeName = typeof themeNames[number]

export type AccessTokenClient = 'OpenAI'

export type AuthCode = Opaque<string, 'AuthCode'>

export type ConnectionCode = Opaque<string, 'ConnectionCode'>

export type SanitizedHtml = Opaque<string, 'SanitizedHtml'>

export type ExternalUrl = Opaque<string, 'ExternalUrl'>

export type NotificationPermissionState = 'NotDetermined' | 'Denied' | 'Authorized' | 'Provisional' | 'Ephemeral'

export type CollectionSlug = Opaque<string, 'CollectionSlug'>

export type Naics2012Code = Opaque<string, 'Naics2012Code'>

export type OnetSocCode = Opaque<string, 'OnetSocCode'>

export type Soc2018Code = Opaque<string, 'Soc2018Code'>

export type Soc2010Code = Opaque<string, 'Soc2010Code'>

export type BasicCanonicalFieldName =
  | 'Address'
  | 'ConsentForJoin'
  | 'ConsentForWorkable'
  | 'Country'
  | 'CoverLetter'
  | 'CurrentCompany'
  | 'Email'
  | 'FamilyName'
  | 'FullName'
  | 'GivenName'
  | 'Level1'
  | 'Level2'
  | 'Locality'
  | 'Phone'
  | 'PostalCode'
  | 'Resume'
  | 'LinkedInURL'
  | 'LinkedInUsername'
  | 'GitHubURL'
  | 'GitHubUsername'
  | 'XURL'
  | 'XUsername'
  | 'WebsiteURL'

export type CanonicalFieldName = BasicCanonicalFieldName | Opaque<string, 'CanonicalFieldName'>

export type NormalizedFieldName = Opaque<string, 'NormalizedFieldName'>

export type FieldId = Opaque<string, 'Field'>

export type ConnectionFieldId = Opaque<string, 'ConnectionField'>

export type GroupedFieldId = Opaque<string, 'GroupedField'>

export type FieldName = Opaque<string, 'FieldName'>

export type FieldValue = ReadonlyDeep<string | string[] | number | boolean>

export type Cip2020Code = Opaque<string, 'Cip2020Code'>

export type LocationCity = Opaque<string, 'LocationCity'>

export type LocationLevel1 = Opaque<string, 'LocationLevel1'>

// ISO 3166-2
export type SubdivisionCode = Opaque<string, 'SubdivisionCode'>

// ISO 3166-1 alpha-2
export type CountryCode = Opaque<string, 'CountryCode'>

export type CurrencyCode = Opaque<string, 'CurrencyCode'>

export type LandingPageSlug = Opaque<string, 'LandingPageSlug'>

export type LocationScope = NonNullable<ILocation['id'] | IUserLocation['id']>

export type LocationScopeWithLabel = `${LocationScope}|${string}`

export type CategorySlug = Opaque<string, 'CategorySlug'>

export type CallingCode = Opaque<`${number}`, 'CallingCode'>

export type PhoneNumber = Opaque<`${number}`, 'PhoneNumber'>

export type NotificationSecret = Opaque<string, 'NotificationSecret'>

export type PhoneNumberWithCallingCode = Opaque<`+${number}`, 'PhoneNumberWithCallingCode'>

export type OtpVerificationCode = Opaque<number, 'OtpVerificationCode'>

export type RedirectApplicationUrl = Opaque<string, 'RedirectApplicationUrl'>

export type ForwardPublicSectionUrl = Opaque<string, 'ForwardPublicSectionUrl'>

export type UserFeedSectionId = Opaque<string, 'UserFeedSectionId'>

export type PublicSectionId = Opaque<string, 'PublicSectionId'>

export type ReactionType = 'None' | 'Like' | 'Dislike'

export type ResumeImportState = 'Pending' | 'Processing' | 'RecommendationsAdded' | 'Success' | 'Error' | 'Retry'

export type UserJobFilterState = 'Pending' | 'Processing' | 'Success' | 'Error' | 'RequestRetry'

export type ScreenerFilterState = 'Pending' | 'Processing' | 'Success' | 'Error' | 'RequestRetry'

export type ScreenerResumeState = 'Init' | 'Pending' | 'Processing' | 'Success' | 'Error' | 'RequestRetry'

export type MarketMembershipState = 'Pending' | 'Declined' | 'Active' | 'Inactive'

export type MarketProductBillingPeriod = 'OneTime' | 'Weekly' | 'Monthly' | 'Yearly'

export type MarketStripeState = 'Pending' | 'Incomplete' | 'ChargesDisabled' | 'PayoutsDisabled' | 'Active'

export type MarketCofounderState = 'Seeking' | 'Open' | 'Closed'

export type ScreenerResumeFilterState =
  | 'Init'
  | 'Pending'
  | 'Processing'
  | 'Success'
  | 'Error'
  | 'QueryError'
  | 'MissingScreenerFilter'
  | 'MissingResumeText'
  | 'RequestRetry'

export type ScreenerResumeCategoryState =
  | 'Init'
  | 'Pending'
  | 'Processing'
  | 'Success'
  | 'Error'
  | 'QueryError'
  | 'MissingJobDescription'
  | 'MissingResumeText'
  | 'RequestRetry'

export type ScreenerResumeFilterValueConfidence = 'NoConfidence' | 'LittleConfidence' | 'Confident' | 'VeryConfident'

export type OpenGraphVariant = 'chatgpt'

export type AssemblyUserToken = Opaque<string, 'AssemblyUserToken'>

export type InterviewId = Opaque<string, 'Interview'>

export type TrackdeskReferral = Opaque<string, 'TrackdeskReferral'>

export type JobScopeWithGooglePlaceId = Opaque<string, 'JobScopeWithGooglePlaceId'>

export type CollectionType =
  | 'Applications'
  | 'Connection'
  | 'Continent'
  | 'Country'
  | 'LatLng'
  | 'Location'
  | 'MapBounds'
  | 'Nearby'
  | 'Private'
  | 'Skill'
  | 'SortBy'
  | 'Remote'
  | 'Team'
  | 'Industry'
  | 'Occupation'

// order is important (used by updateOccupationEducation logic)
export const entryEducations = [
  'None',
  'HighSchool',
  'SomeCollege',
  'PostSecondaryNonDegree',
  'Associates',
  'Bachelors',
  'Masters',
  'Doctorate'
] as const

// order is important (used by updateOccupationEducation logic)
export const entryExperiences = ['None', 'LessThan5Years', '5YearsOrMore'] as const

// order is important (used by updateOccupationEducation logic)
export const onTheJobTrainings = [
  'None',
  'ShortTerm',
  'ModerateTerm',
  'LongTerm',
  'Apprenticeship',
  'InternshipOrResidency'
] as const

export type CollectionIcon = { source: TranslatedText; type: 'Emoji' } | { source: string; type: 'Svg' }

export const compensationFrequencies = ['Hour', 'Day', 'Week', 'Month', 'Year'] as const
export type CompensationFrequency = typeof compensationFrequencies[number]

export type SubscriptionType = 'Collection' | 'DailyEmail' | 'WeeklyCreditEmail'

export const continentCodes = ['AF', 'AN', 'AS', 'EU', 'NA', 'OC', 'SA'] as const
export type ContinentCode = typeof continentCodes[number]

export const experienceLevels = [
  'Experienced',
  'HighSchoolStudent',
  'CollegeStudent',
  'EntryLevel',
  'MidLevel',
  'Manager',
  'SeniorManager',
  'Executive',
  'SeniorExecutive'
] as const

export type ExperienceLevel = typeof experienceLevels[number]

export const joinExperienceLevels: Record<string, ExperienceLevel> = {
  'Entry level': 'EntryLevel',
  Executive: 'Executive',
  Manager: 'Manager',
  'No experience required / Student': 'HighSchoolStudent',
  'Professional / Experienced': 'Experienced'
}

export interface IGoogleLocation {
  latitude: number
  longitude: number
  altitude: number | null
  accuracy: number
  altitudeAccuracy: number | null
  heading: number | null
  speed: number | null
}

export interface IVisitorLocation {
  locationId?: never //LocationScope | null
  latitude: number
  longitude: number
  // accuracy of latitude and longitude in meters
  accuracy: number
  timeZone: string | null
  metroCode: number | null
  cityName: string | null
  level1Name: string | null
  // subdivisionCode: SubdivisionCode | null
  // level1Code: never
  level1Code: string | null
  countryCode: CountryCode | null
  continentCode: string | null
}

export interface IUserPreference {
  preferRemote: boolean
  distanceRadius: number | null
  date: number | null
  compensation: number | null
  compensationFrequency: CompensationFrequency | null
}

export interface ICoverLetterSettings {
  size: 'Short' | 'Medium' | 'Long'
  locale: SupportedLocaleCode | null
  tone: 'Casual' | 'Conversational' | 'Professional' | 'Formal'
  instructions: TranslatedText | null
}

export interface IFeedSection {
  title: TranslatedText
  reason?: TranslatedText | null
  seeAllPath?: ClientRoutePath
  teams?: ITeamDetail[]
  removable?: boolean
  sectionId?: UserFeedSectionId | PublicSectionId | 'new-jobs'

  locale?: string
  country?: string
  source?: 'AIAssistant' | 'ResumeImport'
  userId?: IUser['id']

  searchPhrase?: string | null
  onetSocCode?: OnetSocCode | null
  occupationId?: IOccupation['id'] | null
  positions?: PageableResponse<CompactPositionRecord> | null
  latLng?: [number, number] | null
}

export const profileWebsites = [
  { code: 'website', name: 'Website', icon: 'globe-outline' },
  { code: 'linkedin', name: 'LinkedIn', icon: 'logo-linkedin' },
  { code: 'github', name: 'GitHub', icon: 'logo-github' },
  { code: 'facebook', name: 'Facebook', icon: 'logo-facebook' },
  { code: 'twitter', name: 'Twitter', icon: 'logo-twitter' },
  { code: 'youtube', name: 'YouTube', icon: 'logo-youtube' },
  { code: 'instagram', name: 'Instagram', icon: 'logo-instagram' }
] as const

export type WebsiteCode = typeof profileWebsites[number]['code']

export const PromptTypeValues = ['CoverLetterInstructions', 'JobMacro'] as const

export interface IAutopilotState {
  enabled: boolean
}

export interface IUserPrompt {
  id: Opaque<string, 'UserPromptId'>
  userId: IUser['id']
  promptValue: string
  promptType: typeof PromptTypeValues[number]
}

export type JobMacroData = {
  jobId: CompactPositionRecord['id']
  results: string[] | null
}

export type BreadcrumbNode<T> = {
  record: T | null
  leaf?: boolean
  active?: boolean
  children?: Omit<BreadcrumbNode<T>, 'children'>[]
}

export type Breadcrumb<T> = {
  index: number
  items: BreadcrumbNode<T>[]
}

export interface IContentPreview {
  url: string
  title: TranslatedText | null
  siteName: TranslatedText | null
  description: TranslatedText | null
  mediaType: string
  contentType: string | null
  images: string[]
  favicons: string[]
  videos: {
    url: string | null
    secureUrl: string | null
    type: string | null
    width: string | null
    height: string | null
  }[]
}

export type IContentNode = ReadonlyDeep<
  | {
      tag: string
      attributes: { [name: string]: string }
      text?: TranslatedText
      children?: IContentNode[]
    }
  | TranslatedText
>

export type ContentSectionType = 'Unknown' | 'Role' | 'Responsibilities' | 'Requirements' | 'Benefits'

export type IContentSection = {
  sectionType: ContentSectionType
  introduction?: string
  items: IContentNode[]
  conclusion?: string
}

export interface IRecordStyle {
  padding?: number
  background?: string
  backgroundColor?: string
  filter?: string
  color?: string
}

export interface IAutofillData {
  fieldAliases: Partial<{ [field in CanonicalFieldName]: NormalizedFieldName[] }>
  fieldMarkup: string[]
  fieldQualifiers: string[]
  buttonElementWeights: Record<string, number>
  buttonAttributeWeights: Record<string, number>
  applyButtonKeywordWeights: Record<string, number>
}

export interface IAutofillState {
  values: Partial<Record<CanonicalFieldName, FieldValue>>
}

export interface IMarketProfileMetadata {
  hero?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
    cta: TranslatedText | null
  }
  deliverables?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
    items: { name: TranslatedText }[]
  }
  testimonials?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
    items: { name: TranslatedText; headline: TranslatedText; quote: TranslatedText }[]
  }
  about?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
  }
  plans?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
  }
  products?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
  }
  faq?: {
    header: TranslatedText | null
    subheader: TranslatedText | null
    questions: { question: TranslatedText; answer: TranslatedText }[]
  }
}

export const calendarBookingServices = [
  { code: 'Calendly', name: 'Calendly', url: 'https://calendly.com', placeholder: 'https://calendly.com/luke' },
  { code: 'CalDotCom', name: 'Cal.com', url: 'https://cal.com', placeholder: 'https://cal.com/ricky/intro' }
] as const

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Mutable {
  interface Base<T> {
    id: Opaque<string, T>
    createdAt: Date
    updatedAt: Date | null
  }

  export interface AgentCommand {
    id: Opaque<string, 'AgentCommand'>
    explanation?: TranslatedText
    sideEffects?: MessageSideEffect[]
    state: 'Pending' | 'Success' | 'Error'
  }

  export interface Collection {
    id: Opaque<string, 'Collection'>
    url?: ClientRoutePath | null
    category?: TranslatedText | null
    name: TranslatedText
    query?: IPositionQuery | null
    icon?: string | null
    image?: string | null
    style?: IRecordStyle | null
    children?: Collection[]
    parentCollectionId?: Opaque<string, 'Collection'> | null
    soc2018Code?: Soc2018Code | null
    isPrivate?: boolean
    isActive?: boolean
    userId?: Opaque<string, 'User'> | null
  }

  export interface CollectionPosition {
    id: Opaque<string, 'CollectionPosition'>
    collectionId: ICollection['id']
    positionId: IPosition['id']
    userId: IUser['id'] | null
    deletedAt?: Date | null
  }

  export interface Occupation {
    id: Opaque<string, 'Occupation'>
    slug: Opaque<string, 'OccupationSlug'>
    name: TranslatedText
    simpleName: TranslatedText | null
    icon: string | null
    parentId?: Opaque<string, 'Occupation'>
    isOther?: boolean
    path?: ClientRoutePath
  }

  export interface OccupationDetail extends Occupation {
    collections: ICollection[]
  }

  export interface Comment extends Base<'Comment'> {
    author: { id: IUser['id']; type: 'User' } | { id: Opaque<string, 'OpenAiCompletion'>; type: 'System' }
    comment: string | null
    positionId?: IPosition['id']
    threadId?: IComment['id'] | null
    replyToId?: IComment['id'] | null
    likesCount?: number
    dislikesCount?: number
    deletedAt?: Date | null
    replies?: Comment[]
  }

  export interface Compensation {
    min: number | null
    max: number | null
    currency: CurrencyCode | null
    frequency: CompensationFrequency | null
    note?: string | null
    type?: 'Estimate'
  }

  export interface Connection extends Base<'Connection'> {
    code: ConnectionCode
    name: string
    isActive: boolean
  }

  export interface Continent {
    code: Opaque<typeof continentCodes[number], 'ContinentCode'>
    name: TranslatedText
  }

  export interface CollectionForm {
    name: TranslatedText
  }

  export interface Country {
    code: CountryCode
    name: TranslatedText
    emoji: TranslatedText
    bbox?: [number, number, number, number] | null
    noIcon?: boolean
    callingCode: CallingCode
    continent: Continent['code']
  }

  export interface CoverLetter extends Base<'CoverLetter'> {
    userId: User['id']
    resume: string
    jobTitle: string | null
    jobDescription: string | null
    settings: ICoverLetterSettings | null
    coverLetter: string | null
    state: 'Pending' | 'Success' | 'Error'
  }

  export interface CoverLetterTone extends Base<'CoverLetterTone'> {
    name: TranslatedText
    icon: string
    keywords: TranslatedText[]
    explanation: TranslatedText
    useCase: TranslatedText
    strengths: TranslatedText
    caveats: TranslatedText
    suitability: TranslatedText
    difference: TranslatedText
    intensities: TranslatedText[]
  }

  export interface DashboardLocation {
    id: Opaque<string, 'Location'>
    latLng: [number, number] | null
    city: LocationCity | null
    level1: LocationLevel1 | null
    country: CountryCode | null
  }

  export interface DashboardPosition extends Base<'Position'> {
    title: TranslatedText
    locationId: DashboardLocation['id'] | null
    teamId: DashboardTeam['id'] | null
    occupationId: IOccupation['id'] | null
    postedAt: Date
    allowRemote: boolean
  }

  export interface DashboardTeam {
    id: Opaque<string, 'Team'>
    name: TranslatedText
  }

  export interface Device {
    id: Opaque<string, 'Device'>
    oneSignalId: string
    hasNotificationPermission: boolean | null
    notificationPermissionState: NotificationPermissionState | null
  }

  export interface Experience {
    skillSlug: Skill['slug']
    minYears: number | null
    maxYears: number | null
    kind: 'Required' | 'Optional' | null
  }

  export interface ExtensionSite {
    domain?: string
    part?: string
  }

  export interface ExtensionState {
    data: {
      autofill: IAutofillState
    }
  }

  export interface InterviewQuestion extends Base<'InterviewQuestion'> {
    question: string
    questionHash: Opaque<string, 'JobQuestionHash'>
    voiceSource: 'elevenlabs'
    voiceFilePath: string
  }

  export interface Interview extends Base<'Interview'> {
    applicationId: IApplication['id'] | null
    audioFile: string | null
  }

  export interface InterviewLogMessage extends Base<'InterviewLogMessage'> {
    interviewId: Interview['id']
    role: 'user' | 'assistant'
    message: string
    audioDuration: number | null
    relatedQuestionId: InterviewQuestion['id'] | null
  }

  export interface Location {
    id?: Opaque<string, 'Location'>
    slug: Opaque<string, 'LocationSlug'>
    slugNew: Opaque<string, 'LocationSlug'> | null
    name: TranslatedText
    photo: string | null
    streetAddress: string | null
    city: LocationCity | null
    level1: LocationLevel1 | null
    postalCode: string | null
    country: Country | null
    latLng?: [number, number] | null
    distance?: number | null
    jobCount?: number | null
  }

  export interface MarketDirectory {
    profiles: {
      id: IMarketProfile['id']
      companyName: TranslatedText | null
      username: Opaque<string, 'MarketProfileUsername'>
      country: CountryCode
      locale: SupportedLocaleCode
      cofounderState: MarketCofounderState
      cofounderNote: TranslatedText | null
      createdAt: Date
    }[]
    people: {
      id: IMarketPerson['id']
      firstName: TranslatedText | null
      lastName: TranslatedText | null
      headline: TranslatedText | null
      country: CountryCode
    }[]
    memberships: MarketMembership[]
  }

  export interface MarketMembership extends Base<'MarketMembership'> {
    marketProfileId: IMarketProfile['id']
    marketPersonId: IMarketPerson['id']
    note: TranslatedText | null
    state: MarketMembershipState
  }

  export interface MarketPerson extends Base<'MarketPerson'> {
    firstName: TranslatedText | null
    lastName: TranslatedText | null
    headline: TranslatedText | null
    bio: TranslatedText | null
    country: CountryCode
    websites: { code: WebsiteCode; url: string }[] | null
    resume: IResume | null
  }

  export interface MarketPrice extends Base<'MarketPrice'> {
    marketProductId: MarketProduct['id']
    amount: number
    currency: CurrencyCode
    billingPeriod: MarketProductBillingPeriod
  }

  export interface MarketProduct extends Base<'MarketProduct'> {
    marketProfileId: MarketProfile['id'] | null
    name: TranslatedText
    description: TranslatedText | null
    features: { name: TranslatedText }[]
    estimateMin: number | null
    estimateMax: number | null
    price: number | null
    currency: CurrencyCode | null
    isRecurring: boolean
    position: number
    prices: IMarketPrice[]
  }

  export interface MarketProfile extends Base<'MarketProfile'> {
    businessType: 'Individual' | 'Company'
    companyName: TranslatedText | null
    username: Opaque<string, 'MarketProfileUsername'>
    avatar: string | null
    metadata: IMarketProfileMetadata
    hourlyRate: number
    currency: CurrencyCode
    affiliateRate: number
    country: CountryCode
    cofounderState: MarketCofounderState
    cofounderNote: TranslatedText | null
    secondsToGenerate: number | null
    calendarBookingUrl: string | null
    setupVersion: number | null
    state: 'Pending' | 'Generating' | 'Draft' | 'Live'
    memberships: IMarketMembership[]
    people: IMarketPerson[]
    products: IMarketProduct[]

    // private fields
    stripeState?: MarketStripeState
  }

  export interface Membership extends Base<'Membership'> {
    teamId: Team['id']
    userId: User['id']
  }

  export interface Person extends Base<'Person'> {
    firstName: string
    lastName: string
    email: string
  }

  export interface Invitation extends Base<'Invitation'> {
    email: IUser['email']
    status: string
    teamName: ITeam['name']
    teamUsername: ITeam['username']
    invitingUser: IUser['username']
    teamId: ITeam['id']
  }

  interface NotificationBase extends Base<'Notification'> {
    header: TranslatedText
    message: TranslatedText
    icon: string | null
    route: ClientRoutePath | null
    badgeCount: number
    readAt: Date | null
  }

  export type Notification = NotificationBase & Notifiable & NotificationType

  export interface PositionPartial extends Base<'Position'> {
    teamId?: Team['id']
    // compensation: Compensation
    title: TranslatedText
    allowRemote: boolean | null
    allowEmployee: boolean | null
    allowContract: boolean | null
    experienceLevel: ExperienceLevel | null
    applicantCountry: CountryCode | null
    employeeCompensation: Compensation | null
    contractCompensation: Compensation | null
    compensation: Compensation | null
    location: TranslatedText | null
    remoteLocation: {
      type: 'PostalAddress'
      streetAddress: string | null
      postOfficeBoxNumber: string | null
      locality: string | null
      region: string | null
      postalCode: string | null
      country: string | null
    } | null
    placeId?: string | null
    summary: TranslatedText | null
    description: SanitizedHtml | null
    sections: IContentSection[] | null
    experiences: Experience[]
    viewUrl?: ExternalUrl | null
    applicationUrl: RedirectApplicationUrl | null
    applicationEmail: string | null
    applicationInstructions: string | null
    postedAt: Date
    locationId?: string
    isExternal?: boolean | null
    adNetwork?: Opaque<string, 'AdNetwork'>
    stripeProductId?: IStripeProduct['id'] | null
    promoteProductPaid?: boolean | null
    productPriority?: number | null
    screenerEmail: string | null
    latLng?: [lat: number, lng: number] | null
    deletedAt?: Date | null
    remoteExpiresAt?: Date | null
    socCode?: Soc2018Code | null
    interviewState?: 'Public' | 'Private' | 'Disabled' | null
    showDetailPage?: boolean
  }

  export interface Position extends PositionPartial {
    remoteId?: string
    category?: string
    remoteCreatedAt?: Date | null
    stripeProductId?: IStripeProduct['id'] | null
    promoteProductPaid?: boolean | null
    productPriority?: number | null
    questions: ApplicationForm | null
  }

  export interface Resume extends Base<'Resume'> {
    personId: Person['id']
  }

  export interface Skill extends Base<'Skill'> {
    slug: Opaque<string, 'SkillSlug'>
    name: TranslatedText
    icon: string | null
    style?: IRecordStyle
    children?: Skill['slug'][]
  }

  export interface Snapshot extends Base<'Snapshot'> {
    connectionId: Connection['id']
    log: string | null
  }

  export interface TeamPartial extends Base<'Team'> {
    locationId?: Opaque<string, 'Location'> | Opaque<number, 'Location'> | null
    username: Opaque<string, 'TeamUsername'>
    name: TranslatedText
    website: TranslatedText | null
    screenerEmail: string | null
    summary?: TranslatedText | null
    summaryRaw?: string | null
    logo: string | null
    banner?: string | null
    location: TranslatedText | null
    placeId?: string | null
    about: SanitizedHtml | null
    isSlackIntegrated?: boolean
    clearbitLatLng?: { lat: string | null; lng: string | null } | null | undefined
    isMember?: boolean
    autoProcessScreenerAnalytics?: boolean | null
  }

  export interface UserPartial extends User {
    isSlackIntegrated?: boolean
    isMember?: boolean
    fullName?: string | null
  }

  export interface Team extends TeamPartial {
    in5Words: TranslatedText | null
    bio: TranslatedText | null
  }

  export interface User extends Base<'User'> {
    username: Opaque<string, 'UserUsername'>
    email: Opaque<string, 'UserEmail'>
    avatar?: string | null
    githubId: string | null
    githubData: string | null
    teams?: ITeam[] | null
    firstName?: TranslatedText | null
    lastName?: TranslatedText | null
    location?: TranslatedText | null
    locale?: SupportedLocaleCode
    about?: TranslatedText | null
    website?: TranslatedText | null
    resume?: string | null
    universityId?: IUniversity['id'] | null
    universityName?: IUniversity['name'] | null
    studyYear?: number | null
    studyDegree?: TranslatedText | null
    experienceCompany?: TranslatedText | null
    experienceStartMonth?: number | null
    experienceEndMonth?: number | null
    experienceStartYear?: number | null
    experienceEndYear?: number | null
    emailConfirmedAt?: Date | null
    autopilotState: IAutopilotState | null
    types: ('JobSeeker' | 'Employer')[]
  }

  export interface GitHubUser {
    id: string
    name: string
    username: TranslatedText
    company: string | null
    user_avatar: string
    location: string | null
    email: string
    followers: number | null
    following: number | null
    totalPublicRepos: number | null
    privateRepos?: number | null
    ownedPrivateRepos?: number | null
  }

  export interface Application extends Base<'Application'> {
    positionId: IPosition['id'] | ITalentJob['jobid']
    userId: IUser['id']
    statusId: IApplicationStatus['id'] | null
    positionTitle: IPosition['title']
    teamName: ITeam['name']
    username: IUser['username']
    email: IUser['email']
    interviewId?: Interview['id'] | null
  }

  export interface ApplicationStatus extends Base<'ApplicationStatus'> {
    name: TranslatedText
    deletedAt?: Date | null
  }

  export interface University extends Base<'University'> {
    name: TranslatedText
    state?: string | null
    country?: string
  }

  export interface Channel extends Base<'Channel'> {
    userId: User['id']
    teamId: Team['id'] | null
    positionId: Position['id'] | null
    name: TranslatedText | null
    user: Pick<User, 'username' | 'firstName' | 'lastName'> | null
    team: Pick<Team, 'name'> | null
    position: Pick<Position, 'title'> | null
    lastMessage: Message | null
    type: 'Assistant' | null
    messages?: Message[]
    replaces?: Channel['id'] | null
  }

  export interface ChannelMember extends Base<'ChannelMember'> {
    channelId: Channel['id']
    userId?: User['id']
    teamId?: Team['id']
    user: User | null
    team: Team | null
  }

  export interface Subscription extends Base<'Subscription'> {
    userId: User['id']
    collectionId: Collection['id'] | null
    collection: Pick<Collection, 'id' | 'name' | 'url' | 'icon'> | null
    query: IPositionQuery | null
    lastSentNotificationAt?: Date | null
    lastViewedNotificationAt?: Date | null
    type: SubscriptionType
  }

  export type ApplicationField = {
    id: FieldId
    label: TranslatedText
    canonical: CanonicalFieldName | null
    required: boolean
    defaultValue?: string
    veryShortLabel?: string
  } & (
    | { type: 'Text'; maxLength?: number; variant?: 'Resume' | 'CoverLetter' | 'Location' }
    | { type: 'Email'; maxLength: number }
    | { type: 'Phone'; maxLength: number; helper?: string }
    | { type: 'TextArea' }
    | { type: 'Checkbox'; options: { label: string; value: string; isHtml?: boolean }[] }
    | { type: 'Radio'; options: { label: string; value: string }[] }
    | { type: 'Select'; options: { label: string; value: string }[]; allowMultiple: boolean }
    | {
        type: 'File'
        maxFileSize: number
        supportedMimeTypes?: string[]
        supportedFileTypes?: string[]
        variant?: 'Resume' | 'CoverLetter'
      }
    | { type: 'Date' }
    | { type: 'Number'; minValue?: number; maxValue?: number; step?: number }
    | { type: 'Group'; fields: Exclude<ApplicationField, GroupField>[] }
    | { type: 'Hidden' }
    | { type: 'Other'; data: any }
    | { type: 'Boolean' }
  )

  export type TextField = Extract<ApplicationField, { type: 'Text' }>
  export type FileField = Extract<ApplicationField, { type: 'File' }>
  export type GroupField = Extract<ApplicationField, { type: 'Group' }>

  export type ApplicationFieldWithAnswer =
    | (Exclude<ApplicationField, GroupField> & { answer?: FieldValue })
    | (Exclude<GroupField, 'fields'> & {
        fields: (GroupField['fields'][number] & { answer?: FieldValue })[]
        answer?: Record<string, FieldValue>[]
      })

  export interface ApplicationForm {
    sections: { title: string | null; fields: ApplicationField[] }[]
  }

  export interface ApplicationFormWithAnswers {
    sections: { title: string | null; fields: ApplicationFieldWithAnswer[] }[]
  }

  export interface CollectionHeader {
    text: TranslatedText | null
    icon?: CollectionIcon | CollectionIcon[] | null
  }

  export interface MapBounds {
    north: number
    south: number
    east: number
    west: number
  }

  export interface StripeProduct {
    id: Opaque<string, 'StripeProduct'>
    active: boolean
    created: number
    description: TranslatedText | null
    name: TranslatedText
    images: string[]
    livemode: boolean
    metadata: Record<string, string>
    type: 'good' | 'service'
    unitLabel: string | null
    updated: number
    url: string | null
    price: IStripePrice | null
  }

  export interface StripePrice {
    id: Opaque<string, 'StripePrice'>
    active: boolean
    billingScheme: 'per_unit' | 'tiered'
    created: number
    // Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
    // Must be a [supported currency](https://stripe.com/docs/currencies).
    currency: string
    livemode: boolean
    metadata: Record<string, string>
    nickname: string | null
    taxBehavior: 'exclusive' | 'inclusive' | 'unspecified' | null
    type: 'one_time' | 'recurring'
    unitAmount: number | null
    unitAmountDecimal: string | null
  }

  export interface StripeCheckoutSession {
    id: Opaque<string, 'StripeCheckoutSession'>
    url: string | null
  }

  export interface Company {
    name: TranslatedText
    domain: string
    logo: string
  }

  export interface SearchResult {
    url?: ClientRoutePath
    icon?: string | null
    name: TranslatedText
    type?: string
  }

  export interface Message extends Base<'Message'> {
    channelId: Channel['id']
    userId: User['id'] | null
    message: TranslatedText
    commands?: AgentCommand[] | null
    attachments?: MessageAttachment[] | null
    attachmentUrl?: string | null
    role?: 'Assistant' | 'Greeting'
    state?: 'Ghost' | 'Pending' | 'Success' | 'Error' | null
    replaces?: Message['id'] | null
  }

  export type MessageAttachment = {
    id?: Opaque<string, 'MessageAttachment'>
    type: 'Link'
    route?: ClientRoutePath
    title?: TranslatedText
    subtitle?: TranslatedText
    tags?: TranslatedText[]
  }

  export type MessageSideEffect =
    | { type: 'NewFeedSectionAdded'; jobScope?: JobScope }
    | { type: 'GoTo'; url?: URL; path?: ClientRoutePath; replace?: boolean }
    | { type: 'HandleLogin'; user: User; skipRedirect?: boolean }
    | { type: 'InvalidateQueries'; queryKeys: unknown[] | null }
    | { type: 'LoadUserData' }

  export interface CreateRecentSearch {
    search: SearchResult
  }

  export interface RecentSearch {
    id: Opaque<string, 'RecentSearch'>
    search: SearchResult
  }

  export interface OtpVerification {
    id: Opaque<string, 'OtpVerification'>
  }

  export interface UserLocation {
    id: Opaque<string, 'UserLocation'>
  }

  export interface StripeSubscription {
    id: Opaque<string, 'StripeSubscription'>
    status: Stripe.Subscription.Status | null
    currentPeriodEnd?: Date
    currentPeriodStart?: Date
  }

  export interface ScreenerFilter {
    id: Opaque<string, 'ScreenerFilter'>
    jobDescription: string
    screenerFields: ApplicationField[] | null
    state: ScreenerFilterState
  }

  export interface UserJobFilter {
    id: Opaque<string, 'UserJobFilter'>
    fields: ApplicationField[] | null
    state: UserJobFilterState
    isActive: boolean
  }

  export interface ScreenerResume {
    id: Opaque<string, 'ScreenerResume'>
    teamId: Team['id']
    fileUrl: string
    fileType: 'pdf' | 'docx' | 'txt' | null
    html: string | null
    markdown: string | null
    plainText: string | null
    jsonResume: IJsonResumeSchema | null
    parsingstate: ScreenerResumeState

    // screener filter values
    filterValuesState: ScreenerResumeFilterState
    filterValues: ScreenerResumeFilterResult | null

    // screener category
    categoryState: ScreenerResumeCategoryState
    category: { id: ScreenerCategory['id']; explanation: TranslatedText | null } | null

    // client-side fields
    rank?: number | null
  }

  export type ScreenerResumeFilterResult = Record<
    string,
    {
      answer: string | string[] | number | boolean
      confidence: ScreenerResumeFilterValueConfidence
      reasoning: string
    }
  >

  export type ScreenerResumeCategoryResult = {
    id: ScreenerCategory['id']
    category: string
    explanation: TranslatedText
  }

  export interface ScreenerInterviewQuestionRequest {
    id: Opaque<string, 'ScreenerInterviewQuestionRequest'>
    positionId: Position['id']
    teamId: Team['id']
    positionDescription: string
    questions: ScreenerInterviewQuestion[]
  }

  export interface ScreenerInterviewQuestion {
    id: Opaque<string, 'ScreenerInterviewQuestion'>
    question: string
    questionIndex: number
  }

  export interface ScreenerCategory {
    id: Opaque<string, 'ScreenerCategory'>
    name: TranslatedText
    description: TranslatedText
    position: number
  }

  export type AutopilotEvaluation = {
    id: Opaque<string, 'AutopilotEvaluation'>
    categoryId: ScreenerCategory['id']
    category: ScreenerCategory['name']
    matchRating: 'Bad' | 'OK' | 'Good' | 'Great'
    explanation: TranslatedText
  }
}

export type IAgentCommand = ReadonlyDeep<Mutable.AgentCommand>
export type ICollection = ReadonlyDeep<Mutable.Collection>
export type ICollectionPosition = ReadonlyDeep<Mutable.CollectionPosition>
export type IOccupation = ReadonlyDeep<Mutable.Occupation>
export type IOccupationDetail = ReadonlyDeep<Mutable.OccupationDetail>
export type IComment = ReadonlyDeep<Mutable.Comment>
export type IConnection = ReadonlyDeep<Mutable.Connection>
export type IContinent = ReadonlyDeep<Mutable.Continent>
export type ICountry = ReadonlyDeep<Mutable.Country>
export type ICompensation = ReadonlyDeep<Mutable.Compensation>
export type ICoverLetter = ReadonlyDeep<Mutable.CoverLetter>
export type ICoverLetterTone = ReadonlyDeep<Mutable.CoverLetterTone>
export type IDashboardLocation = ReadonlyDeep<Mutable.DashboardLocation>
export type IDashboardPosition = ReadonlyDeep<Mutable.DashboardPosition>
export type IDashboardTeam = ReadonlyDeep<Mutable.DashboardTeam>
export type IDevice = ReadonlyDeep<Mutable.Device>
export type IExperience = ReadonlyDeep<Mutable.Experience>
export type IExtensionSite = ReadonlyDeep<Mutable.ExtensionSite>
export type IExtensionState = ReadonlyDeep<Mutable.ExtensionState>
export type IInterview = ReadonlyDeep<Mutable.Interview>
export type IInterviewLogMessage = ReadonlyDeep<Mutable.InterviewLogMessage>
export type ILocation = ReadonlyDeep<Mutable.Location>
export type IMarketDirectory = ReadonlyDeep<Mutable.MarketDirectory>
export type IMarketMembership = ReadonlyDeep<Mutable.MarketMembership>
export type IMarketPerson = ReadonlyDeep<Mutable.MarketPerson>
export type IMarketPrice = ReadonlyDeep<Mutable.MarketPrice>
export type IMarketProduct = ReadonlyDeep<Mutable.MarketProduct>
export type IMarketProfile = ReadonlyDeep<Mutable.MarketProfile>
export type IMembership = ReadonlyDeep<Mutable.Membership>
export type INotification = ReadonlyDeep<Mutable.Notification>
export type IPerson = ReadonlyDeep<Mutable.Person>
export type IPositionPartial = ReadonlyDeep<Mutable.PositionPartial>
export type IPosition = ReadonlyDeep<Mutable.Position>
export type ISkill = ReadonlyDeep<Mutable.Skill>
export type ISnapshot = ReadonlyDeep<Mutable.Snapshot>
export type ITeamPartial = ReadonlyDeep<Mutable.TeamPartial>
export type ITeam = ReadonlyDeep<Mutable.Team>
export type IUser = ReadonlyDeep<Mutable.User>
export type IGitHubUser = ReadonlyDeep<Mutable.GitHubUser>
export type IInvitation = ReadonlyDeep<Mutable.Invitation>
export type IApplication = ReadonlyDeep<Mutable.Application>
export type IApplicationStatus = ReadonlyDeep<Mutable.ApplicationStatus>
export type IUniversity = ReadonlyDeep<Mutable.University>
export type ICollectionForm = ReadonlyDeep<Mutable.CollectionForm>
export type IApplicationForm = ReadonlyDeep<Mutable.ApplicationForm>
export type IApplicationFormWithAnswers = ReadonlyDeep<Mutable.ApplicationFormWithAnswers>
export type IFileField = ReadonlyDeep<Mutable.FileField>
export type ITextField = ReadonlyDeep<Mutable.TextField>
export type IGroupField = ReadonlyDeep<Mutable.GroupField>
export type IApplicationField = ReadonlyDeep<Mutable.ApplicationField>
export type IApplicationFieldWithAnswer = ReadonlyDeep<Mutable.ApplicationFieldWithAnswer>
export type ICollectionHeader = ReadonlyDeep<Mutable.CollectionHeader>
export type IMapBounds = ReadonlyDeep<Mutable.MapBounds>
export type IStripeProduct = ReadonlyDeep<Mutable.StripeProduct>
export type IStripePrice = ReadonlyDeep<Mutable.StripePrice>
export type IStripeCheckoutSession = ReadonlyDeep<Mutable.StripeCheckoutSession>
export type IBasicProduct = ReadonlyDeep<ReadonlyDeep<{ id: null } & Omit<Mutable.StripeProduct, 'id'>>>
export type IProduct = IBasicProduct | IStripeProduct
export type IChannel = ReadonlyDeep<Mutable.Channel>
export type IChannelMember = ReadonlyDeep<Mutable.ChannelMember>
export type ISubscription = ReadonlyDeep<Mutable.Subscription>
export type ICompany = ReadonlyDeep<Mutable.Company>
export type ISearchResult = ReadonlyDeep<Mutable.SearchResult>
export type IMessage = ReadonlyDeep<Mutable.Message>
export type IMessageAttachment = ReadonlyDeep<Mutable.MessageAttachment>
export type IMessageSideEffect = ReadonlyDeep<Mutable.MessageSideEffect>
export type IRecentSearch = ReadonlyDeep<Mutable.RecentSearch>
export type ICreateRecentSearch = ReadonlyDeep<Mutable.CreateRecentSearch>
export type IOtpVerification = ReadonlyDeep<Mutable.OtpVerification>
export type IUserLocation = ReadonlyDeep<Mutable.UserLocation>
export type IUserJobFilter = ReadonlyDeep<Mutable.UserJobFilter>
export type IStripeSubscription = ReadonlyDeep<Mutable.StripeSubscription>
export type IScreenerCategory = ReadonlyDeep<Mutable.ScreenerCategory>
export type IScreenerFilter = ReadonlyDeep<Mutable.ScreenerFilter>
export type IScreenerResume = ReadonlyDeep<Mutable.ScreenerResume>
export type IScreenerResumeFilterResult = ReadonlyDeep<Mutable.ScreenerResumeFilterResult>
export type IScreenerResumeCategoryResult = ReadonlyDeep<Mutable.ScreenerResumeCategoryResult>
export type IScreenerInterviewQuestionRequest = ReadonlyDeep<Mutable.ScreenerInterviewQuestionRequest>
export type IScreenerInterviewQuestion = ReadonlyDeep<Mutable.ScreenerInterviewQuestion>

export const newPositionFields = [
  'remoteId',
  'title',
  'summary',
  'description',
  'category',
  'allowRemote',
  'allowEmployee',
  'allowContract',
  'employeeCompensation',
  'contractCompensation',
  'location',
  'placeId',
  'remoteCreatedAt',
  'deletedAt',
  'teamId',
  'experiences',
  'applicationUrl',
  'applicationEmail',
  'applicationInstructions',
  'isExternal',
  'stripeProductId',
  'promoteProductPaid',
  'productPriority',
  'questions',
  'interviewState',
  'screenerEmail'
] as const

export const newCollectionFields = [
  'url',
  'parentCollectionId',
  'name',
  'style',
  'icon',
  'isPrivate',
  'userId',
  'category',
  'query',
  'soc2018Code'
] as const

export const newMarketMembershipFields = ['marketProfileId', 'marketPersonId', 'note'] as const

export const newMarketPersonFields = ['firstName', 'lastName', 'headline', 'bio', 'websites'] as const

export const newMarketProfileFields = [
  'businessType',
  'companyName',
  'username',
  'metadata',
  'hourlyRate',
  'currency',
  'affiliateRate',
  'country'
] as const

export const newMarketProductFields = [
  'marketProfileId',
  'name',
  'description',
  'features',
  'estimateMin',
  'estimateMax',
  'price',
  'currency',
  'isRecurring'
] as const

export const newCommentFields = ['positionId', 'replyToId', 'comment'] as const
export const newDeviceFields = ['hasNotificationPermission', 'notificationPermissionState'] as const
export const newExperienceFields = ['skillSlug', 'minYears', 'maxYears', 'kind'] as const
export const newSkillFields = ['slug', 'name', 'icon', 'style', 'children'] as const
export const newSubscriptionFields = ['userId', 'collectionId', 'query', 'type'] as const
export const newTeamFields = [
  'username',
  'name',
  'website',
  'logo',
  'screenerEmail',
  'location',
  'placeId',
  'about'
] as const
export const newUserFields = [
  'username',
  'firstName',
  'lastName',
  'website',
  'location',
  'locale',
  'about',
  'fullName',
  'universityName',
  'universityId',
  'studyYear',
  'studyDegree',
  'experienceCompany',
  'experienceStartMonth',
  'experienceEndMonth',
  'experienceStartYear',
  'experienceEndYear'
] as const

export const StripeUserServices = ['ambition-plus', 'jobs-api'] as const
export const StripeTeamServices = ['job-posting', 'promote-position', 'screener', 'team-interview'] as const
export type StripeUserService = typeof StripeUserServices[number]
export type StripeTeamService = typeof StripeTeamServices[number]
export type StripeService = StripeUserService | StripeTeamService

export type INewCollection = ReadonlyDeep<Pick<Mutable.Collection, typeof newCollectionFields[number]>>
export type INewComment = ReadonlyDeep<Pick<Mutable.Comment, typeof newCommentFields[number]>>
export type INewDevice = ReadonlyDeep<Pick<Mutable.Device, typeof newDeviceFields[number]>>
export type INewPosition = ReadonlyDeep<Pick<Mutable.Position, typeof newPositionFields[number]>>
export type INewExperience = ReadonlyDeep<Pick<Mutable.Experience, typeof newExperienceFields[number]>>
export type INewSkill = Pick<Mutable.Skill, typeof newSkillFields[number]>
export type INewSubscription = ReadonlyDeep<Pick<Mutable.Subscription, typeof newSubscriptionFields[number]>>
export type INewMarketMembership = ReadonlyDeep<
  Pick<Mutable.MarketMembership, typeof newMarketMembershipFields[number]>
>
export type INewMarketPerson = ReadonlyDeep<Pick<Mutable.MarketPerson, typeof newMarketPersonFields[number]>>
export type INewMarketProduct = ReadonlyDeep<Pick<Mutable.MarketProduct, typeof newMarketProductFields[number]>>
export type INewMarketProfile = ReadonlyDeep<Pick<Mutable.MarketProfile, typeof newMarketProfileFields[number]>>
export type INewTeam = ReadonlyDeep<
  Omit<Pick<Mutable.Team, typeof newTeamFields[number]>, 'about'> & {
    statuses?: TranslatedText[]
    about: string | null
    email?: string | null
  }
>
export type INewUser = Pick<Mutable.UserPartial, typeof newUserFields[number]>

export type Pageable = {
  total?: number
  nextPage: number | null
}

export type PageableResponse<T> = {
  data: T[]
  pageable: Pageable
}

export interface OpenAiData {
  results?: {
    tags?: string[] | null
    header?: string | null
    summary?: string | null
    benefits?: string | null
    requirements?: string | null
    uniqueAspect?: string | null
    imageDescription?: string | null
  } | null
  imageResultsId?: string | null
  videoResultsId?: string | null
  type:
    | 'GenerateCoverLetter'
    | 'RunJobMacros'
    | 'RunJobMacrosForEmail'
    | 'EnhanceTeams'
    | 'GenerateJsonResume'
    | 'GenerateMarketContent'
    | 'GenerateMarketProducts'
    | 'GenerateMarketProfile'
    | 'GenerateMessage'
    | 'GetJobTitleSocCodes'
    | 'PositionEnhancement'
    | 'PositionQ&A'
    | 'ResumeAnalysis'
    | 'ResumeEvaluation'
    | 'ResumeJobTitleRecommendations'
    | 'ResumeRecommendations'
    | 'TeamQ&A'
    | 'SpeechFromText'
    | 'GenerateInterviewQuestions'
    | 'InterviewChat'
    | 'GenerateScreenerFilters'
    | 'GenerateScreenerFilterValues'
    | 'CategorizeScreenerResume'
    | 'EvaluateAutopilot'
}

export const UserActivityEntityType = {
  COLLECTION: 'collection',
  POSITION: 'position'
} as const

export const UserActivityWeight = {
  VIEW: 1,
  TALK_TO_THE_TEAM: 2,
  SAVED: 3,
  APPLY: 4
} as const

export type CompactPositionRecord = Pick<IPosition, 'id' | 'title' | 'summary' | 'location' | 'postedAt'> & {
  skills?: Pick<ISkill, 'name' | 'icon'> | null
  team: ITeamDetail
  totalViewsCount?: number
  lastVisitedAt?: Date
  totalSubmission?: number
  compensation?: ICompensation | null
  extra?: {
    enhancements?: OpenAiData | null
  }
  applicationUrl?: RedirectApplicationUrl | null
  cityName?: LocationCity | null
  level1Name?: LocationLevel1 | null
  countryCode?: CountryCode | null
  entryEducation?: typeof entryEducations[number]
  entryExperience?: typeof entryExperiences[number]
  macroResults?: string[] | null
  allowRemote: boolean
  showDetailPage?: boolean
  hasForm: boolean
  promoteProductPaid: boolean

  source?: 'talent' | 'talroo' | IConnection['code']

  filteredDistance?: number
} & OesCompensationResponse

export type CompactCollectionRecord = Pick<ICollection, 'id' | 'url' | 'name' | 'style' | 'icon'>

export interface IPositionPartialDetail extends IPositionPartial, OesCompensationResponse {
  teamDetail: ITeam
  locationDetail: ILocation | null
  hasForm: boolean
  commentsCount: number

  occupationName?: TranslatedText
  occupationCode?: OnetSocCode | Soc2018Code
  industry?: { name: TranslatedText; englishName: string }
  language?: string | null
  saved?: boolean
  extra?: {
    enhancements?: OpenAiData | null
  }
  redirect?: ClientRoutePath
}

export interface ICombinedPositionData {
  positions: IPositionPartialDetail[]
  locationsById: { [id: string]: ILocation }
  nextPage: number | null
}

type MarkTranslated<T> = {
  [P in keyof T]: T[P] extends string
    ? TranslatedText
    : T[P] extends string | undefined
    ? TranslatedText | undefined
    : MarkTranslated<T[P]>
}

export type IJsonResumeSchema = MarkTranslated<ResumeSchema>

export interface IResumeAdditionalProperties {
  [k: string]: any
}

export type IResume = Omit<IJsonResumeSchema, '$schema' | 'basics' | 'education'> & {
  basics?: IJsonResumeSchema['basics'] & IResumeAdditionalProperties & { coverImage?: string }
  education?: (NonNullable<IJsonResumeSchema['education']>[number] & {
    id: IUniversity['id']
  })[]
}

export type IResumeBasics = IResume['basics']

export type IResumeLocation = NonNullable<NonNullable<IResume['basics']>['location']>

export type IResumeProfile = NonNullable<NonNullable<IResume['basics']>['profiles']>[number]

export type IResumeWork = NonNullable<IResume['work']>[number]

export type IResumeVolunteer = NonNullable<IResume['volunteer']>[number]

export type IResumeAward = NonNullable<IResume['awards']>[number]

export type IResumeCertificate = NonNullable<IResume['certificates']>[number]

export type IResumeEducation = NonNullable<IResume['education']>[number]

export type IResumePublication = NonNullable<IResume['publications']>[number]

export type IResumeSkill = NonNullable<IResume['skills']>[number]

export type IResumeLanguage = NonNullable<IResume['languages']>[number]

export type IResumeInterest = NonNullable<IResume['interests']>[number]

export type IResumeReference = NonNullable<IResume['references']>[number]

export type IResumeProject = NonNullable<IResume['projects']>[number]

export type IResumeMeta = NonNullable<IResume['meta']>

export interface IResumeUpdateRequest extends Record<string, unknown> {
  data: IResume
}

export type ZodFormField<T> = Record<keyof T, ZodTypeAny>

export interface ITeamDetail {
  id: ITeam['id']
  username: ITeam['username']
  name: TranslatedText
  website: TranslatedText | null
  description: TranslatedText | null
  logo: string | null
  banner: string | null
  location: TranslatedText | null
  category: TranslatedText | null
  jobsCount: number
  in5Words: TranslatedText | null
  bio: TranslatedText | null
}

export const TalentSupportedCountries = [
  'ae',
  'ao',
  'ar',
  'at',
  'au',
  'be',
  'bh',
  'br',
  'ca',
  'ch',
  'ci',
  'cl',
  'cm',
  'cn',
  'co',
  'cr',
  'cz',
  'de',
  'dk',
  'dz',
  'ec',
  'eg',
  'es',
  'fi',
  'fr',
  'gb',
  'gh',
  'gr',
  'gt',
  'hk',
  'hu',
  'id',
  'ie',
  'il',
  'in',
  'it',
  'jp',
  'ke',
  'kr',
  'kw',
  'kz',
  'lb',
  'lu',
  'ma',
  'mx',
  'my',
  'mz',
  'ng',
  'nl',
  'no',
  'nz',
  'om',
  'pa',
  'pe',
  'ph',
  'pl',
  'pk',
  'pr',
  'pt',
  'qa',
  'ro',
  'sa',
  'se',
  'sg',
  'sn',
  'th',
  'tn',
  'tr',
  'tw',
  'ua',
  'ug',
  'us',
  'uy',
  've',
  'vn',
  'za',
  'zm'
] as const

export type TalentSupportedCountry = typeof TalentSupportedCountries[number]

export const talentCountriesWithoutSponsoredFeeds = [
  'ao',
  'cm',
  'cn',
  'gh',
  'id',
  'il',
  'jp',
  'ke',
  'kr',
  'kw',
  'kz',
  'mz',
  'sn',
  'ug',
  'vn',
  'zm'
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITalentJob extends Record<string, any> {
  jobid: string
  title: string
  company?: string
  city?: string
  state?: string
  country?: string
  description?: string
  date?: Date
  currency?: string
  cpc?: number
  url?: string
  logo?: string
}

export const JoinSupportedCountries = ['es', 'de'] as const
export type JoinSupportedCountry = typeof JoinSupportedCountries[number]

export type ElasticSearchSupportedCountry = TalentSupportedCountry | JoinSupportedCountry
export const elasticSearchSupportedCountries: ElasticSearchSupportedCountry[] = uniq([
  ...TalentSupportedCountries.map(item => item.toLowerCase() as TalentSupportedCountry),
  ...JoinSupportedCountries.map(item => item.toLowerCase() as JoinSupportedCountry)
])

export interface AdvancedSearchResult {
  positions: CompactPositionRecord[]
  subTitleQueries: string[]
  teams: ITeamDetail[]
  skills: Pick<ISkill, 'id' | 'slug' | 'name' | 'icon'>[]
  occupations: IOccupation[]
  nextPage: number | null
}

export interface ILocationSearchResult {
  name: TranslatedText
  alternativeName?: TranslatedText | null
  locationId: ILocation['id']
  jobCount?: number
  distance?: number | null
}

export interface EntityViewCount {
  id: string
  viewsCount: number
}

export const DatePostedFilters = ['all', '24hrs', '3days', '7days', '14days', '1month'] as const
export type DatePosted = typeof DatePostedFilters[number]
export const DatePostedFilterValues: Record<DatePosted, number | null> = {
  all: null,
  '24hrs': 1,
  '3days': 3,
  '7days': 7,
  '14days': 14,
  '1month': 30
}

export type CompensationSetting = {
  min: number
  max: number
  step: number
}

export const defaultCompensation: Record<CompensationFrequency, CompensationSetting> = {
  Hour: {
    min: 0,
    max: 80,
    step: 10
  },
  Day: {
    min: 0,
    max: 800,
    step: 100
  },
  Week: {
    min: 0,
    max: 4000,
    step: 500
  },
  Month: {
    min: 0,
    max: 16000,
    step: 2000
  },
  Year: {
    min: 0,
    max: 200000,
    step: 10000
  }
}

export type CompensationEstimateFilter = Record<
  ContinentCode,
  Partial<Record<CompensationFrequency, CompensationSetting>>
>

// use to override defaultCompensation
export const compensationEstimateFilter: CompensationEstimateFilter = {
  AS: {},
  AN: {},
  AF: {},
  NA: {},
  EU: {},
  OC: {},
  SA: {}
}

//@todo drop when native is ready
const defaultMonthSalaryRange = [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000]
const defaultYearSalaryRange = [0, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000]
export type SalaryEstimateFilter = Record<ContinentCode, Partial<ICompensation> & { range: number[] }>
export const salaryEstimateFilter: SalaryEstimateFilter = {
  AS: {
    range: defaultMonthSalaryRange,
    frequency: 'Month'
  },
  AN: {
    range: defaultMonthSalaryRange,
    frequency: 'Month'
  },
  AF: {
    range: defaultMonthSalaryRange,
    frequency: 'Month'
  },
  NA: {
    range: defaultYearSalaryRange,
    frequency: 'Year'
  },
  EU: {
    range: [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000],
    frequency: 'Month'
  },
  OC: {
    range: defaultYearSalaryRange,
    frequency: 'Year'
  },
  SA: {
    range: defaultMonthSalaryRange,
    frequency: 'Month'
  }
}
export const SalaryEstimateFilters = [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000] as const

export const DistanceFilters = {
  km: [0, 10, 20, 50, 100, 150],
  // not accurate, but more aesthetic
  mi: [0, 5, 10, 30, 50, 100]
} as const

export const coverLetterSizes: ICoverLetterSettings['size'][] = ['Short', 'Medium', 'Long']

export const coverLetterTones: ICoverLetterSettings['tone'][] = ['Casual', 'Conversational', 'Professional', 'Formal']

export const defaultCoverLetterSettings: ICoverLetterSettings = {
  size: 'Medium',
  locale: null,
  tone: 'Conversational',
  instructions: null
}

export type PositionFilterTeamResult = Pick<ITeam, 'name' | 'username'> & { jobsCount: number }

export type PositionFilterFormModel = {
  datePosted?: DatePosted | null
  salaryEstimate?: number[] | null
  distanceRadius?: number | null
  salaryFrequency?: ICompensation['frequency'] | null
  locationId?: TranslatedText | null
  company?: ITeam['username'][] | null
  subTitleQuery?: string | null
}

export type OesCompensationResponse = {
  oesAreaType?: number
  oesHourlyRate?: number
  oesAnnualRate?: number

  oesHourly25Percentile?: number
  oesHourly75Percentile?: number
  oesHourly90Percentile?: number

  oesAnnual25Percentile?: number
  oesAnnual75Percentile?: number
  oesAnnual90Percentile?: number
}

export type OesEstimationFields = {
  areaType?: number
  hPct25?: number
  hPct75?: number
  hPct90?: number
  hMean?: number
  hMedian?: number
  aPct25?: string
  aPct75?: string
  aPct90?: string
  aMean?: string
  aMedian?: string
}

export interface IInterviewQuestion {
  id: Opaque<string, 'InterviewQuestion'>
  question: string
  questionIndex: number
  voiceFilePath?: string | null
}

export interface IStripeSubscriptionPlan<T = TranslatedText> {
  id: Opaque<string, 'StripeSubscriptionService'>
  name: T
  monthlyPrice: { amount: number; currency: CurrencyCode }
  annualPrice: { amount: number; currency: CurrencyCode }
  monthlyPriceWhenAnnual: { amount: number; currency: CurrencyCode }
  productId: IStripeProduct['id']
  monthlyPriceId: IStripePrice['id']
  annualPriceId: IStripePrice['id']
  features: T[]
  service: StripeService
  mostPopular: boolean
}

export type IAnnouncement<T = TranslatedText> = {
  message?: UntranslatedText
  tag?: UntranslatedText
  rawMessage?: T
  rawTag?: T
}

export type IFaqContent<T = TranslatedText> = {
  header: T
  questions: {
    question: T
    answer: T
  }[]
}

export type IFeaturesContent<T = TranslatedText> = {
  headerTag?: T
  header?: T
  subheader?: T
  features: {
    header: T
    subheader: T
    icon?: string
  }[]
}[]

export type IPricingContent<T = TranslatedText> = {
  header: T
  annualDiscount: T
  assurances: { header: T }[]
  climate: T
  plans?: IStripeSubscriptionPlan[]
}

export type IAffiliateContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  hero: {
    tag: T
    header: T
    subheader: T
    cta: T
  }
  products: (
    | {
        name: T
        description: T
        type: 'Purchase'
        plans: { name: T; price: number }[]
      }
    | {
        name: T
        description: T
        type: 'Subscription'
        plans: {
          name: T
          monthly: number
          annually: number
        }[]
      }
  )[]
  calculator: {
    header: T
    subheader: T
    disclaimer: T
  }
  howItWorks: {
    header: T
    steps: {
      header: T
      body: T
    }[]
  }
  faq: IFaqContent<T>
}

export type IApiContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  hero: {
    headerTag: T
    header: T
    subheader: T
    cta: T | null
  }
  features: IFeaturesContent<T>
  pricing: IPricingContent<T>
  faq: IFaqContent<T>
}

export type IAutopilotContent<T = TranslatedText> = {
  preorder: {
    subheader: T
    perksHeader: T
    perksSubheader: T
    perks: { header: T; subheader?: T }[]
    cta: T
  }
}

export type IBusinessContent<T = TranslatedText> = {
  hero: {
    header: T
    subheader: T
    cta: T
    demo: T
  }
  features: IFeaturesContent<T>
  howItWorks: {
    header: T
    steps: {
      header: T
      body: T
    }[]
  }
  pricing: IPricingContent<T>
  faq: IFaqContent<T>
}

export type IChangelogContent<T = TranslatedText> = {
  header: T
  about: T
  items: {
    date: Date
    label: T
    description: T
  }[]
}

export type ICopilotContent<T = TranslatedText> = {
  instructions: {
    examples: {
      header: T
      examples: T[]
    }[]
  }
}

export type IEmailContent<T = TranslatedText> = {
  launchpadHeader: T
  launchpadSubheaders: T[]
  studioHeader: T
  studioSubheaders: T[]
  freeCreditsSubject: T[]
  freeCreditsContent: T[]
  freeCreditsActionText: T[]
  freeCreditsDemoIntro: T[]
}

export type ILaunchpadContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  hero: {
    header: T
    subheader: T
    cta: T
  }
  howItWorks: {
    header: T
    steps: {
      header: T
      body: T
    }[]
  }
  faq: IFaqContent<T>
}

export type ILaunchpadDefaultContent<T = TranslatedText> = {
  metadata: {
    hero: {
      cta: T
    }
    deliverables: {
      subheader: T
    }
    testimonials: {
      header: T
      items: { name: T; headline: T; quote: T }[]
    }
    about: {
      header: T
    }
    plans: {
      header: T
      subheader: T
    }
    products: {
      header: T
    }
    faq: {
      header: T
    }
  }
  plans: {
    byHour: {
      name: T
      description: T
      features: { name: T }[]
    }[]
    byRequest: {
      name: T
      description: T
      features: { name: T }[]
    }[]
  }
}

export type ITeamDashboardContent<T = TranslatedText> = {
  emails: {
    templates: {
      code: string
      name: T
      subject: T
      body: T
    }[]
  }
}

export type IPipelineContent<T = TranslatedText> = {
  preorder: {
    subheader: T
    howItWorks: { header: T; subheader?: T }[]
    perksHeader: T
    perksSubheader: T
    perks: { header: T; subheader?: T }[]
    cta: T
    exclusiveHeader: T
    exclusiveSubheader: T
    exclusivePerks: { header: T; subheader?: T }[]
    exclusiveCta: T
  }
}

export type IPlusContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  announcement?: IAnnouncement<T>
  hero: {
    header: T
    subheader: T
    cta: T | null
  }
  benefits: {
    header?: T
    items: T[]
  }
  pricing: IPricingContent<T>
  faq: IFaqContent<T>
}

export type IPromoteContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  announcement?: IAnnouncement<T>
  hero: {
    header: T
    subheader: T
    cta: T
  }
  features: IFeaturesContent<T>
  howItWorks: {
    header: T
    steps: {
      header: T
      body: T
    }[]
  }
  pricing: IPricingContent<T>
  faq: IFaqContent<T>
}

export type IScreenerContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  hero: {
    headerTag: T
    header: T
    subheader: T
    cta: T
  }
  explainer: {
    steps: { header: T; subheader: T; icon: string }[]
  }
  demo: {
    header: T
    callouts: Record<string, { header: T }>
    positions: { id: IPosition['id']; title: T }[]
  }
  features: IFeaturesContent<T>
  howItWorks: {
    header: T
    steps: {
      header: T
      body: T
    }[]
  }
  pricing: IPricingContent<T>
  faq: IFaqContent<T>
}

export type IStudioContent<T = TranslatedText> = {
  metaTags: {
    title: T
    description: T
  }
  welcome: {
    headerTag: T
    header: T
    subheaders: T[][]
    features: { header: T; icon: string }[]
  }
  instructions: {
    header: T
    subheader: T
    examples: {
      header: T
      examples: T[]
    }[]
  }
  affiliate?: {
    amount: number
    currency: CurrencyCode
  }
}

export const outOfCreditError = 'OUT_OF_CREDIT'

export interface IStripeBaseSubscription {
  stripeProductId: IStripeProduct['id'] | null
  stripePriceId: IStripePrice['id'] | null
}

export interface IStripeSubscriptionAmbitionPlusServiceBenefits {
  unlimitedCoverLetter?: boolean
  numberOfCoverLetter: number
  launchpad?: boolean
}

export interface IStripeSubscriptionTeamInterviewServiceBenefits {
  numberOfInterviews: number
  numberOfJobPosts: number
  unlimitedJobPosts?: boolean
  additionalInterviewsPrice: number
}

export interface IStripeSubscriptionScreenerServiceBenefits {
  numberOfCredits: number
  numberOfResumeUploads: number
  unlimitedResumeUploads?: boolean
}

export interface IStripeSubscriptionJobPostingServiceBenefits {
  numberOfJobs: number
  onlineDistribution: boolean | null
  supportAnalytics: boolean | null
  analyticsLevel: 'advanced' | 'basic' | null
  featuredPositioning: boolean | null
  emailDistribution: boolean | null
  copilotSuggestions: boolean | null
  prioritySupport: boolean | null
}

export type IStripeSubscriptionBenefits =
  | IStripeSubscriptionAmbitionPlusServiceBenefits
  | IStripeSubscriptionTeamInterviewServiceBenefits
  | IStripeSubscriptionJobPostingServiceBenefits
  | IStripeSubscriptionScreenerServiceBenefits
