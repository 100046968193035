import { UntranslatedText } from 'core'
import React from 'react'

import { H1, H5 } from './H'
import { Icon } from './Icon'
import { IIconProps } from './types'
import { View } from './View'

export interface INonIdealStateProps {
  icon?: IIconProps['icon']
  title?: UntranslatedText
  description?: UntranslatedText
  action?: React.ReactNode
  iconTestID?: string
  titleTestID?: string
  descriptionTestID?: string
}

export const NonIdealState: React.FC<INonIdealStateProps> = ({
  icon,
  title,
  description,
  action,
  iconTestID,
  titleTestID,
  descriptionTestID
}) => {
  return (
    <View
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      minHeight={100}
    >
      {icon && <Icon testID={iconTestID} icon={icon} size={120} />}
      {title && <H1 marginTop="s" marginBottom="m" marginX="none" testID={titleTestID} text={title} />}
      {description && <H5 marginBottom="m" testID={descriptionTestID} text={description} />}
      {action}
    </View>
  )
}
