import { IPosition, TranslatedText } from 'core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useAudioPlayer,
  useInterviewQuestionsHandler,
  useInterviewRecorder,
  usePermissions,
  useStudioData
} from '~/hooks'
import { animations, breakpoint, Button, css, H1, H2, Icon, styled, Text, View } from '~/lite'

import { Orb } from '../Orb.lazy'

const Wrap = styled(View)`
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  padding: 8px;
  padding-bottom: 166px;
  justify-content: start;
  align-items: stretch;
`

const TextFadedIn = styled(Text)`
  display: inline-block;
  animation: ${animations.fadeInUpWithScale} 0.2s ease-in-out;
`

const Visualizer = styled(View)`
  position: absolute;
  top: 0;
  width: 400px;
  height: 400px;
  border-width: 10px;
  border-radius: 100%;
  transform: scale3d(0, 0, 0);
`

const GoButton = styled(Button)`
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(${props => props.theme.backgroundRgb}, 1);
`

const Title = styled(H1)<{ $enter: boolean }>`
  text-align: center;
  text-wrap: balance;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 60px;
  margin-bottom: 16px;
  background: linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38));
  -webkit-background-clip: text;
  animation: 1000ms ease 0s both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};
`

const Subtitle = styled(H2)<{ $enter: boolean }>`
  text-align: center;
  text-wrap: balance;
  color: #b4bcd0;
  margin: 0px 0px 48px;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;
  animation: 1200ms ease 400ms both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};
`

const JoinMeetingButton = styled(GoButton)<{ $enter: boolean }>`
  animation: 1200ms ease 800ms both ${props => (props.$enter ? animations.fadeInDown : animations.fadeOutUp)};
`

const Interviewer = styled(View)`
  margin: 0 auto;
  width: 750px;
  max-width: 100%;
  align-items: center;
  text-wrap: balance;
  text-align: center;
`

const Footer = styled(View)<{ $enter?: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${props =>
    props.$enter &&
    css`
      animation: 2800ms ease 1200ms both ${animations.fadeInUp};
    `}
`

const ToolbarWrap = styled(View)<{ $enter?: boolean }>`
  align-items: center;
  justify-content: center;
  flex: 1;
`

const Toolbar = styled(View)`
  border-radius: 24px;
  padding: 16px 16px 66px;
  width: 100%;
  z-index: 20;
  background-color: rgba(${props => props.theme.backgroundRgb}, 0.8);
  backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-width: 1px;

  @media ${breakpoint.lg} {
    border-radius: 200px;
    padding-bottom: 16px;
    margin-bottom: 32px;
    width: 500px;
    max-width: 100%;
  }
`

const Callout = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin: 16px;
  border-radius: 100px;
  background: ${props => props.theme.error};
`

const CalloutIcon = styled(Icon)`
  margin-right: 4px;
`

const CalloutText = styled(Text)`
  color: #fff;
`

const MuteButton = styled(Button)<{ $muted?: boolean }>`
  background: ${props => (props.$muted ? props.theme.error : props.theme.buttonBackground)};
`

const Number = styled(Text)`
  font-size: 24px;
`

const NumberWrap = styled(View)<{ $success?: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${props => (props.$success ? props.theme.success : props.theme.cardBackground)};
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  ${Number} {
    color: ${props => (props.$success ? '#fff' : props.theme.text)};
  }
`

const Message = styled(View)`
  flex: 1;
  padding: 16px;
  margin-bottom: 16px;
  text-align: center;
  animation: ${animations.fadeInUpSubtle} 1000ms ease-in-out 1000ms;
`

const MessageText = styled(Text)`
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.8);
  -webkit-background-clip: text;
`

/*
import { useEffect } from 'react'

import { useAudioPlayer, useGenerateSpeech } from '~/hooks'

export const AudioSample: React.FC = () => {
  const { play: playSpeech } = useAudioPlayer()

  const { data: blob } = useGenerateSpeech('Tell me an IT joke in less than 30 words')

  useEffect(() => {
    if (!blob) {
      return
    }
    playSpeech(blob)
  }, [playSpeech, blob])

  return null
}
*/

const speakerTest =
  "Hello again! If my voice is coming through clear, we're all set. If you're having any trouble hearing me, please tweak your speaker settings. Once you've got everything sorted, just unmute your mic, and we'll get started!" as TranslatedText

const micTest = 'Great! To ensure I can hear you clearly, could you please count from 1 to 5 for me?' as TranslatedText

export const Interview: React.FC = () => {
  const params = useParams()
  const [userMuted, setUserMuted] = useState(true)
  const [userTranscript, setUserTranscript] = useState<string[] | null>(null)
  const { data } = useStudioData(true)
  const audioVisualizerRef = useRef<HTMLDivElement>(null)
  const triggered = useRef<boolean>(false)

  /*const { replay, isPlayingWithStreamOrAudio } = useSpeechGenerator(
    `Please find a quiet location with minimal background noise. When you're ready, unmute your interviewer.`,
    true
  )*/

  const { playQuestionAudio, pauseQuestionAudio, nextQuestion, isPlayingAudio, isLoadingQuestions } =
    useInterviewQuestionsHandler(params.positionId as IPosition['id'], audioVisualizerRef)
  const { setAudioUrl, play: playSpeech, isBuffering, setPlayerVolume } = useAudioPlayer(audioVisualizerRef)
  const micPermission = usePermissions('microphone')

  const [interviewerMuted, setInterviewerMuted] = useState(true)
  const [interviewerText, setInterviewerText] = useState<TranslatedText | null>(null)

  const { toggleRecord, userSpeech, assistantSpeech, recordState, playRecord, isReplayingRecord, isReady, isEnded } =
    useInterviewRecorder(params.positionId as IPosition['id'], interviewerMuted, audioVisualizerRef)

  const [activeView, setActiveView] = useState<
    'Welcome' | 'WelcomeExit' | 'Main' | 'MicTest' | 'SpeakerTest' | 'Interview' | null
  >('Welcome')

  const [micCheck, setMicCheck] = useState<[boolean, boolean, boolean, boolean, boolean]>([
    false,
    false,
    false,
    false,
    false
  ])

  const playSpeakerTest = useCallback(() => {
    setAudioUrl('https://ambitioncdn.com/audio/en-US/valley-girl/speaker-test.mp3')
    playSpeech()
    setActiveView('SpeakerTest')
    setInterviewerText(speakerTest)
  }, [setAudioUrl, playSpeech])

  const playMicTest = useCallback(() => {
    setAudioUrl('https://ambitioncdn.com/audio/en-US/valley-girl/mic-test.mp3')
    playSpeech()
    setActiveView('MicTest')
    setInterviewerText(micTest)
  }, [setAudioUrl, playSpeech])

  useEffect(() => {
    setInterviewerText(assistantSpeech as TranslatedText)
  }, [assistantSpeech])

  useEffect(() => {
    if (activeView === 'Interview') {
      if (recordState === 'paused') {
        setUserMuted(true)
      } else {
        setUserMuted(false)
      }
    }
  }, [activeView, recordState])

  const micButtonHandler = useMemo(() => {
    let recognition: any
    let listening = false

    const init = () => {
      if (!('webkitSpeechRecognition' in window)) {
        alert('Your browser does not support the Web Speech API. Try using Google Chrome.')
        return
      }

      recognition = new (window as any).webkitSpeechRecognition()
      recognition.continuous = true
      recognition.interimResults = true

      recognition.onresult = (event: any) => {
        const transcript = Array.from(event.results)
          .map(result => (result as any)[0])
          .map(result => result.transcript)
          .join('')

        setUserTranscript(previous => {
          const prior = previous?.join('') ?? ''
          let i = 0

          while (i < prior.length && i < transcript.length && prior[i] === transcript[i]) {
            i++
          }

          const checkMic = (str: string) => {
            setMicCheck(check => [
              check[0] || str.includes('1') || str.includes('one'),
              check[1] || str.includes('2') || str.includes('two'),
              check[2] || str.includes('3') || str.includes('three'),
              check[3] || str.includes('4') || str.includes('four'),
              check[4] || str.includes('5') || str.includes('five')
            ])
          }

          checkMic(transcript)

          return previous ? [transcript.substring(0, i), transcript.substring(i)] : [transcript]
        })
      }

      recognition.onend = function () {
        if (listening) {
          // make sure it keeps on listening after speech has ended
          recognition.start()
        }
      }

      setTimeout(() => {
        playMicTest()
      }, 1000)
    }

    return {
      toggle: () => {
        if (!recognition) {
          init()
        }

        if (recognition) {
          if (listening) {
            listening = false
            recognition.stop()
            setUserMuted(true)
          } else {
            listening = true
            recognition.start()
            setUserMuted(false)
          }
        }
      },
      stop: () => {
        listening = false
        recognition.stop()
      }
    }
  }, [playMicTest])

  const [speakerTestPassed, setSpeakerTestPassed] = useState(false)
  const micTestPassed = useMemo(() => {
    return micCheck.every(check => check)
  }, [micCheck])

  useEffect(() => {
    if (micTestPassed) {
      micButtonHandler.stop()
    }
  }, [micTestPassed, micButtonHandler])

  const onVolumeButtonPress = useMemo(() => {
    if (activeView === 'Interview') {
      return () => {
        setInterviewerMuted(!interviewerMuted)
      }
    }
    return () => {
      if (!micTestPassed && !userMuted) {
        micButtonHandler.toggle()
      }
      setInterviewerMuted(!interviewerMuted)

      if (interviewerMuted) {
        setPlayerVolume(1)
        setTimeout(() => {
          playSpeakerTest()
        }, 100)
      } else {
        setPlayerVolume(0)
      }
    }
  }, [activeView, interviewerMuted, micButtonHandler, micTestPassed, playSpeakerTest, setPlayerVolume, userMuted])

  const onMicButtonPress = useCallback(() => {
    if (activeView === 'Interview') {
      toggleRecord()
      return
    }
    if (!speakerTestPassed) {
      if (!interviewerMuted) {
        setInterviewerMuted(true)
      }
    }
    setActiveView('MicTest')
    micButtonHandler.toggle()
  }, [activeView, interviewerMuted, micButtonHandler, speakerTestPassed, toggleRecord])

  const startInterview = useCallback(async () => {
    await toggleRecord()
    setInterviewerText('Waiting for interviewer response...' as TranslatedText)
    setActiveView('Interview')
  }, [toggleRecord])

  const recordButtonText = useMemo(() => {
    if (recordState === 'establishing') {
      return 'Establishing' as TranslatedText
    }

    if (recordState === 'recording') {
      return 'Stop Recording' as TranslatedText
    }

    if (recordState === 'paused' || recordState === 'idle') {
      return 'Start Record' as TranslatedText
    }

    if (recordState === 'playback') {
      return 'Assistant replying' as TranslatedText
    }

    return 'Record Assembly' as TranslatedText
  }, [recordState])

  // @todo temporary rawText until copy decided on
  return (
    <Wrap>
      <View position="relative" alignItems="center">
        <Visualizer ref={audioVisualizerRef}></Visualizer>
        <Orb />
      </View>
      <Interviewer>
        {interviewerText && (
          <Message>
            <MessageText rawText={interviewerText} fontSize={18} />
          </Message>
        )}
      </Interviewer>
      {(activeView === 'Welcome' || activeView === 'WelcomeExit') && (
        <View marginX="auto">
          <Title $enter={activeView === 'Welcome'} rawText={'Welcome to your interview.' as TranslatedText} />
          <Subtitle
            $enter={activeView === 'Welcome'}
            rawText={'Please find a quiet location with minimal background noise.' as TranslatedText}
          />
          <View flexDirection="row" alignItems="center" justifyContent="center" paddingBottom={32}>
            <JoinMeetingButton
              $enter={activeView === 'Welcome'}
              rawText={'Join Meeting' as TranslatedText}
              onPress={() => {
                setActiveView('WelcomeExit')

                setTimeout(() => {
                  setActiveView('Main')
                }, 5000)
              }}
              primary
              skipTracking
              large
            />
          </View>
        </View>
      )}
      {activeView === 'SpeakerTest' && (
        <View marginX="auto" flexDirection="row">
          <Button
            icon="refresh"
            marginRight="m"
            onPress={playSpeakerTest}
            rawText={'Say it again' as TranslatedText}
            large
            skipTracking
          />
          <Button skipTracking primary={speakerTestPassed} onPress={() => setSpeakerTestPassed(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 10v12" />
              <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
            </svg>
            <Text rawText={'Sounds Good' as TranslatedText} fontSize={16} paddingLeft="s" />
          </Button>
        </View>
      )}
      {activeView === 'MicTest' && (
        <View marginX="auto" flexDirection="row">
          {[1, 2, 3, 4, 5].map((number, index) => (
            <NumberWrap key={index} $success={micCheck[index]}>
              <Number text={number} />
            </NumberWrap>
          ))}
        </View>
      )}
      {/*false && !isLoadingQuestions && (
        <View flexDirection="row" alignItems="center" justifyContent="center" paddingBottom={32}>
          <JoinMeetingButton
            rawText={'Join Meeting' as TranslatedText}
            onPress={nextQuestion}
            primary
            skipTracking
            large
          />
        </View>
      )}
      <View flexDirection="row" alignItems="center" justifyContent="center" paddingBottom={32}>
        <GoButton
          loading={isPlayingWithStreamOrAudio}
          marginLeft="m"
          rawText={'Replay' as TranslatedText}
          onPress={replay}
      )*/}

      {/* <View flexDirection="row" alignItems="center" justifyContent="center" paddingBottom={32}>
        <GoButton
          loading={recordState === 'establishing' || recordState === 'playback'}
          loadingText={(recordState === 'establishing' ? 'Establishing' : 'Assistant replying') as UntranslatedText}
          disabled={recordState === 'establishing' || recordState === 'playback'}
          marginLeft="m"
          rawText={recordButtonText}
          onPress={toggleRecord}
          skipTracking
        />
      </View>
      <View flexDirection="row" alignItems="center" justifyContent="center" paddingBottom={32}>
        <GoButton
          disabled={!hasRecorded || isReplayingRecord}
          loading={isReplayingRecord}
          loadingText={'Replaying' as UntranslatedText}
          marginLeft="m"
          rawText={'Replay Assembly Record' as TranslatedText}
          onPress={playRecord}
          skipTracking
        />
      </View> */}
      <View display="none">
        {isPlayingAudio ? (
          <GoButton rawText={'Pause' as TranslatedText} onPress={pauseQuestionAudio} skipTracking large />
        ) : (
          <GoButton rawText={'Play' as TranslatedText} onPress={playQuestionAudio} skipTracking large />
        )}
        <GoButton
          marginLeft="m"
          rawText={'Next Question' as TranslatedText}
          onPress={nextQuestion}
          skipTracking
          large
        />
      </View>
      {activeView === 'MicTest' && (
        <View marginX="auto" display="block">
          {userTranscript && userTranscript.length > 0 && (
            <Text fontSize={20} rawText={'[Your speech]: ' as TranslatedText} />
          )}
          {userTranscript && userTranscript.length > 0 && (
            <Text fontSize={20} rawText={userTranscript[0] as TranslatedText} />
          )}
          {userTranscript && userTranscript.length > 1 && (
            <TextFadedIn fontSize={20} rawText={userTranscript[1] as TranslatedText} />
          )}
        </View>
      )}

      {activeView === 'Interview' && isReady && !isEnded && (
        <View marginX="auto" flexDirection="row">
          <Button
            icon="refresh"
            marginRight="m"
            onPress={playRecord}
            rawText={'Say it again' as TranslatedText}
            large
            skipTracking
            loading={isReplayingRecord}
          />
          <Button
            icon="mic-outline"
            marginRight="m"
            onPress={toggleRecord}
            rawText={recordButtonText}
            large
            skipTracking
          />
        </View>
      )}
      <View marginX="auto" marginTop="m" display="block">
        {userSpeech && (
          <>
            <Text fontSize={20} rawText={'[Your speech]: ' as TranslatedText} />
            <Text fontSize={20} rawText={userSpeech as TranslatedText} />
          </>
        )}
      </View>
      {activeView !== 'Interview' &&
        micTestPassed &&
        speakerTestPassed &&
        (recordState === 'idle' || recordState === 'establishing') && (
          <Button
            marginTop="m"
            primary
            loading={recordState === 'establishing'}
            onPress={startInterview}
            rawText={'Start the interview' as TranslatedText}
            large
            skipTracking
          />
        )}
      {activeView !== 'Welcome' && (
        <Footer $enter={activeView === 'WelcomeExit'}>
          <ToolbarWrap>
            {micPermission === 'denied' && (
              <Callout>
                <CalloutIcon icon="mic-off-outline" color="#fff" />
                <CalloutText
                  rawText={'Microphone access denied. Please check your browser settings.' as TranslatedText}
                />
              </Callout>
            )}
            <Toolbar>
              <MuteButton
                $muted={interviewerMuted}
                icon={interviewerMuted ? 'volume-mute' : 'volume-high'}
                iconSize={36}
                onPress={onVolumeButtonPress}
                loading={isBuffering}
                width={70}
                height={70}
                marginX={8}
                large
                skipTracking
              />
              <MuteButton
                $muted={userMuted || micPermission !== 'granted'}
                icon={userMuted || micPermission !== 'granted' ? 'mic-off-outline' : 'mic-outline'}
                iconSize={36}
                onPress={onMicButtonPress}
                width={70}
                height={70}
                marginX={8}
                large
                skipTracking
              />
            </Toolbar>
          </ToolbarWrap>
        </Footer>
      )}
    </Wrap>
  )
}
