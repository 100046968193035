import { Studio } from '@ambition/module-shared'
import { IPosition } from 'core'
import React, { useState } from 'react'

import { BottomSheet, Editor, IBottomSheetProps } from '~/components'

interface IApplicationStudioProps {
  isOpen: IBottomSheetProps['isOpen']
  onClose?(htmlValue: string | null, plainTextValue: string | null): void
  value?: string
  positionId: IPosition['id']
  dashboard?: boolean
}

export const ApplicationStudio: React.FC<IApplicationStudioProps> = ({
  isOpen,
  onClose,
  value,
  positionId,
  dashboard
}) => {
  const [editor, setEditor] = useState<Editor | null>(null)

  return (
    <BottomSheet
      title="Studio"
      closeText="Done"
      isOpen={isOpen}
      onClose={() => {
        // html may be just empty tags so need to check text
        const text = editor?.getText()?.trim()
        const html = editor?.getHTML()?.trim()

        onClose?.(text ? html || null : null, text || null)
      }}
      snapPoints={['45%']}
      alwaysOpen={1}
      useBottomSheetModal
      dashboard
    >
      <Studio variant="Apply" positionId={positionId} value={value} onEditorChange={setEditor} />
    </BottomSheet>
  )
}
