import { IUniversity } from '../models'
import { Endpoint, get, post } from '../util/api'

export type CreateUniversity = Endpoint<
  Record<string, string>,
  { university: IUniversity; success: true },
  { name: IUniversity['name'] }
>
export const getUniversities = (term: string) => get<GetUniversitiesByTerm>(`/universities?term=${term}`)

export const createUniversity = (name: IUniversity['name']) => post<CreateUniversity>('/universities', { name })

export type GetUniversitiesByTerm = Endpoint<
  Record<string, string>,
  { success: true; universities: IUniversity[] },
  { name: IUniversity['name'] }
>
