import { breakpoint, styled, View } from '~/lite'

export const IconView = styled(View)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #0000009e;
  cursor: pointer;
`
export const NabBarContainer = styled(View)`
  @media ${breakpoint.md} {
    display: none;
  }
`
