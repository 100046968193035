import React from 'react'

import { getTeamLogo } from '../util'
import { Avatar } from './Avatar'
import { ITeamAvatarProps } from './types'

export const TeamAvatar: React.FC<ITeamAvatarProps> = ({ team, onPress, ...avatarProps }) => {
  const teamLogo = getTeamLogo(team)
  return (
    <Avatar
      {...avatarProps}
      source={teamLogo ? { uri: teamLogo } : undefined}
      recordKey={team.name}
      skeleton={!teamLogo && !team.name}
    />
  )
}
