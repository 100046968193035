import React, { Suspense, useState } from 'react'

import { useOnHydrate } from '~/lite'

import { IMenuProps } from './types'

const LazyMenu = React.lazy(() => import('./Menu').then(module => ({ default: module.Menu })))

export const Menu: React.FC<IMenuProps> = props => {
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return props.children ?? null
  }

  return (
    <Suspense fallback={null}>
      <LazyMenu {...props} />
    </Suspense>
  )
}
