import React from 'react'

import { styled, View } from '~/lite'

const Wrap = styled(View)`
  position: relative;
`

const PageShim = styled(View)`
  width: 200px;
  margin: 64px auto 0;
  padding: 10%;
  aspect-ratio: 0.7;
`

const PageWrap = styled(View)<{ $rotation: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  padding: 10%;
  aspect-ratio: 0.7;
  gap: 8px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0) scale3d(0.66, 0.66, 0.66) rotate3d(0, 1, 1, ${props => props.$rotation}deg);
`

const Line = styled.div`
  flex-direction: row;
  gap: 8px;
`

const Spacer = styled.div`
  flex: 1;
`

const Text = styled.div<{ $width: number; $large?: boolean }>`
  width: ${props => props.$width}%;
  height: ${props => (props.$large ? 20 : 10)}px;
  border-radius: 6px;
  background: #ededef;
`

interface IPageProps {
  rotation: number
}

const Page: React.FC<IPageProps> = ({ rotation }) => (
  <PageWrap $rotation={rotation}>
    <Text $width={30} />
    <Text $width={20} />
    <Text $width={25} />
    <Line />
    <Line />
    {[0, 1, 2].map(i => (
      <React.Fragment key={i}>
        <Line />
        <Line />
        <Line />
        <Line>
          <Text $width={40} />
          <Spacer />
          <Text $width={20} />
        </Line>
        <Text $width={100} />
        <Text $width={60} />
      </React.Fragment>
    ))}
  </PageWrap>
)

export const ResumeIllustration: React.FC = () => {
  return (
    <Wrap>
      <PageShim />
      <Page rotation={-5} />
      <Page rotation={5} />
      <Page rotation={0} />
    </Wrap>
  )
}
