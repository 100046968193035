import { ContinentCode, parsePositionFilterQueryParams, PositionFilterFormModel, salaryEstimateFilter } from 'core'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useStore } from '~/lite'

export type FilterParams = {
  datePosted: string | null
  salaryEstimate: string | null
  location: string | null
  company: string | null
}

export const usePositionFilterQueryParams = (): PositionFilterFormModel => {
  const { search } = useLocation()
  const [location] = useStore(store => [store.location])
  const defaultSalaryRange = React.useMemo(() => {
    const continentCode = location?.continentCode
    if (!continentCode) {
      return salaryEstimateFilter['NA']
    }
    return salaryEstimateFilter[continentCode as ContinentCode]
  }, [location?.continentCode])

  return React.useMemo(() => {
    const { salaryFrequency, datePosted, ...rest } = parsePositionFilterQueryParams(search)
    return {
      ...rest,
      datePosted: datePosted || 'all',
      salaryFrequency: salaryFrequency || defaultSalaryRange.frequency
    }
  }, [defaultSalaryRange.frequency, search])
}
