import { markTranslated } from 'core'
import React, { useEffect, useState } from 'react'

import { keyframes, styled } from '../styled'
import { Text } from './Text'
import { ILoaderProps } from './types'
import { View } from './View'

const rotation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

// https://cssloaders.github.io/
const ActivityIndicator = styled.span<{ $size: number; $color?: string }>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  border: ${props => Math.round(props.$size / 10)}px solid ${props => props.$color ?? props.theme.primary};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`

const ProgressText = styled(View)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Circle = styled.circle<{ $strokeWidth: number; $strokeOpacity?: number }>`
  transition: stroke-dashoffset 1s ease;
  stroke: ${props => props.theme.inputBackground};
  stroke-opacity: ${props => props.$strokeOpacity ?? 1};
  stroke-width: ${props => `${props.$strokeWidth}px`};
`

const ProgressCircle = styled(Circle)<{ $color?: string }>`
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: ${props => props.$color ?? props.theme.primary};
`

interface IProgressIndicatorProps {
  percent: number
  size: number
  color?: string
}

const ProgressIndicator: React.FC<IProgressIndicatorProps> = ({ percent, size, color }) => {
  const strokeOpacity = color === '#fff' ? 0.2 : undefined
  const strokeWidth = Math.ceil(size / 12)

  const radius = size / 2 - strokeWidth
  const circumference = Math.PI * (radius * 2)
  const value = Math.min(Math.max(Math.round(percent), 0), 100)
  const offset = ((100 - value) / 100) * circumference

  return (
    <View position="relative" borderRadius="100%" width={size} height={size}>
      {size >= 100 && (
        <ProgressText>
          <Text rawText={markTranslated(`${value}%`)} fontSize={Math.floor(size / 6)} fontWeight="bold" />
        </ProgressText>
      )}
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <Circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          $strokeOpacity={strokeOpacity}
          $strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset="0"
        ></Circle>
        <ProgressCircle
          $color={color}
          r={radius}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          $strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeLinecap="round"
          strokeDashoffset={offset}
        ></ProgressCircle>
      </svg>
    </View>
  )
}

export const Loader: React.FC<ILoaderProps> = ({
  progress,
  isLoading = true,
  size = 'large',
  estimatedSeconds,
  color,
  inline = false,
  testID,
  children
}) => {
  const [estimateProgress, setEstimateProgress] = useState<number | null>(null)
  const sizeNumber = size === 'small' ? 20 : size === 'large' ? 36 : size === 'giant' ? 101 : size

  useEffect(() => {
    if (!estimatedSeconds) {
      return
    }

    setEstimateProgress(1)

    const interval = setInterval(() => {
      setEstimateProgress(prev => {
        const progress = prev ?? 0

        // fast at first then progressively slower to buy time
        return Math.min(progress + Math.min(1, Math.max(1, 100 - progress + 30) / 100), 99)
      })
    }, (estimatedSeconds * 1000) / (90 * 2))

    return () => clearInterval(interval)
  }, [estimatedSeconds])

  if (!isLoading) {
    return children ? <>{children}</> : null
  }

  const percent = estimateProgress !== null ? Math.floor(estimateProgress) : progress

  const component =
    typeof percent === 'number' || estimatedSeconds ? (
      <ProgressIndicator color={color} size={sizeNumber} percent={percent ?? 1} />
    ) : (
      <ActivityIndicator $color={color} $size={sizeNumber} />
    )

  if (inline) {
    return component
  }

  return (
    <View
      display="flex"
      flex={1}
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {component}
    </View>
  )
}
