import React, { useMemo } from 'react'
import { Background } from 'react-imgix'
import {
  backgroundPosition,
  backgroundRepeat,
  backgroundSize,
  border,
  color,
  layout,
  position,
  space
} from 'styled-system'

import { styled } from '../styled'
import { IImageProps, IImgixParams, ImageStyledProps } from './types'
import { View } from './View'

const InnerImage: React.FC<IImageProps> = ({
  source,
  width,
  height,
  resizeMode,
  onError,
  testID,
  backgroundColor,
  backgroundPosition = 'center',
  backgroundRepeat,
  backgroundSize,
  skipImgix,
  style,
  ...rest
}) => {
  const params = useMemo<IImgixParams>(
    () => ({ fit: 'crop', auto: 'compress,enhance', w: width, h: height }),
    [width, height]
  )

  if (skipImgix || true) {
    return (
      <View
        {...rest}
        style={[
          { backgroundImage: `url("${source}")` } as any,
          {
            backgroundRepeat: 'no-repeat',
            backgroundSize: resizeMode,
            backgroundPosition: 'center center',
            width,
            height
          },
          style
        ]}
        {...(testID && { 'data-testid': testID })}
      />
    )
  }

  return (
    <Background
      data-testid={testID}
      src={source}
      htmlAttributes={{
        style: {
          width,
          height,
          backgroundColor,
          backgroundPosition,
          backgroundRepeat,
          backgroundSize,
          ...(rest as any)
        }
      }}
      imgixParams={params}
    />
  )
}

export const Image = styled(InnerImage)<ImageStyledProps>`
  ${color}
  ${layout}
  ${space}
  ${border}
  ${position}
  ${backgroundPosition}
  ${backgroundSize}
  ${backgroundRepeat}
`
