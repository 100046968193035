import { DynamicFormComponent, ResumeImport } from '@ambition/module-shared'
import { addAnswersToForm, GetJobDashboardPath, IApplicationFormWithAnswers, IPosition, ITeam } from 'core'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { BottomSheet, Button, Loader, View } from '~/components'
import { useCreateApplication, useExtensionState, usePositionQuestions, useStore } from '~/hooks'
import { toast } from '~/lite'

import { ApplicationStatus } from './ApplicationStatus'
import { ApplicationStudio } from './ApplicationStudio'

interface IApplicationProps {
  positionId: IPosition['id']
  team?: Pick<ITeam, 'logo' | 'name'>
  isOpen: boolean
  onClose?(): void
  onSuccess?(positionId?: IPosition['id']): void
  getJobDashboardPath?: GetJobDashboardPath
  dashboard?: boolean
}

export const Application: React.FC<IApplicationProps> = ({
  positionId,
  team,
  isOpen,
  onClose,
  onSuccess,
  getJobDashboardPath,
  dashboard
}) => {
  const isLoadingUser = useStore(state => state.isLoadingUser)
  const resumeImportState = useStore(state => state.resumeImportState)
  const setIsApplying = useStore(state => state.setIsApplying)
  const addApplied = useStore(state => state.addApplied)

  const isImportingResume =
    resumeImportState === 'Pending' ||
    resumeImportState === 'Processing' ||
    resumeImportState === 'RecommendationsAdded'
  const hasImportedResume = resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success'

  const { data: questions, isLoading: isLoadingPositionQuestions } = usePositionQuestions(positionId, {
    enabled: hasImportedResume && isOpen
  })
  const { data: extensionState, refetch: refetchExtensionState } = useExtensionState()

  const [studioValue, setStudioValue] = useState('')
  const [isStudioOpen, setIsStudioOpen] = useState<boolean | null>(null)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean | null>(null)
  const [isFailedModalOpen, setIsFailedModalOpen] = useState<boolean | null>(null)

  const coverLetterFieldId = useMemo(() => {
    for (const section of questions?.sections ?? []) {
      for (const field of section.fields) {
        if ((field.type === 'File' || field.type === 'Text') && field.variant === 'CoverLetter') {
          return { id: field.id, type: field.type }
        }
      }
    }

    return null
  }, [questions?.sections])

  const questionsWithAnswers = useMemo(
    () =>
      questions
        ? addAnswersToForm(questions, extensionState?.success ? extensionState.state?.data.autofill ?? null : null)
        : null,
    [questions, extensionState]
  )

  const defaultValues = useMemo(() => {
    const formFields: Record<string, any> = {}
    questionsWithAnswers?.sections.forEach(section => {
      section.fields.forEach(field => {
        if (field.answer) {
          formFields[field.id] = field.answer
        }
      })
    })
    return formFields
  }, [questionsWithAnswers?.sections])

  const {
    control,
    formState: { isSubmitting, errors },
    setValue,
    handleSubmit,
    reset
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    defaultValues && reset(defaultValues)
  }, [defaultValues, reset])

  useEffect(() => {
    setIsApplying(isOpen || !!isSuccessModalOpen || !!isFailedModalOpen)

    if (!isOpen) {
      setIsStudioOpen(null)
    }

    return () => {
      setIsApplying(false)
    }
  }, [setIsApplying, isOpen, isSuccessModalOpen, isFailedModalOpen])

  const { mutateAsync: createApplication } = useCreateApplication()

  const onSubmitButtonPress = handleSubmit(async values => {
    if (!questionsWithAnswers) {
      setIsFailedModalOpen(true)
      onClose?.()
      return
    }

    const formWithAnswers = {
      ...questionsWithAnswers,
      sections: questionsWithAnswers.sections.reduce(
        (answers, section) => [
          ...answers,
          {
            ...section,
            fields: section.fields.map(field => {
              const answer = get(values, field.id)
              return isEmpty(answer)
                ? field
                : {
                    ...field,
                    answer,
                    ...(field.type === 'Group'
                      ? {
                          fields: field.fields.map(groupField => {
                            const answer = get(values, `${field.id}[0].${groupField.id}`)
                            return isEmpty(answer) ? groupField : { ...groupField, answer }
                          })
                        }
                      : {})
                  }
            })
          }
        ],
        [] as IApplicationFormWithAnswers['sections']
      )
    }

    const result = await createApplication({ positionId, formData: formWithAnswers })

    if (result.success) {
      setIsSuccessModalOpen(true)
      refetchExtensionState()
      addApplied(positionId)
      onSuccess?.(positionId)
      onClose?.()
    } else {
      toast.failure(result.message ?? result.errors?.general ?? 'An error has occurred')
    }
  })

  const children: React.ReactNode[] = []

  if ((isLoadingUser && !isImportingResume) || (hasImportedResume && isLoadingPositionQuestions)) {
    children.push(
      <View key="loader" height={300}>
        <Loader />
      </View>
    )
  } else if (hasImportedResume) {
    children.push(
      <View key="form" paddingY="m" paddingX="m">
        {questions?.sections.map((section, idx) => (
          <React.Fragment key={idx}>
            {section.fields.map(field => (
              <DynamicFormComponent
                key={field.id}
                question={field}
                control={control}
                error={errors[field.id]?.message as any}
                source="Application"
                onStudioButtonPress={value => {
                  setStudioValue(value)
                  setIsStudioOpen(true)
                }}
              />
            ))}
          </React.Fragment>
        ))}
        <View paddingY="s" paddingX="s">
          <Button
            skipTracking
            text="Submit"
            onPress={onSubmitButtonPress}
            loading={isSubmitting}
            disabled={isSubmitting}
            primary
            large
          />
        </View>
      </View>
    )
  } else {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    children.push(<ResumeImport key="resume-import" variant="Apply" team={team} onChange={() => {}} />)
  }

  return (
    <>
      <BottomSheet
        isOpen={isOpen}
        isMinimized={isStudioOpen}
        onClose={() => {
          reset()
          onClose?.()
        }}
        title="Apply"
        modalTopOffset={50}
        closeSnapPointStraightEnabled={false}
      >
        {children}
      </BottomSheet>
      {isStudioOpen !== null && (
        <ApplicationStudio
          isOpen={isStudioOpen}
          value={studioValue}
          onClose={(htmlValue, plainTextValue) => {
            if (coverLetterFieldId) {
              const { id, type } = coverLetterFieldId
              setValue(id, type === 'File' ? htmlValue : plainTextValue)
            }

            setIsStudioOpen(false)
          }}
          positionId={positionId}
          dashboard={dashboard}
        />
      )}
      {isSuccessModalOpen !== null && (
        <ApplicationStatus
          status="Success"
          positionId={positionId}
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          getJobDashboardPath={getJobDashboardPath}
          dashboard={dashboard}
        />
      )}
      {isFailedModalOpen !== null && (
        <ApplicationStatus
          status="Failure"
          positionId={positionId}
          isOpen={isFailedModalOpen}
          onClose={() => setIsFailedModalOpen(false)}
          getJobDashboardPath={getJobDashboardPath}
          dashboard={dashboard}
        />
      )}
    </>
  )
}
