import { buildQueryString, ClientRoutePath, PositionFilterFormModel } from 'core'
import React from 'react'
import { useForm } from 'react-hook-form'

import { breakpoint, styled, useHistory, View } from '~/lite'

import { CompensationField } from './CompensationField'
import { DatePostedField } from './DatePostedField'
import { DistanceField } from './DistanceField'
import { RemoteField } from './RemoteField'
import { TeamSelectField } from './TeamSelectField'
import { PositionFilterFormProps } from './types'
import { usePositionFilterQueryParams } from './usePositionFilterQueryParams'

const Wrap = styled(View)`
  position: fixed;
  top: 250px;
  right: 0;
  left: 0;
  z-index: 999;

  @media ${breakpoint.lg} {
    top: 60px;
    left: 432px;
  }
`

const Inner = styled(View)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1168px;
  margin: 0 auto;
  padding: 16px;
  overflow-x: auto;
  background: linear-gradient(
    0deg,
    ${props => props.theme.background}00 0%,
    ${props => props.theme.background}cc 25%,
    ${props => props.theme.background}ff 100%
  );

  &::-webkit-scrollbar {
    display: none;
  }
`

const Fields = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const positionFilterOffset = 64

export const PositionFilterForm: React.FC<PositionFilterFormProps> = ({
  onApply,
  collectionSlug,
  searchTerm,
  searchType
}) => {
  /*
  const history = useHistory()

  const formFilterParams = usePositionFilterQueryParams()

  const formReturn = useForm<PositionFilterFormModel>({
    mode: 'onChange',
    defaultValues: formFilterParams
  })

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting }
  } = formReturn

  const salaryValue = watch('salaryEstimate')

  React.useEffect(() => {
    formFilterParams && formReturn.reset(formFilterParams)
  }, [formFilterParams, formReturn])

  const onSubmit = React.useCallback(
    (data: PositionFilterFormModel) => {
      const { datePosted, salaryEstimate, salaryFrequency, company, distanceRadius } = data
      const queryString = buildQueryString({
        distanceRadius,
        datePosted,
        salaryEstimate: salaryEstimate ? salaryEstimate[0] : '',
        salaryFrequency,
        company: company?.length ? company.join(',') : '',
        type: searchType,
        query: searchTerm
      })
      window.scrollTo(0, 0)
      onApply?.(data)
      history.push(queryString as ClientRoutePath)
    },
    [history, onApply, searchTerm, searchType]
  )
  */

  return (
    <Wrap>
      <Inner>
        <Fields>
          <DatePostedField />
          <DistanceField />
          <RemoteField />
          {/*<CompensationField />*/}
          {/*!!collectionSlug && <TeamSelectField collectionSlug={collectionSlug} control={control} />*/}
          {/*<SalaryEstimateSliderField control={control} setValue={setValue} salaryValue={salaryValue} />*/}
          {/*<Button
          action="FilterCollectionPositions"
          marginTop="s"
          text="Apply Filters"
          onPress={handleSubmit(onSubmit)}
          primary
          loading={isSubmitting}
        />*/}
        </Fields>
      </Inner>
    </Wrap>
  )
}
