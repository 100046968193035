import { IMarketProfile } from 'core'
import React from 'react'

import { Accordion } from '~/components'
import { breakpoint, styled, View } from '~/lite'

import { Section, SectionContent, SectionHeader } from './styles'

const AccordionWrap = styled(View)`
  margin: 0 8px;

  @media ${breakpoint.md} {
    margin: 16px 0 0;
  }
`

interface IFaqProps {
  profile: IMarketProfile
}

export const Faq: React.FC<IFaqProps> = ({ profile }) => {
  if (!profile.metadata.faq?.questions?.length) {
    return null
  }

  const { header, questions } = profile.metadata.faq

  return (
    <Section id="faq">
      <SectionContent>
        {!!header && <SectionHeader rawText={header} />}
        <AccordionWrap>
          <Accordion data={questions.map(({ question, answer }) => ({ trigger: question, content: answer }))} />
        </AccordionWrap>
      </SectionContent>
    </Section>
  )
}
