import { IAgentCommand } from 'core'

import { Icon, Loader, Text, useTranslation } from '~/lite'

import { AgentCommandIcon, AgentCommandWrap } from './styles'

interface IAgentCommandProps {
  command: IAgentCommand
}

export const AgentCommand: React.FC<IAgentCommandProps> = ({ command }) => {
  const t = useTranslation()

  return (
    <AgentCommandWrap>
      <AgentCommandIcon>
        {command.state === 'Pending' ? (
          <Loader size={20} inline />
        ) : command.state === 'Error' ? (
          <Icon size={20} icon="alert-circle-outline" color="#ff656d" width="100%" />
        ) : (
          <Icon size={20} icon="checkmark" color="#00c853" width="100%" />
        )}
      </AgentCommandIcon>
      <Text rawText={command.explanation ?? t('Loading...')} />
    </AgentCommandWrap>
  )
}
