import { INewSubscription, ISubscription, PageableResponse } from '../models'
import { Endpoint, get, post, remove } from '../util/api'
import { buildQueryString } from '../util/helpers'

export type GetSubscriptions = Endpoint<Record<string, never>, { success: true } & PageableResponse<ISubscription>>

export type CreateSubscription = Endpoint<Record<string, never>, { success: true }, INewSubscription>

export type RemoveSubscription = Endpoint<{ id: ISubscription['id'] }, { success: true }>

export const getSubscriptions = (page?: number, limit?: number) =>
  get<GetSubscriptions>(`/subscriptions${buildQueryString({ page, limit })}`)

export const createSubscription = (data: INewSubscription) => post<CreateSubscription>('/subscriptions', data)

export const removeSubscription = (id: ISubscription['id']) => remove<RemoveSubscription>(`/subscriptions/${id}`, {})
