import React from 'react'
import type { StyledConfig } from 'styled-components'
import {
  background,
  border,
  buttonStyle,
  color,
  colorStyle,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  SpaceProps,
  textStyle,
  typography
} from 'styled-system'

import { StyledSystemTheme } from '../theme'
import { util } from '../util'
import { ViewStyle, ViewStyleProp } from './types'

export interface IMaybeProps {
  component: React.ComponentType<any>
  condition: boolean
  children?: React.ReactNode
}

export const Maybe: React.FC<IMaybeProps> = ({ component: Component, condition, children }) =>
  condition ? <Component>{children}</Component> : <>{children}</>

// https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/modules/unitlessNumbers/index.js
const unitlessNumbers = [
  'animationIterationCount',
  'aspectRatio',
  'borderImageOutset',
  'borderImageSlice',
  'borderImageWidth',
  'boxFlex',
  'boxFlexGroup',
  'boxOrdinalGroup',
  'columnCount',
  'flex',
  'flexGrow',
  'flexOrder',
  'flexPositive',
  'flexShrink',
  'flexNegative',
  'fontWeight',
  'gridRow',
  'gridRowEnd',
  'gridRowGap',
  'gridRowStart',
  'gridColumn',
  'gridColumnEnd',
  'gridColumnGap',
  'gridColumnStart',
  'lineClamp',
  'opacity',
  'order',
  'orphans',
  'tabSize',
  'widows',
  'zIndex',
  'zoom',
  'fillOpacity',
  'floodOpacity',
  'stopOpacity',
  'strokeDasharray',
  'strokeDashoffset',
  'strokeMiterlimit',
  'strokeOpacity',
  'strokeWidth',
  'scale',
  'scaleX',
  'scaleY',
  'scaleZ',
  'shadowOpacity'
].flatMap(key => [
  key,
  ...['ms', 'Moz', 'O', 'Webkit'].map(prefix => `${prefix}${key.charAt(0).toUpperCase()}${key.substring(1)}`)
])

const getStyleValue = (name: string, value: any, isCustomProperty: boolean): string => {
  if (value === null || typeof value === 'boolean' || value === '') {
    return ''
  }

  if (typeof value === 'number' && value !== 0 && !isCustomProperty && !unitlessNumbers.includes(name)) {
    return `${value}px`
  }

  return value.toString().trim()
}

const styleTransforms: { [attribute in keyof ViewStyle]: (value: any) => React.CSSProperties } = {
  paddingHorizontal: (value: any) => ({ paddingLeft: value, paddingRight: value }),
  paddingVertical: (value: any) => ({ paddingTop: value, paddingBottom: value }),
  marginHorizontal: (value: any) => ({ marginLeft: value, marginRight: value }),
  marginVertical: (value: any) => ({ marginTop: value, marginBottom: value })
}

export const fixSpacePropsForNative = (props: SpaceProps<StyledSystemTheme>) => {
  // Using SpaceVariant(xs, s, m, l, xl) is getting this warning in native: JSON value 's' of type NSString cannot be converted to a YGValue
  // Only get the warning when we pass these variants to "InnerView", so need to remove them
  // Other than that everthing still works well, because the "View" component is still getting the correct spacing props
  const {
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
    paddingX,
    paddingY,
    ...rest
  } = props
  return {
    ...rest
  }
}

export const convertNativeToWebStyle = (style: ViewStyleProp | undefined): React.CSSProperties | undefined => {
  let adjustedStyle = style

  // styled-components will convert an array of styles into a single object with numeric keys, so we need to convert it back to an array
  if (util.isPlainObject(style) && '0' in style) {
    adjustedStyle = []

    for (let i = 0; i < Object.keys(style).length; i++) {
      adjustedStyle.push((style as Record<string, any>)[i.toString()])
    }
  }

  if (util.isArray(adjustedStyle)) {
    return adjustedStyle
      .map(item => convertNativeToWebStyle(item as ViewStyleProp))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {})
  }

  if (util.isPlainObject(adjustedStyle)) {
    return Object.entries(adjustedStyle).reduce((acc, [attribute, nativeValue]) => {
      if (typeof nativeValue === 'undefined') {
        return acc
      }

      const webValue = getStyleValue(attribute, nativeValue, attribute.startsWith('--'))
      const transformer = styleTransforms[attribute as keyof ViewStyle]

      return transformer ? { ...acc, ...transformer(webValue) } : { ...acc, [attribute]: webValue }
    }, {})
  }

  return undefined
}

const styledSystemProps: (string | number | symbol)[] =
  compose(
    space,
    typography,
    color,
    layout,
    flexbox,
    border,
    background,
    position,
    grid,
    shadow,
    buttonStyle,
    textStyle,
    colorStyle
  ).propNames ?? []

// styled-components forwards styled-system's props to the underlying dom component, resulting in React errors,
// so adding this to our primitive components (Text, View) to filter them out
// https://github.com/styled-system/styled-system/issues/1044
export const getStyledConfig = (forwardProps?: string[]): StyledConfig<any> => ({
  shouldForwardProp: prop => !styledSystemProps.includes(prop) || !!forwardProps?.includes(prop.toString())
})
