import { MarketCofounderState, presentmentCurrencies, TranslatedText } from 'core'

import { useTranslation } from '~/lite'

export const useOptions = () => {
  const t = useTranslation()

  const cofounder: { value: MarketCofounderState; label: TranslatedText }[] = [
    { value: 'Seeking', label: t('Actively seeking') },
    { value: 'Open', label: t('Open to offers') },
    { value: 'Closed', label: t('Closed to offers') }
  ]

  const commission = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(commission => ({
    value: commission,
    label: commission === 0 ? t('Disabled') : (`${commission}%` as TranslatedText)
  }))

  const currency = presentmentCurrencies.map(currency => ({
    value: currency,
    label: currency as TranslatedText
  }))

  return { cofounder, commission, currency }
}
