import { util } from '../util'

// encoding/decoding based on https://github.com/js-cookie/js-cookie

interface ICookieAttributes {
  expires?: number | Date
  path?: string
}

export const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie?.split('; ') ?? []

  for (const cookie of cookies) {
    const [key, ...parts] = cookie.split('=')

    if (decodeURIComponent(key) === name) {
      let value = parts.join('=')

      if (value?.[0] === '"') {
        value = value.slice(1, -1)
      }

      return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
    }
  }

  return undefined
}

export const setCookie = (name: string, value: string, attributes?: ICookieAttributes) => {
  const attr = { path: '/', expires: 10 * 365, ...attributes }

  if (typeof attr.expires === 'number') {
    // numeric expires is number of days
    attr.expires = new Date(Date.now() + attr.expires * 24 * 60 * 60 * 1000)
  }

  const attributeString = Object.entries(attr)
    .map(([key, value]) => {
      if (!value) {
        return null
      }

      if ((value as any) === true) {
        return key
      }

      if (util.isDate(value)) {
        return `${key}=${value.toUTCString()}`
      }

      // if an attribute value has a semi-colon, drop it and everything past it (RFC 6265 section 5.2)
      return `${key}=${value.toString().split(';')[0]}`
    })
    .filter(Boolean)
    .join('; ')

  const encodedName = encodeURIComponent(name)
    .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
    .replace(/[()]/g, escape)

  const encodedValue = encodeURIComponent(value).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent)

  document.cookie = `${encodedName}=${encodedValue}; ${attributeString}`
}

export const deleteCookie = (name: string, attributes?: ICookieAttributes) =>
  setCookie(name, '', { ...attributes, expires: -1 })

let listening = false
const listeners: (() => void)[] = []

const listenForCookieChanges = () => {
  let lastCookie = document.cookie

  setInterval(() => {
    const currentCookie = document.cookie

    if (currentCookie !== lastCookie) {
      listeners.forEach(listener => listener())
      lastCookie = currentCookie
    }
  }, 2000)
}

export const onCookieChange = (listener: typeof listeners[number]) => {
  listeners.push(listener)

  if (!listening) {
    listening = true
    listenForCookieChanges()
  }

  return () => {
    const index = listeners.indexOf(listener)
    listeners.splice(index, 1)
  }
}
