import type { QueryClientConfig } from '@tanstack/react-query'

import * as fixtures from './fixtures'
import { ThemeName } from './models'
import { setAppInstanceId, setAppVersion, setCookieHeader, setExtensionVersion, setServerUrl } from './util/api'
import { JobScope } from './util/query'
export * from './api'
export { uploadToS3Url } from './api.external'
export { fixtures }
export * from './components/Job'
export * from './components/JobCard'
export * from './extension'
export * from './lib/type-fest'
export * from './models'
export * from './notifications'
export * from './pusher'
export * from './routes'
export * from './sitemap'
export * from './socket'
export type { Endpoint, Errors, FailureResponse, GenericSuccessResponse, IApiResponse, Response } from './util/api'
export {
  convertDateValues,
  getCountry,
  getFetchParams,
  setCountry,
  setDeviceId,
  setFailOnApiError,
  setLocale,
  setUseNativeFetch,
  subscribeToApiResponses
} from './util/api'
export * from './util/autofill'
export * from './util/currency'
export * from './util/date'
export * from './util/env'
export * from './util/geo'
export * from './util/helpers'
export * from './util/i18n'
export * from './util/i18n-countries'
export * from './util/parsers'
export * from './util/query'
export * from './util/state'
export * from './util/string'
export * from './validations'

export const defaultThemeName: ThemeName = 'light'

export const defaultJobScope: JobScope = ''

export const reactQueryClientOptions: QueryClientConfig['defaultOptions'] = {
  queries: {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  }
}

export const init = ({
  apiServerUrl,
  appInstanceId,
  appVersion,
  extensionVersion,
  cookie
}: {
  apiServerUrl: string
  appInstanceId: string
  appVersion: string | null
  extensionVersion?: string
  cookie?: string
}): void => {
  setServerUrl(apiServerUrl)
  setAppInstanceId(appInstanceId)
  setAppVersion(appVersion)

  if (cookie) {
    setCookieHeader(cookie)
  }

  if (extensionVersion) {
    setExtensionVersion(extensionVersion)
  }
}
