import type { TranslatedText } from 'core'
import React from 'react'

import { Logo, Pressable, Text, useHeaderHeight, View } from '~/lite'

export const HeaderLogo: React.FC = () => {
  const headerHeight = useHeaderHeight()

  return (
    <View
      position="absolute"
      width={150}
      height={!headerHeight ? '100%' : headerHeight}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      zIndex={10}
    >
      <Pressable to="/" display="flex" flexDirection="row" alignItems="center">
        <Logo size={20} />
        <Text rawText={'RemoteAmbition' as TranslatedText} fontWeight={600} fontSize={16} marginLeft="xs" />
      </Pressable>
    </View>
  )
}
