import { FieldValue, IApplicationFieldWithAnswer, markTranslated } from 'core'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, FlexVertical, H4, View } from '~/components'

import { DynamicFormComponent } from '../DynamicFormComponent'
import { GroupTag } from '../styles'

interface IGroupProps {
  onChange(value: Record<string, FieldValue>[]): void
  question: Extract<IApplicationFieldWithAnswer, { type: 'Group' }>
  value: Record<string, FieldValue>[]
}

export const Group: React.FC<IGroupProps> = ({ onChange, question, value = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const { control: groupControl, formState, handleSubmit, reset } = useForm({})

  const onSaveButtonPress = handleSubmit(data => {
    const newValue = [...value]
    if (selectedIndex !== null) {
      newValue[selectedIndex] = data
    } else {
      newValue.push(data)
    }
    onChange(newValue)
    reset({})
    setIsOpen(!isOpen)
    setSelectedIndex(null)
  })

  const onItemRemove = (removeIndex: number) => {
    let newValue = [...value]
    newValue = newValue.filter((_, index) => index !== removeIndex)
    onChange(newValue)
  }

  const onItemPress = (selectedIndex: number) => {
    const selectedItem: any = value[selectedIndex]
    setIsOpen(true)
    setSelectedIndex(selectedIndex)
    reset(selectedItem)
  }

  const onCloseButtonPress = useCallback(() => {
    reset({})
    setIsOpen(isOpen => !isOpen)
    setSelectedIndex(null)
  }, [reset])

  return (
    <>
      <H4 rawText={question.label} />
      <View display="flex" flexDirection="row" paddingBottom="2%" alignItems="center" flexWrap="wrap">
        {value.map((_, index) => (
          <GroupTag
            key={index}
            primary={selectedIndex === index}
            onRemove={() => onItemRemove(index)}
            onPress={() => onItemPress(index)}
            rawText={markTranslated(`${question.label} ${index + 1}`)}
            skipTracking
          />
        ))}
      </View>
      {isOpen ? (
        <FlexVertical borderWidth={1} borderRadius={10} padding="m" marginBottom="s">
          {question.fields.map(formQuestion => (
            <DynamicFormComponent
              key={formQuestion.id}
              question={formQuestion}
              control={groupControl}
              error={formState.errors[formQuestion.id]?.message as any}
              source="Application"
            />
          ))}
          <Button text="Save" onPress={onSaveButtonPress} marginBottom="2%" skipTracking />
          <Button minimal text="Close" onPress={onCloseButtonPress} marginBottom="2%" skipTracking />
        </FlexVertical>
      ) : (
        <Button text="Add" onPress={() => setIsOpen(!isOpen)} marginBottom="2%" skipTracking />
      )}
    </>
  )
}
