import React from 'react'

import { Avatar } from '~/components'

type ProfileAvatarProps = {
  name?: string
  avatar?: string | null
  size?: number
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ name, avatar, size }) => {
  if (avatar) {
    return <Avatar source={{ uri: avatar }} borderRadius={size} width={size} height={size} />
  }

  return <Avatar recordKey={name} width={size} height={size} />
}
