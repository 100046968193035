import React, { useCallback, useRef } from 'react'

import { Icon, Loader, useStore, View } from '~/lite'

import { Grid, LeftArrow, RightArrow, Slide, Slides, Wrap } from './styles'

interface ICarouselProps {
  isLoading?: boolean
  loadingComponent?: React.ReactNode
  rows?: number
  itemMinWidth?: number
  enabledOnDesktop?: boolean
  children?: React.ReactElement<any>[]
}

export const Carousel: React.FC<ICarouselProps> = ({
  isLoading,
  loadingComponent,
  rows = 1,
  itemMinWidth = 300,
  enabledOnDesktop = false,
  children
}) => {
  const scroller = useRef<HTMLDivElement>(null)
  const theme = useStore(state => state.theme)

  const onLeftArrowPress = useCallback(() => {
    scroller.current?.scrollBy(-300, 0)
  }, [])

  const onRightArrowPress = useCallback(() => {
    scroller.current?.scrollBy(300, 0)
  }, [])

  if (isLoading) {
    if (loadingComponent) {
      return <View>{loadingComponent}</View>
    }

    return <Loader />
  }

  return (
    <Wrap $enabledOnDesktop={enabledOnDesktop}>
      {enabledOnDesktop && (
        <>
          <LeftArrow onPress={onLeftArrowPress}>
            <Icon icon="chevron-back-outline" size={30} color={theme.primary} />
          </LeftArrow>
          <RightArrow onPress={onRightArrowPress}>
            <Icon icon="chevron-forward-outline" size={30} color={theme.primary} />
          </RightArrow>
        </>
      )}
      <Slides ref={scroller}>
        <Grid
          className="grid"
          $itemMinWidth={itemMinWidth}
          $width={rows > 1 && children ? Math.ceil(children.length / rows) * itemMinWidth + 10 : undefined}
        >
          {children?.map((child, index) => (
            <Slide key={index}>{child}</Slide>
          ))}
        </Grid>
      </Slides>
    </Wrap>
  )
}
