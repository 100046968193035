import { isServer } from '../../env'
import { storageKeys } from './shared'

// @todo figure out what adjustments are needed in code attempting to use these methods server-side

const get = async (key: string): Promise<string | null> => {
  try {
    return isServer ? null : window.localStorage.getItem(key)
  } catch (e) {
    console.log(`Error reading item: ${key}`)
    throw e
  }
}

const set = async (key: string, value: string): Promise<void> => {
  try {
    return isServer ? undefined : window.localStorage.setItem(key, value)
  } catch (e) {
    console.log(`Error saving item: ${key}`)
    throw e
  }
}

const remove = async (key: string): Promise<void> => {
  try {
    return isServer ? undefined : window.localStorage.removeItem(key)
  } catch (e) {
    console.log(`Error deleting item: ${key}`)
    throw e
  }
}

const clear = async (): Promise<void> => {
  try {
    return isServer ? undefined : window.localStorage.clear()
  } catch (e) {
    console.log('Error clearing data')
    throw e
  }
}

const onChange = (listener: (key: string | null, newValue: string | null, oldValue: string | null) => void) => {
  if (isServer) {
    return () => {}
  }

  const callback = (e: StorageEvent) => {
    if (e.key === null || storageKeys.includes(e.key)) {
      listener(e.key, e.newValue, e.oldValue)
    }
  }

  window.addEventListener('storage', callback)

  return () => window.removeEventListener('storage', callback)
}

export const provider = { get, set, remove, clear, onChange }
