import { PositionList } from '@ambition/module-position'
import { CompanyDetail } from '@ambition/module-team'
import { CollectionSlug, ExplorePositionType, ExplorePositionTypeQueryMapping, IPosition } from 'core'
import React from 'react'
import { useParams } from 'react-router-dom'

import { usePositionQuery } from '~/hooks'
import { useStore } from '~/lite'

import { CollectionContent } from './CollectionContent'

export interface ICollectionProps {
  collectionSlug: CollectionSlug
}

export const Collection: React.FC<ICollectionProps> = ({ collectionSlug }) => {
  const currentTeam = useStore(state => state.currentTeam)
  const params = useParams()
  const positionId = params.positionId as IPosition['id']
  const explorePositionQueryKey = ExplorePositionTypeQueryMapping[collectionSlug as ExplorePositionType]
  const { collectionType, query } = usePositionQuery(collectionSlug)

  if (collectionType === 'Team' && !currentTeam) {
    const team = query.teams?.length ? query.teams[0] : null
    return team ? <CompanyDetail username={team} /> : null
  }

  return explorePositionQueryKey ? (
    <PositionList type={collectionSlug as ExplorePositionType} positionId={positionId} />
  ) : (
    <CollectionContent collectionSlug={collectionSlug} positionId={positionId} />
  )
}
