import { TranslatedText } from 'core'
import React, { useCallback } from 'react'

import { useTranslation } from '../hooks'
import { useHistory } from '../navigation'
import { styled } from '../styled'
import { Text } from './Text'
import { ILinkProps, ITextProps } from './types'

const StyledText = styled(Text)`
  color: ${props => props.theme.primary};
`

export const Link: React.FC<ILinkProps> = ({
  to,
  href,
  hrefAttrs,
  title,
  rawTitle,
  onPress: _onPress,
  testID,
  text,
  rawText,
  style,
  ...rest
}) => {
  const t = useTranslation()
  const history = useHistory()

  const onPress = useCallback<NonNullable<ITextProps['onPress']>>(
    e => {
      _onPress?.(e)

      if (to) {
        e.preventDefault()
        to === 'goBack' ? history.goBack() : history.push(to)
      }
    },
    [history, to, _onPress]
  )

  const props: Partial<Omit<ITextProps, 'text' | 'raw' | 'rawText'> & { title?: TranslatedText }> = {
    testID,
    href: to ?? href,
    hrefAttrs,
    title: rawTitle ? rawTitle : title ? t(title) : undefined,
    onPress,
    style
  }

  return rawText ? (
    <StyledText {...props} {...rest} rawText={rawText} />
  ) : text ? (
    <StyledText {...props} {...rest} text={text} />
  ) : null
}
