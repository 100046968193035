import { ISavedState, parseSavedState } from 'core'
import { isEqual } from 'lodash'
import { StateCreator } from 'zustand'

import { isStaging } from '../../constants'
import { isWeb } from '../../env'
import { getCookie } from '../../util/cookie'
import { loadData, saveData } from '../../util/storage'
import { getJobScopeValues } from './createJobScopeSlice'
import { getThemeValues } from './createThemeSlice'
import { IPersistenceSlice, IStoreState } from './types'

export const serializeState = (state: IStoreState): ISavedState => ({
  isAuthenticated: !!state.isAuthenticated,
  locale: state.locale,
  themeName: state.themeName,
  jobScope: state.jobScope,
  preferRemoteJobs: state.preferRemoteJobs,
  datePosted: state.datePosted,
  distanceRadiusFilter: state.distanceRadiusFilter,
  compensationFilter: state.compensationFilter,
  compensationFrequencyFilter: state.compensationFrequencyFilter
})

const getCookieState = (): ISavedState => {
  const cookiePrefix = isStaging ? 'staging-' : ''

  return parseSavedState({
    isAuthenticated: getCookie(`${cookiePrefix}authenticated`),
    locale: getCookie(`${cookiePrefix}locale`),
    themeName: getCookie(`${cookiePrefix}theme`),
    jobScope: getCookie(`${cookiePrefix}jobScope`),
    preferRemoteJobs: getCookie(`${cookiePrefix}preferRemoteJobs`),
    datePosted: getCookie(`${cookiePrefix}datePosted`),
    distanceRadiusFilter: getCookie(`${cookiePrefix}distanceRadiusFilter`),
    compensationFilter: getCookie(`${cookiePrefix}compensationFilter`),
    compensationFrequencyFilter: getCookie(`${cookiePrefix}compensationFrequencies`)
  })
}

export const loadSavedState = async (): Promise<ISavedState | null> => {
  const data = await loadData('state')

  const state = data
    ? parseSavedState({
        locale: undefined,
        themeName: undefined,
        jobScope: undefined,
        ...data
      })
    : null

  return isWeb ? { ...(state ?? {}), ...getCookieState() } : state
}

export const saveState = async (state: ISavedState): Promise<void> => {
  const savedState = await loadSavedState()

  if (!isEqual(savedState, state)) {
    await saveData('state', state, { unversioned: true })
  }
}

export const createPersistenceSlice: StateCreator<IStoreState, [], [], IPersistenceSlice> = (set, get) => ({
  hydrated: false,

  hydrate: async () => {
    const savedState = await loadSavedState()

    if (!savedState) {
      set(() => ({ hydrated: true }))
      return
    }

    const { jobScope, themeName, ...state } = savedState

    const scopeValues = jobScope ? getJobScopeValues(jobScope) ?? {} : {}
    const themeValues = themeName ? getThemeValues(themeName) : {}

    set(() => ({
      hydrated: true,
      ...state,
      ...scopeValues,
      ...themeValues
    }))
  }
})
