import { isServer } from '~/lite'

// @todo switch over to useBreakpoints hook
export const getWindowHeight = () => (isServer ? 800 : window.innerHeight)

export const getWindowWidth = () => (isServer ? 1200 : window.innerWidth)

export const getScreenHeight = () => (isServer ? 800 : window.innerHeight)

export const getScreenWidth = () => (isServer ? 1200 : window.innerWidth)
