import { UntranslatedText } from 'core'
import React from 'react'

import { useBreakpoints } from '~/hooks'

import { Text } from '../Text/Text'
import { ViewProps } from '../View/View'
import { Body, CloseIcon, GeneralError, Header, HeaderInner, Subheader, Wrap } from './styles'

export interface IPanelProps {
  testID?: string
  header?: UntranslatedText
  subheader?: React.ReactNode
  errors?: { [field: string]: UntranslatedText | undefined }
  noBodyPadding?: boolean
  active?: boolean
  onClose?(): void
  fill?: boolean
  errorPosition?: 'TOP' | 'BOTTOM'
  style?: ViewProps['style']
  children?: React.ReactNode
}

export const Panel: React.FC<IPanelProps> = ({
  header,
  subheader,
  errors,
  noBodyPadding = false,
  active,
  onClose,
  children,
  style,
  fill,
  errorPosition = 'TOP',
  ...rest
}) => {
  const { isPhone } = useBreakpoints()

  const renderErrors = () => {
    return (
      errors?.general && (
        <GeneralError>
          <Text text={errors.general} />
        </GeneralError>
      )
    )
  }

  return (
    <Wrap style={style} $isPhone={isPhone} $active={active} $fill={fill} {...rest}>
      {header && (
        <Header>
          <HeaderInner text={header} />
          {onClose && <CloseIcon icon="close" size={20} onPress={onClose} />}
        </Header>
      )}
      {subheader && <Subheader>{subheader}</Subheader>}
      <Body $noBodyPadding={noBodyPadding}>
        {errorPosition === 'TOP' && renderErrors()}
        {children}
        {errorPosition === 'BOTTOM' && renderErrors()}
      </Body>
    </Wrap>
  )
}
