import { useQueryClient } from '@tanstack/react-query'
import {
  CreateCoverLetter,
  createCoverLetter,
  defaultCoverLetterSettings,
  formatCurrency,
  generatePdf,
  getCoverLetterHtml,
  ICoverLetterSettings,
  IPosition,
  localizePath,
  outOfCreditError,
  supportedLocales,
  TranslatedText
} from 'core'
import React, { startTransition, useCallback, useMemo, useRef, useState } from 'react'

import { Editor, HtmlEditor, Input, Menu } from '~/components'
import { useStudioData, useUserSubscriptionBenefit } from '~/hooks'
import {
  animations,
  AnnouncementCard,
  breakpoint,
  Button,
  css,
  DesktopView,
  isWeb,
  Link,
  Loader,
  MetaTags,
  PhoneView,
  styled,
  Text,
  toast,
  useBreakpoints,
  useStore,
  useTranslation,
  View
} from '~/lite'

import { IResumeImportProps, ResumeImport } from '../MagicLink/ResumeImport'
import { SignUpNote } from '../SignUpNote'
import { Settings } from './Settings'
import { createCoverLetterWithStreamingResponse } from './util'
import { Welcome } from './Welcome'

const Wrap = styled(View)`
  flex: 1;
  height: 100%;
  padding: 16px;
  align-content: stretch;
  align-items: stretch;
  gap: 16px;

  @media ${breakpoint.lg} {
    flex-direction: row;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
  }
`

const Master = styled(View)`
  @media ${breakpoint.lg} {
    width: 400px;
  }
`

const Detail = styled(View)<{ $variant?: 'Apply' | 'Dashboard' }>`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  justify-content: stretch;
  align-items: stretch;

  @media ${breakpoint.lg} {
    min-height: ${props =>
      props.$variant === 'Dashboard'
        ? 'calc(max(calc(100vh - 92px - 60px), 600px))'
        : 'calc(max(calc(100vh - 92px), 600px))'};
    flex: 1;
  }
`

const Sidebar = styled(View)<{ $animated: boolean }>`
  flex: 1;
  gap: 16px;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: ${props => (props.$animated ? animations.fadeInUp : 'none')};
`

const cardColors = ['#85c6c7', '#ff9a9e', '#98d887', '#90d1eb', '#bbbaf7', '#ff9a9e', '#eac287']

const CoverLetterCards = styled(View)`
  flex-direction: row;
  gap: 8px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.lg} {
    flex-wrap: wrap;
    overflow-x: visible;
  }
`

const CoverLetterDot = styled(View)<{ $delay: number }>`
  font-size: 18px;
  font-weight: bold;
  background: ${props => cardColors[props.$delay % cardColors.length]};
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform-origin: center center;
  pointer-events: none;
  margin-right: 8px;
`

const CoverLetterCard = styled(View)<{ $delay: number }>`
  flex-direction: row;
  align-items: center;
  border-width: 1px;
  padding: 8px 12px;
  border-radius: 50px;
  background-color: ${props => cardColors[props.$delay % cardColors.length]};
  background: ${props => props.theme.cardBackground};
  transition: transform 100ms ease-in-out;
  cursor: pointer;

  ${isWeb &&
  css`
    @media ${breakpoint.lg} {
      &:hover {
        transform: scale(1.04);
      }
    }
  `}
`

const CoverLetterCardText = styled(Text)`
  font-size: 14px;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 8px;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
  animation-name: ${animations.fadeIn};
`

const ResumeImportWrap = styled(View)`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  border-width: 1px;
  padding-top: 16px;
  margin-top: 16px;
`

const SignUpNoteWrap = styled.div`
  display: block;
  padding: 0 8px;
  margin-top: -4px;
  font-size: 11px;
  text-align: center;
  opacity: 0.8;
  text-wrap: balance;

  a:hover {
    text-decoration: underline;
  }
`

const InputWrap = styled(View)`
  position: relative;
  flex: 1;

  ${isWeb &&
  css`
    textarea {
      height: 100%;
      padding: 16px;
    }

    textarea::placeholder {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translate(0, -50%);
      padding: 16px;
      text-align: center;
    }
  `}
`

const CoverLetterButton = styled(Button)`
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(${props => props.theme.backgroundRgb}, 1);
`

const CoverLetterButtonWrap = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoint.lg} {
    padding-bottom: 16px;
  }
`

const CoverLetter = styled(View)`
  width: 100%;
  flex: 1;
  overflow: hidden;
  border-width: 1px;
  border-radius: 12px;

  > * {
    height: 100%;
  }
`

const AffiliateLink = styled(View)`
  width: 100%;
  padding-top: 16px;
  align-items: center;
`

const StudioWrap = styled(View)`
  flex: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
`

const UnlimitedCreditsText = styled(Text)`
  white-space: nowrap;
  padding: 8px 16px;
  border-radius: 100px;
  background: ${props => props.theme.backgroundContrast};
  border-width: 1px;
  font-size: 12px;
`

const Languages = styled(View)`
  width: 100%;
  font-size: 14px;
  opacity: 0.8;
  display: grid;
  padding: 16px 16px 0;
  background: ${props => props.theme.background};
  grid-template-columns: repeat(auto-fill, minmax(150px, max-content));
  justify-content: center;

  @media ${breakpoint.lg} {
    padding: 16px 0 0;
  }
`

const LanguageLink = styled(Link)`
  padding: 4px 0;
  font-size: 12px;
  color: ${props => props.theme.text}aa;

  &:hover {
    text-decoration: underline;
  }
`

const Step = styled(Text)<{ $delay: number }>`
  position: absolute;
  top: -16px;
  left: calc(50% - 16px);
  z-index: 10;
  text-align: center;
  font-size: 16px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  border-radius: 100%;
  color: #fff;
  background: #ff8a00;
  pointer-events: none;
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.2);
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.$delay}ms;
  animation-name: ${animations.popIn};
`

const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export interface IStudioProps {
  variant?: 'Apply' | 'Dashboard'
  positionId?: IPosition['id']
  value?: string
  onEditorChange?(editor: Editor): void
}

export const Studio: React.FC<IStudioProps> = ({ variant, positionId, value, onEditorChange }) => {
  const t = useTranslation()
  const { locale, isAuthenticated, isLoadingUser, resumeImportState, isWidget } = useStore()
  const { data, isLoading: isLoadingData, refetch: refetchData } = useStudioData(true)
  const { isPhone } = useBreakpoints()
  const { data: subscriptionBenefits } = useUserSubscriptionBenefit('ambition-plus')
  const queryClient = useQueryClient()

  const [isJobDescriptionEmpty, setIsJobDescriptionEmpty] = useState(true)
  const [isUploadingResume, setIsUploadingResume] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [creatingCoverLetter, setCreatingCoverLetter] = useState(false)
  const [editor, setEditor] = useState<Editor | null>(null)
  const [showWelcome, setShowWelcome] = useState(true)
  const [newSettings, setNewSettings] = useState<ICoverLetterSettings | null>(null)

  const jobDescriptionInputRef = useRef<HTMLInputElement>(null)

  const isImportingResume = resumeImportState === 'Pending' || resumeImportState === 'Processing'
  const hasImportedResume = resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success'

  const settings = useMemo<ICoverLetterSettings>(
    () => ({
      ...defaultCoverLetterSettings,
      ...(data?.data.coverLetterSettings ?? {}),
      ...(newSettings ?? {})
    }),
    [data?.data.coverLetterSettings, newSettings]
  )

  const hasCredit = useMemo(() => {
    return (
      subscriptionBenefits?.benefits &&
      (subscriptionBenefits.benefits.numberOfCoverLetter > 0 || !!subscriptionBenefits.benefits.unlimitedCoverLetter)
    )
  }, [subscriptionBenefits])

  const handleCreateCoverLetter = useCallback<(params: Parameters<typeof createCoverLetter>[0]) => Promise<void>>(
    async params => {
      if (!editor) {
        toast.failure('An error has occurred')
        throw new Error('Tried to create cover letter before editor was ready')
      }
      if (!hasImportedResume) {
        toast.failure('Please add your CV')
        return
      }
      if (!positionId && !params.jobTitle && !params.jobDescription) {
        toast.failure('Please enter a job description')
        return
      }
      if (!hasCredit) {
        toast.failure('You have run out of credits. Please upgrade to continue using this feature.')
        return
      }

      setShowWelcome(false)
      setCreatingCoverLetter(true)

      const handleMessageResponse = async (response: CreateCoverLetter['response']) => {
        if (response.success) {
          if (response.error === outOfCreditError) {
            toast.failure('You have run out of credits. Please upgrade to continue using this feature.')
            return
          }
          if (!response.coverLetter) {
            // first message from stream
            queryClient.invalidateQueries(['user-subscription-benefit'])
          }
          startTransition(() => {
            if (editor) {
              editor?.commands.setContent(getCoverLetterHtml(response.coverLetter))
            }
          })
        }
      }

      if (isWeb) {
        await createCoverLetterWithStreamingResponse(params, handleMessageResponse)
      } else {
        handleMessageResponse(await createCoverLetter(params))
      }

      setCreatingCoverLetter(false)
    },
    [editor, queryClient, hasImportedResume, positionId, hasCredit]
  )

  const onResumeUploadChange = useCallback(() => setIsUploadingResume(true), [])

  const onResumeImportChange: IResumeImportProps['onChange'] = useCallback(
    state => {
      if (state === 'RecommendationsAdded') {
        Promise.all([refetchData(), new Promise(resolve => setTimeout(resolve, 3000))]).then(() =>
          setIsUploadingResume(false)
        )
      }
    },
    [refetchData]
  )

  const jobDescriptionInput = useMemo(
    () => (
      <Input
        ref={jobDescriptionInputRef}
        placeholder="Paste the job description here"
        onChange={(value: TranslatedText) => {
          startTransition(() => {
            setIsJobDescriptionEmpty(value.length === 0)
          })
        }}
        flex={1}
        wrapStyle={{ borderRadius: 12 }}
        numberOfLines={5}
      />
    ),
    []
  )

  const settingsView = data?.data.content ? (
    <Settings
      variant={variant === 'Apply' ? variant : hasImportedResume ? undefined : 'Step'}
      content={data.data.content}
      settings={settings}
      onChange={changes => setNewSettings(value => ({ ...settings, ...value, ...changes }))}
    />
  ) : null

  const affiliateLink = data?.data.content.affiliate ? (
    <AffiliateLink>
      <AnnouncementCard
        announcement={{
          tag: 'Earn 30% commission',
          rawMessage: t('Share this tool and earn up to {{amount}} per referral', {
            amount: formatCurrency(
              locale,
              data.data.content.affiliate.currency,
              data.data.content.affiliate.amount,
              true
            ) as TranslatedText
          })
        }}
        to={localizePath('/affiliate', locale)}
        subtleTag
      />
    </AffiliateLink>
  ) : null

  const coverLetterInput = useMemo(
    () => (
      <HtmlEditor
        value={value || null}
        theme={variant === 'Apply' ? 'Mini' : undefined}
        style={{ borderRadius: 0, borderWidth: 0 }}
        bodyStyle={
          variant === 'Apply'
            ? { padding: 32, minHeight: 450 }
            : { minHeight: 'calc(100% - 57px)', padding: isPhone ? 16 : 72, paddingTop: 60 }
        }
        onReady={editor => {
          setEditor(editor)
          onEditorChange?.(editor)
        }}
        menuChildren={
          variant !== 'Apply' && (
            <>
              {!isPhone && <View flex={1} />}
              {!!editor && (
                <Menu
                  align="end"
                  items={[
                    {
                      text: 'Download',
                      onPress: async () => {
                        if (!editor) {
                          toast.failure('An error has occurred')
                          throw new Error('Tried to download cover letter before editor was ready')
                        }

                        setIsDownloading(true)
                        const html = getCoverLetterHtml(editor.getHTML())
                        const pdf = await generatePdf({ html, download: false, appendResume: false })
                        downloadFile(pdf, 'cover-letter.pdf')
                        setIsDownloading(false)
                      }
                    },
                    {
                      text: 'Download with CV',
                      onPress: async () => {
                        if (!editor) {
                          toast.failure('An error has occurred')
                          throw new Error('Tried to download cover letter before editor was ready')
                        }

                        if (!hasImportedResume) {
                          toast.failure('Please add your CV')
                          return
                        }

                        setIsDownloading(true)
                        const html = getCoverLetterHtml(editor.getHTML())
                        const pdf = await generatePdf({ html, download: false, appendResume: true })
                        downloadFile(pdf, 'cover-letter.pdf')
                        setIsDownloading(false)
                      }
                    }
                  ]}
                >
                  <Button
                    action="DownloadCoverLetter"
                    icon="download-outline"
                    text="Download PDF"
                    disabled={creatingCoverLetter || isDownloading}
                    loading={isDownloading}
                    borderRadius={6}
                    marginX={8}
                    primary
                  />
                </Menu>
              )}
            </>
          )
        }
      />
    ),
    [creatingCoverLetter, editor, isDownloading, isPhone, variant, onEditorChange, value, hasImportedResume]
  )

  const creditsView = (
    <View paddingTop="s" flexDirection="row" alignItems="center" minHeight={36}>
      {subscriptionBenefits?.benefits &&
        (subscriptionBenefits.benefits.unlimitedCoverLetter ? (
          <UnlimitedCreditsText text="Unlimited credits" />
        ) : (
          <>
            <Text
              opacity={0.8}
              rawText={t('{{count}} credits left', { count: subscriptionBenefits.benefits.numberOfCoverLetter })}
            />
            <Button
              action={
                variant === 'Apply'
                  ? 'UpgradeViaApplyStudio'
                  : variant === 'Dashboard'
                  ? 'UpgradeViaDashboardStudio'
                  : 'UpgradeViaStudioScreen'
              }
              text="Upgrade"
              to={localizePath('/plus', locale)}
              marginLeft={8}
              small
            />
          </>
        ))}
    </View>
  )

  if (variant === 'Apply') {
    return (
      <>
        <StudioWrap>{coverLetterInput}</StudioWrap>
        <View
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX="m"
          paddingY="m"
          borderTopWidth={1}
        >
          {settingsView}
          <Button
            action="CreateCoverLetter"
            text="Generate Cover Letter"
            loadingText="Generate Cover Letter"
            icon="sparkles"
            iconSize={16}
            onPress={() =>
              handleCreateCoverLetter({
                resume: null,
                positionId: positionId ?? null,
                jobTitle: null,
                jobDescription: null,
                settings
              })
            }
            disabled={creatingCoverLetter}
            loading={creatingCoverLetter}
            large
            primary
          />

          {creditsView}
        </View>
      </>
    )
  }

  const isInitialLoad = (isLoadingUser || isLoadingData) && !isUploadingResume && !isImportingResume

  const metaTags = data?.success ? (
    <MetaTags
      type="Content"
      title={data.data.content.metaTags.title}
      description={data.data.content.metaTags.description}
      localizedPath="/studio"
    />
  ) : null

  const welcome =
    showWelcome && data?.success && data.data.showWelcome && variant !== 'Dashboard' ? (
      <Welcome content={data.data.content.welcome} />
    ) : null

  const languages =
    welcome && !isWidget && variant !== 'Dashboard' ? (
      <Languages>
        {supportedLocales.map(locale => (
          <LanguageLink key={locale.code} rawText={locale.name} href={localizePath('/studio', locale.code)} />
        ))}
      </Languages>
    ) : null

  const sidebar =
    isAuthenticated && isInitialLoad ? null : (
      <Sidebar $animated={!!isAuthenticated}>
        {!hasImportedResume && (
          <>
            <ResumeImportWrap>
              <Step text={1} $delay={1000} />
              <ResumeImport
                variant="CoverLetter"
                onUploadChange={onResumeUploadChange}
                onChange={onResumeImportChange}
                skipSignupNote
              />
            </ResumeImportWrap>
            {!isImportingResume && (
              <SignUpNoteWrap>
                <SignUpNote style={{ fontSize: 11 }} />
              </SignUpNoteWrap>
            )}
          </>
        )}
        {!positionId && hasImportedResume && data?.success && data.data.recommendedJobTitles.length > 0 && (
          <CoverLetterCards>
            {data.data.recommendedJobTitles.map((jobTitle, index) => (
              <CoverLetterCard
                key={index}
                $delay={index}
                onPress={() => {
                  if (!creatingCoverLetter) {
                    handleCreateCoverLetter({
                      resume: null,
                      positionId: null,
                      jobTitle,
                      jobDescription: null,
                      settings
                    })
                  }
                }}
              >
                <CoverLetterDot $delay={index} />
                <CoverLetterCardText rawText={jobTitle} />
              </CoverLetterCard>
            ))}
          </CoverLetterCards>
        )}
        {!positionId && (
          <InputWrap marginTop={hasImportedResume ? 0 : 16}>
            {!hasImportedResume && <Step text={2} $delay={2500} />}
            {!isJobDescriptionEmpty && (
              <CloseButton
                icon="close-circle"
                padding={0}
                iconSize={32}
                color="#5c7080"
                onPress={() => {
                  ;(jobDescriptionInputRef.current as any)?.clear()
                  setIsJobDescriptionEmpty(true)
                }}
                minimal
                skipTracking
              />
            )}
            {jobDescriptionInput}
          </InputWrap>
        )}
        <CoverLetterButtonWrap marginTop={hasImportedResume ? 0 : 16}>
          {!hasImportedResume && <Step text={positionId ? 2 : 3} $delay={4000} />}
          {settingsView}
          <CoverLetterButton
            action="CreateCoverLetter"
            icon="sparkles"
            text="Generate Cover Letter"
            loadingText="Generate Cover Letter"
            onPress={() =>
              handleCreateCoverLetter({
                resume: null,
                positionId: null,
                jobTitle: null,
                jobDescription: ((jobDescriptionInputRef.current as any)?.getValue() || null) as TranslatedText | null,
                settings
              })
            }
            disabled={creatingCoverLetter}
            loading={creatingCoverLetter}
            primary
            large
          />
          {creditsView}
        </CoverLetterButtonWrap>
      </Sidebar>
    )

  return (
    <Wrap>
      {metaTags}
      {welcome && <PhoneView>{welcome}</PhoneView>}
      <Master>{sidebar ?? <Loader />}</Master>
      <Detail $variant={variant}>
        {welcome ? (
          <DesktopView>{welcome}</DesktopView>
        ) : isAuthenticated || variant === 'Dashboard' ? null : (
          <Loader />
        )}
        <CoverLetter>{coverLetterInput}</CoverLetter>
        {variant !== 'Dashboard' && <DesktopView width="100%">{affiliateLink}</DesktopView>}
        {languages}
      </Detail>
    </Wrap>
  )
}
