import { IPosition, IProduct, IStripeProduct } from 'core'
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react'

import { Loader } from '~/components'
import { usePosition } from '~/hooks'

const ProductSelect = lazy(() => import('@ambition/module-billing').then(module => ({ default: module.ProductSelect })))

export interface IPromotePositionProps {
  positionId: IPosition['id']
  productId?: IStripeProduct['id']
  onChange?(position: IPosition): void
}

export const PromotePosition: React.FC<IPromotePositionProps> = ({ positionId, productId, onChange }) => {
  const position = usePosition(positionId)
  const [selectedProduct, setSelectedProduct] = useState<IProduct>()

  const positionWithProduct = useMemo<IPosition | undefined>(() => {
    if (!position.data?.position) {
      return undefined
    }

    return typeof selectedProduct?.id === 'undefined'
      ? position.data.position
      : { ...position.data.position, stripeProductId: selectedProduct?.id }
  }, [position.data?.position, selectedProduct])

  useEffect(() => {
    if (onChange && positionWithProduct) {
      onChange(positionWithProduct)
    }
  }, [onChange, positionWithProduct])

  if (position.NonIdealState) {
    return <position.NonIdealState />
  }

  return (
    <Suspense fallback={<Loader />}>
      <ProductSelect
        position={position.data.position}
        productId={productId || selectedProduct?.id}
        onChange={setSelectedProduct}
      />
    </Suspense>
  )
}
