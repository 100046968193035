import { createAuthCode, defaultLocale, SupportedLocaleCode, TranslatedText } from 'core'
import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  animations,
  Button,
  H1,
  H2,
  Icon,
  Link,
  Loader,
  Logo,
  styled,
  Text,
  useStore,
  useTranslation,
  View
} from '~/lite'

import { ResumeImportField, ResumeUploadState } from './MagicLink/ResumeImportField'
import { OpenAiLogo } from './OpenAiLogo'
import { SignUpNote } from './SignUpNote'

const liExport: Record<SupportedLocaleCode, { gif: SupportedLocaleCode; buttons: [string, string] } | null> = {
  'af-ZA': null,
  'ar-SA': null,
  'ca-ES': null,
  'cs-CZ': { gif: 'cs-CZ', buttons: ['Více', 'Uložit ve formátu PDF'] },
  'da-DK': { gif: 'da-DK', buttons: ['Mere', 'Gem som PDF'] },
  'de-DE': { gif: 'de-DE', buttons: ['Mehr', 'Als PDF speichern'] },
  'el-GR': null,
  'en-US': { gif: 'en-US', buttons: ['More', 'Save to PDF'] },
  'es-419': { gif: 'es-ES', buttons: ['Más', 'Guardar en PDF'] },
  'es-ES': { gif: 'es-ES', buttons: ['Más', 'Guardar en PDF'] },
  'fi-FI': null,
  'fr-CA': { gif: 'fr-FR', buttons: ['Plus', 'Enregistrer au format PDF'] },
  'fr-FR': { gif: 'fr-FR', buttons: ['Plus', 'Enregistrer au format PDF'] },
  'he-IL': null,
  'hi-IN': { gif: 'hi-IN', buttons: ['अधिक', 'PDF में सेव करें'] },
  'hr-HR': null,
  'hu-HU': null,
  'hy-AM': null,
  'id-ID': { gif: 'id-ID', buttons: ['Lainnya', 'Simpan sebagai PDF'] },
  'it-IT': { gif: 'it-IT', buttons: ['Altro', 'Salva come PDF'] },
  'ja-JP': { gif: 'ja-JP', buttons: ['その他', 'PDFとして保存する'] },
  'ko-KR': { gif: 'ko-KR', buttons: ['더 보기', 'PDF로 저장'] },
  'ms-MY': { gif: 'ms-MY', buttons: ['Lagi', 'Simpan ke PDF'] },
  'nl-NL': { gif: 'nl-NL', buttons: ['Meer', 'Opslaan als PDF'] },
  'no-NO': { gif: 'no-NO', buttons: ['Flere', 'Lagre til PDF'] },
  'pl-PL': { gif: 'pl-PL', buttons: ['Więcej', 'Zapisz jako PDF'] },
  'pt-BR': { gif: 'pt-PT', buttons: ['Mais', 'Salvar como PDF'] },
  'pt-PT': { gif: 'pt-PT', buttons: ['Mais', 'Salvar como PDF'] },
  'ro-RO': { gif: 'ro-RO', buttons: ['Mai multe', 'Salvați în PDF'] },
  'ru-RU': { gif: 'ru-RU', buttons: ['Еще', 'Сохранить в PDF'] },
  'sk-SK': null,
  'sr-SP': null,
  'sv-SE': { gif: 'sv-SE', buttons: ['Mer', 'Spara som PDF-fil'] },
  'th-TH': { gif: 'th-TH', buttons: ['เพิ่มเติม', 'บันทึกเป็น PDF'] },
  'tr-TR': { gif: 'tr-TR', buttons: ['Daha Fazla', 'PDF olarak kaydet'] },
  'uk-UA': { gif: 'uk-UA', buttons: ['Більше', 'Зберегти в PDF'] },
  'ur-PK': null,
  'vi-VN': null,
  'zh-CN': null,
  'zh-TW': { gif: 'zh-TW', buttons: ['更多內容', '存為 PDF'] }
}

const localesWithoutLIExport: SupportedLocaleCode[] = ['ar-SA', 'zh-CN']

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`

const SystemIcon = styled(View)`
  width: 100px;
  height: 100px;
  padding: ${(100 * ((180 - 116) / 180)) / 2}px;
  border-radius: 100%;
  background: ${props => props.theme.cardBackground};

  svg {
    width: 100%;
    height: 100%;
  }
`

const SyncIcon = styled(View)`
  width: 100px;
  height: 100px;
  padding: 25px;
`

const CheckIcon = styled(View)<{ $numbered: boolean }>`
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  background: ${props => (props.$numbered ? props.theme.primary : '#113123')};
  color: ${props => (props.$numbered ? '#fff' : '#4cc38a')};
  margin-right: 12px;
  text-align: center;
  line-height: 15px;
`

const Cards = styled(View)`
  position: relative;
  display: flex;
  margin: 16px 0;
  overflow: hidden;

  .uppy-FileInput-btn {
    border-radius: 8px;
    font-size: 16px;
    min-height: 42px;
    text-align: center;
  }
`

const Card = styled(View)`
  flex: 1;
  padding: 24px 32px;
  border-width: 1px;
  border-radius: 12px;
`

const SecondaryCard = styled(Card)<{ $visible: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(${props => props.theme.backgroundRgb}, 0.8);
  backdrop-filter: blur(10px);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: ${props => (props.$visible ? animations.slideInUp : animations.slideOutDown)};
  box-shadow: ${props => (props.$visible ? 'rgb(0 0 0 / 8%) 0px -4px 16px' : 'none')};
`

const SignUpNoteWrap = styled.div`
  display: block;
  margin-top: 16px;
  font-size: 12px;
  text-align: center;

  a:hover {
    text-decoration: underline;
  }
`

const Header = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ProgressMessage = styled(View)`
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
`

const Footer = styled(View)`
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  margin-bottom: 32px;
`

const FooterItem = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const FooterIcon = styled(View)`
  max-width: 24px;
  opacity: 0.5;
  margin-right: 8px;
`

const FooterText = styled(Text)`
  opacity: 0.8;
`

interface IOAuthParams {
  responseType: string | null
  clientId: string | null
  scope: string | null
  redirectUri: string | null
}

const useOAuthParams = () => {
  const [params] = useSearchParams()

  return useMemo<IOAuthParams>(
    () => ({
      responseType: params.get('response_type'),
      clientId: params.get('client_id'),
      scope: params.get('scope'),
      redirectUri: params.get('redirect_uri')
    }),
    [params]
  )
}

interface IAuthProps {
  variant: 'ChatGPTOAuth'
}

export const Auth: React.FC<IAuthProps> = () => {
  const params = useOAuthParams()
  const t = useTranslation()
  const locale = useStore(state => state.locale)

  const [showImportFromLinkedIn, setShowImportFromLinkedIn] = useState<boolean | null>(null)
  const [showProgress, setShowProgress] = useState<boolean | null>(false)
  const [resumeUploadState, setResumeUploadState] = useState<ResumeUploadState | null>(null)
  const [authCodeState, setAuthCodeState] = useState<null | 'Pending' | 'Success' | 'Error'>(null)

  const authorizeClient = useCallback(async () => {
    setShowProgress(true)
    setAuthCodeState('Pending')

    if (!params.redirectUri) {
      setAuthCodeState('Error')

      setTimeout(() => {
        throw new Error('OpenAI redirect URI missing')
      }, 2000)

      return
    }

    const results = await createAuthCode('OpenAI')

    if (!results.success) {
      setAuthCodeState('Error')

      setTimeout(() => {
        throw new Error('Could not generate auth code for OpenAI')
      }, 2000)

      return
    }

    setAuthCodeState('Success')
    window.location.href = `${params.redirectUri}?code=${results.code}`
  }, [params.redirectUri])

  const onResumeImportChange = useCallback(
    async (state: ResumeUploadState) => {
      setResumeUploadState(state)

      if (state === 'Uploading') {
        if (showImportFromLinkedIn) {
          setShowImportFromLinkedIn(false)

          // wait for animation to finish
          setTimeout(() => {
            setShowProgress(true)
          }, 500)
        } else {
          setShowProgress(true)
        }
      }

      if (state === 'Uploaded') {
        authorizeClient()
      }
    },
    [showImportFromLinkedIn, authorizeClient]
  )

  const whatWeDo = [
    t('Make your CV available to ChatGPT across conversations and devices'),
    t('Save changes made by you and ChatGPT'),
    t('Read job data from across the web'),
    t('Send new jobs to ChatGPT for it to decide if they are relevant to you. Then we notify you on its behalf.')
  ]

  const features = [
    t('Search and discuss millions of jobs'),
    t('Tailored job recommendations'),
    t('Comprehensive resume analysis')
  ]

  const renderChecklist = (items: TranslatedText[], numbered?: boolean) => (
    <>
      {items.map((item, index) => (
        <View key={index} flexDirection="row" alignItems="center" paddingTop="m">
          <CheckIcon $numbered={!!numbered}>
            {numbered ? (
              <Text rawText={`${index + 1}` as TranslatedText} fontSize={16} />
            ) : (
              <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={15} height={15}>
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={32}
                  d="M416 128L192 384l-96-96"
                />
              </Svg>
            )}
          </CheckIcon>
          <Text rawText={item} fontSize={16} />
        </View>
      ))}
    </>
  )

  const exportInfo =
    liExport[locale] ?? (liExport[defaultLocale] as NonNullable<typeof liExport[keyof typeof liExport]>)

  return (
    <View alignItems="center" paddingTop="l" paddingX="s" marginX="auto" width="100%" maxWidth={500}>
      <View flexDirection="row">
        <SystemIcon>
          <Logo />
        </SystemIcon>
        <SyncIcon>
          <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M434.67 285.59v-29.8c0-98.73-80.24-178.79-179.2-178.79a179 179 0 00-140.14 67.36m-38.53 82v29.8C76.8 355 157 435 256 435a180.45 180.45 0 00140-66.92"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={32}
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={32}
              d="M32 256l44-44 46 44M480 256l-44 44-46-44"
            />
          </Svg>
        </SyncIcon>
        <SystemIcon>
          <OpenAiLogo />
        </SystemIcon>
      </View>
      <H1 text="Allow ChatGPT to access your CV" fontSize={22} marginTop="l" textAlign="center" />
      <Cards>
        <Card>
          <H2 text="What we do" />
          {renderChecklist(whatWeDo)}
          <H2 text="Features added to ChatGPT" marginTop="l" />
          {renderChecklist(features)}
          <View height={32} />
          <ResumeImportField buttonText="Analyze CV" onChange={onResumeImportChange} />
          {!localesWithoutLIExport.includes(locale) && (
            <Button
              action="OpenImportFromLinkedIn"
              text="Import from LinkedIn"
              onPress={() => setShowImportFromLinkedIn(true)}
              width="100%"
              borderRadius={5}
              marginTop="s"
              large
            />
          )}
          <Link
            text="I don't have a CV or LinkedIn profile"
            onPress={e => {
              e.preventDefault()
              authorizeClient()
            }}
            href="#"
            marginX="auto"
            marginTop="m"
          />
          <SignUpNoteWrap>
            <SignUpNote style={{ fontSize: 11 }} />
          </SignUpNoteWrap>
        </Card>
        {showImportFromLinkedIn !== null && (
          <SecondaryCard $visible={showImportFromLinkedIn}>
            <Header>
              <H2 text="Import from LinkedIn" fontSize={22} />
              <Button
                action="CloseImportFromLinkedIn"
                icon="close-outline"
                size={30}
                onPress={() => setShowImportFromLinkedIn(false)}
              />
            </Header>
            {exportInfo.gif && (
              <img
                src={`https://assets.remoteambition.com/help/export-li/${exportInfo.gif}.gif`}
                alt=""
                width="100%"
                style={{
                  borderRadius: 12,
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.8)',
                  marginBottom: 4 + 8,
                  minHeight: 217
                }}
              />
            )}
            {renderChecklist(
              [
                t('Visit your LinkedIn profile'),
                t('Click {{button1}}, then click {{button2}}', {
                  button1: exportInfo.buttons[0] as TranslatedText,
                  button2: exportInfo.buttons[1] as TranslatedText
                }),
                t('Upload your PDF here to be analyzed by ChatGPT')
              ],
              true
            )}
            <View height={24} />
            <ResumeImportField buttonText="Upload PDF from LinkedIn" onChange={onResumeImportChange} />
            <SignUpNoteWrap>
              <SignUpNote style={{ fontSize: 11 }} />
            </SignUpNoteWrap>
          </SecondaryCard>
        )}
        {showProgress && (
          <SecondaryCard $visible top={0}>
            <View flex={1} justifyContent="center" alignItems="center">
              <View position="relative" flexDirection="column" justifyContent="stretch" alignItems="center">
                {resumeUploadState === 'Uploading' && (
                  <ProgressMessage>
                    <Loader size={60} />
                    <H2 text="Uploading..." marginY="m" fontSize={22} />
                  </ProgressMessage>
                )}
                {resumeUploadState === 'Uploaded' && (
                  <ProgressMessage>
                    <Icon size={60} icon="checkmark" color="#00c853" />
                    <H2 text="Uploaded" marginY="m" fontSize={22} />
                  </ProgressMessage>
                )}
                {(authCodeState === 'Pending' || authCodeState === 'Success') && (
                  <ProgressMessage>
                    <Loader size={60} />
                    <H2 text="Connecting..." marginY="m" fontSize={22} />
                  </ProgressMessage>
                )}
                {authCodeState === 'Error' && (
                  <ProgressMessage>
                    <Icon size={60} icon="alert-circle-outline" color="#ff656d" />
                    <H2 text="An error has occurred" marginY="m" fontSize={22} />
                  </ProgressMessage>
                )}
              </View>
            </View>
          </SecondaryCard>
        )}
      </Cards>
      <Footer>
        <FooterItem>
          <FooterIcon>
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </Svg>
          </FooterIcon>
          <FooterText text="No Spam" />
        </FooterItem>
        <FooterItem>
          <FooterIcon>
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
              />
            </Svg>
          </FooterIcon>
          <FooterText text="Privacy Focused" />
        </FooterItem>
        <FooterItem>
          <FooterIcon>
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </Svg>
          </FooterIcon>
          <FooterText text="Secure" />
        </FooterItem>
      </Footer>
    </View>
  )
}
