import { CompensationFrequency, DatePosted, ThemeName, themeNames } from '../models'
import { SupportedLocaleCode, supportedLocales } from './i18n'
import { JobScope } from './query'

export interface ISavedState {
  isAuthenticated: boolean
  locale?: SupportedLocaleCode
  themeName?: ThemeName
  jobScope?: JobScope
  preferRemoteJobs?: boolean
  datePosted?: DatePosted
  distanceRadiusFilter?: number
  compensationFrequencyFilter?: CompensationFrequency
  compensationFilter?: number
}

// storage can be tampered so dirty/untrusted data in, clean/validated data out
export const parseSavedState = (state: { [key in keyof ISavedState]: unknown }): ISavedState => {
  const parsed: ISavedState = {
    // cookie values are always strings, while storage values are booleans
    isAuthenticated: state.isAuthenticated === 'true' || state.isAuthenticated === true
  }

  if (state.locale && supportedLocales.some(locale => locale.code === state.locale)) {
    parsed.locale = state.locale as SupportedLocaleCode
  }

  if (state.themeName && themeNames.includes(state.themeName as ThemeName)) {
    parsed.themeName = state.themeName as ThemeName
  }

  if (state.jobScope) {
    parsed.jobScope = state.jobScope as JobScope
  }

  if (state.preferRemoteJobs) {
    parsed.preferRemoteJobs = state.preferRemoteJobs as boolean
  }

  if (state.datePosted) {
    parsed.datePosted = state.datePosted as DatePosted
  }

  if (state.distanceRadiusFilter) {
    parsed.distanceRadiusFilter = state.distanceRadiusFilter as number
  }

  if (state.compensationFrequencyFilter) {
    parsed.compensationFrequencyFilter = state.compensationFrequencyFilter as CompensationFrequency
  }

  if (state.compensationFilter) {
    parsed.compensationFilter = state.compensationFilter as number
  }

  return parsed
}
