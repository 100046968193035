import { TranslatedText } from 'core'
import React from 'react'
import { ActionMeta, components, ControlProps, SingleValue } from 'react-select'

import { GroupedSelectOption, Select } from '~/components'
import { useDebounce, useNearbyLocations, useUpdateJobScope, useUserLocations, useValidateSearchRoute } from '~/hooks'
import { Combobox, Icon, Loader, useStore, useTranslation, View } from '~/lite'
import { searchPlaces } from '~/util'

import { ILocationOption } from '../LocationInput'
import { SelectCityProps } from './types'

export type CityOption = {
  label: ILocationOption['name']
  value: ILocationOption['placeId'] | null
  googleData: google.maps.places.AutocompletePrediction | null
}

const Control = ({ children, ...props }: ControlProps<CityOption, false>) => {
  return (
    <components.Control {...props}>
      <Icon icon="location" size={18} marginLeft={16 - 2 - 5} />
      {children}
    </components.Control>
  )
}

const ClearIndicator = () => null

const DropdownIndicator = () => null

const LoadingIndicator = () => (
  <View marginRight="s">
    <Loader size="small" inline />
  </View>
)

const LoadingMessage = () => null

const NoOptionsMessage = () => null

export const SelectCity: React.FC<SelectCityProps> = ({ onChange, onFocus, onBlur, minimal, radix }) => {
  const t = useTranslation()
  const validateRoute = useValidateSearchRoute()
  const {
    jobScope,
    setJobScope,
    isUpdatingJobScope,
    setIsUpdatingJobScope,
    selectedLocation,
    resetJobScope,
    hydrated
  } = useStore(store => ({
    jobScope: store.jobScope,
    setJobScope: store.setJobScope,
    isUpdatingJobScope: store.isUpdatingJobScope,
    setIsUpdatingJobScope: store.setIsUpdatingJobScope,
    selectedLocation: store.selectedLocation,
    resetJobScope: store.resetJobScope,
    hydrated: store.hydrated
  }))
  const [sessionToken, setSessionToken] = React.useState<google.maps.places.AutocompleteSessionToken | null>(null)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [activated, setActivated] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState<CityOption | null>(null)
  // const [localSearchLocations, setLocalSearchLocations] = React.useState<ILocationSearchResult[]>([])
  const [searchLocations, setSearchLocations] = React.useState<Awaited<ReturnType<typeof searchPlaces>>['results']>([])
  const debouncedQuery = useDebounce(searchQuery, 100)
  const [isLoading, setIsLoading] = React.useState(false)
  const { mutate: updateJobScope } = useUpdateJobScope({
    onSuccess: response => {
      setIsUpdatingJobScope(false)
    }
  })

  // const { data: searchLocations, isLoading } = useSearchLocations({
  //   limit: '10',
  //   page: '0',
  //   search: debouncedQuery
  // })

  // React.useEffect(() => {
  //   if (!searchLocations) {
  //     return
  //   }
  //   const localLocationMap = keyBy(localSearchLocations, 'locationId')
  //   const mergedLocations: [ILocationSearchResult[], ILocationSearchResult[]] = [[], []]
  //   for (const location of searchLocations) {
  //     if (location.locationId) {
  //       if (localLocationMap[location.locationId]) {
  //         mergedLocations[0].push(location)
  //       } else {
  //         mergedLocations[1].push(location)
  //       }
  //     }
  //   }
  //   startTransition(() => {
  //     setLocalSearchLocations(flatten(mergedLocations))
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setLocalSearchLocations, searchLocations])

  React.useEffect(() => {
    if (!debouncedQuery) {
      return
    }
    setIsLoading(true)
    searchPlaces(sessionToken, debouncedQuery, { types: ['(regions)'] }).then(({ sessionToken, results }) => {
      setIsLoading(false)
      setSessionToken(sessionToken)
      setSearchLocations(results)
    })
  }, [debouncedQuery, sessionToken])

  const { data: userLocationData, isLoading: isUserLocationLoading } = useUserLocations({ limit: '10' }, activated)

  const { data: nearbyLocationData, isLoading: isNearbyLocationLoading } = useNearbyLocations(
    { locationId: jobScope?.split('|')[0], limit: '10' },
    isUpdatingJobScope,
    activated
  )

  React.useEffect(() => {
    if (hydrated) {
      if (selectedLocation) {
        setSelectedOption({
          label: selectedLocation.locationName,
          value: selectedLocation.locationId,
          googleData: null
        })
      }
    }
  }, [hydrated, selectedLocation])

  // const cityOptions = React.useMemo(() => {
  //   const searchQueryRe = new RegExp(`^.*${escapeRegExp(debouncedQuery.replaceAll(',', ''))}.*$`, 'i')
  //   return localSearchLocations
  //     .filter(
  //       c =>
  //         searchQueryRe.test(c.name.replaceAll(',', '')) ||
  //         (c.alternativeName && searchQueryRe.test(c.alternativeName.replaceAll(',', '')))
  //     )
  //     .map(result => {
  //       return { label: result.name, value: result.locationId }
  //     })
  // }, [localSearchLocations, debouncedQuery])

  const cityOptions = React.useMemo<CityOption[]>(() => {
    return searchLocations.map(result => {
      return { label: result.name, value: `gmp-${result.placeId}`, googleData: result.prediction }
    })
  }, [searchLocations])

  const nearbyCityOptions = React.useMemo<CityOption[]>(() => {
    if (!nearbyLocationData) {
      return []
    }
    return nearbyLocationData
      .filter(location => location.id)
      .map(result => {
        const title = (result.city as TranslatedText) || result.name
        return { label: title, value: result.id, googleData: null }
      })
  }, [nearbyLocationData])

  const userLocationOptions = React.useMemo<CityOption[]>(() => {
    if (!userLocationData) {
      return []
    }
    return userLocationData
      .filter(location => location.id)
      .map(result => {
        return { label: result.name, value: result.id, googleData: null }
      })
  }, [userLocationData])

  const groupedOptions: GroupedSelectOption<CityOption>[] | CityOption[] = React.useMemo(() => {
    if (searchQuery) {
      return cityOptions
    } else {
      return [
        {
          label: t('Nearby locations'),
          options: !searchQuery ? nearbyCityOptions : []
        },
        {
          label: t('Suggested locations'),
          options: !searchQuery ? userLocationOptions : []
        }
      ]
    }
  }, [cityOptions, nearbyCityOptions, userLocationOptions, searchQuery, t])

  const handleOnInputChange = (newValue: string) => {
    setSearchQuery(newValue)
  }

  const handleOnChange = (option: SingleValue<CityOption>, actionMeta?: ActionMeta<CityOption>) => {
    if (actionMeta?.action === 'clear') {
      updateJobScope({ oldScope: jobScope, newScope: '' })
      resetJobScope()
      return
    }
    if (!option?.value) {
      return
    }
    const newScope = `${option.value}|${option.label}`
    updateJobScope({ oldScope: jobScope, newScope })
    setIsUpdatingJobScope(true)
    setJobScope({
      scope: newScope,
      fromSearchbar: true
    })
    onChange?.(option.value)
    validateRoute('locationId', option.value)
  }

  if (radix) {
    return (
      <Combobox<CityOption>
        placeholder={t('Anywhere')}
        value={selectedOption}
        options={groupedOptions}
        inputValue={searchQuery}
        onToggle={() => setActivated(true)}
        onInputChange={handleOnInputChange}
        onChange={handleOnChange}
        shouldFilter={false}
        popoverProps={{ style: { width: 300 } }}
        isLoading={isLoading}
        minimal
      />
    )
  }

  return (
    <Select
      components={{ ClearIndicator, DropdownIndicator, LoadingIndicator, ...(minimal ? {} : { Control }) }}
      placeholder={t('Anywhere')}
      value={selectedOption}
      options={groupedOptions}
      styles={{
        indicatorSeparator: () => ({ display: 'none' }),
        valueContainer: () => ({ paddingLeft: 12 - 5 - 2 }),
        ...(minimal ? { control: () => ({ borderWidth: 0 }) } : {})
      }}
      filterOption={(options: any) => {
        // disable local filtering
        return options
      }}
      loadingMessage={LoadingMessage}
      noOptionsMessage={NoOptionsMessage}
      menuPlacement="auto"
      isLoading={
        (isLoading && !!searchQuery) ||
        (!searchQuery && (isUserLocationLoading || isNearbyLocationLoading)) ||
        isUpdatingJobScope
      }
      onInputChange={handleOnInputChange}
      onChange={handleOnChange}
      onFocus={onFocus}
      onBlur={onBlur}
      isDisabled={isUpdatingJobScope}
    />
  )
}
