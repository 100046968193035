import { markTranslated, TranslatedText, UntranslatedText } from 'core'
import React from 'react'

import { styled } from '~/lite'
import { isWeb } from '~/util'

import { Text } from '../Text/Text'
import { ErrorText, MutedText } from '../Text/variants'
import { View, ViewProps } from '../View/View'

const Qualifier = styled(Text)`
  opacity: 0.5;
  margin: 0 4px;
  white-space: nowrap;
`

export interface IFormGroupProps {
  label?: UntranslatedText
  rawLabel?: TranslatedText
  labelInfo?: TranslatedText
  helperText?: string | number
  helperTextPosition?: 'Top'
  labelFor?: string
  helperTestID?: string
  isRequired?: boolean
  style?: ViewProps['style']
  children?: React.ReactNode
}

/*
@todo props not implemented
labelFor
*/

export const FormGroup: React.FC<IFormGroupProps> = ({
  label,
  rawLabel,
  labelInfo,
  helperText,
  helperTestID,
  helperTextPosition,
  isRequired,
  children,
  style
}) => {
  const helperTextEl = helperText && (
    <ErrorText
      testID={helperTestID}
      marginTop="xs"
      marginLeft={helperTextPosition === 'Top' ? 'xs' : 's'}
      fontSize={12}
      rawText={markTranslated(helperText)}
    />
  )

  return (
    <View margin="none" marginBottom="m" width="100%" style={style}>
      <View display="flex" flexDirection="row" alignItems="center" marginBottom="xs" width={isWeb ? '100%' : '80%'}>
        {label ? <Text marginX="xs" text={label} /> : undefined}
        {rawLabel ? <Text marginX="xs" rawText={rawLabel} /> : undefined}
        {labelInfo ? <MutedText rawText={labelInfo} /> : undefined}
        {typeof isRequired === 'boolean' && (
          <>
            <View flex={1} />
            <Qualifier text={isRequired ? 'required' : 'optional'} />
          </>
        )}
      </View>
      {helperTextPosition === 'Top' && helperTextEl}
      {children}
      {helperTextPosition !== 'Top' && helperTextEl}
    </View>
  )
}
