import { IAffiliateContent, IBusinessContent } from 'core'
import React from 'react'

import { breakpoint, styled, Text, useStore, View } from '~/lite'

import { backgrounds, CardTitle, Section, SectionContent, SectionTitle } from './styles'

type Variant = 'Card'

const Steps = styled(View)<{ $variant?: Variant }>`
  flex-direction: column;
  gap: 8px;

  @media ${breakpoint.md} {
    flex-direction: ${props => (props.$variant === 'Card' ? 'column' : 'row')};
    margin-top: ${props => (props.$variant === 'Card' ? 0 : '32px')};
  }
`

const Step = styled(View)<{ $variant?: Variant }>`
  flex: 1;
  flex-direction: column;
  justify-content: start;
  padding: 16px;
  margin: 24px 8px 0;
  border-radius: 12px;
  border-width: 1px;
  background: ${props => props.theme.cardBackground};

  @media ${breakpoint.md} {
    margin: ${props => (props.$variant === 'Card' ? '24px 8px 0' : '0')};
  }
`

const Number = styled(Text)`
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  background: ${props => props.theme.primary};
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 50px;
  width: 44px;
  height: 44px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
`

const Header = styled(Text)<{ $variant?: Variant }>`
  font-weight: 500;
  font-size: 22px;
  margin-top: ${props => (props.$variant === 'Card' ? 0 : '24px')};
  margin-bottom: 16px;
  text-align: center;
`

const Body = styled(Text)`
  position: relative;
  font-size: 16px;
  hyphens: none;
  text-wrap: balance;
  text-align: center;
  padding-top: 16px;

  &:before {
    position: absolute;
    top: 0;
    right: 25%;
    left: 25%;
    display: block;
    background: linear-gradient(90deg, #5c708000 0%, #5c7080 50%, #5c708000 100%);
    border-radius: 50px;
    height: 1px;
    content: '';
  }
`

interface IHowItWorksProps {
  data: IAffiliateContent['howItWorks'] | IBusinessContent['howItWorks']
  id?: string
  variant?: Variant
}

export const HowItWorks: React.FC<IHowItWorksProps> = ({ data, id, variant }) => {
  const darkMode = useStore(state => state.darkMode)

  return (
    <Section id={id ?? 'how-it-works'} style={darkMode ? { background: backgrounds.orange } : undefined}>
      <SectionContent
        $allowWide={data.steps.length > 3}
        style={variant === 'Card' ? { padding: 8, paddingBottom: 16 } : {}}
      >
        {variant === 'Card' ? <CardTitle rawText={data.header} /> : <SectionTitle rawText={data.header} />}
        <Steps $variant={variant}>
          {data.steps.map(({ header, body }, index) => (
            <Step key={index} $variant={variant}>
              <Number text={index + 1} />
              <Header rawText={header} />
              <Body rawText={body} />
            </Step>
          ))}
        </Steps>
      </SectionContent>
    </Section>
  )
}
