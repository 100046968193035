import React from 'react'

import { H2, Loader, useStore, View } from '~/lite'

import { MagicLinkCard } from './styles'
import { IResumeAnalysisProps } from './types'

export const ResumeAnalysis: React.FC<IResumeAnalysisProps> = ({ visible, progress }) => {
  const user = useStore(state => state.user)

  return (
    <MagicLinkCard
      $visible={visible}
      $enableAnimation={true}
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="m"
      textAlign="center"
    >
      <View position="relative" width="100%" flexDirection="column" justifyContent="center">
        <H2 marginBottom="m" fontSize={28} fontWeight={600} text="Analyzing" />
        <Loader size={user?.email ? 160 : 240} progress={progress} />
      </View>
    </MagicLinkCard>
  )
}
