import { Community } from '@ambition/module-shared'
import React, { useEffect } from 'react'

import { usePlusContent, useUserSubscriptionBenefit } from '~/hooks'
import { AnnouncementCard, Loader, MetaTags } from '~/lite'

import { Benefits } from '../Benefits'
import { Faq } from '../Faq'
import { Pricing } from '../Pricing'
import { ContentWrap } from '../styles'
import { Hero } from './Hero'

export const Plus: React.FC = () => {
  const { data, isLoading } = usePlusContent()
  const { data: ambitionPlusSubscription, isLoading: isLoadingSubscription } =
    useUserSubscriptionBenefit('ambition-plus')

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoading || isLoadingSubscription) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { metaTags, announcement, hero, benefits, pricing, faq } = data.data

  return (
    <ContentWrap>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/plus" />
      {announcement && <AnnouncementCard announcement={announcement} />}
      <Hero data={hero} />
      <Pricing
        data={pricing}
        currentProductId={ambitionPlusSubscription?.metadata.stripeProductId || null}
        hideTitle
        hideFreePlan
      />
      <Community jobSeeker />
      <Benefits data={benefits} />
      <Faq data={faq} />
    </ContentWrap>
  )
}
