import type { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs'
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import type { StackNavigationOptions } from '@react-navigation/stack'
import type { CollectionSlug, Domain, TranslatedText, UntranslatedText } from 'core'
import React, { useMemo } from 'react'
import type { RouteObject } from 'react-router-dom'

import {
  Container,
  HeaderButton,
  isIos,
  isWeb,
  pageTitles,
  useBreakpoints,
  useNotificationStore,
  useStore,
  useTranslation
} from '~/lite'

import { HeaderLogo } from '../components/HeaderLogo'
import { HeaderMenu } from '../components/HeaderMenu'
import { Lazy } from '../components/Lazy'
import { Screen } from '../components/Screen'
import { StackName } from '../types'

export interface IStack {
  screens: IScreen[]
}

export interface IScreen {
  name: string
  path?: string
  index?: true
  screen: React.ComponentType<any> | StackName
  loader?: RouteObject['loader']
  title?: UntranslatedText | (() => TranslatedText)
  initialParams?: any
  tabOptions?: BottomTabNavigationOptions
  options?:
    | (NativeStackNavigationOptions & StackNavigationOptions)
    | ((props: { route: any; navigation: any }) => NativeStackNavigationOptions & StackNavigationOptions) // { route: RouteProp<ParamList, RouteName> }
  getId?: ({ params }: { params: any }) => string | undefined // { params: ParamList[RouteName] }
}

export const useScreens = (domain?: Domain, subdomain?: string) => {
  const user = useStore(state => state.user)
  const currentTeam = useStore(state => state.currentTeam)
  const themeName = useStore(state => state.themeName)
  const { notificationBadgeCount } = useNotificationStore()
  const { isPhone } = useBreakpoints()
  const t = useTranslation()

  const formOptions = isWeb
    ? { presentation: 'transparentModal' as any, headerShown: false }
    : { presentation: 'formSheet' as any, headerShown: false }

  const modalOptions = isWeb
    ? { presentation: 'transparentModal' as any, headerShown: isPhone }
    : { presentation: 'modal' as any }

  const screenOptions = {
    headerTitleAlign: 'left',
    headerTitle: () => <HeaderLogo />,
    headerRight: () => <HeaderMenu />
  } as any

  const teamScreenOptions = {
    headerTitleAlign: 'left',
    headerTitle: t('Teams'),
    headerRight: () => <HeaderButton action="NewTeam" to="/teams/new" large icon="add-sharp" />
  } as any

  const tabBarBadge = useMemo(
    () => (notificationBadgeCount === 0 ? undefined : notificationBadgeCount > 9 ? '9+' : notificationBadgeCount),
    [notificationBadgeCount]
  )

  const temporarilyHiddenTabOptions: BottomTabNavigationOptions = { tabBarItemStyle: { display: 'none' } }

  const teamlessTabOptions: BottomTabNavigationOptions = !currentTeam ? {} : { tabBarItemStyle: { display: 'none' } }

  const userTabOptions: BottomTabNavigationOptions =
    user && !currentTeam ? {} : { tabBarItemStyle: { display: 'none' } }

  const teamTabOptions: BottomTabNavigationOptions = user && currentTeam ? {} : { tabBarItemStyle: { display: 'none' } }

  const isInstantSdk = domain === 'instantsdk.com'
  const isInstantSdkDirectory = isInstantSdk && (!subdomain || subdomain === 'www')
  const isInstantSdkProfile = isInstantSdk && !isInstantSdkDirectory

  const isWeebin = domain === 'weebin.com'
  const isWeebinDirectory = isWeebin && (!subdomain || subdomain === 'www')
  const isWeebinProfile = isWeebin && !isWeebinDirectory

  const launchpadDirectoryScreens: IScreen[] = isWeebinProfile
    ? []
    : [
        {
          name: 'LaunchpadLocalized',
          path: isWeebin ? ':locale' : ':locale/launchpad',
          screen: Screen.Launchpad,
          title: 'Launchpad',
          options: modalOptions
        },
        {
          name: 'Launchpad',
          ...(isWeebin ? { path: '', index: true } : { path: 'launchpad' }),
          screen: Screen.Launchpad,
          title: 'Launchpad',
          options: modalOptions
        }
      ]

  const launchpadScreens: IScreen[] = [
    {
      name: 'LaunchpadProfile',
      ...(isWeebinProfile ? { path: '', index: true } : { path: 'u/:id' }),
      screen: isWeebinProfile
        ? () => <Screen.LaunchpadProfileViaSubdomain {...({ username: subdomain } as any)} />
        : Screen.LaunchpadProfile,
      title: 'Launchpad',
      options: modalOptions
    },
    ...launchpadDirectoryScreens,
    {
      name: 'LaunchpadDashboard',
      path: isWeebinProfile ? 'dashboard' : 'launchpad/:marketProfileId/:view?',
      screen: Screen.LaunchpadDashboard,
      title: 'Launchpad',
      options: modalOptions
    }
  ]

  const adjustStacks = (stacks: Record<string, IStack>) => {
    Object.values(stacks).forEach(stack => {
      stack.screens.forEach(screen => {
        if (screen.title) {
          const title =
            typeof screen.title === 'string' || typeof screen.title === 'number' ? t(screen.title) : screen.title()
          screen.options = { ...screen.options, title }
          screen.tabOptions = { ...screen.tabOptions, title }
        }
      })
    })
  }

  if (isInstantSdk) {
    const instantSdkDirectoryScreens: IScreen[] = isInstantSdkProfile
      ? []
      : [
          {
            name: 'InstantSdk',
            path: '',
            index: true,
            screen: Screen.InstantSdk,
            title: 'InstantSDK' as any,
            options: modalOptions
          }
        ]

    const instantSdkScreens: IScreen[] = [
      {
        name: 'InstantSdkProfile',
        ...(isInstantSdkProfile ? { path: '', index: true } : { path: 'sdk/:id' }),
        screen: isInstantSdkProfile
          ? () => <Screen.InstantSdkProfileViaSubdomain {...({ username: subdomain } as any)} />
          : Screen.InstantSdkProfile,
        title: 'InstantSDK' as any,
        options: modalOptions
      },
      ...instantSdkDirectoryScreens,
      {
        name: 'InstantSdkDashboard',
        path: isInstantSdkProfile ? 'dashboard' : 'dashboard/:instantSdkProfileId/:view?',
        screen: Screen.InstantSdkDashboard,
        title: 'InstantSDK' as any,
        options: modalOptions
      }
    ]

    const stacks: Record<string, IStack> = {
      Root: { screens: instantSdkScreens },
      MainTabs: { screens: [] }
    }

    adjustStacks(stacks)

    return stacks
  }

  const mainStacks: Record<StackName, IStack> = {
    Root: {
      screens: [
        { name: 'MainTabs', path: '*', screen: 'MainTabs', options: { headerShown: false } },
        { name: 'AuthStack', screen: 'Auth', options: formOptions },
        { name: 'Contact', path: 'contact', screen: Screen.Contact, title: 'Contact', options: modalOptions },
        {
          name: 'UserAccount',
          path: 'user/account',
          screen: Screen.UserAccount,
          title: 'Account',
          options: modalOptions
        },
        {
          name: 'Privacy',
          path: 'privacy',
          screen: Screen.Privacy,
          title: 'Privacy',
          options: modalOptions
        },
        {
          name: 'Terms',
          path: 'terms',
          screen: Screen.Terms,
          title: 'Terms',
          options: modalOptions
        },
        {
          name: 'SitemapRoot',
          path: 'sitemap',
          screen: Screen.Sitemap,
          title: 'Sitemap'
        },
        {
          name: 'SitemapPage',
          path: 'sitemap/:section/:page?',
          screen: Screen.Sitemap,
          title: 'Sitemap'
        },
        {
          name: 'LocaleSettings',
          path: 'select-language',
          screen: Screen.LocaleSettings,
          title: 'Select Language',
          options: modalOptions
        },
        {
          name: 'Analyze',
          path: 'analyze',
          screen: Screen.AnalyzeResume,
          title: 'Analyze CV',
          options: modalOptions
        },
        {
          name: 'AnalyzeLocalized',
          path: ':locale/analyze',
          screen: Screen.AnalyzeResume,
          title: 'Analyze CV',
          options: modalOptions
        },
        {
          name: 'Autopilot',
          path: 'autopilot',
          screen: Screen.Autopilot,
          title: 'Autopilot',
          options: modalOptions
        },
        {
          name: 'AutopilotLocalized',
          path: ':locale/autopilot',
          screen: Screen.Autopilot,
          title: 'Autopilot',
          options: modalOptions
        },
        {
          name: 'Api',
          path: 'api',
          screen: Screen.Api,
          title: 'Jobs API',
          options: modalOptions
        },
        {
          name: 'ApiLocalized',
          path: ':locale/api',
          screen: Screen.Api,
          title: 'Jobs API',
          options: modalOptions
        },
        {
          name: 'Pipeline',
          path: 'pipeline',
          screen: Screen.Pipeline,
          title: 'Talent Pipeline',
          options: modalOptions
        },
        {
          name: 'PipelineLocalized',
          path: ':locale/pipeline',
          screen: Screen.Pipeline,
          title: 'Talent Pipeline',
          options: modalOptions
        },
        ...(domain === 'weebin.com' ? [] : launchpadScreens),
        { name: 'NewTeam', path: 'teams/new', screen: Screen.NewTeam, title: 'New Team', options: modalOptions },
        { name: 'UserTeams', path: 'user/teams', screen: Screen.UserTeams, title: 'Teams', options: teamScreenOptions },
        { name: 'Team', path: 'teams/:view?', screen: Screen.Team, title: 'Team', options: screenOptions },
        {
          name: 'EditTeam',
          path: 'teams/edit',
          screen: Screen.EditTeam,
          title: 'Edit Team',
          options: { presentation: 'modal' }
        },
        {
          name: 'TeamSettings',
          path: 'teams/settings',
          screen: Screen.TeamSettings,
          title: 'Team Settings',
          options: screenOptions
        },
        // @todo drop soon, here for backwards compatibility for any visitors having this in their history
        {
          name: 'JobDashboard',
          path: 'dashboard',
          screen: Screen.JobDashboardInterstitial,
          title: 'Dashboard',
          options: screenOptions
        },
        {
          name: 'JobDashboard',
          path: 'jobs',
          screen: Screen.JobDashboardInterstitial,
          title: 'Dashboard',
          options: screenOptions
        },
        {
          name: 'TeamDashboard',
          path: 'dashboard/teams/:teamId',
          screen: Screen.TeamDashboard,
          title: 'Dashboard',
          options: screenOptions
        },
        {
          name: 'TeamDashboard',
          path: 'dashboard/teams/:teamId/jobs/:positionId',
          screen: Screen.TeamDashboard,
          title: 'Dashboard',
          options: screenOptions
        },
        {
          name: 'TeamDashboard',
          path: 'dashboard/teams/:teamId/jobs/:positionId/applications/:screenerResumeId',
          screen: Screen.TeamDashboard,
          title: 'Dashboard',
          options: screenOptions
        },
        {
          name: 'TeamStatuses',
          path: 'teams/statuses',
          screen: Screen.TeamStatuses,
          title: 'Application Statuses',
          options: { presentation: 'modal', headerTitleStyle: { fontSize: 15 } }
        },
        {
          name: 'PositionPreview',
          screen: Screen.PositionPreview,
          title: 'Position',
          options: { header: undefined, headerShown: isWeb, presentation: 'modal' }
        },
        // {
        //   name: 'NewPosition',
        //   path: 'positions/new',
        //   screen: Screen.NewPosition,
        //   title: 'Post a Job',
        //   options: { headerShown: false }
        // },
        {
          name: 'EditPosition',
          path: 'positions/:positionId/edit',
          screen: Screen.EditPosition,
          title: 'Edit Position',
          options: { headerShown: false }
        },
        {
          name: 'PromotePosition',
          path: 'positions/:positionId/promote/:productId?',
          screen: Screen.PromotePosition,
          title: 'Promote Position'
        },
        {
          name: 'SelectLocation',
          path: 'positions/select-location',
          screen: Screen.SelectPositionLocation,
          title: 'Select location'
        },
        {
          name: 'Affiliate',
          path: 'affiliate',
          screen: Screen.Affiliate,
          title: 'Affiliate',
          options: modalOptions
        },
        {
          name: 'AffiliateLocalized',
          path: ':locale/affiliate',
          screen: Screen.Affiliate,
          title: 'Affiliate',
          options: modalOptions
        },
        {
          name: 'Interview',
          path: 'jobs/:positionId/interview',
          screen: Screen.Interview,
          title: 'Interview',
          options: modalOptions
        },
        {
          name: 'Business',
          path: 'business',
          screen: Screen.Business,
          options: modalOptions
        },
        {
          name: 'Plus',
          path: 'plus',
          screen: Screen.Plus,
          options: modalOptions
        },
        {
          name: 'PlusLocalized',
          path: ':locale/plus',
          screen: Screen.Plus,
          options: modalOptions
        },
        {
          name: 'Promote',
          path: 'promote',
          screen: Screen.Promote,
          options: modalOptions
        },
        {
          name: 'PromoteLocalized',
          path: ':locale/promote',
          screen: Screen.Promote,
          options: modalOptions
        },
        {
          name: 'Screener',
          path: 'screener',
          screen: Screen.ScreenerWebsite,
          options: modalOptions
        },
        {
          name: 'ScreenerLocalized',
          path: ':locale/screener',
          screen: Screen.ScreenerWebsite,
          options: modalOptions
        },
        // { name: 'teamChatView', path: 'teams/chat', screen: TeamChatScreen, title: 'Team messages', options: { headerTitleAlign: 'center' } },
        { name: 'teamMembers', path: 'teams/members', screen: Screen.TeamMembers, title: 'Members' },
        { name: 'webView', screen: Screen.WebView, options: { presentation: 'modal' } },
        { name: 'ShareApp', path: 'share-app', screen: Screen.ShareApp, title: 'Sharing App - Please wait...' },
        { name: 'DownloadApp', path: 'download-app', screen: Screen.ShareApp, title: 'Download' },
        {
          name: 'DeleteAccount',
          path: 'user/delete',
          screen: Screen.DeleteAccount,
          title: 'Delete Account',
          options: { presentation: 'modal' }
        },
        { name: 'OAuth', screen: Screen.OAuth, options: { presentation: 'modal' } }
      ]
    },
    MainTabs: {
      screens: [
        ...(domain === 'weebin.com'
          ? launchpadScreens
          : [
              {
                name: 'ExploreStack',
                path: '',
                screen: 'Explore',
                title: 'Explore',
                tabOptions: teamlessTabOptions
              } as IScreen
            ]),
        {
          name: 'Studio',
          path: 'studio',
          screen: Screen.Studio,
          title: 'Studio',
          tabOptions: teamlessTabOptions
        },
        {
          name: 'StudioLocalized',
          path: ':locale/studio',
          screen: Screen.Studio,
          title: 'Studio',
          tabOptions: { tabBarItemStyle: { display: 'none' } }
        },
        { name: 'ShortStack', path: 'shorts', screen: 'Shorts', title: 'Shorts', tabOptions: teamlessTabOptions },
        {
          name: 'MessagesStack',
          path: 'messages',
          screen: 'Messages',
          title: 'Messages',
          tabOptions: { ...userTabOptions, ...temporarilyHiddenTabOptions, tabBarTestID: 'messages-tab-button' }
        },
        {
          name: 'ProfileStack',
          path: 'user/profile/:username?',
          screen: 'Profile',
          title: 'Profile',
          tabOptions: { ...userTabOptions, tabBarTestID: 'profile-tab' }
        },
        {
          name: 'NotificationStack',
          path: 'notifications',
          screen: 'Notifications',
          title: 'Notifications',
          tabOptions: {
            ...userTabOptions,
            ...temporarilyHiddenTabOptions,
            tabBarTestID: 'notifications-tab',
            tabBarBadge,
            tabBarBadgeStyle: {
              minWidth: 26,
              ...(!isPhone && (notificationBadgeCount > 9 ? { left: 0, top: -20 } : { left: -8 }))
            }
          }
        },
        {
          name: 'TeamPositions',
          screen: 'TeamPositions',
          title: 'Positions',
          tabOptions: {
            ...teamTabOptions,
            tabBarTestID: 'messages-tab-button',
            headerShown: false,
            headerRight: () => <HeaderMenu />,
            headerRightContainerStyle: { paddingEnd: 17 },
            headerLeftContainerStyle: { paddingStart: 17 }
          }
        },
        {
          name: 'TeamMessagesStack',
          path: 'teams/messages',
          screen: 'TeamMessages',
          title: 'Messages',
          tabOptions: { ...teamTabOptions, tabBarTestID: 'messages-tab-button' }
        }
        // { name: 'TeamProfile', path: 'teams/profile', screen: 'TeamProfile', title: 'Profile', tabOptions: { tabBarTestID: 'profile-tab', headerShown: false } }
        // <Tabs.Screen name="TeamDashboardStack" component={Stack.TeamDashboard} options={{ title: t('Team Dashboard') }} />
      ]
    },
    Auth: {
      screens: [
        { name: 'Login', path: 'login/:secret?', screen: Screen.Login, title: 'Log In' },
        { name: 'SignUp', path: 'signup/:invitationId?', screen: Screen.SignUpContainer, title: 'Sign Up' },
        {
          name: 'ConfirmEmail',
          path: 'confirm/:secret',
          screen: Screen.ConfirmEmail,
          title: 'Confirmation'
        },
        {
          name: 'RecoverPassword',
          path: 'recover-password',
          screen: Screen.RecoverPassword,
          title: 'Recover Password'
        },
        {
          name: 'AuthForAI',
          path: 'ai/oauth',
          screen: Screen.AuthForAI,
          title: 'Log In'
        },
        {
          name: 'GitHub',
          path: 'github/:invitationId?',
          screen: Screen.GitHub,
          title: () => 'GitHub' as TranslatedText
        },
        { name: 'SelectCountry', path: 'select-country-code', screen: Screen.SelectCountry, title: 'Select region' }
      ]
    },
    Explore: {
      screens: [
        {
          name: 'Explore',
          index: true,
          screen: Screen.Explore,
          title: 'Explore',
          options: {
            headerShown: false,
            headerTitleAlign: 'left',
            headerTitle: HeaderLogo,
            headerRight: () => <HeaderMenu />
          }
        },
        {
          name: 'LandingPage',
          path: ':firstSlug/:secondSlug?',
          getId: ({ params }) => `${params.firstSlug}/${params.secondSlug}`,
          screen: Screen.Explore,
          title: 'Explore',
          options: {
            headerShown: false,
            headerTitleAlign: 'left',
            headerTitle: HeaderLogo,
            headerRight: () => <HeaderMenu />
          }
        },
        {
          name: 'ExplorePositions',
          path: 'positions/:type/:positionId?',
          screen: Screen.ExplorePositions,
          getId: ({ params }) => params.positionId,
          options: ({ route }) => {
            let title = pageTitles[route.path]
            if (route.params.positionId) {
              title = pageTitles['/positions/related']
            }
            return {
              title: title.toString()
            }
          }
        },
        {
          name: 'SubscribedCollections',
          path: 'subscriptions',
          screen: Screen.SubscribedCollections,
          title: 'Subscriptions'
        },
        {
          name: 'Collections',
          screen: Screen.Collections,
          title: 'Explore',
          options: {
            headerTitleAlign: 'left',
            headerTitle: HeaderLogo,
            headerRight: () => <HeaderMenu />,
            headerTransparent: true
          }
        },
        {
          name: 'SkillCollections',
          path: 'collections/skills',
          screen: Screen.SkillCollections,
          title: 'Skills'
        },
        // { name: 'TeamCollection', screen: Screen.Collection, title: 'Team' }
        {
          name: 'Collection',
          path: 'collections/:collectionSlug',
          screen: Screen.Collection,
          title: 'Collection',
          getId: ({ params }) => params.collectionSlug
        },
        {
          name: 'CollectionPosition',
          path: 'collections/:collectionSlug/jobs/:positionId?',
          screen: Screen.Collection,
          title: 'Collection',
          getId: ({ params }) => params.collectionSlug
        },
        { name: 'Locations', path: 'locations', screen: Screen.Locations, title: 'Top Locations' },
        {
          name: 'CompanyDetail',
          path: 'teams/:username/jobs',
          screen: Screen.CompanyDetail,
          title: 'Team',
          getId: ({ params }) => params.username
        },
        {
          name: 'JobPosition',
          path: 'jobs/:positionId',
          screen: Screen.JobDetail,
          title: 'Position',
          getId: ({ params }) => params.positionId,
          options: { headerShown: false }
        },
        {
          name: 'JobShort',
          path: 'shorts/:positionId',
          screen: Screen.JobShort,
          title: 'Position',
          getId: ({ params }) => params.positionId,
          options: { headerShown: false }
        },
        {
          name: 'Search',
          path: 'search',
          screen: Screen.Search,
          title: 'Search',
          options: {
            headerShown: false
          }
        },
        {
          name: 'OccupationCollections',
          path: 'collections',
          screen: Screen.OccupationCollections,
          options: ({ route }) => ({
            title: route.params?.title,
            fullScreenGestureEnabled: true,
            headerTransparent: true,
            headerBlurEffect: themeName,
            headerShown: true
          })
        }
      ]
    },
    Messages: {
      screens: isPhone
        ? [
            { name: 'ChannelList', index: true, screen: Screen.ChannelList, title: 'Messages' },
            { name: 'Channel', path: ':channelId', screen: Screen.Channel, title: 'Conversation' }
          ]
        : [
            {
              name: 'ChannelMasterDetail',
              path: ':channelId?',
              screen: Screen.ChannelMasterDetail,
              title: 'Messages'
            }
          ]
    },
    Notifications: {
      screens: [
        {
          name: 'Notifications',
          index: true,
          screen: Screen.NotificationList,
          title: 'Notifications',
          options: { headerShown: isWeb }
        }
      ]
    },
    Shorts: {
      screens: [
        {
          name: 'Shorts',
          index: true,
          screen: Screen.Shorts,
          title: 'Jobs',
          options: { headerShown: isWeb }
        }
      ]
    },
    TeamMessages: {
      screens: isPhone
        ? [
            { name: 'TeamChannelList', index: true, screen: Screen.TeamChannelList, title: 'Messages' },
            { name: 'TeamChannel', path: ':channelId', screen: Screen.Channel, title: 'Conversation' }
          ]
        : [
            {
              name: 'TeamChannelMasterDetail',
              path: ':channelId?',
              screen: Screen.TeamChannelMasterDetail,
              title: 'Team Messaging'
            }
          ]
    },
    EditProfile: {
      screens: [
        {
          name: 'EditProfile',
          index: true,
          screen: Screen.EditProfile,
          title: 'Edit Profile',
          options: { headerTransparent: isIos, headerBlurEffect: themeName }
        },
        {
          name: 'SelectCompany',
          path: 'company',
          screen: Screen.SelectCompanyForProfile,
          title: 'Select a Company',
          options: { presentation: 'modal', headerBlurEffect: themeName }
        },
        {
          name: 'SelectUniversity',
          path: 'university',
          screen: Screen.SelectUniversityForProfile,
          title: 'Select a University',
          options: { presentation: 'modal', headerBlurEffect: themeName }
        }
      ]
    },
    Profile: {
      screens: [
        {
          name: 'Profile',
          index: true,
          screen: Screen.Profile,
          title: 'Profile',
          initialParams: { username: user?.username }, // ?? route.params?.username },
          options: { presentation: 'modal', headerBlurEffect: themeName, headerShown: false }
        },
        {
          name: 'EditProfile',
          path: 'edit',
          screen: 'EditProfile',
          title: 'Edit Profile',
          initialParams: { username: user?.username }, // ?? route.params?.username },
          options: { headerShown: false, gestureEnabled: false }
        }
      ]
    },
    TeamPositions: {
      screens: [
        {
          name: 'TeamPositionsScreen',
          screen: () => (
            <Container height="100%" position="relative">
              {currentTeam && (
                <Lazy.PositionList
                  context="Team"
                  collectionSlug={`teams-${currentTeam.username}` as CollectionSlug}
                  collectionHeader={null}
                  collectionStyle={null}
                  headerText={null}
                  showTeamHeader={false}
                  renderPositionFilter={() => null}
                />
              )}
            </Container>
          ),
          title: () => (currentTeam ? currentTeam.name : t('Positions')),
          options: {
            headerLargeTitle: isIos,
            headerRight: () => <HeaderMenu />,
            fullScreenGestureEnabled: true,
            headerTransparent: !isWeb,
            headerBlurEffect: themeName
          }
        }
        // @todo readd when it doesn't conflict with jobs/:positionId elsewhere
        // { name: 'Position', path: 'jobs/:positionId', screen: Screen.JobDetail, title: 'Position' }
      ]
    },
    TeamProfile: {
      screens: [
        {
          name: 'TeamProfileScreen',
          index: true,
          screen: Screen.Team,
          title: 'Profile',
          options: {
            headerShown: true,
            headerLargeTitle: isIos,
            fullScreenGestureEnabled: true,
            headerTransparent: isIos,
            headerBlurEffect: themeName
          }
        },
        {
          name: 'TeamUserProfile',
          path: ':username?',
          screen: Screen.Profile,
          title: 'Profile',
          options: { headerShown: true }
        },
        /*<TeamProfile.Screen name="ProfilePreview" options={{ headerShown: false }}>
        {() => <Lazy.ProfilePreview Form={ProfileFormStack} />}
      </TeamProfile.Screen>*/
        {
          name: 'EditProfile',
          path: 'edit',
          screen: 'EditProfile',
          title: 'Edit Profile',
          options: { headerShown: false, presentation: 'modal' }
        }
      ]
    }
  }

  adjustStacks(mainStacks)

  return mainStacks
}
