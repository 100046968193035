import React, { lazy, Suspense } from 'react'

import { Loader, View } from '~/lite'

const load = {
  auth: () => import(/* webpackChunkName: "auth" */ '@ambition/module-auth'),
  billing: () => import(/* webpackChunkName: "billing" */ '@ambition/module-billing'),
  collection: () => import(/* webpackChunkName: "collection" */ '@ambition/module-collection'),
  dashboard: () => import(/* webpackChunkName: "dashboard" */ '@ambition/module-dashboard'),
  explore: () => import(/* webpackChunkName: "explore" */ '@ambition/module-explore'),
  content: () => import(/* webpackChunkName: "content" */ '@ambition/module-content'),
  form: () => import(/* webpackChunkName: "form" */ '@ambition/module-form'),
  instantsdk: () => import(/* webpackChunkName: "instantsdk" */ '@ambition/module-instantsdk'),
  marketplace: () => import(/* webpackChunkName: "marketplace" */ '@ambition/module-marketplace'),
  notification: () => import(/* webpackChunkName: "notification" */ '@ambition/module-notification'),
  position: () => import(/* webpackChunkName: "position" */ '@ambition/module-position'),
  shared: () => import(/* webpackChunkName: "shared" */ '@ambition/module-shared'),
  team: () => import(/* webpackChunkName: "team" */ '@ambition/module-team'),
  user: () => import(/* webpackChunkName: "user" */ '@ambition/module-user'),
  search: () => import(/* webpackChunkName: "search" */ '@ambition/module-search'),
  components: () => import(/* webpackChunkName: "components" */ '~/components')
}

const lazyComponents = {
  GitHub: lazy(() => load.auth().then(module => ({ default: module.GitHub }))),
  Login: lazy(() => load.auth().then(module => ({ default: module.Login }))),
  SelectCountry: lazy(() => load.auth().then(module => ({ default: module.SelectCountry }))),
  ConfirmEmail: lazy(() => load.auth().then(module => ({ default: module.ConfirmEmail }))),
  RecoverPassword: lazy(() => load.auth().then(module => ({ default: module.RecoverPassword }))),
  SignUpContainer: lazy(() => load.auth().then(module => ({ default: module.SignUpContainer }))),
  Channel: lazy(() => load.shared().then(module => ({ default: module.Channel }))),
  ChannelList: lazy(() => load.shared().then(module => ({ default: module.ChannelList }))),
  TeamChannelList: lazy(() => load.shared().then(module => ({ default: module.TeamChannelList }))),
  ChannelMasterDetail: lazy(() => load.shared().then(module => ({ default: module.ChannelMasterDetail }))),
  TeamChannelMasterDetail: lazy(() => load.shared().then(module => ({ default: module.TeamChannelMasterDetail }))),
  NotificationList: lazy(() => load.notification().then(module => ({ default: module.NotificationList }))),
  DeleteAccount: lazy(() => load.user().then(module => ({ default: module.DeleteAccount }))),
  Contact: lazy(() => load.content().then(module => ({ default: module.Contact }))),
  Privacy: lazy(() => load.content().then(module => ({ default: module.Privacy }))),
  Terms: lazy(() => load.content().then(module => ({ default: module.Terms }))),
  Sitemap: lazy(() => load.content().then(module => ({ default: module.Sitemap }))),
  Position: lazy(() => load.position().then(module => ({ default: module.Position }))),
  Team: lazy(() => load.team().then(module => ({ default: module.Team }))),
  TeamMembers: lazy(() => load.team().then(module => ({ default: module.TeamMembers }))),
  ProductSelect: lazy(() => load.billing().then(module => ({ default: module.ProductSelect }))),
  Profile: lazy(() => load.user().then(module => ({ default: module.Profile }))),
  UserTeams: lazy(() => load.user().then(module => ({ default: module.UserTeams }))),
  UserAccount: lazy(() => load.user().then(module => ({ default: module.UserAccount }))),
  SubscribedCollections: lazy(() => load.collection().then(module => ({ default: module.SubscribedCollections }))),
  Collections: lazy(() => load.collection().then(module => ({ default: module.Collections }))),
  Collection: lazy(() => load.collection().then(module => ({ default: module.Collection }))),
  ExplorePositions: lazy(() => load.position().then(module => ({ default: module.PositionList }))),
  JobDetail: lazy(() => load.position().then(module => ({ default: module.JobDetail }))),
  Locations: lazy(() => load.collection().then(module => ({ default: module.Locations }))),
  PositionList: lazy(() => load.collection().then(module => ({ default: module.PositionList }))),
  EditPosition: lazy(() => load.form().then(module => ({ default: module.EditPosition }))),
  EditTeam: lazy(() => load.form().then(module => ({ default: module.EditTeam }))),
  NewPosition: lazy(() => load.position().then(module => ({ default: module.NewPosition }))),
  SelectCompany: lazy(() => load.form().then(module => ({ default: module.SelectCompany }))),
  SelectCompanyForProfile: lazy(() => load.form().then(module => ({ default: module.SelectCompanyForProfile }))),
  SelectLocation: lazy(() => load.form().then(module => ({ default: module.SelectLocation }))),
  SelectPositionLocation: lazy(() => load.form().then(module => ({ default: module.SelectPositionLocation }))),
  SelectUniversityForProfile: lazy(() => load.form().then(module => ({ default: module.SelectUniversityForProfile }))),
  NewTeam: lazy(() => load.form().then(module => ({ default: module.NewTeam }))),
  PositionPreview: lazy(() => load.position().then(module => ({ default: module.PositionPreview }))),
  PromotePosition: lazy(() => load.form().then(module => ({ default: module.PromotePosition }))),
  EditProfile: lazy(() => load.form().then(module => ({ default: module.EditProfile }))),
  ProfilePreview: lazy(() => load.user().then(module => ({ default: module.ProfilePreview }))),
  NewApplication: lazy(() => load.form().then(module => ({ default: module.NewApplication }))),
  NewCollection: lazy(() => load.form().then(module => ({ default: module.NewCollection }))),
  JobDashboardInterstitial: lazy(() => load.dashboard().then(module => ({ default: module.JobDashboardInterstitial }))),
  TeamDashboard: lazy(() => load.dashboard().then(module => ({ default: module.TeamDashboardInterstitial }))),
  TeamSettings: lazy(() => load.team().then(module => ({ default: module.TeamSettings }))),
  TeamStatuses: lazy(() => load.team().then(module => ({ default: module.TeamStatusesPage }))),
  TeamCard: lazy(() => load.shared().then(module => ({ default: module.TeamCard }))),
  AnalyzeResume: lazy(() => load.shared().then(module => ({ default: module.AnalyzeResume }))),
  Arena: lazy(() => load.shared().then(module => ({ default: module.Arena }))),
  Pipeline: lazy(() => load.shared().then(module => ({ default: module.Pipeline }))),
  InstantSdk: lazy(() => load.instantsdk().then(module => ({ default: module.InstantSdk }))),
  InstantSdkDashboard: lazy(() => load.instantsdk().then(module => ({ default: module.InstantSdkDashboard }))),
  InstantSdkProfile: lazy(() => load.instantsdk().then(module => ({ default: module.InstantSdkProfile }))),
  Launchpad: lazy(() => load.marketplace().then(module => ({ default: module.Launchpad }))),
  LaunchpadDashboard: lazy(() => load.marketplace().then(module => ({ default: module.LaunchpadDashboard }))),
  LaunchpadProfile: lazy(() => load.marketplace().then(module => ({ default: module.LaunchpadProfile }))),
  Autopilot: lazy(() => load.shared().then(module => ({ default: module.Autopilot }))),
  ShareApp: lazy(() => load.content().then(module => ({ default: module.ShareApp }))),
  OmniSearch: lazy(() => load.components().then(module => ({ default: module.OmniSearch }))),
  Search: lazy(() => load.search().then(module => ({ default: module.SearchResults }))),
  Studio: lazy(() => load.shared().then(module => ({ default: module.Studio }))),
  Interview: lazy(() => load.shared().then(module => ({ default: module.Interview }))),
  InterviewPlayer: lazy(() => load.shared().then(module => ({ default: module.InterviewPlayer })))
} as const

type LazyComponents = typeof lazyComponents

interface IWrapProps {
  loader?: 'block' | 'inline' | 'none'
  children?: React.ReactNode
}

const Wrap: React.FC<IWrapProps> = ({ loader = 'block', children }) => (
  <Suspense
    fallback={
      loader === 'none' ? null : loader === 'inline' ? (
        <Loader inline />
      ) : (
        <View minHeight="calc(100vh - 60px)">
          <Loader />
        </View>
      )
    }
  >
    {children}
  </Suspense>
)

export const Lazy = Object.entries(lazyComponents).reduce(
  (loaders, [name, LazyComponent]) => ({
    ...loaders,
    [name]: ({ loader, ...props }: React.ComponentProps<LazyComponents[keyof LazyComponents]> & IWrapProps) => (
      <Wrap loader={loader}>
        <LazyComponent {...(props as any)} />
      </Wrap>
    )
  }),
  {} as { [K in keyof LazyComponents]: React.FC<React.ComponentProps<LazyComponents[K]> & IWrapProps> }
)
