import React from 'react'

import { Text } from './Text'
import { IHProps } from './types'

// accessibilityLevel={1}
export const H1: React.FC<IHProps> = props => <Text fontSize={20} fontWeight="bold" {...props} tag="h1" />

// accessibilityLevel={2}
export const H2: React.FC<IHProps> = props => <Text fontSize={18} fontWeight="bold" {...props} tag="h2" />

// accessibilityLevel={3}
export const H3: React.FC<IHProps> = props => <Text fontSize={16} fontWeight="bold" {...props} tag="h3" />

// accessibilityLevel={4}
export const H4: React.FC<IHProps> = props => <Text fontSize={14} fontWeight="bold" {...props} tag="h4" />

// accessibilityLevel={5}
export const H5: React.FC<IHProps> = props => <Text fontSize={13} fontWeight="bold" {...props} tag="h5" />
