import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  formatDistanceStrict,
  Locale,
  parseISO
} from 'date-fns'

import { markTranslated, SupportedLocaleCode, TranslatedText } from '../i18n'

// null will fallback to date-fns's default locale (en-US)
const locales: Record<SupportedLocaleCode, (() => Promise<{ default: Locale }>) | null> = {
  'af-ZA': () => import('date-fns/locale/af'),
  'ar-SA': () => import('date-fns/locale/ar'),
  'ca-ES': () => import('date-fns/locale/ca'),
  'cs-CZ': () => import('date-fns/locale/cs'),
  'da-DK': () => import('date-fns/locale/da'),
  'de-DE': () => import('date-fns/locale/de'),
  'el-GR': () => import('date-fns/locale/el'),
  'en-US': null,
  'es-419': () => import('date-fns/locale/es'),
  'es-ES': () => import('date-fns/locale/es'),
  'fi-FI': () => import('date-fns/locale/fi'),
  'fr-CA': () => import('date-fns/locale/fr-CA'),
  'fr-FR': () => import('date-fns/locale/fr'),
  'he-IL': () => import('date-fns/locale/he'),
  'hi-IN': () => import('date-fns/locale/hi'),
  'hr-HR': () => import('date-fns/locale/hr'),
  'hu-HU': () => import('date-fns/locale/hu'),
  'hy-AM': () => import('date-fns/locale/hy'),
  'id-ID': () => import('date-fns/locale/id'),
  'it-IT': () => import('date-fns/locale/it'),
  'ja-JP': () => import('date-fns/locale/ja'),
  'ko-KR': () => import('date-fns/locale/ko'),
  'ms-MY': () => import('date-fns/locale/ms'),
  'nl-NL': () => import('date-fns/locale/nl'),
  'no-NO': () => import('date-fns/locale/nb'),
  'pl-PL': () => import('date-fns/locale/pl'),
  'pt-BR': () => import('date-fns/locale/pt-BR'),
  'pt-PT': () => import('date-fns/locale/pt'),
  'ro-RO': () => import('date-fns/locale/ro'),
  'ru-RU': () => import('date-fns/locale/ru'),
  'sk-SK': () => import('date-fns/locale/sk'),
  'sr-SP': () => import('date-fns/locale/sr'),
  'sv-SE': () => import('date-fns/locale/sv'),
  'th-TH': () => import('date-fns/locale/th'),
  'tr-TR': () => import('date-fns/locale/tr'),
  'uk-UA': () => import('date-fns/locale/uk'),
  'ur-PK': null,
  'vi-VN': () => import('date-fns/locale/vi'),
  'zh-CN': () => import('date-fns/locale/zh-CN'),
  'zh-TW': () => import('date-fns/locale/zh-TW')
}

export const getDateFnConfig = async (locale: SupportedLocaleCode) => {
  const loader = locales[locale]
  return loader ? (await loader()).default : loader
}

const formatDistance = (locale: SupportedLocaleCode, date: Date, useMonthsThreshold = true): string => {
  const now = new Date()

  // date-fn helps with DST logic...
  const seconds = differenceInSeconds(date, now)
  const minutes = differenceInMinutes(date, now)
  const hours = differenceInHours(date, now)
  const days = differenceInDays(date, now)
  const months = differenceInMonths(date, now)
  const years = differenceInYears(date, now)

  let unit: Intl.RelativeTimeFormatUnitSingular = 'second'
  let value = seconds

  if (useMonthsThreshold && Math.abs(months) >= 1) {
    return `${new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(-30, 'days')}`.replace('30', '30+')
  }

  if (Math.abs(seconds) < 60) {
    unit = 'second'
    value = seconds
  } else if (Math.abs(minutes) < 60) {
    unit = 'minute'
    value = minutes
  } else if (Math.abs(hours) < 24) {
    unit = 'hour'
    value = hours
  } else if (Math.abs(days) < 31) {
    unit = 'day'
    value = days
  } else if (Math.abs(months) < 12) {
    unit = 'month'
    value = months
  } else {
    unit = 'year'
    value = years
  }

  return new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(value, unit)
}

// useful: https://intl-explorer.com
export const getDateFns = (locale: SupportedLocaleCode) => {
  const getTimeAgo = (date: Date, useMonthsThreshold = true): TranslatedText =>
    markTranslated(formatDistance(locale, date, useMonthsThreshold))

  // react native hasn't supported Intl.RelativeTimeFormat yet, so we need to use this for native
  const getTimeAgoAsync = async (date: Date, withoutSuffix?: boolean) => {
    const dateFnLocale = await getDateFnConfig(locale)
    return markTranslated(
      formatDistanceStrict(date, new Date(), {
        addSuffix: !withoutSuffix,
        locale: dateFnLocale ?? undefined
      })
    )
  }

  const formatDate = (date: Date, formatOptions: Intl.DateTimeFormatOptions): TranslatedText =>
    markTranslated(date.toLocaleString(locale, formatOptions))

  const formatDateString = (date: string, formatOptions: Intl.DateTimeFormatOptions): TranslatedText =>
    formatDate(parseISO(date), formatOptions)

  const getDateRangeLabel = (startDate?: string, endDate?: string) => {
    const startDateLabel = startDate ? formatDate(new Date(startDate), { dateStyle: 'long' }) : '...'
    const endDateLabel = endDate ? formatDate(new Date(endDate), { dateStyle: 'long' }) : '...'
    return markTranslated([startDateLabel, endDateLabel].join(' - '))
  }

  return {
    getTimeAgo,
    getTimeAgoAsync,
    formatDate,
    formatDateString,
    getDateRangeLabel
  }
}

export type DateFns = ReturnType<typeof getDateFns>
