import React, { useState } from 'react'

import { CompanyCardProps } from '../CompanyCard/types'

export const CompanyCardSkeleton: React.FC<Omit<CompanyCardProps, 'record'>> = () => {
  /*
  @todo Math.random() + SSR = mismatch
  const [styles] = useState([
    { width: `${Math.floor(50 + Math.random() * 50)}%` },
    { width: `${Math.floor(30 + Math.random() * 30)}%` },
    { width: `${Math.floor(30 + Math.random() * 30)}%` }
  ])*/

  const [styles] = useState([{ width: '70%' }, { width: '50%' }, { width: '30%' }])

  return (
    <div className="job-card skeleton" aria-hidden="true">
      <div className="job-card-info">
        <div className="job-card-title">
          <div className="skeleton-text" />
          <div className="skeleton-text" style={styles[0]} />
        </div>
        <div className="job-card-subtitle">
          <div className="skeleton-text" style={styles[1]} />
        </div>
        <div className="job-card-subtitle">
          <div className="skeleton-text" style={styles[2]} />
        </div>
      </div>
    </div>
  )
}
