import { isString, keyBy } from 'lodash'
import qs from 'qs'

import {
  CollectionSlug,
  CollectionType,
  CompensationFrequency,
  CountryCode,
  DatePosted,
  ICollection,
  IConnection,
  IContinent,
  ICountry,
  IDashboardPosition,
  IDashboardTeam,
  IExperience,
  ILocation,
  IMapBounds,
  IOccupation,
  IPosition,
  IPositionPartial,
  IScreenerResume,
  ISkill,
  ITeam,
  PositionFilterFormModel,
  Soc2018Code
} from '../models'
import { ClientRoutePath } from '../routes'
import { isUUID } from './helpers'

interface IPossiblyPagedQuery {
  /**
   * Indicates where we should start returning results.
   *
   * offset+limit might work for simple cases, but for frequently
   * changing data it would result in missing or duplicate results.
   *
   * With a cursor we can use the id of the last record of the last page,
   * ensuring the next page starts at the next record regardless of data updates.
   */
  cursor?: string | null
}

interface IPageOffsetQuery {
  page?: string
  limit?: string
  scope?: JobScope
}

type ExperiencesQuery = { experiences: IExperience[] }

type TeamsQuery = { teams: ITeam['username'][] }

type LatLngQuery = { latLng: [number, number] }

type LocationBySlug = { locationSlug: ILocation['slug'] }

type FileType = { fileType: string }
type ContentType = { contentType: string }

export interface IPositionQuery extends IPageOffsetQuery {
  naicsCode?: string
  socCode?: Soc2018Code
  collectionId?: ICollection['id']
  parentCollectionId?: ICollection['id']
  experiences?: IExperience[]
  teams?: ITeam['username'][]
  latLng?: [number, number]
  locationSlug?: ILocation['slug']
  language?: string[]
  continentCodes?: IContinent['code'][]
  countryCodes?: ICountry['code'][]
  withLocation?: boolean
  applied?: boolean
  bookmarked?: boolean
  connectionCode?: IConnection['code']
  sortBy?: 'date' | 'distance' | 'popularity'
  bounds?: IMapBounds
  remote?: boolean
  datePosted?: DatePosted | null
  salaryEstimate?: number | null
  salaryFrequency?: CompensationFrequency | null
  locationFilterId?: ILocation['id']
  teamName?: string | null
  scope?: JobScope
}

export interface IRecommededPositionsQuery {
  teamId?: ITeam['id']
  locationId?: ILocation['id']
  socCode?: Soc2018Code
}

export interface IFileUploadQuery {
  fileName?: string
  fileType?: string
  contentType?: string
}

export interface ILatitudeLongitudeQuery {
  latLng?: [number, number]
  distanceRadius?: number
}

export interface IRedirectPositionParam extends Record<string, string> {
  jid: string
  jobid: string
}

export interface IForwardPublicSectionParam extends Record<string, string> {
  jid: string
  publicSectionId: string
}

export interface IAdvancedSearchQuery extends IPageOffsetQuery, ILatitudeLongitudeQuery {
  // web-search fields
  type?: AdvancedSearchQueryType
  teams?: ITeam['username'][]
  preferRemote?: string

  // filters
  search?: string
  subTitleQuery?: string // use to override search
  keywordGroups?: string[][]
  optionalKeywords?: string[]
  excludedKeywords?: string[]
  minimumCPC?: number
  locationId?: ILocation['id']
  preferForm?: boolean

  datePosted?: DatePosted | null
  dateValue?: number

  salaryEstimate?: number
  salaryFrequency?: CompensationFrequency | null

  // optional fields request from elastic search
  requestHighlights?: boolean
  includeDistanceField?: boolean
}

export interface ITeamPositionsQuery extends IPageOffsetQuery {
  source?: 'dashboard' | null
}

export interface ICitySearchQuery extends IPageOffsetQuery {
  countryCode?: string
  search?: string
  state?: string
}

export interface ILocationSearchQuery extends IPageOffsetQuery {
  search?: string
  locationId?: string
}

export interface IShortPositionQuery extends IPageOffsetQuery {
  positionId?: IPosition['id'] | null
}

export interface IFeedSectionQuery extends IPageOffsetQuery {
  excludePositions?: string
  preferCurrentLocation?: string

  preferRemote?: string
  distanceRadius?: string
  date?: string
  compensation?: string
  compensationFrequency?: CompensationFrequency
  subTitleQuery?: string
  occupationId?: IOccupation['id']
}

export interface IScreenerInterviewQuestionQuery {
  teamId?: ITeam['id']
  positionId?: IPosition['id'] | null
  screenerResumeId?: IScreenerResume['id']
}

export type JobScope = string | null | undefined

type QueryPart<T> = { [param in keyof T]: string }

// Express' typings require all params values to be strings: { foo: string }
// TypeScript converts optional params like { foo?: string } to { foo?: string | undefined }
// So to support optional params, generate a union of every possible combination of component parts, with values as strings
export type IPositionQueryParams = QueryPart<ExperiencesQuery> &
  QueryPart<TeamsQuery> &
  QueryPart<LatLngQuery> &
  QueryPart<LocationBySlug>

export type IFileUploadQueryParams = QueryPart<FileType> & QueryPart<ContentType>

export const encodePositionQuery = (query: IPositionQuery): string => {
  return qs.stringify(query, { encodeValuesOnly: true })
  /*
  compact([
    teams.length ? `teams/${teams.join(',')}` : null,
    experiences
      .map(experience =>
        compact([
          encodeURIComponent(experience.skillSlug),
          experience.minYears || experience.maxYears ? `min/${encodeURIComponent(experience.minYears || 0)}` : null,
          experience.maxYears !== null ? `max/${encodeURIComponent(experience.maxYears)}` : null
        ]).join('/')
      )
      .join('/')
  ]).join('/')
  */
}

export const decodePositionQuery = (query: string | IPositionQuery): IPositionQuery => {
  if (!isString(query)) {
    return query
  }

  const q = qs.parse(query) as IPositionQuery

  return {
    ...q,
    ...(q.latLng ? { latLng: [+q.latLng[0], +q.latLng[1]] } : {})
  }

  /*
  const result: IPositionQuery = {}

  if(params.experiences){

  }

  if(params.teams){
    result.teams = params.teams
  }
  experiences?: IExperience[]
  teams?: ITeam['username'][]
  latLng?: [number, number]

  const experiences: IExperience[] = []
  const teams: ITeam['username'][] = []

  if (isString(query)) {
    const parts = query.split('/') || []

    let nextIsTeam = false
    let nextIsMin = false
    let nextIsMax = false

    parts.forEach(part => {
      if (nextIsTeam) {
        teams.push(...(part.split(',') as ITeam['username'][]))
        nextIsTeam = false
        return
      }

      if (nextIsMin) {
        if ((+part).toString() === part && experiences.length) {
          experiences[experiences.length - 1] = { ...experiences[experiences.length - 1], minYears: +part }
        }

        nextIsMin = false
        return
      }

      if (nextIsMax) {
        if ((+part).toString() === part && experiences.length) {
          experiences[experiences.length - 1] = { ...experiences[experiences.length - 1], maxYears: +part }
        }

        nextIsMax = false
        return
      }

      if (part === 'teams') {
        nextIsTeam = true
        return
      }

      if (part === 'min') {
        nextIsMin = true
        return
      }

      if (part === 'max') {
        nextIsMax = true
        return
      }

      const skill = skills.find(skill => skill.slug === part)

      if (!skill) {
        return
      }

      experiences.push({
        skillSlug: skill.slug,
        minYears: null,
        maxYears: null,
        kind: null
      })
    })
  }

  return { experiences, teams }
  */
}

export const parseNumberFromQueryField = (parsedQuery: qs.ParsedQs, fields: string[], defaults: number[]): number[] => {
  return fields.map((field, index) => {
    const value = parsedQuery[field]
    if (value && isString(value)) {
      const parsedValue = parseInt(value)
      if (!Number.isNaN(parsedValue)) {
        return parsedValue
      }
    }

    return defaults[index] ?? 0
  })
}

export const decodeQueryString = (query: string): qs.ParsedQs => {
  return qs.parse(query)
}

export const decodeFileUploadQuery = (query: string | IFileUploadQuery): IFileUploadQuery => {
  return isString(query) ? qs.parse(query) : query
}

export const encodeFileUploadQuery = (query: IFileUploadQuery): string => {
  return qs.stringify(query, { encodeValuesOnly: true })
}

export const getFilteredPositions = (
  positions: IPositionPartial[],
  { experiences = [] }: IPositionQuery
): IPositionPartial[] => {
  const filtersBySkillSlug = keyBy(experiences, 'skill.slug')

  return positions.filter(
    position =>
      !experiences.length ||
      position.experiences.some(experience => {
        const filter = filtersBySkillSlug[experience.skillSlug]
        return (
          filter &&
          (!filter.minYears || filter.minYears <= (experience.minYears || 0)) &&
          (!filter.maxYears || filter.maxYears >= (experience.maxYears || 15))
        )
      })
  )
}

const slugDivider = ','
const idDivider = '-'

type PositionQueryWithParam<T extends keyof IPositionQuery> = Omit<IPositionQuery, T> &
  Record<T, NonNullable<IPositionQuery[T]>>

interface IPositionQueryParser<T extends CollectionType> {
  serialize(query: IPositionQuery): string | null
  deserialize(part: string): Extract<ICollectionInfo, { collectionType: T }>['query'] | null
}

// @todo PositionQuery elements not parsed by positionQueryParsers: language, forMap, parentCollectionId
const positionQueryParsers: { [type in CollectionType]: IPositionQueryParser<type> } = {
  Private: {
    serialize: query => (query.collectionId ? query.collectionId : null),
    deserialize: part => (isUUID<ICollection['id']>(part) ? { collectionId: part } : null)
  },
  Skill: {
    serialize: query =>
      query.experiences && query.experiences.length > 0
        ? query.experiences.map(experience => experience.skillSlug).join(slugDivider)
        : null,
    deserialize: part => ({
      experiences: [
        {
          skillSlug: part as ISkill['slug'],
          minYears: null,
          maxYears: null,
          kind: 'Optional'
        }
      ]
    })
  },
  Applications: {
    serialize: query => (query.applied ? 'applied' : null),
    deserialize: part => (part === 'applied' ? { applied: true } : null)
  },
  Continent: {
    serialize: query => (query.continentCodes ? `continents-${query.continentCodes.join(idDivider)}` : null),
    deserialize: part =>
      part.startsWith('continents-')
        ? { continentCodes: part.replace('continents-', '').split(idDivider) as IContinent['code'][] }
        : null
  },
  Country: {
    serialize: query => (query.countryCodes ? `countries-${query.countryCodes.join(idDivider)}` : null),
    deserialize: part =>
      part.startsWith('countries-')
        ? { countryCodes: part.replace('countries-', '').split(idDivider) as ICountry['code'][] }
        : null
  },
  Location: {
    serialize: query => (query.locationSlug ? `locations-${query.locationSlug}` : null),
    deserialize: part =>
      part.startsWith('locations-') ? { locationSlug: part.replace('locations-', '') as ILocation['slug'] } : null
  },
  Nearby: {
    serialize: query => (query.withLocation ? 'nearby' : null),
    deserialize: part => (part === 'nearby' ? { withLocation: true } : null)
  },
  Team: {
    serialize: query => (query.teams && query.teams.length > 0 ? `teams-${query.teams.join('--')}` : null),
    deserialize: part =>
      part.startsWith('teams-')
        ? { teams: part.replace('teams-', '').split('--').map(decodeURI) as ITeam['username'][] }
        : null
  },
  MapBounds: {
    serialize: query => {
      if (!query.bounds) {
        return null
      }

      const { north, south, east, west } = query.bounds

      return `bounds-${[north, south, east, west].join(idDivider)}`
    },
    deserialize: part => {
      if (!part.startsWith('bounds-')) {
        return null
      }

      const [north, south, east, west] = part
        .replace('bounds-', '')
        .split(idDivider)
        .map(part => +part)

      return { bounds: { north, south, east, west } }
    }
  },
  SortBy: {
    serialize: query => (query.sortBy ? `sortby-${query.sortBy}` : null),
    deserialize: part =>
      part.startsWith('sortby-')
        ? { sortBy: part.replace('sortby-', '') as NonNullable<IPositionQuery['sortBy']> }
        : null
  },
  Remote: {
    serialize: query => (query.remote ? 'remote' : null),
    deserialize: part => (part === 'remote' ? { remote: true } : null)
  },
  LatLng: {
    serialize: query => (query.latLng ? `latlng-${query.latLng.join(idDivider)}` : null),
    deserialize: part =>
      part.startsWith('latlng-')
        ? { latLng: part.replace('latlng-', '').split(idDivider) as unknown as NonNullable<IPositionQuery['latLng']> }
        : null
  },
  Connection: {
    serialize: query => (query.connectionCode ? `connections-${query.connectionCode}` : null),
    deserialize: part =>
      part.startsWith('connections-')
        ? { connectionCode: part.replace('connections-', '') as NonNullable<IPositionQuery['connectionCode']> }
        : null
  },
  Industry: {
    serialize: query => (query.naicsCode ? `naics-${query.naicsCode}` : null),
    deserialize: part =>
      part.startsWith('naics-')
        ? { naicsCode: part.replace('naics-', '') as NonNullable<IPositionQuery['naicsCode']> }
        : null
  },
  Occupation: {
    serialize: query => (query.socCode ? `${query.socCode}-jobs` : null),
    deserialize: part => {
      /*
       * this matches:
       *  15-1141-replace-with-whatever-does-not-matter-jobs
       *  15-1141-jobs
       * to:
       * 15-1141-
       *
       * TODO: refine regexp to match only 15-1141
       */
      const socCode = part.match(/^(\d{2}-\d{4})/)?.[0]
      return socCode ? { socCode: socCode as NonNullable<IPositionQuery['socCode']> } : null
    }
  }
}

// please avoid modifying the order...
// the order of these parsers determines the order the query parts appear in the url while keeping it consistent helps improve SEO.
// unique collection ids should come first, then filters that could apply across many collections
const parserPriority: CollectionType[] = [
  'Private',
  'Applications',
  'Continent',
  'Country',
  'Location',
  'Nearby',
  'Team',
  'MapBounds',
  'SortBy',
  'Remote',
  'LatLng',
  'Connection',
  'Industry',
  'Occupation',
  // Skill must come last since its deserializer matches all paths (*)
  'Skill'
]

export type ICollectionInfo =
  | { collectionType?: null; query: IPositionQuery }
  | { collectionType: 'Applications'; query: PositionQueryWithParam<'applied'> }
  | { collectionType: 'Connection'; query: PositionQueryWithParam<'connectionCode'> }
  | { collectionType: 'Continent'; query: PositionQueryWithParam<'continentCodes'> }
  | { collectionType: 'Country'; query: PositionQueryWithParam<'countryCodes'> }
  | { collectionType: 'LatLng'; query: PositionQueryWithParam<'latLng'> }
  | { collectionType: 'Location'; query: PositionQueryWithParam<'locationSlug'> }
  | { collectionType: 'MapBounds'; query: PositionQueryWithParam<'bounds'> }
  | { collectionType: 'Nearby'; query: PositionQueryWithParam<'withLocation'> }
  | { collectionType: 'Private'; query: PositionQueryWithParam<'collectionId'> }
  | { collectionType: 'Skill'; query: PositionQueryWithParam<'experiences'> }
  | { collectionType: 'SortBy'; query: PositionQueryWithParam<'sortBy'> }
  | { collectionType: 'Remote'; query: PositionQueryWithParam<'remote'> }
  | { collectionType: 'Team'; query: PositionQueryWithParam<'teams'> }
  | { collectionType: 'Industry'; query: PositionQueryWithParam<'naicsCode'> }
  | { collectionType: 'Occupation'; query: PositionQueryWithParam<'socCode'> }

export const getCollectionInfoFromSlug = (collectionSlug: CollectionSlug): ICollectionInfo => {
  let collectionType: CollectionType | null = null
  let query: IPositionQuery = {}

  collectionSlug.split(slugDivider).forEach(part => {
    for (const parserName of parserPriority) {
      const partQuery = positionQueryParsers[parserName].deserialize(part)
      if (partQuery) {
        if (!collectionType) {
          collectionType = parserName
        }

        query = { ...query, ...partQuery }
        break
      }
    }
  })

  return { collectionType, query }
}

export const getCollectionSlugFromInfo = (info: ICollectionInfo): string => {
  const { query, collectionType } = info
  const parts = []

  for (const parserName of parserPriority) {
    const part = positionQueryParsers[parserName].serialize(query)

    if (part) {
      // collection type first (so remains collection type when deserialized), then order by parserPriority
      parserName === collectionType ? parts.unshift(part) : parts.push(part)
    }
  }

  return parts.join(slugDivider)
}

export type AdvancedSearchQueryType = 'all' | 'position' | 'team' | 'skill' | 'occupation'
export const AdvancedSearchMaxPage = 50
export const AdvancedSearchOccupationDefaultLimit = 10
export const AdvancedSearchDefaultLimit = 25
export const AdvancedSearchSkillDefaultLimit = 100

export const getCombinedPositionQuery = (
  query: IPositionQuery,
  formFilterParams: PositionFilterFormModel,
  mapBounds: IMapBounds | null
): IPositionQuery => {
  const { company, datePosted, locationId, salaryEstimate, distanceRadius } = formFilterParams
  return {
    ...query,
    ...(mapBounds && { bounds: mapBounds }),
    ...{
      distanceRadius,
      teams: company || undefined,
      salaryEstimate: salaryEstimate ? salaryEstimate[0] : null,
      locationFilterId: locationId as ILocation['id'],
      datePosted: datePosted
    }
  }
}

export const jobDashboardViews = ['applications', 'apply', 'autopilot', 'bookmarks', 'studio'] as const

export type JobDashboardView = typeof jobDashboardViews[number]

export interface IJobDashboardParams {
  query: string | null
  countries: CountryCode[] | null
  locationId: IDashboardPosition['locationId'] | null
  locationLevel: 'city' | 'level1' | null
  occupationId: IDashboardPosition['occupationId'] | null
  positionId: IDashboardPosition['id'] | null
  teamId: IDashboardTeam['id'] | null
  view: JobDashboardView | null
  datePosted: DatePosted | null
  remote: boolean | null
}

export type GetJobDashboardPath = (newParams: Partial<IJobDashboardParams>) => ClientRoutePath
