import { IMarketProfile } from 'core'

export const getPlans = (profile: IMarketProfile) => (profile.products ?? []).filter(product => product.isRecurring)

export const getProducts = (profile: IMarketProfile) => (profile.products ?? []).filter(product => !product.isRecurring)

export const getProfilePeople = (profile: IMarketProfile) => {
  const activePeople = profile.memberships
    .filter(membership => membership.state === 'Active')
    .map(membership => membership.marketPersonId)

  return profile.people.filter(person => activePeople.includes(person.id) && (person.headline || person.bio))
}

export const getSectionVisibilities = (
  profile: IMarketProfile
): Record<'hero' | 'deliverables' | 'testimonials' | 'about' | 'plans' | 'products' | 'faq', boolean> => ({
  hero: true,
  deliverables: !!profile.metadata.deliverables && profile.metadata.deliverables.items.length > 0,
  testimonials: !!profile.metadata.testimonials && profile.metadata.testimonials.items.length > 0,
  about: !!profile.metadata.about && getProfilePeople(profile).length > 0,
  plans: !!profile.metadata.plans && getPlans(profile).length > 0,
  products: !!profile.metadata.products && getProducts(profile).length > 0,
  faq: !!profile.metadata.faq?.questions?.length
})
