import { IInvitation, ITeam } from '../models'
import { Endpoint, get, post, put, remove } from '../util/api'

export type CreateInvitationsForTeam = Endpoint<
  { username: ITeam['username'] },
  { success: true },
  Record<string, IInvitation['email']>
>

export type GetInvitationsByTeam = Endpoint<
  { username: ITeam['username'] },
  { success: true; invitations: IInvitation[] },
  Record<string, never>
>

export type DeleteInvitation = Endpoint<Record<string, string>, { success: true }, { invitationId: IInvitation['id'] }>

export type PreviewInvitation = Endpoint<{ invitationId: IInvitation['id'] }, { success: true }, Record<string, string>>

export type GetInvitation = Endpoint<
  { invitationId: IInvitation['id'] },
  { success: true; invitation: IInvitation },
  Record<string, string>
>

export type GetInvitationsForUser = Endpoint<
  Record<string, string>,
  { success: true; invitations: IInvitation[] },
  Record<string, string>
>

export type AcceptInvitation = Endpoint<
  Record<string, string>,
  { success: true },
  { invitationId: IInvitation['id']; accepted: boolean }
>

export const getInvitation = (invitationId: IInvitation['id']) => get<GetInvitation>(`/invitations/${invitationId}`)

export const getInvitationsForUser = () => get<GetInvitationsForUser>(`/invitations`)

export const getInvitationsByTeam = (username: ITeam['username']) =>
  get<GetInvitationsByTeam>(`/invitations/team/${username}`)

export const createInvitationsForTeam = ({ teamUsername, ...invitations }: Record<string, string>) =>
  put<CreateInvitationsForTeam>(
    `/invitations/team/${teamUsername}`,
    invitations as Record<string, IInvitation['email']>
  )

export const deleteInvitation = (invitationId: IInvitation['id']) =>
  remove<DeleteInvitation>(`/invitations`, { invitationId })

export const acceptInvitation = (invitationId: IInvitation['id'], accepted: boolean) =>
  post<AcceptInvitation>(`/invitations/accept`, { invitationId, accepted })
