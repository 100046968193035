import { GetJobDashboardPath, viewPosition } from 'core'
import React, { useCallback, useState } from 'react'

import { CompactPositionRecord, IPosition, IPositionPartialDetail, ITeam } from '../models'
import { ClientRoutePath, encodeToken } from '../routes'
import { TranslationFn } from '../util/i18n'
import { Avatar } from './Avatar'

interface IJobCardProps {
  t: TranslationFn
  record: CompactPositionRecord | IPositionPartialDetail
  id?: string
  href?: string
  hrefTarget?: React.HTMLAttributeAnchorTarget
  showTeam?: boolean
  selected?: boolean
  listItem?: boolean
  enableSaveToCollection?: boolean
  isFetchingJobMacros?: boolean
  onPress?(id: IPosition['id']): void
  onTeamPress?(username: ITeam['username']): void
  getJobDashboardPath?: GetJobDashboardPath
}

export const JobCard: React.FC<IJobCardProps> = ({
  t,
  id,
  record,
  href,
  hrefTarget,
  showTeam = true,
  isFetchingJobMacros,
  selected,
  listItem,
  onPress,
  onTeamPress,
  getJobDashboardPath
}) => {
  const { location, title, compensation } = record
  const macroResults = 'macroResults' in record ? record.macroResults : null
  const postedAt = 'postedAt' in record ? record.postedAt : null
  const team = 'teamDetail' in record ? record.teamDetail : record.team
  const jobLink: ClientRoutePath = getJobDashboardPath
    ? getJobDashboardPath({ positionId: record.id })
    : `/jobs/${encodeToken(record.id)}`
  const teamLink: ClientRoutePath = `/teams/${encodeToken(team.username)}/jobs`
  const formattedCompensation = compensation ? t.formatCompensation(compensation) : null
  const formattedLocation = record.allowRemote ? t('Remote') : location

  const [skeletonStyles] = useState([{ width: '70%', height: '1rem' }])

  const handleOnPress = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      viewPosition(record.id, null)

      if (href) {
        return
      }

      if (onPress) {
        e.preventDefault()
        onPress(record.id)
      }
    },
    [onPress, record.id]
  )

  const handleOnTeamPress = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (onTeamPress) {
        e.preventDefault()
        onTeamPress(team.username)
      }
    },
    [onTeamPress, team.username]
  )

  const renderMacroSection = () => {
    if (isFetchingJobMacros) {
      return (
        <div className="job-card-description-section">
          <div className="skeleton-text" style={skeletonStyles[0]} />
        </div>
      )
    }
    if (macroResults) {
      return (
        <div className="job-card-description-section">
          {macroResults.map((macro, index) => (
            <p key={index} className="job-card-description">
              {macro}
            </p>
          ))}
        </div>
      )
    }
  }

  const maybeHasMacro = isFetchingJobMacros || !!macroResults

  //{compensation?.type === 'Estimate' && <div className="job-card-estimation">{t('Estimation')}</div>}
  return (
    <div
      id={id}
      className={`job-card${listItem ? ' job-list-item' : ''}${selected ? ' selected' : ''}${
        showTeam ? '' : ' no-team'
      }${maybeHasMacro ? ' maybe-has-macro' : ''}`}
    >
      <div className="job-card-inner">
        <div className="job-card-info">
          <a
            href={href ?? jobLink}
            className="job-card-title"
            onClick={handleOnPress}
            target={hrefTarget}
            rel="noopener noreferrer nofollow"
          >
            {!!title && (formattedCompensation ? <span className="job-card-title-text">{title}</span> : title)}
            {formattedCompensation && <span className="job-card-compensation">{formattedCompensation}</span>}
          </a>
          {showTeam && (
            <div className="job-card-subtitle">
              <Avatar team={team} to={teamLink} onPress={handleOnTeamPress} />
              <div className="job-card-team-info">
                <span>{team.name}</span>
                {team.in5Words && (
                  <>
                    <span className="job-card-subtitle-divider">&nbsp;·</span>{' '}
                    <span className="job-card-short-desc">
                      <span className="job-card-subtitle-divider">&nbsp;</span>
                      {team.in5Words}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="job-card-extra">
          <p className="job-card-location">{formattedLocation}</p>
          <span className="job-card-extra-divider">&nbsp;·&nbsp;</span>
          <p className="job-card-posted-date">{postedAt && <>{t.getTimeAgo(postedAt)}</>}</p>
        </div>
      </div>
      {renderMacroSection()}
    </div>
  )
}
