import { Assistant, AssistantView } from '@ambition/module-shared'
import React, { useEffect, useState } from 'react'

import { View } from '~/components'
import { useStore } from '~/hooks'
import { breakpoint, keyframes, styled, useBreakpoints } from '~/lite'

import { FeedSectionList } from './LeftMenu'
import { MainMenuShim } from './styles'
import { WebFooter } from './WebFooter'

const minimizeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.8);
  }
`

const maximizeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Wrap = styled(View)<{ $visible: boolean | null }>`
  transform-origin: top;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: ${props => (props.$visible === null ? 'none' : props.$visible ? maximizeIn : minimizeOut)};

  @media ${breakpoint.lg} {
    transform-origin: bottom;
  }
`

export const MainMenu: React.FC = () => {
  const isApplying = useStore(state => state.isApplying)

  const [assistantView, setAssistantView] = useState<AssistantView>('Mini')
  const [isAssistantInputFocused, setIsAssistantInputFocused] = useState(false)
  const scrollDirection = React.useRef<'up' | 'down'>()
  const scrollPosition = React.useRef<number>(0)
  const { isPhone } = useBreakpoints()

  useEffect(() => {
    if (!isPhone || isAssistantInputFocused) {
      return
    }

    /*
    @todo make this stable and re-enable
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset

      if (currentScrollPosition > scrollPosition.current && scrollDirection.current !== 'down') {
        setAssistantView('Closed')
        scrollDirection.current = 'down'
      } else if (currentScrollPosition < scrollPosition.current && scrollDirection.current !== 'up') {
        setAssistantView('Mini')
        scrollDirection.current = 'up'
      }

      scrollPosition.current = currentScrollPosition
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    */
  }, [assistantView, isAssistantInputFocused, isPhone])

  return (
    <>
      <MainMenuShim $view={assistantView} className="main-menu-shim" />
      <Wrap $visible={isApplying === null ? null : !isApplying} className="main-menu">
        <Assistant view={assistantView} setView={setAssistantView} setInputFocused={setIsAssistantInputFocused} />
        {/*<View className="main-menu-items" padding="m" paddingTop="none" zIndex={1}>
          <ListItem
            variant="Menu"
            accessoryLeft={<Icon width={30} height={26} icon="home-outline" />}
            action={{ item: 'Home' }}
            title={t('Explore')}
            to="/"
            fontSize={18}
          />
          <ListItem
            variant="Menu"
            accessoryLeft={<Icon width={30} height={26} icon="reader-outline" />}
            action={{ item: 'Shorts' }}
            title={t('Shorts')}
            to="/shorts"
            fontSize={18}
          />
          {!!user && (
            <>
              <ListItem
                variant="Menu"
                accessoryLeft={<Icon width={30} height={26} icon="person-circle-outline" />}
                action={{ item: 'Profile' }}
                title={t('Profile')}
                to="/user/profile"
                fontSize={18}
              />
              <ListItem
                variant="Menu"
                accessoryLeft={<Icon width={30} height={26} icon="document-text-outline" />}
                action={{ item: 'Applied' }}
                title={t('Applied')}
                to="/collections/applied"
                fontSize={18}
              />
              <ListItem
                variant="Menu"
                accessoryLeft={<Icon width={30} height={26} icon="bookmark-outline" />}
                action={{ item: 'Saved' }}
                title={t('Saved')}
                to={'/collections?groupSoc=private&title=My Collections' as any}
                fontSize={18}
              />
            </>
          )}
        </View>*/}
        {/*<LocationList />*/}
        <FeedSectionList />
        <WebFooter variant="desktop" />
      </Wrap>
    </>
  )
}

/*
import { MagicLink, ProfileAvatar } from '@ambition/module-shared'
import { BasicInformation } from '@ambition/module-user'

const COVER_IMAGE_HEIGHT = 100
const AVATAR_IMAGE_HEIGHT = 70

type CoverImageProps = {
  height: number
}

const CoverImage = styled.img<CoverImageProps>`
  width: 100%;
  height: ${props => props.height};
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const theme = useStore(state => state.theme)
const { data } = useProfileResume(username)
const username = user?.username || null

const renderProfileSection = () => {
  if (!data?.basics) {
    return null
  }
  const { basics } = data
  return (
    <View
      borderRadius={10}
      paddingBottom={16}
      margin={16}
      marginRight={0}
      backgroundColor={theme.cardBackground}
      overflow="hidden"
    >
      {basics.coverImage ? (
        <CoverImage src={basics.coverImage} height={COVER_IMAGE_HEIGHT} />
      ) : (
        <View width="100%" height={COVER_IMAGE_HEIGHT} backgroundColor={theme.primary} />
      )}
      <View width="100%" marginTop={-AVATAR_IMAGE_HEIGHT / 2}>
        <Button skipTracking minimal padding="none" to="/user/profile">
          <ProfileAvatar avatar={basics.image} name={basics.name} size={AVATAR_IMAGE_HEIGHT} />
        </Button>
      </View>
      <View marginTop={10}>
        <BasicInformation username={username} data={basics} />
      </View>
      <Button skipTracking minimal text="View Profile" to="/user/profile" marginTop={10} />
    </View>
  )
}

<Section title={t('Collections')} titleStyle={{ paddingLeft: 8 }} paddingLeft={8}>
  <SpecialCollectionList />
</Section>
<MagicLink variant="ResumeAnalysis" viewProps={{ marginRight: -16 }} />
{renderProfileSection()}
*/
