import { styled } from '~/lite'

import { View } from '../View/View'

export const CheckmarkWrapper = styled(View)`
  background-color: ${props => props.theme.primary};
`

export const Mark = styled(View)`
  transform: rotate(-135deg);
`
