import {
  authorizePusherChannel,
  authorizePusherUser,
  convertDateValues,
  IPusherChannelChannel,
  IPusherEvent,
  IPusherTeamChannel,
  IPusherUserChannel
} from 'core'
import type { Channel, default as PusherClass, PresenceChannel } from 'pusher-js'

import { constants } from '../constants'
import { getStateListeners, IPusherChannel, IPusherClient, PusherState } from './shared'

// see https://github.com/pusher/pusher-js#connection-states
type WebPusherState = 'initialized' | 'connecting' | 'connected' | 'disconnected' | 'unavailable' | 'failed'

const pusherStates: Record<WebPusherState, PusherState> = {
  initialized: 'Initialized',
  connecting: 'Connecting',
  connected: 'Connected',
  disconnected: 'Disconnected',
  unavailable: 'Unavailable',
  failed: 'Failed'
}

let _pusher: Promise<PusherClass> | null = null

const getPusher = async () => {
  if (!_pusher) {
    _pusher = (async () => {
      const Pusher = (await import('pusher-js')).default

      const client = new Pusher(constants.pusherKey, {
        cluster: constants.pusherCluster,
        userAuthentication: {
          endpoint: '/auth/pusher/user',
          transport: 'ajax',
          customHandler: async ({ socketId }, callback) => {
            const data = await authorizePusherUser(socketId)

            if (data.success) {
              const { success, ...authData } = data
              callback(null, authData)
            } else {
              callback(new Error(`Error calling auth endpoint: ${data.message}`), null)
            }
          }
        },
        channelAuthorization: {
          endpoint: '/auth/pusher/channel',
          transport: 'ajax',
          customHandler: async ({ socketId, channelName }, callback) => {
            const data = await authorizePusherChannel(socketId, channelName)

            if (data.success) {
              const { success, ...authData } = data
              callback(null, authData)
            } else {
              callback(new Error(`Error calling auth endpoint: ${data.message}`), { auth: '' })
            }
          }
        }
      })

      client.connection.bind(
        'error',
        (error: {
          type: string
          error?: { code: number; message: string }
          data?: { code: number; message: string }
        }) => {
          console.error(error)
        }
      )

      client.connection.bind(
        'state_change',
        ({ previous, current }: { previous: WebPusherState; current: WebPusherState }) => {
          if (!(current in pusherStates) || !(previous in pusherStates)) {
            throw new Error(`Unknown Pusher state: ${previous} -> ${current}`)
          }

          const previousState = pusherStates[previous as keyof typeof pusherStates]
          const currentState = pusherStates[current as keyof typeof pusherStates]

          getStateListeners().forEach(listener => listener({ previous: previousState, current: currentState }))
        }
      )

      return client
    })()
  }

  return _pusher
}

function createChannelInterface<Events extends IPusherEvent>(channelPromise: Promise<Channel>): IPusherChannel<Events> {
  return {
    onSubscriptionSucceeded: async callback => {
      ;(await channelPromise).bind('pusher:subscription_succeeded', callback)
    },
    trigger: async (eventName: Events['event'], data: Events['data']) => {
      ;(await channelPromise).trigger(eventName, data)
    },
    unsubscribe: async () => {
      ;(await channelPromise).unsubscribe()
    }
  }
}

export const pusher: IPusherClient = {
  subscribeToChannel: (channelId, onEvent) => {
    const channelName: IPusherChannelChannel['name'] = `presence-channel-${channelId as string}`
    const channelPromise = getPusher().then(pusher => pusher.subscribe(channelName) as PresenceChannel)

    channelPromise.then(channel => {
      channel.bind_global(
        <Event extends IPusherChannelChannel['events']['event']>(
          event: Event,
          data: Extract<IPusherChannelChannel['events'], { event: Event }>['data']
        ) => {
          const adjustedData = convertDateValues(data)
          onEvent({ event, data: adjustedData } as IPusherChannelChannel['events'])
        }
      )
    })

    return createChannelInterface(channelPromise)
  },

  subscribeToTeam: (teamId, onEvent) => {
    const channelName: IPusherTeamChannel['name'] = `private-team-${teamId as string}`
    const channelPromise = getPusher().then(pusher => pusher.subscribe(channelName))

    channelPromise.then(channel => {
      channel.bind_global(
        <Event extends IPusherTeamChannel['events']['event']>(
          event: Event,
          data: Extract<IPusherTeamChannel['events'], { event: Event }>['data']
        ) => {
          const adjustedData = convertDateValues(data)
          onEvent({ event, data: adjustedData } as IPusherTeamChannel['events'])
        }
      )
    })

    return createChannelInterface(channelPromise)
  },

  subscribeToUser: (userId, onEvent) => {
    const channelPromise = getPusher().then(pusher => pusher.subscribe(`private-user-${userId}`))

    channelPromise.then(channel => {
      channel.bind_global(
        <Event extends IPusherUserChannel['events']['event']>(
          event: Event,
          data: Extract<IPusherUserChannel['events'], { event: Event }>['data']
        ) => {
          const adjustedData = convertDateValues(data)
          onEvent({ event, data: adjustedData } as IPusherUserChannel['events'])
        }
      )
    })

    return createChannelInterface(channelPromise)
  }

  /*
  @todo switch back to this when we use pusher user channels again
  subscribeToUser: (onEvent: (event: IPusherUserChannel['events']) => void) => {
    getPusher().signin()

    const userFacade = getPusher().user

    userFacade.bind_global(
      <Event extends IPusherUserChannel['events']['event']>(
        event: Event,
        data: Extract<IPusherUserChannel['events'], { event: Event }>['data']
      ) => {
        onEvent({ event, data } as IPusherUserChannel['events'])
      }
    )

    return createChannelInterface(userFacade.serverToUserChannel)
  }
  */
}
