import { DatePosted, DatePostedFilters, TranslatedText, updateUserPreference } from 'core'
import React from 'react'

import { useValidateSearchRoute } from '~/hooks'
import { Select, useStore, useTranslation } from '~/lite'

interface IDatePostedFieldProps {}

export const DatePostedField: React.FC<IDatePostedFieldProps> = () => {
  const t = useTranslation()
  const validateRoute = useValidateSearchRoute()
  const { datePosted, setDatePosted } = useStore()

  const labelMap = React.useMemo<Record<DatePosted, TranslatedText>>(
    () => ({
      '24hrs': t('Last {{amount}} hours', { amount: 24 }),
      '3days': t('Last {{amount}} days', { amount: 3 }),
      '7days': t('Last {{amount}} days', { amount: 7 }),
      '14days': t('Last {{amount}} days', { amount: 14 }),
      '1month': t('Last {{amount}} days', { amount: 30 }),
      all: t('Any time')
    }),
    [t]
  )

  const options = React.useMemo(
    () => DatePostedFilters.map(item => ({ value: item, label: labelMap[item] })),
    [labelMap]
  )

  const onChange = (value: DatePosted | null) => {
    if (!value) {
      return
    }
    validateRoute('datePosted', value)
    setDatePosted(value)
    updateUserPreference({ datePosted: value })
  }

  return (
    <Select
      placeholder={t('Any time')}
      options={options}
      onChange={option => onChange(option?.value ? (option.value as DatePosted) : null)}
      value={datePosted ? { label: labelMap[datePosted], value: datePosted } : null}
    />
  )
}
