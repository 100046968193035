import React from 'react'

import { ShareButton } from '~/components'
import { Icon, Text, View } from '~/lite'
import { emptyString } from '~/util'

import { IconView, NabBarContainer } from './styles'
import { PositionNavBarProps } from './types'

export const PositionNavBar: React.FC<PositionNavBarProps> = ({ title, url }) => {
  return (
    <NabBarContainer paddingY="s" top={0} zIndex={1} position="absolute" width="100%">
      <View flexDirection="row" justifyContent="space-between" alignItems="center" paddingX="s">
        <View display="flex" flex={1} justifyContent="center" alignItems="center" paddingX="s" width="100%">
          {title ? <Text rawText={title} fontWeight="bold" fontSize={18} numberOfLines={1} textAlign="center" /> : null}
        </View>
        {url ? (
          <ShareButton
            action={{ item: 'App' }}
            message={title || emptyString}
            url={url}
            minimal
            padding="none"
            Component={({ onPress }) => (
              <IconView onPress={onPress}>
                <Icon icon="share-outline" size={24} color="white" />
              </IconView>
            )}
          />
        ) : null}
      </View>
    </NabBarContainer>
  )
}
