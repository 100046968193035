import { CreatePosition, CurrencyCode, ICompensation, IExperience, INewPosition } from 'core'
import React, { useCallback, useMemo, useState } from 'react'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'

import { Button, Checkbox, FormGroup, Icon, Pressable, styled, Text, View } from '~/components'
import { IUseFormState, useBreakpoints, useHistory, useStore, useTranslation } from '~/hooks'
import { isWeb } from '~/lite'
import { shareGeolocation } from '~/util'

import { ExperienceInput } from './ExperienceInput'
import { ILocationOption, LocationInput } from './LocationInput'

const PositionType = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border: 1px solid ${props => props.theme.border};
  padding: 15px;
`

const PositionCheckbox = styled(Checkbox)`
  flex: 1;
`

const SelectLocation = styled(Pressable)`
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-radius: 4px;
  padding: 0 10px 0 16px;
  border-color: ${props => props.theme.border};
`

const defaultEmployeeCompensation: ICompensation = {
  min: null,
  max: null,
  currency: 'USD' as CurrencyCode,
  frequency: 'Year'
}

const defaultContractCompensation: ICompensation = {
  min: null,
  max: null,
  currency: 'USD' as CurrencyCode,
  frequency: 'Hour'
}

type UseForm = IUseFormState<INewPosition, CreatePosition>

interface IQualificationInputProps {
  data: UseForm['data']
  errors: FieldErrors<INewPosition>
  updateField: UseFormSetValue<INewPosition>
  locationPlaceholder?: string
}

export const QualificationInput: React.FC<IQualificationInputProps> = ({
  data,
  errors,
  updateField,
  locationPlaceholder
}) => {
  const theme = useStore(state => state.theme)
  const { isPhone } = useBreakpoints()
  const t = useTranslation()
  const history = useHistory()
  const [activePanel, setActivePanel] = useState<'RequiredExperienceSkills' | 'OptionalExperienceSkills' | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const requiredExperiences = useMemo(
    () => data.experiences.filter(experience => experience.kind === 'Required'),
    [data]
  )

  const optionalExperiences = useMemo(
    () => data.experiences.filter(experience => experience.kind !== 'Required'),
    [data]
  )

  const onLocationInputChange = useCallback(
    (location: ILocationOption | null) => {
      updateField('location', location?.name || null)
      updateField('placeId', location?.placeId || null)
    },
    [updateField]
  )

  const onRequiredExperienceInputChange = useCallback(
    (experiences: IExperience[]) => updateField('experiences', [...experiences, ...optionalExperiences]),
    [updateField, optionalExperiences]
  )

  const onOptionalExperienceInputChange = useCallback(
    (experiences: IExperience[]) => updateField('experiences', [...requiredExperiences, ...experiences]),
    [updateField, requiredExperiences]
  )

  const onExperienceInputClose = useCallback(() => setActivePanel(null), [])

  const onLocationButtonPress = useCallback(async () => {
    setIsLoading(true)

    const location = await shareGeolocation()
    if (location.success && location.address) {
      onLocationInputChange({ name: location.address })
    }
    setIsLoading(false)
  }, [onLocationInputChange])

  return (
    <>
      <FormGroup label="Location" labelFor="location-input" helperText={errors.location?.message}>
        {isWeb || !isPhone ? (
          <LocationInput
            location={data.location ? { name: data.location } : null}
            onChange={onLocationInputChange}
            placeholder={locationPlaceholder}
          />
        ) : (
          <SelectLocation onPress={() => history.push('/positions/select-location')}>
            <Text color={theme.primary} rawText={data.location ?? t('Select location')}></Text>
            <View
              display={'flex'}
              flexDirection="row"
              minWidth={50}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Button
                action="GetCurrentLocation"
                icon="navigate"
                minimal
                loading={isLoading}
                onPress={onLocationButtonPress}
                height={45}
              />
              <Icon icon="chevron-forward" />
            </View>
          </SelectLocation>
        )}
      </FormGroup>
      {/*<FormGroup
        label="Types"
        helperText={
          compact([
            errors.allowEmployee,
            errors.employeeCompensation,
            errors.allowContract,
            errors.contractCompensation
          ]).join('. ') || undefined
        }
      >
        <PositionType>
          <PositionCheckbox
            label="Employee"
            checked={!!data.allowEmployee}
            onChange={checked => updateField('allowEmployee', checked)}
          />
          {data.allowEmployee && (
            <CompensationInput
              value={data.employeeCompensation || defaultEmployeeCompensation}
              onChange={value => updateField('employeeCompensation', value)}
            />
          )}
        </PositionType>
        <PositionType>
          <PositionCheckbox
            label="Contract"
            checked={!!data.allowContract}
            onChange={() => updateField('allowContract', !data.allowContract)}
          />
          {data.allowContract && (
            <CompensationInput
              value={data.contractCompensation || defaultContractCompensation}
              onChange={value => updateField('contractCompensation', value)}
            />
          )}
        </PositionType>
          </FormGroup>*/}
      {/* <FormGroup label="Experience" helperText={errors.experiences}>
        <Button
          text={requiredExperiences.length ? `${requiredExperiences.length} required` : 'Add required'}
          onPress={() => setActivePanel('RequiredExperienceSkills')}
          fill
        />
        <Button
          text={optionalExperiences.length ? `${optionalExperiences.length} bonus` : 'Add bonus'}
          onPress={() => setActivePanel('OptionalExperienceSkills')}
          fill
        />
      </FormGroup> */}

      <ExperienceInput
        active={activePanel === 'RequiredExperienceSkills'}
        view="SkillsAndDurations"
        header="Add required experience"
        durationPanelHeader="Minimum required years"
        kind="Required"
        experiences={requiredExperiences}
        onChange={onRequiredExperienceInputChange}
        onClose={onExperienceInputClose}
      />

      <ExperienceInput
        active={activePanel === 'OptionalExperienceSkills'}
        view="Skills"
        header="Add bonus experience"
        kind="Optional"
        experiences={optionalExperiences}
        onChange={onOptionalExperienceInputChange}
        onClose={onExperienceInputClose}
      />
    </>
  )
}
