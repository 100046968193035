import { DownloadApp, OpenAiLogo } from '@ambition/module-shared'
import { hoverArrow, localizePath } from 'core'
import React, { useState } from 'react'

import {
  breakpoint,
  Button,
  Dialog,
  Icon,
  isServer,
  isWeb,
  NavMenu,
  openURL,
  Pressable,
  styled,
  Text,
  trackEvent,
  useBreakpoints,
  useHistory,
  useOnHydrate,
  useStore,
  useTranslation,
  View
} from '~/lite'

const Group = styled(View)`
  align-items: stretch;
  gap: 8px;
`

const GroupHead = styled(View)`
  margin-top: 8px;
  text-align: center;

  @media ${breakpoint.md} {
    text-align: initial;
  }
`

const GroupHeader = styled(Text)`
  text-wrap: balance;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.8;
  margin-bottom: 8px;
`

const GroupSubheader = styled(Text)`
  font-size: 14px;
  text-wrap: balance;
  opacity: 0.6;
`

const ToolMenuItem = styled(Pressable)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: ${props => props.theme.inputBackground};
  border-width: 1px;
  border-color: ${props => (props.theme.dark ? props.theme.inputBorderColor : props.theme.border)};
  box-shadow: 0 0 5px rgba(${props => props.theme.backgroundRgb}, 1);
  border-radius: 12px;
`

const Header = styled(Text)`
  text-wrap: balance;
  font-size: 16px;
  font-weight: 500;
`

const Subheader = styled(Text)`
  text-wrap: balance;
  opacity: 0.6;
`

const Arrow = styled.span``

const Wrap = styled(View)<{ $variant?: 'header' }>`
  width: 100%;
  align-items: stretch;
  gap: 16px;
`

const HeaderTrigger = styled(Text)`
  font-weight: 500;
`

const HeaderWrap = styled(View)`
  align-items: stretch;
  gap: 0;
  flex-direction: row;
  padding: 12px 8px;

  ${Group} {
    min-width: 280px;
    gap: 0;
  }

  ${GroupHeader} {
    padding: 0 18px;
  }

  ${GroupSubheader} {
    padding: 0 18px 8px;
  }

  ${Arrow} {
    display: none;
  }

  ${ToolMenuItem} {
    border-width: 0;
    box-shadow: none;
    background: transparent;

    &:hover {
      background: ${props => (props.theme.dark ? props.theme.buttonBackground : props.theme.inputBackground)};
    }
  }
`

const getBrowser = () => {
  if (isServer || !isWeb) {
    return null
  }

  const userAgent = navigator.userAgent.toLowerCase()
  const isEdge = /edg\//.test(userAgent)
  const isChrome = /chrome/.test(userAgent) && /google inc/.test(navigator.vendor.toLowerCase()) && !isEdge

  if (isChrome) {
    return 'Chrome'
  }

  if (isEdge) {
    return 'Edge'
  }

  return null
}

const getChromeLogo = (size: number) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={size} width={size}>
    <defs>
      <linearGradient id="a" x1="3.2173" y1="15" x2="44.7812" y2="15" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d93025" />
        <stop offset="1" stopColor="#ea4335" />
      </linearGradient>
      <linearGradient id="b" x1="20.7219" y1="47.6791" x2="41.5039" y2="11.6837" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fcc934" />
        <stop offset="1" stopColor="#fbbc04" />
      </linearGradient>
      <linearGradient id="c" x1="26.5981" y1="46.5015" x2="5.8161" y2="10.506" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#1e8e3e" />
        <stop offset="1" stopColor="#34a853" />
      </linearGradient>
    </defs>
    <circle cx="24" cy="23.9947" r="12" style={{ fill: '#fff' }} />
    <path
      d="M3.2154,36A24,24,0,1,0,12,3.2154,24,24,0,0,0,3.2154,36ZM34.3923,18A12,12,0,1,1,18,13.6077,12,12,0,0,1,34.3923,18Z"
      style={{ fill: 'none' }}
    />
    <path
      d="M24,12H44.7812a23.9939,23.9939,0,0,0-41.5639.0029L13.6079,30l.0093-.0024A11.9852,11.9852,0,0,1,24,12Z"
      style={{ fill: 'url(#a)' }}
    />
    <circle cx="24" cy="24" r="9.5" style={{ fill: '#1a73e8' }} />
    <path
      d="M34.3913,30.0029,24.0007,48A23.994,23.994,0,0,0,44.78,12.0031H23.9989l-.0025.0093A11.985,11.985,0,0,1,34.3913,30.0029Z"
      style={{ fill: 'url(#b)' }}
    />
    <path
      d="M13.6086,30.0031,3.218,12.006A23.994,23.994,0,0,0,24.0025,48L34.3931,30.0029l-.0067-.0068a11.9852,11.9852,0,0,1-20.7778.007Z"
      style={{ fill: 'url(#c)' }}
    />
  </svg>
)

const getEdgeLogo = (size: number) => (
  <svg
    height={size}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-13.150631399175808 4.920000000000002 291.9547293925731 272.12347840798424"
  >
    <linearGradient
      id="a"
      gradientTransform="matrix(1 0 0 -1 0 266)"
      gradientUnits="userSpaceOnUse"
      x1="63.33"
      x2="241.67"
      y1="84.03"
      y2="84.03"
    >
      <stop offset="0" stopColor="#0c59a4" />
      <stop offset="1" stopColor="#114a8b" />
    </linearGradient>
    <radialGradient
      id="b"
      cx="161.83"
      cy="68.91"
      gradientTransform="matrix(1 0 0 -.95 0 248.84)"
      gradientUnits="userSpaceOnUse"
      r="95.38"
    >
      <stop offset=".72" stop-opacity="0" />
      <stop offset=".95" stop-opacity=".53" />
      <stop offset="1" />
    </radialGradient>
    <linearGradient
      id="c"
      gradientTransform="matrix(1 0 0 -1 0 266)"
      gradientUnits="userSpaceOnUse"
      x1="157.35"
      x2="45.96"
      y1="161.39"
      y2="40.06"
    >
      <stop offset="0" stopColor="#1b9de2" />
      <stop offset=".16" stopColor="#1595df" />
      <stop offset=".67" stopColor="#0680d7" />
      <stop offset="1" stopColor="#0078d4" />
    </linearGradient>
    <radialGradient
      id="d"
      cx="-340.29"
      cy="62.99"
      gradientTransform="matrix(.15 -.99 -.8 -.12 176.64 -125.4)"
      gradientUnits="userSpaceOnUse"
      r="143.24"
    >
      <stop offset=".76" stop-opacity="0" />
      <stop offset=".95" stop-opacity=".5" />
      <stop offset="1" />
    </radialGradient>
    <radialGradient
      id="e"
      cx="113.37"
      cy="570.21"
      gradientTransform="matrix(-.04 1 2.13 .08 -1179.54 -106.69)"
      gradientUnits="userSpaceOnUse"
      r="202.43"
    >
      <stop offset="0" stopColor="#35c1f1" />
      <stop offset=".11" stopColor="#34c1ed" />
      <stop offset=".23" stopColor="#2fc2df" />
      <stop offset=".31" stopColor="#2bc3d2" />
      <stop offset=".67" stopColor="#36c752" />
    </radialGradient>
    <radialGradient
      id="f"
      cx="376.52"
      cy="567.97"
      gradientTransform="matrix(.28 .96 .78 -.23 -303.76 -148.5)"
      gradientUnits="userSpaceOnUse"
      r="97.34"
    >
      <stop offset="0" stopColor="#66eb6e" />
      <stop offset="1" stopColor="#66eb6e" stop-opacity="0" />
    </radialGradient>
    <path
      d="M235.68 195.46a93.73 93.73 0 0 1-10.54 4.71 101.87 101.87 0 0 1-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 0 1 117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0 0 66.6-52.8 4 4 0 0 0-5.32-5.64z"
      fill="url(#a)"
      transform="translate(-4.63 -4.92)"
    />
    <path
      d="M235.68 195.46a93.73 93.73 0 0 1-10.54 4.71 101.87 101.87 0 0 1-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 0 1 117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0 0 66.6-52.8 4 4 0 0 0-5.32-5.64z"
      fill="url(#b)"
      opacity=".35"
      transform="translate(-4.63 -4.92)"
    />
    <path
      d="M110.34 246.34A79.2 79.2 0 0 1 87.6 225a80.72 80.72 0 0 1 29.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0 1 25.69 13 31.88 31.88 0 0 1 6.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0 0 12.11 56 128 128 0 0 0 156.38 67.11 75.55 75.55 0 0 1-62.78-8z"
      fill="url(#c)"
      transform="translate(-4.63 -4.92)"
    />
    <path
      d="M110.34 246.34A79.2 79.2 0 0 1 87.6 225a80.72 80.72 0 0 1 29.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0 1 25.69 13 31.88 31.88 0 0 1 6.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0 0 12.11 56 128 128 0 0 0 156.38 67.11 75.55 75.55 0 0 1-62.78-8z"
      fill="url(#d)"
      opacity=".41"
      transform="translate(-4.63 -4.92)"
    />
    <path
      d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0 0 30.27-8.35 61.38 61.38 0 0 0 30.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 0 0-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z"
      fill="url(#e)"
      transform="translate(-4.63 -4.92)"
    />
    <path
      d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0 0 30.27-8.35 61.38 61.38 0 0 0 30.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 0 0-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z"
      fill="url(#f)"
      transform="translate(-4.63 -4.92)"
    />
  </svg>
)

interface IToolMenuProps {
  variant?: 'header'
}

export const ToolMenu: React.FC<IToolMenuProps> = ({ variant }) => {
  const history = useHistory()
  const { isPhone } = useBreakpoints()
  const t = useTranslation()
  const locale = useStore(state => state.locale)
  const theme = useStore(state => state.theme)
  const [browser, setBrowser] = useState<ReturnType<typeof getBrowser>>(null)

  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false)

  useOnHydrate(() => {
    if (isWeb && !isServer) {
      setBrowser(getBrowser())
    }
  })

  const webExtensionUrl =
    browser === 'Edge'
      ? 'https://microsoftedge.microsoft.com/addons/detail/ambition/dmhmkckoachhkiicfhcoigkpkjpahbhg'
      : 'https://chrome.google.com/webstore/detail/ambition/jcmgaoihheikehfhnhjdedbnbaipdpkf'

  const menuItems = (
    <>
      <Group>
        <GroupHead>
          <GroupHeader text="For job seekers" />
        </GroupHead>
        <ToolMenuItem
          className="hover-arrow"
          to={isPhone ? localizePath('/', locale) : '/jobs'}
          onPress={() => trackEvent('Press', { action: 'ViewJobSearch', text: 'Job search' })}
        >
          <Icon icon="search" color="#6360ff" size={24} />
          <View flex={1}>
            <Header text="Job search" />
            <Subheader text="Search millions of jobs" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/launchpad', locale)}
          onPress={() => trackEvent('Press', { action: 'ViewLaunchpad', text: 'Launchpad' })}
        >
          <Icon icon="rocket-sharp" color="#88add2" size={24} />
          <View flex={1}>
            <View flexDirection="row" alignItems="center">
              <Header text="Launchpad" />
              <Text
                text="New"
                fontSize={11}
                marginLeft={4}
                paddingY={2}
                paddingX={5}
                fontWeight={400}
                borderRadius={4}
                backgroundColor="#ff7600"
                color="#fff"
              />
            </View>
            <Subheader text="CV to business" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        {/*<ToolMenuItem
          className="hover-arrow"
          to="/exchange"
          onPress={() => trackEvent('Press', { action: 'ViewExchange', text: 'Talent Exchange' })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#88add2" d="m16 3 4 4-4 4" />
            <path stroke="#88add2" d="M20 7H4" />
            <path stroke="#88add2" d="m8 21-4-4 4-4" />
            <path stroke="#88add2" d="M4 17h16" />
          </svg>
          <View flex={1}>
            <Header text="Talent Exchange" />
            <Subheader text="Broadcast your availability" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>*/}
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/studio', locale)}
          onPress={() => trackEvent('Press', { action: 'VisitStudio', text: 'Cover Letter Studio' })}
        >
          <Icon icon="sparkles" color="#1a74e8" size={24} />
          <View flex={1}>
            <Header text="Cover Letter Studio" />
            <Subheader text="Generate a cover letter" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        {!!browser && (
          <ToolMenuItem
            className="hover-arrow"
            onPress={() => {
              /*href={webExtensionUrl}}*/
              trackEvent('Press', {
                action: 'InstallWebExtension',
                text: browser === 'Edge' ? 'Add to Edge' : 'Add to Chrome'
              })

              history.openWebView({ type: 'ExternalSite', url: webExtensionUrl })
            }}
          >
            {browser === 'Edge' ? getEdgeLogo(24) : getChromeLogo(24)}
            <View flex={1}>
              <Header text={browser === 'Edge' ? 'Add to Edge' : 'Add to Chrome'} />
              <Subheader text="Autofill job applications" />
            </View>
            <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
          </ToolMenuItem>
        )}
        <ToolMenuItem
          className="hover-arrow"
          onPress={() => {
            trackEvent('Press', { action: 'AddToChatGpt', text: 'Add to ChatGPT' })
            history.openWebView({ type: 'ExternalSite', url: 'https://chat.openai.com/g/g-dnFnjifmN-ambition' })
          }}
        >
          <OpenAiLogo size={24} />
          <View flex={1}>
            <Header text="Add to ChatGPT" />
            <Subheader text="Find and discuss jobs" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
      </Group>
      <Group>
        <GroupHead>
          <GroupHeader text="For employers and recruiters" />
        </GroupHead>
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/screener', locale)}
          onPress={() => trackEvent('Press', { action: 'ViewScreenerLanding', text: 'CV Screener' })}
        >
          <Icon icon="flash" color="#facc14" size={24} />
          <View flex={1}>
            <Header text="CV Screener" />
            <Subheader text="Organize and rank candidates" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          to="/pipeline"
          onPress={() => trackEvent('Press', { action: 'ViewPipeline', text: 'Talent Pipeline' })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#88add2" d="m16 3 4 4-4 4" />
            <path stroke="#88add2" d="M20 7H4" />
            <path stroke="#88add2" d="m8 21-4-4 4-4" />
            <path stroke="#88add2" d="M4 17h16" />
          </svg>
          <View flex={1}>
            <View flexDirection="row" alignItems="center">
              <Header text="Talent Pipeline" />
              <Text
                text="Pre-order"
                fontSize={11}
                marginLeft={4}
                paddingY={2}
                paddingX={5}
                fontWeight={400}
                borderRadius={4}
                backgroundColor="#000"
                color="#fff"
              />
            </View>
            <Subheader text="Source top talent" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/promote', locale)}
          onPress={() => trackEvent('Press', { action: 'ViewPromoteJob', text: 'Promote a job' })}
        >
          <Icon icon="radio" color="#34a854" size={24} />
          <View flex={1}>
            <Header text="Promote a job" />
            <Subheader text="Reach more candidates" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
      </Group>
      <Group>
        <GroupHead>
          <GroupHeader text="For everyone" />
        </GroupHead>
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/affiliate', locale)}
          onPress={() => trackEvent('Press', { action: 'ViewAffiliateLanding', text: 'Referral program' })}
        >
          <Icon icon="heart" color="#e0372c" size={24} />
          <View flex={1}>
            <Header text="Referral program" />
            <Subheader text="Earn 30% commission" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          onPress={() => {
            if (variant === 'header') {
              setTimeout(() => {
                setIsMobileDialogOpen(true)
              }, 100)
            } else {
              setIsMobileDialogOpen(true)
            }

            trackEvent('Press', { action: 'DownloadApp', text: 'Get mobile app' })
          }}
        >
          <Icon icon="phone-portrait" color="#1a74e8" size={24} />
          <View flex={1}>
            <Header text="Get mobile app" />
            <Subheader text="Browse anywhere" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          to={localizePath('/api', locale)}
          onPress={() => trackEvent('Press', { action: 'ViewApi', text: 'Jobs API' })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="4" y="4" width="16" height="16" rx="2" />
            <rect x="9" y="9" width="6" height="6" />
            <path d="M15 2v2" />
            <path d="M15 20v2" />
            <path d="M2 15h2" />
            <path d="M2 9h2" />
            <path d="M20 15h2" />
            <path d="M20 9h2" />
            <path d="M9 2v2" />
            <path d="M9 20v2" />
          </svg>
          <View flex={1}>
            <Header text="Jobs API" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
        <ToolMenuItem
          className="hover-arrow"
          onPress={() => {
            trackEvent('Press', { action: 'InquireAboutPartnership', text: 'Become a partner' })
            openURL(`mailto:support@remoteambition.com?subject=${encodeURIComponent(t('Partnership Inquiry'))}`)
          }}
        >
          <Icon icon="earth" color={theme.text} size={24} />
          <View flex={1}>
            <Header text="Become a partner" />
          </View>
          <Arrow dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
        </ToolMenuItem>
      </Group>
    </>
  )

  const mobileDialog = (
    <Dialog
      open={isMobileDialogOpen}
      onOpenChange={open => !open && setIsMobileDialogOpen(false)}
      trigger={null}
      title="Get mobile app"
      description="Browse anywhere"
      footer={<Button text="Done" onPress={() => setIsMobileDialogOpen(false)} primary skipTracking />}
      small
    >
      <DownloadApp />
    </Dialog>
  )

  if (variant === 'header') {
    return (
      <>
        <NavMenu
          items={[
            {
              trigger: <HeaderTrigger text="Products" />,
              content: <HeaderWrap>{menuItems}</HeaderWrap>
            }
          ]}
        />
        {mobileDialog}
      </>
    )
  }

  return (
    <Wrap>
      {menuItems}
      {mobileDialog}
    </Wrap>
  )
}
