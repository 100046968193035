import { Loader } from '@googlemaps/js-api-loader'
import { generateUUID, markTranslated, TranslatedText } from 'core'
import { flatten } from 'lodash'

import { createDivElement } from '~/dom'
import { isNotBot, util } from '~/lite'

import { isWeb } from './common'
import { constants } from './constants'

const loader = new Loader({
  apiKey: constants.googleMapsApiKey,
  version: 'weekly',
  libraries: ['places']
})

let clientLibraryPromise: Promise<typeof google> | null = null

export const loadGoogleClientLibrary = (): Promise<typeof google> | null => {
  const enableGoogleClient = isNotBot

  if (!enableGoogleClient) {
    return clientLibraryPromise
  }

  if (!clientLibraryPromise) {
    clientLibraryPromise = loader.load()
  }

  return clientLibraryPromise
}

const getAutocompleteService = (() => {
  let service: Promise<google.maps.places.AutocompleteService> | null = null

  return async (): Promise<google.maps.places.AutocompleteService | null> => {
    if (!service) {
      service = loadGoogleClientLibrary()?.then(() => new google.maps.places.AutocompleteService()) || null
    }

    return service
  }
})()

/*
const getGeocoder = (() => {
  let geocoder: Promise<google.maps.Geocoder> | null = null

  return async (): Promise<google.maps.Geocoder> => {
    if (!geocoder) {
      geocoder = loadClientLibrary().then(() => new google.maps.Geocoder())
    }

    return geocoder
  }
})()
*/

const getPlacesService = (() => {
  let service: Promise<google.maps.places.PlacesService> | null = null

  return async (): Promise<google.maps.places.PlacesService | null> => {
    if (!service) {
      service = loadGoogleClientLibrary()?.then(() => new google.maps.places.PlacesService(createDivElement())) || null
    }

    return service
  }
})()

export const searchPlaces = async (
  sessionToken: google.maps.places.AutocompleteSessionToken | null,
  query: string,
  options?: Pick<google.maps.places.AutocompleteOptions, 'types'>
): Promise<{
  sessionToken: google.maps.places.AutocompleteSessionToken
  results: { placeId: string; name: TranslatedText; prediction: google.maps.places.AutocompletePrediction }[]
}> => {
  // web needs to load client library to fetch because of CORS restrictions, while native can fetch directly
  if (isWeb) {
    const service = await getAutocompleteService()
    const session = sessionToken || new google.maps.places.AutocompleteSessionToken()

    return new Promise(resolve => {
      service?.getPlacePredictions({ sessionToken: session, input: query, ...(options || {}) }, predictions =>
        resolve({
          sessionToken: session,
          results: (predictions ?? []).map(prediction => ({
            placeId: prediction.place_id,
            name: markTranslated(prediction.description),
            prediction
          }))
        })
      )
    })
  }

  const session = sessionToken || (generateUUID() as google.maps.places.AutocompleteSessionToken)

  const params = {
    key: constants.googleMapsApiKey,
    sessiontoken: session as string,
    input: query,
    ...(options?.types ? { types: options.types.join(',') } : {})
  }

  const response = await fetch(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?${util.encodeParams(params)}`
  )

  return {
    sessionToken: session,
    results: ((await response.json()) as google.maps.places.AutocompleteResponse).predictions.map(prediction => ({
      placeId: prediction.place_id,
      name: markTranslated(prediction.description),
      prediction
    }))
  }
}

const placeFieldCategories = {
  basic: [
    'address_component',
    'adr_address',
    'business_status',
    'formatted_address',
    'geometry',
    'icon',
    'name',
    'photo',
    'place_id',
    'plus_code',
    'type',
    'url',
    ...(isWeb ? ['utc_offset_minutes'] : ['utc_offset']),
    'vicinity'
  ],
  contact: ['formatted_phone_number', 'international_phone_number', 'opening_hours', 'website'],
  atmosphere: ['price_level', 'rating', 'review', 'user_ratings_total']
}

export const getPlaceDetails = async (
  sessionToken: google.maps.places.AutocompleteSessionToken | null,
  placeId: string,
  fieldCategories: (keyof typeof placeFieldCategories)[] = ['basic']
): Promise<google.maps.places.PlaceResult | null> => {
  const fields = flatten(
    Object.entries(placeFieldCategories).map(([category, categoryFields]) =>
      fieldCategories.includes(category as keyof typeof placeFieldCategories) ? categoryFields : []
    )
  )

  // web needs to load client library to fetch because of CORS restrictions, while native can fetch directly
  if (isWeb) {
    const service = await getPlacesService()

    return new Promise(resolve => {
      service?.getDetails({ ...(sessionToken ? { sessionToken } : {}), placeId, fields }, resolve)
    })
  }

  const params = {
    key: constants.googleMapsApiKey,
    ...(sessionToken ? { sessiontoken: sessionToken } : {}),
    place_id: placeId,
    fields
  }

  const response = await fetch(`https://maps.googleapis.com/maps/api/place/details/json?${util.encodeParams(params)}`)

  return (await response.json()) as google.maps.places.PlaceResult
}
