import { IOccupation } from '../models'
import { encodeToken } from '../routes'
import { Endpoint, get } from '../util/api'
import { defaultLocale, SupportedLocaleCode } from '../util/i18n'

export type GetOccupations = Endpoint<Record<string, never>, { success: true; occupations: IOccupation[] }>

export const getOccupations = (slugLocale: SupportedLocaleCode) => {
  const param = slugLocale !== defaultLocale ? `?slugLocale=${encodeToken(slugLocale)}` : ''
  return get<GetOccupations>(`/occupations${param}`)
}
