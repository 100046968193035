import { PositionTypeIndicators } from '@ambition/module-position'
import { compactAndJoin, encodeToken, formatCompensation, INewPosition, IPosition, IPositionPartial } from 'core'
import React, { useCallback, useMemo } from 'react'

import { IListItemProps, ListItem, Text, View } from '~/components'
import { useHistory } from '~/hooks'
import { TeamAvatar, useBreakpoints, useTranslation } from '~/lite'
import { emptyString } from '~/util'

import { IPositionListItemProps } from './types'

const getPositionInfo = (
  position: IPositionPartial | IPosition | INewPosition
): [string, string | null | undefined][] => [
  ['Location', position.location || 'Unknown'],
  [
    'Employment',
    position.allowEmployee
      ? position.employeeCompensation
        ? formatCompensation(position.employeeCompensation, 'Available')
        : 'Available'
      : 'Unavailable'
  ],
  [
    'Contract',
    position.allowContract
      ? position.contractCompensation
        ? formatCompensation(position.contractCompensation, 'Available')
        : 'Available'
      : 'Unavailable'
  ]
]

const nl2br = (content: string) => content.split('\n').map((item, i) => (i === 0 ? item : [<br />, item]))

export const PositionListItem: React.FC<IPositionListItemProps> = ({
  context,
  position,
  team,
  onTeamPress,
  summary = false,
  isSelected = false,
  color,
  collectionSlug,
  testID,
  openInNewWindow
}) => {
  const { isPhone } = useBreakpoints()
  const history = useHistory()
  const t = useTranslation()
  const hasId = 'id' in position

  const onTeamPressHandler = useCallback(() => {
    if (!hasId) {
      return
    }

    if (onTeamPress) {
      onTeamPress(position, team, false)
      return
    }

    if (team?.username) {
      history.push(`/teams/${encodeToken(team.username)}/jobs`)
    }
  }, [history, hasId, position, team, onTeamPress])

  const handleOnPress = useCallback(() => {
    if ('id' in position && !position.viewUrl) {
      history.push(`/jobs/${encodeToken(position.id)}`)
      return false
    }

    return undefined
  }, [position, history])

  const info = useMemo(() => getPositionInfo(position), [position])

  const accessoryLeft = useMemo<IListItemProps['accessoryLeft']>(
    () => (team ? props => <TeamAvatar team={team} onPress={onTeamPressHandler} size="small" {...props} /> : undefined),
    [team, onTeamPressHandler]
  )

  const postedAt = 'postedAt' in position ? position.postedAt : null

  const description = useMemo(() => {
    const info = compactAndJoin([position.location, postedAt ? t.getTimeAgo(postedAt) : null], ' · ')

    return (
      <View>
        <PositionTypeIndicators position={position} containerProps={{ marginY: 10 }} />
        <Text rawText={team ? team.name : emptyString} />
        {info && <Text rawText={info} />}
      </View>
    )
  }, [team, position, postedAt, t])

  return (
    <ListItem
      action={
        hasId
          ? {
              item: 'Position',
              context,
              positionId: position.id,
              teamId: team?.id ?? null,
              adNetwork: position.adNetwork ?? null
            }
          : { item: 'NewPosition', context, teamId: team?.id ?? null }
      }
      testID={testID}
      to={hasId ? position.viewUrl || `/jobs/${encodeToken(position.id)}` : undefined}
      onPress={handleOnPress}
      selected={isSelected}
      title={position.title}
      description={description}
      accessoryLeft={accessoryLeft}
      openInNewWindow={openInNewWindow}
    />
  )
}
/*
      {}
      <Content>
        <Header>
          {isWeb && (
            <>
              {info.map(([_, value], idx) => (
                <React.Fragment key={idx}>
                  {idx > 0 && <Text> &middot; </Text>}
                  <Text>{value}</Text>
                </React.Fragment>
              ))}
            </>
          )}
        </Header>
        <Body>{summary && position.summary && nl2br(position.summary)}</Body>
      </Content>
*/
