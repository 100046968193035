import { NewTeam } from '@ambition/module-form'
import React, { useEffect, useState } from 'react'

import { useScreenerContent, useTeams, useTeamSubscriptionBenefit } from '~/hooks'
import { Dialog, Loader, MetaTags, useStore } from '~/lite'

import { Faq } from '../Faq'
import { HowItWorks } from '../HowItWorks'
import { Pricing } from '../Pricing'
import { ContentWrap } from '../styles'
import { Demo } from './Demo'
import { Explainer } from './Explainer'
import { Hero } from './Hero'

interface IScreenerProps {
  locale: string
}

export const Screener: React.FC<IScreenerProps> = ({ locale }) => {
  const isColdEmail = locale === 'cold'
  const isLoadingUser = useStore(state => state.isLoadingUser)
  const { data: teams } = useTeams()
  const { data, isLoading: isLoadingContent } = useScreenerContent()
  const { data: teamSubscriptionPlan } = useTeamSubscriptionBenefit('screener', teams?.[0]?.id)
  const [isNewTeamModalOpen, setIsNewTeamModalOpen] = useState(false)
  const [selectedFreePlan, setSelectedFreePlan] = useState(false)
  const [subscriptionHandler, setSubscriptionHandler] = useState<(() => Promise<void>) | null>(null)

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoadingContent) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { metaTags, hero, explainer, demo, features, howItWorks, pricing, faq } = data.data

  return (
    <ContentWrap>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/screener" />
      <Hero data={hero} />
      <Explainer data={explainer} />
      <Demo data={demo} />
      {/*<Features data={features} />*/}
      <HowItWorks data={howItWorks} />
      <Pricing
        data={pricing}
        currentProductId={teamSubscriptionPlan?.metadata.stripeProductId ?? null}
        selectPlanText="Get started"
        onSelect={(subscriptionHandler, isFreePlan) => {
          if (isLoadingUser) {
            throw new Error('Tried to select a plan while loading user')
          }

          if (teams?.length) {
            subscriptionHandler()
          } else {
            setSubscriptionHandler(() => subscriptionHandler)
            setSelectedFreePlan(isFreePlan)
            setIsNewTeamModalOpen(true)
          }
        }}
      />
      <Faq data={faq} />
      {!teams?.length && (
        <Dialog
          open={isNewTeamModalOpen}
          onOpenChange={setIsNewTeamModalOpen}
          trigger={null}
          title="Create your team"
          description="Post jobs and access advanced hiring tools"
          small
        >
          <NewTeam
            onClose={() => setIsNewTeamModalOpen(false)}
            onSubmit={subscriptionHandler ?? undefined}
            skipRedirect={!selectedFreePlan}
            isColdEmail={isColdEmail}
          />
        </Dialog>
      )}
    </ContentWrap>
  )
}
