import { isEqual, isFunction } from 'lodash'
import { useEffect, useRef } from 'react'

import { trackEvent, ViewEventProperties } from '~/lite'

export const useScreenTracking = (
  properties: ViewEventProperties | (() => ViewEventProperties | null | false | void)
) => {
  const prevProperties = useRef<ViewEventProperties>()

  useEffect(() => {
    const newProperties = isFunction(properties) ? properties() : properties

    if (!newProperties) {
      return
    }

    if (!isEqual(prevProperties.current, newProperties)) {
      prevProperties.current = newProperties
      trackEvent('View', newProperties)
    }
  }, [properties])
}
