import { useEffect, useState } from 'react'

type PermissionName = 'microphone' | 'camera' | 'geolocation'
type PermissionState = 'granted' | 'denied' | 'prompt' | 'unknown'

export const usePermissions = (permissionName: PermissionName): PermissionState => {
  const [permission, setPermission] = useState<PermissionState>('unknown')

  useEffect(() => {
    if (!('permissions' in navigator)) {
      console.error('Permissions API is not available in this browser')
      return
    }

    navigator.permissions.query({ name: permissionName as any }).then(permissionStatus => {
      setPermission(permissionStatus.state)

      const handlePermissionChange = () => {
        setPermission(permissionStatus.state)
      }

      permissionStatus.onchange = handlePermissionChange

      return () => {
        // cleanup to avoid memory leaks
        permissionStatus.onchange = null
      }
    })
  }, [permissionName])

  return permission
}
