import { Button, css, Flex, FlexHorizontal, H1, styled } from '~/components'
import { Image } from '~/lite'
import { isWeb } from '~/util'

export const HeaderWrap = styled(FlexHorizontal)`
  margin-bottom: 10px;
  justify-content: space-between;
`

export const ImageHeader = styled(Image)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
`

export const Header = styled(Flex)`
  padding: 10px 5px;
`

export const LargeName = styled(H1)`
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: center;

  ${isWeb &&
  css`
    text-overflow: ellipsis;
  `};
`

export const AddPositionButton = styled(Button)`
  margin-left: 5px;
  background-color: transparent;
  position: absolute;
  border: none;
  height: 40px;
  padding: 0;
  right: 0;
  top: 0;
  bottom: 0;
`
