import React from 'react'
import type { Keyframes } from 'styled-components'

import { css, keyframes, styled } from '~/lite'

import { View } from '../View/View'
import { IGridListProps } from './types'

export type { IGridListProps }

const animations: Record<NonNullable<IGridListProps<any>['animation']>, Keyframes> = {
  fadeInUp: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, 10%, 0) scale(0.8);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1);
    }
  `
}

const GridContainer = styled(View)<{ $minItemWidth: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.$minItemWidth}px, 1fr));
`

/*
const GridItem = styled(View)<{ $aspectRatio?: number; $animation: IGridListProps<any>['animation'] }>`
  aspect-ratio: ${props => props.$aspectRatio ?? 'initial'};
  ${props =>
    props.$animation
      ? css`
          animation-name: ${animations[props.$animation]};
          animation-duration: 0.5s;
          animation-fill-mode: both;
        `
      : ''}
`*/

const GridItemInner = styled(View)<{
  $index: number
  $aspectRatio?: number
  $animation: IGridListProps<any>['animation']
}>`
  aspect-ratio: ${props => props.$aspectRatio ?? 'initial'};
  ${props =>
    props.$animation
      ? css`
          animation-name: ${animations[props.$animation]};
          animation-duration: 400ms;
          animation-fill-mode: both;
          animation-timing-function: ease-in-out;
          animation-delay: ${Math.min(props.$index, 40) * 20}ms;
        `
      : ''}
`

export function GridList<ItemT = any>({
  data,
  renderItem,
  minItemWidth,
  aspectRatio,
  giveFullRow,
  style,
  animation
}: IGridListProps<ItemT>): ReturnType<React.FC<IGridListProps<ItemT>>> {
  return (
    <GridContainer padding="s" style={style} $minItemWidth={minItemWidth}>
      {data.map((item, index) => (
        <View key={index}>
          <GridItemInner $index={index} $aspectRatio={aspectRatio} $animation={animation}>
            {renderItem({ item, index })}
          </GridItemInner>
        </View>
      ))}
    </GridContainer>
  )
}
