import {
  CollectionSlug,
  CompactCollectionRecord,
  ICollection,
  ICollectionHeader,
  IMapBounds,
  INewCollection,
  IRecordStyle,
  PageableResponse,
  Soc2018Code
} from '../models'
import { encodeToken } from '../routes'
import { Endpoint, get, post, put, remove } from '../util/api'
import { buildQueryString } from '../util/helpers'

export type GetCollection = Endpoint<
  { slug: CollectionSlug },
  {
    success: true
    collection: ICollection | null
    header: ICollectionHeader | null
    style: IRecordStyle | null
    mapBounds: IMapBounds | null
  }
>
export type GetCollections = Endpoint<Record<string, never>, { success: true; collections: ICollection[] }>
export type GetSpecialCollections = Endpoint<Record<string, never>, { success: true; collections: ICollection[] }>
export type DeleteCollection = Endpoint<{ id: ICollection['id'] }, { success: true }>
export type CreateCollection = Endpoint<
  Record<string, never>,
  { success: true; collection: ICollection },
  INewCollection
>
export type UpdateCollection = Endpoint<Record<string, never>, { success: true }, INewCollection>

export type GetCompactCollectionList = Endpoint<
  Record<string, never>,
  { success: true } & PageableResponse<CompactCollectionRecord>
>

export const getCollections = (soc2018Code?: Soc2018Code) => {
  let endPoint = '/collections'
  if (soc2018Code) {
    endPoint += `?soc2018Code=${soc2018Code}`
  }
  return get<GetCollections>(endPoint)
}

export const getSpecialCollections = () => get<GetSpecialCollections>('/collections/special')
export const getCountryCollections = () => get<GetCollections>('/collections/countries')
export const getPrivateCollections = () => get<GetCollections>('/collections/private')
export const getSkillCollections = () => get<GetCollections>('/collections/skills')

export const getSkillCollectionsQuery = () => ({
  queryKey: ['positions', 'skills'],
  queryFn: async () => {
    const results = await getSkillCollections()
    return results?.success ? results.collections : []
  }
})

export const getSpecialCollectionsQuery = () => ({
  queryKey: ['collections', 'special'],
  queryFn: async () => {
    const results = await getSpecialCollections()
    return results?.success ? results.collections : []
  }
})

export const getCollection = (slug: CollectionSlug) => get<GetCollection>(`/collections/${encodeToken(slug)}`)
export const createCollection = (collection: INewCollection) => post<CreateCollection>('/collections', collection)
export const updateCollection = (id: ICollection['id'], collection: INewCollection) =>
  put<UpdateCollection>(`/collections/${id}`, collection)
export const deleteCollection = (id: ICollection['id']) => remove<DeleteCollection>(`/collections/${id}`, {})

export const getCollectionRelevant = (page?: number, limit?: number) => {
  get<GetCompactCollectionList>(`/collections/recommendation${buildQueryString({ page, limit })}`)
}

export const getCollectionTrending = (pastDay?: number, page?: number, limit?: number) => {
  get<GetCompactCollectionList>(`/collections/trending${buildQueryString({ pastDay, page, limit })}`)
}

export const getCollectionQuery = (collectionSlug: CollectionSlug) => {
  const dedupedSlug = collectionSlug.split(',')[0] as CollectionSlug

  return {
    queryKey: ['collection', dedupedSlug],
    queryFn: async () => {
      const results = await getCollection(dedupedSlug)
      return results?.success
        ? { collection: results.collection, header: results.header, style: results.style, mapBounds: results.mapBounds }
        : null
    }
  }
}
