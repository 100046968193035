import React from 'react'

import { useLaunchpadContent, useMarketState } from '~/hooks'
import { Loader, MetaTags, styled, useStore, View } from '~/lite'

import { Faq } from '../Faq'
import { HowItWorks } from '../HowItWorks'
import { Hero } from './Hero'

const Card = styled(View)`
  background: ${props => (props.theme.dark ? '#000' : '#fff')};
  align-items: stretch;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
`

export interface ILaunchpadProps {
  setupComponent: React.ReactNode
}

export const Launchpad: React.FC<ILaunchpadProps> = ({ setupComponent }) => {
  const isAuthenticated = useStore(state => state.isAuthenticated)
  const isLoadingUser = useStore(state => state.isLoadingUser)
  const { data: content, isLoading: isLoadingContent } = useLaunchpadContent()
  const { isLoading: isLoadingState } = useMarketState()
  const initializingSetup = isAuthenticated && (isLoadingUser || isLoadingState)

  if (isLoadingContent || !content?.success) {
    return <View />
  }

  const { metaTags, hero, howItWorks, faq } = content.data

  return (
    <>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/launchpad" />
      <Card>
        <Hero data={hero}>{initializingSetup ? <Loader /> : setupComponent}</Hero>
      </Card>
      <Card>
        <HowItWorks data={howItWorks} id="explainer-how-it-works" variant="Card" />
      </Card>
      <Card>
        <Faq data={faq} id="explainer-how-it-works" variant="Card" />
      </Card>
    </>
  )
}
