import { devLocale, getBestAvailableLocale, getWebsiteLocale, setLocale as setCoreLocale } from 'core'
import { StateCreator } from 'zustand'

import { localize } from '../../localize'
import { II18nSlice, IStoreState } from './types'

const initialLocale =
  (typeof window !== 'undefined' ? getWebsiteLocale(window.location.pathname) : null) ??
  getBestAvailableLocale(localize.getLocaleCodes())

setCoreLocale(initialLocale)

export const createI18nSlice: StateCreator<IStoreState, [], [], II18nSlice> = (set, get) => ({
  locale: initialLocale,
  translations: { [devLocale]: null },

  setLocale: async locale => set(() => ({ locale })),

  addTranslation: async (locale, translation) =>
    set(() => ({ translations: { ...get().translations, [locale]: translation } }))
})
