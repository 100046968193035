import { IContentPreview } from 'core'
import React from 'react'

import { openURL, Pressable, styled } from '~/lite'

import { Image } from '../Image/Image'
import { Text } from '../Text/Text'
import { View } from '../View/View'

const Wrapper = styled(Pressable)`
  display: flex;
  flex: 1;
  flex-basis: max-content;
  align-content: center;
  justify-content: center;
  max-width: 50vw;
  height: 200px;
  border: 1px solid black;
  border-radius: 6px;
  margin-bottom: 10px;
`

interface URLPreviewProps {
  preview: IContentPreview
}

export const URLPreview: React.FC<URLPreviewProps> = ({ preview }) => {
  const { url, title, siteName, description, images, favicons } = preview

  return (
    <Wrapper testID={`url-preview-${url}`} onPress={() => openURL(url)}>
      {images?.length > 0 && (
        <View style={{ alignItems: 'center' }}>
          <Image source={images[0]} width={100} height={100} />
        </View>
      )}
      {title && <Text tag="h3" rawText={title} style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 10 }} />}
      {siteName && (
        <View style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingBottom: 10, paddingRight: 10 }}>
          {favicons && favicons.length > 0 && <Image source={favicons[0]} width={16} height={16} style={{}} />}
          <Text tag="h4" rawText={siteName} />
        </View>
      )}
      {description && (
        <Text
          tag="p"
          rawText={description}
          style={{
            marginTop: 2,
            flexWrap: 'wrap',
            padding: 10
          }}
        />
      )}
    </Wrapper>
  )
}
