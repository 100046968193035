import {
  IPosition,
  IScreenerCategory,
  IScreenerFilter,
  IScreenerInterviewQuestionRequest,
  IScreenerResume,
  IScreenerResumeCategoryResult,
  IScreenerResumeFilterResult,
  ITeam
} from '../models'
import { Endpoint, get, post, put } from '../util/api'
import { buildQueryString } from '../util/helpers'

export type GetLastPositionScreenerFilter = Endpoint<
  { positionId: IPosition['id'] },
  { success: true; data: IScreenerFilter }
>

export type GenerateScreenerFilters = Endpoint<
  Record<string, never>,
  { success: true; data: IScreenerFilter },
  {
    teamId?: ITeam['id']
    positionId?: IPosition['id']
    jobDescription: string
  }
>

export type GetScreenerCategories = Endpoint<Record<string, never>, { success: true; data: IScreenerCategory[] }>

export type GetScreenerResumesByPositionId = Endpoint<
  { positionId: IPosition['id'] },
  { success: true; data: IScreenerResume[] }
>

export type CreateScreenerResume = Endpoint<
  Record<string, never>,
  { success: true; screenerResumeId?: IScreenerResume['id']; error?: string },
  {
    fileUrl: string
    positionId: IPosition['id'] | null
    teamId: ITeam['id']
  }
>

export type GetScreenerInterviewQuestions = Endpoint<
  Record<string, never>,
  { success: true; data: IScreenerInterviewQuestionRequest[] }
>

export type GenerateScreenerInterviewQuestions = Endpoint<
  Record<string, never>,
  { success: true; error?: string },
  {
    teamId: ITeam['id']
    positionId: IPosition['id']
    screenerResumeId?: IScreenerResume['id']
  }
>

export type GenerateScreenerShareLink = Endpoint<
  Record<string, never>,
  { success: true; secret: string },
  {
    teamId: ITeam['id']
    positionId?: IPosition['id']
  }
>

export type AssociateUserWithSharedTeam = Endpoint<
  Record<string, never>,
  { success: true },
  {
    shareToken: string
  }
>

export type PromoteScreenerPosition = Endpoint<
  Record<string, never>,
  { success: true; error?: string },
  {
    positionId: IPosition['id']
    teamId: ITeam['id']
  }
>

export type AnalyzeScreenerResume = Endpoint<
  Record<string, never>,
  {
    success: true
    filterValues: IScreenerResumeFilterResult | null
    categoryResult: IScreenerResumeCategoryResult | null
    error?: string
  },
  {
    positionId: IPosition['id']
    teamId: ITeam['id']
    screenerResumeId: IScreenerResume['id']
  }
>

export type AnalyzeAllScreenerResumes = Endpoint<
  Record<string, never>,
  { success: true; numberOfCreditsUsed: number },
  {
    positionId: IPosition['id']
    teamId: ITeam['id']
  }
>

export type UpdateScreenerEmail = Endpoint<
  Record<string, never>,
  {
    success: true
    screenerEmail: string
  },
  {
    positionId: IPosition['id']
    teamId: ITeam['id']
    screenerEmail: string
  }
>

export type AssociateScreenerResumesToPosition = Endpoint<
  Record<string, never>,
  {
    success: true
  },
  {
    positionId: IPosition['id']
    teamId: ITeam['id']
    screenerResumeIds: IScreenerResume['id'][]
  }
>

export const getLastPositionScreenerFilter = (positionId: IPosition['id']) =>
  get<GetLastPositionScreenerFilter>(`/screener/positions/${positionId}`)

export const generateScreenerFilters = (data: GenerateScreenerFilters['body']) =>
  post<GenerateScreenerFilters>('/screener/generate-filters', data)

export const getScreenerCategories = () => get<GetScreenerCategories>('/screener/categories')

export const getScreenerResumesByPositionId = (positionId: IPosition['id']) =>
  get<GetScreenerResumesByPositionId>(`/screener/resume/positions/${positionId}`)

export const createScreenerResume = (data: CreateScreenerResume['body']) =>
  post<CreateScreenerResume>('/screener/resume', data)

export const getScreenerInterviewQuestions = ({
  teamId,
  positionId,
  screenerResumeId
}: {
  teamId: ITeam['id']
  positionId?: IPosition['id']
  screenerResumeId?: IScreenerResume['id']
}) =>
  get<GetScreenerInterviewQuestions>(
    `/screener/interview-questions${buildQueryString({ teamId, positionId, screenerResumeId })}`
  )

export const generateScreenerInterviewQuestions = (data: GenerateScreenerInterviewQuestions['body']) =>
  post<GenerateScreenerInterviewQuestions>('/screener/interview-questions', data)

export const generateScreenerShareLink = (data: GenerateScreenerShareLink['body']) =>
  post<GenerateScreenerShareLink>('/screener/share', data)

export const associateUserWithSharedTeam = (data: AssociateUserWithSharedTeam['body']) =>
  post<AssociateUserWithSharedTeam>('/screener/share/associate', data)

export const promoteScreenerPosition = (data: PromoteScreenerPosition['body']) =>
  post<PromoteScreenerPosition>('/screener/promote', data)

export const analyzeScreenerResume = (data: AnalyzeScreenerResume['body']) =>
  post<AnalyzeScreenerResume>('/screener/analyze', data)

export const analyzeAllScreenerResume = (data: AnalyzeAllScreenerResumes['body']) =>
  post<AnalyzeAllScreenerResumes>('/screener/analyze-all', data)

export const updateScreenerEmail = (data: UpdateScreenerEmail['body']) =>
  put<UpdateScreenerEmail>('/screener/email', data)

export const associateScreenerResumesToPosition = (data: AssociateScreenerResumesToPosition['body']) =>
  post<AssociateScreenerResumesToPosition>('/screener/associate-resume', data)
