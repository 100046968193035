import { ProfileAvatar } from '@ambition/module-shared'
import { ClientRoutePath, encodeToken } from 'core'
import React, { useCallback } from 'react'

import { FlexHorizontal, Icon, Image, Text, View } from '~/components'
import { useStore } from '~/hooks'
import { getTeamLogo, goldenRatio, styled, useHistory } from '~/lite'
import { constants, toText } from '~/util'

import { COMPANY_BANNER_SIZE, COMPANY_LOGO_SIZE } from './styles'
import { CompanyCardProps } from './types'

const Wrap = styled(View)`
  aspect-ratio: ${goldenRatio};
  box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px;
  transition: box-shadow 0.25s ease-in-out 0s, transform 0.25s ease 0s;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;

  &:hover {
    box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 12%) 0px 4px 16px;
    transform: translate3d(0px, -4px, 0);
  }
`

export const CompanyCard: React.FC<CompanyCardProps> = ({
  record,
  bannerHeight = COMPANY_BANNER_SIZE,
  avatarSize = COMPANY_LOGO_SIZE,
  ...rest
}) => {
  const { name, banner, logo, jobsCount, location, in5Words } = record
  const history = useHistory()
  const theme = useStore(state => state.theme)
  const to: ClientRoutePath = `/teams/${encodeToken(record.username)}/jobs`

  const onPress = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      history.push(to)
    },
    [history, to]
  )

  return (
    <Wrap
      className="company-card"
      backgroundColor={theme.cardBackground}
      paddingBottom="m"
      borderRadius={10}
      margin="s"
      {...rest}
    >
      <View
        width="100%"
        flex={2}
        maxHeight={bannerHeight}
        backgroundColor={theme.placeholder}
        borderTopLeftRadius={10}
        borderTopRightRadius={10}
      >
        {banner ? (
          <Image
            source={`${constants.assetServerUrl}/team-banners/${banner}`}
            width="100%"
            height="100%"
            backgroundSize="cover"
            borderTopLeftRadius={10}
            borderTopRightRadius={10}
          />
        ) : null}
      </View>
      <FlexHorizontal marginX="s" justifyContent="space-between">
        <View marginTop={-avatarSize / 2}>
          <ProfileAvatar name={name || ''} size={avatarSize} avatar={getTeamLogo(logo)} />
        </View>
        {jobsCount ? (
          <FlexHorizontal alignItems="center" marginTop="xs">
            <Text rawText={toText(jobsCount)} fontWeight="bold" fontSize={12} marginRight="xxs" />
            <Text text="Jobs available" fontSize={10} />
          </FlexHorizontal>
        ) : null}
      </FlexHorizontal>
      <View flex={1} paddingTop="s" paddingX="m">
        {name ? (
          <Text href={to} onPress={onPress} rawText={name} numberOfLines={1} ellipsizeMode="tail" fontWeight="bold" />
        ) : null}
        {in5Words ? (
          <Text rawText={in5Words} numberOfLines={2} ellipsizeMode="tail" marginTop="xs" fontSize={12} />
        ) : null}
        {location ? (
          <FlexHorizontal alignItems="center" marginTop="xs">
            <Icon icon="location" marginRight="xs" color={theme.primary} size={12} />
            <Text rawText={location} numberOfLines={1} ellipsizeMode="tail" fontSize={12} />
          </FlexHorizontal>
        ) : null}
      </View>
    </Wrap>
  )
}
