import { markTranslated, SupportedLocaleCode, TranslatedText } from './i18n'

export const getNumberFns = (locale: SupportedLocaleCode) => {
  const formatNumber = (value: number): TranslatedText => {
    try {
      return markTranslated(new Intl.NumberFormat(locale).format(value))
    } catch {
      return markTranslated(`${value}`)
    }
  }

  return { formatNumber }
}

export type NumberFns = ReturnType<typeof getNumberFns>
