import { IChannel, IMessage } from 'core'
import { Draft, produce } from 'immer'
import { groupBy } from 'lodash'
import { create } from 'zustand'

interface IMessageStoreState {
  channels: Record<IChannel['id'], IChannel>
  messages: Record<IChannel['id'], Record<IMessage['id'], IMessage>>
  setChannels(channels: IChannel[]): void
  addChannel(channel: IChannel): void
  addMessage(message: IMessage): void
  addMessages(messages: IMessage[]): void
}

export const useMessageStore = create<IMessageStoreState>(set => ({
  channels: {},
  messages: {},
  setChannels: channels =>
    set(() => ({ channels: channels.reduce((acc, channel) => ({ ...acc, [channel.id]: channel }), {}) })),
  addChannel: channel =>
    set(state =>
      produce(state, draft => {
        draft.channels[channel.id] = channel as Draft<IChannel>

        if (channel.replaces && draft.channels[channel.replaces]) {
          delete draft.channels[channel.replaces]
        }
      })
    ),
  addMessage: message =>
    set(state =>
      produce(state, draft => {
        if (
          draft.channels[message.channelId] &&
          message.createdAt > (draft.channels[message.channelId].lastMessage?.createdAt ?? 0)
        ) {
          draft.channels[message.channelId].lastMessage = message as Draft<IMessage>
        }

        if (!draft.messages[message.channelId]) {
          draft.messages[message.channelId] = {}
        }

        if (message.replaces && draft.messages[message.channelId][message.replaces]) {
          delete draft.messages[message.channelId][message.replaces]
        }

        draft.messages[message.channelId][message.id] = message as Draft<IMessage>
      })
    ),
  addMessages: messages =>
    set(state =>
      produce(state, draft => {
        const byChannel = groupBy(messages, message => message.channelId)

        for (const key in byChannel) {
          const channelId = key as IChannel['id']

          if (draft.channels[channelId]) {
            const lastMessage = messages.reduce(
              (acc, message) => ((acc?.createdAt ?? 0) > message.createdAt ? acc : message),
              null as IMessage | null
            )

            if (lastMessage && lastMessage.createdAt > (draft.channels[channelId].lastMessage?.createdAt ?? 0)) {
              draft.channels[channelId].lastMessage = lastMessage as Draft<IMessage>
            }
          }

          if (!draft.messages[channelId]) {
            draft.messages[channelId] = {}
          }

          for (const message of byChannel[channelId]) {
            if (message.replaces && draft.messages[channelId][message.replaces]) {
              delete draft.messages[channelId][message.replaces]
            }

            draft.messages[channelId][message.id] = message as Draft<IMessage>
          }
        }
      })
    )
}))
