import { useCallback, useState } from 'react'

export const useLayout = () => {
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 })

  // @todo implement on web if we want this
  // const onLayout = useCallback(e => setLayout(e.nativeEvent.layout), [])
  const onLayout = useCallback(() => {}, [])

  return { onLayout, ...layout }
}
