import { subscribeToSubscriptionPlan } from '@ambition/module-shared'
import { formatCurrency, IPricingContent, IStripePrice, IStripeProduct, TranslatedText, UntranslatedText } from 'core'
import React, { useCallback, useState } from 'react'

import { breakpoint, css, H3, Icon, styled, Text, trackEvent, useStore, useTranslation, View } from '~/lite'

import { StripeClimateIcon } from './styles'
import { backgrounds, HighlightedButton, Section, SectionContent, SectionTitle } from './styles'

const ToggleWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  gap: 8px;
`

const ToggleContainer = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 2px;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  overflow: hidden;
  background: ${props => props.theme.cardBackground};
  white-space: nowrap;
  border: 1px solid ${props => props.theme.border};

  input {
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0px;
    vertical-align: middle;
  }
`

const ToggleBackground = styled.div`
  background-color: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.05)' : props.theme.buttonBackground)};
  position: absolute;
  inset: 0px;
  transition: all 0.15s ease 0s;
  border-radius: 32px;

  input:checked + &::before {
    transform: translateX(calc(100% - 4px));
  }

  &::before {
    background: ${props => props.theme.primary};
    border-radius: 23px;
    top: 2px;
    bottom: 2px;
    content: '';
    left: 2px;
    position: absolute;
    transition: all 0.15s ease 0s;
    width: 50%;
  }
`

const Toggle = styled.div<{ $active: boolean }>`
  flex: 1;
  padding: 8px 16px;
  color: ${props => (props.$active ? 'white' : props.theme.text)};
  text-align: center;
`

const AnnualDiscount = styled(Text)`
  padding: 6px 8px;
  border-radius: 4px;
  background: ${props => props.theme.backgroundContrast};
  font-size: 12px;
`

const Assurances = styled(View)`
  align-items: center;
  gap: 16px;
  margin: 16px 8px 0;

  @media ${breakpoint.md} {
    flex-direction: row;
    margin: 32px auto 0;
  }
`

const Assurance = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const Plan = styled(View)`
  position: relative;
  flex: 1;
  margin: 8px 8px 0;
  padding: 24px 16px 16px;
  border-radius: 12px;
  border-width: 1px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  text-align: center;

  @media ${breakpoint.md} {
    margin: 0;
    max-width: 340px;
  }
`

const PlanName = styled(H3)`
  font-size: 28px;
  text-align: center;
`

const PlanPrice = styled(Text)`
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px;
`

const MostPopular = styled(Text)`
  position: absolute;
  top: -16px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 8px 16px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 1);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.2), 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
`

const Features = styled(View)`
  flex: 1;
  margin: 16px auto;
`

const FeatureWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 8px 20px 8px 0;
  text-align: initial;
  hyphens: none;
`

const SelectButton = styled(HighlightedButton)`
  margin-top: 32px;
`

const Plans = styled(View)<{ $compact: boolean }>`
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
  margin-top: 8px;

  @media ${breakpoint.md} {
    flex-direction: row;
    align-items: stretch;
    margin-top: 48px;

    ${props =>
      props.$compact &&
      css`
        gap: 8px;

        ${Plan} {
          padding-left: 8px;
          padding-right: 8px;
        }

        ${FeatureWrap} {
          padding-right: 0;
        }

        ${SelectButton} {
          margin-top: 0;
        }
      `}
  }
`

const ClimateCard = styled(View)`
  flex-direction: row;
  align-items: center;
  margin: 16px 8px 0;
  padding: 8px 32px 8px 16px;
  border-radius: 100px;
  border-width: 1px;
  background: ${props => props.theme.cardBackground};

  @media ${breakpoint.md} {
    padding-right: 16px;
    margin: 32px auto 0;
  }
`

interface IFeatureProps {
  text: TranslatedText
}

const Feature: React.FC<IFeatureProps> = ({ text }) => (
  <FeatureWrap>
    <Icon icon="checkmark-outline" color="#6360ff" marginRight={8} />
    <Text rawText={text} fontSize={16} />
  </FeatureWrap>
)

const checkIfFreePlan = (productId: IStripeProduct['id'] | null) => !!productId?.startsWith('free')

interface IPricingProps {
  data: IPricingContent
  currentProductId: IStripeProduct['id'] | null
  hideTitle?: boolean
  hideFreePlan?: boolean
  selectPlanText?: UntranslatedText
  onSelect?(subscriptionHandler: () => Promise<void>, isFreePlan: boolean): void
}

export const Pricing: React.FC<IPricingProps> = ({
  data,
  currentProductId,
  hideTitle,
  hideFreePlan,
  selectPlanText,
  onSelect
}) => {
  const darkMode = useStore(state => state.darkMode)
  const locale = useStore(state => state.locale)
  const t = useTranslation()

  const [isAnnual, setIsAnnual] = useState(false)
  const [isProcessingProductId, setIsProcessingProductId] = useState<IStripeProduct['id'] | null>(null)

  const onSubscribe = useCallback(
    (productId: IStripeProduct['id'], monthlyPriceId: IStripePrice['id'], annualPriceId: IStripePrice['id']) => {
      const isFreePlan = checkIfFreePlan(currentProductId)
      if (isProcessingProductId || (currentProductId && !isFreePlan)) {
        return
      }
      if (checkIfFreePlan(productId)) {
        return async () => {
          trackEvent('Select', { item: 'Product', productId, isFreePlan: true })
        }
      }
      if (isAnnual) {
        return async () => {
          trackEvent('Select', { item: 'Product', productId, isAnnual })
          setIsProcessingProductId(productId)
          await subscribeToSubscriptionPlan(productId, annualPriceId)
        }
      }

      return async () => {
        trackEvent('Select', { item: 'Product', productId, isAnnual })
        setIsProcessingProductId(productId)
        await subscribeToSubscriptionPlan(productId, monthlyPriceId)
      }
    },
    [isAnnual, isProcessingProductId, currentProductId]
  )

  /*
  @todo add add usage panel on dashboard (simplifying for i18n for now)
  const activeTeamPlanStatistic = useMemo<TranslatedText[]>(() => {
    if (!subscriptionPlan || !('numberOfInterviews' in subscriptionPlan.benefits)) {
      return []
    }
    return [
      `${Math.max(0, subscriptionPlan.benefits.numberOfJobPosts)} jobs left`,
      `${Math.max(0, subscriptionPlan.benefits.numberOfInterviews)} interviews left`,
      `$${subscriptionPlan.benefits.additionalInterviewsPrice} per additional interview`
    ] as TranslatedText[]
  }, [subscriptionPlan])
  */

  const isFreePlan = currentProductId?.startsWith('free')

  return (
    <Section id="pricing" style={darkMode ? { background: backgrounds.blue } : undefined}>
      <SectionContent $allowWide={(data.plans?.length ?? 0) > 3}>
        {!hideTitle && <SectionTitle rawText={data.header} />}
        <ToggleWrap>
          <ToggleContainer>
            <input type="checkbox" tabIndex={0} checked={isAnnual} onChange={e => setIsAnnual(!!e.target.checked)} />
            <ToggleBackground />
            <Toggle $active={!isAnnual}>{t('Monthly')}</Toggle>
            <Toggle $active={isAnnual}>{t('Annual')}</Toggle>
          </ToggleContainer>
          <AnnualDiscount rawText={data.annualDiscount} />
        </ToggleWrap>
        <Plans $compact={(data.plans?.length ?? 0) > 3}>
          {data.plans
            ?.filter(plan => !hideFreePlan || !plan.productId.startsWith('free'))
            .map(
              (
                {
                  name,
                  monthlyPrice,
                  monthlyPriceWhenAnnual,
                  mostPopular,
                  features,
                  productId,
                  monthlyPriceId,
                  annualPriceId
                },
                idx
              ) => (
                <Plan key={idx}>
                  <PlanName rawText={name} />
                  <PlanPrice
                    rawText={t('{{amount}} / month', {
                      amount: formatCurrency(
                        locale,
                        isAnnual ? monthlyPriceWhenAnnual.currency : monthlyPrice.currency,
                        isAnnual ? monthlyPriceWhenAnnual.amount : monthlyPrice.amount,
                        Number.isInteger(isAnnual ? monthlyPriceWhenAnnual.amount : monthlyPrice.amount)
                      ) as TranslatedText
                    })}
                  />
                  {!!mostPopular && <MostPopular text="Most popular" />}
                  <Features>
                    {features.map((feature, idx) => (
                      <Feature key={idx} text={feature} />
                    ))}
                  </Features>
                  <SelectButton
                    text={currentProductId === productId ? 'Active' : selectPlanText ?? 'Upgrade'}
                    primary={!currentProductId || currentProductId === productId || isFreePlan}
                    loading={isProcessingProductId === productId}
                    onPress={() => {
                      const handler = onSubscribe(productId, monthlyPriceId, annualPriceId)

                      if (handler) {
                        onSelect ? onSelect(handler, checkIfFreePlan(productId)) : handler()
                      }
                    }}
                    disabled={(!!currentProductId && !isFreePlan) || currentProductId === productId}
                    skipTracking
                  />
                </Plan>
              )
            )}
        </Plans>
        <Assurances>
          {data.assurances.map((assurance, idx) => (
            <Assurance key={idx}>
              <Icon icon="checkmark" color="#6360ff" size={24} />
              <Text rawText={assurance.header} fontWeight="bold" opacity={0.8} fontSize={15} />
            </Assurance>
          ))}
        </Assurances>
        <ClimateCard>
          <View width={24} marginRight={8}>
            <StripeClimateIcon />
          </View>
          <Text rawText={data.climate} />
        </ClimateCard>
      </SectionContent>
    </Section>
  )
}
