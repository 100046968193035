import type { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types'
import { create } from 'zustand'

type UseAssistantBottomSheetStoreProps = {
  assistantMessageSheetRef: React.RefObject<BottomSheetMethods> | null
  setAssistantMessageSheetRef: (ref: React.RefObject<BottomSheetMethods> | null) => void
}

export const useAssistantBottomSheetStore = create<UseAssistantBottomSheetStoreProps>(set => ({
  assistantMessageSheetRef: null,
  setAssistantMessageSheetRef: ref => set(() => ({ assistantMessageSheetRef: ref }))
}))
