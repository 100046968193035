import { Community } from '@ambition/module-shared'
import { IScreenerContent } from 'core'

import { View } from '~/lite'

import { ActionButton, HeaderSubtitle, HeaderTag, HeaderTitle, HeroWrap, SectionContent } from '../styles'

interface IHeroProps {
  data: IScreenerContent['hero']
}

export const Hero: React.FC<IHeroProps> = ({ data }) => {
  const onActionButtonPress = () => {
    const target = document.getElementById('pricing')
    target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  return (
    <HeroWrap>
      <SectionContent>
        <HeaderTag rawText={data.headerTag} />
        <HeaderTitle rawText={data.header} />
        <HeaderSubtitle rawText={data.subheader} />
        <View flexDirection="row" alignItems="stretch" justifyContent="center" paddingBottom={32}>
          <ActionButton onPress={onActionButtonPress} rawText={data.cta} $enter primary skipTracking large />
        </View>
        <Community compact />
      </SectionContent>
    </HeroWrap>
  )
}
