import React from 'react'
import { color, layout, space } from 'styled-system'

import { useStore } from '../hooks'
import { getStyledConfig, styled } from '../styled'
import { Svg } from './Svg'
import { IconStyledProps, IIconProps } from './types'

export const getIonicon = (name: string): string | null => `https://ambitioncdn.com/icons/ionicons/${name}.svg`

export const InnerIcon: React.FC<IIconProps> = ({ testID, icon, primary, onPress, size = 20, ...props }) => (
  <Svg testID={testID} uri={getIonicon(icon)} {...props} width={size ?? 20} height={size ?? 20} onPress={onPress} />
)

export const StyledIcon = styled(InnerIcon).withConfig(getStyledConfig(['color']))<IconStyledProps>`
  ${color}
  ${layout}
  ${space}
`

export const Icon: React.FC<IIconProps> = props => {
  const theme = useStore(state => state.theme)
  return <StyledIcon {...props} color={props.color ?? (props.primary ? theme.primary : theme.inputPlaceholder)} />
}
