import { TranslatedText, UntranslatedText } from 'core'
import React from 'react'

import { Button, IButtonTrackingProps } from '../Button/Button'

export type ITagProps = {
  onPress?(): void
  onRemove?(): void
  text?: UntranslatedText
  rawText?: TranslatedText
  primary?: boolean
} & IButtonTrackingProps

export const Tag: React.FC<ITagProps> = ({ text, rawText, onRemove, onPress, ...rest }) => (
  <Button
    rightIcon="remove-circle"
    text={text}
    rawText={rawText}
    onRightIconPress={onRemove}
    onPress={onPress}
    margin="xxs"
    {...rest}
  />
)
