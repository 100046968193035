import type { AnimationItem } from 'lottie-web'
import React, { useMemo, useRef, useState } from 'react'

import { View } from '~/lite'

import aiAssistant from '../../assets/lottie/ai-assistant.json'
import aiAssistantAvatar from '../../assets/lottie/ai-assistant-avatar.json'
import checkCircle from '../../assets/lottie/check-circle.json'
import emailReceived from '../../assets/lottie/email-received.json'
import errorCircle from '../../assets/lottie/error-circle.json'
import remoteWorking from '../../assets/lottie/remote-working.json'
import robot from '../../assets/lottie/robot.json'
import { AnimationType, LottieProps } from './types'

export type { LottieProps }

const animations: Record<AnimationType, object> = {
  robot,
  remoteWorking,
  checkCircle,
  emailReceived,
  errorCircle,
  aiAssistant,
  aiAssistantAvatar
}

export const Lottie = React.forwardRef<any, LottieProps>(
  ({ animation, loop = true, autoPlay = true, onLoad, width, height, style }, ref) => {
    const animationContainer = useRef<HTMLDivElement>(null)
    const [lottieAnimation, setLottieAnimation] = useState<AnimationItem | null>(null)

    useMemo(async () => {
      const lottie = (await import('lottie-web')).default

      setTimeout(() => {
        if (animationContainer.current && !lottieAnimation) {
          const newAnimation = lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animations[animation],
            renderer: 'svg',
            loop,
            autoplay: autoPlay
          })

          if (onLoad) {
            const callOnLoad = () => onLoad?.({ play: () => newAnimation.play() })
            newAnimation.isLoaded ? callOnLoad() : newAnimation.addEventListener('DOMLoaded', callOnLoad)
          }

          setLottieAnimation(newAnimation)
        }
      }, 100)
    }, [animation, loop, autoPlay, onLoad, lottieAnimation])

    return <View style={[style, { width, height }]} ref={ref || animationContainer} />
  }
)
