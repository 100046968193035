import { IMarketPerson, IMarketProfile, INewMarketPerson, profileWebsites, TranslatedText } from 'core'
import { keyBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, UseFieldArrayReturn, useForm } from 'react-hook-form'

import { FormGroup, Input, Tooltip } from '~/components'
import { useCreateMarketPerson, useDeleteMarketPerson, useUpdateMarketPerson } from '~/hooks'
import { Button, Dialog, Icon, IDialogProps, styled, toast, useStore, View } from '~/lite'

const profileWebsitesByCode = keyBy(profileWebsites, website => website.code)

const Row = styled(View)`
  flex-direction: row;
  gap: 8px;
`

const WebsiteButtons = styled(View)`
  flex-direction: row;
  gap: 8px;
`

const WebsiteButton = styled(Button)`
  background: none;
  opacity: 0.8;

  &:hover {
    background: none;
    opacity: 1;
  }
`

const DeleteIcon = styled(Icon)`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

const WebsiteInputs = styled(View)`
  gap: 8px;
`

interface IPersonDialogProps extends Omit<IDialogProps, 'trigger' | 'children'> {
  people: UseFieldArrayReturn<IMarketProfile, 'people', 'id'>
  person: IMarketPerson | INewMarketPerson
  onClose(): void
}

export const PersonDialog: React.FC<IPersonDialogProps> = ({ people, person, onClose, ...props }) => {
  const theme = useStore(state => state.theme)
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const isNewForm = !('id' in person)

  const personIndex = isNewForm ? -1 : people.fields.findIndex(item => item.id === person.id)

  const { mutateAsync: createPerson, isLoading: isCreatingPerson } = useCreateMarketPerson({
    onSuccess: data => {
      if (data.success) {
        people.append(data.person)
        onClose()
        toast.success('Added record')
      }
    }
  })

  const { mutateAsync: updatePerson, isLoading: isUpdatingPerson } = useUpdateMarketPerson({
    onSuccess: data => {
      if (data.success) {
        people.update(personIndex, data.person)
        onClose()
        toast.success('Updated record')
      }
    }
  })

  const { mutateAsync: deletePerson, isLoading: isDeletingPerson } = useDeleteMarketPerson({
    onSuccess: data => {
      if (data.success) {
        people.remove(personIndex)
        onClose()
        toast.success('Deleted record')
      }
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IMarketPerson>()

  const { replace: replaceWebsites, ...websites } = useFieldArray({ control, name: 'websites' })

  useEffect(() => {
    const resetForm = (person: IMarketPerson | INewMarketPerson) => {
      reset(person)

      // useFieldArray doesn't seem to update when useForm reset is called, so we need to manually update it
      replaceWebsites((person.websites as any) ?? [])
    }

    resetForm(person)
    setIsConfirmingDelete(false)
  }, [reset, person, replaceWebsites])

  const existingCodes = websites.fields.map(website => website.code)
  const otherWebsites = profileWebsites.filter(website => !existingCodes.includes(website.code))

  const disableActions = isCreatingPerson || isUpdatingPerson || isDeletingPerson

  return (
    <Dialog
      {...props}
      trigger={null}
      title={isNewForm ? 'Add person' : 'Edit person'}
      disableAutoFocus={!isNewForm}
      footer={
        <>
          {!isNewForm && (
            <>
              {/*<Button
                text={isConfirmingDelete ? 'Confirm Delete' : 'Delete'}
                disabled={disableActions}
                loading={isDeletingPerson}
                onPress={() => {
                  if (!isConfirmingDelete) {
                    setIsConfirmingDelete(true)
                  } else {
                    deletePerson(person.id)
                  }
                }}
                skipTracking
                dangerous
              />*/}
              <View flex={1} />
            </>
          )}
          <Button text="Cancel" onPress={onClose} disabled={disableActions} skipTracking />
          <Button
            text="Save"
            onPress={handleSubmit(values => (isNewForm ? createPerson(values) : updatePerson(values)))}
            disabled={disableActions}
            loading={isCreatingPerson || isUpdatingPerson}
            skipTracking
            primary
          />
        </>
      }
    >
      <Row>
        <View flex={1}>
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <FormGroup label="First name" helperText={errors.firstName?.message} style={{ marginBottom: 0 }}>
                <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
              </FormGroup>
            )}
          />
        </View>
        <View flex={1}>
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <FormGroup label="Last name" helperText={errors.lastName?.message} style={{ marginBottom: 0 }}>
                <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
              </FormGroup>
            )}
          />
        </View>
      </Row>
      <Controller
        control={control}
        name="headline"
        render={({ field }) => (
          <FormGroup label="Headline" helperText={errors.headline?.message} style={{ marginBottom: 0 }}>
            <Input {...field} wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }} />
          </FormGroup>
        )}
      />
      {otherWebsites.length > 0 && (
        <WebsiteButtons>
          {otherWebsites.map(({ code, name, icon }) => (
            <Tooltip key={code} rawText={name as TranslatedText} delay={0}>
              <WebsiteButton
                icon={icon}
                iconSize={24}
                size={24}
                onPress={() => websites.append({ code, url: '' })}
                skipTracking
              />
            </Tooltip>
          ))}
        </WebsiteButtons>
      )}
      <WebsiteInputs>
        {websites.fields.map((field, index) => (
          <Controller
            key={field.id}
            control={control}
            name={`websites.${index}.url`}
            render={({ field: urlField }) => (
              <Input
                accessoryLeft={<Icon icon={profileWebsitesByCode[field.code].icon} size={24} />}
                accessoryRight={<DeleteIcon icon="close-circle" size={24} onPress={() => websites.remove(index)} />}
                wrapStyle={{
                  backgroundColor: theme.background,
                  borderColor: theme.border,
                  paddingLeft: 8,
                  paddingRight: 8
                }}
                {...urlField}
              />
            )}
          />
        ))}
      </WebsiteInputs>
      <Controller
        control={control}
        name="bio"
        render={({ field }) => (
          <FormGroup helperText={errors.bio?.message} style={{ marginBottom: 0 }}>
            <Input
              type="textarea"
              numberOfLines={4}
              placeholder="About"
              autoGrow
              {...field}
              wrapStyle={{ backgroundColor: theme.background, borderColor: theme.border }}
            />
          </FormGroup>
        )}
      />
    </Dialog>
  )
}
