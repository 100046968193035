import {
  IAgentCommand,
  IChannel,
  IChannelMember,
  IContentPreview,
  IMessage,
  IMessageAttachment,
  IPosition,
  ITeam,
  IUser
} from '../models'
import { Endpoint, get, getFetchParams, post } from '../util/api'
import { JobScope } from '../util/query'

export type ChannelType = 'Assistant'

export type NewChannel = { positionId: IPosition['id'] } | { teamId: ITeam['id'] } | { type: ChannelType }

export type GetChannels = Endpoint<Record<string, never>, { success: true; channels: IChannel[] }>

export type GetChannel = Endpoint<
  { channelId: IChannel['id'] } | NewChannel,
  { success: true; channel: IChannel | null }
>

export type CreateChannel = Endpoint<Record<string, never>, { success: true; channel: IChannel }, NewChannel>

export type GetTeamChannels = Endpoint<{ teamId: ITeam['id'] }, { success: true; channels: IChannel[] }>

export type GetChannelMembers = Endpoint<
  { channelId: IChannel['id'] },
  { success: true; members: (IChannelMember & { team: ITeam | null; user: IUser | null })[] }
>

export type GetChannelMessages = Endpoint<{ channelId: IChannel['id'] }, { success: true; messages: IMessage[] }>

export type SendMessage = Endpoint<
  { stream?: 'stream' },
  { success: true; messages: IMessage[]; channels?: IChannel[] },
  {
    message: IMessage['message']
    attachmentUrl?: IMessage['attachmentUrl']
    ghostId?: IMessage['id']
    channelGhostId?: IChannel['id']
    assistantGhostId?: IMessage['id']
    greeting?: IMessage['message']
    scope?: JobScope
  } & ({ channelId: IMessage['channelId'] } | { channel: NewChannel })
>

export type SendMessageStreamResponse =
  | SendMessage['response']
  | { messageId: IMessage['id']; chunk: string; commands?: IAgentCommand[]; attachments?: IMessageAttachment[] }

export type SendMessageToAssistant = Endpoint<
  Record<string, never>,
  { success: true; message: IMessage; reply: IMessage },
  { message: IMessage['message']; attachmentUrl?: IMessage['attachmentUrl'] }
>

export type GetURLPreview = Endpoint<
  Record<string, never>,
  { success: true; previews: (IContentPreview | null)[] },
  { urls: string[] }
>

export type GenerateSpeechFromText = Endpoint<{ stream?: 'stream' }, { success: true }, { text: string }>

export const getChannels = () => get<GetChannels>('/messaging/channels')

export const getChannelById = (channelId: IChannel['id']) => get<GetChannel>(`/messaging/channels/${channelId}`)

export const getChannelByPosition = (positionId: IPosition['id']) =>
  get<GetChannel>(`/messaging/channels/positions/${positionId}`)

export const getChannelByTeam = (teamId: ITeam['id']) => get<GetChannel>(`/messaging/channels/teams/${teamId}`)

export const getChannelByType = (type: ChannelType) => get<GetChannel>(`/messaging/channels/types/${type}`)

export const createChannel = (data: CreateChannel['body']) => post<CreateChannel>('/messaging/channels', data)

export const getTeamChannels = (teamId: ITeam['id']) => get<GetTeamChannels>(`/messaging/teams/${teamId}/channels`)

export const getChannelMembers = (channelId: IChannel['id']) =>
  get<GetChannelMembers>(`/messaging/channels/${channelId}/members`)

export const getChannelMessages = (channelId: IChannel['id']) =>
  get<GetChannelMessages>(`/messaging/channels/${channelId}/messages`)

export const sendMessage = (data: SendMessage['body']) => post<SendMessage>('/messaging/messages', data)

export const getURLPreview = (urls: string[]) => post<GetURLPreview>('/messaging/preview', { urls })

export const generateSpeech = async (text: string) => {
  const response = await fetch(
    ...getFetchParams<GenerateSpeechFromText>('POST', '/messaging/speech', { data: { text } })
  )
  return response.blob()
}
