import { IMarketProduct, IMarketProfile } from 'core'
import React from 'react'

import { breakpoint, keyframes, styled, Text, View } from '~/lite'

import { About } from './About'
import { Deliverables } from './Deliverables'
import { Faq } from './Faq'
import { Hero } from './Hero'
import { Navigation } from './Navigation'
import { Plans } from './Plans'
import { Products } from './Products'
import { getSectionVisibilities } from './shared'
import { Testimonials } from './Testimonials'

const raysAnimation = keyframes`
  from {
    background-position: 50% 50%, 50% 50%;
  }
  to {
    background-position: 350% 50%, 350% 50%;
  }
`

const Rays = styled.div`
  position: absolute;
  inset: 0px;
  overflow: hidden;
`

const RaysInner = styled.div`
  position: absolute;
  inset: -10px;
  opacity: 0.2;
  pointer-events: none;
  --stripes: repeating-linear-gradient(100deg, #fff 0%, #fff 7%, transparent 10%, transparent 12%, #fff 16%);
  --stripesDark: repeating-linear-gradient(100deg, #000 0%, #000 7%, transparent 10%, transparent 12%, #000 16%);
  --rainbow: repeating-linear-gradient(100deg, #60a5fa 10%, #e879f9 15%, #60a5fa 20%, #5eead4 25%, #60a5fa 30%);
  background-image: var(${props => (props.theme.dark ? '--stripesDark' : '--stripes')}), var(--rainbow);
  background-size: 300%, 200%;
  background-position: 50% 50%, 50% 50%;
  filter: ${props => (props.theme.dark ? 'blur(10px) opacity(50%) saturate(200%)' : 'blur(10px) invert(100%)')};
  mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

  &:after {
    display: block;
    content: '';
    position: absolute;
    inset: 0;
    background-image: var(${props => (props.theme.dark ? '--stripesDark' : '--stripes')}), var(--rainbow);
    background-size: 200%, 100%;
    background-attachment: fixed;
    mix-blend-mode: difference;
  }
`
/*
  // disabled all animations for now
  // animations in safari are very slow, so only enabling on desktop
  @media ${breakpoint.lg} {
    &:after {
      animation: ${raysAnimation} 60s linear infinite;
    }
  }
*/

const Wrap = styled(View)`
  flex-direction: row;
  justify-content: center;
  background: ${props => (props.theme.dark ? '#000' : props.theme.background)};
`

const Inner = styled(View)`
  width: 100%;
  gap: 16px;
  padding-top: 60px;

  @media ${breakpoint.md} {
    padding-top: 76px;
  }
`

const MostPopular = styled(Text)`
  position: absolute;
  top: -16px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 8px 16px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 1);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.2), 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
`

export const xLogo = (
  <svg viewBox="0 0 24 24" width="30" height="30">
    <g>
      <path
        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
        fill="#5c7080"
      />
    </g>
  </svg>
)

interface IProfileProps {
  profile: IMarketProfile
  variant?: 'Launchpad' | 'Example'
  isSidebarOpen?: boolean
  handleEditProduct?(product: IMarketProduct): void
  handleBuyProduct?(product: IMarketProduct): boolean
  handleCalendarBooking?(): boolean
}

export const Profile: React.FC<IProfileProps> = ({
  profile,
  variant,
  isSidebarOpen,
  handleEditProduct,
  handleBuyProduct,
  handleCalendarBooking
}) => {
  const sections = getSectionVisibilities(profile)

  return (
    <Wrap>
      <Rays>
        <RaysInner />
      </Rays>
      <View id="hero" />
      <Inner>
        <Navigation profile={profile} isSidebarOpen={isSidebarOpen} variant={variant} />
        {sections.hero && <Hero profile={profile} />}
        {sections.deliverables && <Deliverables profile={profile} />}
        {sections.about && <About profile={profile} />}
        {sections.testimonials && <Testimonials profile={profile} />}
        {sections.plans && (
          <Plans
            profile={profile}
            variant={variant}
            handleEditProduct={handleEditProduct}
            handleBuyProduct={handleBuyProduct}
            handleCalendarBooking={handleCalendarBooking}
          />
        )}
        {sections.products && (
          <Products
            profile={profile}
            variant={variant}
            handleEditProduct={handleEditProduct}
            handleBuyProduct={handleBuyProduct}
            handleCalendarBooking={handleCalendarBooking}
          />
        )}
        {sections.faq && <Faq profile={profile} />}
        <View height={64} />
      </Inner>
    </Wrap>
  )
}
