import { markTranslated, TranslatedText } from 'core'
import { throttle } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, FlexHorizontal, Select } from '~/components'
import { useBreakpoints, useTranslation } from '~/hooks'
import { getPlaceDetails, isWeb, searchPlaces, shareGeolocation } from '~/util'

const DropdownIndicator = () => null

export interface ILocationOption {
  name: TranslatedText
  placeId?: string
  place?: google.maps.places.PlaceResult
}

const getLocationOption = (location: ILocationOption) => ({ label: location.name, value: location.placeId, location })

interface ILocationInputProps {
  location: ILocationOption | null
  onChange(location: ILocationOption | null): void
  placeholder?: string
}

export const LocationInput: React.FC<ILocationInputProps> = ({ location, onChange, placeholder }) => {
  const t = useTranslation()
  const { isPhone } = useBreakpoints()
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | null>(null)
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState<boolean | null>(null)

  const [items, setItems] = useState<ILocationOption[]>([])
  const [message, setMessage] = useState<string>()

  const onQueryChange = useMemo(
    () =>
      throttle((newQuery: string) => {
        onChange({ name: markTranslated(newQuery) })
        return setQuery(newQuery)
      }, 500),

    [setQuery, onChange]
  )

  const onItemSelect = useCallback(
    async (item: ILocationOption) => {
      const place = await getPlaceDetails(sessionToken, item.placeId as string)

      setSessionToken(null)
      setMessage(undefined)
      onChange({ ...item, ...(place ? { place: isWeb ? place : (place as any).result } : {}) })
      setQuery('')
    },
    [setQuery, onChange, sessionToken]
  )

  const onLocationButtonPress = useCallback(async () => {
    setQuery('')
    setMessage(t('Loading...'))
    const location = await shareGeolocation()
    if (location.success && location.address) {
      setQuery(location.address)
      setMessage(location.address)
      onChange({ name: location.address })
    } else {
      setMessage(undefined)
    }
  }, [setQuery, setMessage, t, onChange])

  useEffect(() => {
    if (query) {
      setIsLoading(true)
      searchPlaces(sessionToken, query, { types: ['(regions)'] }).then(({ sessionToken, results }) => {
        setIsLoading(false)
        setSessionToken(sessionToken)
        setItems(results)
      })
    }

    if (!query) {
      if (!location) {
        onChange(null)
      }

      setItems([])
    }
  }, [query, location, onChange, sessionToken])

  return (
    <FlexHorizontal>
      <Select<ReturnType<typeof getLocationOption>>
        placeholder={message || placeholder || ''}
        isLoading={!!isLoading}
        options={items.map(getLocationOption)}
        menuPlacement="auto"
        closeMenuOnScroll={isPhone}
        hideMenu={isLoading === null || (isLoading && items.length === 0) || !query}
        components={{ DropdownIndicator }}
        onInputChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'input-change') {
            onQueryChange(newValue)
          }
        }}
        onChange={option => option && onItemSelect(option.location)}
        value={location && getLocationOption(location)}
      />
      <Button
        action="GetCurrentLocation"
        marginTop={-7}
        marginRight={-8}
        marginBottom={-7}
        icon="navigate"
        onPress={onLocationButtonPress}
        minimal
      />
    </FlexHorizontal>
  )
}
