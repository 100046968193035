import { INotification } from '../models'
import { Endpoint, get, post } from '../util/api'

export type GetNotifications = Endpoint<
  { cursor?: INotification['id'] },
  { success: true; notifications: INotification[]; nextCursor: INotification['id'] | null }
>

export type MarkAllNotificationsAsRead = Endpoint<Record<string, never>, { success: true }, Record<string, never>>

export type MarkNotificationAsRead = Endpoint<{ id: INotification['id'] }, { success: true }, Record<string, never>>

export type MarkNotificationAsUnread = Endpoint<{ id: INotification['id'] }, { success: true }, Record<string, never>>

export const getNotifications = ({ cursor }: { cursor: INotification['id'] | null }) =>
  get<GetNotifications>(cursor ? `/notifications?cursor=${cursor}` : '/notifications')

export const markAllNotificationsAsRead = () => post<MarkAllNotificationsAsRead>('/notifications/read', {})

export const markNotificationAsRead = (id: INotification['id']) =>
  post<MarkNotificationAsRead>(`/notifications/${id}/read`, {})

export const markNotificationAsUnread = (id: INotification['id']) =>
  post<MarkNotificationAsUnread>(`/notifications/${id}/unread`, {})
