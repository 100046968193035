import type { IUser } from 'core'

import { constants } from '../constants'
import { isServer } from '../env'
import { util } from '../util'
import { AnalyticsEvent } from './events'
import { IProperties } from './shared'

// used to ensure init is finished before getProviders resolves, so libs are initialized before used
const initialization = (() => {
  const initialization: { promise: Promise<any>; resolve: (value?: unknown) => void } = {} as any

  initialization.promise = new Promise(resolve => {
    initialization.resolve = resolve
  })

  return initialization
})()

const getProviders = async (skipInitialization?: true) => {
  if (isServer) {
    throw new Error('Cannot load analytics providers on server')
  }

  if (!skipInitialization) {
    await initialization.promise
  }

  return (await import('./providers')).providers
}

export * from './events'

// probably a better way to grab this type, kinda crazy
let _sentryPromise: Promise<ReturnType<Awaited<ReturnType<typeof getProviders>>['sentry']['init']>> | null = null

export const initSentry = () => {
  if (!_sentryPromise) {
    _sentryPromise = getProviders().then(providers => providers.sentry.init())
  }

  return _sentryPromise
}

export const init = async () => {
  const { googleAnalytics, mixpanel, oneSignal, initVitals, logRocket } = await getProviders(true)

  await googleAnalytics.init(constants.gaMeasurementId)
  await mixpanel.init(constants.mixpanelToken)
  await oneSignal.init(constants.oneSignalAppId)

  initVitals(({ id, name, delta, value }) => {
    setUserPropertyOnce(`First ${name}`, value)

    googleAnalytics.track(name, {
      value: delta, // use delta so the value can be summed
      metric_id: id, // needed to aggregate events
      metric_value: value,
      metric_delta: delta
    })
  })

  await logRocket.init(constants.logRocketAppId, {
    network: {
      requestSanitizer: (request: any) => {
        request.headers['Authorization'] = undefined

        if (request.url.toLowerCase().includes('auth') || request.url.toLowerCase().includes('user')) {
          request.body = undefined
        }

        return request
      }
    }
  })

  initialization.resolve()
}

export const identifyUser = async (userId: IUser['id']) => {
  if (isServer) {
    throw new Error('Cannot call identifyUser on server')
  }

  const { sentry, mixpanel, googleAnalytics, logRocket } = await getProviders()
  sentry.identify(userId)
  mixpanel.identify(userId)
  await googleAnalytics.identify(userId)
  await logRocket.identify(userId)
}

export const resetUserIdentity = async () => {
  if (isServer) {
    throw new Error('Cannot call resetUserIdentity on server')
  }

  const { sentry, mixpanel } = await getProviders()

  mixpanel.reset()
  sentry.reset()
}

export type MutableAnalyticsUserProperty = { property: never; value: never }

export type ImmutableAnalyticsUserProperty =
  | { property: 'First CLS'; value: number }
  | { property: 'First FCP'; value: number }
  | { property: 'First FID'; value: number }
  | { property: 'First INP'; value: number }
  | { property: 'First LCP'; value: number }
  | { property: 'First TTFB'; value: number }

export const setUserProperty = async <PropertyName extends MutableAnalyticsUserProperty['property']>(
  property: PropertyName,
  value: Extract<MutableAnalyticsUserProperty, { property: PropertyName }>['value']
) => {
  if (isServer) {
    throw new Error('Cannot call setUserProperty on server')
  }

  return (await getProviders()).mixpanel.setUserProperty(property, value)
}

export const setUserPropertyOnce = async <PropertyName extends ImmutableAnalyticsUserProperty['property']>(
  property: PropertyName,
  value: Extract<ImmutableAnalyticsUserProperty, { property: PropertyName }>['value']
) => {
  if (isServer) {
    throw new Error('Cannot call setUserPropertyOnce on server')
  }

  return (await getProviders()).mixpanel.setUserPropertyOnce(property, value)
}

const consistentlySerializeObject = (object: IProperties): string =>
  JSON.stringify(object, (_, value: any) =>
    util.isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce((result, key) => {
            result[key] = value[key]
            return result
          }, {} as any)
      : value
  )

const doTrack = async (eventName: string, properties?: IProperties) => {
  if (isServer) {
    throw new Error('Cannot call doTrack on server')
  }

  const { googleAnalytics, logRocket } = await getProviders()

  await Promise.all([
    googleAnalytics.track(eventName, properties),
    logRocket.track(properties ? `${eventName}:${consistentlySerializeObject(properties)}` : eventName)
  ])
}

export const trackPage = async () => {
  if (isServer) {
    throw new Error('Cannot call trackPage on server')
  }

  const { googleAnalytics, logRocket } = await getProviders()
  return Promise.all([googleAnalytics.trackScreen(), logRocket.trackScreen()])
}

export const trackEvent = async <EventName extends AnalyticsEvent['event']>(
  event: EventName,
  properties: Extract<AnalyticsEvent, { event: EventName }>['properties']
) => {
  if (isServer) {
    throw new Error('Cannot call trackEvent on server')
  }

  const { mixpanel } = await getProviders()
  const data = { event, properties } as AnalyticsEvent
  const payload = { ...data.properties, eventType: data.event }

  if (data.event === 'Press') {
    return mixpanel.track(`Press${data.properties.action.replace(/([A-Z])/g, ' $1')}`, payload)
  }

  if (data.event === 'Select') {
    return mixpanel.track(`Select${data.properties.item.replace(/([A-Z])/g, ' $1')}`, payload)
  }

  if (data.event === 'Share') {
    return mixpanel.track(`Share${data.properties.item.replace(/([A-Z])/g, ' $1')}`, payload)
  }

  if (data.event === 'View') {
    return mixpanel.track(`View${data.properties.screen.replace(/([A-Z])/g, ' $1')}`, payload)
  }

  throw new Error('Unknown mixpanel event type')
}
