import React, { Suspense, useEffect, useState } from 'react'
import type { toast } from 'react-hot-toast'

import type { IToast } from '~/lite'

import { Toast } from './Toast'

let waitForToaster: Promise<typeof toast> | null = null
let enableToaster: () => void = () => null
let setToaster: (value: typeof toast) => void

export const showToast = (toast: IToast) => {
  if (!waitForToaster) {
    waitForToaster = new Promise(resolve => {
      setToaster = resolve
      enableToaster()
    })
  }

  waitForToaster.then(toaster => toaster.custom(t => <Toast toast={toast} onPress={() => toaster.dismiss(t.id)} />))
}

const LazyToaster = React.lazy(() => import('react-hot-toast').then(module => ({ default: module.Toaster })))

export const ToasterProvider: React.FC = () => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    if (waitForToaster) {
      setEnabled(true)
    }

    enableToaster = () => setEnabled(true)
  }, [])

  useEffect(() => {
    if (enabled) {
      import('react-hot-toast').then(module => setToaster(module.toast))
    }
  }, [enabled])

  if (!enabled) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <LazyToaster position="top-center" />
    </Suspense>
  )
}
