import { useQueryClient } from '@tanstack/react-query'
import { defaultLocale, ITeam, ResumeImportState, SupportedLocaleCode, TranslatedText } from 'core'
import React, { useCallback, useEffect, useState } from 'react'

import {
  Button,
  H1,
  H2,
  Icon,
  keyframes,
  Loader,
  Logo,
  styled,
  TeamAvatar,
  Text,
  useLoadUserData,
  useStore,
  useTranslation,
  View
} from '~/lite'

import { OpenAiLogo } from '../OpenAiLogo'
import { SignUpNote } from '../SignUpNote'
import { ResumeImportField, ResumeUploadState } from './ResumeImportField'

type Variant = 'Apply' | 'ChatGPT' | 'CoverLetter' | 'Launcher' | 'Launchpad'

const liExport: Record<SupportedLocaleCode, { gif: SupportedLocaleCode; buttons: [string, string] } | null> = {
  'af-ZA': null,
  'ar-SA': null,
  'ca-ES': null,
  'cs-CZ': { gif: 'cs-CZ', buttons: ['Více', 'Uložit ve formátu PDF'] },
  'da-DK': { gif: 'da-DK', buttons: ['Mere', 'Gem som PDF'] },
  'de-DE': { gif: 'de-DE', buttons: ['Mehr', 'Als PDF speichern'] },
  'el-GR': null,
  'en-US': { gif: 'en-US', buttons: ['More', 'Save to PDF'] },
  'es-419': { gif: 'es-ES', buttons: ['Más', 'Guardar en PDF'] },
  'es-ES': { gif: 'es-ES', buttons: ['Más', 'Guardar en PDF'] },
  'fi-FI': null,
  'fr-CA': { gif: 'fr-FR', buttons: ['Plus', 'Enregistrer au format PDF'] },
  'fr-FR': { gif: 'fr-FR', buttons: ['Plus', 'Enregistrer au format PDF'] },
  'he-IL': null,
  'hi-IN': { gif: 'hi-IN', buttons: ['अधिक', 'PDF में सेव करें'] },
  'hr-HR': null,
  'hu-HU': null,
  'hy-AM': null,
  'id-ID': { gif: 'id-ID', buttons: ['Lainnya', 'Simpan sebagai PDF'] },
  'it-IT': { gif: 'it-IT', buttons: ['Altro', 'Salva come PDF'] },
  'ja-JP': { gif: 'ja-JP', buttons: ['その他', 'PDFとして保存する'] },
  'ko-KR': { gif: 'ko-KR', buttons: ['더 보기', 'PDF로 저장'] },
  'ms-MY': { gif: 'ms-MY', buttons: ['Lagi', 'Simpan ke PDF'] },
  'nl-NL': { gif: 'nl-NL', buttons: ['Meer', 'Opslaan als PDF'] },
  'no-NO': { gif: 'no-NO', buttons: ['Flere', 'Lagre til PDF'] },
  'pl-PL': { gif: 'pl-PL', buttons: ['Więcej', 'Zapisz jako PDF'] },
  'pt-BR': { gif: 'pt-PT', buttons: ['Mais', 'Salvar como PDF'] },
  'pt-PT': { gif: 'pt-PT', buttons: ['Mais', 'Salvar como PDF'] },
  'ro-RO': { gif: 'ro-RO', buttons: ['Mai multe', 'Salvați în PDF'] },
  'ru-RU': { gif: 'ru-RU', buttons: ['Еще', 'Сохранить в PDF'] },
  'sk-SK': null,
  'sr-SP': null,
  'sv-SE': { gif: 'sv-SE', buttons: ['Mer', 'Spara som PDF-fil'] },
  'th-TH': { gif: 'th-TH', buttons: ['เพิ่มเติม', 'บันทึกเป็น PDF'] },
  'tr-TR': { gif: 'tr-TR', buttons: ['Daha Fazla', 'PDF olarak kaydet'] },
  'uk-UA': { gif: 'uk-UA', buttons: ['Більше', 'Зберегти в PDF'] },
  'ur-PK': null,
  'vi-VN': null,
  'zh-CN': null,
  'zh-TW': { gif: 'zh-TW', buttons: ['更多內容', '存為 PDF'] }
}

const localesWithoutLIExport: SupportedLocaleCode[] = ['ar-SA', 'zh-CN']

const slideInUp = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

const slideOutDown = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
`

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`

const SystemIcon = styled(View)`
  width: 100px;
  height: 100px;
  padding: ${(100 * ((180 - 116) / 180)) / 2}px;
  border-radius: 100%;
  background: ${props => props.theme.cardBackground};

  svg {
    width: 100%;
    height: 100%;
  }
`

const SyncIcon = styled(View)`
  width: 100px;
  height: 100px;
  padding: 25px;
`

const CheckIcon = styled(View)<{ $numbered: boolean }>`
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  background: ${props => (props.$numbered ? props.theme.primary : props.theme.dark ? '#113123' : '#2bbf5c')};
  color: ${props => (props.$numbered ? '#fff' : props.theme.dark ? '#4cc38a' : '#fff')};
  text-align: center;
  line-height: 15px;
`

const Wrap = styled(View)<{ $variant: Variant }>`
  position: relative;
  display: flex;
  overflow: hidden;
  margin: ${props => (props.$variant === 'ChatGPT' ? '16px 0' : 0)};

  .uppy-FileInput-btn {
    border-radius: 8px;
    font-size: 16px;
    min-height: 42px;
    text-align: center;
  }
`

const Card = styled(View)`
  flex: 1;
  padding: 24px 32px;
  border-width: 1px;
  border-radius: 12px;
`

const SecondaryCard = styled(Card)<{ $visible: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(${props => props.theme.backgroundRgb}, 0.8);
  backdrop-filter: blur(10px);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: ${props => (props.$visible ? slideInUp : slideOutDown)};
  box-shadow: ${props => (props.$visible ? 'rgb(0 0 0 / 8%) 0px -4px 16px' : 'none')};
`

const SignUpNoteWrap = styled.div`
  display: block;
  margin-top: 16px;
  font-size: 12px;
  text-align: center;

  a:hover {
    text-decoration: underline;
  }
`

const Header = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ProgressMessage = styled(View)`
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
`

const Footer = styled(View)<{ $variant: Variant }>`
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  padding-bottom: ${props => (props.$variant === 'ChatGPT' ? '24px' : '8px')};
`

const FooterItem = styled(View)<{ $variant: Variant }>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px 8px;
  flex: ${props => (props.$variant === 'ChatGPT' ? 1 : 'none')};
`

const FooterIcon = styled(View)`
  max-width: 24px;
  opacity: 0.5;
  margin-right: 8px;
`

const FooterText = styled(Text)`
  opacity: 0.8;
`

const Tag = styled(Text)`
  display: inline-block;
  padding: 2px 4px;
  background: ${props => (props.theme.dark ? '#251e40' : `${props.theme.primary}33`)};
  color: ${props => (props.theme.dark ? '#9e8cfc' : '#251e40')};
  font-size: 14px;
  border-radius: 10px;
`

const ImportFieldFallbackButton = styled(Button)`
  border-radius: 5px;
  height: 42px;
  font-size: 16px;
  padding-top: 1px;
`

const importFieldFallback = <ImportFieldFallbackButton text="Upload CV" primary disabled skipTracking />

export interface IResumeImportProps {
  variant: Variant
  team?: Pick<ITeam, 'logo' | 'name'>
  method?: 'Launchpad'
  onChange(state: ResumeImportState): void
  onUploadChange?(state: ResumeUploadState): void
  skipSignupNote?: boolean
}

export const ResumeImport: React.FC<IResumeImportProps> = ({
  variant,
  team,
  method,
  onUploadChange,
  onChange,
  skipSignupNote
}) => {
  const t = useTranslation()
  const loadUserData = useLoadUserData()
  const queryClient = useQueryClient()

  const locale: SupportedLocaleCode = useStore(state => state.locale)
  const resumeImportState = useStore(state => state.resumeImportState)

  const [showImportFromLinkedIn, setShowImportFromLinkedIn] = useState<boolean | null>(null)
  const [showProgress, setShowProgress] = useState<boolean | null>(null)
  const [resumeUploadState, setResumeUploadState] = useState<ResumeUploadState | null>(null)

  const onResumeImportChange = useCallback(
    async (state: ResumeUploadState) => {
      onUploadChange?.(state)

      if (state === 'Uploading') {
        setResumeUploadState(state)

        if (showImportFromLinkedIn) {
          setShowImportFromLinkedIn(false)

          // wait for animation to finish
          setTimeout(
            () => {
              setShowProgress(true)
            },
            variant === 'ChatGPT' ? 500 : 0
          )
        } else {
          setShowProgress(true)
        }
      }

      if (state === 'Uploaded') {
        setShowProgress(true)
        await loadUserData()
        setResumeUploadState(state)
      }
    },
    [showImportFromLinkedIn, onUploadChange, loadUserData, variant]
  )

  useEffect(() => {
    if (showProgress && resumeImportState) {
      if (resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success') {
        queryClient.resetQueries().then(() => {
          onChange(resumeImportState)
        })
      }
    }
  }, [showProgress, resumeImportState, onChange, queryClient])

  const whatWeDo = [
    { label: t('Read job data from across the web') },
    { label: t('Send new jobs to ChatGPT along with your resume to decide if they are relevant to you') },
    { label: t('Make your CV available to ChatGPT across conversations and devices'), comingSoon: true },
    { label: t('Save changes made by you and ChatGPT'), comingSoon: true }
  ]

  const features = [
    { label: t('Search and discuss millions of jobs') },
    { label: t('Tailored job recommendations') },
    { label: t('Comprehensive resume analysis'), comingSoon: true }
  ]

  const renderChecklist = (
    items: (TranslatedText | { label: TranslatedText; comingSoon?: boolean })[],
    numbered?: boolean
  ) => (
    <>
      {items.map((item, index) => (
        <View key={index} flexDirection="row" alignItems="center" paddingTop="m">
          <CheckIcon $numbered={!!numbered} marginRight={variant === 'ChatGPT' ? 12 : 8}>
            {numbered ? (
              <Text rawText={`${index + 1}` as TranslatedText} fontSize={16} />
            ) : (
              <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={15} height={15}>
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={32}
                  d="M416 128L192 384l-96-96"
                />
              </Svg>
            )}
          </CheckIcon>
          <View flex={1} display="block">
            <Text rawText={typeof item === 'string' ? item : item.label} fontSize={16} marginRight="s" />
            {typeof item !== 'string' && item.comingSoon && <Tag text="Coming Soon" />}
          </View>
        </View>
      ))}
    </>
  )

  const exportInfo =
    liExport[locale] ?? (liExport[defaultLocale] as NonNullable<typeof liExport[keyof typeof liExport]>)

  const renderImportButtons = () => (
    <>
      <ResumeImportField
        method={method}
        buttonText={
          variant === 'ChatGPT'
            ? 'Analyze CV'
            : variant === 'Launcher'
            ? "Analyze CV — It's Free"
            : variant === 'Launchpad'
            ? 'Analyze CV'
            : 'Upload CV'
        }
        onChange={onResumeImportChange}
        fallback={importFieldFallback}
      />
      {!localesWithoutLIExport.includes(locale) && (
        <Button
          action="OpenImportFromLinkedIn"
          text="Import from LinkedIn"
          onPress={() => setShowImportFromLinkedIn(true)}
          width="100%"
          marginTop="s"
          large
        />
      )}
      {!skipSignupNote && (
        <SignUpNoteWrap>
          <SignUpNote style={{ fontSize: 11 }} />
        </SignUpNoteWrap>
      )}
    </>
  )

  const renderChatGptCard = () => (
    <Card>
      <View flexDirection="row" alignItems="center" justifyContent="center">
        <SystemIcon>
          <Logo />
        </SystemIcon>
        <SyncIcon>
          <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M434.67 285.59v-29.8c0-98.73-80.24-178.79-179.2-178.79a179 179 0 00-140.14 67.36m-38.53 82v29.8C76.8 355 157 435 256 435a180.45 180.45 0 00140-66.92"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={32}
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={32}
              d="M32 256l44-44 46 44M480 256l-44 44-46-44"
            />
          </Svg>
        </SyncIcon>
        <SystemIcon>
          <OpenAiLogo />
        </SystemIcon>
      </View>
      <H1 text="Sync your CV with ChatGPT" fontSize={22} marginTop="l" textAlign="center" />
      <View borderTopWidth={1} marginY="l" />
      <H2 text="What we do" />
      {renderChecklist(whatWeDo)}
      <H2 text="Features added to ChatGPT" marginTop="l" />
      {renderChecklist(features)}
      <View height={32} />
      {renderImportButtons()}
    </Card>
  )

  const renderApplyCard = () => (
    <View flex={1} padding={16}>
      <View flexDirection="row" alignItems="center" justifyContent="center">
        <SystemIcon>
          <Logo />
        </SystemIcon>
        <View height={100} paddingX={8} justifyContent="center">
          <Icon icon="arrow-forward" size={48} />
        </View>
        <SystemIcon>{team && <TeamAvatar team={team} width={64} height={64} />}</SystemIcon>
      </View>
      <H1
        rawText={team?.name ? t('Send your CV to {{companyName}}', { companyName: team.name }) : t('Send your CV')}
        fontSize={22}
        marginTop="l"
        textAlign="center"
      />
      <View height={32} />
      {renderImportButtons()}
    </View>
  )

  const renderCoverLetterCard = () => (
    <View flex={1} padding={16}>
      {renderImportButtons()}
    </View>
  )

  const renderLiCard = () => (
    <>
      {exportInfo.gif && (
        <img
          src={`https://assets.remoteambition.com/help/export-li/${exportInfo.gif}.gif`}
          alt=""
          width="100%"
          style={{
            borderRadius: 12,
            boxShadow: '0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25)',
            marginBottom: variant === 'ChatGPT' ? 4 + 8 : 0,
            minHeight: variant === 'ChatGPT' ? 215 : 186
          }}
        />
      )}
      {renderChecklist(
        [
          t('Visit your LinkedIn profile'),
          t('Click {{button1}}, then click {{button2}}', {
            button1: exportInfo.buttons[0] as TranslatedText,
            button2: exportInfo.buttons[1] as TranslatedText
          }),
          ...(variant === 'ChatGPT' ? [t('Upload your PDF here to be analyzed with ChatGPT')] : [])
        ],
        true
      )}
      <View height={variant === 'ChatGPT' ? 24 : 16} />
      <ResumeImportField method={method} buttonText="Upload PDF from LinkedIn" onChange={onResumeImportChange} />
      {variant !== 'ChatGPT' && (
        <Button
          action="CloseImportFromLinkedIn"
          text="Cancel"
          onPress={() => setShowImportFromLinkedIn(false)}
          width="100%"
          marginTop="s"
          large
        />
      )}
      {!skipSignupNote && (
        <SignUpNoteWrap>
          <SignUpNote style={{ fontSize: 11 }} />
        </SignUpNoteWrap>
      )}
    </>
  )

  const renderProgressCard = () => (
    <View flex={1} justifyContent="center" alignItems="center">
      <View position="relative" flexDirection="column" justifyContent="stretch" alignItems="center">
        {resumeUploadState === 'Uploading' && (!resumeImportState || variant === 'ChatGPT') && (
          <ProgressMessage>
            <Loader size={60} />
            <H2 text="Uploading..." marginY="m" fontSize={22} />
          </ProgressMessage>
        )}
        {resumeUploadState === 'Uploaded' && (!resumeImportState || variant === 'ChatGPT') && (
          <ProgressMessage>
            <Icon size={60} icon="checkmark" color="#00c853" />
            <H2 text="Uploaded" marginY="m" fontSize={22} />
          </ProgressMessage>
        )}
        {(resumeImportState === 'Pending' || resumeImportState === 'Processing' || resumeImportState === 'Retry') && (
          <ProgressMessage>
            <Loader size={60} />
            <H2 text="Analyzing" marginY="m" fontSize={22} />
            <Text text="This can take a couple minutes" fontSize={16} />
            {variant === 'ChatGPT' && (
              <Button text="Cancel" onPress={() => setShowProgress(false)} marginTop="s" minimal skipTracking />
            )}
          </ProgressMessage>
        )}
        {(resumeImportState === 'RecommendationsAdded' || resumeImportState === 'Success') && (
          <ProgressMessage>
            <Icon size={60} icon="checkmark" color="#00c853" />
            <H2 text="Analysis Complete" marginY="m" fontSize={22} />
            {variant === 'ChatGPT' && (
              <>
                <Button text="View Results" to="/" skipTracking />
                <Button text="Try Again" onPress={() => setShowProgress(false)} marginTop="s" minimal skipTracking />
              </>
            )}
          </ProgressMessage>
        )}
        {resumeImportState === 'Error' && (
          <ProgressMessage>
            <Icon size={60} icon="alert-circle-outline" color="#ff656d" />
            <H2 text="An error has occurred" marginY="m" fontSize={22} />
            <Button text="Try Again" onPress={() => setShowProgress(false)} marginTop="s" minimal skipTracking />
          </ProgressMessage>
        )}
      </View>
    </View>
  )

  const renderFooter = () => (
    <Footer $variant={variant}>
      <FooterItem $variant={variant}>
        <FooterIcon>
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </Svg>
        </FooterIcon>
        <FooterText text="No Spam" />
      </FooterItem>
      <FooterItem $variant={variant}>
        <FooterIcon>
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
            />
          </Svg>
        </FooterIcon>
        <FooterText text="Privacy Focused" />
      </FooterItem>
      <FooterItem $variant={variant}>
        <FooterIcon>
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </Svg>
        </FooterIcon>
        <FooterText text="Secure" />
      </FooterItem>
    </Footer>
  )

  const showProgressCard =
    showProgress ||
    (showProgress === null &&
      !!resumeImportState &&
      ['Pending', 'Processing', 'RecommendationsAdded', 'Success', 'Retry'].includes(resumeImportState))

  if (variant === 'ChatGPT') {
    return (
      <>
        <Wrap $variant={variant}>
          {renderChatGptCard()}
          {showImportFromLinkedIn !== null && (
            <SecondaryCard $visible={showImportFromLinkedIn}>
              <Header>
                <H2 text="Import from LinkedIn" fontSize={22} />
                <Button
                  action="CloseImportFromLinkedIn"
                  icon="close-outline"
                  size={30}
                  onPress={() => setShowImportFromLinkedIn(false)}
                />
              </Header>
              {renderLiCard()}
            </SecondaryCard>
          )}
          {showProgressCard && (
            <SecondaryCard $visible top={0}>
              {renderProgressCard()}
            </SecondaryCard>
          )}
        </Wrap>
        {renderFooter()}
      </>
    )
  }

  return (
    <>
      <Wrap $variant={variant}>
        {showProgressCard ? (
          <View padding="m" minHeight={variant === 'Launcher' || variant === 'Launchpad' ? 200 : 300}>
            {renderProgressCard()}
          </View>
        ) : showImportFromLinkedIn ? (
          <View padding="m">{renderLiCard()}</View>
        ) : variant === 'Apply' ? (
          renderApplyCard()
        ) : (
          renderCoverLetterCard()
        )}
      </Wrap>
      {!showProgressCard && renderFooter()}
    </>
  )
}
