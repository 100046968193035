import { hoverArrow, IMessageAttachment } from 'core'

import { AttachmentSubtitle, AttachmentTitle, AttachmentWrap } from './styles'

interface IAttachmentProps {
  attachment: IMessageAttachment
}

export const Attachment: React.FC<IAttachmentProps> = ({ attachment }) => {
  if (attachment.type === 'Link') {
    const { route, title, subtitle } = attachment

    return (
      <AttachmentWrap className="hover-arrow" to={route}>
        {title && <AttachmentTitle rawText={title} />}
        {subtitle && <AttachmentSubtitle rawText={subtitle} />}
        <span dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
      </AttachmentWrap>
    )
  }

  return null
}
