import type { IHtmlRendererProps } from '@ambition/ui-html-renderer'
import React, { Suspense } from 'react'

import { Loader } from '~/lite'

export type { IHtmlRendererProps }

const LazyHtmlRenderer = React.lazy(() =>
  import('@ambition/ui-html-renderer').then(module => ({ default: module.HtmlRenderer }))
)

export const HtmlRenderer: React.FC<IHtmlRendererProps> = props => (
  <Suspense fallback={<Loader />}>
    <LazyHtmlRenderer {...props} />
  </Suspense>
)
