import type { GeolocationResponse } from '@react-native-community/geolocation'

export const getGeolocation = (): Promise<GeolocationResponse | null> =>
  new Promise((resolve, reject) => {
    if (!navigator?.geolocation) {
      throw new Error('Navigator is undefined')
    }

    navigator.geolocation.getCurrentPosition(resolve, e => {
      if (e.code === e.PERMISSION_DENIED) {
        resolve(null)
      }

      if (e.code === e.POSITION_UNAVAILABLE) {
        resolve(null)
      }

      if (e.code === e.TIMEOUT) {
        resolve(null)
      }

      reject(e)
    })
  })
