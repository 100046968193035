import { IScreenerContent, ITeam } from 'core'
import { chunk } from 'lodash'
import React, { Suspense, useCallback, useState } from 'react'

import { animations, breakpoint, Button, css, Loader, styled, Text, View } from '~/lite'

import { Arrow } from '../Arrow'

const LazyDashboard = React.lazy(() =>
  import('@ambition/module-dashboard').then(module => ({ default: module.TeamDashboard }))
)

const Gutter = styled(View)`
  display: none;

  @media ${breakpoint.lg} {
    display: flex;
    width: 120px;
  }
`

const GuideCallout = styled(View)<{ $ready: boolean }>`
  position: relative;
  display: block;
  z-index: 10;
  margin-right: auto;
  margin-left: auto;
  transition: opacity 3s cubic-bezier(0.16, 1, 0.3, 1) 4s;
  opacity: ${props => (props.$ready ? 1 : 0)};
`

const GuideHero = styled(View)`
  flex: 1;
  margin: 0 auto;
  text-align: center;
  text-wrap: balance;
  animation: 1200ms ease 800ms both ${animations.fadeInDown};
`

const GuideHeroText = styled(Text)`
  font-size: 20px;
  hyphens: none;
`

const GuideText = styled(Text)`
  display: inline-block;
  font-size: 14px;
  hyphens: none;
`

const Wrap = styled(View)`
  position: relative;
  width: 100%;
  padding: 32px 16px;
  justify-content: stretch;
  align-items: stretch;
  background: ${props => (props.theme.dark ? 'transparent' : '#f6f9fc')};

  @media ${breakpoint.md} {
    padding: 32px 16px;
  }
`

const DashboardWrap = styled(View)<{ $ready: boolean }>`
  position: relative;
  flex: 1;
  height: calc(100vh - 60px - 60px - 60px);
  min-height: 600px;
  margin: 32px 0;
  justify-content: stretch;
  align-items: stretch;
  background: ${props => props.theme.background};
  border-width: ${props => (props.theme.dark ? '1px' : 0)};
  border-radius: 12px;
  overflow: hidden;
  transition: all 3s cubic-bezier(0.16, 1, 0.3, 1);

  @media ${breakpoint.lg} {
    margin: 32px;
  }

  opacity: 0.5;
  box-shadow: none;
  transform: translateY(4px) scale(0.97);

  ${props =>
    props.$ready &&
    css`
      opacity: 1;
      box-shadow: 0 0 40px 0px #6360ff44, 0 13px 27px -5px #6360ff44;
      transform: translateY(0) scale(1);
    `};
`

const PositionsWrap = styled(View)`
  flex: 1;
  width: calc(100% + 32px);
  margin: 16px -16px 0;
  padding: 0 8px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.lg} {
    margin: 32px -16px 0;
    padding: 0;
  }
`

const PositionButtonRow = styled(View)`
  flex-direction: row;
  gap: 8px;
  padding: 4px 0;
`

const PositionLeftGutter = styled(View)`
  flex: 1;
  border-radius: 0 8px 8px 0;
  background: ${props => (props.theme.dark ? `${props.theme.background}88` : '#e3edf6')};
`

const PositionRightGutter = styled(View)`
  flex: 1;
  border-radius: 8px 0 0 8px;
  background: ${props => (props.theme.dark ? `${props.theme.background}88` : '#e3edf6')};
`

const PositionButton = styled(Button)<{ $active: boolean }>`
  border-radius: 8px;
  text-wrap: nowrap;
  background: ${props => (props.$active ? props.theme.primary : 'transparent')};
  border: 2px solid
    ${props => (props.$active ? props.theme.primary : props.theme.dark ? `${props.theme.background}88` : '#e3edf6')};

  &:hover {
    background: ${props =>
      props.$active ? props.theme.primary : props.theme.dark ? `${props.theme.background}88` : '#e3edf6'};
  }
`

interface IContainerProps {
  data: IScreenerContent['demo']
  selectedPosition?: IScreenerContent['demo']['positions'][number]
  onSelectPosition?: (position: IScreenerContent['demo']['positions'][number]) => void
  ready: boolean
  children?: React.ReactNode
}

const Container: React.FC<IContainerProps> = ({ data, ready, children, selectedPosition, onSelectPosition }) => (
  <Wrap id="demo">
    <View flexDirection="row">
      <Gutter />
      <GuideHero>
        <GuideHeroText rawText={data.header} />
      </GuideHero>
      <Gutter />
    </View>
    <View flexDirection="column">
      <PositionsWrap>
        {chunk(data.positions, 5).map((group, idx) => (
          <PositionButtonRow key={idx}>
            <PositionLeftGutter />
            {group.map((position, idx) => (
              <PositionButton
                key={idx}
                rawText={position.title}
                onPress={() => onSelectPosition?.(position)}
                primary={position.id === selectedPosition?.id}
                $active={position.id === selectedPosition?.id}
                skipTracking
              />
            ))}
            <PositionRightGutter />
          </PositionButtonRow>
        ))}
      </PositionsWrap>
      <View flexDirection="row">
        <Gutter>
          {!!data.callouts['AddResumes'] && (
            <GuideCallout $ready={ready} marginTop={40}>
              <Arrow variant="straight" rotate={0} top={10} right={-70} scale={2.4} />
              <GuideText textAlign="right" rawText={data.callouts['AddResumes'].header} />
            </GuideCallout>
          )}
          {!!data.callouts['Filters'] && (
            <GuideCallout $ready={ready} marginTop={200}>
              <Arrow variant="curved" rotate={0} scale={2} right={-70} />
              <GuideText textAlign="right" rawText={data.callouts['Filters'].header} />
            </GuideCallout>
          )}
        </Gutter>
        <DashboardWrap $ready={ready}>{children ?? <Loader />}</DashboardWrap>
        <Gutter>
          {!!data.callouts['Search'] && (
            <GuideCallout $ready={ready} marginTop={40}>
              <Arrow variant="straight" rotate={180} top={10} left={0} scale={2.4} mirrorY />
              <GuideText rawText={data.callouts['Search'].header} />
            </GuideCallout>
          )}
          {!!data.callouts['Rank'] && (
            <GuideCallout $ready={ready} marginTop={200}>
              <Arrow variant="curved" rotate={0} scale={2} mirrorX />
              <GuideText rawText={data.callouts['Rank'].header} />
            </GuideCallout>
          )}
        </Gutter>
      </View>
    </View>
  </Wrap>
)

interface IDemoProps {
  data: IScreenerContent['demo']
}

export const Demo: React.FC<IDemoProps> = ({ data }) => {
  const [ready, setReady] = useState(false)

  const [selectedPosition, setSelectedPosition] = useState(data.positions[0])
  const onSelectPosition = useCallback((position: IScreenerContent['demo']['positions'][number]) => {
    setSelectedPosition(position)
  }, [])

  return (
    <Suspense fallback={<Container data={data} ready={false} />}>
      <Container selectedPosition={selectedPosition} onSelectPosition={onSelectPosition} data={data} ready={ready}>
        <LazyDashboard
          teamId={'demo' as ITeam['id']}
          positionId={selectedPosition?.id}
          onReady={() => setReady(true)}
        />
      </Container>
    </Suspense>
  )
}
