import { IMessage, IUser } from 'core'
import React, { useCallback, useEffect, useRef } from 'react'

import { IListProps, IListRef, List, Loader } from '~/components'
import { useHeaderHeight } from '~/hooks'
import { NonIdealState, useStore, View, ViewStyle } from '~/lite'

import { Message } from './Message'

interface IMessageListProps {
  isLoading: boolean
  messages: IMessage[]
  typingUserIds: IUser['id'][]
  hideTime?: boolean
  style?: ViewStyle
}

export const MessageList: React.FC<IMessageListProps> = ({ isLoading, messages, typingUserIds, hideTime, style }) => {
  const currentUserId = useStore(state => state.currentUserId)
  const headerHeight = useHeaderHeight()
  const listRef = useRef<IListRef>(null)

  useEffect(() => {
    setTimeout(() => {
      listRef.current?.scrollToBottom()
    }, 50)
  }, [messages])

  const renderItem = useCallback<NonNullable<IListProps<IMessage>['renderItem']>>(
    ({ item }) => <Message key={item.id} message={item} currentUserId={currentUserId} hideTime={hideTime} />,
    [currentUserId, hideTime]
  )

  if (messages.length === 0) {
    return isLoading ? <Loader testID="channel-loader" /> : <NonIdealState icon="chatbubbles-outline" />
  }

  return (
    <List<IMessage>
      ref={listRef}
      style={{ display: 'flex', flex: 1, paddingTop: headerHeight + 70, ...(style ?? {}) }}
      data={messages}
      keyExtractor={item => item.id}
      renderItem={renderItem}
      contentContainerStyle={{ justifyContent: 'flex-end' }}
      ListHeaderComponent={<View height={8} />}
      ListFooterComponent={<View height={8} />}
    />
  )
}

/*
const { isPhone } = useBreakpoints()
const shouldUseDesktopView = isWeb && !isPhone
  return (
    <MessagesScrollContainer
      testID="channel-messages-container"
      {...(shouldUseDesktopView
        ? {}
        : {
            style: { height: windowHeight - 136 },
            ref: scrollView,
            onContentSizeChange: () => scrollView?.current.scrollToEnd({ animated: true })
          })}
    >
      <TypingIndicator isActive={typingUserIds.length > 0} />
    </MessagesScrollContainer>
  )
  const onDrop = useCallback(acceptedFiles => {
    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
      // channelProxy?.sendMessage({ contentType: acceptedFiles[0].type, media: acceptedFiles[0] })
    }
  }, [])
  const formatDateLabel = useCallback(
    (date: Date): TranslatedText => {
      if (isToday(date)) {
        return t('Today')
      }

      if (isYesterday(date)) {
        return t('Yesterday')
      }

      return t.formatDate(date, { month: 'short', day: 'numeric' })
    },
    [t]
  )
  const groups = groupBy<IMessage>(allMessages, message =>
    t.formatDateString(message.createdAt as unknown as string, { dateStyle: 'long' })
  )
*/
