import { IMarketProfile, profileWebsites, TranslatedText } from 'core'
import { compact, keyBy } from 'lodash'
import React, { useMemo } from 'react'

import { breakpoint, Button, H3, H4, openURL, styled, Text, View } from '~/lite'

import { getProfilePeople } from './shared'
import { Section, SectionContent, SectionHeader, SectionSubheader } from './styles'

const profileWebsitesByCode = keyBy(profileWebsites, website => website.code)

const Card = styled(View)`
  position: relative;
  min-width: 350px;
  margin: 8px 8px 0;
  padding: 32px;
  border-radius: 12px;
  border-width: 1px;
  background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
  backdrop-filter: blur(20px);
  transition: transform 200ms ease;

  @media ${breakpoint.md} {
    margin: 0 auto;
    max-width: 700px;
  }
`

const Header = styled(View)`
  align-items: start;
  justify-content: center;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom-width: 1px;

  @media ${breakpoint.md} {
    flex-direction: row;
  }
`

const HeaderContent = styled(View)`
  flex: 1;
`

const Name = styled(H3)`
  font-size: 26px;
  font-weight: 600;
`

const Headline = styled(H4)`
  font-size: 18px;
  opacity: 0.8;
`

const ProfileLinks = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const ProfileLink = styled(Button)`
  width: 32px;
  height: 32px;
  background: none;
  opacity: 0.8;

  &:hover {
    background: none;
    opacity: 1;
  }
`

const AboutText = styled(Text)`
  margin-top: 16px;
  font-size: 18px;
  hyphens: none;
`

const Experience = styled(View)`
  flex-direction: row;
  border-top-width: 1px;
  padding: 16px 0;

  &:first-child {
    border-top-width: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`

const ExperienceRole = styled(Text)`
  flex: 1;
  font-size: 16px;
`

const ExperienceTimeframe = styled(Text)`
  font-size: 16px;
`

interface IAboutProps {
  profile: IMarketProfile
}

export const About: React.FC<IAboutProps> = ({ profile }) => {
  const people = useMemo(() => getProfilePeople(profile), [profile])

  if (!profile.metadata.about) {
    return null
  }

  const { header, subheader } = profile.metadata.about

  return (
    <Section id="about">
      <SectionContent>
        {!!header && <SectionHeader rawText={header} />}
        {!!subheader && <SectionSubheader rawText={subheader} />}
        {people.map(person => {
          const name = compact([person.firstName, person.lastName].map(part => part?.trim())).join(
            ' '
          ) as TranslatedText

          const pastExperience = (person.resume?.work ?? []).map(data => ({
            company: data.name,
            position: data.position
          }))

          return (
            <Card key={person.id}>
              <Header>
                <HeaderContent>
                  {!!name && <Name rawText={name} />}
                  {!!person.headline && <Headline rawText={person.headline} />}
                </HeaderContent>
                {!!person.websites?.length && (
                  <ProfileLinks>
                    {person.websites.map(({ code, url }, idx) => (
                      <ProfileLink
                        key={idx}
                        iconSize={32}
                        onPress={url ? () => openURL(url) : undefined}
                        icon={profileWebsitesByCode[code]?.icon}
                        skipTracking
                        small
                        link
                      />
                    ))}
                  </ProfileLinks>
                )}
              </Header>
              {!!person.bio && <AboutText rawText={person.bio} />}
              {/*(person.resume?.work ?? []).length > 0 && (
                <>
                  <SectionSubheader marginTop="m" rawText="Experience" />
                  <View>
                    {(person.resume?.work ?? []).map((data, idx) => (
                      <Experience key={idx}>
                        <ExperienceRole rawText={`${data.position} at ${data.name}`} />
                        <ExperienceTimeframe rawText={`${data.startDate} - ${data.endDate}`} />
                      </Experience>
                    ))}
                  </View>
                </>
              )*/}
              {/*(person.resume?.education ?? []).length > 0 && (
                <>
                  <SectionSubheader marginTop="m" rawText="Education" />
                  <View>
                    {(person.resume?.education ?? []).map((data, idx) => (
                      <Experience key={idx}>
                        <ExperienceRole rawText={data.area} />
                        <ExperienceRole rawText={data.studyType} />
                        <ExperienceRole rawText={data.institution} />
                        <ExperienceTimeframe rawText={`${data.startDate} - ${data.endDate}`} />
                      </Experience>
                    ))}
                  </View>
                </>
              )*/}
            </Card>
          )
        })}
      </SectionContent>
    </Section>
  )
}
