import { Domain } from 'core'
import React from 'react'
import { Outlet, Route, RouteObject, Routes, useRoutes as useReactRouterRoutes } from 'react-router-dom'

import { PortalHost } from '~/lite'

import { IStack, useScreens } from '../hooks/useScreens'
import { StackName } from '../types'
import { SentryErrorBoundary } from './SentryErrorBoundary'
import { StackWrap } from './styles'
import { INavigatorProps } from './types'
import { WebContainer } from './WebContainer'

export const createStack = (name: StackName, navigatorProps?: INavigatorProps): React.FC => {
  return () => {
    const screens = useScreens()

    return (
      <StackWrap>
        <Outlet />
        <Routes>
          {screens[name].screens.map(({ name, path, screen, options }, idx) => (
            <Route
              key={idx}
              path={path === 'index' ? undefined : path}
              index={path === 'index' ? true : undefined}
              element={React.createElement(typeof screen === 'string' ? stacks[screen] : screen, {
                route: { params: {} }
              })}
            />
          ))}
        </Routes>
      </StackWrap>
    )
  }
}

const buildRoutes = (stacks: Record<StackName, IStack>, stackName: StackName): RouteObject => ({
  element: (
    <StackWrap>
      <Outlet />
    </StackWrap>
  ),
  children: stacks[stackName].screens.flatMap(({ path, index, screen: Screen, loader }) => {
    const child =
      typeof Screen === 'string'
        ? { path, index, loader, ...buildRoutes(stacks, Screen) }
        : { path, index, loader, element: <Screen route={{ params: {} }} /> }

    // react-router v6 dropped optional params (see https://github.com/remix-run/react-router/issues/7285)
    if (child.path?.endsWith('?')) {
      return [
        { ...child, path: child.path.replace(/\/?:[^/]+\?$/, '') },
        { ...child, path: child.path.replace(/\?$/, '') }
      ]
    }

    return child
  })
})

export const useRoutes = (domain?: Domain, subdomain?: string) => {
  const screens = useScreens(domain, subdomain)

  const tabs = screens['MainTabs'].screens.filter(screen => {
    const style = screen.tabOptions?.tabBarItemStyle
    return !style || typeof style !== 'object' || !('display' in style) || style.display !== 'none'
  })

  return [
    {
      path: '/',
      element: (
        <PortalHost>
          <WebContainer domain={domain} subdomain={subdomain} tabs={tabs}>
            <Outlet />
          </WebContainer>
        </PortalHost>
      ),
      errorElement: <SentryErrorBoundary />,
      children: buildRoutes(screens, 'Root').children
    }
  ]
}

export interface IRootProps {
  domain?: Domain
  subdomain?: string
}

export const Root: React.FC<IRootProps> = ({ domain, subdomain }) => {
  const routes = useRoutes(domain, subdomain)
  return useReactRouterRoutes(routes)
}

const opaqueHeader: Parameters<typeof createStack>[1] = {
  screenOptions: { headerTransparent: false, headerBlurEffect: undefined }
}

const stacks: Record<StackName, React.FC> = {
  Root,
  MainTabs: createStack('MainTabs'),
  Auth: createStack('Auth'),
  Explore: createStack('Explore', opaqueHeader),
  Shorts: createStack('Shorts', opaqueHeader),
  Messages: createStack('Messages'),
  Notifications: createStack('Notifications', { initialRouteName: 'Notifications' }),
  TeamMessages: createStack('TeamMessages'),
  TeamPositions: createStack('TeamPositions', opaqueHeader),
  EditProfile: createStack('EditProfile', opaqueHeader),
  Profile: createStack('Profile', { ...opaqueHeader, initialRouteName: 'Profile' }),
  TeamProfile: createStack('TeamProfile', opaqueHeader)
}
