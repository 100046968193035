import { compact, isArray, keyBy } from 'lodash'
import { plural, singular } from 'pluralize'

import { i18nText } from '../i18n-text'
import { Opaque } from '../lib/type-fest'
import { CompensationFrequency, CountryCode, IUser } from '../models'
import { ClientRoutePath } from '../routes'
import { CurrencyFns, getCurrencyFns } from './currency'
import { DateFns, getDateFns } from './date'
import { getNumberFns, NumberFns } from './number'

export type UntranslatedText = typeof i18nText[number] | number

export type TranslatedText = Opaque<'TranslatedText', string>

export const markTranslated = (text: string | number) =>
  (typeof text === 'number' ? text.toString() : text) as TranslatedText

export type SupportedLocaleCode =
  | 'af-ZA'
  | 'ar-SA'
  | 'ca-ES'
  | 'cs-CZ'
  | 'da-DK'
  | 'de-DE'
  | 'el-GR'
  | 'en-US'
  | 'es-419'
  | 'es-ES'
  | 'fi-FI'
  | 'fr-CA'
  | 'fr-FR'
  | 'he-IL'
  | 'hi-IN'
  | 'hr-HR'
  | 'hu-HU'
  | 'hy-AM'
  | 'id-ID'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'ms-MY'
  | 'nl-NL'
  | 'no-NO'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ro-RO'
  | 'ru-RU'
  | 'sk-SK'
  | 'sr-SP'
  | 'sv-SE'
  | 'th-TH'
  | 'tr-TR'
  | 'uk-UA'
  | 'ur-PK'
  | 'vi-VN'
  | 'zh-CN'
  | 'zh-TW'

export const supportedLatinLocaleCodes = [
  'af-ZA',
  'ca-ES',
  'cs-CZ',
  'da-DK',
  'de-DE',
  'en-US',
  'es-419',
  'es-ES',
  'fi-FI',
  'fr-CA',
  'fr-FR',
  'hr-HR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ms-MY',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'sk-SK',
  'sv-SE',
  'tr-TR',
  'vi-VN'
] as const

export const isSupportedLatinLocale = (locale: SupportedLocaleCode): locale is SupportedLatinLocaleCode =>
  supportedLatinLocaleCodes.includes(locale as SupportedLatinLocaleCode)

export const getBestLatinLocale = (locale: string): SupportedLatinLocaleCode =>
  isSupportedLatinLocale(locale as SupportedLocaleCode)
    ? (locale as SupportedLatinLocaleCode)
    : (defaultLocale as SupportedLatinLocaleCode)

export type SupportedLatinLocaleCode = typeof supportedLatinLocaleCodes[number]

export type SupportedNonLatinLocaleCode = Exclude<SupportedLocaleCode, SupportedLatinLocaleCode>

export const defaultLocale: SupportedLocaleCode = 'en-US'

export const devLocale: SupportedLocaleCode = 'en-US'

const arrayOfAllLocales = <T extends { code: SupportedLocaleCode; name: string }[]>(
  array: T & ({ code: SupportedLocaleCode }[] extends { code: T[number]['code'] }[] ? unknown : 'Invalid')
) => array as { code: SupportedLocaleCode; name: TranslatedText }[]

// generally following the same labels and ordering as google maps language selection
// (seems to be latin characters first, then localeCompare result)
export const supportedLocales = arrayOfAllLocales([
  { code: 'af-ZA', name: 'Afrikaans' },
  { code: 'id-ID', name: 'Bahasa Indonesia' },
  { code: 'ms-MY', name: 'Bahasa Melayu' },
  { code: 'ca-ES', name: 'català' },
  { code: 'cs-CZ', name: 'Čeština' },
  { code: 'da-DK', name: 'Dansk' },
  { code: 'de-DE', name: 'Deutsch' },
  { code: 'en-US', name: 'English' },
  { code: 'es-ES', name: 'Español (España)' },
  { code: 'es-419', name: 'Español (Latinoamérica)' },
  { code: 'fr-CA', name: 'Français (Canada)' },
  { code: 'fr-FR', name: 'Français (France)' },
  { code: 'hr-HR', name: 'Hrvatski' },
  { code: 'it-IT', name: 'Italiano' },
  { code: 'hu-HU', name: 'magyar' },
  { code: 'nl-NL', name: 'Nederlands' },
  { code: 'no-NO', name: 'norsk' },
  { code: 'pl-PL', name: 'polski' },
  { code: 'pt-BR', name: 'Português (Brasil)' },
  { code: 'pt-PT', name: 'Português (Portugal)' },
  { code: 'ro-RO', name: 'română' },
  { code: 'sk-SK', name: 'Slovenčina' },
  { code: 'fi-FI', name: 'Suomi' },
  { code: 'sv-SE', name: 'Svenska' },
  { code: 'vi-VN', name: 'Tiếng Việt' },
  { code: 'tr-TR', name: 'Türkçe' },
  { code: 'el-GR', name: 'Ελληνικά' },
  { code: 'ru-RU', name: 'Русский' },
  { code: 'sr-SP', name: 'српски (ћирилица)' },
  { code: 'uk-UA', name: 'Українська' },
  { code: 'hy-AM', name: 'Հայամ' },
  { code: 'he-IL', name: 'עברית' },
  { code: 'ur-PK', name: 'اردو' },
  { code: 'ar-SA', name: 'العربية' },
  { code: 'hi-IN', name: 'हिन्दी' },
  { code: 'th-TH', name: 'ไทย' },
  { code: 'ko-KR', name: '한국어' },
  { code: 'ja-JP', name: '日本語' },
  { code: 'zh-CN', name: '简体中文' },
  { code: 'zh-TW', name: '繁體中文' }
])

export const localeSitePrefixes: Record<SupportedLocaleCode, string | null> = {
  'af-ZA': 'za',
  'ar-SA': 'sa',
  'ca-ES': 'es-ca',
  'cs-CZ': 'cz',
  'da-DK': 'dk',
  'de-DE': 'de',
  'el-GR': 'gr',
  'en-US': null,
  'es-419': 'latam',
  'es-ES': 'es',
  'fi-FI': 'fi',
  'fr-CA': 'ca',
  'fr-FR': 'fr',
  'he-IL': 'il',
  'hi-IN': 'in',
  'hr-HR': 'hr',
  'hu-HU': 'hu',
  'hy-AM': 'am',
  'id-ID': 'id',
  'it-IT': 'it',
  'ja-JP': 'jp',
  'ko-KR': 'kr',
  'ms-MY': 'my',
  'nl-NL': 'nl',
  'no-NO': 'no',
  'pl-PL': 'pl',
  'pt-BR': 'br',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'sk-SK': 'sk',
  'sr-SP': 'sp',
  'sv-SE': 'se',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'uk-UA': 'ua',
  'ur-PK': 'pk',
  'vi-VN': 'vn',
  'zh-CN': 'cn',
  'zh-TW': 'tw'
}

const sitePrefixLocales = Object.fromEntries(
  Object.entries(localeSitePrefixes).map(([locale, prefix]) => [prefix, locale])
)

export const getWebsiteLocale = (path: string): SupportedLocaleCode | null => {
  const firstPart = path.replace(/^\//, '').split('/')[0]?.toLowerCase()
  return firstPart ? sitePrefixLocales[firstPart] ?? null : null
}

export const localizePath = (path: ClientRoutePath, locale: SupportedLocaleCode): ClientRoutePath => {
  const prefix = localeSitePrefixes[locale]
  return prefix ? (`/${prefix}${path}` as ClientRoutePath) : path
}

export const adjustTranslation = (
  locale: SupportedLocaleCode,
  translation: string,
  country: CountryCode | null
): string => {
  if (locale !== devLocale || !translation.includes('CV')) {
    return translation
  }

  // if we don't know the country, use "resume / CV". If we do, use "resume" for US, keep as "CV" for all others
  const lowerReplacement = country === null ? 'resume / CV' : country === 'US' ? 'resume' : null
  const upperReplacement = country === null ? 'Resume / CV' : country === 'US' ? 'Resume' : null

  // the machine translation is better w/ "CV" alone (no duplicate text), but "resume" is what's commonly used in US
  if (!/resume\s*\/\s*CV/gi.test(translation) && lowerReplacement && upperReplacement) {
    const adjusted = translation.replaceAll('CV', lowerReplacement)
    return adjusted.startsWith(lowerReplacement) ? adjusted.replace(lowerReplacement, upperReplacement) : adjusted
  }

  return translation
}

export const getLocaleName = (localeCode: SupportedLocaleCode): TranslatedText => {
  const locale = supportedLocales.find(locale => locale.code === localeCode)

  if (!locale) {
    throw new Error(`Unknown locale code: ${localeCode}`)
  }

  return locale.name
}

export function getBestAvailableLocale(localeCode: string | string[] | null, noFallback?: false): SupportedLocaleCode
export function getBestAvailableLocale(
  localeCode: string | string[] | null,
  noFallback: true
): SupportedLocaleCode | null
export function getBestAvailableLocale(
  localeCode: string | string[] | null,
  noFallback?: boolean
): SupportedLocaleCode | null {
  const localesWithRegion = keyBy(supportedLocales, locale => locale.code.toLowerCase())
  const localesWithoutRegion = keyBy(supportedLocales, locale => locale.code.toLowerCase().split('-')[0])

  const preferredLocales = compact(isArray(localeCode) ? localeCode : [localeCode]).map(locale => {
    const normalized = locale.toLowerCase().replace('_', '-')

    if (normalized === 'zh-hans') {
      return 'zh-cn'
    }

    if (normalized === 'zh-hant') {
      return 'zh-tw'
    }

    return normalized
  })

  for (const preferredLocale of preferredLocales) {
    const match = localesWithRegion[preferredLocale] ?? localesWithoutRegion[preferredLocale.split('-')[0]]

    if (match) {
      if (preferredLocale.startsWith('es')) {
        // "es" & "es-es" go to Castilian Spanish, all else go to LATAM Spanish
        return preferredLocale === 'es' || preferredLocale === 'es-es'
          ? localesWithRegion['es-es'].code
          : localesWithRegion['es-419'].code
      }

      if (preferredLocale.startsWith('fr')) {
        // "fr-ca" goes to Canadian French, all else go to Standard French
        return preferredLocale === 'fr-ca' ? localesWithRegion['fr-ca'].code : localesWithRegion['fr-fr'].code
      }

      if (preferredLocale.startsWith('pt')) {
        // "pt-br" goes to Brazilian Portuguese, all else go to European Portuguese
        return preferredLocale === 'pt-br' ? localesWithRegion['pt-br'].code : localesWithRegion['pt-pt'].code
      }

      if (preferredLocale.startsWith('zh')) {
        // "zh-tw" goes to Traditional Chinese, all else go to Simplified Chinese
        return preferredLocale === 'zh-tw' ? localesWithRegion['zh-tw'].code : localesWithRegion['zh-cn'].code
      }

      return match.code
    }
  }

  return noFallback ? null : defaultLocale
}

const tokenRe = /\{\{([^}]+)\}\}/g

type ReplacementValue = TranslatedText | number | IUser['username'] | ClientRoutePath

type ReplacementParam = Record<string, ReplacementValue> & { count?: number }

export type TranslateParams = UntranslatedText | [UntranslatedText, ReplacementParam]

export type TranslationFn = CurrencyFns &
  DateFns &
  NumberFns & {
    (text: TranslateParams, replace?: Record<string, ReplacementValue>): TranslatedText
    ready: boolean
  }

export const getTranslationFn = (
  locale: SupportedLocaleCode,
  translations: Record<string, string> | null,
  country: CountryCode | null
): TranslationFn => {
  const t = (text: TranslateParams, replace?: ReplacementParam) => {
    const [untranslated, replacement] = isArray(text) ? text : [text, replace]

    if (typeof untranslated === 'number') {
      return untranslated.toString() as TranslatedText
    }

    // show empty strings when we're loading the translations file
    if (locale !== devLocale && !translations) {
      return ' '.repeat(untranslated.length) as TranslatedText
    }

    let suffix = ''

    // if translation needs pluralization
    if (typeof replacement?.count !== 'undefined') {
      try {
        suffix = `_${new Intl.PluralRules(locale).select(replacement.count)}`
      } catch {
        console.log('Intl.PluralRules unsupported')
        // far from perfect, but Intl api mostly supported (https://caniuse.com/?search=pluralrules)
        const groups = ['zero', 'one', 'two', 'few', 'many', 'other']
        const orderedGroups =
          replacement.count === 0
            ? ['zero', ...groups]
            : replacement.count === 1
            ? ['one', ...groups]
            : ['other', ...groups]
        const group = orderedGroups.find(group => translations?.[`${untranslated}_${group}`])
        suffix = group ? `_${group}` : ''
      }
    }

    const key = `${untranslated}${suffix}`
    let translated = translations?.[key]

    // fallback to key if we don't have a translation
    if (typeof translated === 'undefined' || translated === '') {
      translated = untranslated
    }

    translated = adjustTranslation(locale, translated, country)

    // we don't load translations for devLocale, so we need to do some special handling for pluralization
    if (locale === devLocale && typeof replacement?.count !== 'undefined' && typeof translated === 'string') {
      translated = translated.replace(
        /\{\{count\}\}\s([^\s.,!]+)/,
        (_, word) => `{{count}} ${replacement.count === 1 ? singular(word) : plural(word)}`
      ) as TranslatedText
    }

    // simple translation without tokens
    if (typeof replacement === 'undefined') {
      return translated as TranslatedText
    }

    return translated.replace(tokenRe, (_, name) => replacement[name].toString()) as TranslatedText
  }

  Object.entries(
    getCurrencyFns(locale, (amount, frequency) => {
      const frequencyLabels: Record<CompensationFrequency, TranslatedText> = {
        Hour: t('{{amount}} / hour', { amount }),
        Day: t('{{amount}} / day', { amount }),
        Week: t('{{amount}} / week', { amount }),
        Month: t('{{amount}} / month', { amount }),
        Year: amount
      }

      return frequencyLabels[frequency] ?? amount
    })
  ).forEach(([key, value]) => {
    Object.defineProperty(t, key, { value, enumerable: true, configurable: false })
  })

  Object.entries(getDateFns(locale)).forEach(([key, value]) => {
    Object.defineProperty(t, key, { value, enumerable: true, configurable: false })
  })

  Object.entries(getNumberFns(locale)).forEach(([key, value]) => {
    Object.defineProperty(t, key, { value, enumerable: true, configurable: false })
  })

  t.ready = locale === devLocale || translations !== null

  return t as TranslationFn
}
