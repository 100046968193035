import { UntranslatedText } from 'core'
import React, { useState } from 'react'

import { useImportResume } from '~/hooks'
import { toast } from '~/lite'

import { FileInput } from '../FileInput'

export type ResumeUploadState = 'Uploading' | 'Uploaded'

interface IResumeImportFieldProps {
  onChange(state: ResumeUploadState | null, fileUrl?: string): void
  method?: 'Launchpad'
  buttonText?: UntranslatedText
  fallback?: React.ReactNode
}

export const ResumeImportField: React.FC<IResumeImportFieldProps> = ({ onChange, method, buttonText, fallback }) => {
  const [state, setState] = useState<ResumeUploadState | null>(null)

  const onError = () => {
    setState(null)
    onChange(null)
    toast.failure('Failed to upload CV. Please try again.')
  }

  const { mutateAsync: importResume } = useImportResume({
    onSuccess: data => {
      if (data.success) {
        setState('Uploaded')
        onChange('Uploaded', data.fileUrl)
      } else {
        onError()
      }
    },
    onError
  })

  return (
    <FileInput
      accept={['.pdf', '.docx', '.txt']}
      onInputChange={(resume, success) => {
        if (resume && success) {
          importResume({ resume, method })
        } else {
          setState(null)
          onChange(null)
        }

        if (!success) {
          toast.failure('Failed to upload CV. Please try again.')
        }
      }}
      onBeforeFileAdded={() => {
        setState('Uploading')
        onChange('Uploading')
        return true
      }}
      uploadFileAction="UploadFileForResumeImport"
      uploadPhotoAction="UploadPhotoForResumeImport"
      takePhotoAction="TakePhotoForResumeImport"
      uploadDocumentAction="UploadDocumentForResumeImport"
      buttonText={buttonText ?? 'Upload CV'}
      isLoading={state === 'Uploading'}
      isUploaded={state === 'Uploaded'}
      fallback={fallback}
      autoProceed
    />
  )
}
