import React, { forwardRef, Suspense } from 'react'

import type { IGridProps } from './types'

export type { IGridProps }

const LazyGrid = React.lazy(() => import('./Grid').then(module => ({ default: module.Grid })))

export function getGrid<TData = any>() {
  const Component = forwardRef<React.Ref<TData>, IGridProps<TData>>((props, ref) => (
    <Suspense fallback={null}>
      <LazyGrid ref={ref as any} {...props} />
    </Suspense>
  ))

  return Component
}
