import { useQueryClient } from '@tanstack/react-query'
import { formatCurrency, IMarketProfile, IMarketProfileSetupData, TranslatedText, UntranslatedText } from 'core'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Input } from '~/components'
import { marketStateQueryKey, useSetUpMarketProfile } from '~/hooks'
import { Button, Dialog, IDialogProps, Select, styled, Text, toast, useStore, useTranslation, View } from '~/lite'

import { useOptions } from './useOptions'

export const currentMarketProfileSetupVersion = 1

const Card = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const SalaryText = styled(Text)`
  font-weight: bold;
`

const Header = styled(Text)`
  font-weight: bold;
`

const Subheader = styled(Text)`
  opacity: 0.8;
  margin: 4px 0 8px;
`

const ErrorText = styled(Text)`
  margin-top: 4px;
  color: ${props => props.theme.error};
`

const SegmentedButton = styled(View)`
  flex-direction: row;
  gap: 8px;
`

interface ISetupDialogProps extends Omit<IDialogProps, 'trigger' | 'children'> {
  profile: IMarketProfile
  onClose(): void
}

export const SetupDialog: React.FC<ISetupDialogProps> = ({ profile, onClose, ...props }) => {
  const locale = useStore(state => state.locale)
  const t = useTranslation()
  const options = useOptions()
  const queryClient = useQueryClient()

  const { mutateAsync: setUpProfile, isLoading: isSettingUpProfile } = useSetUpMarketProfile({
    onSuccess: data => {
      if (data.success) {
        queryClient.setQueryData(marketStateQueryKey, data)
        onClose()
      } else {
        toast.failure()
      }
    },
    onError: () => {
      toast.failure()
    }
  })

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<IMarketProfileSetupData>()

  useEffect(() => {
    reset({
      compensationRate: profile.hourlyRate * 40 * 52,
      compensationCurrency: profile.currency,
      compensationFrequency: 'Yearly'
    })
  }, [reset, profile])

  const data = watch()

  const salaryUnitOptions: { value: IMarketProfileSetupData['compensationFrequency']; label: TranslatedText }[] = [
    { value: 'Hourly', label: t('Hourly') },
    { value: 'Daily', label: t('Daily') },
    { value: 'Weekly', label: t('Weekly') },
    { value: 'Monthly', label: t('Monthly') },
    { value: 'Yearly', label: t('Yearly') }
  ]

  const salaryUnitLabels: Record<IMarketProfileSetupData['compensationFrequency'], UntranslatedText> = {
    Hourly: '{{amount}} / hour',
    Daily: '{{amount}} / day',
    Weekly: '{{amount}} / week',
    Monthly: '{{amount}} / month',
    Yearly: '{{amount}} / year'
  }

  const salaryUnitLabel = salaryUnitLabels[data.compensationFrequency]
  const salaryAmount = formatCurrency(locale, data.compensationCurrency, data.compensationRate, true)

  return (
    <Dialog
      {...props}
      trigger={null}
      title="Setup"
      disableAutoFocus
      footer={
        <Button
          text="Save"
          onPress={handleSubmit(values =>
            setUpProfile({ ...values, id: profile.id, version: currentMarketProfileSetupVersion })
          )}
          disabled={isSettingUpProfile}
          loading={isSettingUpProfile}
          skipTracking
          primary
        />
      }
    >
      <View>
        <Header text="Pricing" />
        {errors.compensationRate?.message && <ErrorText rawText={errors.compensationRate.message as TranslatedText} />}
        <Subheader text="What is your usual pay? This helps the AI price your services." />
        <Card>
          <Controller
            control={control}
            name="compensationRate"
            rules={{ required: { value: true, message: t('This field is required') } }}
            render={({ field }) => <Input {...field} keyboardType="numeric" wrapStyle={{ width: 140 }} />}
          />
          <Controller
            control={control}
            name="compensationCurrency"
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                options={options.currency}
                value={options.currency.find(option => option.value === value) ?? null}
                onChange={value => onChange(value?.value)}
              />
            )}
          />
          <Controller
            control={control}
            name="compensationFrequency"
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                options={salaryUnitOptions}
                value={salaryUnitOptions.find(option => option.value === value) ?? null}
                onChange={value => onChange(value?.value)}
              />
            )}
          />
          <View flex={1} alignItems="end">
            <SalaryText rawText={salaryUnitLabel ? t(salaryUnitLabel, { amount: salaryAmount }) : salaryAmount} />
          </View>
        </Card>
      </View>
      <View marginTop={8}>
        <Header text="Co-Founder" />
        {errors.cofounderState?.message && <ErrorText rawText={errors.cofounderState.message as TranslatedText} />}
        <Subheader text="Connect with potential co-founders to jointly develop and grow your startup, sharing responsibilities and rewards." />
        <Controller
          control={control}
          name="cofounderState"
          rules={{ required: { value: true, message: t('This field is required') } }}
          render={({ field: { value, onChange } }) => (
            <SegmentedButton>
              {options.cofounder.map((option, i) => (
                <Button
                  key={option.value}
                  rawText={`${['👍', '🤔', '👎'][i]} ${option.label}` as TranslatedText}
                  active={value === option.value}
                  onPress={() => onChange(option.value)}
                  skipTracking
                />
              ))}
            </SegmentedButton>
          )}
        />
        {(data.cofounderState === 'Seeking' || data.cofounderState === 'Open') && (
          <Controller
            control={control}
            name="cofounderNote"
            render={({ field }) => (
              <Input type="textarea" placeholder="Add a comment" autoGrow {...field} wrapStyle={{ marginTop: 8 }} />
            )}
          />
        )}
      </View>
    </Dialog>
  )
}
