import {
  IAffiliateContent,
  IApiContent,
  IAutopilotContent,
  IBusinessContent,
  IChangelogContent,
  ICopilotContent,
  ILaunchpadContent,
  IPipelineContent,
  IPlusContent,
  IPromoteContent,
  IScreenerContent,
  ITeamDashboardContent
} from '../models'
import { Endpoint, get } from '../util/api'
import { TranslatedText } from '../util/i18n'

export type GetAffiliateContent = Endpoint<
  Record<string, never>,
  { success: true; data: IAffiliateContent<TranslatedText> }
>

export type GetApiContent = Endpoint<Record<string, never>, { success: true; data: IApiContent<TranslatedText> }>

export type GetAutopilotContent = Endpoint<
  Record<string, never>,
  { success: true; data: IAutopilotContent<TranslatedText> }
>

export type GetBusinessContent = Endpoint<
  Record<string, never>,
  { success: true; data: IBusinessContent<TranslatedText> }
>

export type GetChangelogContent = Endpoint<
  Record<string, never>,
  { success: true; data: IChangelogContent<TranslatedText> }
>

export type GetCopilotContent = Endpoint<
  Record<string, never>,
  { success: true; data: ICopilotContent<TranslatedText> }
>

export type GetLaunchpadContent = Endpoint<
  Record<string, never>,
  { success: true; data: ILaunchpadContent<TranslatedText> }
>

export type GetPipelineContent = Endpoint<
  Record<string, never>,
  { success: true; data: IPipelineContent<TranslatedText> }
>

export type GetPlusContent = Endpoint<Record<string, never>, { success: true; data: IPlusContent<TranslatedText> }>

export type GetPromoteContent = Endpoint<
  Record<string, never>,
  { success: true; data: IPromoteContent<TranslatedText> }
>

export type GetScreenerContent = Endpoint<
  Record<string, never>,
  { success: true; data: IScreenerContent<TranslatedText> }
>

export type GetTeamDashboardContent = Endpoint<
  Record<string, never>,
  { success: true; data: ITeamDashboardContent<TranslatedText> }
>

const getAffiliateContent = () => get<GetAffiliateContent>('/content/affiliate')

const getApiContent = () => get<GetApiContent>('/content/api')

const getAutopilotContent = () => get<GetAutopilotContent>('/content/autopilot')

const getBusinessContent = () => get<GetBusinessContent>('/content/business')

const getChangelogContent = () => get<GetChangelogContent>('/content/changelog')

const getCopilotContent = () => get<GetCopilotContent>('/content/copilot')

const getLaunchpadContent = () => get<GetLaunchpadContent>('/content/launchpad')

const getPipelineContent = () => get<GetPipelineContent>('/content/pipeline')

const getPlusContent = () => get<GetPlusContent>('/content/plus')

const getPromoteContent = () => get<GetPromoteContent>('/content/promote')

const getScreenerContent = () => get<GetScreenerContent>('/content/screener')

const getTeamDashboardContent = () => get<GetTeamDashboardContent>('/content/team-dashboard')

export const getAffiliateContentQuery = () => ({
  queryKey: ['content', 'affiliate'],
  queryFn: async () => {
    const results = await getAffiliateContent()
    return results.success ? results : null
  }
})

export const getApiContentQuery = () => ({
  queryKey: ['content', 'api'],
  queryFn: async () => {
    const results = await getApiContent()
    return results.success ? results : null
  }
})

export const getAutopilotContentQuery = () => ({
  queryKey: ['content', 'autopilot'],
  queryFn: async () => {
    const results = await getAutopilotContent()
    return results.success ? results : null
  }
})

export const getBusinessContentQuery = () => ({
  queryKey: ['content', 'business'],
  queryFn: async () => {
    const results = await getBusinessContent()
    return results.success ? results : null
  }
})

export const getChangelogContentQuery = () => ({
  queryKey: ['content', 'changelog'],
  queryFn: async () => {
    const results = await getChangelogContent()
    return results.success ? results : null
  }
})

export const getCopilotContentQuery = () => ({
  queryKey: ['content', 'copilot'],
  queryFn: async () => {
    const results = await getCopilotContent()
    return results.success ? results : null
  }
})

export const getLaunchpadContentQuery = () => ({
  queryKey: ['content', 'launchpad'],
  queryFn: async () => {
    const results = await getLaunchpadContent()
    return results.success ? results : null
  }
})

export const getPipelineContentQuery = () => ({
  queryKey: ['content', 'pipeline'],
  queryFn: async () => {
    const results = await getPipelineContent()
    return results.success ? results : null
  }
})

export const getPlusContentQuery = () => ({
  queryKey: ['content', 'plus'],
  queryFn: async () => {
    const results = await getPlusContent()
    return results.success ? results : null
  }
})

export const getPromoteContentQuery = () => ({
  queryKey: ['content', 'promote'],
  queryFn: async () => {
    const results = await getPromoteContent()
    return results.success ? results : null
  }
})

export const getScreenerContentQuery = () => ({
  queryKey: ['content', 'screener'],
  queryFn: async () => {
    const results = await getScreenerContent()
    return results.success ? results : null
  }
})

export const getTeamDashboardContentQuery = () => ({
  queryKey: ['content', 'team-dashboard'],
  queryFn: async () => {
    const results = await getTeamDashboardContent()
    return results.success ? results : null
  }
})
