import * as RAccordion from '@radix-ui/react-accordion'
import { TranslatedText } from 'core'
import React, { forwardRef } from 'react'
import type { StyledComponent } from 'styled-components'

import { css, keyframes, styled } from '~/lite'

import { Icon } from '../Icon/Icon'

const slideDown = keyframes`
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
`

const styles = {
  root: css`
    border-radius: 12px;
    background-color: ${props => props.theme.background};
    border-width: 1px;
  `,
  trigger: css`
  background-color: ${props => props.theme.background}};
  `
}

const AccordionRoot = styled(RAccordion.Root)<{ $simple?: boolean }>`
  width: 100%;
  overflow: hidden;
  ${props => (props.$simple ? '' : styles.root)}
` as StyledComponent<
  React.ForwardRefExoticComponent<
    (RAccordion.AccordionSingleProps | RAccordion.AccordionMultipleProps) &
      React.RefAttributes<HTMLDivElement> & { $simple?: boolean }
  >,
  any,
  RAccordion.AccordionSingleProps | RAccordion.AccordionMultipleProps,
  never
>

const TriggerWrap = styled(RAccordion.Header)`
  all: unset;
  display: flex;
`

const Trigger = styled(RAccordion.Trigger)<{ $simple?: boolean }>`
  all: unset;
  font-family: inherit;
  background-color: transparent;
  padding: 0 16px;
  height: 52px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  box-shadow: inset 0 1px 0 ${props => props.theme.border};
  cursor: pointer;
  color: ${props => (props.$simple ? `${props.theme.text}cc` : props.theme.text)};
  ${props => (props.$simple ? '' : styles.trigger)}

  &:disabled {
    pointer-events: none;
  }

  [data-state='open'] &:not(:disabled),
  &:hover {
    background-color: ${props => (props.$simple ? 'transparent' : props.theme.backgroundContrast)};
    color: ${props => props.theme.text};
  }
`

const AccordionItem = styled(RAccordion.Item)`
  overflow: hidden;

  &:first-child {
    margin-top: 0;

    ${Trigger} {
      box-shadow: none;
    }
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
`

const ContentWrap = styled(RAccordion.Content)<{ $simple?: boolean }>`
  overflow: hidden;
  color: ${props => props.theme.text};
  background-color: ${props => (props.$simple ? 'transparent' : props.theme.background)};

  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`

const Chevron = styled(Icon)`
  color: ${props => props.theme.primary};
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  ${Trigger}[data-state='open'] & {
    transform: rotate(180deg);
  }
`

const ContentText = styled.div`
  padding: 16px 16px 16px 32px;
  font-size: 16px;

  &:before {
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    width: 3px;
    display: block;
    content: '';
    background-color: ${props => props.theme.primary};
    border-radius: 3px;
  }
`

const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof RAccordion.Trigger> & { chevron?: boolean; simple?: boolean }
>(({ chevron, simple, children, ...props }, forwardedRef) => (
  <TriggerWrap>
    <Trigger {...props} ref={forwardedRef} $simple={simple}>
      {children}
      {chevron !== false && <Chevron icon="chevron-down" aria-hidden />}
    </Trigger>
  </TriggerWrap>
))

const AccordionContent = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RAccordion.Content> & { simple?: boolean }
>(({ simple, children, ...props }, forwardedRef) => (
  <ContentWrap {...props} ref={forwardedRef} $simple={simple}>
    <ContentText>{children}</ContentText>
  </ContentWrap>
))

export interface IAccordionProps {
  data: {
    trigger: TranslatedText | Exclude<NonNullable<React.ReactNode>, string>
    content: TranslatedText | Exclude<NonNullable<React.ReactNode>, string> | null
  }[]
  chevron?: boolean
  simple?: boolean
}

export const Accordion: React.FC<IAccordionProps> = ({ data, chevron, simple }) => (
  <AccordionRoot type="single" defaultValue="item-1" $simple={simple} collapsible>
    {data.map(({ trigger, content }, idx) => (
      <AccordionItem key={idx} value={`item-${idx + 1}`}>
        <AccordionTrigger chevron={chevron} disabled={content === null} simple={simple}>
          {trigger}
        </AccordionTrigger>
        {content !== null && <AccordionContent simple={simple}>{content}</AccordionContent>}
      </AccordionItem>
    ))}
  </AccordionRoot>
)
