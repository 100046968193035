import { IBusinessContent } from 'core'

import { View } from '~/lite'

import { ActionButton, HeaderSubtitle, HeaderTitle, HeroWrap, SecondaryActionButton, SectionContent } from '../styles'
import { CalendarTransition } from './illustration/CalendarTransition'

interface IHeroProps {
  data: IBusinessContent['hero']
}

export const Hero: React.FC<IHeroProps> = ({ data }) => {
  const onActionButtonPress = () => {
    const target = document.getElementById('pricing')
    target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  return (
    <HeroWrap>
      <SectionContent>
        <HeaderTitle rawText={data.header} />
        <HeaderSubtitle rawText={data.subheader} />
        <View flexDirection="row" alignItems="stretch" justifyContent="center" paddingBottom={32}>
          <ActionButton onPress={onActionButtonPress} rawText={data.cta} $enter primary skipTracking large />
          <SecondaryActionButton to="/interview" rawText={data.demo} $enter skipTracking large />
        </View>
        <CalendarTransition />
      </SectionContent>
    </HeroWrap>
  )
}
