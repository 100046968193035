import type { NativeStackNavigationOptions } from '@react-navigation/native-stack'
import type { ClientRoutePath } from 'core'
import { useNavigate } from 'react-router-dom'

import { IWebViewProps } from './components/types'

export { useLocation } from 'react-router-dom'

export const useHeaderHeight = () => 49

export const useBottomTabBarHeight = () => 40

export type NavigationAction = unknown

export const useNavigation = () => ({
  addListener: (type: string, callback: (e: unknown) => void) => () => {},
  dispatch: (action: unknown) => {}
})

// @todo need to implement on web
export const useScrollToTop = (ref: React.RefObject<any>) => {}

const openNewPage = (url: string): void => {
  window.open(url, '_blank', 'noopener')
}

export const openURL = async (url: string): Promise<void> => {
  const resolvedURL = new URL(url, window.location.href).toString()

  if (resolvedURL.startsWith('tel:')) {
    window.location.href = resolvedURL
  } else {
    openNewPage(resolvedURL)
  }
}

export const useHistory = () => {
  const navigate = useNavigate()

  return {
    push: (path: ClientRoutePath) => navigate(path),
    openWebView: (options: IWebViewProps) => openNewPage(options.url),
    goBack: () => navigate(-1),
    // @todo need to remove or implement
    navigate: (screen: string, params: any) => {},
    // @todo is there an equivalent in react-navigation?
    replace: (path: string) => navigate(path, { replace: true }),
    setOptions: (options: NativeStackNavigationOptions) => {},
    dispatch: (
      action: Readonly<{
        type: string
        payload?: object | undefined
        source?: string | undefined
        target?: string | undefined
      }>
    ) => {},
    addListener: (type: string, callback: () => void) => {},
    reset: () => {}
  }
}

export const hideSplashScreen = () => {}

export const clientUrl = 'https://remoteambition.com'

// this is used by react-native-render-html when turning relative urls into absolute
// (need to give it a base. otherwise it always gives relative urls an erroneous base)
export const relativeUrlBase = 'relativepath://'

const isAbsoluteUrlRe = /(?:^[a-z][a-z0-9+.-]*:|\/\/)/i

export const isRelativeUrl = (url: string): boolean => url.startsWith(relativeUrlBase) || !isAbsoluteUrlRe.test(url)

export const isInternalUrl = (url: string): boolean => url.startsWith(clientUrl)

export const isSpecialUrl = (url: string): boolean => !!url.match(/^(mailto|tel|sms):\/\//)

export const isExternalUrl = (url: string): boolean => !isRelativeUrl(url) && !isInternalUrl(url) && !isSpecialUrl(url)

export const getLinkHandler = (history: ReturnType<typeof useHistory>) => (href: string) => {
  // don't know how to resolve links to relative content
  if (isRelativeUrl(href)) {
    return null
  }

  if (isInternalUrl(href)) {
    history.push(href.replace(clientUrl, '') as ClientRoutePath)
    return null
  }

  if (isExternalUrl(href)) {
    history.openWebView({ type: 'ExternalSite', url: href })
    return null
  }

  if (isSpecialUrl(href)) {
    openURL(href)
    return null
  }

  return null
}
