import { UntranslatedText } from 'core'
import { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { useTranslation } from '~/lite'

/**
 * Connects react-hook-form's validation system with the errors we return from our API.
 *
 * @param setError setError function returned by react-hook-form
 */
export function useApiErrors<
  FieldValues extends Record<string, any>,
  ErrorValues extends Record<string, any> = Partial<
    { general?: UntranslatedText } & Record<keyof FieldValues, UntranslatedText>
  >
>(setError: UseFormReturn<FieldValues>['setError']): [ErrorValues, (errors: ErrorValues) => void] {
  const t = useTranslation()
  const [apiErrors, doSetApiErrors] = useState<ErrorValues>({} as ErrorValues)

  const setApiErrors = useCallback<(errors: ErrorValues) => void>(
    errors => {
      doSetApiErrors(errors)

      Object.entries<UntranslatedText>(errors).forEach(([key, message]) => {
        // if the api error doesn't have an equivalent field in the form, ignore it
        // otherwise, react-hook-form will never enter a valid state again, preventing re-submission of the form
        if (key !== 'general' && message) {
          setError(key as any, { message: t(message) })
        }
      })
    },
    [setError, t]
  )

  return [apiErrors, setApiErrors]
}
