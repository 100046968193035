import { IPosition } from 'core'
import React from 'react'
import { useParams } from 'react-router-dom'

import { usePositionDetail, useViewPosition } from '~/hooks'
import { Container, isNotBot, Loader, MetaTags, useStore } from '~/lite'

import { PositionContent } from '../PositionContent'
import { JobDetailProps } from './types'

export const JobDetail: React.FC<JobDetailProps> = ({ id, skipMetaTags, containerViewProps }) => {
  const params = useParams()
  const positionId = id || (params.positionId as IPosition['id'])
  const { data, isInitialLoading } = usePositionDetail(positionId)
  const agentCommandId = useStore(state => state.agentCommandId)

  useViewPosition(isNotBot && positionId ? positionId : null, agentCommandId)

  return (
    <Container id="job-detail-container" flex={1} paddingBottom="m" maxWidth={832} {...containerViewProps}>
      {data && !skipMetaTags && (
        <MetaTags type="Position" position={data} team={data.teamDetail} location={data.locationDetail} />
      )}
      <Loader isLoading={isInitialLoading}>{data ? <PositionContent context="StandAlone" data={data} /> : null}</Loader>
    </Container>
  )
}
