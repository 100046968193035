import { styled } from '~/lite'
import { breakpoint } from '~/util'

export const SkillCardContainer = styled.div`
  width: 110px;
  margin: 10px 10px 0;
  @media ${breakpoint.xl} {
    width: 160px;
  }
  @media ${breakpoint['2xl']} {
    width: 220px;
  }
`
