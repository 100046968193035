import type { ClientRoutePath } from 'core'

import { deleteData, isWeb, loadData, saveData, useHistory } from '~/lite'

export const usePromptAuth = () => {
  const history = useHistory()
  const key = 'authRedirect' as const

  return {
    redirect: async (redirectPath?: ClientRoutePath) => {
      if (redirectPath) {
        history.push(redirectPath)
        return
      }
      const path = await loadData(key)

      if (path) {
        await deleteData(key)
        history.push(path)
      } else {
        history.push('/')
      }
    },

    prompt: async () => {
      if (isWeb) {
        await saveData(key, window.location.pathname as ClientRoutePath)
      }

      history.push('/login')
    }
  }
}
