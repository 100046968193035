import * as RadioGroup from '@radix-ui/react-radio-group'
import { TranslatedText, UntranslatedText } from 'core'
import React, { useId } from 'react'

import { styled } from '~/lite'

import { Text } from '../Text/Text'
import { View } from '../View/View'

const RadioItemWrap = styled(View)`
  flex-direction: row;
  align-items: stretch;
`

const RadioGroupItem = styled(RadioGroup.Item)`
  all: unset;
  background: ${props => props.theme.inputBackground};
  width: 25px;
  height: 25px;
  margin: 8px 4px;
  border-radius: 100%;
  box-shadow: 0 0 0 1px ${props => props.theme.inputBorderColor};

  &:hover {
    background: ${props => props.theme.primary}66;
    box-shadow: 0 0 0 1px ${props => props.theme.primary};
  }

  &:focus {
    background: ${props => props.theme.primary}33;
  }

  &[data-state='checked'] {
    background: ${props => props.theme.inputBackground};
  }
`

const RadioGroupIndicator = styled(RadioGroup.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${props => props.theme.primary};
  }
`

const Label = styled.label`
  flex: 1;
  padding: 4px;
  display: flex;
  align-items: center;
`

interface IRadioItemProps {
  label?: UntranslatedText
  rawLabel?: TranslatedText
  value: string
}

const RadioItem: React.FC<IRadioItemProps> = ({ label, rawLabel, value }) => {
  const id = useId()

  return (
    <RadioItemWrap>
      <RadioGroupItem value={value} id={id}>
        <RadioGroupIndicator />
      </RadioGroupItem>
      <Label htmlFor={id}>{rawLabel ? <Text rawText={rawLabel} /> : <Text text={label ?? ''} />}</Label>
    </RadioItemWrap>
  )
}

const RadioGroupRoot = styled(RadioGroup.Root)`
  display: flex;
  flex-direction: column;
`

export type RadioOption = Readonly<{ label?: UntranslatedText; rawLabel?: TranslatedText; value: string }>

export interface IRadioProps<T extends RadioOption> {
  ariaLabel?: TranslatedText
  options: T[]
  value?: T['value']
  onChange(value: T['value']): void
}

export function Radio<T extends RadioOption>({
  ariaLabel,
  options,
  value,
  onChange,
  ...props
}: IRadioProps<T>): ReturnType<React.FC<IRadioProps<T>>> {
  return (
    <RadioGroupRoot {...props} value={value} aria-label={ariaLabel} onValueChange={value => onChange(value)}>
      {options.map((option, index) => (
        <RadioItem key={index} label={option.label} rawLabel={option.rawLabel} value={option.value} />
      ))}
    </RadioGroupRoot>
  )
}
