import { MagicLink } from '@ambition/module-shared'
import { encodeToken } from 'core'
import React from 'react'

import { Button, FlexHorizontal } from '~/components'
import { useCreateApplication } from '~/hooks'
import { css, space, styled, useHistory, useStore, View } from '~/lite'
import { triggerHapticFeedback } from '~/util'

import { Application } from '../Application'
import { ActionButtonsProps } from './types'

const ButtonContainer = styled(View)`
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  gap: 16px;
`

const ActionButton = styled(Button)<{ $applied?: boolean }>`
  border-radius: 8px;

  ${props =>
    props.$applied &&
    css`
      background: #34b792;

      &:hover {
        background: #34b792;
      }
    `}
`

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  id,
  team,
  hasForm,
  enabledInterview,
  title,
  applicationUrl,
  scrollY,
  topOffsetToBeShowed,
  enableMagicLinkAnimation,
  getJobDashboardPath,
  onApplyButtonPress,
  onStudioButtonPress
}) => {
  const history = useHistory()

  const { isAuthenticated, applied, addApplied, removeApplied } = useStore()
  const [isApplicationBottomSheetOpened, setIsApplicationBottomSheetOpened] = React.useState<boolean | null>(null)
  const hasApplied = applied?.includes(id)

  const { mutate: createApplication } = useCreateApplication({
    onMutate: () => {
      onApplyButtonPress?.(id, false)
      addApplied(id)
      triggerHapticFeedback()
    },
    onError: () => {
      removeApplied(id)
    }
  })

  const handleApply = () => {
    if (hasForm) {
      if (onApplyButtonPress) {
        onApplyButtonPress(id, hasForm)
        return
      }

      setIsApplicationBottomSheetOpened(true)
      return
    }

    if (applicationUrl) {
      if (!hasApplied) {
        createApplication({ positionId: id })
      }

      history.openWebView({ type: 'ExternalSite', url: applicationUrl, headerTitle: title })
    }
  }

  let opacity = 1
  if (topOffsetToBeShowed && scrollY !== undefined) {
    if (scrollY + 200 < topOffsetToBeShowed) {
      opacity = 0
    }
  }

  return (
    <>
      <FlexHorizontal marginX="m" marginTop="m" opacity={opacity} zIndex={100}>
        {hasForm || isAuthenticated ? (
          <ButtonContainer maxWidth={enabledInterview || onStudioButtonPress ? 600 : 300}>
            <ActionButton
              $applied={hasApplied}
              text={hasApplied ? 'Applied' : 'Apply'}
              onPress={handleApply}
              action={hasApplied && !hasForm ? 'OpenExternalWebView' : 'ApplyApplicationInPositionDetails'}
              icon={hasApplied ? 'checkmark-outline' : undefined}
              rightIcon={!hasForm && !hasApplied ? 'open-outline' : undefined}
              flex={1}
              large
              primary
            />
            {!!onStudioButtonPress && !hasForm && (
              <ActionButton
                text="Studio"
                icon="sparkles"
                onPress={() => onStudioButtonPress(id)}
                action="VisitStudioViaJob"
                large
              />
            )}
            {enabledInterview && (
              <ActionButton
                text="Start Interview"
                to={`/jobs/${encodeToken(id)}/interview`}
                action="StartInterview"
                large
              />
            )}
            {/*<View justifyContent="center" alignItems="center" width={70}>
              <BookmarkButton
                positionId={id}
                action="SavePositionToCollection"
                iconSize={largeApplyButton ? 26 : 24}
                iconColor={theme.text}
              />
            </View>*/}
          </ButtonContainer>
        ) : (
          <MagicLink
            variant="Application"
            submitButtonText="Continue"
            onSuccess={handleApply}
            viewProps={{ marginX: -space.m }}
            horizontal={!getJobDashboardPath}
            skipConfirmation
            enableAnimation={enableMagicLinkAnimation}
          />
        )}
      </FlexHorizontal>
      {isApplicationBottomSheetOpened !== null && (
        <Application
          positionId={id}
          team={team}
          getJobDashboardPath={getJobDashboardPath}
          isOpen={isApplicationBottomSheetOpened}
          onClose={() => setIsApplicationBottomSheetOpened(false)}
        />
      )}
    </>
  )
}
