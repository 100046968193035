import { getFirstValue, getSkillIcon, IExperience, ISkill } from 'core'
import { lighten } from 'polished'
import React, { useCallback, useMemo } from 'react'

import { Checkmark, css, FlexHorizontal, Pressable, styled, Svg, Text, View } from '~/components'
import { useTranslation } from '~/hooks'
import { getSkillInitials, isWeb } from '~/util'

const StyledCheckmark = styled(Checkmark)`
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 4px;
  background: ${props => props.theme.primary};
  color: #fff;
  border-radius: 100px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
`

const CompactIcon = styled(View)<{ $size?: number }>`
  width: ${props => props.$size || '34'}px;
  height: ${props => props.$size || '34'}px;
  padding: 5px;
  overflow: hidden;
  background-color: ${props => props.theme.border};
  border-color: ${props => props.theme.border};
  ${isWeb &&
  css`
    border-radius: 50%;
    border-style: inherit;
    border-width: inherit;
    border-image: inherit;
  `}
  ${!isWeb &&
  css`
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const CompactInfo = styled(View)`
  margin-left: 5px;
`

const CompactName = styled(Text)`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`

const CompactDuration = styled(Text)`
  font-size: 12px;
  opacity: 0.6;
  font-weight: 500;
  line-height: 14px;
`

const CompactWrap = styled(FlexHorizontal)`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
`

const LargeIcon = styled(View)`
  position: relative;
  padding: 10px;
  border-radius: 15px;
  background-color: ${lighten(0.1, '#30404d')};
  height: 70px;
  min-width: 70px;
  max-width: 70px;
`
/* background: linear-gradient(#e4eafd 0%, #bcc8f1 100%); */

const LargeName = styled(Text)`
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  ${isWeb &&
  css`
    font-size: 0.9em;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`

const LargeWrap = styled(Pressable)<{ $animate: boolean }>`
  margin: 5px;
  width: 70px;
`

const SkillInitialsWrap = styled(Text)`
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  text-align: center;
  color: #fff;
  font-size: 24px;
`

interface ISkillInitialsProps {
  skill: ISkill
}

const SkillInitials: React.FC<ISkillInitialsProps> = ({ skill }) => (
  <SkillInitialsWrap rawText={getSkillInitials(skill)} />
)

interface ISkillProps {
  skill: ISkill
  experience?: IExperience
  selected?: boolean
  onPress?(skill: ISkill): void
  compact?: boolean
  onlyIcon?: boolean
  name?: boolean
  animate?: boolean
  styleOverride?: ISkill['style']
}

export const Skill: React.FC<ISkillProps> = ({
  skill,
  experience,
  selected,
  onPress,
  compact,
  onlyIcon,
  name = true,
  animate = true,
  styleOverride
}) => {
  const t = useTranslation()
  const onPressHandler = useCallback(() => onPress && onPress(skill), [onPress, skill])

  const style = useMemo(
    () => ({ ...skill.style, ...(!isWeb && { background: undefined }), ...styleOverride }),
    [skill, styleOverride]
  )

  const uri = skill.icon ? getSkillIcon(skill) : null

  if (onlyIcon) {
    // className={wrapClassName}
    return (
      <CompactWrap /* onPress={onPressHandler} */>
        <CompactIcon $size={24} style={style}>
          {!skill.icon && <SkillInitials skill={skill} />}
          <Svg uri={uri} height="100%" width="100%" />
        </CompactIcon>
      </CompactWrap>
    )
  }

  if (compact) {
    return (
      <CompactWrap /* onPress={onPressHandler} */>
        <CompactIcon style={style}>
          {!skill.icon && <SkillInitials skill={skill} />}
          <Svg uri={uri} height="100%" width="100%" />
        </CompactIcon>
        <CompactInfo>
          {skill.name && <CompactName rawText={getFirstValue(skill.name)} />}
          {experience && experience.minYears !== null && (
            <CompactDuration rawText={t('{{count}} years', { count: experience.minYears })} />
          )}
        </CompactInfo>
      </CompactWrap>
    )
  }

  return (
    <LargeWrap $animate={animate} onPress={onPressHandler}>
      <LargeIcon style={style}>
        {selected && <StyledCheckmark /* icon="checkmark" */ />}
        {!skill.icon && <SkillInitials skill={skill} />}
        <Svg uri={uri} height="100%" width="100%" />
      </LargeIcon>
      {name && skill.name && <LargeName rawText={getFirstValue(skill.name)} />}
    </LargeWrap>
  )
}
