import { defaultJobScope, ILocation, JobScope } from 'core'
import { StateCreator } from 'zustand'

import { IJobScopeSlice, IStoreState } from './types'

export const getJobScopeValues = (scope: JobScope) => {
  if (!scope) {
    return null
  }

  const [locationId, locationName] = scope.split('|')

  return {
    jobScope: scope,
    selectedLocation: {
      locationId: locationId as ILocation['id'],
      locationName: locationName as ILocation['name']
    }
  }
}

export const createJobScopeSlice: StateCreator<IStoreState, [], [], IJobScopeSlice> = set => ({
  jobScope: defaultJobScope,
  preferRemoteJobs: false,
  isUpdatingJobScope: false,
  setIsUpdatingJobScope: isUpdatingJobScope => {
    set(() => ({ isUpdatingJobScope }))
  },
  setPreferRemoteJobs: preferRemoteJobs => {
    set(() => ({ preferRemoteJobs }))
  },
  selectedLocation: null,
  occupationNavigation: null,
  jobScopeChangedFromSearchbar: false,
  setJobScope: ({ scope, fromSearchbar }) => {
    const values = getJobScopeValues(scope)

    if (values) {
      set(() => ({ ...values, jobScopeChangedFromSearchbar: fromSearchbar }))
    }
  },
  setSelectedLocation: location => {
    set(() => ({ selectedLocation: location }))
  },
  setOccupationNavigation: async state => {
    set(() => ({ occupationNavigation: state }))
  },
  resetJobScope: () => {
    set(() => ({
      jobScope: defaultJobScope,
      selectedLocation: null
    }))
  },
  datePosted: 'all',
  setDatePosted: datePosted => {
    set(() => ({ datePosted: datePosted }))
  },
  distanceRadiusFilter: 0,
  setDistanceRadiusFilter: distanceRadiusFilter => {
    set(() => ({ distanceRadiusFilter: distanceRadiusFilter }))
  },
  compensationFilter: 0,
  setCompensationFilter: compensationFilter => {
    set(() => ({ compensationFilter: compensationFilter }))
  },
  compensationFrequencyFilter: 'Year',
  setCompensationFrequencyFilter: compensationFrequencyFilter => {
    set(() => ({ compensationFrequencyFilter: compensationFrequencyFilter }))
  }
})
