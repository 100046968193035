import { compact } from 'lodash'

import {
  Breadcrumb,
  CompactPositionRecord,
  CountryCode,
  ICountry,
  IFeedSection,
  ILocation,
  IOccupation,
  IUserPreference,
  JobMacroData,
  LandingPageSlug,
  PageableResponse
} from '../models'
import { Endpoint, get, post } from '../util/api'
import { buildQueryString } from '../util/helpers'
import { TranslatedText } from '../util/i18n'
import { IFeedSectionQuery } from '../util/query'
import { LANDING_PAGE_POSITION_PAGE_LIMIT } from './positions'

export type LandingPageResult = {
  country: ICountry | null
  location: ILocation | null
  pageTitle: TranslatedText
  header: TranslatedText
  occupations?: Breadcrumb<IOccupation>
  sections?: IFeedSection[]
}

export type GetLandingPage = Endpoint<
  { firstSlug: string; secondSlug?: string },
  {
    success: true
    data: LandingPageResult
  }
>

export type GetUserFeedSections = Endpoint<
  Record<string, never>,
  {
    success: true
    data: {
      sections: PageableResponse<IFeedSection>
    }
  }
>

export type GetFeedSectionDetail = Endpoint<
  { feedSectionId?: IFeedSection['sectionId'] },
  {
    success: true
    data: {
      sectionDetail?: IFeedSection | null
      positions: PageableResponse<CompactPositionRecord>
      subTitleQueries: string[]
    }
  }
>

export type GenerateJobMacro = Endpoint<
  Record<string, never>,
  {
    success: true
    data: {
      jobMacroData: JobMacroData[]
    }
  },
  {
    jobIds: CompactPositionRecord['id'][]
  }
>

export type GetJobMacros = Endpoint<
  Record<string, never>,
  {
    success: true
    data: {
      jobMacroData: JobMacroData[]
    }
  },
  {
    jobIds: CompactPositionRecord['id'][]
  }
>

export const getLandingPage = ({
  countryCode,
  slug,
  locationId,
  excludePositions,
  page,
  limit,
  userPreference
}: {
  countryCode: CountryCode | null
  slug: LandingPageSlug | null
  locationId: ILocation['id'] | null
  excludePositions?: number
  page?: number
  limit?: number
  userPreference?: IUserPreference
}) => {
  const path =
    compact([countryCode, slug])
      .map(part => part.toLowerCase())
      .join('/') || 'home'

  return get<GetLandingPage>(
    `/landing-pages/${path}${buildQueryString({
      locationId,
      excludePositions,
      page,
      limit,

      date: userPreference?.date?.toString(),
      distanceRadius: userPreference?.distanceRadius?.toString(),
      preferRemote: userPreference?.preferRemote ? '1' : '0',
      compensation: userPreference?.compensation?.toString(),
      compensationFrequency: userPreference?.compensationFrequency
    })}`
  )
}

export const getLandingPageQuery = ({
  countryCode,
  slug,
  locationId,
  userPreference,
  excludePositions,
  page = 0,
  limit = LANDING_PAGE_POSITION_PAGE_LIMIT
}: {
  countryCode: CountryCode | null
  slug: LandingPageSlug | null
  locationId: ILocation['id'] | null
  userPreference: IUserPreference
  excludePositions?: number
  page?: number
  limit?: number
}) => ({
  queryKey: [
    'landing-page',
    countryCode?.toLowerCase() ?? null,
    slug?.toLowerCase() ?? null,
    locationId ?? null,
    excludePositions ?? null,
    page ?? null,
    limit ?? null,
    userPreference.date,
    userPreference.distanceRadius,
    userPreference.preferRemote,
    userPreference.compensation,
    userPreference.compensationFrequency
  ],
  queryFn: async () => {
    const results = await getLandingPage({ countryCode, slug, locationId, page, limit, userPreference })
    return results.success ? results.data : null
  }
})

export const getFeedSectionDetail = (feedSectionId: IFeedSection['sectionId'], query?: IFeedSectionQuery) => {
  return get<GetFeedSectionDetail>(`/landing-pages/sections/${feedSectionId}${buildQueryString(query)}`)
}

export const getFeedSections = (query?: IFeedSectionQuery) => {
  return get<GetUserFeedSections>(`/landing-pages/sections${buildQueryString(query)}`)
}

export const generateJobMacros = (data: GenerateJobMacro['body']) => {
  return post<GenerateJobMacro>('/landing-pages/jobs/prompt', data)
}

export const getJobMacros = (data: GetJobMacros['body']) => {
  return post<GetJobMacros>('/landing-pages/jobs/macros', data)
}
