import React from 'react'

import { Svg } from './Svg'
import { ILogoProps } from './types'

const logoXml = `
<svg role="img" aria-label="RemoteAmbition" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" viewBox="0 0 375 375">
  <title>RemoteAmbition</title>
  <path d="M187.46.066c79.938 0 144.75 142.371 144.75 226.082 0 198.383-289.5 198.383-289.5 0C42.71 142.438 107.513.066 187.46.066" style="stroke:none;fill-rule:nonzero;fill:#706eff;fill-opacity:1" />
  <path d="M106.305 1.297c58.105 0 105.215 103.492 105.215 164.34 0 144.203-210.45 144.203-210.45 0 0-60.848 47.114-164.34 105.235-164.34" style="stroke:none;fill-rule:nonzero;fill:#a9a8fa;fill-opacity:1" transform="translate(75 21)" />
  <path d="M65.148.504c34.907 0 63.211 62.176 63.211 98.742 0 86.637-126.445 86.637-126.445 0C1.918 62.684 30.22.504 65.148.504" style="stroke:none;fill-rule:nonzero;fill:#cbcbfc;fill-opacity:1" transform="translate(110 43)" />
</svg>
`

export const Logo: React.FC<ILogoProps> = ({ size }) => <Svg xml={logoXml} width={size} height={size} />
