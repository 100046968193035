import React from 'react'

import { breakpoint, styled, View } from '~/lite'

import { Directory } from './Directory'
import { LaunchpadSetup } from './LaunchpadSetup'

const Wrap = styled(View)`
  width: 100%;
  flex-direction: column;
  align-items: stretch;

  @media ${breakpoint.lg} {
    flex-direction: row;
    justify-content: stretch;
  }
`

const Sidebar = styled(View)`
  align-items: stretch;
  padding: 16px;
  gap: 16px;
  background: ${props => (props.theme.dark ? 'transparent' : props.theme.backgroundContrast)};

  @media ${breakpoint.lg} {
    width: 500px;

    &:before {
      position: fixed;
      display: block;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 500px;
      pointer-events: none;
      background: ${props => (props.theme.dark ? 'transparent' : props.theme.backgroundContrast)};
    }
  }
`

const Main = styled(View)`
  position: relative;
  flex: 1;
  align-items: stretch;
`

export interface ILaunchpadProps {
  locale: string
}

export const Launchpad: React.FC<ILaunchpadProps> = () => (
  <Wrap>
    <Sidebar>
      <LaunchpadSetup />
    </Sidebar>
    <Main>
      <Directory />
    </Main>
  </Wrap>
)
