import { useQueryClient } from '@tanstack/react-query'
import { SupportedLocaleCode, supportedLocales } from 'core'
import React from 'react'

import { Icon, List, ListItem, Menu } from '~/components'
import { useBreakpoints, useHistory, useStore, useUpdateLocale } from '~/hooks'
import { trackEvent } from '~/lite'

type ILocale = typeof supportedLocales[number]

interface ILocaleSettingsProps {
  variant: 'Menu' | 'Screen'
}

export const LocaleSettings: React.FC<ILocaleSettingsProps> = ({ variant }) => {
  const { setLocale, locale, theme } = useStore()
  const { isPhone } = useBreakpoints()
  const history = useHistory()
  const { mutateAsync } = useUpdateLocale()
  const queryClient = useQueryClient()

  const onLocaleItemPress = async (localeCode: SupportedLocaleCode) => {
    trackEvent('Select', { item: 'Locale', localeCode })
    setLocale(localeCode)

    // need to update user before refetching since i18nMiddleware prioritizes user record locale over header locale
    await mutateAsync({ oldLocale: locale, newLocale: localeCode })

    await queryClient.refetchQueries()

    if (isPhone) {
      history.goBack()
    }
  }

  if (variant === 'Menu') {
    return (
      <Menu
        align="end"
        triggerStyle={{ flex: 1, cursor: 'pointer' }}
        items={supportedLocales.map(item => ({
          rawText: item.name,
          selected: item.code === locale,
          onPress: () => onLocaleItemPress(item.code)
        }))}
      >
        <Icon icon="globe-outline" size={28} marginX={8} />
      </Menu>
    )
  }

  if (variant === 'Screen') {
    return (
      <List<ILocale>
        data={supportedLocales}
        renderItem={({ item }) => (
          <ListItem
            key={item.code}
            onPress={() => onLocaleItemPress(item.code)}
            title={item.name}
            accessoryRight={item.code === locale ? () => <Icon icon="checkmark" color={theme.primary} /> : undefined}
            forceLTR
          />
        )}
      />
    )
  }

  return null
}
