import { IPosition, RedirectApplicationUrl } from 'core'
import React, { useMemo } from 'react'

import { Loader, styled, View } from '~/components'
import { useHistory, usePosition, usePositionRawApplicationUrl } from '~/hooks'

import { PositionForm } from '../form/PositionForm'

const FormWrap = styled(View)`
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 12px;
  margin: 16px auto;
`

export interface IEditPositionProps {
  positionId: IPosition['id']
  onChange?(position: IPosition): void
}

export const EditPosition: React.FC<IEditPositionProps> = ({ positionId, onChange }) => {
  const history = useHistory()
  const { data: positionData, isLoading: isFetchingPosition } = usePosition(positionId)
  const { data: applicationUrl, isLoading: isFetchingPositionRawApplicationUrl } =
    usePositionRawApplicationUrl(positionId)

  const combinedPositionData = useMemo(() => {
    if (!positionData) {
      return null
    }
    return {
      ...positionData,
      position: {
        ...positionData.position,
        ...(applicationUrl ? { applicationUrl: applicationUrl as RedirectApplicationUrl } : {})
      }
    }
  }, [positionData, applicationUrl])

  if (isFetchingPosition || isFetchingPositionRawApplicationUrl) {
    return <Loader />
  }

  if (!combinedPositionData) {
    history.push('/')
    return null
  }

  return (
    <FormWrap>
      <PositionForm
        position={combinedPositionData.position}
        onChange={onChange}
        isCreating={false}
        teamUsername={combinedPositionData.team.username}
        teamName={combinedPositionData.team.name}
      />
    </FormWrap>
  )
}
