import { ClientRoutePath, ExternalUrl, isExternalUrl, markTranslated, TranslatedText } from 'core'
import { isString } from 'lodash'
import React, { useCallback, useState } from 'react'
import {
  alignItems,
  AlignItemsProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  minHeight,
  MinHeightProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps
} from 'styled-system'

import { openNewPage } from '~/dom'
import { useHistory } from '~/hooks'
import { SelectEventProperties, styled, StyledSystemTheme, trackEvent, View } from '~/lite'
import { fixSpacePropsForNative } from '~/lite'

import { Text } from '../Text/Text'
import { Link, ListItemInfo, Wrap } from './styles'

export interface IListItemProps {
  title?: TranslatedText
  subtitle?: TranslatedText
  description?: TranslatedText | React.ReactElement
  accessoryLeft?: React.FC<any> | React.ReactElement
  accessoryRight?: React.FC<any> | React.ReactElement
  to?: ClientRoutePath | ExternalUrl
  action?: SelectEventProperties
  selected?: boolean
  disabled?: boolean
  onPress?(e?: any): any
  testID?: string
  forceLTR?: boolean
  openInNewWindow?: boolean
  variant?: 'Menu'
  skeleton?: boolean
  children?: React.ReactNode
}

export type ListItemStyledProps = SpaceProps<StyledSystemTheme> &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  PositionProps &
  TypographyProps &
  AlignItemsProps &
  MinHeightProps

export const InnerListItem: React.FC<IListItemProps & ListItemStyledProps> = ({
  title,
  subtitle,
  description,
  accessoryLeft,
  accessoryRight,
  action,
  selected,
  to,
  onPress,
  testID,
  forceLTR,
  openInNewWindow,
  variant,
  skeleton,
  children,
  fontSize,
  ...rest
}) => {
  const history = useHistory()

  /*@todo Math.random() + SSR = mismatch
  const [skeletonStyle] = useState({
    width: `${Math.floor(40 + Math.random() * 20)}%`,
    ...(variant === 'Menu' ? { height: 14, marginBottom: 0, borderRadius: 8 } : {})
  })*/

  const [skeletonStyle] = useState({
    width: '50%',
    ...(variant === 'Menu' ? { height: 14, marginBottom: 0, borderRadius: 8 } : {})
  })

  const onPressHandler = useCallback(
    (e?: any) => {
      action && trackEvent('Select', action)
      const cancel = onPress?.()

      if (to) {
        // prevent link from reloading page
        e?.preventDefault?.()

        // prevent press event from bubbling up to parent ListItem, firing onPress twice
        e?.stopPropagation?.()

        if (cancel !== false) {
          isExternalUrl(to) || openInNewWindow ? openNewPage(to) : history.push(to)
        }
      }
    },
    [action, onPress, to, history, openInNewWindow]
  )

  const titleElement = to
    ? ((
        <Link
          href={to}
          rawText={title ?? markTranslated('')}
          onPress={onPressHandler}
          style={{ width: '100%', display: 'flex' }}
        />
      ) as unknown as TranslatedText)
    : title

  // relative position on wrap and static position on inner view so link's :before element is positioned to make the list item clickable
  return (
    <Wrap
      testID={testID}
      $selected={selected}
      $skeleton={skeleton}
      $variant={variant}
      onPress={onPressHandler}
      position="relative"
      flexDirection="row"
      alignItems="center"
      paddingX="m"
      {...fixSpacePropsForNative(rest)}
    >
      {children ?? (
        <>
          {accessoryLeft && (React.isValidElement(accessoryLeft) ? accessoryLeft : React.createElement(accessoryLeft))}
          <ListItemInfo $border={variant !== 'Menu'}>
            {skeleton && (
              <View
                fontSize={variant === 'Menu' ? 14 : fontSize || 13}
                fontWeight={600}
                className="skeleton-text"
                style={skeletonStyle}
              />
            )}
            {!!title && titleElement && (
              <Text
                rawText={titleElement}
                fontSize={variant === 'Menu' ? 14 : fontSize || 13}
                fontWeight={600}
                {...(forceLTR && { textAlign: 'left' })}
              />
            )}
            {!!subtitle && <Text rawText={subtitle} fontSize={12} fontWeight={600} numberOfLines={1} />}
            {!!description && (isString(description) ? <Text rawText={description} fontSize={12} /> : description)}
          </ListItemInfo>
          {accessoryRight &&
            (React.isValidElement(accessoryRight) ? accessoryRight : React.createElement(accessoryRight))}
        </>
      )}
    </Wrap>
  )
}

export const ListItem = styled(InnerListItem)`
  ${color}
  ${flexbox}
  ${layout}
  ${space}
  ${border}
  ${position}
  ${typography}
  ${alignItems}
  ${minHeight}
`
