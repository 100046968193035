import React, { Suspense } from 'react'

import { isIos } from '~/lite'

import { IInputProps, Input } from '../Input/Input'
import { TextInputProps } from '../Input/types'

const LazyBottomSheetInputGroup = React.lazy(() =>
  import('./BottomSheetInputGroup').then(module => ({ default: module.BottomSheetInputGroup }))
)

// BottomSheetInputGroup is not working well on Android for now
//https://github.com/gorhom/react-native-bottom-sheet/issues/1285
export function BottomSheetInputGroup<T extends string>(
  props: IInputProps<T> & TextInputProps
): ReturnType<React.FC<IInputProps<T>>> {
  return <Suspense fallback={null}>{isIos ? <LazyBottomSheetInputGroup {...props} /> : <Input {...props} />}</Suspense>
}
