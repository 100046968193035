import { styled } from '~/lite'
import { breakpoint } from '~/util'

export const CompanyCardContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
  box-sizing: border-box;
  @media ${breakpoint.md} {
    width: 150px;
    margin: 10px;
    padding: 0;
  }
  @media ${breakpoint.xl} {
    width: 180px;
  }
  @media ${breakpoint['2xl']} {
    width: 250px;
  }
`
