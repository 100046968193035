import { CountryCode, getChannels, GetData, getData, IUser } from 'core'
import { useCallback } from 'react'

import { isNotBot } from '../env'
import { pubSub } from '../util/pubsub'
import { useStore } from './useStore'

const botResponse: GetData['response'] = {
  success: true,
  data: {
    stats: {
      jobsCount: 3514225
    },
    user: null,
    location: {
      latitude: 40.7597,
      longitude: -73.981,
      accuracy: 1000,
      cityName: null,
      level1Code: null,
      level1Name: null,
      countryCode: 'US' as CountryCode,
      continentCode: null,
      timeZone: null,
      metroCode: null
    },
    currentUserId: 'bot' as IUser['id'],
    device: null,
    subscriptions: null,
    notificationBadgeCount: 0,
    shouldLoadMessages: false,
    resumeImportState: null,
    extensionState: null,
    applied: [],
    saved: [],
    ambitionPlusSubscription: null,
    hasStripeSubscription: false
  }
}

export const useLoadUserData = () => {
  const setIsLoadingUser = useStore(state => state.setIsLoadingUser)

  return useCallback(async () => {
    // set to false in subscriber since pub/sub via BroadcastChannel is asynchronous
    setIsLoadingUser(true)

    const response = isNotBot ? await getData() : botResponse
    const channelsResponse =
      response.success && response.data.shouldLoadMessages && isNotBot ? await getChannels() : null

    pubSub.publish({ type: 'LoadedUserData', response, channelsResponse })

    return response.success ? response.data.user?.id ?? null : null
  }, [setIsLoadingUser])
}
