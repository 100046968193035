import { useAutoAnimate } from '@formkit/auto-animate/react'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { ListDivider } from '../ListDivider'
import { Loader } from '../Loader/Loader'
import { NonIdealState } from '../NonIdealState/NonIdealState'
import { ScrollView } from '../ScrollView/ScrollView'
import { View } from '../View/View'
import { IListProps, IListRef } from './types'

export type { IBottomSheetListProps, IListProps, IListRef } from './types'

function ListComponent<ItemT = any>(
  {
    loadMore,
    isLoading = false,
    emptyIcon,
    emptyText,
    emptyDescription,
    onEndReached,
    ListHeaderComponent,
    ListFooterComponent,
    keyExtractor,
    hasDivider,
    loadingComponent,
    data,
    renderItem,
    numColumns,
    horizontal,
    onEndReachedThreshold,
    hasNextPage,
    useWindow,
    centerGridRow,
    fillGridItem
  }: IListProps<ItemT>,
  ref: React.Ref<IListRef>
): ReturnType<React.FC<IListProps<ItemT>>> {
  const endOfListRef = useRef<HTMLDivElement>(null)

  const getKey = keyExtractor ?? ((_, index) => index.toString())

  useImperativeHandle(ref, () => ({
    scrollToBottom: () =>
      endOfListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }))

  const [parent] = useAutoAnimate({
    duration: 300
  })

  /*
  if (bottomSheetProps) {
    return <BottomSheetList {...listProps} bottomSheetProps={bottomSheetProps} />
  }
  */

  const renderItems = (data: NonNullable<IListProps<ItemT>['data']>) =>
    data.map((item, index) => (
      <View key={getKey(item, index)} flex={fillGridItem ? 1 : 'inherit'}>
        {hasDivider && index !== 0 && <ListDivider />}
        {renderItem?.({ index, item, target: 'Cell' })}
      </View>
    ))

  const header = ListHeaderComponent ? (
    React.isValidElement(ListHeaderComponent) ? (
      ListHeaderComponent
    ) : (
      <ListHeaderComponent />
    )
  ) : null

  const footer = (
    <>
      {ListFooterComponent ? (
        React.isValidElement(ListFooterComponent) ? (
          ListFooterComponent
        ) : (
          <ListFooterComponent />
        )
      ) : null}
      <div ref={endOfListRef} />
    </>
  )

  let body: React.ReactNode = null

  if (data?.length) {
    body =
      numColumns && numColumns > 1 ? (
        Array.from({ length: Math.ceil(data.length / numColumns) }).map((_, groupIndex) => (
          <View
            key={groupIndex}
            display="flex"
            flexDirection="row"
            justifyContent={centerGridRow ? 'center' : 'flex-start'}
          >
            {renderItems(data.slice(numColumns * groupIndex, numColumns * groupIndex + numColumns))}
          </View>
        ))
      ) : (
        <div ref={parent}>{renderItems(data)}</div>
      )
  } else if (isLoading) {
    body = loadingComponent ? <View>{loadingComponent}</View> : <Loader />
  } else {
    body = (
      <NonIdealState
        iconTestID="empty-list-icon"
        titleTestID="empty-list-title"
        descriptionTestID="empty-list-description"
        icon={emptyIcon}
        title={emptyText ?? 'No results'}
        description={emptyDescription}
      />
    )
  }

  const renderContent = () => {
    return (
      <>
        {header}
        {body}
        {footer}
      </>
    )
  }

  if (onEndReached) {
    return (
      <InfiniteScroll
        useWindow={useWindow}
        pageStart={0}
        hasMore={hasNextPage}
        threshold={onEndReachedThreshold}
        loader={
          <View width="100%" justifyContent="center" alignItems="center" paddingY="s">
            <Loader testID="load-more-indicator" style={{ margin: 8 }} inline />
          </View>
        }
        loadMore={() => {
          onEndReached()
        }}
      >
        {renderContent()}
      </InfiniteScroll>
    )
  }

  return (
    <ScrollView
      {...(!data?.length && { contentContainerStyle: { flex: 1 } })}
      {...(horizontal && { display: 'flex', flexDirection: 'row' })}
    >
      {renderContent()}
    </ScrollView>
  )
}

export const List = forwardRef(ListComponent) as <ItemT = any>(
  props: IListProps<ItemT> & { ref?: React.Ref<IListRef> }
) => ReturnType<React.FC<IListProps<ItemT>>>
