import { useParams, useSearchParams } from 'react-router-dom'

import { util } from '~/lite'

export const useRouteParams = (props: unknown, whitelistedSearchParams?: string[]) => {
  const webParams = useParams()
  const [webSearchParams] = useSearchParams()

  const searchParams = (whitelistedSearchParams ?? []).reduce((combinedParams, key) => {
    const value = webSearchParams.get(key)
    return typeof value === 'undefined' ? combinedParams : { ...combinedParams, [key]: value }
  }, {})

  return util.omit({ ...webParams, ...searchParams }, '*')
}
