import React, { useEffect } from 'react'

import { useAffiliateContent } from '~/hooks'
import { Loader, MetaTags } from '~/lite'

import { Faq } from '../Faq'
import { HowItWorks } from '../HowItWorks'
import { ContentWrap } from '../styles'
import { Calculator } from './Calculator'
import { Hero } from './Hero'

export const Affiliate: React.FC = () => {
  const { data, isLoading } = useAffiliateContent()

  useEffect(() => {
    window.document.body.classList.add('content-view')
    window.scrollTo(0, 0)

    return () => {
      window.document.body.classList.remove('content-view')
    }
  }, [])

  if (isLoading) {
    return (
      <ContentWrap>
        <Loader />
      </ContentWrap>
    )
  }

  if (!data?.success) {
    return null
  }

  const { metaTags, hero, products, calculator, howItWorks, faq } = data.data

  return (
    <ContentWrap>
      <MetaTags type="Content" title={metaTags.title} description={metaTags.description} localizedPath="/affiliate" />
      <Hero data={hero} />
      <Calculator data={calculator} products={products} />
      <HowItWorks data={howItWorks} />
      <Faq data={faq} />
    </ContentWrap>
  )
}
