import {
  IChannel,
  IPusherChannelChannel,
  IPusherEvent,
  IPusherTeamChannel,
  IPusherUserChannel,
  ITeam,
  IUser
} from 'core'

export type PusherState =
  | 'Initialized'
  | 'Connecting'
  | 'Connected'
  | 'Disconnecting'
  | 'Disconnected'
  | 'Reconnecting'
  | 'Unavailable'
  | 'Failed'

type StateListener = ({ previous, current }: { previous: PusherState; current: PusherState }) => void

let stateListeners: StateListener[] = []

export const getStateListeners = () => stateListeners

export const onPusherStateChange = (callback: StateListener) => {
  stateListeners.push(callback)

  return () => {
    stateListeners = stateListeners.filter(listener => listener !== callback)
  }
}

export type IPusherChannel<ChannelEvent extends IPusherEvent> = {
  onSubscriptionSucceeded(callback: () => void): void
  trigger<ClientEvent extends Extract<ChannelEvent, { event: `client-${string}` }>>(
    event: ClientEvent['event'],
    data: ClientEvent['data']
  ): void
  unsubscribe(): void
}

export interface IPusherClient {
  subscribeToChannel(
    channelId: IChannel['id'],
    onEvent: (event: IPusherChannelChannel['events']) => void
  ): IPusherChannel<IPusherChannelChannel['events']> // PresenceChannel

  subscribeToTeam(
    teamId: ITeam['id'],
    onEvent: (event: IPusherTeamChannel['events']) => void
  ): IPusherChannel<IPusherTeamChannel['events']> // PrivateChannel

  subscribeToUser(
    userId: IUser['id'],
    onEvent: (event: IPusherUserChannel['events']) => void
  ): IPusherChannel<IPusherUserChannel['events']> // PrivateChannel
}
