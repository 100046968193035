import React from 'react'
import { color, ColorProps, layout, LayoutProps, position, PositionProps, SpaceProps } from 'styled-system'

import { styled, StyledSystemTheme, View, ViewProps } from '~/lite'

export type RefreshControlStyledProps = SpaceProps<StyledSystemTheme> & ColorProps & LayoutProps & PositionProps

export interface RefreshControlProps extends RefreshControlStyledProps, ViewProps {
  colors?: string[]
  enabled?: boolean
  onRefresh?: () => void
  progressBackgroundColor?: string
  progressViewOffset?: number
  refreshing: boolean
  size?: 0 | 1
  tintColor?: string
  title?: string
  titleColor?: string
}

const InnerRefreshControl: React.FC<RefreshControlProps> = ({
  colors,
  enabled,
  onRefresh,
  progressBackgroundColor,
  progressViewOffset,
  refreshing,
  size,
  tintColor,
  title,
  titleColor,
  ...rest
}) => {
  return <View {...rest} />
}

export const RefreshControl = styled(InnerRefreshControl)`
  ${color}
  ${layout}
  ${position}
`
