import { useSearch, useSearchQueryParams } from '@ambition/module-search'
import { CategorySlug, ClientRoutePath, createRecentSearches, TranslatedText } from 'core'
import React, { startTransition, useEffect, useMemo, useState } from 'react'
import { components, OptionProps } from 'react-select'

import { Select } from '~/components'
import { useCategoryPath, useRecentSearches } from '~/hooks'
import { Loader, useBreakpoints, useStore, useTranslation, View } from '~/lite'

interface ISearchResult {
  label: TranslatedText
  value: string
  path: ClientRoutePath | null
  type: 'Ghost' | 'Occupation' | 'RecentSearch'
}

const ClearIndicator = () => null

const DropdownIndicator = () => null

const LoadingIndicator = () => (
  <View marginRight="s">
    <Loader size="small" inline />
  </View>
)

const LoadingMessage = () => null

const NoOptionsMessage = () => null

const SearchResultOption: React.FC<OptionProps<ISearchResult, false> & { onClear?(): Promise<void> }> = ({
  children,
  onClear,
  ...props
}) => {
  const theme = useStore(state => state.theme)
  const [clearing, setClearing] = useState(false)

  return (
    <components.Option {...props}>
      <View flex={1} flexDirection="row">
        <View flex={1} padding={12}>
          {children}
        </View>
        {/*onClear && (
          <Button
            icon="close-outline"
            color={theme.text}
            onPress={async () => {
              setClearing(true)
              await onClear()
              setClearing(false)
            }}
            loading={clearing}
            skipTracking
            minimal
          />
          )*/}
      </View>
    </components.Option>
  )
}

interface ISearchInputProps {
  onChange?(value: TranslatedText, path: ClientRoutePath | null): void
  onFocus?(): void
  onBlur?(): void
  enabled?: boolean
}

export const SearchInput: React.FC<ISearchInputProps> = ({ onChange, onFocus, onBlur, enabled }) => {
  const t = useTranslation()
  const { isPhone } = useBreakpoints()
  const stats = useStore(state => state.stats)
  const { data: recentSearches, refetch: refetchRecentSearches } = useRecentSearches({ enabled })
  const { searchTerm, setSearchTerm, allResults } = useSearch({ enabled })
  // const [selectedOption, setSelectedOption] = useState<ISearchResult | null>(null)
  const searchParams = useSearchQueryParams()

  const { getCategoryPath } = useCategoryPath({ countryCode: null, landingPageSlug: null })

  useEffect(() => {
    if (searchParams.searchTerm) {
      setSearchTerm(searchParams.searchTerm)
    }
  }, [searchParams.searchTerm, setSearchTerm])

  const recentSearchOptions = useMemo<ISearchResult[]>(
    () =>
      (recentSearches ?? []).map(search => ({
        label: search.search.name,
        value: search.id,
        path: search.search.url ?? null,
        type: 'RecentSearch'
      })),
    [recentSearches]
  )

  const options: ISearchResult[] =
    allResults?.[0]?.occupations.map(occupation => ({
      label: occupation.simpleName ?? occupation.name,
      value: occupation.simpleName ?? occupation.name,
      path: getCategoryPath(occupation.slug as unknown as CategorySlug),
      type: 'Occupation'
    })) ?? []

  const selectedOption: ISearchResult | null = searchTerm
    ? { label: searchTerm as TranslatedText, value: searchTerm as TranslatedText, path: null, type: 'Ghost' }
    : null

  return (
    <Select
      components={{
        ClearIndicator,
        DropdownIndicator,
        LoadingIndicator,
        Option: props => (
          <SearchResultOption
            {...props}
            onClear={
              props.data.type === 'RecentSearch'
                ? async () => {
                    await new Promise(resolve => setTimeout(resolve, 5000))
                    // await deleteRecentSearch(props.data.value as IRecentSearch['id'])
                    // await refetchRecentSearches()
                  }
                : undefined
            }
          />
        )
      }}
      aria-label={t(isPhone ? 'Search' : 'Search millions of jobs')}
      placeholder={t(isPhone ? 'Search' : 'Search millions of jobs')}
      value={selectedOption}
      inputValue={searchTerm}
      options={searchTerm ? options : [{ label: t('Recent searches'), options: recentSearchOptions }]}
      styles={{
        container: () => ({ flex: 1 }),
        indicatorSeparator: () => ({ display: 'none' }),
        valueContainer: () => ({ paddingLeft: 12 - 5 - 2 }),
        control: () => ({ borderWidth: 0 }),
        option: () => ({ padding: 0 }),
        menu: () => ({ width: isPhone ? '100%' : 350 })
      }}
      loadingMessage={LoadingMessage}
      noOptionsMessage={NoOptionsMessage}
      menuPlacement="auto"
      onInputChange={(inputValue, meta) => {
        if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
          startTransition(() => setSearchTerm(inputValue))
        }
      }}
      onChange={option => {
        if (option) {
          setSearchTerm(option.label)

          if (!recentSearches?.find(search => search.search.name === option.label)) {
            createRecentSearches({ name: option.label, url: option.path ?? undefined })
          }

          onChange?.(option.label, option.path)
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      formatCreateLabel={searchTerm => t('Search for "{{searchTerm}}"', { searchTerm: searchTerm as TranslatedText })}
      isLoading={false}
      backspaceRemovesValue={false}
      allowCreateWhileLoading
      isValidNewOption={(inputValue, selectValue, selectOptions, accessors) =>
        !(
          !inputValue ||
          selectValue.some(
            option => option.type !== 'Ghost' && inputValue.toLowerCase() === option.label.toLowerCase()
          ) ||
          selectOptions.some(option => inputValue.toLowerCase() === option.label?.toLowerCase())
        )
      }
      isSearchable
      isClearable
      creatable
    />
  )
}
