import { encodeToken, GetGeoData, IContinent, ICountry } from 'core'
import React, { useMemo } from 'react'

import { isWeb } from '../env'
import { styled } from '../styled'
import { CountryIcon } from './CountryIcon'
import { H4 } from './H'
import { Link } from './Link'
import { Pressable } from './Pressable'
import { Text } from './Text'
import { View } from './View'

const ContinentName = styled(H4)`
  padding: 8px 16px;
  font-size: 24px;
`

const CountryContainer = styled(View)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  padding: 8px;
`

const Country = styled(isWeb ? View : Pressable)`
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background-color: ${props => props.theme.cardBackground};
  }
`

const CountryName = styled(Text)`
  margin-left: 8px;
`

const CountryNameLink = styled(Link)`
  margin-left: 8px;
  color: ${props => props.theme.text};

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    z-index: 2;
  }
`

export interface ICountryListProps {
  geoData: Omit<GetGeoData['success'], 'success'>
  onSelect(countryCode: ICountry['code'], countryName: string): void
  searchQuery?: string
  selectedContinentCodes?: IContinent['code'][]
}

export const CountryList: React.FC<ICountryListProps> = ({
  geoData,
  onSelect,
  searchQuery,
  selectedContinentCodes
}) => {
  const continents = useMemo<IContinent[]>(() => geoData.continents ?? [], [geoData.continents])

  const countries = useMemo<ICountry[]>(() => {
    let allCountries = geoData.countries ?? []

    if (selectedContinentCodes?.length) {
      allCountries = allCountries.filter(country => selectedContinentCodes.includes(country.continent))
    }

    if (!searchQuery) {
      return allCountries
    }

    const query = searchQuery.toLowerCase()

    return allCountries.filter(
      country => country.name.toLowerCase().includes(query) || country.code.toLowerCase().includes(query)
    )
  }, [geoData.countries, selectedContinentCodes, searchQuery])

  const countriesByContinent = useMemo<Record<string, ICountry[]>>(() => {
    return countries.reduce<Record<string, ICountry[]>>((acc, country) => {
      if (!acc[country.continent]) {
        acc[country.continent] = []
      }

      acc[country.continent].push(country)

      return acc
    }, {})
  }, [countries])

  return (
    <>
      {continents.map(continent => (
        <View key={continent.code}>
          <ContinentName rawText={continent.name} />
          <CountryContainer>
            {countriesByContinent[continent.code]?.map(country => {
              if (isWeb) {
                return (
                  <Country key={country.code}>
                    <CountryIcon country={country} size={24} />
                    <CountryNameLink
                      to={`/collections/countries-${encodeToken(country.code)}`}
                      onPress={() => onSelect(country.code, country.name)}
                      rawText={country.name}
                    />
                  </Country>
                )
              }

              return (
                <Country
                  key={country.code}
                  to={`/collections/countries-${encodeToken(country.code)}`}
                  onPress={() => onSelect(country.code, country.name)}
                >
                  <CountryIcon country={country} size={24} />
                  <CountryName rawText={country.name} />
                </Country>
              )
            })}
          </CountryContainer>
        </View>
      ))}
    </>
  )
}

/*
  const locationsByCountry = useMemo(
    () => groupBy(locationData?.locations || [], location => location.country?.code),
    [locationData?.locations]
  )

  const topCountries = useMemo(() => {
    const getLocationsInCountry = (code: ICountry['code']) => locationsByCountry[code] || []

    return countries.sort((c1, c2) => {
      const distance1 = getLocationsInCountry(c1.code).reduce<number>(
        (min, location) => ((location.distance || 1000000000) < min ? location.distance || 0 : min),
        100000000
      )

      const distance2 = getLocationsInCountry(c2.code).reduce<number>(
        (min, location) => ((location.distance || 100000000) < min ? location.distance || 0 : min),
        100000000
      )

      if (distance1 === 100000000 && distance2 === 100000000) {
        return 100000000
      }

      return distance1 - distance2
    })
  }, [countries, locationsByCountry])
*/
