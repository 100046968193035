import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { TranslatedText, UntranslatedText } from 'core'
import React, { forwardRef } from 'react'

import { animations, styled, Text } from '~/lite'

const TooltipProvider = TooltipPrimitive.Provider

const TooltipRoot = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const StyledTooltipContent = styled(TooltipPrimitive.Content)<{ $sideOffset?: number }>`
  position: relative;
  z-index: 60;
  display: inline-block;
  overflow: hidden;
  max-width: 300px;
  width: max-content;
  hyphens: none;
  text-wrap: balance;
  text-align: center;
  border-radius: 6px;
  background: ${props => (props.theme.dark ? props.theme.inputBackground : '#fff')};
  box-shadow: ${props =>
    props.theme.dark
      ? 'rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'
      : 'rgb(255, 255, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0.267) 0px 12px 60px 0px, rgba(0, 0, 0, 0.498) 0px 12px 32px -16px'};
  border: 1px solid ${props => (props.theme.dark ? props.theme.buttonBackground : '#fff')};
  padding: 12px 8px;
  font-size: 14px;
  animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  &[data-state='closed'] {
    animation: ${animations.fadeOut} 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-side='bottom'],
  &[data-side='left'],
  &[data-side='right'],
  &[data-side='top'] {
    animation: ${animations.fadeInUpSubtle} 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
`

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>((props, ref) => <StyledTooltipContent ref={ref} {...props} />)

export interface ITooltipProps {
  text?: UntranslatedText
  rawText?: TranslatedText
  delay?: number
  align?: 'start' | 'center' | 'end'
  children: React.ReactNode
}

export const Tooltip: React.FC<ITooltipProps> = ({ children, text, rawText, delay, align }) => (
  <TooltipProvider>
    <TooltipRoot delayDuration={delay}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipPrimitive.Portal>
        <TooltipContent side="bottom" sideOffset={8} align={align}>
          {text ? <Text text={text} /> : rawText ? <Text rawText={rawText} /> : null}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipRoot>
  </TooltipProvider>
)
