import { markTranslated, trackLocation } from 'core'

import { constants } from './constants'
import { getGeolocation } from './geoprovider'

export { getGeolocation }

export const shareGeolocation = async () => {
  const location = await getGeolocation()
  const address = await getAddress(location?.coords?.latitude, location?.coords.longitude)

  if (location) {
    // destructuring is necessary to convert location.coords to a plain JS object
    // passed to trackLocation -> post -> request -> JSON.stringify which only serializes plain objects
    const { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed } = location.coords
    trackLocation({ latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed })
  }

  return { success: !!location, address }
}

export const getAddress = async (latitude?: number, longitude?: number) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${constants.googleMapsApiKey}&address=${latitude},${longitude}&sensor=false`
  )
  const data = await response.json()

  if (data.results[0]) {
    const address = data.results[0].formatted_address
    const value = address.split(',')

    const count = value.length
    const country = value[count - 1]
    const city = value[count - 2]

    return markTranslated(`${city ? city + ', ' : ''}${country}`)
  }

  return null
}
