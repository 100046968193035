import { useQueryClient } from '@tanstack/react-query'
import {
  ClientRoutePath,
  CollectionSlug,
  createChannel,
  encodeToken,
  ILocation,
  INewPosition,
  IPosition,
  IPositionPartial,
  ITeam,
  ITeamPartial,
  markTranslated,
  removePositionFromCollection,
  TranslatedText
} from 'core'
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'

import {
  BottomSheet,
  Button,
  ITooltipProps,
  Loader,
  ScrollView,
  ShareButton,
  styled,
  Text,
  Tooltip,
  View
} from '~/components'
import {
  useBottomTabBarHeight,
  useCreateApplication,
  useDeletePosition,
  useHeaderHeight,
  useHistory,
  useLayout,
  usePromptAuth,
  useScreenTracking,
  useStore,
  useTranslation,
  useViewPosition
} from '~/hooks'
import { clientUrl, isNotBot, isWeb, MetaTags, TeamAvatar, ViewEventProperties } from '~/lite'
import { triggerHapticFeedback } from '~/util'

import { AddToCollection } from './AddToCollection'
import { Trix } from './Lazy'
import { PositionDescription } from './PositionDescription'
import { PositionDialog } from './PositionDialog'
import { PositionTypeIndicators } from './PositionTypeIndicators'
import {
  ApplicationInstructions,
  ExpiredBanner,
  ExpiredBannerText,
  Section,
  SectionHeader,
  StyledInfoList,
  SummarySection,
  TeamAvatarWrap,
  TeamInfo,
  TeamName,
  Title,
  Wrap
} from './styles'

const BR = styled(View)``

const NewApplication = lazy(() => import('@ambition/module-form').then(module => ({ default: module.NewApplication })))

const MaybeTooltip: React.FC<{ show: boolean } & ITooltipProps> = ({ show, children, ...props }) =>
  // eslint-disable-next-line no-constant-condition
  false ? <Tooltip {...props}>{children}</Tooltip> : <>{children}</>

export type IPositionLike = IPositionPartial | IPosition | INewPosition

export type IPositionTrackingProps =
  | { context?: never; skipTracking: true }
  | { context: Extract<ViewEventProperties, { screen: 'Position' }>['context']; skipTracking?: never }

export type IPositionProps = IPositionTrackingProps & {
  position: IPositionLike
  team?: ITeam | ITeamPartial
  location?: ILocation | null
  editing?: boolean
  refetchPosition?(): Promise<any>
  testID?: string
  collectionSlug?: CollectionSlug
}

const isExistingPosition = (position: IPositionLike): position is IPositionPartial | IPosition =>
  !!(position as IPosition).id

// @todo figure out something better than duck typing with questions here
const isFullPosition = (position: IPositionLike): position is IPosition =>
  !!(position as IPosition).id && 'questions' in (position as IPosition)

export const Position: React.FC<IPositionProps> = ({
  context,
  position,
  team,
  location,
  editing = false,
  refetchPosition,
  testID,
  collectionSlug,
  skipTracking
}) => {
  const t = useTranslation()
  const { width } = useLayout()
  const tabBarHeight = useBottomTabBarHeight()
  const headerHeight = useHeaderHeight()
  const [isAddToCollectionOpen, setIsAddToCollectionOpen] = useState<boolean | null>(null)
  const [isApplicationFormOpen, setIsApplicationFormOpen] = useState<boolean | null>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean | null>(null)
  const [isSuggestModalOpen, setIsSuggestModalOpen] = useState<boolean | null>(null)
  const [loadingChat, setLoadingChat] = useState(false)
  const user = useStore(state => state.user)
  const isTeamMember = useStore(state => state.isTeamMember)
  const setCurrentTeam = useStore(state => state.setCurrentTeam)
  const agentCommandId = useStore(state => state.agentCommandId)
  const applied = useStore(state => state.applied)
  const saved = useStore(state => state.saved)
  const history = useHistory()
  const { prompt } = usePromptAuth()
  const positionId = (position as IPosition).id

  const [isPositionRemoving, setIsPositionRemoving] = useState(false)

  const { mutateAsync: createApplication, isLoading: isCreatingApplication } = useCreateApplication()
  const { mutate: deletePosition } = useDeletePosition({
    onSuccess: response => {
      if (response.success) {
        queryClient.invalidateQueries(['positions'])
      }
    }
  })
  const queryClient = useQueryClient()

  useViewPosition(isNotBot && isExistingPosition(position) ? position.id : null, agentCommandId)

  useScreenTracking(
    () =>
      isExistingPosition(position) &&
      !skipTracking && { screen: 'Position', context, positionId: position.id, teamId: team?.id ?? null }
  )

  useEffect(
    () =>
      history.addListener('focus', () => {
        refetchPosition?.()
      }),
    [history, refetchPosition]
  )

  const onLogoPress = useCallback(() => {
    if (team) {
      setCurrentTeam(null)
      history.push(`/teams/${encodeToken(team.username)}/jobs`)
    }
  }, [history, team, setCurrentTeam])

  const isMember = isTeamMember(team?.id)
  const canPromote = isMember && user
  const canDelete = isMember && user

  const handleSave = () => {
    if (!user) {
      setIsSuggestModalOpen(true)
      return
    }
    setIsAddToCollectionOpen(true)
  }

  const onDeleteButtonPress = () => {
    if (!isExistingPosition(position)) {
      return
    }
    deletePosition({ id: position.id })
    setIsDeleteModalOpen(false)

    if (!isWeb) {
      history.goBack()
      return
    }

    if (collectionSlug) {
      setCurrentTeam(null)
      history.push(`/collections/${encodeToken(collectionSlug)}`)
    }
  }

  const onAddToCollectionChange = async () => {
    refetchPosition?.()
  }

  const removeFromCollection = useCallback(async () => {
    setIsPositionRemoving(true)
    await removePositionFromCollection(positionId)
    refetchPosition?.()
    setIsPositionRemoving(false)
  }, [positionId, setIsPositionRemoving, refetchPosition])

  const onStartChannelButtonPress = useCallback(async () => {
    if (!user) {
      setIsSuggestModalOpen(true)
      return
    }

    if (!isExistingPosition(position)) {
      return
    }

    setLoadingChat(true)
    const response = await createChannel({ positionId: position.id })
    setLoadingChat(false)

    if (response.success) {
      history.push(`/messages/${encodeToken(response.channel.id)}`)
    }
  }, [position, history])

  const path: ClientRoutePath = `/jobs/${encodeToken(positionId)}`
  const shareUrl = markTranslated(`${clientUrl}${path}`)

  const hasExpired =
    ('remoteExpiresAt' in position && position.remoteExpiresAt && position.remoteExpiresAt <= new Date()) ||
    ('deletedAt' in position && position.deletedAt && position.deletedAt <= new Date())

  return (
    <>
      <Wrap testID={testID} contentContainerStyle={{ paddingTop: headerHeight, paddingBottom: tabBarHeight }}>
        {isExistingPosition(position) && (
          <MetaTags type="Position" position={position} team={team} location={location} />
        )}
        {/*<MaybeTooltip
          show={!!position.applicationInstructions && !position.applicationEmail}
          rawText={isWeb ? ((<ApplicationInstructions />) as any) : undefined}
        />*/}
        <Title testID="main-position-title" rawText={position.title} />
        {team && (
          <TeamInfo>
            <TeamAvatarWrap>
              <TeamAvatar testID="team-logo" team={team} size="small" onPress={onLogoPress} />
            </TeamAvatarWrap>
            <TeamName
              testID="team-name"
              rawText={team.name}
              onPress={onLogoPress}
              numberOfLines={1}
              ellipsizeMode="head"
              style={{ flex: 1 }}
            />
          </TeamInfo>
        )}
        <PositionTypeIndicators position={position} />
        {/*position.experiences.length > 0 && (
          <ExperienceSection>
            <SkillList
              experiences={position.experiences}
              filterToExperiences
              onSkillPress={skill => history.push(`/collections/${encodeToken(skill.slug)}`)}
            />
          </ExperienceSection>
        )*/}
        {!editing && isExistingPosition(position) && (
          <ScrollView horizontal>
            {(!hasExpired || applied?.includes(position.id)) && (
              <Button
                action="NewApplication"
                testID="position-apply-button"
                loading={isCreatingApplication}
                text={applied?.includes(position.id) ? 'Applied' : 'Apply'}
                icon={!position.applicationUrl && position.applicationEmail ? 'mail' : 'flash-sharp'}
                disabled={
                  !position.isExternal &&
                  ((!position.applicationUrl && !position.applicationEmail) || applied?.includes(position.id))
                }
                onPress={() => isExistingPosition(position) && setIsApplicationFormOpen(true)}
                primary
              />
            )}
            <ShareButton
              action={{ item: 'Position' }}
              message={t('Check out this job {{url}}', { url: shareUrl })}
              url={shareUrl}
              text="Share"
              marginLeft="xs"
              marginRight="xs"
              primary
            />
            <Button
              action="AddPositionToCollection"
              primary
              loading={isPositionRemoving}
              text={saved?.includes(position.id) ? 'Unsave' : 'Save'}
              testID="add-to-collection-button"
              icon={saved?.includes(position.id) ? 'heart' : 'heart-outline'}
              onPress={saved?.includes(position.id) ? removeFromCollection : handleSave}
              marginRight="xs"
            />
            {/*<Button
              action="CreateChannel"
              text="Talk with the team"
              testID="start-channel-button"
              icon="chatbubbles"
              onPress={onStartChannelButtonPress}
              loading={loadingChat}
              marginRight="xs"
              display={position.isExternal && !isMember ? 'flex' : 'none'}
            />*/}
            {isMember && (
              <Button
                action="EditPosition"
                text="Edit"
                to={`/positions/${encodeToken(position.id)}/edit`}
                fill
                testID={`edit-position-${position.id}-button`}
                icon="pencil"
                marginRight="xs"
              />
            )}
            {canPromote && (
              <Button
                action="PromotePosition"
                testID={`promote-position-${position.id}-button`}
                large
                fill
                icon="checkmark-circle"
                text="Promote"
                to={`/positions/${encodeToken(positionId)}/promote`}
                marginRight="xs"
              />
            )}
            {canDelete && (
              <Button
                action="DeletePosition"
                testID={`delete-position-${position.id}-button`}
                large
                dangerous
                icon="remove-circle-outline"
                text="Delete"
                onPress={() => setIsDeleteModalOpen(true)}
                marginRight="xs"
              />
            )}
          </ScrollView>
        )}
        {hasExpired && (
          <ExpiredBanner>
            <ExpiredBannerText text="This job is no longer available" />
          </ExpiredBanner>
        )}
        <StyledInfoList
          items={[
            { label: 'Location', value: position.location || t('Unknown') }
            /*{
              label: 'Employment',
              value: position.allowEmployee
                ? position.employeeCompensation
                  ? formatCompensation(position.employeeCompensation, 'Available')
                  : t('Available')
                : t('Unavailable')
            },
            {
              label: 'Contract',
              value: position.allowContract
                ? position.contractCompensation
                  ? formatCompensation(position.contractCompensation, 'Available')
                  : t('Available')
                : t('Unavailable')
            }*/
          ]}
        />
        {!!position.summary && (
          <SummarySection testID="position-summary">
            {(position.summary.split('\n') as TranslatedText[]).map((text, idx) => (
              <React.Fragment key={idx}>
                {idx > 0 && <BR />}
                <Text rawText={text} fontSize={24} />
              </React.Fragment>
            ))}
          </SummarySection>
        )}
        {editing ? (
          <Section>
            <SectionHeader>
              <Text text="About" />
            </SectionHeader>
            {isWeb && (
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              <Trix placeholder="Add a description..." value={position.description || ''} onChange={() => {}} />
            )}
          </Section>
        ) : (
          <>
            {!!position.description && (
              <Section>
                <PositionDescription testID="position-description" position={position} contentWidth={width} />
              </Section>
            )}
            {isExistingPosition(position) && isAddToCollectionOpen !== null && (
              <AddToCollection
                position={position}
                isOpen={isAddToCollectionOpen}
                onClose={() => setIsAddToCollectionOpen(false)}
                onChange={onAddToCollectionChange}
              />
            )}
            {isFullPosition(position) && isApplicationFormOpen !== null && (
              <BottomSheet
                isOpen={!!isApplicationFormOpen}
                onClose={() => setIsApplicationFormOpen(false)}
                title="Apply"
                snapPoint={500}
              >
                <Suspense fallback={<Loader />}>
                  <NewApplication
                    testID="apply-form-modal"
                    position={position}
                    onSubmit={() => {
                      triggerHapticFeedback()
                      setIsApplicationFormOpen(false)
                    }}
                  />
                </Suspense>
              </BottomSheet>
            )}
          </>
        )}
      </Wrap>
      {isDeleteModalOpen !== null && (
        <PositionDialog
          isOpen={isDeleteModalOpen}
          title="Delete this job?"
          confirmButtonAction="DeletePosition"
          cancelButtonAction="CancelDeletePosition"
          confirmationText={t('Are you sure you want to delete "{{recordName}}"?', { recordName: position.title })}
          confirmButtonText="Delete"
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={onDeleteButtonPress}
        />
      )}
      {isSuggestModalOpen !== null && (
        <PositionDialog
          isOpen={isSuggestModalOpen}
          icon="warning"
          title="You are not logged in!"
          confirmButtonAction="ShowSignUpToApply"
          cancelButtonAction="CancelShowSignUpToApply"
          confirmationText={t('Please log in or sign up to submit an application')}
          confirmButtonText="Log In"
          onClose={() => setIsSuggestModalOpen(false)}
          onConfirm={() => {
            setIsSuggestModalOpen(false)
            prompt()
          }}
        />
      )}
    </>
  )
}
