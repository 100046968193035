import 'react-day-picker/dist/style.css'
import 'normalize.css'
import 'trix/dist/trix.css'
import './JobStyles.css'
import './SitemapStyles.css'
import './WebStyles.css'
import 'react-responsive-modal/styles.css'

import { AutopilotDialog } from '@ambition/module-shared'
import {
  type ClientRoutePath,
  type TranslatedText,
  type UntranslatedText,
  Domain,
  encodeToken,
  localizePath
} from 'core'
import React, { useEffect, useState } from 'react'

import { Menu } from '~/components'
import {
  DesktopView,
  Logo,
  MetaTags,
  sendMessageToExtension,
  toast,
  trackEvent,
  useHistory,
  useLocation,
  useStore,
  View
} from '~/lite'

import { IScreen } from '../hooks/useScreens'
import { HeaderMenu } from './HeaderMenu'
import { MainMenu } from './MainMenu'
import { ReactQueryDevtools } from './ReactQueryDevtools'
import { Searchbar, SearchbarView } from './Searchbar'
import {
  AppContainer,
  AppContent,
  AppHeader,
  AppHeaderCenter,
  AppHeaderEnd,
  AppHeaderStart,
  BottomTabs,
  DashboardButton,
  LogoText,
  LogoWrap,
  UpgradeButton,
  WebsiteMenu,
  WebsiteMenuItem
} from './styles'
import { Tab } from './Tab'
import { ToolMenu } from './ToolMenu'
import { WebFooter } from './WebFooter'

interface IWebContainerProps {
  domain?: Domain
  subdomain?: string
  tabs: IScreen[]
  children?: React.ReactNode
}

const getTabIcon = (name: string) => {
  const focused = false

  const icons: Record<string, string> = {
    ExploreStack: focused ? 'home' : 'home-outline',
    Studio: focused ? 'sparkles' : 'sparkles-outline',
    ShortStack: focused ? 'reader' : 'reader-outline',
    TeamPositions: focused ? 'newspaper' : 'newspaper-outline',
    ProfileStack: focused ? 'person-circle' : 'person-circle-outline',
    TeamDashboardStack: focused ? 'checkmark-circle' : 'checkmark-circle-outline',
    NotificationStack: focused ? 'notifications' : 'notifications-outline',
    MessagesStack: focused ? 'chatbubble' : 'chatbubble-outline',
    TeamMessagesStack: focused ? 'chatbubble' : 'chatbubble-outline'
  }

  return icons[name]
}

export const WebContainer: React.FC<IWebContainerProps> = ({ domain, subdomain, tabs, children }) => {
  const location = useLocation()
  const history = useHistory()
  const [searchbarView, setSearchbarView] = useState<SearchbarView | null>(null)
  const [userMenuVisible, setUserMenuVisible] = useState(false)
  const header = React.useRef<HTMLDivElement>(null)
  const { ambitionPlusSubscription, locale, user } = useStore()

  useEffect(() => {
    sendMessageToExtension({
      type: 'a:location-change',
      location: {
        path: location.pathname,
        search: location.search,
        hash: location.hash
      }
    })
  }, [location])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('checkout') === 'success') {
      toast.success('Payment successful! Thank you for your purchase.')
    }
  }, [location.search])

  const isInstantSdk = domain === 'instantsdk.com'

  const isWeebin = domain === 'weebin.com'
  const isWeebinDirectory = isWeebin && (!subdomain || subdomain === 'www')
  const isWeebinProfile = isWeebin && !isWeebinDirectory

  const isAuth =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/signup') ||
    location.pathname.startsWith('/confirm/') ||
    location.pathname.startsWith('/recover-password') ||
    location.pathname.startsWith('/github/') ||
    location.pathname.startsWith('/select-country-code') ||
    location.pathname.startsWith('/user/delete')

  // ending "/" is important to distinguish dashboard from marketing site
  const isLaunchpadDashboard = location.pathname.startsWith(isWeebin ? '/dashboard' : '/launchpad/')

  // localized
  const isAffiliate = !!location.pathname.match(/^(\/.+)?\/affiliate$/)
  const isApi = !!location.pathname.match(/^(\/.+)?\/api$/)
  const isAutopilot = !!location.pathname.match(/^(\/.+)?\/autopilot$/)
  const isLaunchpad = isWeebinDirectory || !!location.pathname.match(/^(\/.+)?\/launchpad$/)
  const isPipeline = !!location.pathname.match(/^(\/.+)?\/pipeline$/)
  const isPlus = !!location.pathname.match(/^(\/.+)?\/plus$/)
  const isPromote = !!location.pathname.match(/^(\/.+)?\/promote$/) && !isLaunchpadDashboard
  const isScreener = !!location.pathname.match(/^(\/.+)?\/screener$/)
  const isStudio = !!location.pathname.match(/^(\/.+)?\/studio$/)

  const isBusiness = location.pathname === '/business'
  const isJobDashboard = location.pathname === '/jobs'
  const isMarketplace = location.pathname === '/marketplace'
  const isLaunchpadSite = isWeebinProfile || location.pathname.startsWith('/u/')
  const isOAuth = location.pathname.startsWith('/ai/oauth')
  const isSitemap = location.pathname.startsWith('/sitemap')

  const isTeamDashboard = location.pathname.startsWith('/dashboard/teams')

  const hideHeader = isInstantSdk || isLaunchpadSite
  const hideBottomTabs = isInstantSdk || isWeebin || isLaunchpadDashboard || isLaunchpadSite

  const hideSearch =
    isAffiliate ||
    isApi ||
    isBusiness ||
    isLaunchpad ||
    isMarketplace ||
    isOAuth ||
    isPromote ||
    isScreener ||
    isLaunchpadDashboard ||
    isTeamDashboard ||
    isJobDashboard ||
    isSitemap

  const hideMainMenu =
    isAuth ||
    isAffiliate ||
    isApi ||
    isAutopilot ||
    isInstantSdk ||
    isLaunchpad ||
    isLaunchpadSite ||
    isMarketplace ||
    isPipeline ||
    isPlus ||
    isPromote ||
    isScreener ||
    isStudio ||
    isBusiness ||
    isLaunchpadDashboard ||
    isJobDashboard ||
    isOAuth ||
    isSitemap ||
    location.pathname === '/positions/new' ||
    location.pathname === '/teams/new' ||
    location.pathname.startsWith('/dashboard') ||
    !!location.pathname.match(/\/jobs\/[a-z0-9-]+\/interview/) ||
    /^\/positions\/.*\/edit$/.test(location.pathname)

  const hideFooter = isSitemap

  const tabProps = tabs.map((tab, idx) => {
    const to = (tab.path === 'user/profile/:username?' ? '/user/profile' : `/${tab.path}`) as ClientRoutePath
    return { key: idx, to, text: tab.title as UntranslatedText, icon: getTabIcon(tab.name) }
  })

  const scrollTo = (e: any, id: string) => {
    e.preventDefault()
    const target = document.getElementById(id)
    target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  const teamIds = user?.teams?.map(team => team.id) ?? []

  return (
    <AppContainer>
      <MetaTags type={isInstantSdk ? 'InstantSdk' : isWeebin ? 'Weebin' : 'General'} />
      {!hideHeader && (
        <AppHeader
          $blurred={isAffiliate || isBusiness || isPlus || isPromote || isScreener}
          ref={header}
          position="fixed"
          top={0}
          right={0}
          left={0}
          height={60}
          display="flex"
          flexDirection="row"
          alignItems="stretch"
        >
          <View display="flex" flexDirection="row" alignItems="stretch" width="100%">
            <AppHeaderStart>
              <LogoWrap paddingX="m" to={localizePath('/', locale)}>
                <Logo size={30} />
                <DesktopView flexDirection="row">
                  <LogoText rawText={(isWeebin ? 'Weebin' : 'RemoteAmbition') as TranslatedText} marginLeft="xs" />
                </DesktopView>
              </LogoWrap>
            </AppHeaderStart>
            <AppHeaderCenter>
              <DesktopView marginLeft={-8}>
                <ToolMenu variant="header" />
              </DesktopView>
              {!hideSearch && (
                <Searchbar activeView={userMenuVisible ? null : searchbarView} setActiveView={setSearchbarView} />
              )}
              {isAffiliate && (
                <WebsiteMenu>
                  <WebsiteMenuItem href="#calculator" text="Calculator" onPress={e => scrollTo(e, 'calculator')} />
                  <WebsiteMenuItem
                    href="#how-it-works"
                    text="How it works"
                    onPress={e => scrollTo(e, 'how-it-works')}
                  />
                  <WebsiteMenuItem href="#faq" text="FAQ" onPress={e => scrollTo(e, 'faq')} />
                </WebsiteMenu>
              )}
              {isApi && (
                <WebsiteMenu>
                  <WebsiteMenuItem href="#features" text="Use cases" onPress={e => scrollTo(e, 'features')} />
                  <WebsiteMenuItem href="#pricing" text="Pricing" onPress={e => scrollTo(e, 'pricing')} />
                  <WebsiteMenuItem href="#faq" text="FAQ" onPress={e => scrollTo(e, 'faq')} />
                </WebsiteMenu>
              )}
              {isBusiness && (
                <WebsiteMenu>
                  <WebsiteMenuItem href="#features" text="Features" onPress={e => scrollTo(e, 'features')} />
                  <WebsiteMenuItem
                    href="#how-it-works"
                    text="How it works"
                    onPress={e => scrollTo(e, 'how-it-works')}
                  />
                  <WebsiteMenuItem href="#pricing" text="Pricing" onPress={e => scrollTo(e, 'pricing')} />
                  <WebsiteMenuItem href="#faq" text="FAQ" onPress={e => scrollTo(e, 'faq')} />
                </WebsiteMenu>
              )}
              {isPromote && (
                <WebsiteMenu>
                  <WebsiteMenuItem href="#features" text="Features" onPress={e => scrollTo(e, 'features')} />
                  <WebsiteMenuItem
                    href="#how-it-works"
                    text="How it works"
                    onPress={e => scrollTo(e, 'how-it-works')}
                  />
                  <WebsiteMenuItem href="#pricing" text="Pricing" onPress={e => scrollTo(e, 'pricing')} />
                  <WebsiteMenuItem href="#faq" text="FAQ" onPress={e => scrollTo(e, 'faq')} />
                </WebsiteMenu>
              )}
              {isScreener && (
                <WebsiteMenu>
                  <WebsiteMenuItem href="#demo" text="Demo" onPress={e => scrollTo(e, 'demo')} />
                  <WebsiteMenuItem
                    href="#how-it-works"
                    text="How it works"
                    onPress={e => scrollTo(e, 'how-it-works')}
                  />
                  <WebsiteMenuItem href="#pricing" text="Pricing" onPress={e => scrollTo(e, 'pricing')} />
                  <WebsiteMenuItem href="#faq" text="FAQ" onPress={e => scrollTo(e, 'faq')} />
                </WebsiteMenu>
              )}
            </AppHeaderCenter>
            <AppHeaderEnd>
              {!isScreener && (
                <View className="employer-button" justifyContent="center">
                  {user?.teams?.length ? (
                    user.teams.length === 1 ? (
                      <DashboardButton
                        text="Dashboard"
                        to={`/dashboard/teams/${encodeToken(user.teams[0].id)}`}
                        action="ViewDashboard"
                      />
                    ) : (
                      <Menu
                        align="end"
                        triggerStyle={{ flex: 1, cursor: 'pointer' }}
                        items={user.teams.map(team => ({
                          rawText: team.name,
                          onPress: () => history.push(`/dashboard/teams/${encodeToken(team.id)}`)
                        }))}
                      >
                        <DashboardButton text="Dashboard" action="ViewDashboard" />
                      </Menu>
                    )
                  ) : (
                    <Menu
                      align="end"
                      triggerStyle={{ flex: 1, cursor: 'pointer' }}
                      items={[
                        {
                          text: 'CV Screener',
                          onPress: () => {
                            trackEvent('Press', { action: 'ViewScreenerLandingViaHeader', text: 'CV Screener' })
                            history.push(localizePath('/screener', locale))
                          }
                        },
                        {
                          text: 'Talent Pipeline',
                          onPress: () => {
                            trackEvent('Press', { action: 'ViewPipelineViaHeader', text: 'Talent Pipeline' })
                            history.push(localizePath('/pipeline', locale))
                          }
                        }
                      ]}
                    >
                      <DashboardButton text="Employers / Recruiters" skipTracking />
                    </Menu>
                  )}
                </View>
              )}
              {!isScreener && !isTeamDashboard && teamIds.length === 0 && !ambitionPlusSubscription && (
                <View className="upgrade-button" justifyContent="center" paddingX={8}>
                  <UpgradeButton
                    action="UpgradeViaHeader"
                    text="Upgrade"
                    to={localizePath('/plus', locale)}
                    color="#fff"
                  />
                </View>
              )}
              <HeaderMenu onVisibilityChange={setUserMenuVisible} />
            </AppHeaderEnd>
          </View>
        </AppHeader>
      )}
      {!hideBottomTabs && (
        <BottomTabs id="app-bottom-tabs" position="fixed" right={0} bottom={0} left={0} zIndex={100}>
          {tabProps.map(props => (
            <Tab {...props} />
          ))}
        </BottomTabs>
      )}
      <ReactQueryDevtools />
      <AppContent $hideHeader={hideHeader} $hideBottomTabs={hideBottomTabs}>
        {!hideMainMenu && <MainMenu />}
        <View flex={1}>
          <View className="app-content-container">{children}</View>
        </View>
        {!isInstantSdk && !isLaunchpad && !isLaunchpadDashboard && !isLaunchpadSite && <AutopilotDialog />}
      </AppContent>
      {!hideBottomTabs && !hideFooter && <WebFooter variant="phone" />}
    </AppContainer>
  )
}
