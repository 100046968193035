import React from 'react'

import { Icon, styled, Text, View } from '~/lite'

const Steps = styled(View)`
  margin: 8px 0;
  gap: 16px;
`

const Step = styled(View)`
  flex-direction: row;
`

const StepIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const StepTextWrap = styled(View)`
  flex: 1;
  display: block;
`

const BoldStepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: bold;
`

const StepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: normal;
  opacity: 0.8;
`

export const AutopilotSteps: React.FC = () => {
  return (
    <Steps>
      <Step>
        <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
        <StepTextWrap>
          <BoldStepText text="Train your assistant." />{' '}
          <StepText text="Every day, we scan millions of jobs. The most relevant ones are sent to your AI assistant, which evaluates them against your criteria." />
        </StepTextWrap>
      </Step>
      <Step>
        <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
        <StepTextWrap>
          <BoldStepText text="Automatic applications." />{' '}
          <StepText text="When your AI assistant identifies a match and thinks you're qualified, it will handle the application process for you." />
        </StepTextWrap>
      </Step>
      <Step>
        <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
        <StepTextWrap>
          <BoldStepText text="Stay in control." />{' '}
          <StepText text="At any time, you can view the jobs your AI assistant is targeting and, if necessary, adjust its actions." />
        </StepTextWrap>
      </Step>
    </Steps>
  )
}
