import React, { Suspense, useState } from 'react'

import { useOnHydrate } from '~/lite'

import { CreatableSelectProps, SelectOption, SelectProps } from './types'

const LazySelect = React.lazy(() => import('./Select').then(module => ({ default: module.Select })))

export function Select<Option extends SelectOption, IsMulti extends boolean = false>(
  props:
    | ({ creatable: true } & CreatableSelectProps<Option, IsMulti>)
    | ({ creatable?: false } & SelectProps<Option, IsMulti>)
) {
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <LazySelect {...(props as SelectProps<SelectOption, boolean>)} />
    </Suspense>
  )
}

export * from './types'
