import React from 'react'
export interface IRangeSliderProps {
  min?: number
  max?: number
  labelRenderer?: boolean | ((value: number) => string | JSX.Element)
  value?: [number, number]
  onChange?(value: [number, number]): void
}

export const RangeSlider: React.FC<IRangeSliderProps> = () => null
