import { CountryCode } from '../models'
import { defaultLocale, SupportedLocaleCode } from './i18n'

/*
// hard coded countryLocales, but we can use this to generate it again if we support more locales.
// reason is we wouldn't want to swap locales for a country if the prevalences change slightly
// (half of urls with new locale, half with old locale, etc)

import territoryInfo from 'cldr-core/supplemental/territoryInfo.json'

const countryLocales: Record<CountryCode, SupportedLocaleCode> = {}

Object.entries(territoryInfo.supplemental.territoryInfo).forEach(([territoryCode, territory]) => {
  if ('languagePopulation' in territory) {
    const languages = Object.entries(territory.languagePopulation).sort(
      (a, b) => +b[1]._populationPercent - +a[1]._populationPercent
    )

    for (const [language, info] of languages) {
      // if less than 25% of the population speaks a supported locale, revert to the default locale (en-US)
      if (+info._populationPercent < 25) {
        break
      }

      const locale = getBestAvailableLocale(language, true)

      if (locale) {
        if (locale !== defaultLocale) {
          countryLocales[territoryCode] = locale
        }
        break
      }
    }
  }
})

console.log({ countryLocales })
*/

// manually adjusted Spanish and Portugese locales to match the country's language (cldr is not specific enough)
const countryLocales: Record<string, SupportedLocaleCode> = {
  AD: 'ca-ES',
  AE: 'ar-SA',
  AM: 'hy-AM',
  AO: 'pt-PT',
  AR: 'es-419',
  AT: 'de-DE',
  AW: 'nl-NL',
  AX: 'sv-SE',
  BH: 'ar-SA',
  BI: 'fr-FR',
  BJ: 'fr-FR',
  BL: 'fr-FR',
  BN: 'ms-MY',
  BO: 'es-419',
  BR: 'pt-BR',
  CC: 'ms-MY',
  CF: 'fr-FR',
  CG: 'fr-FR',
  CH: 'de-DE',
  CI: 'fr-FR',
  CL: 'es-419',
  CM: 'fr-FR',
  CN: 'zh-CN',
  CO: 'es-419',
  CR: 'es-419',
  CU: 'es-419',
  CV: 'pt-PT',
  CY: 'el-GR',
  CZ: 'cs-CZ',
  DE: 'de-DE',
  DK: 'da-DK',
  DO: 'es-419',
  DZ: 'ar-SA',
  EA: 'es-ES',
  EC: 'es-419',
  EE: 'ru-RU',
  EG: 'ar-SA',
  EH: 'ar-SA',
  ES: 'es-ES',
  FI: 'fi-FI',
  FR: 'fr-FR',
  GA: 'fr-FR',
  GF: 'fr-FR',
  GN: 'fr-FR',
  GP: 'fr-FR',
  GQ: 'es-ES',
  GR: 'el-GR',
  GT: 'es-419',
  GW: 'pt-PT',
  HK: 'zh-TW',
  HN: 'es-419',
  HR: 'hr-HR',
  HU: 'hu-HU',
  IC: 'es-419',
  ID: 'id-ID',
  IL: 'he-IL',
  IN: 'hi-IN',
  IQ: 'ar-SA',
  IT: 'it-IT',
  JO: 'ar-SA',
  JP: 'ja-JP',
  KG: 'ru-RU',
  KM: 'ar-SA',
  KP: 'ko-KR',
  KR: 'ko-KR',
  KW: 'ar-SA',
  KZ: 'ru-RU',
  LB: 'ar-SA',
  LI: 'de-DE',
  LT: 'ru-RU',
  LU: 'fr-FR',
  LY: 'ar-SA',
  MA: 'ar-SA',
  MC: 'fr-FR',
  MD: 'ro-RO',
  ME: 'sr-SP',
  MF: 'fr-FR',
  MG: 'fr-FR',
  ML: 'fr-FR',
  MO: 'zh-TW',
  MQ: 'fr-FR',
  MR: 'ar-SA',
  MX: 'es-419',
  MY: 'ms-MY',
  MZ: 'pt-PT',
  NA: 'af-ZA',
  NC: 'fr-FR',
  NE: 'fr-FR',
  NI: 'es-419',
  NL: 'nl-NL',
  NO: 'no-NO',
  OM: 'ar-SA',
  PA: 'es-419',
  PE: 'es-419',
  PF: 'fr-FR',
  PK: 'ur-PK',
  PL: 'pl-PL',
  PM: 'fr-FR',
  PR: 'es-419',
  PS: 'ar-SA',
  PT: 'pt-PT',
  QA: 'ar-SA',
  RE: 'fr-FR',
  RO: 'ro-RO',
  RS: 'sr-SP',
  RU: 'ru-RU',
  SA: 'ar-SA',
  SC: 'fr-FR',
  SD: 'ar-SA',
  SE: 'sv-SE',
  SI: 'hr-HR',
  SJ: 'ru-RU',
  SK: 'sk-SK',
  SM: 'it-IT',
  SN: 'fr-FR',
  SO: 'ar-SA',
  SR: 'nl-NL',
  SS: 'ar-SA',
  ST: 'pt-PT',
  SV: 'es-419',
  SY: 'ar-SA',
  TD: 'fr-FR',
  TF: 'fr-FR',
  TG: 'fr-FR',
  TH: 'th-TH',
  TL: 'pt-PT',
  TN: 'ar-SA',
  TR: 'tr-TR',
  TW: 'zh-TW',
  UA: 'uk-UA',
  UY: 'es-419',
  VA: 'it-IT',
  VE: 'es-419',
  VN: 'vi-VN',
  WF: 'fr-FR',
  YE: 'ar-SA',
  YT: 'fr-FR'
}

// pragmatic reference: https://www.apple.com/choose-country-region/
const countryLocaleOverrides: Record<string, SupportedLocaleCode> = {
  // talent jobs are in English + Apple site
  IN: 'en-US'
}

export const getCountryLocale = (countryCode: CountryCode): SupportedLocaleCode =>
  countryLocaleOverrides[countryCode.toUpperCase()] ?? countryLocales[countryCode.toUpperCase()] ?? defaultLocale
