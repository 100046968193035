import { border, BorderProps, flexbox, FlexboxProps, space, SpaceProps } from 'styled-system'

import { css, isWeb, Pressable, styled, View } from '~/lite'

import { Text } from '../Text/Text'

export const ListItemInfo = styled(View)<{ $border: boolean }>`
  position: static;
  flex: 1;
  margin-left: 8px;
  padding: 12px 8px 12px 0;
  border-bottom-width: ${props => (props.$border ? '1px' : 0)};
  border-color: ${props => props.theme.border};
`

export const Wrap = styled(Pressable)<
  FlexboxProps & SpaceProps & BorderProps & { $selected?: boolean; $skeleton?: boolean; $variant?: 'Menu' }
>`
  ${flexbox}
  ${space}
  ${border}
  ${props =>
    props.$selected &&
    (props.$variant === 'Menu'
      ? css`
          &:before {
            background-color: ${props => props.theme.menuItemBackground};
          }
        `
      : css`
          background-color: ${props => props.theme.menuItemBackground};
        `)}
  ${props =>
    props.$skeleton &&
    isWeb &&
    css`
      pointer-events: none;
    `}
  ${props =>
    props.$variant === 'Menu' &&
    css`
      padding: 1px 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:before {
        display: block;
        content: ' ';
        position: absolute;
        pointer-events: none;
        top: 1px;
        right: 0;
        bottom: 1px;
        left: 0;
        z-index: -1;
        border-radius: 10px;
      }
    `}
  ${props =>
    props.$variant === 'Menu' &&
    !props.$selected &&
    isWeb &&
    css`
      &:hover:before {
        background-color: ${props => props.theme.menuItemBackground};
      }
    `}
`
export const Link = styled(Text)`
  width: 100%;
  display: flex;

  &::before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
`
