import { IMarketProfile } from 'core'
import React, { useState } from 'react'

import { breakpoint, Link, Pressable, styled, View } from '~/lite'

import { getSectionVisibilities } from './shared'

const Wrap = styled(View)<{ $variant?: 'Launchpad' | 'Example' }>`
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  position: fixed;
  top: ${props => `${props.$variant === 'Example' ? 120 : props.$variant === 'Launchpad' ? 60 + 64 : 0}px`};
  right: 0;
  left: 0;
  z-index: 100;
  min-height: 60px;
  pointer-events: none;

  @media ${breakpoint.md} {
    top: ${props => `${props.$variant === 'Example' ? 120 : props.$variant === 'Launchpad' ? 60 : 0}px`};
    align-items: center;
    min-height: 80px;
  }
`

const MenuTrigger = styled(Pressable)`
  justify-content: center;
  padding: 0 16px;

  @media ${breakpoint.md} {
    display: none;
  }
`

const Menu = styled(View)<{ $isOpen: boolean }>`
  flex-direction: column;
  position: absolute;
  top: 60px;
  width: 100%;
  background: rgba(${props => props.theme.backgroundRgb}, 1);
  opacity: ${props => (props.$isOpen ? 1 : 0)};
  pointer-events: ${props => (props.$isOpen ? 'auto' : 'none')};

  @media ${breakpoint.md} {
    position: static;
    flex-direction: row;
    width: auto;
    background: transparent;
    padding: 0 16px;
    opacity: 1;
    pointer-events: auto;
  }
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${props => props.theme.text};
  white-space: nowrap;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  @media ${breakpoint.md} {
    padding: 16px;
  }
`

const Inner = styled(View)<{ $offsetX?: number; $isMenuOpen: boolean }>`
  background: rgba(${props => props.theme.backgroundRgb}, ${props => (props.$isMenuOpen ? 1 : 0.8)});
  backdrop-filter: blur(10px);
  box-shadow: rgb(0 0 0 / 5%) 0px 1px;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  pointer-events: auto;
  transition: transform 0.4s ease-in-out;
  will-change: transform;
  max-width: 100%;
  width: 100%;

  @media ${breakpoint.md} {
    width: auto;
    justify-content: flex-start;
    border-radius: 200px;
    transform: ${props => `translateX(${props.$offsetX ?? 0}px)`};
  }
`

const Name = styled(MenuItem)`
  position: relative;
  padding: 16px;

  @media ${breakpoint.md} {
    padding: 16px 24px;

    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      bottom: 12px;
      width: 1px;
      background-color: ${props => props.theme.border};
      opacity: 0.8;
    }
  }
`

const scrollTo = (e: any, id: string) => {
  e.preventDefault()
  const target = document.getElementById(id)
  target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
}

interface INavigationProps {
  profile: IMarketProfile
  variant?: 'Launchpad' | 'Example'
  isSidebarOpen?: boolean
}

export const Navigation: React.FC<INavigationProps> = ({ profile, variant, isSidebarOpen }) => {
  const sections = getSectionVisibilities(profile)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Wrap $variant={variant}>
      <Inner $offsetX={isSidebarOpen || variant === 'Example' ? 250 : 0} $isMenuOpen={isMenuOpen}>
        {!!profile.companyName && (
          <Name href="#hero" onPress={e => scrollTo(e, 'hero')} rawText={profile.companyName} />
        )}
        <MenuTrigger onPress={() => setIsMenuOpen(isOpen => !isOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="4" x2="20" y1="12" y2="12" />
            <line x1="4" x2="20" y1="6" y2="6" />
            <line x1="4" x2="20" y1="18" y2="18" />
          </svg>
        </MenuTrigger>
        <Menu $isOpen={isMenuOpen}>
          {sections.about && (
            <MenuItem
              href="#about"
              text="About"
              onPress={e => {
                setIsMenuOpen(false)
                scrollTo(e, 'about')
              }}
            />
          )}
          {sections.plans && (
            <MenuItem
              href="#plans"
              text="Pricing"
              onPress={e => {
                setIsMenuOpen(false)
                scrollTo(e, 'plans')
              }}
            />
          )}
          {sections.products && (
            <MenuItem
              href="#products"
              text="Products"
              onPress={e => {
                setIsMenuOpen(false)
                scrollTo(e, 'products')
              }}
            />
          )}
          {sections.faq && (
            <MenuItem
              href="#faq"
              text="FAQ"
              onPress={e => {
                setIsMenuOpen(false)
                scrollTo(e, 'faq')
              }}
            />
          )}
        </Menu>
      </Inner>
    </Wrap>
  )
}
