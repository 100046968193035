import { useCallback, useEffect, useRef, useState } from 'react'

import { withAudioLevels } from '~/util'

export const useAudioPlayer = (audioVisualizerRef?: React.RefObject<HTMLDivElement>) => {
  const [audioPlayer] = useState<HTMLAudioElement | null>(typeof Audio !== 'undefined' ? new Audio() : null)
  const [volume, setVolume] = useState(1)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState<number | null>(null)
  const [isBuffering, setIsBuffering] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const unsubscribeRef = useRef<ReturnType<typeof withAudioLevels> | null>(null)

  useEffect(() => {
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [])

  const setAudioBlobData = useCallback(
    (blob: Blob) => {
      const url = window.URL.createObjectURL(blob)
      audioPlayer && (audioPlayer.src = url)
    },
    [audioPlayer]
  )

  const setAudioUrl = useCallback(
    (url: string) => {
      if (audioPlayer) {
        // cloudfront cache must be invalidated if this is changed
        audioPlayer.crossOrigin = 'anonymous'
        audioPlayer.src = url
      }
    },
    [audioPlayer]
  )

  const play = useCallback(
    async (seekTo?: number) => {
      if (!audioPlayer?.src) {
        return
      }

      if (!unsubscribeRef.current) {
        unsubscribeRef.current = withAudioLevels(audioPlayer, volume => {
          if (audioVisualizerRef?.current) {
            const scale = Math.min(0.5 + volume / 100, 1)
            audioVisualizerRef.current.style.transform = `scale3d(${scale}, ${scale}, ${scale})`
          }
        })
      }

      await audioPlayer.play()
      seekTo !== undefined && (audioPlayer.currentTime = seekTo)
      setIsBuffering(false)
      setIsPlaying(true)
    },
    [audioPlayer, audioVisualizerRef]
  )

  const pause = useCallback(() => {
    if (!audioPlayer?.src) {
      return
    }

    audioPlayer.pause()
    setIsPlaying(false)
  }, [audioPlayer])

  const setPlayerVolume = useCallback(
    (value: number) => {
      if (audioPlayer) {
        audioPlayer.volume = value
        setVolume(value)
      }
    },
    [audioPlayer]
  )

  const seek = useCallback(
    (time: number) => {
      if (audioPlayer && duration !== null && time >= 0 && time <= duration) {
        audioPlayer.currentTime = time
      }
    },
    [audioPlayer, duration]
  )

  const cleanup = useCallback(() => {
    if (!audioPlayer) {
      return
    }
    audioPlayer.src = ''
  }, [audioPlayer])

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioPlayer) {
        setProgress(audioPlayer.currentTime)
      }
    }

    const handleLoadedMetadata = () => {
      if (audioPlayer) {
        setDuration(audioPlayer.duration)
      }
    }

    const handleWaiting = () => {
      setIsBuffering(true)
    }

    const handlePlaying = () => {
      setIsBuffering(false)
    }

    const handleEnded = () => {
      setIsPlaying(false)
      if (audioPlayer) {
        audioPlayer.currentTime = 0
      }
    }

    audioPlayer?.addEventListener('timeupdate', handleTimeUpdate)
    audioPlayer?.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioPlayer?.addEventListener('waiting', handleWaiting)
    audioPlayer?.addEventListener('playing', handlePlaying)
    audioPlayer?.addEventListener('ended', handleEnded)

    return () => {
      audioPlayer?.removeEventListener('timeupdate', handleTimeUpdate)
      audioPlayer?.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audioPlayer?.removeEventListener('waiting', handleWaiting)
      audioPlayer?.removeEventListener('playing', handlePlaying)
      audioPlayer?.removeEventListener('ended', handleEnded)
    }
  }, [audioPlayer])

  const hasAudioSource = !!audioPlayer?.src

  return {
    isPlaying,
    isBuffering,
    setAudioBlobData,
    setAudioUrl,
    play,
    pause,
    volume,
    setPlayerVolume,
    progress,
    duration,
    seek,
    cleanup,
    hasAudioSource
  }
}
