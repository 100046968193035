import type { IFileInputProps } from '@ambition/ui-file-input'
import React, { Suspense, useState } from 'react'

import { useOnHydrate } from '~/lite'

const LazyFileInput = React.lazy(() =>
  import('@ambition/ui-file-input').then(module => ({ default: module.FileInput }))
)

type ILazyFileInputProps = IFileInputProps & {
  fallback?: React.ReactNode
}

export const FileInput: React.FC<ILazyFileInputProps> = ({ fallback, ...props }) => {
  const [isHydrated, setIsHydrated] = useState(false)

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return <>{fallback}</>
  }

  return (
    <Suspense fallback={fallback}>
      <LazyFileInput {...props} />
    </Suspense>
  )
}
