import React from 'react'

import { initSentry, NonIdealState } from '~/lite'

interface ISentryErrorBoundaryProps {
  children?: React.ReactNode
}

interface ISentryErrorBoundaryState {
  hasError: boolean
}

export class SentryErrorBoundary extends React.Component<ISentryErrorBoundaryProps, ISentryErrorBoundaryState> {
  public state: ISentryErrorBoundaryState = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error & { cause?: Error }, { componentStack }: React.ErrorInfo) {
    const errorBoundaryError = new Error(error.message)
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`
    errorBoundaryError.stack = componentStack
    error.cause = errorBoundaryError
    initSentry().then(sentry => sentry.captureException(error, { contexts: { react: { componentStack } } }))
  }

  public componentDidMount() {
    // put inside componentDidMount to wait until hydrated client-side
    initSentry()
  }

  public resetErrorBoundary() {
    this.setState({ hasError: false })
  }

  public render() {
    if (this.state.hasError) {
      return <NonIdealState icon="alert-circle" title="An error has occurred" />
    }

    return this.props.children
  }
}
