import { IPlusContent } from 'core'
import React from 'react'

import { animations, breakpoint, styled, Text, View } from '~/lite'

import { Section, SectionContent, SectionTitle } from './styles'

const Body = styled(View)`
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;
  overflow: hidden;
  max-height: 500px;

  @media ${breakpoint.md} {
    max-height: unset;
  }
`

const Shadow = styled(View)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}00 0%,
    ${props => (props.theme.dark ? '#000000' : '#ffffff')}ff 100%
  );
`

const Benefit = styled(Text)<{ $delay: number }>`
  padding: 8px 16px;
  background: ${props => props.theme.backgroundContrast};
  font-size: 18px;
  border-radius: 8px;
  animation-duration: 600ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.$delay}ms;
  animation-name: ${animations.popInUpSubtle};
`

interface IBenefitsProps {
  data: IPlusContent['benefits']
}

export const Benefits: React.FC<IBenefitsProps> = ({ data }) => {
  return (
    <Section id="benefits">
      <SectionContent>
        {data.header && <SectionTitle rawText={data.header} />}
        <Body>
          <Shadow />
          {data.items.map((item, index) => (
            <Benefit key={index} rawText={item} $delay={2000 + 100 * index} />
          ))}
        </Body>
      </SectionContent>
    </Section>
  )
}
