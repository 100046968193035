import type { State, Uppy, UppyOptions } from '@uppy/core'
import { createMultipartUpload } from 'core'
import { useId, useRef, useState } from 'react'

import { constants } from '~/lite'

const initUppy = async (id: string, options?: UppyOptions): Promise<Uppy> => {
  const libs = await Promise.all([import('@uppy/core'), import('@uppy/aws-s3-multipart')])
  const Uppy = libs[0].default
  const AwsS3Multipart = libs[1].default

  const uppyInstance = new Uppy({
    id: `uppy-${id}`,
    autoProceed: true,
    debug: true,
    ...options,
    restrictions: {
      maxFileSize: 10000000,
      allowedFileTypes: null,
      maxNumberOfFiles: 1,
      ...options?.restrictions
    }
  })

  return uppyInstance.use(AwsS3Multipart, {
    // full url matching path in createMultipartUpload
    companionUrl: `${constants.apiServerUrl}/uploads`,
    limit: 4,
    // first purpose of this is to use our own fetch, which sets custom headers needed by the backend
    createMultipartUpload: ({ name, type, meta: { relativePath, ...meta } }) =>
      createMultipartUpload({
        filename: name,
        type: type,
        // second purpose is to encode the metadata to avoid issues with special characters
        // https://github.com/transloadit/uppy/issues/2380#issuecomment-663990256
        metadata: {
          ...meta,
          // when using uppy d&d plugin, sometimes relativePath: null is sent, resulting in "InvalidHeader: Header x-amz-meta-relativepath"
          ...(typeof relativePath === 'string' && { relativePath }),
          name: encodeURIComponent(meta.name)
        }
      })
  })
}

export const useUppy = (options?: UppyOptions) => {
  const id = useId()
  const initialization = useRef<Promise<void>>()
  const [state, setState] = useState<{ uppy: null; uppyState: null } | { uppy: Uppy; uppyState: State }>({
    uppy: null,
    uppyState: null
  })

  if (!initialization.current) {
    initialization.current = initUppy(id, options).then(uppy => {
      // state-update is an undocumented event, so a bit brittle but useful
      uppy.on('state-update' as any, (_: State, nextState: State) => {
        setState({ uppy, uppyState: nextState })
      })

      setState({ uppy, uppyState: uppy.getState() })
    })
  }

  return state
}
