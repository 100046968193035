import React from 'react'

import { Switch } from '~/components'
import { useUpdateUserPreference } from '~/hooks'
import { styled, useStore, useTranslation, View } from '~/lite'

const Wrap = styled(View)`
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.buttonBackground};
  border-radius: 50px;
  padding-right: 4px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.buttonHover};
  }
`

const Label = styled.label`
  padding: 8px 8px 8px 16px;
  color: ${props => props.theme.buttonColor};
  cursor: pointer;
`

export const RemoteField: React.FC = () => {
  const t = useTranslation()
  const { mutate: updateUserPreference } = useUpdateUserPreference()

  const { preferRemoteJobs, setPreferRemoteJobs } = useStore()

  const onChange = React.useCallback(
    (value: boolean) => {
      setPreferRemoteJobs(value)
      updateUserPreference({ preferRemote: value })
    },
    [setPreferRemoteJobs, updateUserPreference]
  )

  return (
    <Wrap>
      <Label htmlFor="prefer-remote-field">{t('Prefer remote jobs')}</Label>
      <Switch id="prefer-remote-field" value={preferRemoteJobs} onChange={onChange} />
    </Wrap>
  )
}
