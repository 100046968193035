import { AnimatedView, Pressable, styled, Text, View } from '~/components'
import { Link } from '~/lite'

export const StyledPressable = styled(Pressable)`
  margin: 8px;
  display: flex;
  flex: 1;
`

export const StyledAnimatedView = styled(AnimatedView)`
  flex: 1;
`

export const Wrap = styled(View)`
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
`

export const Category = styled(Text)<{ $color?: string }>`
  padding: 15px 15px 0;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 16px;
  color: ${props => props.$color};
`

export const Header = styled(Link)<{ $color?: string }>`
  display: flex;
  padding: 0 15px 15px;
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.$color};
  &::before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
`

export const Icon = styled(View)`
  flex: 1;
  padding: 10px 25px 25px;
`

export const Children = styled(View)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  padding: 20px;
`
