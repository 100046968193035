import { UntranslatedText } from 'core'
import React from 'react'

import { Icon, ScrollView, Text, View, ViewProps } from '~/components'
import { useStore } from '~/hooks'

import { IPositionLike } from './Position'

type PositionTypeIndicatorProps = {
  position: IPositionLike
  containerProps?: ViewProps
}

export const PositionTypeIndicators = ({ position, containerProps }: PositionTypeIndicatorProps) => {
  const theme = useStore(state => state.theme)
  const isPromoted = !!position.promoteProductPaid
  const isAllowRemote = !!position.allowRemote

  const renderIndicator = (text: UntranslatedText) => {
    return (
      <View
        display="flex"
        flexDirection="row"
        alignItems="center"
        backgroundColor={theme.primary}
        paddingX="xs"
        paddingY="xxs"
        borderRadius={15}
      >
        <Icon size={10} icon="checkmark" color="white" marginRight="xs" />
        <Text text={text} fontSize={10} color="white" />
      </View>
    )
  }

  if (!isPromoted && !isAllowRemote) {
    return null
  }

  return (
    <View {...containerProps}>
      <ScrollView horizontal>
        {isPromoted && <View marginRight="xs">{renderIndicator('Promote')}</View>}
        {isAllowRemote && <View>{renderIndicator('Remote')}</View>}
      </ScrollView>
    </View>
  )
}
