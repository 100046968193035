import { CanonicalFieldName, IAutofillData, IExtensionSite, IExtensionState } from '../models'
import { Endpoint, get, patch, post } from '../util/api'

export type GetExtensionData = Endpoint<
  Record<string, never>,
  { success: true; ttl: number; sites: IExtensionSite[]; autofill: IAutofillData }
>

export type GetExtensionState = Endpoint<
  Record<string, never>,
  { success: true; ttl: number; state: IExtensionState | null }
>

export type PatchExtensionState = Endpoint<
  Record<string, never>,
  { success: true; ttl: number; state: IExtensionState | null },
  {
    autofill: {
      values: Record<CanonicalFieldName, string | number | boolean>
    }
  }
>

export type EnhanceWithExtension = Endpoint<
  Record<string, never>,
  { success: true },
  { job: { url: string; html: string; readable: string; jsonld: string }; form: { url: string; html: string } }
>

export const getExtensionData = () => get<GetExtensionData>('/extension/data')

export const getExtensionState = () => get<GetExtensionState>('/extension/state')

export const patchExtensionState = (data: PatchExtensionState['body']) =>
  patch<PatchExtensionState>('/extension/state', data)

export const enhanceWithExtension = (data: EnhanceWithExtension['body']) =>
  post<EnhanceWithExtension>('/extension/enhance', data)
