import { redirectToMarketCheckout } from '@ambition/module-shared'
import { formatCurrency, getMarketPrice, IMarketProduct, IMarketProfile, TranslatedText } from 'core'
import { uniq } from 'lodash'
import React, { useState } from 'react'

import { breakpoint, Button, H3, Icon, openURL, styled, Text, trackEvent, useStore, useTranslation, View } from '~/lite'

const EditButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border-width: 1px;
`

const Wrap = styled(View)<{ $small?: boolean }>`
  position: relative;
  flex: 1;
  min-width: 350px;
  padding: ${props => (props.$small ? '16px' : '24px')};
  margin: 8px 8px 0;
  border-radius: 12px;
  border-width: 1px;
  background: ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.3)')};
  border: 1px solid ${props => (props.theme.dark ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.1), 0px 30px 45px -30px rgba(50, 50, 93, 0.25);
  backdrop-filter: blur(20px);

  @media ${breakpoint.md} {
    margin: 0;
    min-width: ${props => (props.$small ? '200px' : '350px')};
    max-width: 340px;
    transition: transform 200ms ease;

    ${EditButton} {
      opacity: 0;
      pointer-events: none;
      transition: opacity 200ms ease;
    }

    &:hover {
      transform: scale3d(1.02, 1.02, 1.02);

      ${EditButton} {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
`

const Name = styled(H3)`
  font-size: 26px;
  padding: 8px;
`

const Description = styled(Text)`
  font-size: 18px;
  padding: 0 8px 8px;
`

const Footer = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
`

const EstimateLabel = styled(Text)<{ $large?: boolean }>`
  opacity: 0.8;
  font-size: ${props => (props.$large ? '16px' : '12px')};
  margin-bottom: 2px;
`

const EstimateValue = styled(Text)`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
`

const Price = styled(Text)<{ $large?: boolean }>`
  white-space: nowrap;
  font-size: ${props => (props.$large ? '20px' : '16px')};
  font-weight: 500;
`

const CalendarBookingButton = styled(Button)`
  border-radius: 100px;

  span {
    padding-bottom: 0;
  }
`

const BuyButton = styled(Button)`
  border-radius: 100px;
`

const Features = styled(View)`
  flex: 1;
  justify-content: start;
  margin-left: 4px;
  padding: 24px 0;
`

const FeatureWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  padding: 8px 20px 8px 0;
  text-align: initial;
  hyphens: none;
`

interface IFeatureProps {
  text: TranslatedText
}

const Feature: React.FC<IFeatureProps> = ({ text }) => (
  <FeatureWrap>
    <Icon icon="checkmark-outline" color="#6360ff" marginRight={8} />
    <Text rawText={text} fontSize={16} />
  </FeatureWrap>
)

export interface IProductProps {
  profile: IMarketProfile
  product: IMarketProduct
  variant?: 'Launchpad' | 'Example'
  small?: boolean
  handleEditProduct?(product: IMarketProduct): void
  handleBuyProduct?(product: IMarketProduct): boolean
  handleCalendarBooking?(): boolean
}

export const Product: React.FC<IProductProps> = ({
  profile,
  product,
  variant,
  small,
  handleEditProduct,
  handleBuyProduct,
  handleCalendarBooking
}) => {
  const t = useTranslation()
  const locale = useStore(state => state.locale)

  const [isProcessingProductId, setIsProcessingProductId] = useState<IMarketProduct['id'] | null>(null)

  const { name, description, features, estimateMin, estimateMax } = product
  const minHours = typeof estimateMin === 'number' && estimateMin !== 0 ? Math.round(estimateMin / 60) : null
  const maxHours = typeof estimateMax === 'number' && estimateMax !== 0 ? Math.round(estimateMax / 60) : null

  // const price = prices.find(price => price.billingPeriod === 'OneTime')
  const formattedTime =
    minHours !== null && maxHours !== null
      ? uniq([minHours, maxHours]).join('-')
      : minHours !== null
      ? `${minHours}+`
      : maxHours !== null
      ? t('Up to {{amount}}', { amount: maxHours })
      : null

  const price = getMarketPrice(product, profile)

  const hasBuyButton = variant === 'Launchpad' || (profile.state === 'Live' && profile.stripeState === 'Active')
  const hasBookingButton = !!product.isRecurring && (variant === 'Launchpad' || !!profile.calendarBookingUrl)

  return (
    <Wrap $small={small}>
      {variant === 'Launchpad' && (
        <EditButton icon="pencil" onPress={() => handleEditProduct?.(product)} skipTracking />
      )}
      <Name rawText={name} />
      {!!description && <Description rawText={description} />}
      {features.length > 0 && (
        <Features>
          {features.map(({ name }, idx) => (
            <Feature key={idx} text={name} />
          ))}
        </Features>
      )}
      {price !== null && (
        <Footer>
          {product.isRecurring ? (
            <View flex={1}>
              <Price
                rawText={formatCurrency(locale, product.currency ?? profile.currency, price, true)}
                $large={hasBuyButton && hasBookingButton}
              />
              <EstimateLabel text="per month" $large={hasBuyButton && hasBookingButton} />
            </View>
          ) : (
            <>
              <View flex={1}>
                <EstimateLabel text="Estimated hours" />
                {!!formattedTime && <EstimateValue rawText={formattedTime as TranslatedText} />}
              </View>
              <Price rawText={formatCurrency(locale, profile.currency, price, true)} />
            </>
          )}
          {(hasBuyButton || hasBookingButton) && (
            <View>
              {hasBuyButton && (
                <BuyButton
                  text={product.isRecurring ? 'Subscribe' : 'Buy'}
                  loading={isProcessingProductId === product.id}
                  onPress={async () => {
                    if (isProcessingProductId) {
                      return
                    }

                    const showCheckout =
                      variant === 'Launchpad'
                        ? !!handleBuyProduct?.(product)
                        : profile.state === 'Live' && profile.stripeState === 'Active'

                    if (showCheckout) {
                      if (variant !== 'Launchpad') {
                        trackEvent('Select', {
                          item: 'MarketProduct',
                          profileId: profile.id,
                          productId: product.id
                        })
                      }

                      setIsProcessingProductId(product.id)
                      await redirectToMarketCheckout(profile.id, product.id)
                    }
                  }}
                  primary
                  skipTracking
                />
              )}
              {hasBookingButton && (
                <CalendarBookingButton
                  text="Book a call"
                  loading={isProcessingProductId === product.id}
                  onPress={async () => {
                    if (isProcessingProductId) {
                      return
                    }

                    if (variant === 'Launchpad' && !handleCalendarBooking?.()) {
                      return
                    }

                    if (profile.calendarBookingUrl) {
                      openURL(profile.calendarBookingUrl)
                    }
                  }}
                  minimal
                  skipTracking
                />
              )}
            </View>
          )}
        </Footer>
      )}
    </Wrap>
  )
}
