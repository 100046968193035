import type { INewCollectionProps } from '@ambition/module-form'
import { addPositionToCollection, ICollection, IPosition, IPositionPartial } from 'core'
import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react'

import { BodyText, Button, ButtonContainer, HeaderText, IBottomSheetProps, Icon, ListItem, Modal } from '~/components'
import { usePrivateCollections, useStore } from '~/hooks'
import { triggerHapticFeedback } from '~/util'

import { DialogBody, DialogHeader, SuccessDialogBody } from './styles'

const NewCollection = lazy(() => import('@ambition/module-form').then(module => ({ default: module.NewCollection })))

interface IAddToCollectionProps extends IBottomSheetProps {
  position: IPosition | IPositionPartial
  onChange?(): Promise<any>
}

export const AddToCollection: React.FC<IAddToCollectionProps> = ({
  position,
  isOpen,
  onClose,
  onChange,
  ...bottomSheetProps
}) => {
  const user = useStore(state => state.user)
  const [openNewCollectionModal, setOpenNewCollectionModal] = useState(false)

  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)

  const { data: collections = [], remove: removeCollections } = usePrivateCollections(user)

  const addToCollection = useCallback(
    async (collection: ICollection) => {
      await addPositionToCollection(position.id, collection.id)
      triggerHapticFeedback()
      setIsOpenSuccessModal(true)
      if (onChange) {
        await onChange()
      }

      onClose?.()
    },
    [position.id, onChange, onClose]
  )

  const listHeader = useMemo(
    () => (
      <Button
        action="NewCollection"
        testID="create-new-collection-button"
        onPress={() => setOpenNewCollectionModal(true)}
        text="Create a new collection"
      />
    ),
    [setOpenNewCollectionModal]
  )

  const onNewCollectionClose = useCallback(() => setOpenNewCollectionModal(false), [])

  const onNewCollectionSubmit = useCallback<INewCollectionProps['onSubmit']>(
    async response => {
      removeCollections()
      onNewCollectionClose()
      await addToCollection(response.collection)
    },
    [removeCollections, onNewCollectionClose, addToCollection]
  )

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose ? onClose : () => null}
        title={'Your Collections'}
        presentation="formSheet"
        showCloseButton
      >
        <DialogBody>
          {
            <>
              {listHeader}
              {collections.map(collection => (
                <ListItem
                  key={collection.id}
                  action={{ item: 'UserCollection', collectionId: collection.id }}
                  onPress={() => addToCollection(collection)}
                  title={collection.name}
                />
              ))}
            </>
          }
        </DialogBody>
      </Modal>
      <Suspense>
        <NewCollection
          isOpen={openNewCollectionModal}
          onSubmit={onNewCollectionSubmit}
          onClose={onNewCollectionClose}
        />
      </Suspense>
      <AddedSuccessFullyModal isOpen={isOpenSuccessModal} onClose={() => setIsOpenSuccessModal(false)} />
    </>
  )
}

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const AddedSuccessFullyModal: React.FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => null} presentation="formSheet" padding={0}>
      <DialogHeader>
        <HeaderText text="Added" />
      </DialogHeader>
      <SuccessDialogBody>
        <Icon icon="checkbox-outline" size={70} />
        <BodyText text="Successfully saved job in collection" />
      </SuccessDialogBody>
      <ButtonContainer>
        <Button action="CloseAddPositionToCollectionToast" text="Done" primary onPress={onClose} />
      </ButtonContainer>
    </Modal>
  )
}
