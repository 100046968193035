import { TeamCard } from '@ambition/module-shared'
import { useQueryClient } from '@tanstack/react-query'
import {
  CollectionIcon,
  CollectionSlug,
  CollectionType,
  createChannel,
  createSubscription,
  encodeToken,
  getTeamByUsername,
  ICollection,
  ICollectionHeader,
  IPositionQuery,
  IRecordStyle,
  ITeam,
  markTranslated,
  removeSubscription,
  TranslatedText
} from 'core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, FlexHorizontal, ShareButton, Svg, Text, View } from '~/components'
import { useHistory, useStore, useTranslation } from '~/hooks'
import { clientUrl, space, useLoadUserData } from '~/lite'
import { isWeb, triggerHapticFeedback } from '~/util'

import { AddPositionButton, HeaderWrap, LargeName } from './styles'

export interface IHeaderIconProps {
  icon: ICollectionHeader['icon']
  height?: number
  width?: number
}

export const HeaderIcon: React.FC<IHeaderIconProps> = ({ icon, height = 45, width = 45 }) => {
  const theme = useStore(state => state.theme)

  if (!icon) {
    return null
  }

  if (Array.isArray(icon)) {
    return (
      <>
        {icon.map((iconItem, idx) => (
          <HeaderIcon key={idx} icon={iconItem} height={height} width={width} />
        ))}
      </>
    )
  }

  const { source, type } = icon as CollectionIcon

  if (type === 'Svg') {
    return <Svg uri={source} width={width} height={height} />
  }

  if (type === 'Emoji') {
    return (
      <View
        width={width}
        height={height}
        justifyContent="center"
        alignItems="center"
        borderRadius={width}
        backgroundColor={theme.buttonColor}
        borderWidth={2}
        borderColor={theme.border}
      >
        <Text fontSize={width - 10} rawText={source} />
      </View>
    )
  }

  return null
}

interface ICollectionHeaderProps {
  query: IPositionQuery
  collectionType: CollectionType
  collectionSlug: CollectionSlug
  collectionHeader: ICollectionHeader
  collectionStyle: IRecordStyle | null
  headerText: TranslatedText | null
  showTeamHeader?: boolean
  testID?: string
  onHeaderIconClick?: () => void
  data?: ICollection | null
}

export const CollectionHeader: React.FC<ICollectionHeaderProps> = ({
  query,
  collectionType,
  collectionSlug,
  collectionHeader,
  collectionStyle,
  headerText,
  testID,
  showTeamHeader = true,
  onHeaderIconClick,
  data: collection
}) => {
  const t = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const loadUserData = useLoadUserData()
  const { user, subscriptions = [], currentTeam, setCurrentTeam } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const [team, setTeam] = useState<ITeam>()
  const showTeamCardHeader = isWeb || showTeamHeader

  const subscription = useMemo(
    () => collection && subscriptions?.find(subs => subs.collectionId === collection.id),
    [collection, subscriptions]
  )

  useEffect(() => {
    if (collectionType === 'Team' && query.teams) {
      getTeamByUsername(query.teams[0] as ITeam['username']).then(results => {
        if (results.success) {
          setTeam(results.team)
        }
      })
    }
  }, [collectionType, query.teams])

  const onSendMessageButtonPress = useCallback(async () => {
    if (!team?.id) {
      throw new Error('Team is not defined')
    }

    const response = await createChannel({ teamId: team.id })

    if (response.success) {
      history.push(`/messages/${encodeToken(response.channel.id)}`)
    }
  }, [history, team?.id])

  const subscribeToCollection = useCallback(async () => {
    if (user && collection) {
      setIsLoading(true)
      if (subscription) {
        await removeSubscription(subscription.id)
      } else {
        await createSubscription({
          userId: user.id,
          collectionId: collection.id,
          query: collection.query ?? null,
          type: 'Collection'
        })
      }
      queryClient.invalidateQueries(['subscriptions'])

      await loadUserData()
      setIsLoading(false)
      triggerHapticFeedback()
    }
  }, [user, collection, subscription, loadUserData, queryClient])

  const shareUrl = markTranslated(
    `${clientUrl}/collections/${collectionSlug}${collectionType === 'Occupation' ? '-jobs' : ''}`
  )

  return (
    <View testID={testID}>
      {collectionType === 'Team' && (
        <HeaderWrap>
          {team ? <TeamCard header={showTeamCardHeader} team={team} /> : null}
          {team?.isMember && (
            <AddPositionButton
              testID="team-positions-button"
              onPress={() => {
                setCurrentTeam(team)
                history.push('/positions/new')
              }}
              icon="add"
              primary
              skipTracking
              marginTop="m"
            />
          )}
        </HeaderWrap>
      )}
      {collectionType !== 'Team' && (
        <View
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          paddingTop="l"
          paddingBottom="xl"
          paddingX="m"
          backgroundColor={collectionStyle?.backgroundColor}
          background={isWeb ? collectionStyle?.background : undefined}
        >
          {collectionHeader.icon && <HeaderIcon icon={collectionHeader.icon} />}
          {headerText && <LargeName color={collectionStyle?.color} rawText={headerText} />}
          {collectionType === 'Private' && collection && (
            <AddPositionButton
              testID="team-positions-button"
              onPress={() => {
                onHeaderIconClick?.()
              }}
              icon="ellipsis-horizontal"
              primary
              skipTracking
            />
          )}
        </View>
      )}
      <FlexHorizontal marginTop={-space.m} paddingX="m">
        <ShareButton
          action={{ item: 'Collection' }}
          message={t('Check out these jobs {{url}}', { url: shareUrl })}
          url={shareUrl}
          text="Share"
          primary
          fill
        />
        {user && (
          <Button
            action={subscription ? 'DeleteSubscriptionToCollection' : 'CreateSubscriptionToCollection'}
            text={subscription ? 'Unsubscribe' : 'Subscribe'}
            icon={subscription ? 'notifications-off' : 'notifications'}
            marginLeft="s"
            fill
            primary
            loading={isLoading}
            onPress={() => subscribeToCollection()}
          />
        )}
        {collectionType === 'Team' && team && !team.isMember && (
          <Button
            action="CreateChannel"
            onPress={onSendMessageButtonPress}
            testID="team-positions-button"
            text="Message"
            icon="chatbox-ellipses-outline"
            marginLeft="xs"
            fill
            primary
          />
        )}
        {user && currentTeam && (
          <Button
            action="NewPosition"
            testID="team-positions-button"
            text="Post a Job"
            to="/promote"
            icon="add"
            marginLeft="xs"
          />
        )}
      </FlexHorizontal>
    </View>
  )
}
