import { IInterviewQuestion, IPosition } from 'core'
import { useCallback, useEffect, useState } from 'react'

import { constants } from '~/util'

import { usePositionInterviewQuestions } from './api'
import { useAudioPlayer } from './useAudioPlayer'

export const useInterviewQuestionsHandler = (
  positionId: IPosition['id'] | null,
  audioVisualizerRef?: React.RefObject<HTMLDivElement>
) => {
  const { data: interviewQuestions, isLoading } = usePositionInterviewQuestions(positionId)
  const [currentQuestion, setCurrentQuestion] = useState<IInterviewQuestion | null>(null)
  const [isFinished, setIsFinished] = useState(false)
  const { setAudioUrl, play, pause, isPlaying } = useAudioPlayer(audioVisualizerRef)

  useEffect(() => {
    if (interviewQuestions?.length) {
      setIsFinished(false)
      setCurrentQuestion(interviewQuestions[0])
      const audioUrl = `${constants.assetServerUrl}/${interviewQuestions[0].voiceFilePath}`
      setAudioUrl(audioUrl)
    }
  }, [interviewQuestions, setAudioUrl])

  const nextQuestion = useCallback(() => {
    if (!currentQuestion || !interviewQuestions) {
      return
    }
    const nextQuestion = interviewQuestions[currentQuestion.questionIndex] || null
    setCurrentQuestion(nextQuestion)
    if (!nextQuestion) {
      setIsFinished(true)
    } else {
      const audioUrl = `${constants.assetServerUrl}/${nextQuestion.voiceFilePath}`
      setAudioUrl(audioUrl)
      play()
    }
  }, [currentQuestion, interviewQuestions, setAudioUrl, play])

  return {
    isLoadingQuestions: isLoading,
    isFinished,
    isPlayingAudio: isPlaying,
    nextQuestion,
    playQuestionAudio: play,
    pauseQuestionAudio: pause
  }
}
