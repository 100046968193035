import { ClientRoutePath } from '../routes'
import { ISitemapSection } from '../sitemap'
import { Endpoint, get } from '../util/api'
import { TranslateParams, UntranslatedText } from '../util/i18n'

export interface ILink {
  name: TranslateParams
  href: ClientRoutePath
  selected?: boolean
}

export type GetSitemap = Endpoint<
  { section: ISitemapSection['slug']; page: string },
  {
    success: true
    data: {
      header: UntranslatedText
      links: ILink[]
      pagingHeader: UntranslatedText
      pagingLinks: ILink[] | null
      breadcrumbLinks: ILink[]
    }
  }
>

export const getSitemap = (section: ISitemapSection['slug'], page: number) =>
  get<GetSitemap>(`/sitemap/${section}/${page}`)

export const getSitemapQuery = (section?: ISitemapSection['slug'], page?: string) => {
  const sectionValue = section ?? ('jobs' as ISitemapSection['slug'])
  const pageValue = +(page ?? '0')

  return {
    queryKey: ['sitemap', sectionValue, pageValue],
    queryFn: async () => {
      const results = await getSitemap(sectionValue, pageValue)
      return results.success ? results.data : null
    }
  }
}
