import { useQuery } from '@tanstack/react-query'
import { getRecentSearches } from 'core'

export const useRecentSearches = (options?: { enabled?: boolean }) =>
  useQuery(
    ['recent-searches'],
    async () => {
      const response = await getRecentSearches()
      return response.success ? response.recentSearches : []
    },
    { enabled: options?.enabled }
  )
