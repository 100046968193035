import { ClientRoutePath, hoverArrow, IAnnouncement } from 'core'
import React from 'react'

import { styled } from '../styled'
import { breakpoint } from '../util/style'
import { Pressable } from './Pressable'
import { Text } from './Text'
import { View } from './View'

const Wrap = styled(Pressable)`
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  padding: 16px;
  border-radius: 12px;
  border-width: 1px;
  background: ${props => props.theme.cardBackground};

  @media ${breakpoint.md} {
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    border-radius: 100px;
    max-width: calc(100% - 16px);
  }
`

const Tag = styled(Text)<{ $subtle?: boolean }>`
  margin-bottom: 8px;
  padding: 4px 8px;
  background: ${props => (props.$subtle ? props.theme.buttonBackground : '#ff8a00')};
  border-radius: 50px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  color: ${props => (props.$subtle ? props.theme.text : '#fff')};

  @media ${breakpoint.md} {
    margin: -6px 8px -6px -10px;
  }
`

const MessageWrap = styled(View)`
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const Message = styled(Text)`
  text-wrap: balance;
  text-align: center;

  @media ${breakpoint.md} {
    text-align: initial;
  }
`

interface IAnnouncementCardProps {
  announcement: IAnnouncement
  to?: ClientRoutePath
  subtleTag?: boolean
}

export const AnnouncementCard: React.FC<IAnnouncementCardProps> = ({ announcement, to, subtleTag }) => (
  <Wrap to={to} className="hover-arrow">
    {announcement.tag && <Tag text={announcement.tag} $subtle={subtleTag} />}
    {announcement.rawTag && <Tag rawText={announcement.rawTag} $subtle={subtleTag} />}
    <MessageWrap>
      {announcement.message && <Message text={announcement.message} />}
      {announcement.rawMessage && <Message rawText={announcement.rawMessage} />}
      <span dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
    </MessageWrap>
  </Wrap>
)
