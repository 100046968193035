import React, { useState } from 'react'

import { PositionCardProps } from '../PositionCard/types'

export const PositionCardSkeleton: React.FC<Omit<PositionCardProps, 'record'>> = ({ showTeam = true, listItem }) => {
  /*
  @todo Math.random() + SSR = mismatch
  const [styles] = useState([
    { width: `${Math.floor(50 + Math.random() * 50)}%` },
    { width: `${Math.floor(30 + Math.random() * 30)}%` },
    { width: `${Math.floor(30 + Math.random() * 30)}%` }
  ])*/

  const [styles] = useState([{ width: '70%' }, { width: '50%' }, { width: 110 }, { width: 60 }])

  return (
    <div className={`job-card${listItem ? ' job-list-item' : ''} skeleton`} aria-hidden="true">
      <div className="job-card-inner">
        <div className="job-card-info">
          <div className="job-card-title">
            <div className="skeleton-text" style={styles[0]} />
          </div>
          <div className="job-card-subtitle">
            {showTeam && <div className="avatar skeleton" />}
            <div className="skeleton-text" style={styles[1]} />
          </div>
        </div>
        <div className="job-card-extra">
          <div className="skeleton-text" style={styles[2]} />
          <span className="skeleton-divider job-card-extra-divider">&nbsp;·&nbsp;</span>
          <div className="skeleton-text" style={styles[3]} />
        </div>
      </div>
    </div>
  )
}
