import { PositionSection } from '@ambition/module-position'
import { CompanySection } from '@ambition/module-team'
import { IFeedSection } from 'core'
import compact from 'lodash/compact'
import React from 'react'

import { IListProps, List } from '~/components'
import { useBreakpoints } from '~/lite'

import { SectionListProps } from './types'

export const SectionList = ({
  data,
  isLoading,
  isRootPage,
  hasNextPage,
  fetchNextPage,
  showPositionSectionTitle,
  queryKey
}: SectionListProps) => {
  const { isPhone } = useBreakpoints()
  const renderItems = React.useCallback<NonNullable<IListProps<IFeedSection>['renderItem']>>(
    ({ item, index }) => {
      if (item.teams) {
        return (
          <CompanySection
            key={index}
            title={item.title}
            seeAllPath={item.seeAllPath}
            data={item.teams}
            isLoading={false}
          />
        )
      }
      if (item.sectionId || item.positions?.data.length) {
        return (
          <PositionSection
            key={index}
            title={showPositionSectionTitle ? item.title : undefined}
            list={showPositionSectionTitle ? false : true}
            reason={item.reason || undefined}
            seeAllPath={item.seeAllPath}
            occupationId={item.occupationId}
            data={item.positions?.data}
            latLng={item.latLng}
            isLoading={false}
            useCarouselLayout={false}
            removable={item.removable}
            isRootPage={isRootPage}
            id={item.sectionId}
            queryKey={queryKey}
          />
        )
      }
      return null
    },
    [queryKey, showPositionSectionTitle, isRootPage]
  )

  const keyExtractor = (item: IFeedSection, index: number) => {
    return compact([item?.title, index.toString()]).join('-')
  }

  const handleFetchMore = () => {
    if (!hasNextPage) {
      return
    }
    fetchNextPage?.()
  }

  return (
    <List
      keyExtractor={keyExtractor}
      data={data}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      onEndReached={handleFetchMore}
      onEndReachedThreshold={!isPhone ? 200 : 0}
      loadingComponent={Array(isRootPage ? 3 : 1)
        .fill(0)
        .map((_, idx) => (
          <PositionSection key={idx} useCarouselLayout={false} isLoading isRootPage={isRootPage} />
        ))}
      renderItem={renderItems}
    />
  )
}
