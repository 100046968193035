import type { IHtmlRendererProps } from '@ambition/ui-html-renderer'
import type { ITrixProps } from '@ambition/ui-trix'
import React, { Suspense } from 'react'

import { Loader } from '~/lite'

const LazyHtmlRenderer = React.lazy(() =>
  import('@ambition/ui-html-renderer').then(module => ({ default: module.HtmlRenderer }))
)

const LazyTrix = React.lazy(() => import('@ambition/ui-trix').then(module => ({ default: module.Trix })))

export const HtmlRenderer: React.FC<IHtmlRendererProps> = props => (
  <Suspense fallback={<Loader />}>
    <LazyHtmlRenderer {...props} />
  </Suspense>
)

export const Trix: React.FC<ITrixProps> = props => (
  <Suspense fallback={null}>
    <LazyTrix {...props} />
  </Suspense>
)
