import { TranslatedText } from 'core'
import React from 'react'

import { useBreakpoints, useStore } from '~/hooks'
import { H2, H3, TextStyle } from '~/lite'

import { View, ViewProps } from '../View/View'

export type SectionProps = {
  title?: TranslatedText
  subtitle?: TranslatedText
  titleStyle?: TextStyle
  rightElement?: React.ReactNode
  children: React.ReactNode
  hasBackground?: boolean
  hasPadding?: boolean
  ref?: React.ForwardedRef<HTMLDivElement>
} & ViewProps

export const Section: React.FC<SectionProps> = ({
  children,
  title,
  titleStyle,
  subtitle,
  rightElement,
  hasBackground,
  hasPadding,
  paddingBottom,
  ...rest
}) => {
  const theme = useStore(state => state.theme)
  const { isDesktop } = useBreakpoints()
  return (
    <View
      className="section"
      backgroundColor={hasBackground ? theme.cardBackground : undefined}
      borderRadius={isDesktop && hasBackground ? 10 : undefined}
      {...rest}
    >
      <View
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingX={hasPadding ? 'm' : undefined}
      >
        {(title || subtitle) && (
          <View flex={1} paddingY="m">
            {title && <H2 fontSize={20} lineHeight="22px" fontWeight="bold" rawText={title} style={titleStyle} />}
            {subtitle && <H3 fontSize={16} lineHeight="16px" fontWeight="bold" rawText={subtitle} style={titleStyle} />}
          </View>
        )}
        {rightElement && (
          <View alignItems="flex-end" minWidth={80} marginLeft="m">
            {rightElement}
          </View>
        )}
      </View>
      <View
        paddingX={hasPadding ? 'm' : undefined}
        paddingBottom={paddingBottom ?? (hasBackground && title ? 'm' : 'none')}
      >
        {children}
      </View>
    </View>
  )
}
