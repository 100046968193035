import { getCountryIcon, ICountry } from 'core'
import React from 'react'

import { useStore } from '../hooks/useStore'
import { Svg } from './Svg'
import { Text } from './Text'
import { View } from './View'

export interface ICountryIconProps {
  country: ICountry
  size?: number
}

export const CountryIcon: React.FC<ICountryIconProps> = ({ country, size = 45 }) => {
  const theme = useStore(state => state.theme)
  const icon = getCountryIcon(country)

  return icon ? (
    <Svg uri={icon} width={size} height={size} />
  ) : (
    <View
      width={size}
      height={size}
      justifyContent="center"
      alignItems="center"
      borderRadius={size}
      backgroundColor={theme.buttonColor}
      borderWidth={2}
      borderColor={theme.border}
    >
      <Text fontSize={size - 10} rawText={country.emoji} />
    </View>
  )
}
