export const fetchWithStream = async ({
  endpoint,
  options,
  callback
}: {
  endpoint: string
  options: Record<string, any>
  callback: (chunk: string | undefined, error?: true) => void
}): Promise<void> => {
  // our api lib uses cross-fetch, which uses whatwg-fetch on web, which doesn't support streaming responses.
  // so switch to native fetch. see: https://github.com/github/fetch/issues/746
  const response = await fetch(endpoint, options)

  if (!response.ok || !response.body) {
    callback(undefined, true)
    return
  }

  const reader = response.body.getReader()
  const decoder = new TextDecoder()
  let dataBuffer = ''

  try {
    for (;;) {
      const { value, done } = await reader.read()

      if (done) {
        break
      }

      dataBuffer += decoder.decode(value)
      const lines = dataBuffer.split('\n')

      while (lines.length > 1) {
        callback(lines.shift()?.trim())
      }

      dataBuffer = lines[0]
    }
  } finally {
    reader.releaseLock()
  }
}

export const rawFetchWithStream = async ({
  endpoint,
  options,
  onChunk,
  onEnd
}: {
  endpoint: string
  options: Record<string, any>
  onChunk: (chunk: Uint8Array | undefined) => Promise<void>
  onEnd: (receivedLength: number, error?: true) => void
}): Promise<void> => {
  const response = await fetch(endpoint, options)
  let receivedLength = 0

  if (!response.ok || !response.body) {
    onEnd(receivedLength, true)
    return
  }

  const reader = response.body.getReader()
  try {
    for (;;) {
      const { value, done } = await reader.read()

      if (done) {
        break
      }

      receivedLength += value.length

      await onChunk(value)
    }
  } finally {
    reader.releaseLock()
    onEnd(receivedLength)
  }
}
