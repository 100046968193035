import { CompactPositionRecord, ExplorePositionTypeQueryMapping } from 'core'
import React from 'react'

import { IListProps, List } from '~/components'
import { useExplorePositions } from '~/hooks'
import { useBreakpoints, useStore } from '~/lite'

import { PositionCard } from '../PositionCard'
import { PositionListProps } from './types'

export const PAGE_LIMIT = 10

export const PositionList: React.FC<PositionListProps> = ({ type, positionId }) => {
  const { isPhone } = useBreakpoints()
  const jobScope = useStore(state => state.jobScope)

  const queryKeyName = ExplorePositionTypeQueryMapping[type]
  const queryKey = React.useMemo(() => {
    const keys = [`${queryKeyName[0]}/infinite`]
    if (jobScope) {
      keys.push(jobScope)
    }
    if (positionId) {
      keys.push(positionId)
    }
    return keys
  }, [jobScope, positionId, queryKeyName])

  const { data, isLoading, fetchNextPage, hasNextPage } = useExplorePositions(
    type,
    queryKey,
    PAGE_LIMIT,
    positionId,
    jobScope
  )

  const positionList = React.useMemo(() => {
    return data?.pages.flatMap(group => {
      if (!group) {
        return null
      }
      return group.data.map(item => item)
    }) as CompactPositionRecord[]
  }, [data?.pages])

  const renderItems = React.useCallback<NonNullable<IListProps<CompactPositionRecord>['renderItem']>>(({ item }) => {
    if (!item) {
      return null
    }

    return <PositionCard key={item.id} record={item} width="100%" borderRadius={0} />
  }, [])

  const keyExtractor = (item: CompactPositionRecord) => {
    return item?.id
  }

  const handleFetchMore = () => {
    if (!hasNextPage) {
      return
    }
    fetchNextPage()
  }

  return (
    <List
      hasDivider
      keyExtractor={keyExtractor}
      data={positionList}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      onEndReached={handleFetchMore}
      renderItem={renderItems}
      onEndReachedThreshold={!isPhone ? 200 : 0}
    />
  )
}
