import { styled } from '~/lite'

import { Text } from './Text'

export const BoldText = styled(Text)`
  font-weight: 600;
`

export const MutedText = styled(Text)`
  color: ${props => props.theme.inputPlaceholder};
`

export const ErrorText = styled(Text)`
  color: ${props => props.theme.error};
`

export const SectionTitle = styled(Text)`
  font-size: 18px;
  font-weight: 400;
`

export const CenteredText = styled(Text)`
  text-align: center;
  margin: 12px auto;
`

export const HeaderText = styled(Text)`
  font-size: 17px;
  font-weight: 600;
`

export const BodyText = styled(Text)`
  padding: 10px;
  flex-direction: row;
  font-size: 14px;
  text-align: center;
`
