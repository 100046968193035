import React from 'react'
import { border, color, display, space, typography } from 'styled-system'

import { useTranslation } from '../hooks'
import { convertNativeToWebStyle, getStyledConfig, styled } from '../styled'
import { ITextProps, IUnstyledTextProps } from './types'

const InnerText: React.FC<IUnstyledTextProps> = ({
  text,
  rawText,
  children = [],
  href,
  hrefAttrs,
  testID,
  tag,
  style,
  onPress,
  numberOfLines,
  ellipsizeMode,
  appearance,
  status,
  ...props
}) => {
  const t = useTranslation()
  const innerCmps = [rawText ? rawText : t(text ?? ''), ...(Array.isArray(children) ? children : [children])]
  const inner = innerCmps.length === 1 ? innerCmps[0] : innerCmps

  const webStyle: React.CSSProperties = {
    ...(numberOfLines != null && numberOfLines > 1 && { WebkitLineClamp: numberOfLines }),
    ...convertNativeToWebStyle(style)
  }

  const scClassName = (props as { className?: string }).className
  let className = scClassName ?? ''

  if (numberOfLines === 1) {
    className += ' single-line'
  } else if (numberOfLines != null && numberOfLines > 1) {
    className += ' multi-line'
  }

  const webProps = {
    ...props,
    className,
    style: webStyle,
    ...(testID && { 'data-testid': testID }),
    ...(onPress && { onClick: onPress })
  }

  if (href) {
    return (
      <a href={href} download={hrefAttrs?.download} rel={hrefAttrs?.rel} target={hrefAttrs?.target} {...webProps}>
        {inner}
      </a>
    )
  }

  return React.createElement(tag ?? 'span', webProps, inner)
}

export const StyledText = styled(InnerText).withConfig(getStyledConfig())<ITextProps>`
  ${color}
  ${space}
  ${typography}
  ${border}
  ${display}
`

export const Text: React.FC<ITextProps> = props => <StyledText {...props} />
