import { generateUUID, IChannel, IMessage, IUser, NewChannel, SendMessage, TranslatedText } from 'core'
import { compact } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useChannel, useSendMessage } from '~/hooks'
import { isNotBot, isWeb, pusher, useMessageStore, useStore, useTranslation } from '~/lite'

import type { IChannelProps } from './Channel'
import { IChannelToolbarProps } from './ChannelToolbar'
import { useSideEffectHandler } from './useSideEffectHandler'
import { sendMessageWithStreamingResponse } from './util'

const sortMessages = (messages: IMessage[]) =>
  messages.sort((m1, m2) => m1.createdAt.getTime() - m2.createdAt.getTime())

export const useMessaging = (props: IChannelProps) => {
  const t = useTranslation()
  const currentUserId = useStore(state => state.currentUserId)
  const channelsById = useMessageStore(state => state.channels)
  const jobScope = useStore(state => state.jobScope)
  const shouldLoadMessages = useStore(state => state.shouldLoadMessages)
  const messagesByChannelId = useMessageStore(state => state.messages)
  const addMessages = useMessageStore(state => state.addMessages)
  const addChannel = useMessageStore(state => state.addChannel)
  const [sendingDisabled, setSendingDisabled] = useState(false)
  const { mutateAsync: sendMessage } = useSendMessage()
  const sideEffectHandler = useSideEffectHandler()

  const { assistant, onSendMessage } = props
  const streamResponse = !!assistant

  const { channelId, newChannel } = useMemo<Pick<IChannelProps, 'channelId' | 'newChannel'>>(() => {
    if (assistant) {
      const existingChannel = Object.values(channelsById).find(channel => channel.type === 'Assistant')
      return existingChannel ? { channelId: existingChannel.id } : { newChannel: { type: 'Assistant' } }
    }

    return { channelId: props.channelId, newChannel: props.newChannel }
  }, [props.channelId, props.newChannel, assistant, channelsById])

  // a "ghost" is a channel or message that doesn't exist on the server, but is used in UI
  const ghostChannelId = useMemo(() => `ghost-${generateUUID()}`, [])

  const messagesById = channelId
    ? messagesByChannelId[channelId]
    : ghostChannelId
    ? messagesByChannelId[ghostChannelId as IChannel['id']]
    : null

  const { isLoading, status: loadChannelStatus } = useChannel(
    isNotBot && shouldLoadMessages ? channelId ?? newChannel ?? null : null,
    {
      onSuccess: channel => {
        // if there are messages, add them to the global store
        if (channel?.messages && channel.messages.length > 0) {
          addMessages([...channel.messages])
        }
      }
    }
  )

  const [typingMap, setTypingMap] = useState<Record<IUser['id'], ReturnType<typeof setTimeout> | null>>({})

  const pusherChannel = useRef<ReturnType<typeof pusher.subscribeToChannel> | null>(null)

  const typingUserIds = useMemo(
    () => compact(Object.entries(typingMap).map(([userId, typing]) => (typing ? (userId as IUser['id']) : null))),
    [typingMap]
  )

  const greeting = useMemo<IMessage | null>(() => {
    const messageIds = Object.keys(messagesById ?? {}) as IMessage['id'][]
    const id = `ghost-${ghostChannelId}-greeting` as IMessage['id']

    if (
      assistant &&
      !channelId &&
      (!shouldLoadMessages || loadChannelStatus === 'success') &&
      (messageIds.length === 0 || (messageIds.length === 1 && messageIds[0] === id))
    ) {
      const greeting: IMessage = {
        // backend follows this convention to replace it by id in the response
        id,
        channelId: ghostChannelId as IChannel['id'],
        userId: null,
        message: t('Hello! I can help you find job opportunities. What type of job are you looking for?'),
        role: 'Greeting',
        state: 'Ghost',
        createdAt: new Date(),
        updatedAt: null
      }

      if (messagesById?.[id]?.message !== greeting.message) {
        setTimeout(() => addMessages([greeting]), 0)
      }

      return greeting
    }

    return null
  }, [assistant, shouldLoadMessages, loadChannelStatus, channelId, messagesById, addMessages, ghostChannelId, t])

  const messages = useMemo(() => {
    const messages = messagesById ? sortMessages(Object.values(messagesById)) : []
    return shouldLoadMessages || messages.length > 0 || !greeting ? messages : [greeting]
  }, [messagesById, shouldLoadMessages, greeting])

  useEffect(() => {
    if (channelId && !pusherChannel.current && !streamResponse) {
      pusherChannel.current = pusher.subscribeToChannel(channelId, ({ event, data }) => {
        if (event === 'client-typing') {
          setTypingMap(typingMap => {
            const existingTimeout = typingMap[data.userId]

            if (existingTimeout) {
              clearTimeout(existingTimeout)
            }

            const typingTimeout = setTimeout(() => {
              setTypingMap(typingMap => ({ ...typingMap, [data.userId]: null }))
            }, 5000)

            return { ...typingMap, [data.userId]: typingTimeout }
          })
        }
      })

      pusherChannel.current.onSubscriptionSucceeded(async () => {
        /*pusherChannel.current?.members.each(function (member: unknown) {
          console.log({ member })
        })*/
      })
    }

    return () => {
      pusherChannel.current?.unsubscribe()
      pusherChannel.current = null
    }
  }, [channelId, streamResponse])

  const handleSendMessage = useCallback<IChannelToolbarProps['handleSendMessage']>(
    async newMessage => {
      if (!channelId && !newChannel) {
        throw new Error('Tried to send message when both channelId and newChannel are undefined')
      }

      const ghost: IMessage = {
        id: `ghost-${generateUUID()}` as IMessage['id'],
        channelId: channelId ?? (ghostChannelId as IChannel['id']),
        userId: currentUserId,
        message: newMessage,
        state: 'Ghost',
        createdAt: new Date(),
        updatedAt: null
      }

      const assistantGhost: IMessage | null = assistant
        ? {
            id: `ghost-${generateUUID()}` as IMessage['id'],
            channelId: channelId ?? (ghostChannelId as IChannel['id']),
            userId: null,
            message: '' as TranslatedText,
            role: 'Assistant',
            state: 'Ghost',
            createdAt: new Date(new Date().getTime() + 1000),
            updatedAt: null
          }
        : null

      addMessages([ghost, ...(assistantGhost ? [assistantGhost] : [])])
      setSendingDisabled(true)
      onSendMessage?.()

      const params: Parameters<typeof sendMessage>[0] = {
        ...(channelId ? { channelId } : { channel: newChannel as NewChannel }),
        message: newMessage,
        ghostId: ghost.id,
        channelGhostId: channelId ? undefined : (ghostChannelId as IChannel['id']),
        assistantGhostId: assistantGhost?.id,
        greeting: greeting?.message,
        scope: jobScope
      }

      const handleMessageResponse = async (response: SendMessage['response']) => {
        if (!response.success) {
          return
        }

        for (const channel of response.channels ?? []) {
          addChannel(channel)
        }

        addMessages(response.messages)

        const sideEffects = response.messages
          .map(message => message.commands ?? [])
          .flat()
          .map(command => command.sideEffects ?? [])
          .flat()

        for (const sideEffect of sideEffects) {
          sideEffectHandler(sideEffect)
        }
      }

      if (streamResponse && isWeb) {
        await sendMessageWithStreamingResponse(params, handleMessageResponse)
      } else {
        const result = await sendMessage(params)

        if (result) {
          handleMessageResponse({ success: true, ...result })
        }
      }

      setSendingDisabled(false)
    },
    [
      jobScope,
      channelId,
      newChannel,
      addChannel,
      addMessages,
      ghostChannelId,
      sideEffectHandler,
      sendMessage,
      currentUserId,
      assistant,
      onSendMessage,
      streamResponse,
      greeting
    ]
  )

  return {
    assistant,
    handleSendMessage,
    isLoading: isLoading && shouldLoadMessages,
    messages,
    pusherChannel,
    sendingDisabled,
    typingUserIds
  }
}
