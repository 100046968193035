import { ICompany } from '../models'
import { Endpoint, get } from '../util/api'

export type GetCompaniesSuggestion = Endpoint<
  Record<string, any>,
  {
    success: true
    companies: ICompany[]
  }
>
export const getCompanySuggestions = (query: string) => get<GetCompaniesSuggestion>(`/companies/suggest/${query}`)
