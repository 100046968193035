import { IMarketProfile } from 'core'
import React from 'react'

import { breakpoint, Button, H1, H2, styled, View } from '~/lite'

import { SectionContent } from './styles'

const Header = styled(H1)`
  text-align: center;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
  text-wrap: balance;
  hyphens: none;
  font-weight: 500;
  font-size: 44px;
  margin: 32px 16px 16px;
  background: ${props =>
    props.theme.dark
      ? 'linear-gradient(to right bottom, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0.38))'
      : 'linear-gradient(to right bottom, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0.38))'};
  -webkit-background-clip: text;

  ::selection {
    -webkit-text-fill-color: #fff;
  }

  @media ${breakpoint.md} {
    margin-top: 64px;
    font-size: 60px;
  }
`

const Subheader = styled(H2)`
  text-align: center;
  text-wrap: balance;
  color: ${props => (props.theme.dark ? '#b4bcd0' : props.theme.text)};
  margin: 0 16px 16px;
  font-size: 22px;
  line-height: 1.3;
  font-weight: 400;

  @media ${breakpoint.md} {
    margin-bottom: 32px;
  }
`

interface IHeroProps {
  profile: IMarketProfile
}

export const Hero: React.FC<IHeroProps> = ({ profile }) => {
  if (!profile.metadata.hero) {
    return null
  }

  const hasPlans = (profile.products ?? []).filter(product => product.isRecurring).length > 0
  const hasProducts = (profile.products ?? []).filter(product => !product.isRecurring).length > 0

  const onActionButtonPress = () => {
    const target = document.getElementById(hasPlans ? 'plans' : hasProducts ? 'products' : 'about')
    target?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  const { header, subheader, cta } = profile.metadata.hero

  return (
    <SectionContent>
      {!!header && <Header rawText={header} />}
      {!!subheader && <Subheader rawText={subheader} />}
      {cta && (
        <View flexDirection="row" alignItems="stretch" justifyContent="center">
          <Button onPress={onActionButtonPress} rawText={cta} primary skipTracking large />
        </View>
      )}
    </SectionContent>
  )
}
