import { css, styled } from '~/lite'
import { isWeb } from '~/util'

import { FlexVertical } from '../Flex/Flex'
import { H5 } from '../H/H'
import { Icon } from '../Icon/Icon'
import { View } from '../View/View'

export const Wrap = styled(FlexVertical)<{ $active?: boolean; $isPhone?: boolean; $fill?: boolean }>`
  position: relative;
  box-shadow: ${props => (props.$fill ? 'none' : props.theme.boxShadow)};
  border-radius: ${props => (props.$fill ? 0 : props.theme.borderRadius)};
  overflow: hidden;
  background-color: ${props => props.theme.cardBackground};
  ${props =>
    props.$fill &&
    css`
      flex: 1;
    `}
  ${props =>
    props.$isPhone &&
    css`
      width: 100%;
    `}
  ${isWeb &&
  css`
    overflow: auto;
  `}
  ${props =>
    typeof props.$active !== 'undefined' &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      border-radius: ${props => props.theme.borderRadius};
      box-shadow: none;
      top: -48px;
      opacity: 0;
      z-index: -1;
      overflow: visible;
      border-bottom-width: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${props =>
    props.$active &&
    css`
      opacity: 1;
      z-index: 1;
    `}
`

export const Header = styled(View)`
  flex-direction: row;
`

export const HeaderInner = styled(H5)`
  flex: 1;
  padding: 24px 20px 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.02em;
`

export const CloseIcon = styled(Icon)`
  padding: 15px 20px;
`

export const Subheader = styled(View)`
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.border};
`

export const Body = styled(FlexVertical)<{ $noBodyPadding: boolean }>`
  flex: 1;
  flex-basis: auto;
  padding: ${props => (props.$noBodyPadding ? 0 : '20px')};
`

export const GeneralError = styled(View)`
  margin-bottom: 15px;
  background-color: rgba(219 55 55 / 15%);
  padding: 10px 12px 9px;
`
