import { styled } from '~/lite'

import { View } from '../View/View'

export const Flex = styled(View)`
  display: flex;
`

export const FlexHorizontal = styled(View)`
  display: flex;
  flex-direction: row;
`

export const FlexVertical = styled(View)`
  display: flex;
  flex-direction: column;
`
export const ButtonContainer = styled(Flex)`
  flex-flow: row;
  justify-content: space-between;
`
export const FormButtonContainer = styled(Flex)`
  flex-flow: row;
  justify-content: flex-end;
`
