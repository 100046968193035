import React, { Suspense, useState } from 'react'

import { useBreakpoints, useOnHydrate } from '~/lite'

const LazyReactQueryDevtools = React.lazy(() =>
  import('@tanstack/react-query-devtools').then(module => ({ default: module.ReactQueryDevtools }))
)

export const ReactQueryDevtools: React.FC = () => {
  const [isHydrated, setIsHydrated] = useState(false)
  const { isPhone } = useBreakpoints()

  useOnHydrate(() => {
    setIsHydrated(true)
  })

  if (!isHydrated) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <LazyReactQueryDevtools
        initialIsOpen={false}
        {...(isHydrated && isPhone && { toggleButtonProps: { style: { bottom: 50 } } })}
      />
    </Suspense>
  )
}
