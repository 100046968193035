import { Button, Flex, FlexHorizontal, FlexVertical, FormGroup, Input, ScrollView, styled, View } from '~/components'
import { getWindowHeight, getWindowWidth } from '~/util'

export const SearchIcon = styled(Flex)`
  height: ${getWindowHeight() / 1.5}px;
  align-items: center;
  justify-content: center;
`

export const SelectLocationWrap = styled(Flex)`
  padding: 14px 10px;
  font-size: 20px;
  width: 100%;
  height: 100%;
`

export const DialogBody = styled(FlexVertical)`
  padding: 12px 12px;
  min-width: ${getWindowWidth() - 80}px;
`

export const SummaryTooltip = styled(View)`
  max-width: 300px;
`

export const Header = styled(FlexHorizontal)`
  justify-content: space-between;
  background-color: ${props => props.theme.cardBackground};
  align-items: center;
  padding: 16px 8px;
  border-bottom-width: 1px;
`

export const Row = styled(FlexHorizontal)`
  justify-content: space-between;
`

export const ProfileFormWrap = styled(FlexVertical)`
  flex: 1;
`

export const StyledFormGroup = styled(FormGroup)`
  flex: 1;
`

export const SpacedFormGroup = styled(StyledFormGroup)`
  padding-left: 20px;
`

export const ApplicationStatusInputWrap = styled(View)`
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: space-between;
`

export const ApplicationStatusInput = styled(Input)`
  flex: 3;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
`

export const ColumnFlex = styled(ScrollView)`
  flex-direction: column;
`

export const ApplicationFormWrap = styled(View)`
  flex-direction: column;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
`

export const TeamButton = styled(Button)`
  flex: 1;
  border-color: ${props => props.theme.placeholder};
  background-color: ${props => props.theme.buttonBackground};
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
`
