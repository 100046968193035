import { getCountry, getTranslationFn } from 'core'
import { useMemo } from 'react'

import { useStore } from './useStore'

export const useTranslation = () => {
  const locale = useStore(state => state.locale)
  const allTranslations = useStore(state => state.translations)

  return useMemo(
    () => getTranslationFn(locale, allTranslations[locale] ?? null, getCountry()),
    [locale, allTranslations]
  )
}
