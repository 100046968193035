import React from 'react'

import { useBottomTabBarHeight, useSkillCollections } from '~/hooks'

import { CollectionList } from './CollectionList'

export const SkillCollections: React.FC = () => {
  const tabBarHeight = useBottomTabBarHeight()

  const { data, isLoading, isFetching } = useSkillCollections()

  return (
    <CollectionList
      contentContainerStyle={{ paddingBottom: tabBarHeight }}
      testID="collections-list"
      collections={data ?? []}
      isLoading={isLoading || isFetching}
    />
  )
}
