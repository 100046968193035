import { useQueryClient } from '@tanstack/react-query'
import { TranslatedText } from 'core'
import React, { useCallback, useRef, useState } from 'react'

import { Accordion, Input } from '~/components'
import { useCopilotContent, useSubmitUserPrompt, useUserPrompt } from '~/hooks'
import {
  Button,
  Dialog,
  Icon,
  Loader,
  Text,
  useBreakpoints,
  useHistory,
  useOnClickOutside,
  useStore,
  View
} from '~/lite'

import { DownloadApp } from '../DownloadApp'
import { Channel, IChannelProps } from '../messaging'
import {
  AccordionWrap,
  AssistantHeader,
  AssistantHeaderText,
  AssistantOnline,
  AssistantWrap,
  CloseButton,
  Example,
  ExampleText
} from './styles'
import { IAssistantProps } from './types'

export const Assistant: React.FC<IAssistantProps> = ({ view, setView, setInputFocused }) => {
  const [showDownloadApp, setShowDownloadApp] = useState(false)
  const wrapRef = React.useRef<HTMLDivElement>(null)
  const { isPhone } = useBreakpoints()
  const history = useHistory()
  const queryClient = useQueryClient()
  const resumeImportState = useStore(state => state.resumeImportState)
  const locale = useStore(state => state.locale)

  const instructionsInputRef = useRef<HTMLInputElement>(null)
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false)
  const [pendingInstructions, setPendingInstructions] = useState<TranslatedText | null>(null)
  const { data: content, isLoading: isLoadingContent } = useCopilotContent({ enabled: isInstructionsOpen })

  const { data: savedInstructions, isLoading: isLoadingInstructions } = useUserPrompt('JobMacro', locale)
  const { mutate: submitInstructions, isLoading: isSubmittingInstructions } = useSubmitUserPrompt({
    onSuccess: async data => {
      if (data.success) {
        await queryClient.invalidateQueries(['user-prompt'])
        // onChange({ instructions: pendingInstructions })
        setPendingInstructions(null)
        setIsInstructionsOpen(false)
      }
    }
  })

  const onSaveInstructionsButtonPress = () => {
    if (pendingInstructions !== null && (pendingInstructions ?? '') !== (savedInstructions?.promptValue ?? '')) {
      submitInstructions({
        promptValue: (pendingInstructions ?? '') as TranslatedText,
        type: 'JobMacro'
      })
    } else {
      setIsInstructionsOpen(false)
    }
  }

  const instructions = pendingInstructions ?? savedInstructions?.promptValue ?? ''

  const onSendMessage = useCallback(() => {
    setView?.('Full')
  }, [setView])

  const onInputFocus = useCallback<NonNullable<IChannelProps['onInputFocus']>>(
    messages => {
      setInputFocused?.(true)
      if (messages.some(message => message.role !== 'Greeting')) {
        setView?.('Full')
      }
    },
    [setInputFocused, setView]
  )

  const onClickOutside = React.useCallback(() => {
    setInputFocused?.(false)
    setView?.(view => (view === 'Full' && isPhone ? 'Mini' : view))
  }, [setInputFocused, setView, isPhone])

  useOnClickOutside(wrapRef, onClickOutside)

  const onClose = () => {
    if (view !== 'Closed') {
      setView?.('Closed')
      setShowDownloadApp(false)
      return
    }
    setView?.('Full')
  }

  return (
    <AssistantWrap
      ref={wrapRef}
      className="assistant"
      $mini={view === 'Mini' && isPhone && !showDownloadApp}
      $closed={view === 'Closed'}
    >
      <AssistantHeader>
        <View flexDirection="row" alignItems="center" flex={1} overflow="hidden">
          <AssistantHeaderText rawText={'Copilot' as TranslatedText} />
          <AssistantOnline />
        </View>
        {showDownloadApp ? (
          <Button
            text="Close"
            onPress={() => {
              setShowDownloadApp(false)
              isPhone && setView?.('Closed')
            }}
            marginY="s"
            small
            skipTracking
          />
        ) : (
          <View flexDirection="row" justifyContent="start">
            <Button
              action="AnalyzeResume"
              text={resumeImportState ? 'Update CV' : 'Analyze CV'}
              onPress={() => history.push('/analyze')}
              marginRight="s"
              marginY="s"
              small
              primary={!resumeImportState}
            />
            <Dialog
              open={isInstructionsOpen}
              onOpenChange={open => {
                setIsInstructionsOpen(open)
                setPendingInstructions(null)
              }}
              trigger={
                <Button
                  action="CustomizeUserPrompt"
                  icon="cog-outline"
                  onPress={() => {
                    setIsInstructionsOpen(true)
                    ;(instructionsInputRef.current as any)?.setValue(savedInstructions ?? '')
                  }}
                  height={28}
                  width={28}
                  paddingX={0}
                  marginY={6}
                  borderRadius={50}
                  small
                />
              }
              title="Custom instructions"
              description="Adjust how you'd like Copilot to present job details to you."
              footer={
                <>
                  <Button
                    text="Cancel"
                    onPress={() => {
                      setIsInstructionsOpen(false)
                      setPendingInstructions(null)
                    }}
                    minimal
                    skipTracking
                  />
                  <Button
                    text="Save"
                    onPress={onSaveInstructionsButtonPress}
                    loading={isSubmittingInstructions}
                    disabled={isSubmittingInstructions}
                    primary
                    skipTracking
                  />
                </>
              }
            >
              {isLoadingInstructions || isLoadingContent ? (
                <View paddingY={16}>
                  <Loader />
                </View>
              ) : (
                <>
                  <Input
                    ref={instructionsInputRef}
                    numberOfLines={3}
                    value={instructions}
                    onChange={value => setPendingInstructions(value as TranslatedText)}
                  />
                  {!!content?.data && (
                    <>
                      <View flexDirection="row" alignItems="center">
                        <Icon icon="bulb-outline" marginRight={4} size={18} />
                        <Text text="Inspiration" fontWeight={600} fontSize={18} />
                      </View>
                      <AccordionWrap>
                        <Accordion
                          data={content.data.instructions.examples.map(example => ({
                            trigger: example.header,
                            content: (
                              <>
                                {example.examples.map((example, idx) => (
                                  <Example key={idx}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="1.75"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z" />
                                      <path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z" />
                                    </svg>
                                    <ExampleText rawText={example} />
                                  </Example>
                                ))}
                              </>
                            )
                          }))}
                        />
                      </AccordionWrap>
                    </>
                  )}
                </>
              )}
            </Dialog>
            {/*<Button
              action="DownloadApp"
              text="Get App"
              onPress={() => {
                setShowDownloadApp(true)
                isPhone && setView?.('Full')
              }}
              marginY="s"
              small
            />*/}
          </View>
        )}
        <CloseButton
          icon={view !== 'Closed' ? 'chevron-up' : 'chevron-down'}
          iconSize={18}
          padding="xs"
          marginLeft="s"
          marginY="s"
          onPress={onClose}
          small
          skipTracking
        />
      </AssistantHeader>
      {showDownloadApp && (
        <View flex={1} justifyContent="center">
          <DownloadApp />
        </View>
      )}
      {!showDownloadApp && (
        <Channel onSendMessage={onSendMessage} onInputFocus={onInputFocus} autoFocusInput={view === 'Full'} assistant />
      )}
    </AssistantWrap>
  )
}
