import React from 'react'

import { styled } from '../styled'
import { breakpoint } from '../util/style'
import { ContainerProps } from './types'
import { View } from './View'

export const StyledView = styled(View)`
  width: 100%;

  @media ${breakpoint.xl} {
    width: 900px;
  }

  @media ${breakpoint['2xl']} {
    width: 1236px;
  }
`

export const Container: React.FC<ContainerProps> = ({ children, ...rest }) => (
  <StyledView marginLeft="auto" marginRight="auto" {...rest}>
    {children}
  </StyledView>
)
