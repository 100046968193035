import React from 'react'

import { Section, SectionDivider } from '~/components'
import { usePositionComments } from '~/hooks'
import { useTranslation, View } from '~/lite'

import { Comment } from './Comment'
import { CommentsSectionProps } from './types'

export const CommentsSection: React.FC<CommentsSectionProps> = ({ positionId, commentsCount }) => {
  const t = useTranslation()
  const { data, isLoading } = usePositionComments(positionId, !!commentsCount)

  if (!commentsCount || isLoading || !data || data.comments.length === 0) {
    return null
  }

  return (
    <>
      <SectionDivider />
      <Section title={t('Questions & answers')} paddingX="m" hasBackground>
        <View display="flex" flexDirection="column">
          {data.comments.map(comment => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </View>
      </Section>
    </>
  )
}
