import { DistanceFilters, markTranslated, TranslatedText, updateUserPreference } from 'core'
import { isNil, isUndefined } from 'lodash'
import React from 'react'

import { useTranslation, useValidateSearchRoute } from '~/hooks'
import { Select, useStore } from '~/lite'
import { emptyString } from '~/util'

interface IDistanceFieldProps {}

export const DistanceField: React.FC<IDistanceFieldProps> = () => {
  const t = useTranslation()
  const { locale, distanceRadiusFilter, setDistanceRadiusFilter } = useStore()
  const validateRoute = useValidateSearchRoute()

  const options = React.useMemo(() => {
    return DistanceFilters.km.map((item, index) => {
      return {
        value: item,
        label:
          item === 0
            ? t('Any distance')
            : t(
                'Within {{number}} {{unit}}',
                locale === 'en-US'
                  ? { number: DistanceFilters.mi[index], unit: markTranslated('miles') }
                  : { number: item, unit: markTranslated('km') }
              )
      }
    })
  }, [t, locale])

  const onChange = (value: number | undefined) => {
    if (isUndefined(value)) {
      return
    }
    validateRoute('distanceRadius', value.toString())
    setDistanceRadiusFilter(value)
    updateUserPreference({ distanceRadius: value })
  }

  return (
    <Select<{ label: TranslatedText; value: number }>
      placeholder={t('Any distance')}
      options={options}
      onChange={option => option !== null && onChange(option.value)}
      value={
        !isNil(distanceRadiusFilter)
          ? {
              label: options.find(item => item.value === distanceRadiusFilter)?.label || emptyString,
              value: distanceRadiusFilter
            }
          : null
      }
    />
  )
}
