import * as RHoverCard from '@radix-ui/react-hover-card'
import React from 'react'

import { keyframes, styled } from '~/lite'

const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px); }
  100% { opacity: 1; transform: translateY(0); }
`

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px); }
  100% { opacity: 1; transform: translateX(0); }
`

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
`

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px); }
  100% { opacity: 1; transform: translateX(0); }
`

const HoverCardContent = styled(RHoverCard.Content)<{ sideOffset?: number }>`
  padding: 16px;
  width: 300px;
  border-radius: 12px;
  background: ${props => props.theme.cardBackground}dd;
  backdrop-filter: blur(10px);
  border-width: 1px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'] {
    &[data-side='top'] {
      animation-name: ${slideDownAndFade};
    }
    &[data-side='right'] {
      animation-name: ${slideLeftAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${slideRightAndFade};
    }
  }
`

interface IHoverCardProps {
  trigger: React.ReactNode
  content: React.ReactNode
  openDelay?: number
  closeDelay?: number
  arrow?: boolean
}

export const HoverCard: React.FC<IHoverCardProps> = ({ trigger, content, arrow = true, ...props }) => (
  <RHoverCard.Root {...props}>
    <RHoverCard.Trigger asChild>{trigger}</RHoverCard.Trigger>
    <RHoverCard.Portal>
      <HoverCardContent sideOffset={8}>{content}</HoverCardContent>
    </RHoverCard.Portal>
  </RHoverCard.Root>
)
