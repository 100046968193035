import { TranslatedText, UntranslatedText } from 'core'
import React from 'react'

import { useStore } from '~/hooks'

import { Text } from '../Text/Text'
import { View } from '../View/View'
import { Placeholder } from './styles'

export interface IInfoListProps {
  items: { label: UntranslatedText; value?: TranslatedText | null | undefined; component?: React.ReactNode }[]
  testID?: string
  skeleton?: boolean
}

export const InfoList: React.FC<IInfoListProps> = ({ items, skeleton, ...props }) => {
  const theme = useStore(state => state.theme)

  return (
    <View display="flex" flexDirection="row" marginBottom="m" {...props}>
      {items
        .filter(item => skeleton || !!item.value || !!item.component)
        .map((item, idx) => (
          <View key={idx} display="flex" flex={1} paddingLeft={idx === 0 ? 'none' : 'm'}>
            <View marginBottom="xs" color={theme.text} opacity={0.6}>
              <Text text={item.label} fontWeight={500} fontSize="12px" lineHeight="14px" />
            </View>
            <View lineHeight="21px">
              {item.value ? <Text rawText={item.value} fontSize="18px" fontWeight={500} /> : null}
              {item.component}
              {!item.value && !item.component && skeleton ? (
                <Placeholder
                  text=""
                  backgroundColor={theme.placeholder}
                  color="transparent"
                  borderRadius={theme.borderRadius}
                />
              ) : null}
            </View>
          </View>
        ))}
    </View>
  )
}
