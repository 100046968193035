import React from 'react'

import { styled, View } from '~/lite'

const Wrap = styled(View)`
  flex: 1;
  width: 100%;
`

// first column for hour labels: 50px
// height of 1-hour row: 40px
// border color: #e0e0e0
const GridWrap = styled(View)`
  display: grid;
  grid-template-columns: 50px repeat(5, 1fr);
  grid-auto-rows: 40px;
  grid-gap: 1px;
  background-color: ${props => (props.theme.dark ? '#404040' : '#e5e5e5')};
`

const DayHeader = styled(View)`
  color: ${props => (props.theme.dark ? '#dddede' : '#3b3b3b')};
  background: ${props => (props.theme.dark ? '#222424' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`

const HourHeader = styled(View)`
  color: ${props => (props.theme.dark ? '#595b5c' : '#c0c0c0')};
  background: ${props => (props.theme.dark ? '#222424' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`

const HourBlock = styled(View)`
  background: ${props => (props.theme.dark ? '#222424' : '#fff')};
`

const WorkWeek = styled(View)`
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1px
  background: ${props => (props.theme.dark ? '#1d1f21' : '#fff')};
`

const Day = styled(View)`
  border-left: 1px solid #404040;
  border-right: 1px solid #404040;
  padding: 8px;
  font-size: 12px;
  background-color: #f5f5f5;
  text-align: center;
  font-weight: bold;
`

const AppointmentWrap = styled(View)`
  flex-direction: row;
  border-left: 3px solid #9fe1e7;
  border-radius: 3px;
  padding: 4px 4px;
  margin: 1px;
  font-size: 12px;
  background-color: ${props => (props.theme.dark ? '#344448' : '#e9f7f8')};
  color: ${props => (props.theme.dark ? '#9fe1e7' : '#567e82')};
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
`

const Grid: React.FC = () => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
  const hours = Array.from({ length: 11 }, (_, i) => 8 + i) // For hours 8 through 18 (6 PM)

  return (
    <GridWrap>
      <DayHeader /> {/* Empty cell on top-left */}
      {days.map(day => (
        <DayHeader key={day}>{day}</DayHeader>
      ))}
      {hours.map(hour => (
        <React.Fragment key={hour}>
          <HourHeader>{hour}:00</HourHeader>
          {days.map(day => (
            <HourBlock key={day} />
          ))}
        </React.Fragment>
      ))}
    </GridWrap>
  )
}

interface ITime {
  hour: number
  minute: number
}

interface IAppointment {
  id: number
  day: number
  start: ITime
  end: ITime
  name: string
}

const names = ['Alice', 'Bob', 'Charlie', 'David', 'Eve']

const generateAppointments = (version: 'Before' | 'After') => {
  const appointments: IAppointment[] = []
  let nameIndex = 0

  for (let day = version === 'Before' ? 0 : 4; day < 5; day++) {
    for (let hour = version === 'Before' ? 8 : 17; hour < 18; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        appointments.push({
          id: appointments.length + 1,
          day: day,
          start: { hour: hour, minute: minute },
          end: { hour: minute < 45 ? hour : hour + 1, minute: (minute + 15) % 60 },
          name: names[nameIndex % names.length]
        })

        nameIndex++
      }
    }
  }

  return appointments
}

const gridStartHour = 8

function timeToGridRow(hour: number, minute: number): number {
  const totalHours = hour + minute / 60
  const rowIndex = (totalHours - gridStartHour) * 4
  return rowIndex + 1
}

interface IAppointmentProps {
  appointment: IAppointment
}

const AppointmentItem: React.FC<IAppointmentProps> = ({ appointment }) => {
  const time = `${appointment.start.hour.toString().padStart(2, '0')}:${appointment.start.minute
    .toString()
    .padStart(2, '0')}`

  return (
    <AppointmentWrap
      key={appointment.id}
      style={{
        // @ts-ignore
        gridColumn: appointment.day + 1,
        gridRowStart: timeToGridRow(appointment.start.hour, appointment.start.minute),
        gridRowEnd: timeToGridRow(appointment.end.hour, appointment.end.minute)
      }}
    >
      {time}
      <b>&nbsp;{appointment.name}</b>
    </AppointmentWrap>
  )
}

interface ICalendar {
  version: 'Before' | 'After'
}

export const Calendar: React.FC<ICalendar> = ({ version }) => {
  const appointments = generateAppointments(version)

  return (
    <Wrap>
      <Grid />
      <WorkWeek>
        {appointments.map(appointment => (
          <AppointmentItem key={appointment.id} appointment={appointment} />
        ))}
      </WorkWeek>
    </Wrap>
  )
}
