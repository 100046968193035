import { IUser, markTranslated, RequestMagicLink, TranslatedText, UntranslatedText } from 'core'
import React, { useCallback, useMemo, useState } from 'react'

import { View } from '~/components'
import { useOnLogin, useRequestMagicLink } from '~/hooks'
import { H2, H3, Text, toast, useBreakpoints, useStore, useTranslation } from '~/lite'

import { SignUpNote } from '../SignUpNote'
import { ResumeImportField, ResumeUploadState } from './ResumeImportField'
import {
  BottomErrorMessage,
  FormContainer,
  InlineErrorMessage,
  InputContainer,
  MagicLinkCard,
  SignUpNoteWrap,
  StyledButton,
  StyledInput,
  SubmitButton
} from './styles'
import { IFormProps } from './types'

export const Form: React.FC<IFormProps> = ({
  header,
  subheader,
  submitButtonText,
  onSuccess,
  visible,
  enableAnimation = true
}) => {
  const user = useStore(state => state.user)
  const onLogin = useOnLogin()
  const t = useTranslation()
  const { isPhone } = useBreakpoints()

  const [resumeUploadState, setResumeUploadState] = useState<ResumeUploadState | null>(null)
  const [enteredEmail, setEnteredEmail] = useState<string | null>(null)
  const [typoSuggestion, setTypoSuggestion] = useState<TranslatedText>()
  const [errorMessage, setErrorMessage] = useState<UntranslatedText>()
  const [authResult, setAuthResult] = useState<RequestMagicLink['response'] | null>(null)

  const email = useMemo(() => user?.email ?? enteredEmail, [user, enteredEmail])

  const { mutateAsync: requestMagicLink, isLoading: requestingMagicLink } = useRequestMagicLink({
    onSuccess: data => {
      setAuthResult(data)

      if (!data.success) {
        setErrorMessage(data.message)
        return
      }

      if (data.type === 'TypoSuggestion') {
        setTypoSuggestion(data.typoSuggestion)
        return
      }

      if (data.type === 'EmailConfirmationOnSignUp') {
        onLogin(true)
      }

      if (data.type === 'EmailConfirmation') {
        toast.info(['We sent a confirmation email to {{email}}', { email: markTranslated(data.email) }])
      } else if (data.type === 'MagicLink') {
        toast.info(['We sent a magic link to {{email}}', { email: markTranslated(data.email) }])
      } else {
        onSuccess?.(data)
      }
    }
  })

  const onJoinButtonPress = useCallback(async () => {
    if (!email) {
      return
    }

    requestMagicLink({ email: email as IUser['email'] })
  }, [email, requestMagicLink])

  return (
    <MagicLinkCard
      $visible={visible}
      $enableAnimation={enableAnimation}
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="m"
      textAlign="center"
    >
      {!!header && <H2 marginBottom="m" fontSize={28} fontWeight={600} text={header} />}
      {!!subheader && <H3 marginBottom="m" fontSize={16} fontWeight="normal" text={subheader} />}
      <FormContainer>
        <ResumeImportField onChange={setResumeUploadState} />
        {!user?.email && (
          <>
            <InputContainer>
              <StyledInput
                placeholder="Your email address"
                value={email ?? ''}
                disabled={!!user}
                keyboardType="email-address"
                onFocus={() => {
                  if (isPhone) {
                    window.scrollTo(0, 0)
                    document.body.scrollTop = 0
                  }
                }}
                onChange={value => {
                  if (!requestingMagicLink && (!authResult?.success || authResult.type === 'TypoSuggestion')) {
                    setEnteredEmail(value)
                    setErrorMessage(undefined)
                    setTypoSuggestion(undefined)
                  }
                }}
              />
              {errorMessage && <InlineErrorMessage marginTop="s" text={errorMessage} />}
            </InputContainer>
            {!typoSuggestion && (
              <SubmitButton
                text={submitButtonText ?? 'Join Free'}
                onPress={onJoinButtonPress}
                loading={requestingMagicLink}
                disabled={!email?.length || requestingMagicLink || resumeUploadState === 'Uploading'}
                primary={!!email?.length && resumeUploadState !== 'Uploading'}
                skipTracking
              />
            )}
          </>
        )}
      </FormContainer>
      {errorMessage && <BottomErrorMessage marginTop="s" text={errorMessage} />}
      {!!typoSuggestion && (
        <View display="flex" flexDirection="column" alignItems="stretch" marginTop="s">
          <Text rawText={t('Did you mean {{suggestedFix}}?', { suggestedFix: typoSuggestion })} />
          <View display="flex" flexDirection="row" marginTop="s">
            <StyledButton
              text="Yes"
              onPress={() => {
                setEnteredEmail(typoSuggestion)
                setTypoSuggestion(undefined)
                requestMagicLink({ email: typoSuggestion as IUser['email'] })
              }}
              loading={requestingMagicLink}
              disabled={requestingMagicLink || !email?.length}
              flex={1}
              marginRight="s"
              primary
              small
              skipTracking
            />
            <StyledButton
              text="No"
              onPress={() => {
                setTypoSuggestion(undefined)
                requestMagicLink({ email: email as IUser['email'], disableTypoCheck: true })
              }}
              loading={requestingMagicLink}
              disabled={requestingMagicLink || !email?.length}
              flex={1}
              small
              skipTracking
            />
          </View>
        </View>
      )}
      <SignUpNoteWrap>
        <SignUpNote style={{ fontSize: 11 }} />
      </SignUpNoteWrap>
    </MagicLinkCard>
  )
}
