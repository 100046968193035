import { UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'

/*
@todo figure out how to load these without circular dep
import { Loader } from '~/components/common/Loader/Loader'
import { NotAuthorized } from '~/components/common/NotAuthorized/NotAuthorized'
import { NotFound } from '~/components/common/NotFound/NotFound'
*/

const Loader = () => null
const NotAuthorized = () => null
const NotFound = () => null
export interface IUseQueryWithNonIdealStateOptions<T extends UseQueryResult<unknown, unknown>> {
  isAuthorized?(result: T): boolean
  enabled?: boolean
}

// typescript doesn't support destructuring with disjoint unions (https://github.com/microsoft/TypeScript/issues/23613)
// so we need to use: const result = useFooRecord(id)
// instead of: const { data, NonIdealState } = useFooRecord(id)

type IdealResult<T extends UseQueryResult<unknown, unknown>> = Exclude<T, 'data'> & {
  NonIdealState: null
  data: NonNullable<T['data']>
}

type NonIdealResult<T extends UseQueryResult<unknown, unknown>> = T & {
  NonIdealState: React.ComponentType
}

export const useQueryWithNonIdealState = <T extends UseQueryResult<unknown, unknown>>(
  result: T,
  options?: IUseQueryWithNonIdealStateOptions<T>
): IdealResult<T> | NonIdealResult<T> =>
  useMemo(() => {
    if (options?.enabled !== false) {
      if (result.isLoading) {
        return { ...result, NonIdealState: Loader }
      }

      if (!result.data) {
        return { ...result, NonIdealState: NotFound }
      }

      if (options?.isAuthorized && !options.isAuthorized(result)) {
        return { ...result, NonIdealState: NotAuthorized }
      }
    }

    return { ...result, NonIdealState: null } as IdealResult<T>
  }, [result])
