import { PositionFilterFormProps } from '@ambition/module-form'
import { CollectionSlug, IPositionQuery, UntranslatedText } from 'core'
import React, { useEffect, useMemo, useState } from 'react'

import { Button, Checkbox, FlexHorizontal, Menu, Modal, View } from '~/components'
import { useBreakpoints, useStore, useTranslation } from '~/hooks'
import { getGeolocation } from '~/util'

interface ISortOption {
  value: NonNullable<IPositionQuery['sortBy']>
  label: UntranslatedText
  testID?: string
}

const sortOptions: ISortOption[] = [
  { value: 'date', label: 'Sort by Date', testID: 'sort-by-date' },
  { value: 'distance', label: 'Sort by Distance', testID: 'sort-by-distance' },
  { value: 'popularity', label: 'Sort by Popularity', testID: 'sort-by-popularity' }
]

interface ICollectionToolbarProps {
  query: IPositionQuery
  onChange(query: IPositionQuery): void
  collectionSlug: CollectionSlug
  renderPositionFilter: (props: PositionFilterFormProps) => void
}

export const CollectionToolbar: React.FC<ICollectionToolbarProps> = ({
  query,
  onChange,
  renderPositionFilter,
  collectionSlug
}) => {
  const t = useTranslation()
  const { isPhone } = useBreakpoints()
  const theme = useStore(state => state.theme)
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)

  const translatedOptions = useMemo(() => sortOptions.map(option => ({ ...option, label: t(option.label) })), [t])

  const sortBy = useMemo(
    () => translatedOptions.find(option => option.value === query.sortBy) ?? translatedOptions[0],
    [translatedOptions, query]
  )

  const [remote, setRemote] = useState(() => query.remote || false)

  useEffect(() => {
    if (query.withLocation && !query.latLng) {
      getGeolocation().then(location => {
        if (location) {
          onChange({ ...query, latLng: [location.coords.latitude, location.coords.longitude] })
        }
      })
    }
  }, [query])

  const onItemSelect = ({ value }: typeof translatedOptions[number]): void => {
    const withGeolocation =
      value === 'distance' ? { withLocation: true } : { withLocation: undefined, latLng: undefined }

    onChange({ ...query, ...withGeolocation, sortBy: value })
  }

  const onApplyFilters = () => {
    setIsFilterModalVisible(false)
  }

  return (
    <View>
      <FlexHorizontal
        alignItems="center"
        justifyContent="space-between"
        paddingX="s"
        paddingY="s"
        borderBottomWidth={1}
        borderBottomColor={theme.border}
      >
        <FlexHorizontal flex={1} width="100%" justifyContent="space-between">
          {isPhone ? (
            <View width={100} height={50} justifyContent="center" alignItems="center">
              <Button
                action="OpenMobileCollectionFilterModal"
                icon="funnel"
                onPress={() => setIsFilterModalVisible(true)}
                text="Filters"
              />
            </View>
          ) : null}
          <Menu
            items={translatedOptions.map(option => ({ rawText: option.label, onPress: () => onItemSelect(option) }))}
          >
            <Button icon="swap-vertical" rawText={sortBy.label} skipTracking />
          </Menu>
          <Checkbox
            key="remote"
            label="Only Remote"
            checked={remote}
            onChange={checked => {
              setRemote(checked)
              onChange({ ...query, remote: checked })
            }}
          />
        </FlexHorizontal>
      </FlexHorizontal>
      <Modal isOpen={isFilterModalVisible} onClose={() => setIsFilterModalVisible(false)}>
        <>
          {renderPositionFilter({
            onApply: onApplyFilters,
            containerProps: { height: '100%', hasBackground: false },
            collectionSlug: collectionSlug
          })}
        </>
      </Modal>
    </View>
  )
}
/*
      <Select<typeof translatedOptions[number]>
        testID="position-sort-toolbar"
        placeholder={t('Sort By')}
        isSearchable={false}
        options={translatedOptions}
        value={sortBy}
        onChange={option => option && onItemSelect(option)}
      />
      */
