import { isServer } from './env'
import { ILocalize } from './types'

export const localize: ILocalize = {
  getCountry: () => {
    if (isServer) {
      return null
    }

    const languages = navigator.languages ?? [navigator.language]

    for (const language of languages) {
      const [, countryCode] = language.split('-')

      if (countryCode && countryCode !== '419' && countryCode.toLowerCase() !== 'un') {
        return countryCode.toUpperCase()
      }
    }

    return null
  },
  getLocaleCodes: () => {
    if (isServer) {
      return []
    }

    return navigator.languages ? [...navigator.languages] : [navigator.language]
  }
}
