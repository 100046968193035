import { IChannel, IMessage, NewChannel } from 'core'
import React, { useMemo } from 'react'

import { KeyboardAvoidingView } from '~/components'
import { useBottomTabBarHeight, useStore } from '~/hooks'
import { isWeb, Pressable, useBreakpoints } from '~/lite'
import { hideKeyboard } from '~/util'

import { ChannelToolbar } from './ChannelToolbar'
import { MessageList } from './MessageList'
import { ChannelWrap } from './styles'
import { useMessaging } from './useMessaging'

export interface IChannelProps {
  channelId?: IChannel['id']
  newChannel?: NewChannel
  assistant?: boolean
  onSendMessage?(): void
  onInputFocus?(messages: IMessage[]): void
  autoFocusInput?: boolean
}

export const Channel: React.FC<IChannelProps> = props => {
  const currentUserId = useStore(state => state.currentUserId)
  const { isPhone } = useBreakpoints()
  const tabBarHeight = useBottomTabBarHeight()

  const { onInputFocus, autoFocusInput } = props

  const { assistant, handleSendMessage, isLoading, messages, pusherChannel, sendingDisabled, typingUserIds } =
    useMessaging(props)

  const handleInputFocus = useMemo(
    () => (onInputFocus ? () => onInputFocus(messages) : undefined),
    [onInputFocus, messages]
  )

  const body = (
    <ChannelWrap>
      <MessageList
        messages={messages}
        isLoading={isLoading}
        hideTime={assistant}
        typingUserIds={typingUserIds}
        style={{
          height: '100%',
          paddingBottom: isWeb || !isPhone ? 0 : tabBarHeight,
          overflow: 'scroll',
          paddingTop: 8
        }}
      />
      <ChannelToolbar
        handleSendMessage={handleSendMessage}
        userId={currentUserId}
        pusherChannel={pusherChannel.current}
        sendTypingIndicator={!assistant}
        sendingDisabled={sendingDisabled}
        autoFocus={autoFocusInput}
        onFocus={handleInputFocus}
        marginBottom={isWeb || !isPhone ? 0 : tabBarHeight}
      />
    </ChannelWrap>
  )

  if (isWeb) {
    return body
  }

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <Pressable onPress={hideKeyboard}>{body}</Pressable>
    </KeyboardAvoidingView>
  )
}

/*
  const headerHeight = useHeaderHeight()
  const scrollView = useRef<any>(null)
  const shouldUseDesktopView = isWeb && !isPhone

  const onDrop = useCallback(acceptedFiles => {
    if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
      // channelProxy?.sendMessage({ contentType: acceptedFiles[0].type, media: acceptedFiles[0] })
    }
  }, [])
  testID="channel-container"
  {...(shouldUseDesktopView ? { ref: scrollView } : {})}
  onContentSizeChange={() => shouldUseDesktopView && scrollView?.current.scrollToEnd({ animated: true })}
  const getOffset = useCallback((height: number): number => (isIos ? height : 0) - tabBarHeight, [tabBarHeight])
  */
