import { keyframes } from '../styled'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeInUpSubtle = keyframes`
  from {
    opacity: 0;
    transform: translateY(4px) scale(.97);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

const fadeInUpWithScale = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0) scale(0.8);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const fadeOutUp = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`

const maximizeSubtle = keyframes`
  from {
    opacity: 0.6;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const minimizeSubtle = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.6;
    transform: scale(0.9);
  }
`

const minimize = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.5;
    transform: scale(0.8);
  }
`

const minimizeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.8);
  }
`

const skeletonLoadingLight = keyframes`
  from {
    background-color: #f9fafb;
  }
  to {
    background-color: #ecf0f6;
  }
`

const skeletonLoadingDark = keyframes`
  from {
    background-color: #333539;
  }
  to {
    background-color: #4b4d53;
  }
`

const slideInUp = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

const slideOutDown = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
`

const popIn = keyframes`
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

const popInUpSubtle = keyframes`
  0% {
    opacity: 0;
    transform: translateY(4px) scale(0.8);
  }

  70% {
    opacity: 1;
    transform: translateY(0) scale(1.05);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`

export const animations = {
  fadeIn,
  fadeInDown,
  fadeInUp,
  fadeInUpWithScale,
  fadeInUpSubtle,
  fadeOut,
  fadeOutUp,
  maximizeSubtle,
  minimizeSubtle,
  minimizeOut,
  minimize,
  popIn,
  popInUpSubtle,
  skeletonLoadingLight,
  skeletonLoadingDark,
  slideInUp,
  slideOutDown
}
