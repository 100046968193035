import { useQueryClient } from '@tanstack/react-query'
import { CreateTeam, encodeToken, INewTeam, ITeam, TranslatedText, validateNewTeam } from 'core'
import { kebabCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, FormGroup, HeaderButton, Input, IPanelProps, Panel } from '~/components'
import {
  useCreateTeam,
  useForm,
  useHeaderHeight,
  useHistory,
  useInvitations,
  useStore,
  useTeamByUsername,
  useTeams,
  useTranslation,
  useUpdateTeam
} from '~/hooks'
import { DialogFooter, styled, Text, toast, useLayoutEffectOnNative, useLoadUserData, View } from '~/lite'
import { emptyString, isWeb } from '~/util'

const GeneralError = styled(View)`
  margin-bottom: 15px;
  background-color: rgba(219 55 55 / 15%);
  padding: 10px 12px 9px;
`

const EditEmailWrap = styled(View)`
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const EmailPrefixInput = styled(Input)`
  flex: 1;
  text-align: end;
  font-size: 16px;
  font-weight: bold;
`

const EmailSuffixText = styled(Text)`
  flex: 1;
  text-align: start;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
`

export interface ITeamFormProps {
  isCreating: boolean
  team?: INewTeam
  onChange?(data: INewTeam): void
  onClose?(): void
  onSubmit?(): Promise<void>
  skipRedirect?: boolean
  username?: ITeam['username']
  panelProps?: IPanelProps
  isColdEmail?: boolean
}

export const TeamForm: React.FC<ITeamFormProps> = ({
  isCreating,
  team,
  onChange,
  onClose,
  onSubmit,
  skipRedirect,
  username,
  panelProps,
  isColdEmail
}) => {
  const history = useHistory()
  const t = useTranslation()
  const queryClient = useQueryClient()
  const headerHeight = useHeaderHeight()
  const loadUserData = useLoadUserData()
  const user = useStore(state => state.user)
  const setCurrentTeam = useStore(state => state.setCurrentTeam)
  const { refetch: refetchTeams } = useTeams(user?.id)
  const { refetch: refetchInvitations } = useInvitations()
  const { refetch: refetchTeamByUserName } = useTeamByUsername(username)

  const { mutateAsync: createTeam } = useCreateTeam()
  const { mutateAsync: updateTeam } = useUpdateTeam()

  const [showLoading, setShowLoading] = useState(false)

  const doOnSubmit = useCallback<(response: CreateTeam['success']) => void>(
    async ({ team, success }) => {
      refetchTeams()
      loadUserData()
      refetchInvitations()
      refetchTeamByUserName()
      setCurrentTeam(team)

      if (success && onSubmit) {
        setShowLoading(true)
        await onSubmit()
        setShowLoading(false)
      }

      onClose?.()

      if (!isWeb) {
        if (!skipRedirect) {
          history.goBack()
        }
      } else {
        if (success) {
          if (!skipRedirect) {
            history.push(`/dashboard/teams/${encodeToken(team.id)}`)
          }
          toast.success(isCreating ? 'Your team has been created' : 'Your team has been updated')
        } else {
          toast.failure('An error has occurred')
        }
      }
    },
    [
      history,
      isCreating,
      loadUserData,
      onClose,
      onSubmit,
      skipRedirect,
      refetchInvitations,
      refetchTeamByUserName,
      refetchTeams,
      setCurrentTeam
    ]
  )

  const [emailSuffix, setEmailSuffix] = useState(team ? `@${team.username}.remoteambition.com` : '')
  useEffect(() => {
    setEmailSuffix(team ? `@${team.username}.remoteambition.com` : '')
  }, [team])

  const emailPrefix = useMemo(() => {
    return team?.screenerEmail?.split('@').shift() || ''
  }, [team])

  const onSubmitData = useCallback(
    async (data: INewTeam) => {
      let response
      if (isCreating) {
        response = await createTeam({ isColdEmail, ...data })
      } else {
        const emailSuffix = `@${data.username}.remoteambition.com`
        response = await updateTeam({
          ...data,
          screenerEmail: data.screenerEmail ? `${data.screenerEmail}${emailSuffix}` : null
        } as any)
        if (response.success) {
          queryClient.invalidateQueries(['teams', response.team.id])
        }
      }

      return response
    },
    [createTeam, isCreating, isColdEmail, queryClient, updateTeam]
  )

  const { data, errors, updateField, submitProps } = useForm<INewTeam, CreateTeam>(
    team
      ? { ...team, screenerEmail: emailPrefix }
      : {
          email: '' as INewTeam['email'],
          username: '' as INewTeam['username'],
          name: emptyString,
          screenerEmail: emptyString,
          website: emptyString,
          logo: null,
          location: emptyString,
          about: emptyString,
          statuses: []
        },
    validateNewTeam,
    onSubmitData,
    doOnSubmit
  )

  const isLoading = submitProps.loading || showLoading

  const newTeamScreenOptions = useMemo(
    () =>
      ({
        headerTitleAlign: 'center',
        headerLeft: () => (
          <HeaderButton action="CancelNewTeam" primary text="Cancel" large onPress={() => history.goBack()} />
        ),
        headerRight: () =>
          !isWeb && (
            <HeaderButton action="CreateTeam" {...submitProps} primary text={isCreating ? 'Create' : 'Done'} large />
          )
      } as any),
    [history, isCreating, submitProps]
  )

  useLayoutEffectOnNative(() => {
    history.setOptions({ ...newTeamScreenOptions })
  }, [history, newTeamScreenOptions])

  useEffect(() => {
    if (onChange) {
      onChange(data)
    }
  }, [onChange, data])

  const groupStyle = isWeb ? { marginBottom: 0, marginTop: 8 } : undefined

  const form = (
    <>
      {isCreating && !user && (
        <FormGroup label="Your email" labelFor="email-input" helperText={errors.email} style={groupStyle}>
          <Input
            testID="email-input"
            id="email-input"
            value={data.email ?? emptyString}
            onChange={value => updateField('email', value)}
            keyboardType="email-address"
          />
        </FormGroup>
      )}
      <FormGroup label="Company name" labelFor="name-input" helperText={errors.name} style={groupStyle}>
        <Input
          testID="team-name-input"
          id="name-input"
          value={data.name ?? emptyString}
          onChange={value => {
            updateField('name', value)

            const username = kebabCase(value)
            updateField('username', username as ITeam['username'])
            setEmailSuffix(`@${username}.remoteambition.com`)
          }}
        />
      </FormGroup>
      {!isCreating && (
        <>
          <FormGroup
            label="Username"
            labelFor="username-input"
            helperText={errors.username ?? `remoteambition.com/${data.username || t('yourteam')}`}
            style={groupStyle}
          >
            <Input
              testID="team-username-input"
              id="username-input"
              value={data.username || ''}
              autoCapitalize="none"
              onChange={value => {
                updateField('username', value as INewTeam['username'])
                setEmailSuffix(`@${value}.remoteambition.com`)
              }}
            />
          </FormGroup>
          <FormGroup label="Email" labelFor="screener-email-input" helperText={errors.location} style={groupStyle}>
            <EditEmailWrap>
              <EmailPrefixInput
                testID="team-screener-email-input"
                id="screener-email-input"
                value={data.screenerEmail || emptyString}
                onChange={value => updateField('screenerEmail', value)}
              />
              <EmailSuffixText rawText={emailSuffix as TranslatedText} />
            </EditEmailWrap>
          </FormGroup>
        </>
      )}
      <FormGroup label="Location" labelFor="location-input" helperText={errors.location} style={groupStyle}>
        <Input
          testID="team-location-input"
          id="location-input"
          value={data.location || emptyString}
          onChange={value => updateField('location', value)}
        />
      </FormGroup>
      <FormGroup label="Website" labelFor="website-input" helperText={errors.website} style={groupStyle}>
        <Input
          testID="team-website-input"
          id="website-input"
          autoCapitalize="none"
          value={data.website || emptyString}
          onChange={value => updateField('website', value)}
        />
      </FormGroup>
      {!isCreating && (
        <FormGroup label="About" labelFor="about-input" helperText={errors.about} style={groupStyle}>
          <Input
            testID="team-about-input"
            id="about-input"
            type="textarea"
            width="100%"
            value={data.about || emptyString}
            numberOfLines={3}
            onChange={(value: TranslatedText) => updateField('about', value)}
          />
        </FormGroup>
      )}
    </>
  )

  if (isWeb) {
    return (
      <>
        {errors?.general && (
          <GeneralError>
            <Text text={errors.general} />
          </GeneralError>
        )}
        {form}
        <DialogFooter>
          <Button text="Cancel" onPress={() => onClose?.()} disabled={isLoading} minimal skipTracking />
          <Button
            text={isCreating ? 'Create Team' : 'Save'}
            action={isCreating ? 'CreateTeam' : 'UpdateTeam'}
            primary
            {...submitProps}
            loading={isLoading}
          />
        </DialogFooter>
      </>
    )
  }

  return (
    <Panel errors={errors} testID="team-form" style={{ paddingTop: headerHeight }} {...panelProps}>
      {form}
    </Panel>
  )
}
