import React from 'react'

import { usePipelineContent } from '~/hooks'
import { Button, Icon, Loader, styled, Text, View } from '~/lite'

const Wrap = styled(View)`
  min-height: calc(100vh - 60px);
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  background: ${props => (props.theme.dark ? props.theme.background : props.theme.backgroundContrast)};
`

const Inner = styled(View)`
  width: 100%;
  max-width: 620px;
  margin: 16px 0;
  padding: 32px;
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 0 0 1px ${props => props.theme.border};
`

const Header = styled(Text)`
  font-size: 26px;
`

const PerksHeader = styled(Text)`
  font-size: 26px;
`

const Subheader = styled(Text)`
  margin-top: 4px;
  margin-bottom: 16px;
  opacity: 0.8;
  hyphens: none;
`

const Steps = styled(View)`
  margin: 8px 0;
  gap: 16px;
`

const Step = styled(View)`
  flex-direction: row;
`

const StepIcon = styled(Icon)`
  margin: -2px 8px 0 0;
`

const StepTextWrap = styled(View)`
  flex: 1;
  display: block;
`

const BoldStepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: bold;
`

const StepText = styled(Text)`
  display: inline;
  text-align: left;
  hyphens: none;
  font-weight: normal;
  opacity: 0.8;
`
const paymentUrl = 'https://buy.stripe.com/bIY5mi7O361m1aM5kr'

const exclusivePaymentUrl = 'https://buy.stripe.com/4gw7uq5FV4XicTufZ6'

export const Pipeline: React.FC = () => {
  const { data, isLoading } = usePipelineContent()

  if (isLoading) {
    return (
      <Wrap>
        <Loader />
      </Wrap>
    )
  }

  if (!data) {
    return null
  }

  const { preorder } = data.data

  return (
    <Wrap>
      <Inner>
        <View flexDirection="row" alignItems="start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="#88add2" d="m16 3 4 4-4 4" />
            <path stroke="#88add2" d="M20 7H4" />
            <path stroke="#88add2" d="m8 21-4-4 4-4" />
            <path stroke="#88add2" d="M4 17h16" />
          </svg>
          <Header marginLeft={8} fontSize={28} text="Talent Pipeline" />
        </View>
        <Subheader rawText={preorder.subheader} />
        <Steps>
          {preorder.howItWorks.map((step, idx) => (
            <Step key={idx}>
              <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
              <StepTextWrap>
                <BoldStepText rawText={step.header} /> {!!step.subheader && <StepText rawText={step.subheader} />}
              </StepTextWrap>
            </Step>
          ))}
        </Steps>
        <View marginTop={32} flexDirection="row" alignItems="center">
          <Text fontSize={26} rawText={'🎁' as any} />
          <PerksHeader marginLeft={8} marginBottom={8} rawText={preorder.perksHeader} />
        </View>
        <Subheader rawText={preorder.perksSubheader} />
        <Steps>
          {preorder.perks.map((perk, idx) => (
            <Step key={idx}>
              <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
              <StepTextWrap>
                <BoldStepText rawText={perk.header} /> {!!perk.subheader && <StepText rawText={perk.subheader} />}
              </StepTextWrap>
            </Step>
          ))}
        </Steps>
        <View flexDirection="row" marginTop={32} justifyContent="center">
          <Button
            action="PreorderPipeline"
            rawText={preorder.cta}
            onPress={() => window.open(paymentUrl, '_blank')}
            primary
            large
          />
        </View>
        <View marginTop={32} flexDirection="row" alignItems="center">
          <Text fontSize={26} rawText={'💎' as any} />
          <PerksHeader marginLeft={8} marginBottom={8} rawText={preorder.exclusiveHeader} />
        </View>
        <Subheader rawText={preorder.exclusiveSubheader} />
        <Steps>
          {preorder.exclusivePerks.map((perk, idx) => (
            <Step key={idx}>
              <StepIcon icon="checkmark-outline" color="#6360ff" size={24} />
              <StepTextWrap>
                <BoldStepText rawText={perk.header} /> {!!perk.subheader && <StepText rawText={perk.subheader} />}
              </StepTextWrap>
            </Step>
          ))}
        </Steps>
        <View flexDirection="row" marginTop={32} justifyContent="center">
          <Button
            action="PreorderExclusivePipeline"
            rawText={preorder.exclusiveCta}
            onPress={() => window.open(exclusivePaymentUrl, '_blank')}
            primary
            large
          />
        </View>
      </Inner>
    </Wrap>
  )
}
