import {
  ClientRoutePath,
  compactAndJoin,
  CompensationFrequency,
  ExperienceLevel,
  ILocation,
  IPosition,
  IPositionPartialDetail,
  localeSitePrefixes,
  OpenGraphVariant,
  TranslatedText
} from 'core'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { helmetJsonLdProp } from 'react-schemaorg'
import type { JobPosting, MonetaryAmount } from 'schema-dts'

import { constants } from './constants'
import { appEnv } from './env'
import { useStore, useTranslation } from './hooks'
import { pageTitles } from './routes'
import type { IMetaTagsProps } from './types'
import { encodeToken, getTeamLogo, util } from './util'

export const MetaTags: React.FC<IMetaTagsProps> = props => {
  const t = useTranslation()
  const theme = useStore(state => state.theme)
  const source = useStore(state => state.source)

  const info: {
    url?: string
    title: TranslatedText
    description: TranslatedText
    image?: string
    canonical?: string
    localizedPath?: ClientRoutePath
    schema?: ReturnType<typeof helmetJsonLdProp>
  } = {
    url: undefined,
    title: t('RemoteAmbition - Job Search'),
    description: t(
      'RemoteAmbition is the best way to discover a job that you will love. With RemoteAmbition, you will find teams that value your skills, and jobs that feel personalized just for you. We make it easy for you to get hired for your next job by streamlining everything from start to finish.'
    ),
    image: undefined,
    canonical: undefined,
    schema: undefined
  }

  const id = {
    logo: `${appEnv.frontendUrl}/#/schema/logo/image/`,
    organization: `${appEnv.frontendUrl}/#organization`,
    website: `${appEnv.frontendUrl}/#website`
  }

  if (props.type === 'InstantSdk') {
    info.title = 'InstantSDK' as TranslatedText
    info.description = '' as TranslatedText
  }

  if (props.type === 'Weebin') {
    info.title = 'Weebin' as TranslatedText
    info.description = '' as TranslatedText
  }

  if (props.type === 'General' || props.type === 'Home') {
    info.schema = helmetJsonLdProp({
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          '@id': id.organization,
          name: 'RemoteAmbition',
          url: `${appEnv.frontendUrl}/`,
          logo: {
            '@type': 'ImageObject',
            '@id': id.logo,
            url: 'https://ambitioncdn.com/logo.png'
          },
          image: { '@id': id.logo },
          sameAs: ['https://www.facebook.com/remoteambition', 'https://twitter.com/remoteambition']
        },
        {
          '@type': 'WebSite',
          '@id': id.website,
          url: `${appEnv.frontendUrl}/`,
          name: 'RemoteAmbition',
          description: info.description,
          publisher: { '@id': id.organization }
        }
      ]
    })
  }

  if (props.type === 'Collection') {
    info.title = compactAndJoin([props.headerText, 'RemoteAmbition' as TranslatedText], ' - ')
  }

  if (props.type === 'LandingPage') {
    info.title = compactAndJoin([props.headerText, 'RemoteAmbition' as TranslatedText], ' - ')
  }

  if (props.type === 'Position') {
    const { position, team, location } = props

    const url = `${appEnv.frontendUrl}/jobs/${encodeToken(position.id)}`
    info.canonical = url
    info.url = url

    info.title = compactAndJoin(
      [
        position.title && team?.name
          ? t(pageTitles['/jobs/Position'], { jobTitle: position.title, companyName: team.name })
          : compactAndJoin([position.title, team?.name], ' - '),
        'RemoteAmbition' as TranslatedText
      ],
      ' - '
    )

    info.description = (position as IPositionPartialDetail).teamDetail.bio || position.summary || info.description
    info.image = `${constants.assetServerUrl}/opengraph/images/positions/${position.id}`

    if (source === 'chatgpt') {
      const variant: OpenGraphVariant = 'chatgpt'
      info.image += `/${variant}`
    }

    const experienceLevels: Record<ExperienceLevel, string> = {
      Experienced: 'Experienced',
      HighSchoolStudent: 'Student (High School)',
      CollegeStudent: 'Student (College)',
      EntryLevel: 'Entry Level',
      MidLevel: 'Mid Level',
      Manager: 'Manager/Supervisor',
      SeniorManager: 'Senior Manager/Supervisor',
      Executive: 'Executive',
      SeniorExecutive: 'Senior Executive'
    }

    let applicantLocationRequirements: JobPosting['applicantLocationRequirements']

    if (position.applicantCountry) {
      applicantLocationRequirements = { '@type': 'Country', name: position.applicantCountry }
    }

    // remote positions require a country, so fall back to position's country if no applicant country is set
    if (!position.applicantCountry && position.allowRemote && location?.country?.code) {
      applicantLocationRequirements = { '@type': 'Country', name: location.country.code }
    }

    const compensationFrequencies: Record<CompensationFrequency, string> = {
      Hour: 'HOUR',
      Day: 'DAY',
      Week: 'WEEK',
      Month: 'MONTH',
      Year: 'YEAR'
    }

    let baseSalary: MonetaryAmount | null = null
    const salaryIsEstimate = false

    const compensation = position.compensation ?? position.employeeCompensation ?? position.contractCompensation

    if (compensation) {
      const { min, max, currency, frequency, type } = compensation

      if (typeof min === 'number' || typeof max === 'number') {
        // salaryIsEstimate = type === 'Estimate'
        baseSalary = {
          '@type': 'MonetaryAmount',
          ...(currency && { currency }),
          value: {
            '@type': 'QuantitativeValue',
            ...(typeof min === 'number' && min !== max && { minValue: min }),
            ...(typeof max === 'number' && min !== max && { maxValue: max }),
            ...(typeof max === 'number' && min === max && { value: max }),
            ...(frequency && { unitText: compensationFrequencies[frequency] })
          }
        }
      }
    }

    const formatLocation = (location: ILocation): JobPosting['jobLocation'] => {
      const { streetAddress, city, level1, postalCode, country, latLng } = location

      if (!streetAddress && !city && !level1 && !postalCode && !country) {
        return undefined
      }

      return {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          ...(streetAddress && { streetAddress }),
          ...(city && { addressLocality: city }),
          ...(level1 && { addressRegion: level1 }),
          ...(postalCode && { postalCode }),
          ...(country?.code && { addressCountry: country.code })
        },
        ...(latLng ? { geo: { '@type': 'GeoCoordinates', latitude: latLng[0], longitude: latLng[1] } } : {})
      }
    }

    const formatRemoteLocation = (location: NonNullable<IPosition['remoteLocation']>): JobPosting['jobLocation'] => {
      const { streetAddress, locality, region, postalCode, country, postOfficeBoxNumber } = location

      if (!streetAddress && !locality && !region && !postalCode && !country && !postOfficeBoxNumber) {
        return undefined
      }

      return {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          ...(streetAddress && { streetAddress }),
          ...(locality && { addressLocality: locality }),
          ...(region && { addressRegion: region }),
          ...(postalCode && { postalCode }),
          ...(country && { addressCountry: country }),
          ...(postOfficeBoxNumber && { postOfficeBoxNumber })
        }
      }
    }

    // @todo temporarily forwarding remoteLocation to schema until we geocode it
    const jobLocation = location
      ? formatLocation(location)
      : position.remoteLocation
      ? formatRemoteLocation(position.remoteLocation)
      : undefined

    const today = new Date()
    const afterToday = new Date(new Date().setDate(today.getDate() + 90))
    const afterPostedAt = position.postedAt ? new Date(new Date().setDate(position.postedAt.getDate() + 90)) : null

    const validThrough =
      util.formatDate(position.remoteExpiresAt) ??
      util.formatDate(position.deletedAt) ??
      (afterPostedAt && afterPostedAt > today ? util.formatDate(afterPostedAt) : null) ??
      util.formatDate(afterToday)

    // if it's been deleted, don't even include the schema since it may be missing required data like the description,
    // throwing errors in Google Search Console
    if (!position.deletedAt) {
      info.schema = helmetJsonLdProp<JobPosting>({
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: position.title,
        description: `${position.description ?? position.summary ?? ''}${
          position.postedAt
            ? `<p>${t('Last updated on {{date}}', {
                date: t.formatDate(position.postedAt, { dateStyle: 'medium' })
              })}</p>`
            : ''
        }`,
        datePosted: util.formatDate(position.postedAt),
        ...(validThrough && { validThrough }),
        ...('occupationCode' in position &&
          position.occupationCode && { occupationalCategory: position.occupationCode }),
        ...('industry' in position && position.industry && { industry: position.industry.englishName }),
        ...(position.experienceLevel && { experienceRequirements: experienceLevels[position.experienceLevel] }),
        employmentType: position.allowContract ? 'CONTRACTOR' : 'FULL_TIME',
        ...(team && {
          hiringOrganization: {
            '@type': 'Organization',
            name: team.name,
            ...(team.website && { sameAs: team.website }),
            ...(team.website && { url: team.website }),
            ...(!!getTeamLogo(team) && { logo: getTeamLogo(team) ?? '' })
          }
        }),
        ...(applicantLocationRequirements ? { applicantLocationRequirements } : {}),
        ...('hasForm' in position && position.hasForm ? { directApply: true } : {}),
        // Google requires at least 1 country if remote, otherwise the listing is invalid.
        // If we don't know it, don't mark it as remote, so it remains valid.
        ...(position.allowRemote && applicantLocationRequirements ? { jobLocationType: 'TELECOMMUTE' } : {}),
        ...(baseSalary ? (salaryIsEstimate ? { estimatedSalary: baseSalary } : { baseSalary }) : {}),
        ...(jobLocation ? { jobLocation } : {})
      })
    }
  }

  if (props.type === 'Content') {
    info.title = props.title
    info.description = props.description
    info.localizedPath = props.localizedPath
  }

  if (props.type === 'Home') {
    info.localizedPath = '/'
  }

  const { url, title, description, image, canonical, localizedPath, schema } = info

  // @todo og:url for non-position pages
  return (
    <Helmet script={schema ? [schema] : []}>
      <meta name="theme-color" content={theme.cardBackground} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@remoteambition" />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta name="twitter:image:alt" content={title} />}
      {url && <meta property="og:url" content={url} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {typeof localizedPath === 'string' &&
        Object.entries(localeSitePrefixes).map(([locale, prefix]) => (
          <link
            key={prefix}
            rel="alternate"
            hrefLang={locale}
            href={`${appEnv.frontendUrl}${prefix ? `/${prefix}` : ''}${localizedPath}`}
          />
        ))}
    </Helmet>
  )
}
