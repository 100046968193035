import React from 'react'

import { BREAKPOINT_WIDTH, styled, View } from '~/lite'

import { IProductProps, Product } from './Product'
import { getProducts } from './shared'
import { Section, SectionContent, SectionHeader, SectionSubheader } from './styles'

const Carousel = styled(View)`
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${BREAKPOINT_WIDTH.MD - 1}px) {
    position: relative;
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    white-space: nowrap;
    scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

const CarouselInner = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINT_WIDTH.MD - 1}px) {
    flex-wrap: nowrap;
    white-space: normal;
    justify-content: start;
    margin: 0 auto;
    padding: 16px 0;
  }

  @media (min-width: ${BREAKPOINT_WIDTH.MD}px) {
    gap: 24px;
    padding: 64px 0;
  }
`

const ProductWrap = styled(View)`
  scroll-snap-coordinate: left;
  scroll-snap-align: start;
  max-width: 80%;
  padding-left: 8px;

  @media (max-width: ${BREAKPOINT_WIDTH.MD - 1}px) {
    flex: 1;
  }
`

type IProductsProps = Omit<IProductProps, 'product'>

export const Products: React.FC<IProductsProps> = props => {
  if (!props.profile.metadata.products) {
    return null
  }

  const { header, subheader } = props.profile.metadata.products

  return (
    <Section id="products">
      <SectionContent $width="full">
        {!!header && <SectionHeader rawText={header} />}
        {!!subheader && <SectionSubheader rawText={subheader} />}
        <Carousel>
          <CarouselInner>
            {getProducts(props.profile).map((product, idx) => (
              <ProductWrap key={idx}>
                <Product {...props} product={product} />
              </ProductWrap>
            ))}
          </CarouselInner>
        </Carousel>
      </SectionContent>
    </Section>
  )
}
