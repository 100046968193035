import { hoverArrow } from 'core'
import React, { useCallback } from 'react'

import { styled, Text, useHistory, View } from '~/lite'

import { IResumeImportProps, ResumeImport } from './MagicLink/ResumeImport'

const JobCardWrap = styled(View)`
  position: relative;
  background: ${props => props.theme.cardBackground};
  box-shadow: inset 0 1px 1px rgb(255, 255, 255, 0.08), rgb(0 0 0 / 8%) 0px 4px 16px;
  margin: 0 auto 16px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  transition: background 150ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    cursor: pointer;
    background: var(--background-contrast);
  }

  .job-card {
    position: static;
    padding: 16px;
    background: none;
  }

  .job-card:hover {
    background: none !important;
  }

  .job-card:hover .job-card-title {
    color: var(--text) !important;
  }
`

interface IJobInterstitialProps {
  jobCard: React.ReactNode
  onDismiss(): void
}

export const JobInterstitial: React.FC<IJobInterstitialProps> = ({ jobCard, onDismiss }) => {
  const history = useHistory()

  const onResumeImportChange = useCallback<IResumeImportProps['onChange']>(
    state => {
      if (state === 'RecommendationsAdded') {
        history.push('/')
      }
    },
    [history]
  )

  /*
  @todo a/b test with and without automatic redirect

  const PauseButton = styled(Button)`
    &:hover {
      background: var(--primary);
    }
  `

  const Countdown = styled(Text)`
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
  `

  const [pauseRedirect, setPauseRedirect] = useState(false)
  const [redirectCount, setRedirectCount] = useState(10)
  const [hydrated, setHydrated] = useState(false)
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null)

  useOnHydrate(() => {
    setHydrated(true)
  })

  useEffect(() => {
    if (!hydrated || !isProduction) {
      return
    }

    let dismissed = false

    const intervalId = setInterval(() => {
      setRedirectCount(count => {
        if (count === 1) {
          clearInterval(intervalId)

          if (!dismissed) {
            dismissed = true
            onDismiss()
          }

          return count
        }

        return count - 1
      })
    }, 1000)

    setIntervalId(intervalId)

    return () => clearInterval(intervalId)
  }, [hydrated, onDismiss])

  {pauseRedirect ? (
    <>
      <Text text="Continue to Job" fontSize={16} />
      <span dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
    </>
  ) : (
    <Text text="Redirecting to job..." fontSize={16} />
  )}
  <View flex={1} />
  {!pauseRedirect && (
    <>
      <Countdown rawText={redirectCount.toString() as TranslatedText} />
      <PauseButton
        action="PauseJobRedirect"
        text="Pause"
        icon="pause-circle-outline"
        zIndex={999}
        paddingLeft={5}
        onPress={() => {
          if (intervalId) {
            clearInterval(intervalId)
          }

          setPauseRedirect(true)
        }}
        small
      />
    </>
  )}
  */

  return (
    <View alignItems="center" paddingTop="l" paddingX="s" marginX="auto" width="100%" maxWidth={500}>
      {jobCard !== null && (
        <JobCardWrap className="hover-arrow">
          {jobCard}
          <View
            flexDirection="row"
            alignItems="center"
            padding="m"
            borderTopWidth={1}
            justifyContent="center"
            textAlign="center"
            zIndex={-1}
          >
            <Text text="Continue to Job" fontSize={16} />
            <span dangerouslySetInnerHTML={{ __html: hoverArrow(12, 8) }} />
          </View>
        </JobCardWrap>
      )}
      <ResumeImport variant="ChatGPT" onChange={onResumeImportChange} />
    </View>
  )
}
