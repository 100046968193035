import { uniq } from 'lodash'

import {
  CurrencyCode,
  ICompensation,
  IExperience,
  IMembership,
  IPerson,
  IPositionPartial,
  ISkill,
  ITeam,
  ITeamPartial,
  IUser
} from '../models'
import { markTranslated, TranslatedText } from '../util/i18n'

const meta = {
  createdAt: new Date(),
  updatedAt: null
}

export const compensation: ICompensation = {
  min: 85,
  max: null,
  currency: 'USD' as CurrencyCode,
  frequency: 'Hour'
}

export const skill: ISkill = {
  id: 'skill:typescript' as ISkill['id'],
  slug: 'typescript' as ISkill['slug'],
  name: 'TypeScript' as TranslatedText,
  icon: 'typescript.svg',
  ...meta
}

export const skills: ISkill[] = [
  {
    slug: 'root',
    name: 'Skills',
    icon: null,
    children: ['frontend', 'backend', 'infrastructure', 'databases', 'languages']
  },
  {
    slug: 'infrastructure',
    name: 'Infrastructure' as TranslatedText,
    icon: null,
    children: ['aws', 'azure', 'google-cloud']
  },
  { slug: 'backend', name: 'Backend', icon: null, children: ['java', 'node', 'php', 'python', 'rails', 'ruby'] },
  {
    slug: 'frontend',
    name: 'Frontend',
    icon: null,
    children: ['angular', 'javascript', 'react', 'redux', 'typescript']
  },
  {
    slug: 'languages',
    name: 'Languages',
    icon: null,
    children: [
      'c',
      'c-sharp',
      'c-plus-plus',
      'dart',
      'go',
      'hcl',
      'java',
      'javascript',
      'kotlin',
      'php',
      'python',
      'ruby',
      'rust',
      'swift',
      'typescript'
    ]
  },
  { slug: 'databases', name: 'Databases', icon: null, children: ['mongodb', 'mysql', 'postgresql', 'redis'] },
  { slug: 'angular', name: 'Angular', icon: 'angular-icon-1.svg' },
  { slug: 'aws', name: 'AWS', icon: 'aws-2.svg' },
  { slug: 'azure', name: 'Azure', icon: 'azure-1.svg' },
  { slug: 'c', name: 'C', icon: 'c-2975.svg' },
  { slug: 'c-sharp', name: 'C#', icon: 'c--4.svg' },
  { slug: 'c-plus-plus', name: 'C++', icon: 'c.svg' },
  { slug: 'dart', name: 'Dart', icon: 'dart.svg' },
  { slug: 'go', name: 'Go', icon: 'go-6.svg' },
  { slug: 'google-cloud', name: 'Google Cloud', icon: 'google-cloud-1.svg' },
  { slug: 'hcl', name: 'HCL', icon: 'hashicorp.svg' },
  { slug: 'java', name: 'Java', icon: 'java-4.svg' },
  { slug: 'javascript', name: 'JavaScript', icon: 'javascript.svg' },
  { slug: 'kotlin', name: 'Kotlin', icon: 'kotlin-1.svg' },
  { slug: 'mongodb', name: 'MongoDB', icon: 'mongodb.svg' },
  { slug: 'mysql', name: 'MySQL', icon: 'mysql.svg' },
  { slug: 'node', name: 'Node.js', icon: 'nodejs-icon.svg' },
  { slug: 'php', name: 'PHP', icon: 'php-1.svg' },
  { slug: 'postgresql', name: 'PostgreSQL', icon: 'postgresql.svg' },
  { slug: 'python', name: 'Python', icon: 'python-5.svg' },
  { slug: 'rails', name: 'Rails', icon: 'rails-1.svg' },
  { slug: 'react', name: 'React', icon: 'react.svg' },
  { slug: 'redis', name: 'Redis', icon: 'redis.svg' },
  { slug: 'redux', name: 'Redux', icon: 'redux.svg' },
  { slug: 'ruby', name: 'Ruby', icon: 'ruby.svg' },
  { slug: 'rust', name: 'Rust', icon: 'rust.svg' },
  { slug: 'swift', name: 'Swift', icon: 'swift-15.svg' },
  { slug: 'typescript', name: 'TypeScript', icon: 'typescript.svg' }
].map(({ slug, name, children, ...data }) => ({
  id: `skill:${slug}` as ISkill['id'],
  slug: slug as ISkill['slug'],
  name: name as TranslatedText,
  ...(children ? { children: children as ISkill['slug'][] } : {}),
  ...data,
  ...meta
}))

/*
export const experience: IExperience = {
  id: '40' as IExperience['id'],
  skillSlug: skill['slug'],
  minYears: 2,
  maxYears: null,
  ...meta
}
*/

export const team: ITeam = {
  id: 'team:cocacola' as ITeam['id'],
  username: 'cocacola' as ITeam['username'],
  name: 'Coca-Cola' as TranslatedText,
  website: null,
  screenerEmail: null,
  logo: 'coca-cola.svg',
  location: null,
  about: null,
  bio: null,
  in5Words: null,
  ...meta
}

export const teams: ITeamPartial[] = [
  {
    id: 'team:cocacola' as ITeamPartial['id'],
    username: 'cocacola' as ITeam['username'],
    name: 'Coca-Cola' as TranslatedText,
    website: null,
    logo: 'coca-cola.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  },
  {
    id: 'team:fye' as ITeamPartial['id'],
    username: 'fye' as ITeam['username'],
    name: 'fye' as TranslatedText,
    website: null,
    logo: 'fye.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  },
  {
    id: 'team:national' as ITeamPartial['id'],
    username: 'national' as ITeam['username'],
    name: 'National' as TranslatedText,
    website: null,
    logo: 'national-3.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  },
  {
    id: 'team:paypal' as ITeamPartial['id'],
    username: 'paypal' as ITeam['username'],
    name: 'PayPal' as TranslatedText,
    website: null,
    logo: 'paypal.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  },
  {
    id: 'team:spotify' as ITeamPartial['id'],
    username: 'spotify' as ITeam['username'],
    name: 'Spotify' as TranslatedText,
    website: null,
    logo: 'spotify-1.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  },
  {
    id: 'team:underarmour' as ITeamPartial['id'],
    username: 'underarmour' as ITeam['username'],
    name: 'Under Armour' as TranslatedText,
    website: null,
    logo: 'under-armour.svg',
    location: null,
    about: null,
    screenerEmail: null,
    ...meta
  }
]

export const person: IPerson = {
  id: '60' as IPerson['id'],
  firstName: 'John',
  lastName: 'Doe',
  email: 'john@example.com',
  ...meta
}

export const user: IUser = {
  id: '70' as IUser['id'],
  email: 'support+usertest@remoteambition.com' as IUser['email'],
  username: 'user1' as IUser['username'],
  githubId: null as IUser['githubId'],
  githubData: null,
  autopilotState: null,
  types: ['JobSeeker'],
  ...meta
}

export const membership: IMembership = {
  id: '80' as IMembership['id'],
  teamId: team.id,
  userId: user.id,
  ...meta
}

const positionTitles: IPositionPartial['title'][] = [
  'Backend / API Engineer',
  'Senior Software Engineer',
  'Senior Backend Engineer',
  'Senior Application Engineer',
  'Developer',
  'Senior Python Developer'
].map(markTranslated)

/*
const teamsById = keyBy(teams, 'id')

export const positions: IPositionPartial[] = [
  {
    id: 'position:cocacola' as IPositionPartial['id'],
    team: teamsById['team:cocacola'],
    title: 'Backend / API Engineer',
    skillIds: ['skill:java', 'skill:mysql'] as ISkill['id'][],
    ...meta
  },
  {
    id: 'position:fye' as IPositionPartial['id'],
    team: teamsById['team:fye'],
    title: 'Senior Software Engineer',
    skillIds: ['skill:node', 'skill:mongodb', 'skill:angular'] as ISkill['id'][],
    ...meta
  },
  {
    id: 'position:national' as IPositionPartial['id'],
    team: teamsById['team:national'],
    title: 'Senior Backend Engineer',
    skillIds: ['skill:php', 'skill:postgresql', 'skill:javascript'] as ISkill['id'][],
    ...meta
  },
  {
    id: 'position:paypal' as IPositionPartial['id'],
    team: teamsById['team:paypal'],
    title: 'Senior Application Engineer',
    skillIds: ['skill:python', 'skill:redis', 'skill:react', 'skill:redux'] as ISkill['id'][],
    ...meta
  },
  {
    id: 'position:spotify' as IPositionPartial['id'],
    team: teamsById['team:spotify'],
    title: 'Developer',
    skillIds: ['skill:rails', 'skill:ruby', 'skill:react', 'skill:redux'] as ISkill['id'][],
    ...meta
  },
  {
    id: 'position:underarmour' as IPositionPartial['id'],
    team: teamsById['team:underarmour'],
    title: 'Senior Python Developer',
    skillIds: ['skill:python', 'skill:redis', 'skill:react', 'skill:redux', 'skill:typescript'] as ISkill['id'][],
    ...meta
  }
]
*/

const getExperiences = (count: number): IExperience[] =>
  new Array(count).fill(null).reduce<IExperience[]>(experiences => {
    const minYears = Math.floor(Math.random() * 15)
    const maxYears = Math.floor(Math.random() * (15 - minYears + 1) + minYears)

    const experience = {
      skillSlug: skills[Math.floor(Math.random() * skills.length)].slug,
      minYears,
      maxYears,
      kind: null
    }

    return experiences.find(exp => exp.skillSlug === experience.skillSlug) ? experiences : [...experiences, experience]
  }, [])

export const getPositions = (count: number): { positions: IPositionPartial[]; teams: ITeamPartial[] } => {
  const positionTeams: ITeamPartial[] = []
  const postedAt = new Date()

  const positions = new Array(count).fill(null).map<IPositionPartial>((_, index) => {
    const positionTeam = teams[index % teams.length]
    const positionTitle = positionTitles[index % positionTitles.length]

    positionTeams.push(positionTeam)

    return {
      id: `position:${index}` as IPositionPartial['id'],
      teamId: positionTeam.id,
      title: positionTitle,
      location: null,
      remoteLocation: null,
      summary: null,
      allowRemote: null,
      allowEmployee: true,
      allowContract: true,
      experienceLevel: null,
      applicantCountry: null,
      experiences: getExperiences(Math.floor(Math.random() * 6) + 1),
      contractCompensation: null,
      employeeCompensation: null,
      compensation: null,
      description: null,
      sections: null,
      applicationEmail: null,
      applicationUrl: null,
      applicationInstructions: null,
      remoteCreatedAt: null,
      screenerEmail: null,
      postedAt,
      ...meta
    }
  })

  return { positions, teams: uniq(positionTeams) }
}

/*
const positionPartial: IPositionPartial = {
  id: '200' as IPositionPartial['id'],
  team: teamPartial,
  title: 'Senior Engineer',
  ...meta
}

const generateRecords = <T extends { id: any }>(proto: T, count: number): T[] =>
  new Array(count).fill(null).map((_, index) => ({ ...proto, id: (`${proto['id']}${index}` as unknown) as T['id'] }))

export const positions: IPositionPartial[] = teams.reduce(
  (positions, team) => [
    ...positions,
    ...generateRecords<IPositionPartial>(
      { ...positionPartial, id: `${positionPartial.id}${team.id}` as IPositionPartial['id'], team },
      10
    )
  ],
  [] as IPositionPartial[]
)
*/
