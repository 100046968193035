import {
  CountryCode,
  IMarketProduct,
  IMarketProfile,
  IPosition,
  IStripeCheckoutSession,
  IStripePrice,
  IStripeProduct,
  TrackdeskReferral
} from '../models'
import { Endpoint, get, post } from '../util/api'

export type GetStripeProducts = Endpoint<
  Record<string, string>,
  {
    success: true
    products: IStripeProduct[]
  }
>

export type CreateStripeCheckoutSession = Endpoint<
  Record<string, string>,
  {
    success: true
    session: IStripeCheckoutSession
  },
  {
    productId: IStripeProduct['id']
    positionId: IPosition['id']
    returnPath: string
  }
>

export type CreateStripeMarketCheckoutSession = Endpoint<
  Record<string, string>,
  {
    success: true
    session: IStripeCheckoutSession
  },
  {
    profileId: IMarketProfile['id']
    productId: IMarketProduct['id']
  }
>

export type CreateGeneralStripeCheckoutSession = Endpoint<
  Record<string, string>,
  {
    success: true
    session: IStripeCheckoutSession
  },
  {
    productId: IStripeProduct['id']
    priceId: IStripePrice['id']
    referral?: TrackdeskReferral
  }
>

export type CreateCustomerPortalSession = Endpoint<
  Record<string, never>,
  {
    success: true
    sessionLink: string
  }
>

export type CreateStripeAccountOnboardingLink = Endpoint<
  Record<string, never>,
  { success: true; onboardingLink: string },
  {
    account: {
      businessType: 'Individual' | 'Company'
      country: CountryCode
      email: string
      companyName?: string
      firstName?: string
      lastName?: string
    } | null
  }
>

export type CreateStripeAccountLoginLink = Endpoint<Record<string, never>, { success: true; loginLink: string }>

export const getStripeProducts = () => get<GetStripeProducts>('/stripe/products')

export const createStripeCheckoutSession = (
  productId: IStripeProduct['id'],
  positionId: IPosition['id'],
  returnPath: string
) => post<CreateStripeCheckoutSession>('/stripe/session', { productId, positionId, returnPath })

export const createGeneralStripeCheckoutSession = (
  productId: IStripeProduct['id'],
  priceId: IStripePrice['id'],
  trackdeskReferral?: TrackdeskReferral
) =>
  post<CreateGeneralStripeCheckoutSession>('/stripe/checkout-session', {
    productId,
    priceId,
    referral: trackdeskReferral
  })

export const createStripeMarketCheckoutSession = (profileId: IMarketProfile['id'], productId: IMarketProduct['id']) =>
  post<CreateStripeMarketCheckoutSession>('/stripe/market/checkout/session', { profileId, productId })

export const createCustomerPortalSession = () => post<CreateCustomerPortalSession>('/stripe/customer-portal', {})

export const createStripeAccountOnboardingLink = (data: CreateStripeAccountOnboardingLink['body']) =>
  post<CreateStripeAccountOnboardingLink>('/stripe/account/onboarding', data)

export const createStripeAccountLoginLink = (data: CreateStripeAccountLoginLink['body']) =>
  post<CreateStripeAccountLoginLink>('/stripe/account/login', data)
