let loaded = false
let opened = false

declare global {
  interface Window {
    hsConversationsSettings: { loadImmediately?: boolean }
    hsConversationsOnReady: (() => void)[]
    HubSpotConversations: {
      widget: {
        load(options?: { widgetOpen?: boolean }): void
        open(): void
      }
    }
  }
}

export const showLiveChat = async () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open()
    return
  }

  if (loaded) {
    return
  }

  loaded = true

  window.hsConversationsSettings = { loadImmediately: false }

  return new Promise<void>(resolve => {
    window.hsConversationsOnReady = [
      () => {
        if (!opened) {
          opened = true
          window.HubSpotConversations.widget.load({ widgetOpen: true })
          window.HubSpotConversations.widget.open()
        }

        resolve()
      }
    ]

    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = '//js-eu1.hs-scripts.com/25032629.js'
    head.appendChild(script)
  })
}
