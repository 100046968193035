import { CreateCoverLetter, CreateCoverLetterStreamResponse, getFetchParams, TranslatedText } from 'core'

import { fetchWithStream } from '~/lite'

export const createCoverLetterWithStreamingResponse = (
  data: CreateCoverLetter['body'],
  callback: (response: CreateCoverLetter['response']) => void
): Promise<void> => {
  const [endpoint, options] = getFetchParams<CreateCoverLetter>('POST', '/studio/cover-letter/stream', { data })
  let coverLetter = '' as TranslatedText

  return fetchWithStream({
    endpoint,
    options,
    callback: (chunk, error) => {
      if (error) {
        callback({ success: false })
        return
      }

      if (!chunk?.startsWith('data: ')) {
        return
      }

      const data = JSON.parse(chunk.slice(6)) as CreateCoverLetterStreamResponse

      // if it's the final data
      if ('success' in data) {
        callback(data)
        return
      }

      coverLetter = (coverLetter + data.chunk) as TranslatedText

      callback({ success: true, coverLetter })
    }
  })
}
