import { INotification, subscribeToApiResponses, TranslateParams } from 'core'

export type IToast =
  | {
      message: TranslateParams
      type: 'Info' | 'Success' | 'Failure'
    }
  | { notification: INotification }

type ToastListener = (toast: IToast) => void

let _listeners: ToastListener[] = []

subscribeToApiResponses(({ type, message, priority }) => {
  if (priority && message) {
    _listeners.forEach(listener => listener({ type, message }))
  }
})

export const subscribeToToasts = (listener: ToastListener) => {
  if (!_listeners.includes(listener)) {
    _listeners.push(listener)
  }

  return () => {
    _listeners = _listeners.filter(callback => callback !== listener)
  }
}

const toast = (message: TranslateParams, info?: Omit<IToast, 'message'>) => {
  _listeners.forEach(listener => listener({ message, type: 'Info', ...info }))
}

toast.success = (message: TranslateParams) => toast(message, { type: 'Success' })

toast.failure = (message?: TranslateParams) =>
  toast(message ?? 'Oops! Something went wrong. Please try again later.', { type: 'Failure' })

toast.info = (message: TranslateParams) => toast(message, { type: 'Info' })

export { toast }
