import React, { Suspense } from 'react'

import type { ICompensationInputProps } from './CompensationInput.component'

const LazyCompensationInput = React.lazy(() =>
  import('./CompensationInput.component').then(module => ({ default: module.CompensationInput }))
)

export const CompensationInput: React.FC<ICompensationInputProps> = props => (
  <Suspense fallback={null}>
    <LazyCompensationInput {...props} />
  </Suspense>
)
