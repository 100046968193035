import React, { Suspense } from 'react'

import { Loader } from '../Loader/Loader'
import { IPdfViewerProps } from './types'

const LazyPdfViewer = React.lazy(async () => import('./PdfViewer').then(module => ({ default: module.PdfViewer })))

export const PdfViewer: React.FC<IPdfViewerProps> = props => (
  <Suspense fallback={<Loader />}>
    <LazyPdfViewer {...props} />
  </Suspense>
)
