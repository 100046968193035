import {
  CategorySlug,
  ClientRoutePath,
  CountryCode,
  defaultLocale,
  encodeToken,
  getCountryLocale,
  getLandingPageSlugParser,
  ILandingPageParams,
  LandingPageSlug
} from 'core'
import React from 'react'

type UseCategorySlugParams = {
  countryCode: CountryCode | null
  landingPageSlug: LandingPageSlug | null
}

export const useCategoryPath = ({ countryCode, landingPageSlug }: UseCategorySlugParams) => {
  const slugLocale = countryCode ? getCountryLocale(countryCode) : defaultLocale
  const parser = React.useMemo(() => getLandingPageSlugParser(slugLocale), [slugLocale])

  const params = React.useMemo<ILandingPageParams | null>(
    () => (landingPageSlug ? parser.parse(landingPageSlug) : null),
    [landingPageSlug, parser]
  )

  const getCategoryPath = React.useCallback<(categorySlug: CategorySlug | null) => ClientRoutePath>(
    categorySlug => {
      const locationSlug = params?.locationSlug ?? null
      const feedSectionIdSlug = params?.feedSectionIdSlug ?? null
      const publicSectionIdSlug = params?.publicSectionIdSlug ?? null

      if (!categorySlug && !locationSlug && !feedSectionIdSlug) {
        return '/'
      }

      const slug = parser.format({ categorySlug, locationSlug, feedSectionIdSlug, publicSectionIdSlug })
      return countryCode ? `/${encodeToken(countryCode)}/${encodeToken(slug)}` : `/${encodeToken(slug)}`
    },
    [params?.locationSlug, params?.feedSectionIdSlug, params?.publicSectionIdSlug, parser, countryCode]
  )

  return { getCategoryPath, params }
}
