import { AssistantView } from '@ambition/module-shared'

import {
  breakpoint,
  BREAKPOINT_WIDTH,
  Button,
  Icon,
  Image,
  Link,
  PhoneView,
  Pressable,
  styled,
  Text,
  View
} from '~/lite'

export const AppContainer = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.background};
`

export const AppHeader = styled(View)<{ $blurred: boolean }>`
  z-index: 100;
  background: ${props => (props.$blurred ? `rgba(${props.theme.backgroundRgb}, 0.8)` : props.theme.cardBackground)};
  backdrop-filter: ${props => (props.$blurred ? 'blur(10px)' : 'initial')};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: -1;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px;
    transition: box-shadow 0.2s ease-in-out;
  }
`

export const AppHeaderStart = styled(View)`
  flex-direction: row;
  align-items: stretch;
`

export const AppHeaderCenter = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  z-index: 1;
`

export const AppHeaderEnd = styled(View)`
  flex-direction: row;
  align-items: stretch;
  justify-content: end;
`

export const AppContent = styled(View)<{ $hideHeader: boolean; $hideBottomTabs: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${props => (props.$hideHeader ? 0 : '60px')};
  padding-bottom: ${props => (props.$hideBottomTabs ? 0 : '50px')};
  z-index: 1;

  @media ${breakpoint.sm} {
    padding-bottom: 0;
  }

  @media ${breakpoint.lg} {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const StackWrap = styled(View)`
  display: flex;
  flex: 1;
`

export const LogoWrap = styled(Pressable)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LogoImage = styled(Image)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`

export const LogoText = styled(Text)`
  font-weight: 600;
  font-size: 18px;
`

export const DashboardButton = styled(Button)`
  background: none;
`

export const UpgradeButton = styled(Button)`
  background: linear-gradient(90deg, #ff8a00 0%, #e52e71 100%);

  &:hover {
    background: linear-gradient(90deg, #ff8a00 0%, #e52e71 100%);
  }
`

export const BottomTabs = styled(PhoneView)`
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.border};
  background: ${props => props.theme.cardBackground};
`

export const IconWrap = styled(Icon)`
  @media (max-width: ${BREAKPOINT_WIDTH.LG - 1}px) {
    padding-bottom: 5px;
  }

  @media (min-width: ${BREAKPOINT_WIDTH.LG}px) {
    margin-right: 5px;
  }
`

export const TabWrap = styled(Text)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.primary};

  @media (max-width: ${BREAKPOINT_WIDTH.LG - 1}px) {
    flex: 1;
    flex-direction: column;
    padding: 8px 0;
    font-size: 11px;
  }

  @media (min-width: ${BREAKPOINT_WIDTH.LG}px) {
    flex-direction: row;
    padding: 0 8px;

    &:first-child {
      padding-left: 16px;
    }
  }
`

export const CloseIcon = styled(Icon)`
  @media ${breakpoint.lg} {
    display: none;
  }
`

export const SearchBarWrap = styled(View)`
  width: 100%;
  max-width: 500px;
  min-height: 42px;
  padding-left: 5px;
  flex-direction: row;
  align-items: stretch;
  border-width: 1px;
  border-color: ${props => props.theme.inputBorderColor};
  border-radius: 20px;
  background: ${props => props.theme.inputBackground};
  overflow: hidden;
`

export const SearchBarInner = styled(View)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

export const MainMenuOptions = styled(View)`
  background: ${props => props.theme.cardBackground};
  padding: 16px;
  border-radius: 12px;
`

export const MainMenuShim = styled(View)<{ $view: AssistantView }>`
  height: ${props => (props.$view === 'Mini' ? 190 : props.$view === 'Full' ? 400 : 50)}px;

  @media ${breakpoint.lg} {
    & {
      height: 400px;
    }
  }
`

export const WebsiteMenu = styled(View)`
  flex: 1;
  margin: 0 auto;
  flex-direction: row;
  align-items: stretch;
`

export const WebsiteMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-weight: 500;
  background-color: transparent;
  color: ${props => props.theme.text};
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }

  &:nth-child(n + 4) {
    display: none;
  }

  @media ${breakpoint.md} {
    padding: 0 16px;

    &:nth-child(n + 4) {
      display: flex;
    }
  }
`

export const UserIcon = styled(View)`
  display: flex;
  height: 36px;
  width: 36px;
  border-radius: 120px;
  border-width: 1px;
  border-color: ${props => props.theme.inputPlaceholder};
  align-items: center;
  justify-content: center;
`

export const SmallImage = styled(Image)`
  border-radius: 120px;
  border-width: 1px;
  border-color: ${props => props.theme.border};
`
